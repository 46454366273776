import {
  Alert,
  AlertProps,
  Button,
  createStyles,
  Group,
  LoadingOverlay,
  Modal,
  SegmentedControl,
  SegmentedControlProps,
  Select,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import React from 'react';
import * as Yup from 'yup';

import {
  useActiveConnectionsResponse,
  useCreateOrganization,
  UseCreateOrganizationParams,
  useIsChannelPartner,
  useRequestPlanUpgrade,
} from '@portals/api/partners';
import { ModalProps, useHasSupportSeat } from '@portals/framework';
import { ReactComponent as InfoCircle } from '@portals/icons/bold/info-circle.svg';

interface AddOrganizationProps extends ModalProps {}

type CustomerAdmin = 'customer' | 'you' | 'both';

const schema = Yup.object().shape({
  email: Yup.string().when('choose_admin', {
    is: (value: CustomerAdmin) => value === 'customer' || value === 'both',
    then: Yup.string().email('Please enter a valid email address'),
  }),
});

export function AddOrganization({ closeMe }: AddOrganizationProps) {
  const { classes } = useStyles();
  const hasSupportSeat = useHasSupportSeat();

  const requestPlanUpgrade = useRequestPlanUpgrade();

  const createOrganization = useCreateOrganization();

  const isChannelPartner = useIsChannelPartner();

  const activeConnectionsResponse = useActiveConnectionsResponse({
    queryOptions: {
      enabled: isChannelPartner,
    },
  });

  const activeConnectionsSelectOptions =
    activeConnectionsResponse.data?.data?.map((item) => {
      return {
        value: item.partner_id,
        label: item.display_name,
      };
    }) || [];

  const form = useForm({
    initialValues: {
      organization_name: '',
      name: '',
      email: '',
      main_partner_id: '',
      choose_admin: isChannelPartner ? 'both' : 'customer',
      auto_admin: isChannelPartner,
    },

    validate: yupResolver(schema),
  });

  const onSetCustomerAdmin = (value: CustomerAdmin) => {
    form.setFieldValue('choose_admin', value);

    if (!hasSupportSeat) {
      return;
    }

    if (value === 'customer') {
      form.setFieldValue('auto_admin', false);
      return;
    }

    form.setFieldValue('auto_admin', true);
  };

  const handleSubmit = async (data: UseCreateOrganizationParams) => {
    try {
      await createOrganization.mutateAsync({
        ...data,
        email: form.values.choose_admin === 'you' ? '' : form.values.email,
        name: form.values.choose_admin === 'you' ? '' : form.values.name,
      });

      closeMe();
    } catch (e) {
      console.error(e);
    }
  };

  const segmentedControlData: SegmentedControlProps['data'] = [
    { value: 'customer', label: 'The customer' },
    { value: 'you', label: 'You', disabled: !hasSupportSeat },
    {
      value: 'both',
      label: 'Both you and the customer',
      disabled: !hasSupportSeat,
    },
  ];

  const onAddSeats = () => {
    requestPlanUpgrade.mutate('Partners > Customers > Add Seats');
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      title="Create Customer"
      radius="lg"
      size={600}
      padding="xxl"
    >
      <LoadingOverlay
        visible={
          activeConnectionsResponse.isFetching || createOrganization.isLoading
        }
      />

      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack>
          {isChannelPartner && activeConnectionsResponse.isFetched && (
            <Select
              data={activeConnectionsSelectOptions}
              required
              placeholder="Connected Partners"
              label="Connected Partners"
              {...form.getInputProps('main_partner_id')}
            />
          )}

          <TextInput
            required
            label="Customer organization name"
            data-testid="customer-organization-name-input"
            placeholder="Customer organization name"
            {...form.getInputProps('organization_name')}
          />

          <Stack>
            <Text>Choose the Admin for the Customer's Portal</Text>

            <SegmentedControl
              data={segmentedControlData}
              value={form.values.choose_admin}
              data-testid="choose-admin"
              onChange={onSetCustomerAdmin}
            />
          </Stack>

          {!hasSupportSeat && (
            <Alert
              icon={<InfoCircle />}
              styles={alertStyles}
              color="gray.1"
              p="xl"
              radius="md"
            >
              <Stack>
                <Text>
                  You don't have support seats, so only the customer can be the
                  admin.
                </Text>

                <Text
                  align="right"
                  color="gray.9"
                  onClick={onAddSeats}
                  className={classes.addSeats}
                  underline
                >
                  Add support seats
                </Text>
              </Stack>
            </Alert>
          )}

          {form.values.choose_admin !== 'you' ? (
            <>
              <TextInput
                required
                label="Customer admin name"
                data-testid="choose-admin-name-input"
                placeholder="Customer admin name"
                {...form.getInputProps('name')}
              />

              <TextInput
                required
                label="Customer admin email"
                placeholder="Customer admin email"
                data-testid="choose-admin-email-input"
                {...form.getInputProps('email')}
              />
            </>
          ) : null}

          <Group position="right" pt="lg">
            <Button variant="default" onClick={closeMe}>
              Close
            </Button>

            <Button type="submit" data-testid="submit-button">
              Create
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
}

const alertStyles: AlertProps['styles'] = (theme) => ({
  icon: {
    color: theme.colors.amber[6],
  },
});

const useStyles = createStyles((theme) => ({
  addSeats: {
    cursor: 'pointer',
  },
}));
