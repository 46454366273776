import { Group, Stack, TextInput, Button } from '@mantine/core';
import { Formik } from 'formik';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffectOnce, useSearchParam } from 'react-use';

import { toastrSuccess } from '@portals/redux/actions/toastr';

import { getApiDevice, setAuth } from '../../redux/actions/api';

const INPUTS = [
  { name: 'id', label: 'Device ID' },
  { name: 'access_key', label: 'Access Key' },
  { name: 'hub_url', label: 'Hub URL' },
];

const DeviceSelector = ({ access, setAuth, toastrSuccess }) => {
  const deviceId = useSearchParam('device_id');
  const deviceAccessKey = useSearchParam('device_access_key');
  const hub_url = useSearchParam('hub_url');
  const navigate = useNavigate();

  const adjustedAccess = useMemo(
    () => ({
      ...access,
      id: deviceId || access.id,
      access_key: deviceAccessKey || access.access_key,
      hub_url: hub_url || access.hub_url,
    }),
    [hub_url, access, deviceAccessKey, deviceId]
  );

  const handleSubmit = (data) => {
    setAuth(data);
    toastrSuccess('Successfully set Device auth');
  };

  useEffectOnce(() => {
    if (deviceId || deviceAccessKey) {
      navigate('/dev-center', { replace: true });
    }

    if (deviceId && deviceAccessKey && hub_url) {
      handleSubmit(adjustedAccess);
    }
  });

  return (
    <Formik
      enableReinitialize={true}
      initialValues={adjustedAccess}
      onSubmit={handleSubmit}
    >
      {({ values, handleChange, handleBlur, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Stack>
            {INPUTS.map(({ name, label }) => (
              <Group key={name} grow>
                <TextInput
                  name={name}
                  data-testid={`${name}-input`}
                  label={label}
                  placeholder={label}
                  value={values[name] || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Group>
            ))}

            <Group>
              <Button data-testid="device-selector-set-button" type="submit">
                Set
              </Button>
            </Group>
          </Stack>
        </form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state) => ({
  access: getApiDevice(state),
});

export default connect(mapStateToProps, { setAuth, toastrSuccess })(
  DeviceSelector
);
