import { createStyles, Group, Stack, Text } from '@mantine/core';
import { isBoolean, noop } from 'lodash/fp';
import React, { useMemo } from 'react';

import { ReactComponent as StoreWithoutBanner } from '../../../assets/img/store-no-banner.svg';
import { ReactComponent as StoreWithBanner } from '../../../assets/img/store-with-banner.svg';
import { useDesignField } from '../common/fields/hooks';

interface StoreBannerSelectorProps {
  disabled: boolean;
}

export function StoreBannerSelector({ disabled }: StoreBannerSelectorProps) {
  const { classes, cx } = useStyles();

  const { value, onChange, defaultValue } = useDesignField(
    'design.store.with_banner'
  );

  const isWithBanner = useMemo(() => {
    if (!isBoolean(value)) {
      return defaultValue;
    }

    return value;
  }, [defaultValue, value]);

  return (
    <Group grow className={cx({ [classes.disabled]: disabled })}>
      <Stack
        p="xs"
        align="center"
        onClick={() => (disabled ? noop : onChange(false))}
        className={cx(classes.section, { selected: !isWithBanner })}
      >
        <StoreWithoutBanner />

        <Text>None</Text>
      </Stack>

      <Stack
        p="xs"
        align="center"
        onClick={() => (disabled ? noop : onChange(true))}
        className={cx(classes.section, { selected: isWithBanner })}
      >
        <StoreWithBanner />

        <Text>Mini Banner</Text>
      </Stack>
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  section: {
    borderRadius: theme.radius.md,
    border: `1px solid transparent`,
    cursor: 'pointer',
    transition: 'border-color 0.15s ease-in-out',
    userSelect: 'none',

    svg: {
      color: theme.colors.gray[3],
      transition: 'color 0.15s ease-in-out',
    },

    '&.selected': {
      borderColor: theme.colors.blue_accent[4],

      svg: {
        color: theme.colors.blue_accent[1],
      },
    },
  },
  disabled: {
    opacity: 0.7,
    borderColor: theme.white,
    pointerEvents: 'none',
  },
}));
