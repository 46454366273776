import { Box, createStyles, LoadingOverlay, Paper } from '@mantine/core';
import React from 'react';
import { Navigate } from 'react-router-dom';

import {
  useSendMessage,
  useTicket,
  useUpdateSeen,
} from '@portals/api/partners';
import { useHasSupportSeat } from '@portals/framework';
import { TenantType } from '@portals/types';
import { Chat } from '@portals/ui';

import DeviceDetails from './DeviceDetails';
import { TicketDetails } from './TicketDetails';

interface TicketViewProps {
  ticketId: string;
}

export function TicketView({ ticketId }: TicketViewProps) {
  const { classes } = useStyles();

  const hasSupportSeat = useHasSupportSeat();
  const ticket = useTicket(ticketId);
  const sendMessage = useSendMessage(ticketId);
  const updateSeen = useUpdateSeen(ticketId);

  if (!hasSupportSeat) {
    return <Navigate to="/tickets" replace />;
  }

  const handleSendMessage = async (message: string) => {
    try {
      await sendMessage.mutateAsync(message);
    } catch (error) {
      return Promise.reject();
    }
  };

  if (!ticket.isFetched) {
    return <LoadingOverlay visible />;
  } else if (!ticket.data) {
    return <Navigate to="/tickets" replace />;
  }

  return (
    <Box className={classes.container}>
      <LoadingOverlay visible={ticket.isInitialLoading} />

      <TicketDetails ticket={ticket.data} />

      {ticket.data.status !== 'resolved' ? (
        <DeviceDetails ticket={ticket.data} />
      ) : null}

      {ticket.data.chat && (
        <Paper mt={32} withBorder radius={0}>
          <Chat
            messages={ticket.data.chat}
            sendMessage={handleSendMessage}
            seen={ticket.data.partner_seen}
            updateSeen={updateSeen.mutate}
            pollingFn={ticket.refetch}
            chatOwner={TenantType.Partner}
          />
        </Paper>
      )}
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateRows: 'max-content 1fr',
    gridAutoRows: '1fr',
    height: '100%',
  },
}));
