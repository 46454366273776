import { LoadingOverlay } from '@mantine/core';
import React from 'react';
import { useParams } from 'react-router-dom';

import {
  StoreListing,
  useArchiveStoreListing,
  useStoreListing,
} from '@portals/api/partners';
import { useConfirmationModal } from '@portals/framework';
import { RoutePanel } from '@portals/framework/route-panels';

import { StoreListingsTableDetailsPanel } from '../../pages/store-listings/store-listing-table/details-panel/StoreListingsTableDetailsPanel';

export function StoreListingRoutePanel() {
  const asyncConfirmation = useConfirmationModal();

  const archiveStoreListing = useArchiveStoreListing();

  const { store_listing_slug } = useParams<{ store_listing_slug: string }>();

  const storeListing = useStoreListing(store_listing_slug);

  const onArchive = async (storeListing: StoreListing) => {
    const isConfirmed = await asyncConfirmation({
      title: 'Archive product listing',
      description: 'Are you sure you want to archive this product listing?',
      confirmationLabel: 'Archive',
    });

    if (isConfirmed) {
      archiveStoreListing.mutate({
        storeListingId: storeListing.id,
      });
    }
  };

  if (storeListing.isLoading || !storeListing.data) {
    return <LoadingOverlay visible />;
  }

  return (
    <RoutePanel panelId="store_listing" size="clamp(700px, 50%, 1000px)">
      {({ onClose }) => (
        <StoreListingsTableDetailsPanel
          storeListing={storeListing.data}
          onArchive={onArchive}
          onClose={onClose}
        />
      )}
    </RoutePanel>
  );
}
