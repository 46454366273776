import { Loader, SimpleGrid, Stack, Title } from '@mantine/core';
import React from 'react';

import { useWebhooks } from '@portals/api/partners';

import { Webhook } from './Webhook';
import { WEBHOOKS } from './webhooks.constants';

export function Webhooks() {
  const webhooks = useWebhooks();

  if (!webhooks.isFetched) return <Loader />;

  return (
    <Stack>
      <Title order={3}>Webhooks</Title>

      <SimpleGrid cols={2} spacing="xl">
        {WEBHOOKS.map(({ event, title, responseSample }) => (
          <Webhook
            key={event}
            event={event}
            title={title}
            responseSample={responseSample}
          />
        ))}
      </SimpleGrid>
    </Stack>
  );
}
