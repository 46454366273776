import {
  createStyles,
  Group,
  Stack,
  Text,
  UnstyledButton,
} from '@mantine/core';
import React, { ReactNode, useMemo } from 'react';
import { Link, useMatch } from 'react-router-dom';

import { useFeatureFlags, useIsCecPartner } from '@portals/api/partners';
import { isPartnerFeatureOn } from '@portals/framework';
import { ReactComponent as BarcodeIcon } from '@portals/icons/linear/barcode.svg';
import { ReactComponent as BoxIcon } from '@portals/icons/linear/box.svg';
import { ReactComponent as ChevronDownIcon } from '@portals/icons/linear/chevron-down.svg';
import { ReactComponent as GlobalIcon } from '@portals/icons/linear/global.svg';
import { ReactComponent as LifebuoyIcon } from '@portals/icons/linear/lifebuoy.svg';
import { ReactComponent as ListIcon } from '@portals/icons/linear/list.svg';
import { ReactComponent as Login1Icon } from '@portals/icons/linear/login-1.svg';
import { ReactComponent as UnlimitedIcon } from '@portals/icons/linear/unlimited.svg';
import { useOpenModal } from '@portals/redux';

import { AuthPagesForm } from './auth-pages/AuthPagesForm';
import { ConnectForm } from './connect/ConnectForm';
import {
  CUSTOMER_PORTAL_PATHS,
  CustomerPortalPaths,
} from './customer-portal.constants';
import { DeviceClaimingForm } from './device-claiming/DeviceClaimingForm';
import { DevicesForm } from './devices/DevicesForm';
import { NavigationMenuForm } from './navigation-menu/NavigationMenuForm';
import { PortalDomainForm } from './portal-domain/PortalDomainForm';
import { TicketsForm } from './tickets/TicketsForm';
import { ReactComponent as ConnectIcon } from '../../../assets/img/connect-plus.svg';
import { DASHBOARD_PATHS } from '../../../routes/dashboard/dashboard-paths.constants';
import { SidebarTabPanel } from '../SidebarTabPanel';

export function CustomerPortalSidebar() {
  const { classes } = useStyles();

  const openModal = useOpenModal();
  const isCecPartner = useIsCecPartner();
  const featureFlags = useFeatureFlags();

  const match = useMatch(DASHBOARD_PATHS.staticPaths.branding);

  const subPages = useMemo(() => {
    const isCecPreEnrollmentOn = isPartnerFeatureOn(
      featureFlags,
      'cec_pre_enrollment'
    );

    const pages: Array<{
      to: CustomerPortalPaths;
      label: ReactNode;
      icon: ReactNode;
      formComponent?: ReactNode;
      isOnlyCEC: boolean;
      isAccessible: boolean;
    }> = [
      {
        to: CUSTOMER_PORTAL_PATHS.PortalDomain,
        label: 'Portal Domain',
        icon: <GlobalIcon />,
        formComponent: <PortalDomainForm />,
        isOnlyCEC: false,
        isAccessible: true,
      },
      {
        to: CUSTOMER_PORTAL_PATHS.AuthPages,
        label: 'Auth Pages',
        icon: <Login1Icon />,
        formComponent: <AuthPagesForm />,
        isOnlyCEC: false,
        isAccessible: true,
      },
      {
        to: CUSTOMER_PORTAL_PATHS.Connect,
        label: 'Connect',
        icon: <UnlimitedIcon />,
        formComponent: <ConnectForm />,
        isOnlyCEC: true,
        isAccessible: isCecPartner || isCecPreEnrollmentOn,
      },
      {
        to: CUSTOMER_PORTAL_PATHS.DeviceClaiming,
        label: 'Device Claiming',
        icon: <BarcodeIcon />,
        formComponent: <DeviceClaimingForm />,
        isOnlyCEC: true,
        isAccessible: isCecPartner || isCecPreEnrollmentOn,
      },
      {
        to: CUSTOMER_PORTAL_PATHS.Devices,
        label: 'Devices',
        icon: <BoxIcon />,
        formComponent: <DevicesForm />,
        isOnlyCEC: true,
        isAccessible: isCecPartner || isCecPreEnrollmentOn,
      },
      {
        to: CUSTOMER_PORTAL_PATHS.Tickets,
        label: 'Tickets',
        icon: <LifebuoyIcon />,
        formComponent: <TicketsForm />,
        isOnlyCEC: true,
        isAccessible: isCecPartner || isCecPreEnrollmentOn,
      },
    ];

    if (!isCecPartner) {
      pages.push({
        to: CUSTOMER_PORTAL_PATHS.NavigationMenu,
        label: 'Navigation Menu',
        icon: <ListIcon />,
        formComponent: <NavigationMenuForm />,
        isOnlyCEC: false,
        isAccessible: !isCecPartner,
      });
    }

    return pages;
  }, [featureFlags, isCecPartner]);

  const selected = subPages.find((link) => link.to === match?.params?.['*']);

  if (selected && selected.isAccessible) {
    return (
      <SidebarTabPanel>
        <SidebarTabPanel.Header>
          <Link to="." className={classes.headerBackLink}>
            <ChevronDownIcon className={classes.headerChevron} />
            <Text>{selected.label}</Text>
          </Link>
        </SidebarTabPanel.Header>

        <SidebarTabPanel.Body>{selected.formComponent}</SidebarTabPanel.Body>
      </SidebarTabPanel>
    );
  }

  return (
    <SidebarTabPanel>
      <SidebarTabPanel.Header>
        <Text>Customer Portal</Text>
      </SidebarTabPanel.Header>

      <SidebarTabPanel.Body>
        <Stack spacing="xs">
          {subPages.map((subPage) => {
            if (subPage.isOnlyCEC && !subPage.isAccessible) {
              return (
                <UnstyledButton
                  key={subPage.to}
                  className={classes.linkItem}
                  onClick={() => openModal('RequestCecEnrollmentModal')}
                >
                  <Group spacing="xs">
                    <span className={classes.linkItemIcon}>{subPage.icon}</span>

                    <Text>{subPage.label}</Text>
                  </Group>

                  <ConnectIcon />
                </UnstyledButton>
              );
            }

            return (
              <Link
                key={subPage.to}
                to={subPage.to}
                className={classes.linkItem}
              >
                <Group spacing="xs">
                  <span className={classes.linkItemIcon}>{subPage.icon}</span>

                  <Text>{subPage.label}</Text>
                </Group>

                <ChevronDownIcon className={classes.chevron} />
              </Link>
            );
          })}
        </Stack>
      </SidebarTabPanel.Body>
    </SidebarTabPanel>
  );
}

const useStyles = createStyles((theme) => ({
  headerBackLink: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing.xs,
    color: 'inherit',

    ...theme.fn.hover({
      color: 'inherit',
      textDecoration: 'none',
    }),
  },
  headerChevron: {
    transform: 'rotate(90deg)',
  },
  linkItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 72,
    paddingInline: theme.spacing.xl,
    borderRadius: theme.radius.lg,
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[9],
    backgroundColor: theme.colors.gray[0],

    ...theme.fn.hover({
      color: 'inherit',
      textDecoration: 'none',
      backgroundColor: theme.colors.gray[1],
    }),
  },
  linkItemIcon: {
    svg: {
      width: 18,
      height: 18,
    },
  },
  chevron: {
    color: theme.colors.gray[4],
    transform: 'rotate(-90deg)',
  },
}));
