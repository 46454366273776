import React from 'react';

import {
  RoutedTabs,
  RoutedTabsProps,
  useGetSettingPageIdFromUrlParams,
} from '@portals/framework';

import { GroupsTable } from './groups/GroupsTable';
import { UsersTable } from './UsersTable';

const ROUTES: RoutedTabsProps['routes'] = [
  {
    path: 'users',
    tabLabel: 'Users',
    element: <UsersTable />,
  },
  {
    path: 'groups',
    tabLabel: 'Groups',
    element: <GroupsTable />,
  },
];

export function UsersAndGroups() {
  const settingPageId = useGetSettingPageIdFromUrlParams();

  return <RoutedTabs routes={ROUTES} basePath={`/settings/${settingPageId}`} />;
}
