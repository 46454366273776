import { Button, Group, Stack } from '@mantine/core';
import React from 'react';
import { connect } from 'react-redux';

import Response from '../../components/Response';
import TestCommand from '../../components/TestCommand';
import { getApiDevice, getLicense } from '../../redux/actions/api';

const GetLicense = ({ access, getLicense }) => (
  <Stack>
    <Group>
      <Button onClick={() => getLicense(access.id)} disabled={!access.id}>
        Get
      </Button>
      <span>
        Device ID:
        {access.id || 'Not set'}
      </span>
    </Group>

    {access.id && <TestCommand url={`/${access.id}/license`} />}

    <Response type="getLicense" />
  </Stack>
);

const mapStateToProps = (state) => ({
  access: getApiDevice(state),
});

export default connect(mapStateToProps, { getLicense })(GetLicense);
