import React from 'react';

import { DashboardContentLayout, RoutedTabs } from '@portals/framework';

import { ProductsTable } from './ProductsTable';

export function ProductsCatalogPage() {
  return (
    <DashboardContentLayout pageTitle="Product Catalog" bg="gray.0">
      <RoutedTabs
        basePath="/catalog"
        routes={[
          {
            path: 'active',
            tabLabel: 'Active',
            element: <ProductsTable archivedOnly={false} />,
          },
          {
            path: 'archived',
            tabLabel: 'Archived',
            element: <ProductsTable archivedOnly />,
          },
        ]}
      />
    </DashboardContentLayout>
  );
}
