import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import { openModal } from '@portals/redux/actions/modal';

import Rules from '../../../components/Rules';
import { getModelRules } from '../../../redux/actions/rules';

const ModelRules = ({ model, rules, getModelRules }) => {
  useEffect(() => {
    getModelRules(model.id);
  }, [getModelRules, model.id]);

  const modelRules = useMemo(
    () =>
      Object.keys(rules || {})
        .filter((key) => rules[key].device_model === model.id)
        .map((key) => rules[key]),
    [rules, model.id]
  );

  return (
    <Rules
      showHeader
      rules={modelRules}
      model={model}
      noFilters
      exportParams={{ isEnabled: false }}
      noColumnsSelection
      noSort
    />
  );
};

const mapStateToProps = (state) => ({
  rules: state.data.rules,
});

export default connect(mapStateToProps, { getModelRules, openModal })(
  ModelRules
);
