import {
  Button,
  Group,
  Select,
  Stack,
  Textarea,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React from 'react';
import { useDispatch } from 'react-redux';

import { ModalBody, ModalFooter } from '@portals/core';
import { PRIORITY_OPTIONS } from '@portals/utils';

import Response from '../../components/Response';
import TestCommand from '../../components/TestCommand';
import { openIncident, useGetApiDevice } from '../../redux/actions/api';

export type PriorityOption =
  (typeof PRIORITY_OPTIONS)[keyof typeof PRIORITY_OPTIONS];

interface OpenIncidentFormType {
  title: string;
  priority: PriorityOption | null;
  description: string;
  issue: string;
}

export function OpenIncident() {
  const form = useForm<OpenIncidentFormType>({
    initialValues: {
      title: '',
      priority: 'Low',
      description: '',
      issue: '',
    },
  });

  const dispatch = useDispatch();
  const access = useGetApiDevice();

  const onSubmit = (values: OpenIncidentFormType) => {
    dispatch(openIncident(access.id, values));
  };

  const priorityOptions = Object.entries(PRIORITY_OPTIONS).map(
    ([key, value]) => ({
      value,
      label: value,
    })
  );

  return (
    <Stack>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <ModalBody>
          <Stack>
            <TextInput
              {...form.getInputProps('title')}
              label="Title"
              required
            />

            <Select
              data={priorityOptions}
              {...form.getInputProps('priority')}
              label="Priority"
            />

            <Textarea
              label="Description"
              minRows={3}
              {...form.getInputProps('description')}
            />

            <TextInput label="Issue" {...form.getInputProps('issue')} />
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Group>
            <Button type="submit" disabled={!access.id || !form.values.title}>
              Get
            </Button>
            <span>
              Device ID:
              {access.id || 'Not set'}
            </span>
          </Group>
        </ModalFooter>
      </form>

      {access.id ? <TestCommand url={`/${access.id}/incidents`} /> : null}

      <Response type="openIncident" />
    </Stack>
  );
}
