import {
  ActionIcon,
  BackgroundImage,
  Box,
  createStyles,
  Input,
  Tooltip,
} from '@mantine/core';
import classNames from 'classnames';
import React from 'react';
import 'cropperjs/dist/cropper.css';
import { ImageType } from 'react-images-uploading';

import { ReactComponent as Edit2 } from '@portals/icons/linear/edit-2.svg';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import { useOpenModal } from '@portals/redux';
import { AutoFormikProps, FieldType, SetFieldValueType } from '@portals/types';

import { ImageInput } from './ImageInput';

export type ImageSelectorFieldValue = {
  id: string;
  url: string;
  thumbnail: string;
};

export interface CropConfig {
  aspectRatio: number;
  width: number;
  height: number;
}

interface PreviewConfig {
  width?: number;
  height?: number;
}

export type ImageSelectorFieldProps = {
  error?: string;
  required?: boolean;
  horizontal: AutoFormikProps['inputProps']['horizontal'];
  setFieldValue: SetFieldValueType<string>;
  field: FieldType & {
    height?: number | string;
    width?: number | string;

    previewConfig?: PreviewConfig;
    cropConfig: CropConfig;
  };
  value: ImageSelectorFieldValue | string;
  className?: string;
  label?: string;
  isRemovable?: boolean;
  disabled?: boolean;
};

// After moving to S3 from Cloudinary, need to support deprecated images structure
// Should be removed after a migration is run on all images
const getImageUrl = (image?: string | ImageSelectorFieldValue) =>
  (image as ImageSelectorFieldValue)?.url || (image as string) || '';

export function ImageSelectorField({
  field,
  value,
  setFieldValue,
  label = 'Upload image',
  error,
  required,
  className,
  isRemovable = true,
  disabled = false,
}: ImageSelectorFieldProps) {
  const { classes } = useStyles();
  const image = getImageUrl(value);
  const openModal = useOpenModal();

  const { inputProps = {}, cropConfig, previewConfig = {} } = field;

  const onChange = (imageList?: ImageType[]) => {
    const image = imageList?.[0];

    openModal('UploadImageModal', {
      image,
      cropConfig,
      onUpload: (imgUrl: string) => setFieldValue(field.name, imgUrl),
      onError: (error) => console.error(error),
    });
  };

  return (
    <Input.Wrapper error={error} {...inputProps}>
      <Input.Label data-testid="upload-image" required={required}>
        {field.title}
      </Input.Label>

      {image ? (
        <Box
          w={field.width || '100%'}
          h={field.height || '100%'}
          bg="gray.2"
          pos="relative"
          className={classes.imagePreviewWrapper}
          data-testid="upload-image-box"
        >
          <BackgroundImage
            className={classes.imagePreviewBackground}
            src={value as string}
            w={previewConfig?.width}
            h={previewConfig?.height}
          >
            <Tooltip
              label={isRemovable ? 'Remove image' : 'Replace image'}
              withArrow
              withinPortal
            >
              <ActionIcon
                disabled={disabled}
                color="gray.0"
                c="gray.7"
                variant="filled"
                className={classes.actionIcon}
                onClick={() => {
                  if (isRemovable) {
                    setFieldValue(field.name, '');
                  } else {
                    onChange();
                  }
                }}
              >
                {isRemovable ? <Trash /> : <Edit2 />}
              </ActionIcon>
            </Tooltip>
          </BackgroundImage>
        </Box>
      ) : (
        <Box w={field.width || '100%'} h={field.height || '100%'}>
          <ImageInput
            label={label}
            data-testid={'image-input-' + field.name}
            onChange={onChange}
            className={classNames(className)}
            cropConfig={cropConfig}
          />
        </Box>
      )}
    </Input.Wrapper>
  );
}

const useStyles = createStyles((theme) => ({
  imagePreviewWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imagePreviewBackground: {
    width: '100%',
    height: '100%',
    backgroundSize: 'contain !important',
    backgroundPosition: 'center !important',
    backgroundRepeat: 'no-repeat !important',
  },
  actionIcon: {
    position: 'absolute',
    bottom: 8,
    right: 8,
    width: 32,
    height: 32,
  },
  imagePreviewRoot: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  imagePreview: {
    objectFit: 'contain',
  },
}));
