import { createStyles, Stack } from '@mantine/core';
import React from 'react';

import { ReactComponent as Book1 } from '@portals/icons/linear/book-1.svg';
import { ReactComponent as Calendar } from '@portals/icons/linear/calendar.svg';
import { ReactComponent as Code } from '@portals/icons/linear/code.svg';

import { GroupedGuideItem } from './GroupedGuideItem';
import { CardContainer, MainContentCardTitle } from '../common';

const useStyles = createStyles((theme) => ({
  container: {
    flex: 1,
  },
  grid: {
    flex: 1,
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: theme.spacing.xs,
  },
}));

export function GroupedGuidesCard() {
  const { classes, theme } = useStyles();

  return (
    <CardContainer className={classes.container}>
      <Stack spacing="xl" sx={{ height: '100%' }}>
        <MainContentCardTitle>Learn & Get Inspired</MainContentCardTitle>
        <div className={classes.grid}>
          <GroupedGuideItem
            linkTo={{ pathname: 'https://dev.xyte.io/docs' }}
            title="Knowledge Base"
            subtitle="Learn how xyte.io works"
            bgColor="rgba(233, 236, 255, 0.6)"
            hoverBgColor="rgba(233, 236, 255, 0.9)"
            icon={<Book1 color={theme.colors.indigo[2]} width={18} />}
          />
          <GroupedGuideItem
            linkTo={{ pathname: 'https://dev.xyte.io/reference' }}
            title="Developers Hub"
            subtitle="Learn more about our API"
            bgColor="rgba(255, 235, 238, 0.5)"
            hoverBgColor="rgba(255, 235, 238, 0.8)"
            icon={<Code color={theme.colors.red[2]} width={18} />}
          />
          <GroupedGuideItem
            linkTo={{
              pathname: 'https://calendly.com/mcgowan-xyte/30min',
            }}
            title="Schedule an Intro"
            subtitle="Let's get in touch!"
            bgColor="rgba(224, 242, 241, 0.5)"
            hoverBgColor="rgba(224, 242, 241, 0.8)"
            icon={<Calendar color={theme.colors.teal[2]} width={18} />}
          />
        </div>
      </Stack>
    </CardContainer>
  );
}
