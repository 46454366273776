import { Group, Text } from '@mantine/core';
import React from 'react';

import {
  SUBSCRIPTIONS_API_URL,
  SubscriptionSummaryType,
  useSubscriptions,
} from '@portals/api/partners';
import {
  PartnerInvoicePaymentStatusBadge,
  SubscriptionStatusBadge,
} from '@portals/framework';
import { ReactComponent as Gallery } from '@portals/icons/bold/gallery.svg';
import { AvatarCell, DateCell, PaginatedTable } from '@portals/table';
import {
  ProductPricingModel,
  SubscriptionStatus,
  TableColumn,
  TableFilterTypeEnum,
} from '@portals/types';
import {
  formatPrice,
  getPricingModelDisplayName,
  getProductPriceDisplayText,
  mediumDateFormatter,
} from '@portals/utils';

import { SubscriptionDetailsPanel } from './SubscriptionDetailsPanel';

const columns: TableColumn<SubscriptionSummaryType>[] = [
  {
    dataField: 'product_name',
    text: 'Product name',
    sort: true,
    isSticky: true,
    minWidth: 350,
    maxWidth: 350,
    filter: { type: TableFilterTypeEnum.Text },
    formatter: (_, { product }) => (
      <AvatarCell
        label={product.name}
        src={product.image_url}
        radius="md"
        withAbbreviation={false}
        color="blue_gray"
      >
        <Gallery />
      </AvatarCell>
    ),
  },
  {
    dataField: 'status',
    text: 'Status',
    sort: true,
    formatter: (_, { status }) => <SubscriptionStatusBadge status={status} />,
    filter: {
      type: TableFilterTypeEnum.Select,
      options: {
        active: 'Active',
        pending_cancellation: 'Pending Cancellation',
      },
    },
  },
  {
    dataField: 'organization.display_name',
    text: 'Customer',
    sort: true,
    filter: { type: TableFilterTypeEnum.Text },
  },
  {
    dataField: 'pricing_model',
    text: 'Pricing model',
    minWidth: 200,
    formatter: (_, { product }) =>
      getPricingModelDisplayName(product.pricing_model),
  },
  {
    dataField: 'amount_in_scu',
    text: 'Price',
    sort: true,
    minWidth: 200,
    maxWidth: 200,
    formatter: (_, subscription) => {
      let basePrice;

      const price = getProductPriceDisplayText({
        pricingModel: subscription.product.pricing_model,
        currency: subscription.currency,
        usageBasedPriceInScu: subscription.usage_based_price_in_scu,
        usageBasedUnitName: subscription.product.usage_based_unit_name,
        amountInScu: subscription.amount_in_scu,
        paymentInterval: subscription.payment_interval,
      });

      // If the subscription is usage-based, we need to display the base price,
      // and the base price is the `amount_in_scu`
      if (
        subscription.product.pricing_model === ProductPricingModel.UsageBased &&
        subscription.amount_in_scu
      ) {
        basePrice = formatPrice({
          value: subscription.amount_in_scu,
          currencyCode: subscription.currency,
        });
      }

      return (
        <Group spacing="xs">
          {basePrice && <Text>{basePrice} / month +</Text>}
          <Text>{price}</Text>
        </Group>
      );
    },
  },
  {
    dataField: 'latest_invoice.status',
    text: 'Last invoice status',
    sort: true,
    filter: {
      type: TableFilterTypeEnum.Select,
      options: {
        pending: 'Pending',
        paid: 'Paid',
        failed: 'Payment failed',
        void: 'Void',
        refunded: 'Payment Refunded',
      },
    },
    formatter: (_, { latest_invoice }) => (
      <PartnerInvoicePaymentStatusBadge status={latest_invoice?.status} />
    ),
  },
  {
    dataField: 'created_at',
    text: 'Date placed',
    minWidth: 250,
    sort: true,
    filter: { type: TableFilterTypeEnum.Date },
    formatter: (_, { created_at }) => <DateCell date={created_at} />,
  },
  {
    dataField: 'next_charge_at',
    text: 'Next billing date',
    minWidth: 250,
    sort: true,
    filter: { type: TableFilterTypeEnum.Date },
    formatter: (_, { next_charge_at }) =>
      mediumDateFormatter(next_charge_at, 'N/A'),
  },
];

interface SubscriptionsTableProps {
  statuses: SubscriptionStatus[];
}
export function SubscriptionsTable({ statuses }: SubscriptionsTableProps) {
  return (
    <PaginatedTable<SubscriptionSummaryType>
      noHeader
      keyField="id"
      name="partners.subscriptions"
      columns={columns}
      dataHook={useSubscriptions}
      dataHookUrl={buildDataHookUrl(statuses)}
      noDataIndication={{ title: 'No subscriptions' }}
      defaultSortBy={[{ id: 'created_at', desc: true }]}
      detailsPanel={{
        type: 'page',
        renderer: ({ row, onClose }) => (
          <SubscriptionDetailsPanel
            subscriptionId={row.original.id}
            onClose={onClose}
          />
        ),
      }}
    />
  );
}

function buildDataHookUrl(statuses: SubscriptionStatus[]) {
  const queryString = statuses
    .map((status) => `q[status_in][]=${status}`)
    .join('&');

  return `${SUBSCRIPTIONS_API_URL}?${queryString}`;
}
