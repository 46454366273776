import React from 'react';

import { StoreListing } from '@portals/api/partners';
import { ReactComponent as DirectInbox } from '@portals/icons/linear/direct-inbox.svg';
import { ReactComponent as Edit } from '@portals/icons/linear/edit.svg';
import { ReactComponent as Shop } from '@portals/icons/linear/shop.svg';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import { RowMenuItem, RowMenuItems } from '@portals/table';
import { RowMenuWrapperType } from '@portals/types';

import {
  canArchiveStoreListing,
  canDeleteStoreListing,
  canPublishStoreListing,
} from '../store-listings-page.utils';

interface StoreListingsTableRowMenuItemsProps {
  wrapperProps: RowMenuWrapperType;
  storeListing: StoreListing;
  onArchive: (storeListing: StoreListing) => void;
  onDelete: (storeListing: StoreListing) => void;
  onEdit: (storeListing: StoreListing) => void;
  onPublish: (storeListing: StoreListing) => void;
}

export function StoreListingsTableRowMenuItems({
  wrapperProps,
  storeListing,
  onArchive,
  onDelete,
  onEdit,
  onPublish,
}: StoreListingsTableRowMenuItemsProps) {
  const items: RowMenuItem[] = [
    {
      id: 'edit',
      label: 'Edit',
      Icon: Edit,
      onClick: () => {
        wrapperProps.menuRef?.onClose();
        onEdit(storeListing);
      },
    },
  ];

  if (canPublishStoreListing(storeListing)) {
    items.push({
      id: 'publish',
      label: 'Publish',
      Icon: Shop,
      onClick: () => {
        wrapperProps.menuRef?.onClose();
        onPublish(storeListing);
      },
    });
  }

  if (canArchiveStoreListing(storeListing)) {
    items.push({
      id: 'archive',
      label: 'Archive',
      Icon: DirectInbox,
      onClick: () => {
        wrapperProps.menuRef?.onClose();
        onArchive(storeListing);
      },
    });
  }

  if (canDeleteStoreListing(storeListing)) {
    items.push({
      id: 'delete',
      label: 'Delete',
      Icon: Trash,
      color: 'red',
      onClick: () => {
        wrapperProps.menuRef?.onClose();
        onDelete(storeListing);
      },
    });
  }

  return <RowMenuItems items={items} wrapperProps={wrapperProps} />;
}
