import {
  ActionIcon,
  Anchor,
  createStyles,
  Group,
  Stack,
  Switch,
  SwitchProps,
  Text,
  Textarea,
  TextareaProps,
  TextInput,
  Tooltip,
} from '@mantine/core';
import React from 'react';

import { useRequestPlanUpgrade } from '@portals/api/partners';
// import { useConfirmNavigation } from '@portals/framework';
import { UpgradeTierBadgeWithText } from '@portals/framework';
import { ReactComponent as RotateLeft } from '@portals/icons/linear/rotate-left.svg';
import { ReactComponent as FacebookIcon } from '@portals/icons/social/facebook.svg';
import { ReactComponent as InstagramIcon } from '@portals/icons/social/instagram.svg';
import { ReactComponent as LinkedinIcon } from '@portals/icons/social/linkedin.svg';
import { ReactComponent as TwitterIcon } from '@portals/icons/social/twitter.svg';

import { SocialCard } from './SocialCard';
import { useIsPricingPlanFeatureEnabled } from '../../../hooks/partner-config';
import { BetaBadge } from '../common/BetaBadge';
import { DesignColorInput } from '../common/fields/ColorField';
import { useDesignField } from '../common/fields/hooks';
import { DesignImageInput } from '../common/fields/ImageField';
import { SidebarTabPanel } from '../SidebarTabPanel';

export function EmailSidebar() {
  const { classes } = useStyles();

  const footerLabel = useDesignField('email_settings.markdown');
  const siteUrlField = useDesignField('email_settings.site_url');
  const customFromEmailField = useDesignField(
    'email_settings.custom_from_email'
  );

  const facebookHref = useDesignField('email_settings.facebook_href');
  const facebookIsActive = useDesignField('email_settings.facebook_is_active');
  const twitterHref = useDesignField('email_settings.twitter_href');
  const twitterIsActive = useDesignField('email_settings.twitter_is_active');
  const linkedinHref = useDesignField('email_settings.linkedin_href');
  const linkedinIsActive = useDesignField('email_settings.linkedin_is_active');
  const instagramHref = useDesignField('email_settings.instagram_href');
  const instagramIsActive = useDesignField(
    'email_settings.instagram_is_active'
  );

  const requestPlanUpgrade = useRequestPlanUpgrade();

  const isEmailBrandingEnabled =
    useIsPricingPlanFeatureEnabled('branded_emails');

  // useConfirmNavigation({
  //   isActive: !isPristine,
  //   confirmationModal: {
  //     title: 'Leave page without saving?',
  //     description:
  //       'You are about to leave the white-label progress and discard all your work. Are you sure?',
  //     confirmationLabel: 'Discard and Leave',
  //     cancelLabel: 'Keep Editing',
  //   },
  //   onConfirm: revertChanges,
  // });

  return (
    <SidebarTabPanel>
      <SidebarTabPanel.Header>
        <Group position="apart">
          <span>Email</span>
          {isEmailBrandingEnabled ? (
            <BetaBadge />
          ) : (
            <UpgradeTierBadgeWithText
              message={
                <Text size="xs">
                  This feature is not included in your current tier, please
                  upgrade to activate.
                </Text>
              }
              onUpgrade={() =>
                requestPlanUpgrade.mutate(
                  `Partners > Branding > Email > Upgrade to premium plan`
                )
              }
            >
              Upgrade
            </UpgradeTierBadgeWithText>
          )}
        </Group>
      </SidebarTabPanel.Header>

      <SidebarTabPanel.Body>
        <Stack spacing="xl">
          <Stack>
            <Text className={classes.sectionTitle}>General</Text>

            <Switch
              labelPosition="left"
              label="Use branded sender name"
              styles={switchStyles}
              onClick={(e) =>
                customFromEmailField.onChange(e.currentTarget.checked)
              }
              checked={customFromEmailField.value}
              disabled={!isEmailBrandingEnabled}
            />
          </Stack>

          <Stack>
            <Text className={classes.sectionTitle}>Design</Text>

            <DesignColorInput
              disabled={!isEmailBrandingEnabled}
              label="Primary Color"
              valuePath="email_settings.primary_color"
            />

            <DesignImageInput
              isRemovable={false}
              disabled={!isEmailBrandingEnabled}
              valuePath="email_settings.logo"
              field={{
                title: 'Logo',
                name: 'logo',
                cropConfig: {
                  aspectRatio: 1,
                  height: 200,
                  width: 200,
                },
                previewConfig: {
                  width: 200,
                  height: 200,
                },
                height: 200,
              }}
            />

            <TextInput
              disabled={!isEmailBrandingEnabled}
              label="Link associated with logo"
              value={siteUrlField.value || siteUrlField.defaultValue}
              onChange={(event) => siteUrlField.onChange(event.target.value)}
            />
          </Stack>

          <Stack>
            <Text className={classes.sectionTitle}>Footer</Text>

            <Textarea
              disabled={!isEmailBrandingEnabled}
              styles={textareaStyles}
              minRows={3}
              value={footerLabel.value}
              onChange={(event) => footerLabel.onChange(event.target.value)}
              description={
                <Text ta="end">
                  Supports{' '}
                  <Anchor
                    href="https://www.markdownguide.org/basic-syntax/"
                    target="_blank"
                  >
                    Markdown
                  </Anchor>
                </Text>
              }
              rightSection={
                footerLabel.value === footerLabel.defaultValue ? null : (
                  <Tooltip label="Reset to default">
                    <ActionIcon
                      onClick={() =>
                        footerLabel.onChange(footerLabel.defaultValue)
                      }
                    >
                      <RotateLeft width={16} height={16} />
                    </ActionIcon>
                  </Tooltip>
                )
              }
            />
          </Stack>

          <Stack>
            <Text className={classes.sectionTitle}>Social</Text>

            <SocialCard
              label="Facebook"
              placeholder="https://www.facebook.com"
              icon={<FacebookIcon />}
              isActive={facebookIsActive.value}
              onIsActiveChange={facebookIsActive.onChange}
              hrefValue={facebookHref.value}
              onHrefChange={facebookHref.onChange}
              disabled={!isEmailBrandingEnabled}
            />

            <SocialCard
              label="X"
              placeholder="https://x.com"
              icon={<TwitterIcon />}
              isActive={twitterIsActive.value}
              onIsActiveChange={twitterIsActive.onChange}
              hrefValue={twitterHref.value}
              onHrefChange={twitterHref.onChange}
              disabled={!isEmailBrandingEnabled}
            />

            <SocialCard
              label="Instagram"
              placeholder="https://www.instagram.com"
              icon={<InstagramIcon />}
              isActive={instagramIsActive.value}
              onIsActiveChange={instagramIsActive.onChange}
              hrefValue={instagramHref.value}
              onHrefChange={instagramHref.onChange}
              disabled={!isEmailBrandingEnabled}
            />

            <SocialCard
              label="Linkedin"
              placeholder="https://www.linkedin.com"
              icon={<LinkedinIcon />}
              isActive={linkedinIsActive.value}
              onIsActiveChange={linkedinIsActive.onChange}
              hrefValue={linkedinHref.value}
              onHrefChange={linkedinHref.onChange}
              disabled={!isEmailBrandingEnabled}
            />
          </Stack>
        </Stack>
      </SidebarTabPanel.Body>
    </SidebarTabPanel>
  );
}

const useStyles = createStyles((theme) => ({
  sectionTitle: {
    fontSize: theme.fontSizes.lg,
    fontWeight: 500,
    color: theme.colors.blue_gray[9],
  },
}));

const textareaStyles: TextareaProps['styles'] = (theme) => ({
  rightSection: {
    paddingTop: theme.spacing.xs,
    alignItems: 'flex-start',
  },
});

const switchStyles: SwitchProps['styles'] = {
  body: {
    justifyContent: 'space-between',
  },
};
