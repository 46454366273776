import { Loader, LoadingOverlay } from '@mantine/core';
import React from 'react';
import { connect } from 'react-redux';
import { useEffectOnce, useInterval } from 'react-use';
import styled from 'styled-components';

import { useIsPending } from '@portals/redux';

import DevicesByModel from './DevicesByModel';
import DevicesByOrg from './DevicesByOrg';
import DevicesFunnel from './DevicesFunnel';
import RegisteredDevices from './RegisteredDevices';
import StatsCards from './StatsCards';
import { fetchStats } from '../../redux/actions/api';

const UPDATE_PERIOD = 10 * 1000; // 1 minute

const Stats = ({ stats, fetchStats }) => {
  const isFetching = useIsPending('fetchStats');

  useEffectOnce(() => {
    fetchStats();
  });

  useInterval(() => {
    fetchStats();
  }, UPDATE_PERIOD);

  if (!stats) {
    return <LoadingOverlay visible />;
  }

  return (
    <>
      {isFetching ? (
        <Loader sx={{ position: 'absolute', right: 30, top: 48 }} />
      ) : null}

      <StatsCards stats={stats} />

      <DevicesFunnel stats={stats} />

      <RegisteredDevices data={stats.device_chart} />

      <ChartsWrapper>
        <DevicesByModel models={stats.models} offline={stats.models_offline} />
        <DevicesByOrg orgs={stats.devices_by_org} />
      </ChartsWrapper>
    </>
  );
};

const ChartsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 25px;
`;

const mapStateToProps = (state) => ({
  stats: state.data.stats,
});

export default connect(mapStateToProps, { fetchStats })(Stats);
