import React from 'react';
import { useDispatch } from 'react-redux';

import { DeviceModelType, useSupportedCommands } from '@portals/api/partners';
import { CommandButtons } from '@portals/framework';
import { useIsPending } from '@portals/redux';
import { DeviceType, TicketType } from '@portals/types';

import { getTicketDevice } from '../../redux/actions/ticket_device';

interface ActionButtonsProps {
  device: DeviceType;
  model: DeviceModelType;
  ticket: TicketType;
}

export function ActionButtons({ device, ticket, model }: ActionButtonsProps) {
  const dispatch = useDispatch();
  const handleRefresh = () => dispatch(getTicketDevice(ticket.id));
  const isPending = useIsPending(`getTicketDevice_${ticket.id}`);
  const supportedCommands = useSupportedCommands(model.id);

  return (
    <CommandButtons<DeviceType>
      management={false}
      withSupportCenter={false}
      device={device}
      getCommandParams={(command) => ({
        ticketId: ticket.id,
        deviceType: device.partner.type_id,
        command,
        deviceId: device.id,
      })}
      supportedCommands={supportedCommands.data || []}
      updateDeviceData={handleRefresh}
      isPending={isPending || supportedCommands.isInitialLoading}
    />
  );
}
