import { getOr } from 'lodash/fp';
import { connect } from 'react-redux';

import { GeneralView } from '@portals/framework';

import { getTelemetries } from '../../redux/actions/ticket_device';

const mapStateToProps = (state, ownProps) => ({
  telemetries: getOr(
    [],
    ['data', 'telemetries', ownProps.device.id, 'telemetries'],
    state
  ),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getTelemetries: (deviceId, limit) =>
    dispatch(getTelemetries(ownProps.device.id, limit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneralView);
