import { createStyles, Divider, Stack } from '@mantine/core';
import { castArray, isFunction } from 'lodash/fp';
import React from 'react';

import { CategoryRouteItem, CommonConfigurationType } from '@portals/types';

import { SidebarItem } from './SidebarItem';
import { useCommonConfig } from '../../../hooks/portal-config';
import { useHasSupportSeat } from '../../../hooks/support-seats';
import { usePermissionAccess } from '../../permission-access/use-permission-access';

interface SidebarSectionProps<
  TCommonConfigurationType extends CommonConfigurationType
> {
  childRoutes: CategoryRouteItem<TCommonConfigurationType>['childRoutes'];
}

export function SidebarSection<
  TCommonConfigurationType extends CommonConfigurationType
>({ childRoutes }: SidebarSectionProps<TCommonConfigurationType>) {
  const configuration = useCommonConfig();
  const { canView, canEdit, isAdmin } = usePermissionAccess();
  const hasSupportSeat = useHasSupportSeat();
  const { classes } = useStyles();

  // check that there is at least one child route that is visible and accessible
  const shouldRenderSection = childRoutes.some(
    ({ canAccessRoute, isVisibleInSidebar }) => {
      const canAccess =
        canAccessRoute?.({
          configuration: configuration.data as TCommonConfigurationType,
          canView,
          canEdit,
          isAdmin,
          hasSupportSeat,
        }) ?? true;

      const isVisible =
        isVisibleInSidebar?.({
          configuration: configuration.data as TCommonConfigurationType,
        }) ?? true;

      return canAccess && isVisible;
    }
  );

  if (!shouldRenderSection) {
    return null;
  }

  return (
    <Stack className={classes.container} spacing="xs">
      {childRoutes.map((childRoute, index) => {
        const { canAccessRoute, isVisibleInSidebar } = childRoute;

        const canAccess =
          canAccessRoute?.({
            configuration: configuration.data as TCommonConfigurationType,
            canView,
            canEdit,
            isAdmin,
            hasSupportSeat,
          }) ?? true;
        const isVisible =
          isVisibleInSidebar?.({
            configuration: configuration.data as TCommonConfigurationType,
          }) ?? true;

        if (!canAccess || !isVisible) {
          return null;
        }

        const computedPath = isFunction(childRoute.path)
          ? childRoute.path({
              configuration: configuration.data as TCommonConfigurationType,
              isAdmin,
            })
          : childRoute.path;

        const pathsArray = castArray(computedPath);

        return (
          <SidebarItem
            key={childRoute.id}
            indicatorComponent={childRoute.indicatorComponent}
            id={childRoute.id}
            badgeColor={childRoute.badgeColor}
            badgeText={childRoute.badgeText}
            badgeComponent={childRoute.badgeComponent}
            icon={childRoute.icon}
            to={pathsArray}
          />
        );
      })}

      <Divider w="100%" h={1} bg="gray.2" className="divider" />
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    '.sidebar-header': {
      paddingBlock: theme.spacing.md,
      paddingInline: theme.spacing.xxl,
      letterSpacing: '0.04em',
      fontSize: theme.fontSizes.xs,
      fontWeight: 600,
      lineHeight: 1.33,
      transition: 'opacity 0.15s ease-in-out',
      textTransform: 'none',
      display: 'none',
    },

    '.sidebar-divider': {
      borderBottom: `1px solid ${theme.fn.rgba(
        theme.colors.blue_gray[6],
        0.3
      )}`,
      marginBlock: 28,
      marginLeft: theme.spacing.md,
      width: 44,
    },

    '&:last-of-type': {
      '.divider': {
        display: 'none',
      },
    },
  },
}));
