import { createStyles } from '@mantine/core';
import React from 'react';

import { UpgradeRibbonRight } from '@portals/framework';
import { useOpenModal } from '@portals/redux';
import { suppressPropagation } from '@portals/utils';

import { UpgradePricingPlanProps } from '../../modals/UpgradePricingPlan';

interface UpgradePlanBadgeProps {
  source: string;
}

export function UpgradePlanBadge({ source }: UpgradePlanBadgeProps) {
  const { classes } = useStyles();
  const openModal = useOpenModal();

  const onClick = () => {
    openModal<UpgradePricingPlanProps['data']>('UpgradePricingPlan', {
      source,
    });
  };

  return (
    <div className={classes.container} onClick={suppressPropagation(onClick)}>
      <UpgradeRibbonRight className={classes.upgradeBadge} />
    </div>
  );
}

const useStyles = createStyles(() => ({
  container: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  upgradeBadge: {
    position: 'absolute',
    top: 24,
    right: -13,
  },
}));
