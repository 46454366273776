import { Button } from '@mantine/core';
import React from 'react';

import { ModalCenteredMediaLayout } from '@portals/core';

import { ReactComponent as Success } from '../../assets/img/success.svg';
import { ModalProps } from '../../components/Modals';

interface RetryChargeSuccessModalProps extends ModalProps {}

export function RetryChargeSuccessModal({
  closeMe,
}: RetryChargeSuccessModalProps) {
  return (
    <ModalCenteredMediaLayout
      opened
      onClose={closeMe}
      media={<Success />}
      mediaSize={115}
      title="Payment Processed Successfully"
      description={
        "The recent charge on the customer's updated payment information was successful."
      }
      footer={
        <Button onClick={closeMe} w="100%">
          Continue
        </Button>
      }
    />
  );
}
