import { Stack } from '@mantine/core';
import React from 'react';

import { ReactComponent as Flash1 } from '@portals/icons/bold/flash-1.svg';
import { ReactComponent as Book } from '@portals/icons/bulk/book.svg';
import { ReactComponent as Glass1 } from '@portals/icons/bulk/glass-1.svg';

import { InsightItem } from './InsightItem';
import { LoadingInsight } from './LoadingInsight';

interface InsightsListProps {
  isLoading: boolean;
  onSelectInsight: (message: string) => void;
}

export function InsightsList({
  isLoading,
  onSelectInsight,
}: InsightsListProps) {
  if (isLoading) {
    return <LoadingInsight />;
  }

  return (
    <Stack spacing="xs">
      <InsightItem
        title="Diagnose & Troubleshoot"
        description="Identify and troubleshoot issues with this device"
        icon={<Glass1 />}
        onClick={() => onSelectInsight('Diagnose & Troubleshoot')}
      />
      <InsightItem
        title="Optimization"
        description="Help optimizing this device"
        icon={<Flash1 />}
        onClick={() => onSelectInsight('Optimization')}
      />
      <InsightItem
        title="Knowledge Base"
        description="Provide access to a database of articles, tutorials, and other resources that can help the user troubleshoot their device"
        icon={<Book />}
        onClick={() => onSelectInsight('Knowledge Base')}
      />
    </Stack>
  );
}
