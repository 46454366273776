import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';
import { LicenseType, PaginationResponse } from '@portals/types';

import {
  DEVICES_API_URL,
  devicesQueryKeys,
  getDeviceApiUrl,
} from './devices.constants';
import { fetchApiRequest, useRequestOptions } from '../../utils/common';
import { buildUrlFromFilters } from '../../utils/paginated-query';

function getApiUrl(deviceId: string) {
  return `${getDeviceApiUrl(deviceId)}/licenses`;
}

function getDeviceLicenseApiUrl(deviceId: string, licenseId: string) {
  return `${getApiUrl(deviceId)}/${licenseId}`;
}

export function useDeviceLicenses(deviceId: string) {
  const { url, options } = useRequestOptions({ url: getApiUrl(deviceId) });

  const requestUrl = buildUrlFromFilters({
    url,
    pagination: {
      page: 0,
      pageSize: 50,
    },
  });

  return useQuery<
    PaginationResponse<LicenseType>,
    { error: string },
    LicenseType[]
  >({
    queryKey: devicesQueryKeys.licenses.list(deviceId),
    queryFn: () => fetchApiRequest(requestUrl, options),
    select: (response) => response.data,
    staleTime: 0,
    meta: {
      baseUrl: `${DEVICES_API_URL}/:id/licenses`,
      method: 'GET',
    },
  });
}

export function useAssignLicenseToDevice() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: '',
    method: 'PUT',
  });

  return useMutation({
    mutationFn: ({
      deviceId,
      licenseId,
    }: {
      deviceId: string;
      licenseId: string;
    }): Promise<void> => {
      const apiUrl = `${url}${getDeviceLicenseApiUrl(deviceId, licenseId)}`;

      return fetchApiRequest(apiUrl, options);
    },
    onSuccess: (data, variables) => {
      dispatch(toastrSuccess('License assigned successfully'));

      queryClient.invalidateQueries(devicesQueryKeys.licenses.global);
      queryClient.invalidateQueries(
        devicesQueryKeys.details(variables.deviceId)
      );

      queryClient.invalidateQueries(
        devicesQueryKeys.licenses.list(variables.deviceId)
      );
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useAssignLicenseToDevice',
      baseUrl: `${DEVICES_API_URL}/:id/licenses/:id`,
      method: 'PUT',
    },
  });
}

export function useRevokeDeviceLicense() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: '',
    method: 'DELETE',
  });

  return useMutation({
    mutationFn: ({
      deviceId,
      licenseId,
    }: {
      deviceId: string;
      licenseId: string;
    }): Promise<void> => {
      const apiUrl = `${url}${getDeviceLicenseApiUrl(deviceId, licenseId)}`;

      return fetchApiRequest(apiUrl, options);
    },
    onSuccess: (data, variables) => {
      dispatch(toastrSuccess('License revoked successfully'));

      queryClient.invalidateQueries(devicesQueryKeys.licenses.global);
      queryClient.invalidateQueries(
        devicesQueryKeys.details(variables.deviceId)
      );
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useRevokeDeviceLicense',
      baseUrl: `${DEVICES_API_URL}/:id/licenses/:id`,
      method: 'DELETE',
    },
  });
}
