import {
  ActionIcon,
  Button,
  createStyles,
  Group,
  Paper,
  Stack,
  Text,
} from '@mantine/core';
import { compact } from 'lodash/fp';
import React from 'react';

import {
  PartnerStoreSettingsType,
  useStoreSettings,
} from '@portals/api/partners';
import { usePermissionAccess } from '@portals/framework';
import { ReactComponent as Edit } from '@portals/icons/linear/edit.svg';
import { useOpenModal } from '@portals/redux';

import locationSrc from '../../../assets/img/icons/location.svg';

function getFullAddress(storeSettings: PartnerStoreSettingsType | undefined) {
  if (!storeSettings) return;

  return compact([
    storeSettings.address_1,
    storeSettings.address_2,
    storeSettings.address_3,
    storeSettings.city,
    storeSettings.state,
    storeSettings.zip,
    storeSettings.country,
  ]).join(', ');
}

export const Address = () => {
  const { classes } = useStyles();
  const { isAdmin } = usePermissionAccess();

  const openModal = useOpenModal();
  const storeSettings = useStoreSettings();

  const fullAddress = getFullAddress(storeSettings.data);

  return (
    <Paper p="xl" withBorder>
      <Stack spacing="xl">
        <Group position="apart">
          <Text sx={(theme) => ({ color: theme.colors.gray[8] })}>Address</Text>
        </Group>

        {fullAddress ? (
          <Group position="apart" noWrap>
            <Text size="sm" className={classes.fullAddress}>
              {fullAddress}
            </Text>

            <ActionIcon
              disabled={!isAdmin}
              color="blue_gray"
              onClick={() => openModal('AddTaxAddress')}
            >
              <Edit width={18} height={18} />
            </ActionIcon>
          </Group>
        ) : (
          <Group align="center" position="apart">
            <Group spacing="lg" align="center">
              <img src={locationSrc} width={55} height={55} />

              <Text color="blue_gray" size="sm">
                Provider address is required
              </Text>
            </Group>

            <Button
              disabled={!isAdmin}
              onClick={() => openModal('AddTaxAddress')}
            >
              Add Address
            </Button>
          </Group>
        )}
      </Stack>
    </Paper>
  );
};

const useStyles = createStyles((theme) => ({
  fullAddress: {
    color: theme.colors.blue_gray[6],
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));
