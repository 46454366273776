import { ActionIcon, Anchor, Divider, Group, Tooltip } from '@mantine/core';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as Element3 } from '@portals/icons/linear/element-3.svg';
import { ReactComponent as List } from '@portals/icons/linear/list.svg';
import { ReactComponent as Map1 } from '@portals/icons/linear/map-1.svg';

import { ViewType } from './types';

interface CustomersViewHeaderActionsProps {
  selectedView: ViewType;
  setView: (viewType: ViewType) => void;
}

export const CustomersViewHeaderActions: FC<
  CustomersViewHeaderActionsProps
> = ({ selectedView, setView }) => {
  return (
    <Group>
      <Group spacing="xs">
        <Tooltip label="Map view">
          <ActionIcon
            radius="sm"
            size="lg"
            color="gray"
            variant={selectedView === ViewType.Map ? 'light' : 'subtle'}
            onClick={() => setView(ViewType.Map)}
          >
            <Map1 width={16} height={16} />
          </ActionIcon>
        </Tooltip>

        <Tooltip label="List view">
          <ActionIcon
            radius="sm"
            size="lg"
            color="gray"
            variant={selectedView === ViewType.List ? 'light' : 'subtle'}
            onClick={() => setView(ViewType.List)}
          >
            <List width={16} height={16} />
          </ActionIcon>
        </Tooltip>

        <Tooltip label="Grid view">
          <ActionIcon
            radius="sm"
            size="lg"
            color="gray"
            variant={selectedView === ViewType.Tiles ? 'light' : 'subtle'}
            onClick={() => setView(ViewType.Tiles)}
          >
            <Element3 width={16} height={16} />
          </ActionIcon>
        </Tooltip>
      </Group>

      <Divider orientation="vertical" />

      <Anchor component={Link} to="/organizations" size="xs" color="primary">
        View All
      </Anchor>
    </Group>
  );
};
