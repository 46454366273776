import { LoadingOverlay, Stack } from '@mantine/core';
import React from 'react';
import { CardTitle } from 'reactstrap';

import {
  DeviceModelType,
  useDeleteSupportedCommand,
  useMoveSupportedCommand,
  useSupportedCommands,
} from '@portals/api/partners';

import { SupportedCommandsTable } from './SupportedCommandsTable';

type ModelCommandsProps = {
  model: DeviceModelType;
};

export function ModelCommands({ model }: ModelCommandsProps) {
  const supportedCommands = useSupportedCommands(model.id);
  const deleteSupportedCommand = useDeleteSupportedCommand(model.id);
  const moveSupportedCommand = useMoveSupportedCommand(model.id);

  const isLoading =
    supportedCommands.isInitialLoading ||
    deleteSupportedCommand.isLoading ||
    moveSupportedCommand.isLoading;

  return (
    <Stack w="100%" h="100%">
      <CardTitle>
        <h4 className="text-muted">Commands supported by this model</h4>

        <h6 className="card-subtitle text-muted">
          Define the various commands devices of this type can receive from
          users
        </h6>
      </CardTitle>

      {!supportedCommands.isFetched ? (
        <LoadingOverlay visible />
      ) : (
        <SupportedCommandsTable
          isLoading={isLoading}
          model={model}
          commands={supportedCommands.data || []}
          onDelete={deleteSupportedCommand.mutate}
          onMove={moveSupportedCommand.mutate}
        />
      )}
    </Stack>
  );
}
