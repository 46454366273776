import { get, set } from 'lodash/fp';
import { useCallback } from 'react';
import { Paths } from 'type-fest';

import { useBrandingContext } from '../../branding.context';

export type ValuePath = Paths<
  ReturnType<typeof useBrandingContext>['portalConfig']
>;

export function useDesignField(valuePath: ValuePath) {
  const { portalConfig, updatePortalConfig, defaultValues } =
    useBrandingContext();

  const defaultValue = get(valuePath, defaultValues);
  const value = get(valuePath, portalConfig) ?? defaultValue;

  const onChange = useCallback(
    (value: string | boolean | null) =>
      updatePortalConfig(set(valuePath, value)),
    [updatePortalConfig, valuePath]
  );

  return {
    value,
    onChange,
    defaultValue,
  };
}
