import {
  Accordion,
  AccordionProps,
  Alert,
  AlertProps,
  Badge,
  Box,
  Button,
  createStyles,
  Group,
  Modal,
  Stack,
  Text,
} from '@mantine/core';
import { size } from 'lodash/fp';
import React from 'react';

import {
  useDeleteUser,
  UserResponseType,
  useUserAssociationsAndAccess,
} from '@portals/api/ui';
import { NameAbbreviationAvatar } from '@portals/core';
import { ModalProps } from '@portals/framework';
import { ReactComponent as Lifebuoy } from '@portals/icons/linear/lifebuoy.svg';
import { VerticalScrollBar } from '@portals/scrollbar';

export interface DeleteUserModalProps
  extends ModalProps<{
    user: UserResponseType | undefined;
  }> {}

export function DeleteUserModal({ closeMe, data }: DeleteUserModalProps) {
  const { user } = data;
  const { classes } = useStyles();

  const userAssociationsAndAccess = useUserAssociationsAndAccess(user.id);
  const deleteUser = useDeleteUser();

  const onDeleteUser = async () => {
    try {
      await deleteUser.mutateAsync({ userId: user.id });
      closeMe();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      padding={0}
      title="Delete user"
      classNames={{
        content: classes.modalContent,
        body: classes.modalBody,
        header: classes.modalHeader,
      }}
    >
      <Stack spacing="xxl">
        <Stack px="xxl" spacing="xxl">
          <Stack align="center">
            <NameAbbreviationAvatar
              name={user?.name}
              radius={98}
              size={98}
              withToolTip={false}
            />

            <Box ta="center">
              <Text size="xl">{user?.name}</Text>
              <Text color="gray.5">{user?.email}</Text>
            </Box>
          </Stack>

          <Alert color="red" radius="md" styles={alertStyles}>
            Please note, initiating deletion for this user is a permanent,
            irreversible action. This will revoke all their access and
            disassociate them from any linked items. However, the user's
            identification will still be recorded in our logs. Proceed with
            caution and review the details provided before continuing
          </Alert>
        </Stack>

        <VerticalScrollBar
          autoHeight
          renderView={(props) => <Box {...props} px="xxl" />}
        >
          <Accordion
            multiple
            defaultValue={['associated-items', 'access-rights']}
            styles={accordionStyles}
          >
            <Accordion.Item value="associated-items">
              <Accordion.Control>
                <Group spacing="xs">
                  <Text size="md" color="gray.8" weight={600}>
                    Associated Items
                  </Text>
                  <Badge color="gray" radius="xl" p={6}>
                    1
                  </Badge>
                </Group>
              </Accordion.Control>
              <Accordion.Panel>
                <Group spacing="xs">
                  <Lifebuoy width={18} height={18} />
                  <Text color="gray.8">Support seat</Text>
                </Group>
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value="access-rights">
              <Accordion.Control>
                <Group spacing="xs">
                  <Text size="md" color="gray.8" weight={600}>
                    Access rights
                  </Text>
                  <Badge color="gray" radius="xl" p={6}>
                    {size(userAssociationsAndAccess?.data?.organizations)}
                  </Badge>
                </Group>
              </Accordion.Control>
              <Accordion.Panel>
                <Stack spacing="xs">
                  {(userAssociationsAndAccess?.data?.organizations || []).map(
                    (organization) => (
                      <Group key={organization.organization_id}>
                        <NameAbbreviationAvatar
                          name={organization.organization_display_name}
                          radius="xl"
                          src={organization.logo_url}
                        />

                        <Text>{organization.organization_display_name}</Text>
                      </Group>
                    )
                  )}
                </Stack>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </VerticalScrollBar>
      </Stack>

      <Group position="right" className={classes.footer}>
        <Button variant="default" onClick={closeMe}>
          Cancel
        </Button>

        <Button
          color="red"
          data-testid="confirm-delete-user-button"
          onClick={onDeleteUser}
          loading={deleteUser.isLoading}
        >
          Delete User
        </Button>
      </Group>
    </Modal>
  );
}

const alertStyles: AlertProps['styles'] = (theme) => ({
  root: {
    overflow: 'visible',
  },
  message: {
    textAlign: 'center',
    color: theme.colors.gray[8],
  },
});

const accordionStyles: AccordionProps['styles'] = (theme) => ({
  control: {
    zIndex: 1,
    position: 'sticky',
    top: 0,
    backgroundColor: theme.white,
  },
  item: {
    '&:last-child': {
      borderBottom: 'none',
    },
  },
});

const useStyles = createStyles((theme) => ({
  modalContent: {
    minHeight: '80%',
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
  },

  modalBody: {
    overflowY: 'hidden',
    display: 'grid',
    gridTemplateRows: '1fr min-content',
  },

  modalHeader: {
    paddingBlock: theme.spacing.xl,
    paddingInline: theme.spacing.xxl,
  },
  footer: {
    paddingBlock: theme.spacing.xl,
    paddingInline: theme.spacing.xxl,
    borderTop: `1px solid ${theme.colors.gray[3]}`,
  },
}));
