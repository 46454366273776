import { Group, Menu, Text } from '@mantine/core';
import { find } from 'lodash/fp';
import React, { FC } from 'react';

import { MenuItemType } from './types';

interface SortMenuProps {
  showSortBySeverity: boolean;
  onSortByChanged: (sortBy: string) => void;
  items: Array<MenuItemType>;
  selectedItemName: string;
}

export const SortMenu: FC<SortMenuProps> = ({
  showSortBySeverity,
  onSortByChanged,
  items,
  selectedItemName,
}) => {
  const { label, icon: Icon } = find(
    (item) => selectedItemName === item.name,
    items
  );

  return (
    <Menu trigger="click">
      <Menu.Target>
        <Group spacing={4}>
          <Icon style={{ height: 14, width: 14 }} />
          <Text size="xs">{label}</Text>
        </Group>
      </Menu.Target>

      <Menu.Dropdown>
        {items.map((item) => {
          return (
            <Menu.Item
              key={item.name}
              icon={<item.icon width={16} height={16} />}
              sx={(theme) => ({
                display:
                  item.label === 'Severity' && !showSortBySeverity
                    ? 'none'
                    : 'flex',
                color:
                  label === item.label
                    ? theme.other.primaryColor
                    : theme.colors.gray[9],
              })}
              onClick={() => {
                onSortByChanged(item.name);
              }}
            >
              <Text size="sm">{item.label}</Text>
            </Menu.Item>
          );
        })}
      </Menu.Dropdown>
    </Menu>
  );
};
