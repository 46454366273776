import { Box, createStyles, Group, Text, Tooltip } from '@mantine/core';
import dayjs from 'dayjs';
import React from 'react';

import { ReactComponent as InfoCircle } from '@portals/icons/bold/info-circle.svg';
import { ReactComponent as LocationIcon } from '@portals/icons/bold/location.svg';
import { DeviceStatusType } from '@portals/types';

import { WidgetColorType } from '../../widgets.types';

export interface DeviceLocationMarkerProps {
  title: string;
  showLocationName: boolean;
  color: WidgetColorType;
  status: DeviceStatusType | undefined;

  isDeviceOffline?: boolean;
  lastUpdateTimestamp?: string;

  lat?: number;
  lng?: number;
}

export function DeviceLocationMarker({
  title,
  showLocationName,
  color,
  isDeviceOffline,
  lastUpdateTimestamp,
}: DeviceLocationMarkerProps) {
  const { classes } = useStyles();

  return (
    <Tooltip
      disabled={!showLocationName}
      label={<Text size="xs">{title}</Text>}
      px="sm"
      opened={true}
      withArrow
      withinPortal={false}
      bg="blue_gray.9"
      arrowSize={8}
    >
      <Group bg={color} className={classes.marker} grow>
        <Box className={classes.markerWrapper}>
          <LocationIcon color="white" className={classes.markerIcon} />

          {isDeviceOffline ? (
            <Box className={classes.offlineTooltipContainer}>
              <Tooltip
                multiline
                withArrow
                color="blue_gray.9"
                fs="xs"
                classNames={{
                  tooltip: classes.offlineTooltip,
                }}
                label={
                  <Text>
                    This data is based on the last known data from{' '}
                    <b>{`${dayjs(lastUpdateTimestamp).format(
                      'MMMM D, YYYY, hh:mm:ss'
                    )} `}</b>{' '}
                    and may not reflect the current real-time state.
                  </Text>
                }
              >
                <InfoCircle className={classes.infoIcon} />
              </Tooltip>
            </Box>
          ) : null}
        </Box>
      </Group>
    </Tooltip>
  );
}

const useStyles = createStyles((theme) => ({
  markerWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  marker: {
    width: 32,
    height: 32,
    borderRadius: 16,
    position: 'absolute',
    transform: 'translate(-25%, -25%)',
  },
  markerIcon: {
    flexShrink: 0,
  },
  offlineTooltipContainer: {
    position: 'absolute',
    right: 0,
    top: 0,
    transform: 'translate(50%, -50%)',
  },
  offlineTooltip: {
    padding: theme.spacing.sm,
  },
  infoIcon: {
    color: theme.colors.orange[5],
    cursor: 'pointer',
    width: 20,
    height: 20,
    flexShrink: 0,
  },
}));
