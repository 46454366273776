import { createStyles, Stack } from '@mantine/core';
import React from 'react';

import { useDeleteFileInfo } from '@portals/api/partners';
import { DetailsPanel, FileTypeIcon } from '@portals/core';
import {
  DetailsList,
  DetailsListProps,
  useConfirmationModal,
} from '@portals/framework';
import { ReactComponent as Import3 } from '@portals/icons/linear/import-3.svg';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import { useOpenModal } from '@portals/redux';
import { PartnerFileResponseType } from '@portals/types';
import { useDownloadFile } from '@portals/utils';

import { FileUploaderProps } from '../../modals';

interface FileInfoDetailsPanelProps {
  file: PartnerFileResponseType;
  onClose: () => void;
}

export function FileDetailsPanel({ file, onClose }: FileInfoDetailsPanelProps) {
  const { classes } = useStyles();

  const asyncConfirmation = useConfirmationModal();
  const deleteFile = useDeleteFileInfo();

  const downloadFile = useDownloadFile();
  const openModal = useOpenModal();

  const onOpenFile = (fileUrl: string) => {
    window.open(fileUrl, `_blank`, 'noopener noreferrer');
  };

  const onDeleteFile = async () => {
    const isConfirmed = await asyncConfirmation({
      title: 'Delete file?',
      description: `Are you sure you want to delete "${file.name}"`,
    });

    if (isConfirmed) {
      try {
        await deleteFile.mutateAsync(file.id);

        onClose();
      } catch (e) {
        console.error(e);
      }
    }
  };

  const detailsList: DetailsListProps['items'] = [
    {
      label: 'Type',
      value: file.type,
    },
    {
      label: 'Version',
      value: file.version,
    },
    {
      label: 'ID',
      value: file.id,
    },
  ];

  return (
    <DetailsPanel>
      <DetailsPanel.Header onClose={onClose} spacing={0}>
        <Stack spacing="md">
          <FileTypeIcon
            fileUrl={file.url}
            width={70}
            className={classes.fileTypeIcon}
          />

          <DetailsPanel.Title ta="center">{file.name}</DetailsPanel.Title>

          <DetailsPanel.Actions>
            <DetailsPanel.ActionButton
              onClick={onDeleteFile}
              loading={deleteFile.isLoading}
              leftIcon={<Trash />}
            >
              Delete
            </DetailsPanel.ActionButton>

            <DetailsPanel.ActionButton
              loading={downloadFile.isLoading}
              leftIcon={<Import3 />}
              onClick={() => {
                onOpenFile(file.url);
              }}
            >
              View File
            </DetailsPanel.ActionButton>

            <DetailsPanel.ActionButton
              leftIcon={<Import3 />}
              onClick={() => {
                openModal<FileUploaderProps['data']>('FileUploader', {
                  modelId: file.device_model_id,
                  fileId: file.id,
                });
              }}
            >
              Edit File
            </DetailsPanel.ActionButton>
          </DetailsPanel.Actions>
        </Stack>
      </DetailsPanel.Header>

      <DetailsPanel.Body>
        <DetailsPanel.Section title="Description" content={file.desc || '--'} />

        <DetailsPanel.Section
          title="Public notes"
          content={file.public_notes || '--'}
        />

        <DetailsList title="Information" items={detailsList} />
      </DetailsPanel.Body>
    </DetailsPanel>
  );
}

const useStyles = createStyles(() => ({
  fileTypeIcon: {
    alignSelf: 'center',
  },
}));
