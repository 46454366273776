import React, { ReactNode, useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';

import { useFeatureFlags, useIsCecPartner } from '@portals/api/partners';
import { isPartnerFeatureOn, Page404 } from '@portals/framework';

import { AuthPagesPreview } from './auth-pages/AuthPagesPreview';
import { ConnectPreview } from './connect/ConnectPreview';
import {
  CUSTOMER_PORTAL_PATHS,
  CustomerPortalPaths,
} from './customer-portal.constants';
import { DeviceClaimingPreview } from './device-claiming/DeviceClaimingPreview';
import { DevicesPreview } from './devices/DevicesPreview';
import { NavigationMenuPreview } from './navigation-menu/NavigationMenuPreview';
import { PortalDomainPreview } from './portal-domain/PortalDomainPreview';
import { TicketsPreview } from './tickets/TicketsPreview';
import { BrandingEmptyState } from '../BrandingEmptyState';

export function CustomerPortalPreview() {
  const isCecPartner = useIsCecPartner();
  const featureFlags = useFeatureFlags();

  const isCecPreEnrollmentOn = isPartnerFeatureOn(
    featureFlags,
    'cec_pre_enrollment'
  );

  const accessibleRoutes = useMemo(() => {
    const allRoutes: Array<{
      path: CustomerPortalPaths;
      element: ReactNode;
      isAccessible: boolean;
    }> = [
      {
        path: CUSTOMER_PORTAL_PATHS.PortalDomain,
        element: <PortalDomainPreview />,
        isAccessible: true,
      },
      {
        path: CUSTOMER_PORTAL_PATHS.AuthPages,
        element: <AuthPagesPreview />,
        isAccessible: true,
      },
      {
        path: CUSTOMER_PORTAL_PATHS.Connect,
        element: <ConnectPreview />,
        isAccessible: isCecPartner || isCecPreEnrollmentOn,
      },
      {
        path: CUSTOMER_PORTAL_PATHS.DeviceClaiming,
        element: <DeviceClaimingPreview />,
        isAccessible: isCecPartner || isCecPreEnrollmentOn,
      },
      {
        path: CUSTOMER_PORTAL_PATHS.Devices,
        element: <DevicesPreview />,
        isAccessible: isCecPartner || isCecPreEnrollmentOn,
      },
      {
        path: CUSTOMER_PORTAL_PATHS.Tickets,
        element: <TicketsPreview />,
        isAccessible: isCecPartner || isCecPreEnrollmentOn,
      },
      {
        path: CUSTOMER_PORTAL_PATHS.NavigationMenu,
        element: <NavigationMenuPreview />,
        isAccessible: !isCecPartner,
      },
    ];

    return allRoutes.filter((route) => route.isAccessible);
  }, [isCecPartner, isCecPreEnrollmentOn]);

  return (
    <Routes>
      <Route index element={<BrandingEmptyState />} />

      {accessibleRoutes.map((route) => (
        <Route key={route.path} path={route.path} element={route.element} />
      ))}

      <Route path="*" element={<Page404 />} />
    </Routes>
  );
}
