import { useMantineTheme } from '@mantine/core';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import { useRemoveDeviceModelWidget } from '@portals/api/partners';
import { WidgetType } from '@portals/device-widgets';
import { PermissionAccess, useConfirmationModal } from '@portals/framework';
import { ReactComponent as Edit } from '@portals/icons/linear/edit.svg';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import { useOpenModal } from '@portals/redux';
import { AccessLevelEnum } from '@portals/types';
import { getStyledThemeColor, suppressPropagation } from '@portals/utils';

import { useWidgetMeta } from '../../../../../hooks/model-widgets';

interface WidgetPreviewProps extends WidgetType {
  isPlaceholder?: boolean;
  modelId: string;
}

export function LegacyWidgetPreview({
  isPlaceholder,
  ...props
}: WidgetPreviewProps) {
  const { id, name, modelId, config } = props;

  const theme = useMantineTheme();

  const widgetMeta = useWidgetMeta(config.id);
  const removeWidget = useRemoveDeviceModelWidget(modelId);

  const asyncConfirmationCheck = useConfirmationModal();
  const openModal = useOpenModal();

  const onCloseWrapper = useCallback(async () => {
    const isConfirmed = await asyncConfirmationCheck({
      description: `Widget ${name} will be removed. Are you sure?`,
    });

    if (isConfirmed) removeWidget.mutate(id);
  }, [asyncConfirmationCheck, id, name, removeWidget]);

  return (
    <Container isPlaceholder={isPlaceholder}>
      <div className="header">
        <div className="label">{name}</div>

        <PermissionAccess
          minAccessLevel={AccessLevelEnum.Edit}
          permissionKeys="models"
        >
          <div className="actions">
            <div onClick={suppressPropagation(onCloseWrapper)}>
              <Trash style={{ marginRight: theme.spacing.xs }} />
            </div>

            <div
              onClick={suppressPropagation(() =>
                openModal('EditModelWidget', props)
              )}
            >
              <Edit />
            </div>
          </div>
        </PermissionAccess>
      </div>

      <div className="icon-wrapper">
        {widgetMeta?.configIcon ? (
          <div
            className="icon"
            style={{
              backgroundImage: `url(${widgetMeta?.configIcon})`,
            }}
          />
        ) : null}
      </div>
    </Container>
  );
}

const Container = styled.div<{ isPlaceholder?: boolean }>`
  height: 100%;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  color: ${getStyledThemeColor('gray400')};
  border: 1px dashed ${getStyledThemeColor('gray400')};
  transition: color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  position: relative;
  display: grid;
  grid-template-rows: 40px 1fr;

  .header {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${getStyledThemeColor('gray150')};
    padding: 0 15px;
    border-bottom: 1px solid ${getStyledThemeColor('gray300')};
    cursor: grab;

    &:active {
      cursor: grabbing;
    }

    .label {
      color: ${getStyledThemeColor('dark')};
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .actions {
      display: flex;
      align-items: center;

      svg {
        transition: color 0.15s ease-in-out;
        width: 20px;
        height: 20px;

        &:hover {
          cursor: pointer;
          color: ${getStyledThemeColor('gray600')};
        }
      }
    }
  }

  .icon-wrapper {
    padding: 32px;

    .icon {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto;
    }
  }
`;
