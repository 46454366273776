import { Box } from '@mantine/core';
import { find, isEmpty, noop } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  ModalButton,
  PermissionAccess,
  useConfirmationModal,
  usePermissionAccess,
} from '@portals/framework';
import { ReactComponent as AddSquare } from '@portals/icons/linear/add-square.svg';
import { ReactComponent as Danger } from '@portals/icons/linear/danger.svg';
import { ReactComponent as ToggleOffCircle } from '@portals/icons/linear/toggle-off-circle.svg';
import { ReactComponent as ToggleOnCircle } from '@portals/icons/linear/toggle-on-circle.svg';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import {
  BadgeCell,
  BooleanCell,
  RowMenuItems,
  SmartTable,
} from '@portals/table';
import { AccessLevelEnum, RuleType, TableColumn } from '@portals/types';
import { PRIORITY_COLORS, PRIORITY_NAMES } from '@portals/utils';

import RulesSelectedActions from './RulesSelectedActions';
import { deleteRule, updateRule } from '../../redux/actions/rules';

function ModelLink({ rule, device_models }) {
  const deviceModel = find({ id: rule.device_model }, device_models);

  if (!deviceModel) {
    return null;
  }

  return <Link to={`/models/${deviceModel.id}`}>{deviceModel.model}</Link>;
}

function RulesList({
  rules,
  model,
  device_models,
  deleteRule,
  updateRule,
  noHeader = false,
  noFilters = false,
  noSort = false,
  exportParams = { isEnabled: true },
  noColumnsSelection = false,
}) {
  const asyncConfirmationCheck = useConfirmationModal();
  const { canEdit } = usePermissionAccess();

  const handleDelete = useCallback(
    async (rule) => {
      const isConfirmed = await asyncConfirmationCheck({
        description: `Are you sure you want to delete ${rule.name}?`,
      });

      if (isConfirmed) {
        deleteRule(rule.id);
      }
    },
    [asyncConfirmationCheck, deleteRule]
  );

  const columns = useMemo<Array<TableColumn<RuleType>>>(
    () => [
      {
        dataField: 'active',
        text: 'Active',
        minWidth: 70,
        maxWidth: 70,
        formatter: (cell) => <BooleanCell isActive={cell.value} />,
      },
      {
        dataField: 'name',
        text: 'Name',
        formatter: (_, rule) => (
          <Link to={`/rules/${rule.id}`}>{rule.name}</Link>
        ),
      },
      {
        dataField: 'priority',
        text: 'Priority',
        formatter: (_, rule) => (
          <BadgeCell
            label={PRIORITY_NAMES[rule.priority]}
            color={PRIORITY_COLORS[rule.priority]}
          />
        ),
      },
      {
        dataField: 'model',
        text: 'Model',
        formatter: (_, rule) => (
          <ModelLink rule={rule} device_models={device_models} />
        ),
      },
      {
        dataField: 'errors',
        text: 'Errors',
        formatter: (_, rule) => (
          <Box
            sx={{
              color: !isEmpty(rule.errors) ? 'red' : 'unset',
            }}
          >
            {!isEmpty(rule.errors) ? <Danger /> : null}
          </Box>
        ),
      },
    ],
    [device_models]
  );

  return (
    <SmartTable<RuleType>
      readOnly={!canEdit('models')}
      name="partners.rules"
      keyField="id"
      data={rules}
      noFilters={noFilters}
      exportParams={exportParams}
      noColumnsSelection={noColumnsSelection}
      noHeader={noHeader}
      noSort={noSort}
      additionalActions={() =>
        model ? (
          <PermissionAccess
            minAccessLevel={AccessLevelEnum.Edit}
            permissionKeys="models"
          >
            <ModalButton
              modalName="AddRule"
              label="Add New Rule"
              data-testid="add-new-rule-button"
              data={model}
              leftIcon={<AddSquare />}
            />
          </PermissionAccess>
        ) : null
      }
      onSelected={noop}
      selectedItemsActions={() => (
        <RulesSelectedActions onRemove={deleteRule} rules={rules} />
      )}
      columns={columns}
      noDataIndication={{ title: 'No rules' }}
      rowMenu={({ row, wrapperProps }) => (
        <RowMenuItems
          wrapperProps={wrapperProps}
          items={[
            {
              id: 'status',
              Icon: row.original.active ? ToggleOffCircle : ToggleOnCircle,
              label: row.original.active ? 'Deactivate' : 'Activate',
              ...(row.original.active && { color: 'red' }),
              onClick: () => {
                wrapperProps.menuRef?.onClose();
                updateRule(row.original.id, {
                  ...row.original,
                  active: !row.original.active,
                });
              },
            },
            {
              id: 'remove',
              label: 'Remove',
              Icon: Trash,
              color: 'red',
              withTopDivider: true,
              onClick: () => {
                handleDelete(row.original);
                wrapperProps.menuRef?.onClose();
              },
            },
          ]}
        />
      )}
    />
  );
}

export default connect(null, { deleteRule, updateRule })(RulesList);
