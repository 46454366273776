import {
  Box,
  createStyles,
  InputProps,
  NumberInput,
  Overlay,
} from '@mantine/core';
import { useFocusWithin, useHover } from '@mantine/hooks';
import React from 'react';
import { useDragLayer } from 'react-dnd';

import { StoreListing } from '@portals/api/partners';
import { DragCross, ProductCard } from '@portals/framework';

import { StoreListingProductCardPrice } from '../../components/store-listings/StoreListingProductCardPrice';
import { mapStoreListingPricesToPaymentSettings } from '../../utils/store-listings.utils';

interface StoreListingDraggableProductCardProps {
  product: StoreListing['product'] | undefined;
  productImageUrl: string | null | undefined;
  productCategory: string | null | undefined;
  productName: string;
  productSubtitle: string | null | undefined;
  paymentSettings: ReturnType<typeof mapStoreListingPricesToPaymentSettings>;
  position: number;
  storeListingId: string;
  onMove: (currentPosition: number, newPosition: number) => void;
  scrollToProductTop: (childIndex: number) => void;
}

export function StoreListingDraggableProductCard({
  product,
  productImageUrl,
  productName,
  productCategory,
  productSubtitle,
  paymentSettings,
  position,
  storeListingId,
  onMove,
  scrollToProductTop,
}: StoreListingDraggableProductCardProps) {
  const { ref: focusRef, focused } = useFocusWithin();

  const { isDragging } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    isDragging:
      monitor.isDragging() && monitor.getItem()?.id === storeListingId,
    count: monitor.getItem()?.count,
  }));

  const { classes, cx } = useStyles({
    isDragging,
    focused,
  });
  const { hovered, ref } = useHover();

  return (
    <div ref={ref} className={classes.container}>
      <ProductCard className={cx(classes.card)}>
        <ProductCard.Image
          imageUrl={productImageUrl}
          category={productCategory}
          headerSlot={
            position && (
              <Box className={classes.positionIndicator} ref={focusRef}>
                <NumberInput
                  data-testid="product-item-input"
                  hideControls
                  type="number"
                  value={position}
                  styles={inputStyles}
                  onBlur={(event) => {
                    if (event.target.value === '') return;

                    let newPosition = Number(event.target.value) - 1;

                    if (newPosition <= 0) newPosition = 0;

                    //On move is 0 index based, but position is 1 index based
                    onMove(position - 1, newPosition);

                    scrollToProductTop(newPosition);
                  }}
                />
              </Box>
            )
          }
        >
          {hovered && !isDragging ? (
            <Overlay center zIndex={101}>
              <DragCross className={classes.dragIcon} />
            </Overlay>
          ) : null}
        </ProductCard.Image>

        <ProductCard.Details name={productName} subtitle={productSubtitle}>
          <StoreListingProductCardPrice
            showBasePrice
            product={product}
            paymentSettings={paymentSettings}
          />
        </ProductCard.Details>
      </ProductCard>
    </div>
  );
}

const useStyles = createStyles(
  (
    theme,
    params: {
      isDragging: boolean;
      focused: boolean;
    }
  ) => ({
    card: {
      width: '100%',
      filter: 'drop-shadow(0px 11px 18px rgba(6, 72, 105, 0.05))',
      opacity: params.isDragging ? 0 : 1,
      boxShadow: params.focused
        ? '0px 11px 18px rgba(38, 50, 56, 0.1)'
        : 'none',
      height: '100%',
    },

    container: {
      height: '100%',
    },

    positionIndicator: {
      zIndex: 102,
      marginLeft: 'auto',
    },

    dragIcon: {
      width: '40%',
      height: '40%',
      maxWidth: 120,
      maxHeight: 120,
      color: theme.white,
    },
  })
);

const inputStyles: InputProps['styles'] = (theme) => ({
  input: {
    borderRadius: theme.radius.md,
    textAlign: 'center',

    ':focus': {
      borderWidth: 2,
    },
  },

  wrapper: {
    width: 50,
  },
});
