import { ActionIcon, Anchor, Group, Text, Tooltip } from '@mantine/core';
import { noop } from 'lodash/fp';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

import {
  useBulkDeleteDevices,
  useExportDevicesToCsv,
  usePaginatedDevicesTableData,
} from '@portals/api/partners';
import {
  DashboardContentLayout,
  useConfirmationModal,
  useHasSupportSeat,
  usePermissionAccess,
} from '@portals/framework';
import { ReactComponent as CloseSquare } from '@portals/icons/linear/close-square.svg';
import { ReactComponent as Edit } from '@portals/icons/linear/edit.svg';
import { ReactComponent as CsvIcon } from '@portals/icons/linear/import-3.svg';
import { ReactComponent as TickSquare } from '@portals/icons/linear/tick-square.svg';
import { useOpenModal } from '@portals/redux';
import {
  AvatarCell,
  BooleanCell,
  PaginatedTable,
  RowMenuItem,
  RowMenuItems,
} from '@portals/table';
import {
  DeviceType,
  PaginatedTableProps,
  TableColumn,
  TableFilterTypeEnum,
} from '@portals/types';
import { prettyTime } from '@portals/utils';

import { Actions } from './Actions';
import { ClaimDeviceProps } from '../../modals';

export function Devices() {
  const { canEdit, canView } = usePermissionAccess();
  const hasSupportSeat = useHasSupportSeat();
  const openModal = useOpenModal();
  const asyncConfirmation = useConfirmationModal();
  const deleteDevices = useBulkDeleteDevices();

  const exportDevicesToCsv = useExportDevicesToCsv();

  const tableColumns: Array<TableColumn<DeviceType>> = [
    {
      dataField: 'model',
      text: 'Model',
      sort: true,
      filter: { type: TableFilterTypeEnum.Text },
      formatter: (cell, row) =>
        canView(['models']) ? (
          <Anchor to={`/models/${row.device_model_id}`} component={Link}>
            {row.model}
          </Anchor>
        ) : (
          <Text>{row.model}"</Text>
        ),
      isSticky: true,
    },
    {
      dataField: 'sub_model',
      text: 'Sub Model',
      sort: true,
      filter: {
        type: TableFilterTypeEnum.Text,
      },
    },
    {
      dataField: 'mac',
      text: 'Mac',
      sort: true,
      filter: { type: TableFilterTypeEnum.Text },
      hidden: true,
    },
    {
      dataField: 'sn',
      text: 'Serial Number',
      sort: true,
      filter: { type: TableFilterTypeEnum.Text },
    },
    {
      dataField: 'cloud_id',
      text: 'Cloud ID',
      sort: true,
      filter: { type: TableFilterTypeEnum.Text },
    },
    {
      dataField: 'firmware',
      text: 'Firmware Version',
      sort: true,
      filter: { type: TableFilterTypeEnum.Text },
      hidden: true,
      formatter: (_, { firmware }) => firmware,
    },
    {
      dataField: 'created_at',
      text: 'Creation Date',
      sort: true,
      filter: { type: TableFilterTypeEnum.Date },
      formatter: (_, row) => prettyTime(row.created_at),
    },
    {
      dataField: 'claimed',
      text: 'Claimed',
      filter: {
        type: TableFilterTypeEnum.Boolean,
        options: {
          true: 'Claimed',
          false: 'Unclaimed',
          all: 'All',
        },
      },
      formatter: (_, row) => {
        return <BooleanCell isActive={row.claimed} withLabel={false} />;
      },
    },
    {
      dataField: 'organization.display_name',
      text: 'Organization',
      sort: true,
      filter: { type: TableFilterTypeEnum.Text },
      formatter: (_, row) =>
        row?.organization?.name ? (
          <AvatarCell
            src={row?.organization?.logo_url}
            label={row?.organization?.name}
            radius="md"
          />
        ) : null,
    },
    {
      dataField: 'note',
      text: 'Notes',
      sort: true,
      filter: { type: TableFilterTypeEnum.Text },
      formatter: (cell, row) => (
        <Group position="apart" noWrap pr={30}>
          <div
            className="overflow-ellipsis text-truncate d-inline-block pt-1"
            style={{ maxWidth: 'calc(100% - 30px)' }}
          >
            {row.note}
          </div>

          {(canEdit('fulfillment') || hasSupportSeat) && (
            <Tooltip label="Edit notes">
              <ActionIcon
                size="xs"
                data-testid={`edit-note-button-${row.model}`}
                variant="transparent"
                sx={{ marginTop: 4 }}
                onClick={() => openModal('DeviceNote', { device: row })}
              >
                <Edit />
              </ActionIcon>
            </Tooltip>
          )}
        </Group>
      ),
    },
  ];

  const handleDeviceDelete = useCallback(
    async (
      device_id: string,
      menuRef: { onClose: () => void; onOpen?: () => void }
    ) => {
      menuRef?.onClose();

      const isConfirmed = await asyncConfirmation({
        title: 'Delete Device',
        description: 'Are you sure you want to delete this device?',
      });

      if (isConfirmed) {
        try {
          await deleteDevices.mutateAsync({ device_ids: [device_id] });
        } catch (err) {
          console.error(err);
        }
      }
    },
    [asyncConfirmation, deleteDevices]
  );

  const canRemoveDevice = canEdit('models') || hasSupportSeat;
  const canAssignDevice = canEdit('fulfillment');

  const getRowMenu: PaginatedTableProps<DeviceType, 'id'>['rowMenu'] =
    useCallback(
      ({ row, wrapperProps }) => {
        if (!canRemoveDevice && !canAssignDevice) return null;

        const items: RowMenuItem[] = [];

        if (canAssignDevice && !row.original.claimed) {
          items.push({
            id: 'assign',
            label: 'Assign',
            onClick: () => {
              wrapperProps.menuRef?.onClose();
              openModal<ClaimDeviceProps['data']>('ClaimDevice', {
                devices: [row.original],
              });
            },
            Icon: TickSquare,
          });
        }

        if (canRemoveDevice) {
          items.push({
            id: 'delete',
            label: 'Delete',
            color: 'red',
            onClick: async () => {
              await handleDeviceDelete(row.original.id, wrapperProps.menuRef);
            },
            Icon: CloseSquare,
          });
        }

        return <RowMenuItems wrapperProps={wrapperProps} items={items} />;
      },
      [canAssignDevice, canRemoveDevice, openModal, handleDeviceDelete]
    );

  return (
    <DashboardContentLayout pageTitle="Devices" subtitle="All partner devices">
      <PaginatedTable<DeviceType>
        columns={tableColumns}
        additionalActions={() => (
          <ActionIcon onClick={() => exportDevicesToCsv.mutate()}>
            <CsvIcon />
          </ActionIcon>
        )}
        onSelected={noop}
        selectedItemsActions={() => <Actions />}
        dataHook={usePaginatedDevicesTableData}
        noDataIndication={{ title: 'No devices' }}
        defaultSortBy={[{ id: 'created_at', desc: true }]}
        keyField="id"
        name="partners.devices"
        rowMenu={getRowMenu}
      />
    </DashboardContentLayout>
  );
}
