import React from 'react';

import { DesignImageInput } from '../../common/fields/ImageField';

export function DevicesForm() {
  return (
    <DesignImageInput
      valuePath="design.cec_devices.logo"
      field={{
        title: 'Logo',
        name: 'logo',
        cropConfig: {
          aspectRatio: 2.5,
          width: 200,
          height: 80,
        },
        previewConfig: {
          width: 200,
          height: 80,
        },

        height: 200,
      }}
    />
  );
}
