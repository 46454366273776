import { Button } from '@mantine/core';
import { forEach, keys } from 'lodash/fp';
import React from 'react';

import { useDeleteFileInfo } from '@portals/api/partners';
import { useConfirmationModal } from '@portals/framework';
import { ReactComponent as CloseSquare } from '@portals/icons/linear/close-square.svg';
import { useTableInstance } from '@portals/table';
import { PartnerFileResponseType } from '@portals/types';

const EditGroupSelectedActions = () => {
  const asyncConfirmationCheck = useConfirmationModal();
  const { state, toggleAllRowsSelected, rows } =
    useTableInstance<PartnerFileResponseType>();
  const { selectedRowIds } = state;
  const deleteFileInfo = useDeleteFileInfo();

  const onRemoveSelected = async () => {
    const isConfirmed = await asyncConfirmationCheck({
      description: 'Are you sure?',
    });

    if (isConfirmed) {
      forEach((rowIndex) => {
        const fileId = rows[Number(rowIndex)]?.original.id;

        if (fileId) {
          deleteFileInfo.mutate(fileId);
        }
      }, keys(selectedRowIds));

      toggleAllRowsSelected(false);
    }
  };

  return (
    <Button
      variant="subtle"
      color="red"
      size="xs"
      px={5}
      styles={{
        leftIcon: {
          width: 16,
          height: 16,
        },
      }}
      onClick={onRemoveSelected}
      leftIcon={<CloseSquare />}
    >
      Remove
    </Button>
  );
};

export default EditGroupSelectedActions;
