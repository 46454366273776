import { Anchor, Badge, Stack } from '@mantine/core';
import React from 'react';

import {
  useAssignSupportSeatToUser,
  useCurrentUser,
  useResendWelcomeEmail,
  useRevokeUserSupportSeat,
  UserResponseType,
} from '@portals/api/ui';
import { DetailsPanel, NameAbbreviationAvatar } from '@portals/core';
import { DetailsList, usePermissionAccess } from '@portals/framework';
import { ReactComponent as SmsTracking } from '@portals/icons/linear/sms-tracking.svg';
import { prettyTime } from '@portals/utils';

interface UsersTableDetailsPanelProps {
  user: UserResponseType;
  onDeleteUser: (user: UserResponseType) => void;
  onClose: () => void;
}

export function UsersTableDetailsPanel({
  user,
  onDeleteUser,
  onClose,
}: UsersTableDetailsPanelProps) {
  const currentUser = useCurrentUser();

  const resendWelcomeEmail = useResendWelcomeEmail();
  const assignSupportSeatToUser = useAssignSupportSeatToUser();
  const revokeUserSupportSeat = useRevokeUserSupportSeat();
  const { isAdmin } = usePermissionAccess();

  const allowDelete =
    isAdmin && !user.deactivated_at && currentUser.data?.id !== user.id;

  const onToggleUserSupportSeat = () => {
    if (user.has_support_seat) {
      revokeUserSupportSeat.mutate({ userId: user.id });
    } else {
      assignSupportSeatToUser.mutate({ userId: user.id });
    }
  };

  return (
    <DetailsPanel>
      <DetailsPanel.Header onClose={onClose} spacing={0}>
        <Stack align="center">
          <NameAbbreviationAvatar
            name={user.name}
            radius={98}
            size={98}
            withToolTip={false}
          />
          <DetailsPanel.Title size={24}>{user.name}</DetailsPanel.Title>

          {user.has_support_seat ? (
            <Badge
              bg="purple.0"
              radius="sm"
              color="gray.9"
              fw="400"
              sx={{ textTransform: 'none' }}
            >
              Support seat
            </Badge>
          ) : null}

          {user.deactivated_at ? (
            <Badge radius="md" color="blue_gray" fw="400" size="lg">
              Deleted user
            </Badge>
          ) : null}
        </Stack>

        <DetailsPanel.Actions mt="xxl">
          <DetailsPanel.ActionButton
            onClick={onToggleUserSupportSeat}
            loading={
              assignSupportSeatToUser.isLoading ||
              revokeUserSupportSeat.isLoading
            }
            size="md"
          >
            {user.has_support_seat
              ? 'Revoke support seat'
              : 'Assign support seat'}
          </DetailsPanel.ActionButton>

          {allowDelete && (
            <DetailsPanel.ActionButton
              size="md"
              color="red"
              variant="outline"
              onClick={() => onDeleteUser(user)}
            >
              Delete user
            </DetailsPanel.ActionButton>
          )}
        </DetailsPanel.Actions>
      </DetailsPanel.Header>

      <DetailsPanel.Body>
        <DetailsList
          items={[
            {
              label: 'Email',
              value: (
                <Anchor href={`mailto:${user.email}`}>{user.email}</Anchor>
              ),
            },
            {
              label: 'Partner',
              value: user.partner_display_name ?? '--',
            },
            {
              label: 'Organization',
              value: user.organization_display_name ?? '--',
            },
          ]}
        />

        <DetailsList
          title="Login Details"
          items={[
            {
              label: 'Last seen',
              value: user.last_seen_at
                ? prettyTime(user.last_seen_at)
                : 'Never',
            },
            {
              label: 'Login count',
              value: user.sign_in_count,
            },
            {
              label: 'Last login IP',
              value: user.last_ip ?? '--',
            },
          ]}
        />
      </DetailsPanel.Body>

      {!user.last_sign_in_at && (
        <DetailsPanel.Footer>
          <DetailsPanel.Actions>
            <DetailsPanel.ActionButton
              leftIcon={<SmsTracking />}
              loading={resendWelcomeEmail.isLoading}
              onClick={() => resendWelcomeEmail.mutate({ userId: user.id })}
            >
              Resend Signup Email
            </DetailsPanel.ActionButton>
          </DetailsPanel.Actions>
        </DetailsPanel.Footer>
      )}
    </DetailsPanel>
  );
}
