import { createStyles } from '@mantine/core';
import { motion } from 'framer-motion';
import React, { ReactNode } from 'react';

interface MainProps {
  children: ReactNode;
}

export function Main({ children }: MainProps) {
  const { classes, cx } = useStyles();

  return (
    <motion.div layout className={cx(classes.main, 'page-main')}>
      {children}
    </motion.div>
  );
}

const useStyles = createStyles((theme) => ({
  main: {
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
    backgroundColor: theme.white,
    letterSpacing: '0.02em',

    display: 'grid',
    gridTemplateRows: `${theme.other.navbarHeight}px 1fr`,
  },
}));
