import { Anchor, createStyles, Group, Stack, Text } from '@mantine/core';
import React from 'react';

import { useOpenModal } from '@portals/redux';

import { CardContainer, MainContentCardTitle } from './common';
import { ReactComponent as OrbitCloud } from '../../assets/img/orbit-cloud.svg';
import { ReactComponent as PotionFlask } from '../../assets/img/potion-flask.svg';
import { RedirectToCustomerPortalButton } from '../../components/RedirectToCustomerPortalButton';

interface NextActionCardProps {
  hasDeviceTelemetries: boolean;
  labOrganizationId: string | undefined;
  modelId: string;
}

export function NextActionCard({
  labOrganizationId,
  hasDeviceTelemetries,
  modelId,
}: NextActionCardProps) {
  const { classes } = useStyles();
  const openModal = useOpenModal();

  let title;
  let description;
  let link;
  let illustration;

  if (hasDeviceTelemetries) {
    title = 'Visit Your Lab';
    description =
      'Before inviting customers, use your lab to claim your first device and test your portal';
    illustration = <PotionFlask />;

    link = (
      <RedirectToCustomerPortalButton
        customerId={labOrganizationId}
        styles={{
          root: { height: 'auto', padding: 0 },
          label: { fontWeight: 700 },
        }}
      >
        Test Client Portal
      </RedirectToCustomerPortalButton>
    );
  } else {
    title = 'Connect Your First Device';
    description =
      'Before launching, use this wizard to easily register your first device';
    illustration = <OrbitCloud />;
    link = (
      <Anchor
        size="sm"
        weight={700}
        onClick={() => openModal('ConnectFlow', { modelId: modelId })}
      >
        Let's Start
      </Anchor>
    );
  }

  return (
    <CardContainer>
      <Group spacing="xs" noWrap>
        <Stack spacing={0} align="flex-start">
          <MainContentCardTitle className={classes.title}>
            {title}
          </MainContentCardTitle>
          <Text className={classes.description}>{description}</Text>
          {link}
        </Stack>
        <div className={classes.illustrationWrapper}>{illustration}</div>
      </Group>
    </CardContainer>
  );
}

const useStyles = createStyles((theme) => ({
  title: {
    marginBottom: theme.spacing.xs,

    [theme.fn.largerThan('xl')]: {
      marginBottom: theme.spacing.md,
    },
  },
  description: {
    marginBottom: theme.spacing.md,
    fontSize: theme.fontSizes.xs,
    color: theme.colors.blue_gray[6],

    [theme.fn.largerThan('xl')]: {
      fontSize: theme.fontSizes.md,
    },
  },
  illustrationWrapper: {
    flex: '0 0 73px',

    svg: {
      width: '100%',
    },

    [theme.fn.largerThan('xl')]: {
      flex: '0 0 auto',
    },
  },
}));
