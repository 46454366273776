import {
  Menu,
  Tooltip,
  createStyles,
  ActionIcon,
  SimpleGrid,
  Text,
  Stack,
  UnstyledButton,
  TooltipProps,
} from '@mantine/core';
import React, { ReactNode, useState } from 'react';

import { ReactComponent as QuestionCircleIcon } from '@portals/icons/linear/question-circle.svg';

import { ReactComponent as Book } from '../../../assets/img/icons/success-center/book.svg';
import { ReactComponent as Lifebuoy } from '../../../assets/img/icons/success-center/lifebuoy.svg';
import { ReactComponent as Monitor } from '../../../assets/img/icons/success-center/monitor.svg';
import { ReactComponent as ProfileCircle } from '../../../assets/img/icons/success-center/profile-circle.svg';
import { ReactComponent as VideoSquare } from '../../../assets/img/icons/success-center/video-square.svg';

interface MenuItemType {
  icon: ReactNode;
  label: string;
  href?: string;
}

// @todo: once hubs is no longer behind a FF, we can remove the `isHubsEnabled` prop and all its related code.
// @todo: this file can go into partners app
interface SuccessCenterProps {
  isHubsEnabled?: boolean;
}

export function SuccessCenter({ isHubsEnabled = false }: SuccessCenterProps) {
  const { classes, theme } = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const resourceCenterMenuItems: Array<MenuItemType> = [
    {
      icon: <Book />,
      href: 'https://dev.xyte.io/docs/support-center',
      label: 'Documentation',
    },
    { icon: <VideoSquare />, href: 'https://dev.xyte.io', label: 'Videos' },
    {
      icon: <Monitor />,
      href: 'https://www.xyte.io/webinars',
      label: 'Webinars',
    },
  ];

  const contactUsMenuItems: Array<MenuItemType> = [
    {
      icon: <Lifebuoy />,
      label: 'Technical support',
      href: 'mailto:support@xyte.io',
    },
    {
      icon: <ProfileCircle />,
      label: 'Contact your CSM',
      href: 'mailto:yael@xyte.io',
    },
  ];

  const getActionIconColor = () => {
    if (isHubsEnabled) {
      if (isOpen) {
        return theme.colors.blue_accent[4];
      }

      return theme.colors.gray[4];
    } else {
      if (isOpen) {
        return theme.colors.indigo_accent[2];
      }

      return theme.colors.blue_gray[6];
    }
  };

  return (
    <Menu
      position="bottom-end"
      opened={isOpen}
      onChange={setIsOpen}
      closeOnClickOutside
      offset={isHubsEnabled ? 20 : undefined}
    >
      <Menu.Target>
        <Tooltip
          label="Success center"
          offset={isHubsEnabled ? 20 : undefined}
          styles={isHubsEnabled ? tooltipStyles : undefined}
        >
          <ActionIcon size="lg" variant="transparent">
            <QuestionCircleIcon
              width={24}
              height={24}
              color={getActionIconColor()}
            />
          </ActionIcon>
        </Tooltip>
      </Menu.Target>

      <Menu.Dropdown p="xl">
        <Stack spacing="xs">
          <Text color="gray.8" weight={600}>
            Resource Center
          </Text>

          <SimpleGrid cols={3} spacing="xs">
            {resourceCenterMenuItems.map(({ icon, label, href }) => {
              return (
                <Stack
                  key={label}
                  spacing="xs"
                  align="center"
                  className={classes.menuItem}
                  onClick={() => window.open(href, '_blank')}
                  data-testid={`success-center-${label}`}
                >
                  {icon}
                  <Text size="xs" color="gray.8">
                    {label}
                  </Text>
                </Stack>
              );
            })}
          </SimpleGrid>

          <Text color="gray.8" weight={600}>
            Contact Us
          </Text>

          <SimpleGrid cols={3}>
            {contactUsMenuItems.map(({ icon, label, href }) => {
              return (
                <UnstyledButton
                  key={label}
                  component="a"
                  href={href}
                  className={classes.contactUsButton}
                  data-testid={`success-center-${label}`}
                >
                  <Stack
                    spacing="xs"
                    align="center"
                    className={classes.menuItem}
                  >
                    {icon}
                    <Text size="xs" color="gray.8">
                      {label}
                    </Text>
                  </Stack>
                </UnstyledButton>
              );
            })}
          </SimpleGrid>
        </Stack>
      </Menu.Dropdown>
    </Menu>
  );
}

const useStyles = createStyles((theme) => ({
  menuItem: {
    borderRadius: theme.radius.md,
    cursor: 'pointer',
    paddingBlock: theme.spacing.xxl,
    paddingInline: theme.spacing.md,

    '&:hover': {
      backgroundColor: theme.colors.gray[0],
    },
  },

  contactUsButton: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

const tooltipStyles: TooltipProps['styles'] = (theme) => ({
  tooltip: {
    backgroundColor: theme.colors.gray[9],
    color: theme.colors.gray[4],
  },
});
