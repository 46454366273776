import {
  Anchor,
  Badge,
  BadgeProps,
  Group,
  HoverCard,
  Paper,
  Text,
} from '@mantine/core';
import { uniqBy } from 'lodash';
import { omit, size } from 'lodash/fp';
import React from 'react';
import { Link } from 'react-router-dom';

import {
  Product,
  PRODUCTS_API_BASE_URL,
  useArchiveProduct,
  useDeleteProduct,
  usePaginatedProducts,
} from '@portals/api/partners';
import { useConfirmationModal, usePermissionAccess } from '@portals/framework';
import { ReactComponent as Gallery } from '@portals/icons/bold/gallery.svg';
import { useOpenModal } from '@portals/redux';
import { AvatarCell, DateCell, PaginatedTable } from '@portals/table';
import {
  PaginatedFilterTypeEnum,
  ProductPricingModel,
  ProductTypeEnum,
  TableColumn,
} from '@portals/types';
import { getPricingModelDisplayName } from '@portals/utils';

import { AddNewCatalogProductButton } from './AddNewCatalogProductButton';
import { ProductRowMenu } from './ProductRowMenu';
import { ProductTableDetailsPanel } from './ProductTableDetailsPanel';
import emptyProductCatalogSrc from '../../assets/img/product-catalog-empty-state.svg';
import { ProductWizardModalData } from '../../modals';

const COLUMNS: TableColumn<Product>[] = [
  {
    dataField: 'name',
    text: 'Product name',
    sort: true,
    isSticky: true,
    filter: {
      type: PaginatedFilterTypeEnum.Text,
    },
    formatter: (_, row) => (
      <AvatarCell
        src={row.image_url}
        label={row.name}
        radius="md"
        withAbbreviation={false}
        color="blue_gray"
      >
        <Gallery />
      </AvatarCell>
    ),
  },
  {
    minWidth: 300,
    dataField: 'device_model.name',
    text: 'Device model',
    sort: true,
    filter: {
      type: PaginatedFilterTypeEnum.Text,
    },
    formatter: (_, row) => <ModelsCell product={row} />,
  },
  {
    dataField: 'category',
    text: 'Category',
    sort: true,
    filter: {
      type: PaginatedFilterTypeEnum.Text,
    },
  },
  {
    minWidth: 300,
    dataField: 'pricing_model',
    text: 'Pricing model',
    sort: true,
    filter: {
      type: PaginatedFilterTypeEnum.Select,
      options: {
        [ProductPricingModel.Standard]: 'Standard',
        [ProductPricingModel.UsageBased]: 'Usage Based',
        [ProductPricingModel.Personalized]: 'Personalized',
      },
    },
    formatter: (_, row) => getPricingModelDisplayName(row.pricing_model),
  },
  {
    dataField: 'sku',
    text: 'SKU',
    sort: true,
    filter: {
      type: PaginatedFilterTypeEnum.Text,
    },
  },
  {
    dataField: 'created_at',
    text: 'Date added',
    sort: true,
    filter: {
      type: PaginatedFilterTypeEnum.Date,
    },
    formatter: (_, row) => (
      <DateCell date={row.created_at} withTimeAgo={false} />
    ),
  },
];

interface ProductsCatalogTableProps {
  archivedOnly: boolean;
}

export function ProductsTable({ archivedOnly }: ProductsCatalogTableProps) {
  const { canEdit } = usePermissionAccess();

  const openModal = useOpenModal();

  const deleteProduct = useDeleteProduct();
  const archiveProduct = useArchiveProduct();
  const asyncConfirmation = useConfirmationModal();

  const onEdit = async (product: Product) => {
    if (!product.in_store) {
      openModal<ProductWizardModalData>('ProductWizardModal', {
        intent: 'edit',
        product: product,
      });

      return;
    }

    const isConfirmed = await asyncConfirmation({
      description: (
        <Text>
          This product has associated products in the store.
          <br />
          Editing it will affect its associated products in the store.
          <br />
          <br />
          Are you sure you want to proceed?
        </Text>
      ),
    });

    if (isConfirmed) {
      openModal<ProductWizardModalData>('ProductWizardModal', {
        intent: 'edit',
        product: product,
      });
    }
  };

  const onDuplicate = (product: Product) => {
    const productWithoutId = omit('id', product);

    openModal<ProductWizardModalData>('ProductWizardModal', {
      intent: 'duplicate',
      product: productWithoutId,
    });
  };

  const onDelete = async (productToDelete: Product) => {
    const description = productToDelete.in_store ? (
      <Text>
        This product has associated product listings in the store.
        <br />
        Deleting it will also delete its associated product listings from the
        store.
        <br />
        <br />
        Are you sure you want to proceed?
      </Text>
    ) : (
      <Text>
        Are you sure you want to delete{' '}
        <Text span weight={600}>
          {productToDelete.name}
        </Text>
        ?
      </Text>
    );
    const isConfirmed = await asyncConfirmation({
      title: 'Delete product',
      confirmationLabel: 'Delete',
      description,
    });

    if (isConfirmed) {
      deleteProduct.mutate(productToDelete.id);
    }
  };

  const onArchive = async (product: Product) => {
    const isConfirmed = await asyncConfirmation({
      title: 'Archive product',
      confirmationLabel: 'Archive',
      description: (
        <Text>
          Are you sure you want to archive this product?
          <br />
          Archiving the product will also archive its associated product
          listings, and they will no longer be visible to users.
        </Text>
      ),
    });

    if (isConfirmed) {
      archiveProduct.mutate(product.id);
    }
  };

  return (
    <PaginatedTable<Product>
      readOnly={!canEdit('store_management')}
      exportParams={{ isEnabled: false }}
      keyField="id"
      name="products_catalog"
      columns={COLUMNS}
      dataHook={usePaginatedProducts}
      dataHookUrl={`${PRODUCTS_API_BASE_URL}/?q[archived_eq]=${archivedOnly}`}
      additionalActions={() => <AddNewCatalogProductButton />}
      // onSelected={noop}
      // selectedItemsActions={() => (
      //   <SelectedProductsActions
      //     onEdit={onEdit}
      //     onDuplicate={onDuplicate}
      //     onDelete={onDelete}
      //     onArchive={onArchive}
      //   />
      // )}
      rowMenu={({ row, wrapperProps }) => (
        <ProductRowMenu
          wrapperProps={wrapperProps}
          product={row.original}
          onEdit={onEdit}
          onDuplicate={onDuplicate}
          onDelete={onDelete}
          onArchive={onArchive}
        />
      )}
      noDataIndication={{
        title: archivedOnly
          ? 'No archived products found'
          : 'No products found',
        assetSrc: emptyProductCatalogSrc,
        actions: archivedOnly ? null : <AddNewCatalogProductButton />,
      }}
      detailsPanel={{
        type: 'page',
        width: 'clamp(700px, 50%, 1000px)',
        renderer: ({ row, onClose }) => (
          <ProductTableDetailsPanel
            product={row.original}
            onClose={onClose}
            onEdit={onEdit}
            onDuplicate={onDuplicate}
            onDelete={onDelete}
            onArchive={onArchive}
          />
        ),
      }}
    />
  );
}

interface ModelsCellProps {
  product: Product;
}
function ModelsCell({ product }: ModelsCellProps) {
  if (product.product_type !== ProductTypeEnum.PlatformLicense) {
    return product.device_model ? (
      <ModelBadge
        modelName={product.device_model.name}
        modelId={product.device_model.id}
      />
    ) : null;
  }

  if (size(product.supported_commands) === 1) {
    return (
      <ModelBadge
        modelName={product.supported_commands[0].device_model_name}
        modelId={product.supported_commands[0].device_model_id}
      />
    );
  }

  const uniqCommandsByModelName = uniqBy(
    product.supported_commands,
    'device_model_name'
  );

  return (
    <HoverCard shadow="md" withArrow position="top" withinPortal>
      <HoverCard.Target>
        <Badge py="sm" px="xl" styles={badgeStyles} color="gray">
          {size(uniqCommandsByModelName)} models
        </Badge>
      </HoverCard.Target>

      <HoverCard.Dropdown>
        <Paper maw={250}>
          <Group p="md" spacing="xs">
            {uniqCommandsByModelName.map((command) => (
              <ModelBadge
                modelName={command.device_model_name}
                modelId={command.device_model_id}
                key={command.id}
              />
            ))}
          </Group>
        </Paper>
      </HoverCard.Dropdown>
    </HoverCard>
  );
}

interface ModelBadgeProps {
  modelName: string;
  modelId: string;
}

function ModelBadge({ modelName, modelId }: ModelBadgeProps) {
  return (
    <Anchor
      component={Link}
      to={`/models/${modelId}`}
      color="gray.6"
      underline={false}
    >
      <Badge py="sm" px="xl" styles={badgeStyles} color="gray">
        {modelName}
      </Badge>
    </Anchor>
  );
}

const badgeStyles: BadgeProps['styles'] = (theme) => ({
  inner: {
    fontWeight: 400,
    fontSize: theme.fontSizes.xs,
    color: theme.colors.gray[6],

    ' a': {
      color: theme.colors.gray[6],
    },
  },
});
