import { Badge, createStyles, Group, Text, Tooltip } from '@mantine/core';
import { isEmpty, omit } from 'lodash/fp';
import React from 'react';

import { StoreListing } from '@portals/api/partners';
import { PaymentIntervalEnum, ProductPricingModel } from '@portals/types';
import { formatCurrency } from '@portals/utils';

import { useStoreListingsTableContext } from './StoreListingTableContextProvider';

interface StorePricesCellProps {
  storeListing: StoreListing;
}

export function StorePricesCell({ storeListing }: StorePricesCellProps) {
  const { classes } = useStyles();
  const { currency } = useStoreListingsTableContext();

  const price = storeListing.prices.find(
    (price) => price.currency === currency
  );

  if (!price || isEmpty(omit('currency', price))) {
    return null;
  }

  if (storeListing.product.pricing_model === ProductPricingModel.UsageBased) {
    let usageBased;
    let basePrice;
    let basePriceText;

    if (price.usage_based_price_in_scu) {
      usageBased = formatCurrency(price.usage_based_price_in_scu, currency);
    }

    if (price.monthly_price_in_scu) {
      basePrice = formatCurrency(price.monthly_price_in_scu, currency);
    }

    if (!usageBased && !basePrice) {
      return null;
    }

    if (basePrice) {
      basePriceText = `${basePrice} / month + `;
    }

    return (
      <Badge size="lg" className={classes.badge}>
        {basePriceText}
        {usageBased} / {storeListing.product.usage_based_unit_name}
      </Badge>
    );
  }

  const items: Array<{
    id: PaymentIntervalEnum;
    value: string;
  }> = [];

  if (price.one_time_price_in_scu) {
    const oneTimePrice = formatCurrency(price.one_time_price_in_scu, currency);

    items.push({
      id: PaymentIntervalEnum.OneTime,
      value: oneTimePrice,
    });
  }

  if (price.monthly_price_in_scu) {
    const monthlyPrice = formatCurrency(price.monthly_price_in_scu, currency);

    items.push({
      id: PaymentIntervalEnum.Monthly,
      value: `${monthlyPrice} / month`,
    });
  }

  if (price.yearly_price_in_scu) {
    const yearly = formatCurrency(price.yearly_price_in_scu, currency);

    items.push({
      id: PaymentIntervalEnum.Yearly,
      value: `${yearly} / year`,
    });
  }

  const [firstPrice, ...additionalPrices] = items;

  return (
    <Group pr="xs" spacing="sm">
      <Badge size="lg" className={classes.badge} key={firstPrice?.id}>
        {firstPrice?.value ?? 'N/A'}
      </Badge>

      {additionalPrices?.length ? (
        <Tooltip
          withArrow
          position="bottom"
          label={
            <>
              {additionalPrices.map(({ id, value }) => (
                <Text key={id}>{value}</Text>
              ))}
            </>
          }
        >
          <Badge size="lg" className={classes.badge} mr={15}>
            + {additionalPrices?.length}
          </Badge>
        </Tooltip>
      ) : null}
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  badge: {
    overflow: 'unset',
    textOverflow: 'unset',
    backgroundColor: theme.colors.gray[2],
    color: theme.colors.gray[8],
    textTransform: 'unset',
    fontWeight: 500,
  },
}));
