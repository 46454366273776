import {
  Badge,
  Button,
  createStyles,
  Divider,
  Group,
  LoadingOverlay,
  Paper,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { Prism } from '@mantine/prism';
import React from 'react';

import {
  useCreateWebhookUrl,
  useDeleteWebhookUrl,
  useTestWebhook,
  useUpdateWebhookUrl,
  useWebhook,
  WebhookType,
} from '@portals/api/partners';
import { useConfirmationModal, usePermissionAccess } from '@portals/framework';
import { prettyTime } from '@portals/utils';

import { WebhookMetaType } from './webhooks.types';

type FormValuesType = Omit<WebhookType, 'last_run_at' | 'event'>;

export function Webhook({ event, title, responseSample }: WebhookMetaType) {
  const { classes } = useStyles();
  const { isAdmin } = usePermissionAccess();

  const webhook = useWebhook(event);

  const form = useForm<FormValuesType>({
    initialValues: {
      url: webhook?.url || '',
      secret: webhook?.secret || '',
    },
  });

  const testWebhook = useTestWebhook();
  const createWebhook = useCreateWebhookUrl();
  const updateWebhook = useUpdateWebhookUrl();
  const deleteWebhook = useDeleteWebhookUrl();

  const asyncConfirmationCheck = useConfirmationModal();

  const onRemoveWebhookClick = async () => {
    const isConfirmed = await asyncConfirmationCheck({
      title: `Disconnect "${webhook.event}" webhook`,
      description: 'Are you sure you want to remove this webhook?',
      confirmationLabel: 'Disconnect',
    });

    if (isConfirmed) {
      try {
        deleteWebhook.mutate({
          event,
        });
      } catch (error) {
        console.error(error?.error);
      }
    }
  };

  const onSubmit = (values: FormValuesType) => {
    if (!webhook) {
      createWebhook.mutate({
        event,
        ...values,
      });
    } else {
      updateWebhook.mutate({
        event,
        ...values,
      });
    }
  };

  const onTest = () =>
    testWebhook.mutate({
      event,
      ...form.values,
    });

  const isSubmitDisabled =
    !isAdmin ||
    !form.values.url ||
    !form.values.secret ||
    (webhook &&
      form.values.url === webhook.url &&
      form.values.secret === webhook.secret);

  const isDeleteButtonDisabled = !isAdmin || !webhook;

  return (
    <Paper withBorder p="xl" radius="md" className={classes.container}>
      <LoadingOverlay
        visible={
          createWebhook.isLoading ||
          updateWebhook.isLoading ||
          deleteWebhook.isLoading
        }
      />

      <form onSubmit={form.onSubmit(onSubmit)}>
        <Stack spacing="xl" h="100%" justify="space-between">
          <Stack>
            <Group align="center" mb="lg">
              <Title order={5}>{title}</Title>

              {webhook ? <Badge>Connected</Badge> : null}
            </Group>

            <Stack>
              <Group grow>
                <TextInput
                  label="Webhook URL"
                  disabled={!isAdmin}
                  required
                  {...form.getInputProps('url')}
                />

                <TextInput
                  label="Request secret"
                  disabled={!isAdmin}
                  required
                  {...form.getInputProps('secret')}
                />
              </Group>

              <Group position="apart">
                <Button
                  disabled={!isAdmin || !form.values.url || !form.values.secret}
                  onClick={onTest}
                  variant="default"
                  loading={testWebhook.isLoading}
                >
                  Test
                </Button>

                <Group>
                  <Button disabled={isSubmitDisabled} type="submit">
                    {webhook ? 'Update' : 'Connect'}
                  </Button>

                  {!webhook ? null : (
                    <Button
                      color="red"
                      disabled={isDeleteButtonDisabled}
                      onClick={onRemoveWebhookClick}
                    >
                      Disconnect
                    </Button>
                  )}
                </Group>
              </Group>

              {webhook?.last_run_at ? (
                <Text size="sm" color="gray.6">
                  Last successful run: {prettyTime(webhook.last_run_at)}
                </Text>
              ) : null}
            </Stack>

            <Divider variant="dashed" my="lg" />

            <Stack miw={400}>
              <Title order={6}>Sample payload:</Title>
              <Prism language="json">{responseSample}</Prism>
            </Stack>
          </Stack>
        </Stack>
      </form>
    </Paper>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    position: 'relative',

    form: {
      height: '100%',
    },
  },
  footer: { borderTop: `1px solid ${theme.colors.gray[3]}` },
}));
