import { createStyles, Group, Stack, Text } from '@mantine/core';
import React, { ReactNode } from 'react';

export interface DetailsListProps {
  title?: ReactNode;
  items: Array<{ label: ReactNode; value: ReactNode }>;
}

export function DetailsList({ title, items }: DetailsListProps) {
  const { classes } = useStyles();

  return (
    <Stack data-testid="details-list">
      {title && <Text className={classes.title}>{title}</Text>}

      <Stack spacing="sm">
        {items.map(({ label, value }, index) => (
          <Group key={index} position="apart" align="start">
            <Text inherit className={classes.label}>
              {label}
            </Text>

            <Text
              inherit
              data-testid={`${label}-value`}
              className={classes.value}
            >
              {value}
            </Text>
          </Group>
        ))}
      </Stack>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    fontSize: theme.fontSizes.sm,
  },
  title: {
    fontSize: theme.fontSizes.md,
    fontWeight: 600,
    color: theme.colors.gray[7],
  },
  label: {
    color: theme.colors.gray[5],
  },
  value: {
    color: theme.colors.blue_gray[9],
  },
}));
