import { Box, Stack, Text } from '@mantine/core';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { isEmpty } from 'lodash/fp';
import React, { useState } from 'react';

import { PartnerInvoiceType, useInvoiceTimeline } from '@portals/api/partners';
import { DetailsPanel } from '@portals/core';
import {
  InvoiceAddressDetails,
  InvoiceItemsPanel,
  InvoicePaymentDetails,
  PartnerInvoicePaymentStatusBadge,
  InvoiceTimeline,
  PurchaseOrderPanel,
  ReceiptPanel,
  usePermissionAccess,
} from '@portals/framework';
import { PaymentMethodEnum } from '@portals/types';
import { formatCurrency, formatDateTime } from '@portals/utils';

import { CustomerDetails } from './CustomerDetails';
import { InvoicePanelActions } from './InvoicePanelActions';
import { InvoicePaymentFailed } from './InvoicePaymentFailed';

dayjs.extend(relativeTime);

interface InvoiceDetailsPanelProps {
  invoice: PartnerInvoiceType;
  onClose: () => void;
}

export function InvoiceDetailsPanel({
  invoice,
  onClose,
}: InvoiceDetailsPanelProps) {
  const [isItemsPanelOpen, setIsItemsPanelOpen] = useState(false);
  const [isReceiptPanelOpen, setIsReceiptPanelOpen] = useState(false);
  const [isPurchaseOrderPanelOpen, setIsPurchaseOrderPanelOpen] =
    useState(false);

  const { canEdit } = usePermissionAccess();

  const timelineEvents = useInvoiceTimeline(invoice.id);

  const showPaymentFailed =
    invoice.payment_method === PaymentMethodEnum.CreditCard &&
    invoice.status === 'failed' &&
    canEdit(['finance']);

  const createdAtDate = formatDateTime(
    invoice?.created_at,
    'MMMM D, YYYY h:mm A'
  );

  return (
    <>
      <ReceiptPanel
        isOpen={isReceiptPanelOpen}
        receiptId={invoice.id}
        fileUrl={invoice.invoice.invoice_file_url}
        onClose={() => setIsReceiptPanelOpen(false)}
      />

      <PurchaseOrderPanel
        isOpen={isPurchaseOrderPanelOpen}
        fileUrl={invoice.purchase_order?.file_url}
        orderId={invoice?.order_id}
        onClose={() => setIsPurchaseOrderPanelOpen(false)}
      />

      <InvoiceItemsPanel
        isOpen={isItemsPanelOpen}
        invoiceItems={invoice.items}
        currency={invoice.currency}
        itemsCount={invoice.items_count}
        onClose={() => setIsItemsPanelOpen(false)}
        paymentMethod={invoice.payment_method}
        orderId={invoice.order_id}
      />

      <DetailsPanel pos="relative" bg="white">
        <DetailsPanel.Header
          onClose={onClose}
          spacing="xxl"
          style={{ zIndex: 1 }}
        >
          <Stack align="center">
            <Box>
              <DetailsPanel.Title align="center">
                <Text size="md">{invoice?.order_short_id}</Text>
              </DetailsPanel.Title>

              <Text color="gray.5">{createdAtDate}</Text>
            </Box>

            <Text size={28}>
              {formatCurrency(invoice.amount_in_scu, invoice.currency)}
            </Text>
            <PartnerInvoicePaymentStatusBadge status={invoice.status} />

            {showPaymentFailed ? (
              <InvoicePaymentFailed invoice={invoice} />
            ) : null}
          </Stack>

          <InvoicePanelActions
            invoice={invoice}
            isItemsPanelOpen={isItemsPanelOpen}
            setIsItemsPanelOpen={setIsItemsPanelOpen}
            isReceiptPanelOpen={isReceiptPanelOpen}
            setIsReceiptPanelOpen={setIsReceiptPanelOpen}
          />
        </DetailsPanel.Header>

        <DetailsPanel.Body style={{ zIndex: 0 }}>
          <CustomerDetails invoiceOrganization={invoice?.organization} />

          <InvoicePaymentDetails
            payment_method={invoice?.payment_method}
            currency={invoice?.currency}
            tax={invoice?.tax_in_scu}
            amount={invoice?.amount_in_scu}
            credit_card={invoice?.credit_card}
            onOpenPurchaseOrderPanel={() => setIsPurchaseOrderPanelOpen(true)}
            invoiceFileUploadedAt={invoice?.invoice.invoice_file_uploaded_at}
          />

          {!isEmpty(timelineEvents.data) ? (
            <InvoiceTimeline timelineEvents={timelineEvents.data} />
          ) : null}

          <InvoiceAddressDetails
            country={invoice?.billing_address?.country}
            city={invoice?.billing_address?.city}
            address={invoice?.billing_address?.line_1}
            state={invoice?.billing_address?.state}
            zip={invoice?.billing_address?.zip}
            title="Billing address"
          />

          <InvoiceAddressDetails
            country={invoice?.shipping_address?.country}
            city={invoice?.shipping_address?.city}
            address={invoice?.shipping_address?.line_1}
            state={invoice?.shipping_address?.state}
            zip={invoice?.shipping_address?.zip}
            title="Shipping address"
          />
        </DetailsPanel.Body>
      </DetailsPanel>
    </>
  );
}
