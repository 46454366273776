import { Box, Button, Group, LoadingOverlay } from '@mantine/core';
import { keys, map, noop } from 'lodash/fp';
import React, { useMemo } from 'react';

import {
  ApiKeyType,
  useApiKeys,
  useCreateApiKey,
  useDeleteApiKey,
  useUpdateApiKey,
} from '@portals/api/partners';
import { CopyToClipboard } from '@portals/core';
import { useConfirmationModal } from '@portals/framework';
import { CreateApiKeyProps } from '@portals/framework/modals';
import { ReactComponent as Add } from '@portals/icons/linear/add.svg';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import { useOpenModal } from '@portals/redux';
import {
  DateCell,
  EditableTextCell,
  PaginatedTable,
  RowMenuItems,
  useTableInstance,
} from '@portals/table';
import {
  PaginatedFilterTypeEnum,
  RowMenuRefType,
  RowType,
  TableColumn,
} from '@portals/types';

interface SelectedApiKeyActionsProps {
  onDelete: (apiKeysIds: Array<string>) => void;
}

function SelectedApiKeyActions({ onDelete }: SelectedApiKeyActionsProps) {
  const asyncConfirmationCheck = useConfirmationModal();
  const {
    state: { selectedRowIds },
    rows,
  } = useTableInstance<ApiKeyType>();

  const onDeleteWrapper = async () => {
    const selectedApiKeys = map(
      (rowIndex) => rows[Number(rowIndex)].original.id,
      keys(selectedRowIds)
    );

    const isConfirmed = await asyncConfirmationCheck({
      title: 'Delete API Keys',
      description: 'Are you sure you want to delete the selected API keys?',
    });

    if (isConfirmed) {
      onDelete(selectedApiKeys);
    }
  };

  return (
    <Button
      variant="subtle"
      size="xs"
      px={5}
      color="red"
      styles={{
        leftIcon: {
          width: 16,
          height: 16,
        },
      }}
      onClick={onDeleteWrapper}
      leftIcon={<Trash />}
    >
      Delete
    </Button>
  );
}

export function EditApiKeys() {
  const createApiKey = useCreateApiKey();
  const deleteApiKey = useDeleteApiKey();
  const updateApiKey = useUpdateApiKey();
  const openModal = useOpenModal();
  const asyncConfirmationCheck = useConfirmationModal();

  const columns = useMemo<Array<TableColumn<ApiKeyType>>>(
    () => [
      {
        dataField: 'key',
        text: 'API Key',
        sort: true,
        minWidth: 350,
        maxWidth: 350,
        isSticky: true,
        filter: {
          type: PaginatedFilterTypeEnum.Text,
        },
        formatter: (_, { key }) => (
          <Group position="apart">
            <span>{key}</span>

            <CopyToClipboard value={key} />
          </Group>
        ),
      },
      {
        dataField: 'name',
        text: 'Name',
        sort: true,
        filter: {
          type: PaginatedFilterTypeEnum.Text,
        },
        formatter: (_, { name, id }) => (
          <EditableTextCell
            value={name}
            onChange={(name) => updateApiKey.mutate({ apiKeyId: id, name })}
          />
        ),
      },
      {
        dataField: 'last_used',
        text: 'Last Used',
        sort: true,
        filter: {
          type: PaginatedFilterTypeEnum.Date,
        },
        formatter: (_, { last_used }) =>
          last_used ? (
            <DateCell date={last_used} withTimeAgo={false} />
          ) : (
            'Never'
          ),
      },
      {
        dataField: 'created_at',
        text: 'Created',
        sort: true,
        filter: {
          type: PaginatedFilterTypeEnum.Date,
        },
        formatter: (_, { created_at }) => (
          <DateCell date={created_at} withTimeAgo={false} />
        ),
      },
    ],
    [updateApiKey]
  );

  const onDeleteApiKeys = async (apiKeysIds: Array<string>) => {
    await Promise.all(
      map((apiKeyId) => deleteApiKey.mutateAsync({ apiKeyId }), apiKeysIds)
    );
  };

  const onDeleteApiKey = async (
    row: RowType<ApiKeyType>,
    menuRef: RowMenuRefType
  ) => {
    menuRef.onClose();

    const isConfirmed = await asyncConfirmationCheck({
      title: 'Delete API Key',
      description: `Are you sure you want to delete "${row.original.name}" API key?`,
    });

    if (isConfirmed) {
      deleteApiKey.mutateAsync({ apiKeyId: row.original.id });
    }
  };

  return (
    <Box h="100%">
      <LoadingOverlay
        visible={deleteApiKey.isLoading || updateApiKey.isLoading}
      />

      <PaginatedTable<ApiKeyType>
        keyField="id"
        name="organizations.settings.apiKeys"
        noColumnsSelection
        columns={columns}
        dataHook={useApiKeys}
        onSelected={noop}
        noDataIndication={{
          title: 'No API keys',
          actions: (
            <Button
              size="md"
              onClick={() =>
                openModal<CreateApiKeyProps['data']>('CreateApiKey', {
                  onCreate: createApiKey,
                })
              }
              leftIcon={<Add />}
            >
              Create API Key
            </Button>
          ),
        }}
        rowMenu={({ row, wrapperProps }) => (
          <RowMenuItems
            wrapperProps={wrapperProps}
            items={[
              {
                id: 'remove',
                label: 'Remove',
                color: 'red',
                onClick: () => onDeleteApiKey(row, wrapperProps.menuRef),
                Icon: Trash,
              },
            ]}
          />
        )}
        selectedItemsActions={() => (
          <SelectedApiKeyActions onDelete={onDeleteApiKeys} />
        )}
        additionalActions={() => (
          <>
            <Button
              size="xs"
              onClick={() =>
                openModal<CreateApiKeyProps['data']>('CreateApiKey', {
                  onCreate: createApiKey,
                })
              }
              leftIcon={<Add width={15} height={15} />}
            >
              Create new API Key
            </Button>
          </>
        )}
      />
    </Box>
  );
}
