import { LoadingOverlay, Anchor } from '@mantine/core';
import React from 'react';
import { Link } from 'react-router-dom';

import { useFileInfoById } from '@portals/api/partners';
import { ReactComponent as ArrowRight1 } from '@portals/icons/linear/arrow-right-1.svg';
import { InfoTable } from '@portals/table';
import { prettyTime } from '@portals/utils';

interface FileDetailsProps {
  fileId: string;
}

export function FileDetails({ fileId }: FileDetailsProps) {
  const file = useFileInfoById(fileId);

  if (file.isLoading || !file.data) {
    return <LoadingOverlay visible />;
  }

  return (
    <InfoTable.Table>
      <InfoTable.Header>
        <Link
          className="btn btn-info d-flex align-items-center"
          to={`/models/${file.data.device_model_id}`}
        >
          Go to Model
          <ArrowRight1 width={17} height={17} style={{ marginLeft: 4 }} />
        </Link>
      </InfoTable.Header>
      <InfoTable.Row label="Name" value={file.data.name} />
      <InfoTable.Row label="Description" value={file.data.desc} />
      <InfoTable.Row label="Version" value={file.data.version} />
      <InfoTable.Row
        label="Type"
        value={
          file.data.type || <span className="text-muted">Not provided</span>
        }
      />
      <InfoTable.Row
        label="URL"
        value={
          <Anchor
            href={file.data.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {file.data.url}
          </Anchor>
        }
      />
      <InfoTable.Row
        label="Created at"
        value={prettyTime(file.data.created_at)}
      />
      <InfoTable.Row label="Checksum" value={file.data.checksum} />
      <InfoTable.Row label="Signature" value={file.data.signature} />
      <InfoTable.Row label="Notes" value={file.data.notes} />
      <InfoTable.Row label="Public Notes" value={file.data.public_notes} />
    </InfoTable.Table>
  );
}
