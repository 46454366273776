import { Box, createStyles, Stack, Text } from '@mantine/core';
import { isEmpty } from 'lodash/fp';
import React, { useEffect } from 'react';

import { Product } from '@portals/api/partners';
import {
  ControlledTextEditor,
  DetailsPanel,
  TextEditorProps,
  useTextEditor,
} from '@portals/core';
import { ProductCard } from '@portals/framework';
import { getPricingModelDisplayName } from '@portals/utils';

import { DetailsPanelActions } from './DetailsPanelActions';
import { ProductDetailsList } from './ProductDetailsList';
import { ReadOnlyPostPurchaseParamsFields } from './ReadOnlyPostPurchaseParamsFields';
import { CommandsAndModelsList } from '../../components/CommandsAndModelList';

interface ProductTableDetailsPanelProps {
  product: Product;
  onClose: () => void;
  onDuplicate: (product: Product) => void;
  onEdit: (product: Product) => void;
  onDelete: (product: Product) => void;
  onArchive: (product: Product) => void;
}

export function ProductTableDetailsPanel({
  product,
  onClose,
  onEdit,
  onDuplicate,
  onDelete,
  onArchive,
}: ProductTableDetailsPanelProps) {
  const { classes } = useStyles();

  const editor = useTextEditor({
    content: product.description,
    editable: false,
  });

  useEffect(
    function updateDescriptionIfChanged() {
      if (editor?.getHTML() !== product.description) {
        editor?.commands?.setContent(product.description);
      }
    },
    [editor, product.description]
  );

  return (
    <Box className={classes.container}>
      <DetailsPanel>
        <DetailsPanel.Header
          title={
            <Stack>
              <DetailsPanel.Title data-testid="product-name">
                {product.name}
              </DetailsPanel.Title>

              {product.subtitle ? (
                <Text size="sm" color="gray.6" data-testid="product-subtitle">
                  {product.subtitle}
                </Text>
              ) : null}
            </Stack>
          }
        >
          <DetailsPanelActions
            product={product}
            onEdit={onEdit}
            onArchive={onArchive}
            onDelete={onDelete}
            onDuplicate={onDuplicate}
          />
        </DetailsPanel.Header>

        <DetailsPanel.Body>
          <ProductDetailsList
            category={product.category}
            productType={product.product_type}
            createdAt={product.created_at}
            deviceModel={product.device_model}
            manufacturerName={product.manufacturer.display_name}
            pricingModel={product.pricing_model}
            sku={product.sku}
            usageBasedDisplayName={product.usage_based_display_name}
            usageBasedTelemetryKey={product.usage_based_telemetry_key}
            usageBasedUnitName={product.usage_based_unit_name}
          />

          <DetailsPanel.Section title="Description">
            <ControlledTextEditor
              styles={textEditorStyles}
              data-testid="product-description-text"
              editor={editor}
            />
          </DetailsPanel.Section>

          {product.supported_commands?.length > 0 ? (
            <DetailsPanel.Section title="Models & commands">
              <CommandsAndModelsList
                supportedCommands={product.supported_commands}
              />
            </DetailsPanel.Section>
          ) : null}

          {!isEmpty(product.post_purchase_parameters) ? (
            <DetailsPanel.Section title="Post Purchase Data">
              <ReadOnlyPostPurchaseParamsFields
                fields={product.post_purchase_parameters}
              />
            </DetailsPanel.Section>
          ) : null}
        </DetailsPanel.Body>
      </DetailsPanel>

      <DetailsPanel bg="gray.1">
        <DetailsPanel.Header onClose={onClose} />

        <DetailsPanel.Body justify="center">
          <Text size="md" color="gray.4" align="center">
            Store card preview
          </Text>

          <ProductCard>
            <ProductCard.Image
              category={product.category}
              imageUrl={product.image_url}
            />
            <ProductCard.Details
              name={product.name}
              subtitle={product.subtitle}
            >
              <Text size="xl" weight={500} color="gray.9">
                {getPricingModelDisplayName(product.pricing_model)}
              </Text>
            </ProductCard.Details>
          </ProductCard>
        </DetailsPanel.Body>
      </DetailsPanel>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    height: '100%',
  },
}));

const textEditorStyles: TextEditorProps['styles'] = (theme) => ({
  root: {
    border: 'none',
  },
  typographyStylesProvider: {
    color: theme.colors.gray[8],
    fontSize: theme.fontSizes.sm,
  },
  content: {
    '.ProseMirror': {
      padding: 0,
    },
  },
});
