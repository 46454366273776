import { Button, createStyles, Group, Stack, Text } from '@mantine/core';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';

import {
  SubscriptionCancellationForm,
  SubscriptionCancellationFormProps,
} from '@portals/framework';
import { ReactComponent as CollapseRightIcon } from '@portals/icons/linear/collapse-right.svg';

interface SubscriptionCancellationReasonPanelProps {
  isOpen: boolean;
  onClose: () => void;
  reason: SubscriptionCancellationFormProps['reason'];
  freeText: SubscriptionCancellationFormProps['freeText'];
}

export function SubscriptionCancellationReasonPanel({
  isOpen,
  onClose,
  reason,
  freeText,
}: SubscriptionCancellationReasonPanelProps) {
  const { classes } = useStyles();

  return (
    <AnimatePresence initial={false}>
      {isOpen && (
        <motion.div
          initial={{ x: 0 }}
          animate={{ x: '-100%' }}
          exit={{ x: 0 }}
          transition={{ type: 'linear' }}
          className={classes.container}
        >
          <Stack spacing="xxl">
            <Group position="apart" align="flex-start">
              <Text color="gray.8" size="md">
                Cancellation reason
              </Text>

              <Button
                variant="default"
                leftIcon={<CollapseRightIcon width={16} height={16} />}
                onClick={onClose}
              >
                Hide
              </Button>
            </Group>

            <SubscriptionCancellationForm
              readOnly
              reason={reason}
              freeText={freeText}
            />
          </Stack>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    padding: theme.spacing.xxl,
    backgroundColor: theme.white,
    borderRight: `1px solid ${theme.colors.gray[3]}`,
    boxShadow: '6px 0px 41px 0px rgba(0, 0, 0, 0.13)',
    clipPath: 'inset(0 0 0 -41px)',
  },
}));
