import {
  Badge,
  Button,
  Flex,
  Paper,
  Stack,
  Text,
  ThemeIcon,
  useMantineTheme,
} from '@mantine/core';
import React, { FC, SVGProps } from 'react';
import { useNavigate } from 'react-router-dom';

interface PageCardProps {
  title: string;
  description: string;
  actionLabel: string;
  to: string;
  Icon: FC<SVGProps<SVGElement>>;
  value?: number;
  badgeLabel?: string;
  badgeLink?: string;
}

export function PageCard({
  title,
  description,
  actionLabel,
  to,
  Icon,
  value,
  badgeLabel,
  badgeLink,
}: PageCardProps) {
  const theme = useMantineTheme();
  const navigate = useNavigate();

  return (
    <Paper radius="md" py="xxl" px="xl" withBorder>
      <Flex direction="column" justify="space-between" h="100%">
        <Stack align="center" spacing="xl" justify="space-between" h="100%">
          <Stack spacing="xl" align="center">
            <ThemeIcon size={72} radius={72 / 2} p="lg" color="deep_purple.0">
              <Icon color={theme.colors.gray[7]} width={32} height={32} />
            </ThemeIcon>

            <Stack align="center" spacing="xs">
              <Text size={36} color="gray.9">
                {value}
              </Text>

              <Text size="xl" color="gray.9">
                {title}
              </Text>

              <Text size="sm" color="gray.6">
                {description}
              </Text>
            </Stack>

            {badgeLabel ? (
              <Badge
                color="amber.0"
                c="gray.9"
                variant="filled"
                size="md"
                fw={400}
                py="sm"
                onClick={() => badgeLink && navigate(badgeLink)}
                sx={{
                  cursor: badgeLink ? 'pointer' : 'default',
                }}
              >
                {badgeLabel}
              </Badge>
            ) : null}
          </Stack>

          <Button
            variant="default"
            onClick={() => navigate(to)}
            w="100%"
            data-testid={`store-management-${title}-button`}
          >
            {actionLabel}
          </Button>
        </Stack>
      </Flex>
    </Paper>
  );
}
