import { isNumber } from 'lodash/fp';

import {
  CommonWidgetFormType,
  DEVICE_MAX_GROUPED_SWITCHES_COUNT,
  DeviceWidgetNameType,
  WidgetType,
} from '@portals/device-widgets';

function calcGroupedSwitchesWidgetMinDimensions(config) {
  const fields = config?.fields;
  const getMinWidth = () => {
    if ('commands' in fields) {
      if (fields?.commands?.length <= DEVICE_MAX_GROUPED_SWITCHES_COUNT) {
        return fields?.commands?.length * config?.width + 1;
      } else {
        return DEVICE_MAX_GROUPED_SWITCHES_COUNT * config?.width + 1;
      }
    }

    return config?.width;
  };

  const getMinHeight = () => {
    if ('commands' in fields) {
      return Math.ceil(fields?.commands?.length / config?.height) === 0
        ? 1
        : Math.ceil(
            fields?.commands?.length / DEVICE_MAX_GROUPED_SWITCHES_COUNT
          ) * config?.height;
    }

    return config?.height;
  };

  const minW = getMinWidth();
  const minH = getMinHeight();

  return {
    minH,
    minW,
  };
}

export function generateWidgetsLayoutPerWidgetType(
  widget: WidgetType<CommonWidgetFormType, DeviceWidgetNameType>
) {
  const { id, name, config } = widget;

  let minW = 0;
  let minH = 0;

  const i = id;
  const x = isNumber(config?.layout?.x) ? config?.layout?.x : 0;
  const y = isNumber(config?.layout?.y) ? config?.layout?.y : 0;
  let h = isNumber(config?.layout?.h) ? config?.layout?.h : config?.height || 2;
  let w = isNumber(config?.layout?.w) ? config?.layout?.w : config?.width;

  switch (config.id) {
    case 'grouped_switches':
      minW = calcGroupedSwitchesWidgetMinDimensions(config).minW;
      minH = calcGroupedSwitchesWidgetMinDimensions(config).minH;
      w = w < minW ? minW : w;
      h = h < minH ? minH : h;
      break;

    case 'status':
      minH = 3;
      minW = 4;
      break;

    case 'numeric_metric':
      minH = 3;
      minW = 4;
      break;

    case 'value':
      minH = 3;
      minW = 4;
      break;

    case 'spline_chart':
      minW = 12;
      minH = 4;
      break;

    case 'scatter_chart':
      minW = 12;
      minH = 7;
      break;

    case 'toggle':
      minH = 4;
      minW = 4;
      break;

    case 'button':
      minH = 3;
      minW = 4;
      break;

    case 'device_location':
      minH = 9;
      minW = 8;
      break;

    case 'gauge_indicator':
      minH = 9;
      minW = 9;
      break;

    case 'bar_indicator':
      minH = 4;
      minW = 6;
      break;

    case 'bar_controller':
      minH = 4;
      minW = 6;
      break;

    case 'section_header':
      minH = 2;
      minW = 4;
      break;

    case 'state_controller':
      minH = 5;
      minW = 6;
      break;

    default:
      break;
  }

  return {
    i,
    x,
    y,
    w,
    h,
    name,
    minH,
    minW,
    config,
  };
}
