import { ScrollArea, Space } from '@mantine/core';
import { groupBy } from 'lodash/fp';
import React from 'react';

import { OrderType, PartnerOrderItemType } from '@portals/api/partners';
import { useGenericContext } from '@portals/framework';

import { OrderProductSummaryRow } from './OrderProductSummaryRow';

export function OrderProductsSummary() {
  const order = useGenericContext<OrderType>();

  const groupedOrderItems = groupBy(
    (orderItem) =>
      `${orderItem.store_listing.id}_${orderItem.payment_interval}`,
    order?.items
  );

  const orderItems: PartnerOrderItemType[] = Object.values(groupedOrderItems)
    .map((item) => ({
      ...item[0],
      quantity: item.length,
      total_charged_amount_in_scu: item.reduce(
        (sum, currItem) => (sum += currItem.total_charged_amount_in_scu),
        0
      ),
    }))
    .sort((a, b) => a.store_listing.id.localeCompare(b.store_listing.id));

  const withSku = orderItems.some(
    (orderItem) => orderItem.product.sku !== null
  );
  const withBasePrice = orderItems.some(
    (orderItem) => orderItem.usage_based?.base_price_in_scu
  );

  return (
    <ScrollArea.Autosize mah={430} type="auto">
      {orderItems.map((item, index) => (
        <OrderProductSummaryRow
          key={index}
          orderItem={item}
          withSku={withSku}
          withBasePrice={withBasePrice}
        />
      ))}

      <Space h="xs" />
    </ScrollArea.Autosize>
  );
}
