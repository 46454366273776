import { Button, Group } from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { FC } from 'react';

import { useCreateTaxGroup } from '@portals/api/partners';
import { ModalCenteredMediaLayout } from '@portals/core';
import { ModalProps } from '@portals/framework';
import { ChooseTaxGroupSrc } from '@portals/framework';

import { CreateTaxGroupForm } from '../../components/tax-groups/create-tax-group-form/CreateTaxGroupForm';
import { TaxGroupIconName } from '../../components/tax-groups/tax-groups-icons';

interface FormType {
  groupName: string;
  taxCode: string;
  iconName: TaxGroupIconName;
}

export const CreateTaxGroup: FC<
  ModalProps<{ onAfterCreate?: (newTaxGroupId: string) => void }>
> = ({ closeMe, data: modalData }) => {
  const { onAfterCreate } = modalData;
  const createTaxGroup = useCreateTaxGroup();

  const form = useForm<FormType>({
    initialValues: {
      groupName: '',
      taxCode: '',
      iconName: 'electricity',
    },
    clearInputErrorOnChange: true,
    validate: (values) => ({
      groupName: values.groupName === '' ? 'Required' : null,
      taxCode: values.taxCode === '' ? 'Required' : null,
    }),
  });

  const onSubmit = async (values: FormType) => {
    try {
      const newTaxGroup = await createTaxGroup.mutateAsync({
        groupName: values.groupName,
        taxCode: values.taxCode,
        iconName: values.iconName,
      });

      onAfterCreate?.(newTaxGroup.id);
      closeMe();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <ModalCenteredMediaLayout
      opened
      onClose={closeMe}
      title="Create Tax Group"
      media={<img src={ChooseTaxGroupSrc} />}
    >
      <form onSubmit={form.onSubmit(onSubmit)}>
        <CreateTaxGroupForm
          iconName={form.values.iconName}
          onChangeIconName={(iconName) =>
            form.setFieldValue('iconName', iconName)
          }
          form={form}
        />

        <Group grow mt={62}>
          <Button variant="default" onClick={closeMe}>
            Cancel
          </Button>
          <Button type="submit" loading={createTaxGroup.isLoading}>
            Create Group
          </Button>
        </Group>
      </form>
    </ModalCenteredMediaLayout>
  );
};
