import { Box, Button, createStyles, Group, SimpleGrid } from '@mantine/core';
import { size } from 'lodash/fp';
import React, { FC } from 'react';

import { useRouteModals } from '@portals/framework/route-modals';
import { useOpenModal } from '@portals/redux';
import { VerticalScrollBar } from '@portals/scrollbar';
import { EmptyState } from '@portals/table';

import { ProviderType } from './credit-providers.types';
import { ProviderCard } from './ProviderCard';

interface ProvidersListProps {
  providers: Array<ProviderType>;
  selectedProvider: string;
  toggleSelectedProvider: (providerId: string) => void;
}

export const ProvidersList: FC<ProvidersListProps> = ({
  providers = [],
  selectedProvider,
  toggleSelectedProvider,
}) => {
  const { classes } = useStyles();
  const routeModalsContext = useRouteModals();
  const openModal = useOpenModal();

  return (
    <Box className={classes.container}>
      {size(providers) ? (
        <VerticalScrollBar
          renderThumbVertical={() => <div />}
          renderView={(props) => <Box className={classes.list} {...props} />}
        >
          <SimpleGrid cols={4}>
            {providers.map((provider) => (
              <ProviderCard
                key={provider.id}
                {...provider}
                onClick={() => toggleSelectedProvider(provider.id)}
                isSelected={provider.id === selectedProvider}
              />
            ))}
          </SimpleGrid>
        </VerticalScrollBar>
      ) : (
        <EmptyState label="No providers" />
      )}

      <Group position="right" className={classes.footer} spacing="md">
        <Button variant="default" onClick={routeModalsContext.onClose}>
          Cancel
        </Button>
        <Button
          disabled={!selectedProvider}
          onClick={() => {
            openModal('CreditProviderApplication');
          }}
        >
          Add
        </Button>
      </Group>
    </Box>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateRows: '1fr 88px',
  },
  list: {
    padding: `${theme.spacing.xl} 30px`,
    width: '100%',
    backgroundColor: theme.colors.gray[0],
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
    gap: theme.spacing.md,
  },
  category: {
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
    gap: theme.spacing.md,
    height: 'fit-content',
  },
  categoryTitle: {
    color: theme.colors.blue_gray[8],
    fontSize: theme.fontSizes.lg,
  },
  footer: {
    borderTop: `1px solid ${theme.colors.gray[2]}`,
    padding: `0 ${theme.spacing.xl}`,
  },
}));
