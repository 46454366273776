import { noop } from 'lodash/fp';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';

export interface ModelMigrationContextType {
  modelsMigrationNotifications: Map<string, true>;
  onToggleNotification: (modelId: string) => void;
}

const MigrationContext = createContext<ModelMigrationContextType>({
  modelsMigrationNotifications: new Map(),
  onToggleNotification: noop,
});

interface ModelMigrationProviderProps {
  children: ReactNode;
}

export function ModelMigrationProvider({
  children,
}: ModelMigrationProviderProps) {
  const [modelsMigrationNotifications, setModelsMigrationNotifications] =
    useState<Map<string, true>>(new Map());

  const onToggleNotification = useCallback((modelId: string) => {
    setModelsMigrationNotifications((curr) => {
      return curr.set(modelId, true);
    });
  }, []);

  return (
    <MigrationContext.Provider
      value={{
        modelsMigrationNotifications,
        onToggleNotification,
      }}
    >
      {children}
    </MigrationContext.Provider>
  );
}

export function useModelMigrationContext() {
  const context = useContext(MigrationContext);

  if (context === null) {
    throw new Error(
      'useModelMigrationContext must be used within ModelMigrationProvider'
    );
  }

  return context;
}
