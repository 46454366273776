import {
  createStyles,
  Group,
  Highlight,
  HighlightProps,
  Modal,
  ModalProps as MantineModalProps,
  Stack,
  TextInput,
} from '@mantine/core';
import React, { useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useKey } from 'react-use';

import { ReactComponent as TickCircleIcon } from '@portals/icons/linear/tick-circle.svg';
import { switchTenant } from '@portals/redux/actions/auth';

import { useAppConfig } from '../context';
import { useCommonConfig } from '../hooks/portal-config';
import { useCurrentUserAccessibleTenants } from '../hooks/users';

import type { ModalProps } from '../components/Modals';

export function SwitchTenantModal({ closeMe }: ModalProps) {
  const { classes, cx } = useStyles();
  const dispatch = useDispatch();

  const tenantElementRefs = useRef<Record<string, HTMLDivElement>>({});

  const { tenantType } = useAppConfig();
  const config = useCommonConfig();
  const accessibleTenants = useCurrentUserAccessibleTenants();

  const [searchTerm, setSearchTerm] = useState('');
  const [hoveredTenantId, setHoveredTenantId] = useState('');

  const tenants = useMemo(() => {
    if (!accessibleTenants) return [];

    if (searchTerm === '') return accessibleTenants;

    const lowerCasedSearchTerm = searchTerm.toLowerCase();

    return accessibleTenants.filter(
      (tenant) =>
        tenant.name?.toLowerCase()?.includes(lowerCasedSearchTerm) ||
        tenant.display_name?.toLowerCase()?.includes(lowerCasedSearchTerm)
    );
  }, [accessibleTenants, searchTerm]);

  const switchToSelectedTenant = (newTenantId: string) => {
    if (!tenantType || !accessibleTenants) return;

    if (newTenantId === config?.data?.[tenantType]?.id) {
      return;
    }

    const selectedTenant = accessibleTenants.find(
      (tenant) => tenant.id === newTenantId
    );

    if (!selectedTenant) return;

    dispatch(switchTenant(selectedTenant, tenantType, '/'));
  };

  const scrollTenantElementIntoView = (tenantId: string) => {
    const element = tenantElementRefs.current[tenantId];

    if (element) {
      element.scrollIntoView({ block: 'nearest' });
    }
  };

  const onArrowDown = () => {
    // Clear the `hoveredTenantId` if the tenants list is empty
    if (tenants.length === 0) {
      setHoveredTenantId('');
      return;
    }

    let tenantIdToHover: string;

    // If the `hoveredTenantId` is not set yet, set it to the first tenant in the list
    if (!hoveredTenantId) {
      tenantIdToHover = tenants[0].id;

      setHoveredTenantId(tenantIdToHover);
      scrollTenantElementIntoView(tenantIdToHover);

      return;
    }

    const currentHoveredTenantIndex = tenants.findIndex(
      (tenant) => tenant.id === hoveredTenantId
    );

    if (
      currentHoveredTenantIndex === -1 ||
      currentHoveredTenantIndex === tenants.length - 1
    ) {
      // Set the `hoveredTenantId` to the first tenant in the list
      tenantIdToHover = tenants[0].id;
    } else {
      // Set the `hoveredTenantId` to the next tenant in the list
      tenantIdToHover = tenants[currentHoveredTenantIndex + 1].id;
    }

    setHoveredTenantId(tenantIdToHover);
    scrollTenantElementIntoView(tenantIdToHover);
  };

  const onArrowUp = () => {
    // Clear the `hoveredTenantId` if the tenants list is empty
    if (tenants.length === 0) {
      setHoveredTenantId('');
      return;
    }

    let tenantIdToHover: string;

    // If the `hoveredTenantId` is not set yet, set it to the last tenant in the list
    if (!hoveredTenantId) {
      tenantIdToHover = tenants[tenants.length - 1].id;

      setHoveredTenantId(tenantIdToHover);
      scrollTenantElementIntoView(tenantIdToHover);

      return;
    }

    const currentHoveredTenantIndex = tenants.findIndex(
      (tenant) => tenant.id === hoveredTenantId
    );

    if (currentHoveredTenantIndex === -1 || currentHoveredTenantIndex === 0) {
      tenantIdToHover = tenants[tenants.length - 1].id;
    } else {
      // Set the `hoveredTenantId` to the prev tenant in the list
      tenantIdToHover = tenants[currentHoveredTenantIndex - 1].id;
    }

    setHoveredTenantId(tenantIdToHover);
    scrollTenantElementIntoView(tenantIdToHover);
  };

  const onEnter = () => {
    if (!hoveredTenantId) return;

    switchToSelectedTenant(hoveredTenantId);
  };

  useKey('ArrowDown', onArrowDown, {}, [tenants, hoveredTenantId]);
  useKey('ArrowUp', onArrowUp, {}, [tenants, hoveredTenantId]);
  useKey('Enter', onEnter, {}, [hoveredTenantId]);

  return (
    <Modal
      opened
      onClose={closeMe}
      padding={0}
      size={350}
      mah={400}
      radius="md"
      styles={modalStyles}
      title="Switch Tenant"
    >
      <div className={classes.contentContainer}>
        <TextInput
          value={searchTerm}
          placeholder="Find tenant..."
          className={classes.input}
          data-testid="tenant-switch-search-input"
          onChange={(event) => {
            setSearchTerm(event.target.value);
            setHoveredTenantId('');
          }}
          autoFocus
          data-autofocus
        />

        <Stack spacing="xs" className={classes.listContainer}>
          {tenants.map((tenant) => {
            const isSelected = config.data?.[tenantType]?.id === tenant.id;

            return (
              <Group
                noWrap
                position="apart"
                key={tenant.id}
                data-testid={`tenant-${tenant.display_name}`}
                onClick={() => switchToSelectedTenant(tenant.id)}
                ref={(el: HTMLDivElement) =>
                  (tenantElementRefs.current[tenant.id] = el)
                }
                className={cx(classes.item, {
                  [classes.hoveredItem]: hoveredTenantId === tenant.id,
                  [classes.selectedItem]: isSelected,
                })}
              >
                <Stack spacing={4}>
                  <Highlight
                    size="sm"
                    highlight={searchTerm}
                    highlightStyles={highlightStyles}
                  >
                    {tenant.display_name}
                  </Highlight>

                  <Highlight
                    size="xs"
                    color="dimmed"
                    highlight={searchTerm}
                    highlightStyles={highlightStyles}
                  >
                    {tenant.name}
                  </Highlight>
                </Stack>

                {isSelected && <TickCircleIcon />}
              </Group>
            );
          })}
        </Stack>
      </div>
    </Modal>
  );
}

const modalStyles: MantineModalProps['styles'] = (theme) => ({
  content: {
    height: 500,
    display: 'grid',
    gridTemplateRows: 'max-content 1fr',
  },
  header: {
    padding: theme.spacing.md,
    borderBottom: `1px solid ${theme.colors.gray[3]}`,
  },
  body: {
    overflow: 'hidden',
  },
});

const highlightStyles: HighlightProps['highlightStyles'] = {
  padding: 0,
};

const useStyles = createStyles((theme) => ({
  input: {
    marginInline: theme.spacing.sm,
  },
  contentContainer: {
    overflow: 'hidden',
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'max-content 1fr',
    gap: theme.spacing.md,
    paddingTop: theme.spacing.md,
  },
  listContainer: {
    overflow: 'auto',
    height: '100%',
    paddingInline: theme.spacing.md,
    paddingBottom: theme.spacing.md,
  },
  item: {
    paddingInline: theme.spacing.sm,
    paddingBlock: theme.spacing.xs,
    borderRadius: theme.radius.md,
    border: `1px solid transparent`,
    cursor: 'pointer',
    userSelect: 'none',

    '&:hover': {
      backgroundColor: theme.colors.gray[1],
    },
  },
  hoveredItem: {
    borderColor: theme.colors.gray[5],
    backgroundColor: theme.colors.gray[2],
  },
  selectedItem: {
    '&, &:hover': {
      backgroundColor: theme.colors.blue[0],
    },
  },
}));
