import { Button } from '@mantine/core';
import { forEach, keys } from 'lodash/fp';
import React, { FC } from 'react';

import { useConfirmationModal } from '@portals/framework';
import { ReactComponent as CloseSquare } from '@portals/icons/linear/close-square.svg';
import { useTableInstance } from '@portals/table';
import { RuleType } from '@portals/types';

interface RulesSelectedActionsProps {
  onRemove: (ruleId: string) => void;
  rules: Array<RuleType>;
}

const RulesSelectedActions: FC<RulesSelectedActionsProps> = ({
  onRemove,
  rules,
}) => {
  const asyncConfirmationCheck = useConfirmationModal();
  const { state, toggleAllRowsSelected } = useTableInstance();
  const { selectedRowIds } = state;

  const onRemoveSelected = async () => {
    const isConfirmed = await asyncConfirmationCheck({
      description: 'Are you sure?',
    });

    if (isConfirmed) {
      forEach((rowIndex) => {
        const ruleId = rules[rowIndex]?.id;

        if (ruleId) {
          onRemove(ruleId);
        }
      }, keys(selectedRowIds));

      toggleAllRowsSelected(false);
    }
  };

  return (
    <Button
      variant="subtle"
      color="red"
      size="xs"
      px={5}
      styles={{
        leftIcon: {
          width: 16,
          height: 16,
        },
      }}
      onClick={onRemoveSelected}
      leftIcon={<CloseSquare />}
    >
      Remove
    </Button>
  );
};

export default RulesSelectedActions;
