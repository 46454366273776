import { Badge, Group } from '@mantine/core';
import { find } from 'lodash/fp';
import React, { useMemo } from 'react';

import {
  OrganizationType,
  useBulkCreateCustomers,
} from '@portals/api/partners';
import { SelfUserResponseType } from '@portals/api/ui';
import { ModalButton, PermissionAccess } from '@portals/framework';
import { ReactComponent as AddSquare } from '@portals/icons/linear/add-square.svg';
import { useOpenModal } from '@portals/redux';
import { AvatarCell, BooleanCell, DateCell, SmartTable } from '@portals/table';
import {
  AccessLevelEnum,
  TableColumn,
  TableFilterTypeEnum,
} from '@portals/types';

import { RedirectToCustomerPortalButton } from '../../components/RedirectToCustomerPortalButton';
import { SelectInvitationTypeModalProps } from '../../modals';

interface TableRowData extends OrganizationType {
  controlled: boolean;
}

function mapRow(
  org: OrganizationType,
  accessibleOrganizations: OrganizationsTableProps['accessibleOrganizations']
) {
  return {
    ...org,
    controlled: find({ id: org.id }, accessibleOrganizations) !== undefined,
  };
}

interface OrganizationsTableProps {
  organizations: OrganizationType[];
  accessibleOrganizations?: SelfUserResponseType['accessible_tenants']['organizations'];
}

export function CustomersTable({
  organizations,
  accessibleOrganizations = [],
}: OrganizationsTableProps) {
  const openModal = useOpenModal();

  const bulkCreateCustomers = useBulkCreateCustomers();

  const tableColumns = useMemo<Array<TableColumn<TableRowData>>>(() => {
    const columns: Array<TableColumn<TableRowData>> = [
      {
        dataField: 'name',
        text: 'Name',
        sort: true,
        isSticky: true,
        minWidth: 500,
        maxWidth: 500,
        filter: {
          type: TableFilterTypeEnum.Text,
        },
        formatter: (_, { name, logo_url, lab }) => (
          <Group align="center" noWrap>
            <AvatarCell label={name} src={logo_url} />

            {lab ? (
              <Badge
                color="indigo_accent"
                variant="filled"
                size="lg"
                fw={300}
                sx={{ flexShrink: 0 }}
              >
                Lab account
              </Badge>
            ) : null}
          </Group>
        ),
      },
      {
        dataField: 'users',
        text: 'Users',
        sort: true,
        maxWidth: 150,
        filter: {
          type: TableFilterTypeEnum.Number,
        },
      },
      {
        dataField: 'devices',
        text: 'Devices',
        sort: true,
        maxWidth: 150,
        filter: {
          type: TableFilterTypeEnum.Number,
        },
      },
      {
        dataField: 'email',
        text: 'Contact',
        sort: true,
        filter: {
          type: TableFilterTypeEnum.Text,
        },
        formatter: (_, row) => <a href={`mailto:${row.email}`}>{row.email}</a>,
      },
      {
        dataField: 'referred_by',
        text: 'Referred by',
        minWidth: 200,
      },
      {
        dataField: 'created_at',
        text: 'Created',
        maxWidth: 180,
        sort: true,
        filter: {
          type: TableFilterTypeEnum.Date,
        },
        formatter: (_, row) => (
          <DateCell date={row.created_at} withTimeAgo={false} />
        ),
      },
    ];

    columns.push({
      dataField: 'controlled',
      text: 'Access',
      sort: true,
      formatter: (_, row) => {
        if (!row.controlled) {
          return <BooleanCell isActive={false} label="No access" />;
        }

        return (
          <RedirectToCustomerPortalButton customerId={row.id}>
            Visit portal
          </RedirectToCustomerPortalButton>
        );
      },
    });

    return columns;
  }, []);

  const tableData = useMemo<TableRowData[]>(
    () => organizations.map((org) => mapRow(org, accessibleOrganizations)),
    [organizations, accessibleOrganizations]
  );

  return (
    <SmartTable<TableRowData>
      keyField="id"
      name="partners.customers"
      data={tableData}
      columns={tableColumns}
      noDataIndication={{ title: 'No customers' }}
      additionalActions={() => (
        <PermissionAccess
          minAccessLevel={AccessLevelEnum.Edit}
          permissionKeys={['customers', 'finance', 'fulfillment']}
        >
          <ModalButton
            modalName="SelectInvitationTypeModal"
            label="Create Customer"
            size="sm"
            data-testid="create-customer-button"
            leftIcon={<AddSquare />}
            data={
              {
                title: 'How would you like to invite customers?',
                directInvitationText:
                  'Create customers and send an email invitation directly. This option is swift, precise and ideal for individual invitations.',
                bulkInvitationText:
                  'Invite all your customers at once. Our support team will reach out to you, assisting in the creation and import of a CSV file.',
                onDirectInvitationClick: () => openModal('AddOrganization'),
                onBulkInvitationClick: () => bulkCreateCustomers.mutate(),
              } satisfies SelectInvitationTypeModalProps['data']
            }
          />
        </PermissionAccess>
      )}
      defaultSorted={[{ id: 'name', desc: false }]}
    />
  );
}
