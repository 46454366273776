import {
  ActionIcon,
  Box,
  createStyles,
  NumberInput,
  NumberInputHandlers,
  NumberInputProps,
} from '@mantine/core';
import React, { useRef } from 'react';

import { ReactComponent as Add } from '@portals/icons/linear/add.svg';
import { ReactComponent as Minus } from '@portals/icons/linear/minus.svg';

interface QuantityInputProps {
  min?: number;
  max?: number;
  numberInputProps?: NumberInputProps;
  value: number;
  onChange: (quantity: number) => void;
}

export function QuantityInput({
  min = 1,
  max,
  numberInputProps = {},
  value,
  onChange,
}: QuantityInputProps) {
  const { classes } = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const handlers = useRef<NumberInputHandlers>(null);

  return (
    <Box className={classes.wrapper}>
      <ActionIcon
        size={28}
        variant="transparent"
        onClick={() => handlers.current?.decrement()}
        disabled={value === min}
        className={classes.control}
        onMouseDown={(event) => event.preventDefault()}
      >
        <Minus />
      </ActionIcon>

      <NumberInput
        disabled
        variant="unstyled"
        min={min}
        max={max}
        ref={inputRef}
        hideControls
        handlersRef={handlers}
        value={value}
        onChange={(value) => onChange(value || 0)}
        step={1}
        classNames={{
          input: classes.input,
        }}
        {...numberInputProps}
      />

      <ActionIcon
        size={28}
        variant="transparent"
        onClick={() => handlers.current?.increment()}
        disabled={value === max}
        className={classes.control}
        onMouseDown={(event) => event.preventDefault()}
      >
        <Add />
      </ActionIcon>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    width: 'fit-content',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: theme.radius.md,
    display: 'grid',
    gridTemplateColumns: 'max-content auto max-content',
  },

  control: {
    '&:disabled': {
      opacity: 0.8,
      backgroundColor: 'transparent',
    },

    svg: {
      color: theme.colors.blue_gray[7],
      width: 16,
      height: 16,
    },
  },

  input: {
    width: 30,
    textAlign: 'center',
    height: 20,
    flex: 1,
    border: 'none',
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[5],
    backgroundColor: 'transparent',

    '&[data-disabled]': {
      opacity: 1,
      color: theme.colors.gray[5],
      backgroundColor: 'transparent',
    },
  },
}));
