import { useCallback } from 'react';

import { useGetCustomerPortalToken } from '@portals/api/partners';
import { usePortalProtocolAndDomain } from '@portals/framework';

export function useRedirectToCustomerPortal() {
  const { protocolAndDomain, isLoading } = usePortalProtocolAndDomain();
  const customerPortalToken = useGetCustomerPortalToken();

  const redirect = useCallback(
    ({
      customerId,
      afterAuthRedirectPathname,
    }: {
      customerId: string;
      afterAuthRedirectPathname?: `/${string}`;
    }) => {
      return customerPortalToken.mutate(
        { id: customerId },
        {
          onSuccess: ({ token }) => {
            const url = new URL(`${protocolAndDomain}/auth/auto-sign-in`);

            url.searchParams.set('t', token);
            url.searchParams.set('customer_id', customerId);

            if (afterAuthRedirectPathname) {
              url.searchParams.set(
                'afterAuthRedirectPathname',
                afterAuthRedirectPathname
              );
            }

            window.open(url, `_blank`, 'noopener noreferrer');
          },
        }
      );
    },
    [customerPortalToken, protocolAndDomain]
  );

  return {
    redirect,
    isLoading: isLoading || customerPortalToken.isLoading,
    customerIdParam: customerPortalToken.variables?.id,
  };
}
