import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useStoreSettings } from '@portals/api/partners';
import { CurrencyCode } from '@portals/types';

interface StoreListingsTableContextType {
  currency: CurrencyCode;
  setCurrency: Dispatch<SetStateAction<CurrencyCode>>;
}

const StoreListingsTableContext =
  createContext<StoreListingsTableContextType | null>(null);

export function StoreListingsTableContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const storeSettings = useStoreSettings();

  const [currency, setCurrency] = useState<CurrencyCode>('USD');

  useEffect(
    function setDefaultCurrency() {
      if (!storeSettings.data) return;

      setCurrency(storeSettings.data.default_currency);
    },
    [storeSettings.data]
  );

  return (
    <StoreListingsTableContext.Provider
      value={{
        currency,
        setCurrency,
      }}
    >
      {children}
    </StoreListingsTableContext.Provider>
  );
}

export function useStoreListingsTableContext() {
  const context = useContext(StoreListingsTableContext);

  if (!context) {
    throw new Error(
      'useStoreListingsTableContext must be used within a StoreListingsTableContextProvider'
    );
  }

  return context;
}
