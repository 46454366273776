import { Menu } from '@mantine/core';
import React, { Dispatch, SetStateAction } from 'react';

import { PartnerInvoiceType } from '@portals/api/partners';
import { DetailsPanel } from '@portals/core';
import { MarkAsUncollectibleModalProps } from '@portals/framework/modals';
import { ReactComponent as CardSlash } from '@portals/icons/linear/card-slash.svg';
import { ReactComponent as More } from '@portals/icons/linear/more.svg';
import { useOpenModal } from '@portals/redux';

import { useUploadReceipt } from '../../../../hooks/Receipt/receipt.hooks';

interface InvoicePanelActionsProps {
  invoice: PartnerInvoiceType;
  isItemsPanelOpen: boolean;
  setIsItemsPanelOpen: Dispatch<SetStateAction<boolean>>;
  isReceiptPanelOpen: boolean;
  setIsReceiptPanelOpen: Dispatch<SetStateAction<boolean>>;
}

export function InvoicePanelActions({
  invoice,
  isItemsPanelOpen,
  setIsItemsPanelOpen,
  isReceiptPanelOpen,
  setIsReceiptPanelOpen,
}: InvoicePanelActionsProps) {
  const uploadReceiptFile = useUploadReceipt(invoice?.id);

  if (invoice.status === 'pending') {
    return (
      <DetailsPanel.Actions grow={false}>
        {invoice?.invoice.invoice_file_url ? (
          <DetailsPanel.ActionButton
            onClick={() => setIsReceiptPanelOpen(!isReceiptPanelOpen)}
            w="100%"
          >
            {isReceiptPanelOpen ? 'Hide Receipt' : 'Show Receipt'}
          </DetailsPanel.ActionButton>
        ) : null}

        <DetailsPanel.ActionButton
          onClick={() => setIsItemsPanelOpen(!isItemsPanelOpen)}
          w="100%"
        >
          {isItemsPanelOpen ? 'Hide Items' : 'Show Items'}
        </DetailsPanel.ActionButton>

        <ExtraActionsMenu invoice={invoice} />
      </DetailsPanel.Actions>
    );
  }

  if (invoice.status !== 'failed') {
    return (
      <DetailsPanel.Actions>
        {invoice?.invoice.invoice_file_url ? (
          <DetailsPanel.ActionButton
            onClick={() => setIsReceiptPanelOpen(!isReceiptPanelOpen)}
          >
            {isReceiptPanelOpen ? 'Hide Receipt' : 'Show Receipt'}
          </DetailsPanel.ActionButton>
        ) : null}

        {invoice.status === 'paid' && !invoice.invoice.invoice_file_url ? (
          <DetailsPanel.ActionButton
            variant="filled"
            onClick={uploadReceiptFile}
            w="100%"
          >
            Upload Receipt
          </DetailsPanel.ActionButton>
        ) : null}

        <DetailsPanel.ActionButton
          onClick={() => setIsItemsPanelOpen(!isItemsPanelOpen)}
        >
          {isItemsPanelOpen ? 'Hide Items' : 'Show Items'}
        </DetailsPanel.ActionButton>
      </DetailsPanel.Actions>
    );
  }

  return (
    <DetailsPanel.Actions grow>
      <DetailsPanel.ActionButton
        onClick={() => setIsItemsPanelOpen(!isItemsPanelOpen)}
      >
        {isItemsPanelOpen ? 'Hide Items' : 'Show Items'}
      </DetailsPanel.ActionButton>

      <DetailsPanel.ActionButton
        onClick={() => window.open('mailto:support@xyte.io', '_blank')}
      >
        Contact support
      </DetailsPanel.ActionButton>
    </DetailsPanel.Actions>
  );
}

function ExtraActionsMenu({ invoice }: { invoice: PartnerInvoiceType }) {
  const openModal = useOpenModal();

  return (
    <DetailsPanel.ActionButton>
      <Menu position="bottom-end">
        <Menu.Target>
          <More width={40} />
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item
            color="red"
            p="sm"
            onClick={() =>
              openModal<MarkAsUncollectibleModalProps['data']>(
                'MarkAsUncollectibleModal',
                { invoiceId: invoice.id }
              )
            }
            icon={<CardSlash />}
          >
            Mark as Uncollectible
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </DetailsPanel.ActionButton>
  );
}
