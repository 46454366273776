import { LoadingOverlay, useMantineTheme } from '@mantine/core';
import { useIsFetching } from '@tanstack/react-query';
import { AnimatePresence, motion } from 'framer-motion';
import { flow, set } from 'lodash/fp';
import React from 'react';

import { deviceModelsQueryKeys, DeviceModelType } from '@portals/api/partners';
import { ReactComponent as CloudLightning } from '@portals/icons/bulk/cloud-lightning.svg';
import { ReactComponent as TagIcon } from '@portals/icons/bulk/tag.svg';

import { CreatableDeviceModelSelect } from '../common/CreatableDeviceModelSelect';
import { DetailsColumnContent } from '../common/DetailsColumnContent';
import { RadioCard } from '../common/RadioCard';
import { RadioCardGroup } from '../common/RadioCardGroup';
import { RadioCardLabel } from '../common/RadioCardLabel';
import { PhysicalIntegrationType, StepId } from '../types';
import { useProductWizard } from '../use-product-wizard';

export function PhysicalProductIntegration() {
  const theme = useMantineTheme();

  const { setContextData, contextData } = useProductWizard();
  const isFetching = useIsFetching(deviceModelsQueryKeys.base) > 0;

  const onIntegrationTypeChange = (value: string) => {
    // reset model id if user selected 'none'
    const deviceModelId =
      (value as PhysicalIntegrationType) === 'none'
        ? null
        : contextData.product.device_model_id || null;

    setContextData((prev) =>
      flow([
        set('physicalIntegrationType', value as PhysicalIntegrationType),
        set('product.device_model_id', deviceModelId),
        set(['errors', StepId.Integration], ''),
        set(['errors', StepId.ProductDetails, 'licenseData'], ''),
      ])(prev)
    );
  };

  const onDeviceModelChange = (deviceModel: DeviceModelType) => {
    const licenseDataError =
      deviceModel &&
      contextData.intent === 'edit' &&
      !contextData.product.license_data
        ? 'License data is a required field'
        : '';

    setContextData((prev) =>
      flow([
        set('physicalIntegrationType', 'device'),
        set(['product', 'device_model_id'], deviceModel.id),
        set(['product', 'sku'], deviceModel.sku || prev.product.sku),
        set(['errors', StepId.Integration], ''),
        set(['errors', StepId.ProductDetails, 'licenseData'], licenseDataError),
      ])(prev)
    );
  };

  return (
    <DetailsColumnContent title="Product Type">
      <DetailsColumnContent.Section
        title="Is this product based on a connected device model?"
        subtitle="Products based on connected devices can be remotely monitored, managed and supported by your customers and relevant partner network"
      >
        <LoadingOverlay visible={isFetching} />

        <RadioCardGroup
          error={contextData.errors[StepId.Integration]}
          required
          name="integration_type"
          value={contextData.physicalIntegrationType ?? undefined}
          onChange={onIntegrationTypeChange}
        >
          <RadioCard
            value="device"
            label={
              <RadioCardLabel
                title="Yes, it's a connected product"
                data-testid="device-integration-radio-button"
                icon={
                  <CloudLightning
                    width={34}
                    height={34}
                    color={theme.colors.blue_accent[1]}
                  />
                }
              >
                <AnimatePresence initial={false}>
                  {contextData.physicalIntegrationType === 'device' && (
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                    >
                      <CreatableDeviceModelSelect
                        required
                        error={contextData.errors[StepId.Integration] !== ''}
                        value={contextData.product.device_model_id}
                        onChange={onDeviceModelChange}
                        onAfterCreate={onDeviceModelChange}
                      />
                    </motion.div>
                  )}
                </AnimatePresence>
              </RadioCardLabel>
            }
          />
          <RadioCard
            value="none"
            label={
              <RadioCardLabel
                title="No, it's a standalone product"
                icon={
                  <TagIcon
                    width={34}
                    height={34}
                    color={theme.colors.blue_accent[1]}
                  />
                }
              />
            }
          />
        </RadioCardGroup>
      </DetailsColumnContent.Section>
    </DetailsColumnContent>
  );
}
