import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { CommandsView, useDeviceCommandsChannel } from '@portals/framework';
import { useOpenModal } from '@portals/redux';

import {
  cancelCommand,
  getTicketDevice,
} from '../../redux/actions/ticket_device';

export const Commands = ({ ticket, device }) => {
  const dispatch = useDispatch();
  const openModal = useOpenModal();

  const [subscriptionParams, messageHandler] = useMemo(() => {
    return [
      { device_id: device.id },
      () => dispatch(getTicketDevice(ticket.id)),
    ];
  }, [device.id, dispatch, ticket.id]);

  useDeviceCommandsChannel({
    subscriptionParams,
    messageHandler,
  });

  return (
    <div className="h-100 p-3">
      <CommandsView
        tableName="partners.tickets.device.commands"
        isUrlSyncEnabled={false}
        commands={device.commands}
        openModal={openModal}
        cancelCommand={(deviceId, id) => dispatch(cancelCommand(ticket.id, id))}
      />
    </div>
  );
};
