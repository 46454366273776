import {
  Anchor,
  Box,
  Center,
  createStyles,
  LoadingOverlay,
  Stack,
  Text,
} from '@mantine/core';
import { last, split } from 'lodash/fp';
import { PDFDocumentProxy } from 'pdfjs-dist';
import React, { useCallback } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useEffectOnce } from 'react-use';

import { downloadFile } from '@portals/utils';

import { ReactComponent as ChooseTaxGroupIcon } from '../assets/img/choose-tax-group.svg';

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

interface FilePreviewProps {
  fileUrl: string;
  fileName: string;
  PDFDocumentProxy: PDFDocumentProxy;
  setPDFDocumentProxy: (value: PDFDocumentProxy) => void;
  isFailedToLoad: boolean;
  setIsFailedToLoad: (value: boolean) => void;
  isDownloaded: boolean;
  setIsDownloaded: (value: boolean) => void;
  withAutomaticDownload?: boolean;
}
export function FilePreview({
  withAutomaticDownload = true,
  fileUrl,
  fileName,
  PDFDocumentProxy,
  setPDFDocumentProxy,
  isFailedToLoad,
  setIsFailedToLoad,
  isDownloaded,
  setIsDownloaded,
}: FilePreviewProps) {
  const { classes } = useStyles();

  const fileFormat = last(split('.', fileUrl));

  useEffectOnce(() => {
    if (fileFormat !== 'pdf' && !isDownloaded && withAutomaticDownload) {
      onDownload();
    }
  });

  const onDownload = useCallback(() => {
    if (fileFormat !== 'pdf' && !isDownloaded) {
      setIsDownloaded(false);
    }

    downloadFile(fileUrl, fileName);
  }, [fileFormat, isDownloaded, fileUrl, fileName, setIsDownloaded]);

  if (fileFormat !== 'pdf' || isFailedToLoad) {
    return (
      <Center h="100%">
        <Stack align="center">
          <ChooseTaxGroupIcon width={158} height={158} />
          {withAutomaticDownload ? (
            <Stack align="center">
              <Text color="gray.8">
                No preview available. Downloading {fileName}....
              </Text>

              <Stack spacing={0} align="center">
                <Text size="xs" color="gray.5">
                  Download should start automatically in a few seconds.
                </Text>

                <Text size="xs" color="gray.5">
                  If it doesn't,{' '}
                  <Anchor
                    onClick={onDownload}
                    data-testid={`no-preview-download-${fileName}-file-button`}
                  >
                    please click here
                  </Anchor>
                </Text>
              </Stack>
            </Stack>
          ) : (
            <Stack align="center" spacing="xs">
              <Text color="gray.8">No preview available. </Text>
              <Anchor
                onClick={onDownload}
                data-testid={`no-preview-download-${fileName}-file-button`}
              >
                please click here to download the {fileName}
              </Anchor>
            </Stack>
          )}
        </Stack>
      </Center>
    );
  }

  return (
    <>
      <LoadingOverlay visible={!PDFDocumentProxy?.numPages} />

      <Box className={classes.fileWrapper}>
        <Document
          file={fileUrl}
          onLoadSuccess={setPDFDocumentProxy}
          onLoadError={() => setIsFailedToLoad(true)}
        >
          {Array.from(
            new Array(PDFDocumentProxy?.numPages || 0),
            (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                scale={1.5}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              />
            )
          )}
        </Document>
      </Box>
    </>
  );
}

const useStyles = createStyles((theme) => ({
  fileWrapper: {
    border: `1px solid ${theme.colors.gray[2]}`,
    borderRadius: theme.radius.md,
  },
}));
