import { useDescope } from '@descope/react-sdk';
import {
  Box,
  createStyles,
  PasswordInput,
  PasswordInputProps,
  Progress,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import React, { useState } from 'react';
import { useEffectOnce } from 'react-use';
import { ref, string } from 'yup';

import { ReactComponent as CloseCircle } from '@portals/icons/linear/close-circle.svg';
import { ReactComponent as TickCircle } from '@portals/icons/linear/tick-circle.svg';

interface PasswordRequirementProps {
  meets: boolean;
  label: string;
}

function PasswordRequirement({ meets, label }: PasswordRequirementProps) {
  return (
    <Text
      color={meets ? 'teal' : 'red'}
      sx={{ display: 'flex', alignItems: 'center' }}
      mt={7}
      data-testid="password-error-message"
      size="sm"
    >
      {meets ? (
        <TickCircle width={14} height={14} />
      ) : (
        <CloseCircle width={14} height={14} />
      )}{' '}
      <Box ml={10}>{label}</Box>
    </Text>
  );
}

interface Requirement {
  regex: RegExp;
  label: string;
  descopePasswordRequirement: keyof Awaited<
    ReturnType<ReturnType<typeof useDescope>['password']['policy']>
  >['data'];
}

const REQUIREMENTS: Requirement[] = [
  {
    regex: /[0-9]/,
    label: 'Must include a number',
    descopePasswordRequirement: 'number',
  },
  {
    regex: /[A-Z]/,
    label: 'Must include an uppercase letter',
    descopePasswordRequirement: 'uppercase',
  },
  {
    regex: /.{10,100}/,
    label: 'Must contain between 10 and 100 characters',
    descopePasswordRequirement: 'minLength',
  },
  {
    regex: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>]/,
    label: 'Must include a special character',
    descopePasswordRequirement: 'nonAlphanumeric',
  },
];

interface PasswordInputWithRequirementsProps {
  label?: PasswordInputProps['label'];
  placeholder?: PasswordInputProps['placeholder'];
  value: string;
  onChange: PasswordInputProps['onChange'];
  error?: PasswordInputProps['error'];
}

export function PasswordInputWithRequirements({
  label = 'Password',
  placeholder = 'Password',
  value,
  onChange,
  error,
}: PasswordInputWithRequirementsProps) {
  const { classes } = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const descope = useDescope();

  const [descopeKeyRequirements, setDescopeKeyRequirements] = useState<
    string[]
  >([]);

  useEffectOnce(function setDescopePasswordRequirement() {
    const getPasswordRequirements = async () => {
      return await descope.password.policy();
    };

    getPasswordRequirements().then((descopeSdkResponse) => {
      if (!descopeSdkResponse.data) {
        return;
      }

      setDescopeKeyRequirements(Object.keys(descopeSdkResponse.data));
    });
  });

  const descopeRequirements = REQUIREMENTS.filter((requirement) =>
    descopeKeyRequirements?.includes(requirement.descopePasswordRequirement)
  );

  function getStrength(password: string) {
    let multiplier = password.length > 9 ? 0 : 1;

    descopeRequirements.forEach((requirement) => {
      if (!requirement.regex.test(password)) {
        multiplier += 1;
      }
    });

    return Math.max(100 - (100 / (REQUIREMENTS.length + 1)) * multiplier, 10);
  }

  const strength = getStrength(value);
  const color = strength === 100 ? 'teal' : strength > 50 ? 'yellow' : 'red';

  return (
    <Tooltip
      opened={isOpen}
      withinPortal
      withArrow
      arrowSize={10}
      position="top-start"
      classNames={{
        tooltip: classes.tooltip,
      }}
      label={
        <Stack spacing={4} p="sm">
          <Progress color={color} value={strength} size={5} mb={10} />

          {descopeRequirements.map((requirement, index) => (
            <PasswordRequirement
              key={index}
              label={requirement.label}
              meets={requirement.regex.test(value)}
            />
          ))}
        </Stack>
      }
    >
      <PasswordInput
        required
        error={error}
        label={label}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        data-testid="password-input"
        onFocusCapture={() => setIsOpen(true)}
        onBlurCapture={() => setIsOpen(false)}
      />
    </Tooltip>
  );
}

export const yupPasswordValidator = string()
  .required()
  .matches(REQUIREMENTS[3].regex, REQUIREMENTS[3].label)
  .matches(REQUIREMENTS[2].regex, REQUIREMENTS[2].label)
  .matches(REQUIREMENTS[1].regex, REQUIREMENTS[1].label)
  .matches(REQUIREMENTS[0].regex, REQUIREMENTS[0].label);

export function yupPasswordConfirmValidator(passwordFieldName: string) {
  return string()
    .required()
    .oneOf([ref(passwordFieldName), null], 'Passwords must match');
}

const useStyles = createStyles((theme) => ({
  tooltip: {
    pointerEvents: 'all',
    background: 'white',
    filter: 'drop-shadow(-4px 4px 36px rgba(0, 2, 41, 0.1))',
  },
}));
