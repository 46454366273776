import {
  Avatar,
  AvatarProps,
  Indicator,
  IndicatorProps,
  Text,
  Tooltip,
} from '@mantine/core';
import React from 'react';

import { ReactComponent as AddCircle } from '@portals/icons/bold/add-circle.svg';
import { ReactComponent as UserIcon } from '@portals/icons/linear/user.svg';
import { suppressPropagation } from '@portals/utils';

import { NameAbbreviationAvatar } from './NameAbbreviationAvatar';

interface UsersAvatarGroupProps {
  users: Array<{ id: string; name: string }>;
  onAddUserClick: () => void;
  numOfUsersToShow?: number;
  size?: AvatarProps['size'];
}

export function UsersAvatarGroup({
  users,
  onAddUserClick,
  numOfUsersToShow = 3,
  size,
}: UsersAvatarGroupProps) {
  const visibleUserAvatars = users.slice(0, numOfUsersToShow);
  const hiddenUsers = users.slice(numOfUsersToShow);

  return (
    <Tooltip.Group openDelay={300} closeDelay={100}>
      <Avatar.Group spacing="xs">
        {visibleUserAvatars.map((user, index) => (
          <NameAbbreviationAvatar
            key={user.id}
            name={user.name}
            sx={{ zIndex: visibleUserAvatars.length - index + 1 }}
            radius="xl"
            size={size}
          />
        ))}
        {hiddenUsers.length > 0 && (
          <Avatar
            variant="filled"
            color="purple"
            radius="xl"
            size={size}
            sx={{ zIndex: 1 }}
          >
            <Text weight={500}>+{hiddenUsers.length}</Text>
          </Avatar>
        )}
        <Tooltip label="Add Member" withArrow withinPortal>
          <Indicator
            withBorder
            size={20}
            offset={8}
            position="bottom-end"
            styles={indicatorStyles}
            label={<AddCircle width={20} height={20} />}
            onClick={suppressPropagation(onAddUserClick)}
          >
            <Avatar styles={avatarStyles} radius="xl" size={size}>
              <UserIcon width="80%" height="80%" />
            </Avatar>
          </Indicator>
        </Tooltip>
      </Avatar.Group>
    </Tooltip.Group>
  );
}

const indicatorStyles: IndicatorProps['styles'] = (theme) => ({
  root: {
    color: theme.colors.gray[4],
    cursor: 'pointer',

    '&:hover': {
      color: theme.colors.gray[5],
    },
  },
  common: {
    padding: 0,
    color: 'inherit',
    backgroundColor: theme.white,
  },
});

const avatarStyles: AvatarProps['styles'] = (theme) => ({
  root: {
    cursor: 'pointer',
    color: 'inherit',
    backgroundColor: theme.white,
  },
  placeholder: {
    border: `2px dashed`,
    color: 'inherit',
    backgroundColor: theme.white,
  },
});
