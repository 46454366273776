import { noop } from 'lodash/fp';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
} from 'react';
import React from 'react';

import { PortalConfigType } from '@portals/api/partners';

interface BrandingContextType {
  portalConfig: Partial<PortalConfigType>;
  updatePortalConfig: Dispatch<SetStateAction<PortalConfigType>>;
  isPristine: boolean;
  isLoading: boolean;
  revertChanges: () => void;
  saveChanges: () => void;
  defaultValues: Partial<PortalConfigType>;
}

const BrandingContext = createContext<BrandingContextType | null>({
  portalConfig: {},
  isPristine: true,
  isLoading: false,
  updatePortalConfig: noop,
  revertChanges: noop,
  saveChanges: noop,
  defaultValues: {},
});

interface BrandingProviderProps {
  children: ReactNode;
  value: BrandingContextType;
}

export function BrandingProvider({ children, value }: BrandingProviderProps) {
  return (
    <BrandingContext.Provider value={value}>
      {children}
    </BrandingContext.Provider>
  );
}

export function useBrandingContext() {
  const context = useContext(BrandingContext);

  if (context === null) {
    throw new Error('useBrandingContext must be used within BrandingProvider');
  }

  return context;
}
