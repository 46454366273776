import {
  Button,
  Group,
  LoadingOverlay,
  Stack,
  useMantineTheme,
} from '@mantine/core';
import { useDebouncedValue, useElementSize } from '@mantine/hooks';
import { find, forEach, isEmpty, isNumber } from 'lodash/fp';
import React, { useCallback } from 'react';
import { Layout, Responsive, WidthProvider } from 'react-grid-layout';
import { useParams } from 'react-router-dom';

import {
  useDeviceModelWidgetsData,
  useMigrateLegacyWidgets,
  useUpdateDeviceModelWidgets,
} from '@portals/api/partners';
import { migrateWidgetsAndAdjustLayout } from '@portals/device-widgets';
import {
  ModalButton,
  PermissionAccess,
  usePermissionAccess,
} from '@portals/framework';
import { ReactComponent as Add } from '@portals/icons/linear/add.svg';
import { ReactComponent as MagicPen } from '@portals/icons/linear/magicpen.svg';
import { VerticalScrollBar } from '@portals/scrollbar';
import { EmptyState } from '@portals/table';
import { AccessLevelEnum } from '@portals/types';

import { LegacyWidgetPreview } from './LegacyWidgetPreview';

const ResponsiveGridLayout = WidthProvider(Responsive);

export function LegacyMonitoringDashboard() {
  const { id } = useParams<{ id?: string }>();
  const { canEdit } = usePermissionAccess();
  const { ref, width } = useElementSize();
  const [debouncedWidth] = useDebouncedValue(width, 150);
  const theme = useMantineTheme();

  const legacyDeviceWidgets = useDeviceModelWidgetsData(id);
  const updateWidgets = useUpdateDeviceModelWidgets(id);
  const migrateLegacyWidgets = useMigrateLegacyWidgets(id);

  const widgets = (legacyDeviceWidgets.data || []).map(
    ({ id, name, config }) => ({
      i: id,
      x: isNumber(config?.layout?.x) ? config?.layout?.x : 0,
      y: isNumber(config?.layout?.y) ? config?.layout?.y : 0,
      h: isNumber(config?.layout?.h) ? config?.layout?.h : 1,
      w: isNumber(config?.layout?.w) ? config?.layout?.w : config.width,
      minW: config.id === 'line_chart' ? 6 : 3,
      config,
      name,
    })
  );

  const onLayoutChange = useCallback(
    (layout: Array<Layout>) => {
      const widgetsToUpdate = [];

      forEach((itemLayout) => {
        const widgetId = itemLayout.i;

        if (widgetId === 'placeholder') {
          return;
        }

        const currentWidget = find({ id: widgetId }, legacyDeviceWidgets.data);

        if (!currentWidget) return;

        const hasChanged =
          currentWidget?.config?.layout?.x !== itemLayout.x ||
          currentWidget?.config?.layout?.y !== itemLayout.y ||
          currentWidget?.config?.layout?.h !== itemLayout.h ||
          currentWidget?.config?.layout?.w !== itemLayout.w;

        if (hasChanged) {
          const updatedWidget = {
            ...currentWidget,
            config: {
              ...currentWidget.config,
              layout: itemLayout,
            },
          };

          widgetsToUpdate.push(updatedWidget);
        }
      }, layout);

      if (!isEmpty(widgetsToUpdate)) {
        updateWidgets.mutate(widgetsToUpdate);
      }
    },
    [legacyDeviceWidgets.data, updateWidgets]
  );

  const onMigrate = async () => {
    const migratedWidgets = migrateWidgetsAndAdjustLayout(
      legacyDeviceWidgets.data
    );

    try {
      await migrateLegacyWidgets.mutateAsync({
        widgets: migratedWidgets,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Stack h="100%" p={0} ref={ref} pos="relative" maw={1260}>
      <LoadingOverlay
        visible={
          !legacyDeviceWidgets.isFetched ||
          updateWidgets.isLoading ||
          migrateLegacyWidgets.isLoading
        }
      />

      {isEmpty(widgets) ? null : (
        <Group position="right" align="center">
          <>
            <PermissionAccess
              minAccessLevel={AccessLevelEnum.Edit}
              permissionKeys="models"
            >
              <ModalButton
                modalName="AddModelWidget"
                label="Add widget"
                data={id}
                leftIcon={<Add width={16} height={16} />}
              />
            </PermissionAccess>

            <PermissionAccess
              minAccessLevel={AccessLevelEnum.Edit}
              permissionKeys="models"
            >
              <Button
                variant="default"
                size="xs"
                onClick={onMigrate}
                leftIcon={<MagicPen width={16} height={16} />}
              >
                Migrate widgets
              </Button>
            </PermissionAccess>
          </>
        </Group>
      )}

      <VerticalScrollBar
        style={{
          border: `1px solid ${theme.colors.gray[2]}`,
          backgroundColor: theme.colors.gray[0],
        }}
      >
        {isEmpty(widgets) ? (
          <EmptyState label="" src="empty-state-widgets">
            <PermissionAccess
              minAccessLevel={AccessLevelEnum.Edit}
              permissionKeys="models"
            >
              <ModalButton
                modalName="AddModelWidget"
                label="Add widget"
                size="md"
                data={id}
                mt="lg"
                data-testid="add-widget-button"
                leftIcon={<Add />}
                styles={{
                  leftIcon: {
                    width: 20,
                    height: 20,
                  },
                }}
              />
            </PermissionAccess>
          </EmptyState>
        ) : (
          <ResponsiveGridLayout
            breakpoint="lg"
            key={debouncedWidth}
            cols={{
              lg: 12,
              md: 12,
              sm: 12,
              xs: 12,
              xxs: 12,
            }}
            layouts={{
              lg: widgets,
            }}
            rowHeight={300}
            containerPadding={[15, 15]}
            className="layout"
            isResizable={canEdit('models')}
            isDraggable={canEdit('models')}
            onLayoutChange={onLayoutChange}
            measureBeforeMount
            useCSSTransforms={false}
          >
            {widgets.map(({ i, name, config }) => (
              <div key={i}>
                <LegacyWidgetPreview
                  id={i}
                  name={name}
                  config={config}
                  modelId={id}
                />
              </div>
            ))}
          </ResponsiveGridLayout>
        )}
      </VerticalScrollBar>
    </Stack>
  );
}
