import { Box, createStyles } from '@mantine/core';
import React from 'react';

import { StackedGuideItem } from './StackedGuideItem';
import devHubBg from '../../../assets/img/dev-hub.svg';
import getStartedBg from '../../../assets/img/get-started.svg';
import scheduleDemoBg from '../../../assets/img/schedule-demo.svg';
import { CardContainer, MainContentCardTitle } from '../common';

const useStyles = createStyles((theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  guideList: {
    flex: 1,
    display: 'grid',
    gridAutoRows: 'minmax(80px, auto)',
    gridGap: theme.spacing.xs,

    [theme.fn.largerThan('xl')]: {
      gridGap: theme.spacing.md,
    },
  },
}));

export function StackedGuidesCard() {
  const { classes } = useStyles();

  return (
    <CardContainer className={classes.container}>
      <Box mb="xl">
        <MainContentCardTitle>Learn & Get Inspired</MainContentCardTitle>
      </Box>

      <div className={classes.guideList}>
        <StackedGuideItem
          linkTo="https://dev.xyte.io/docs"
          title="Getting Started"
          subtitle="Learn how xyte.io works"
          imageSrc={getStartedBg}
          bgColor="rgba(233, 236, 255, 0.6)"
          hoverBgColor="rgba(233, 236, 255, 0.9)"
        />
        <StackedGuideItem
          linkTo="https://dev.xyte.io/reference"
          title="Developers Hub"
          subtitle="Learn more about our API"
          imageSrc={devHubBg}
          bgColor="rgba(255, 235, 238, 0.5)"
          hoverBgColor="rgba(255, 235, 238, 0.8)"
        />
        <StackedGuideItem
          linkTo="https://calendly.com/mcgowan-xyte/30min"
          title="Schedule an Intro"
          subtitle="Let's get in touch!"
          imageSrc={scheduleDemoBg}
          bgColor="rgba(224, 242, 241, 0.5)"
          hoverBgColor="rgba(224, 242, 241, 0.8)"
        />
      </div>
    </CardContainer>
  );
}
