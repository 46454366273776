import { Button } from '@mantine/core';
import { forEach, keys } from 'lodash/fp';
import React, { FC } from 'react';

import { useConfirmationModal } from '@portals/framework';
import { ReactComponent as CloseSquare } from '@portals/icons/linear/close-square.svg';
import { useTableInstance } from '@portals/table';
import { SupportedCommandType } from '@portals/types';

interface CommandsSelectedActionsProps {
  onRemove: (commandId: string) => void;
  commands: Array<SupportedCommandType>;
}

const CommandsSelectedActions: FC<CommandsSelectedActionsProps> = ({
  onRemove,
  commands,
}) => {
  const asyncConfirmationCheck = useConfirmationModal();
  const { state, toggleAllRowsSelected } = useTableInstance();
  const { selectedRowIds } = state;

  const onRemoveSelected = async () => {
    const isConfirmed = await asyncConfirmationCheck({
      description: 'Are you sure?',
    });

    if (isConfirmed) {
      forEach((rowIndex) => {
        const commandId = commands[rowIndex]?.id;

        if (commandId) {
          onRemove(commandId);
        }
      }, keys(selectedRowIds));

      toggleAllRowsSelected(false);
    }
  };

  return (
    <Button
      variant="subtle"
      color="red"
      size="xs"
      px={5}
      styles={{
        leftIcon: {
          width: 16,
          height: 16,
        },
      }}
      onClick={onRemoveSelected}
      leftIcon={<CloseSquare />}
    >
      Remove
    </Button>
  );
};

export default CommandsSelectedActions;
