import { Anchor, Group, Paper, Stack, Text } from '@mantine/core';
import React from 'react';

import { usePartnerConfig } from '@portals/api/partners';
import { ReactComponent as ArrowRight } from '@portals/icons/linear/arrow-right-1.svg';

export function Billing() {
  const partnerConfig = usePartnerConfig();

  return (
    <Paper withBorder p="xl" radius="md">
      <Stack spacing="xl">
        <Text size="md" color="gray.8">
          Billing
        </Text>

        <Group position="apart" align="center" h={48}>
          <Text color="blue_gray.8">Show invoices</Text>

          <Anchor
            href={partnerConfig.billing_portal_url}
            target="_blank"
            color="blue_accent.4"
          >
            <ArrowRight height={18} width={18} />
          </Anchor>
        </Group>
      </Stack>
    </Paper>
  );
}
