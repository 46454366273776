import { Avatar, Button, Grid, Stack, Switch, Text } from '@mantine/core';
import { first } from 'lodash/fp';
import React, { useState } from 'react';

import { PartnerTypeSelection } from '../components/PartnerTypeSelection';
import { StepsFooter } from '../components/StepsFooter';
import { StepsHeader } from '../components/StepsHeader';
import { useInvitePartnerWizard } from '../hooks';

export function ExistingPartnerType() {
  const { contextData, onPrev, isNextDisabled } = useInvitePartnerWizard();
  const { selectedPartner } = contextData;

  const [isChecked, setIsChecked] = useState(true);

  return (
    <>
      <StepsHeader
        title={
          <>
            What is your relationship with&nbsp;
            <Text span weight={700}>
              {selectedPartner?.label}
            </Text>
          </>
        }
        icon={
          <Avatar
            size="xl"
            color="blue"
            src={selectedPartner?.logo}
            sx={{ borderRadius: '50%' }}
          >
            {first(selectedPartner?.label)}
          </Avatar>
        }
        description="This description is for your internal use only. Your partner will not see this."
      />

      <PartnerTypeSelection />

      <Stack mt="md" spacing="xs">
        <Text size="sm">This partner is a certified service provider</Text>

        <Grid align="center" justify="space-between">
          <Grid.Col span={10}>
            <Text size="sm" color="gray.5" align="justify">
              Enable this option to mark the selected partner as a certified
              service provider. Once marked, your customers will be able to
              directly contact this partner for support and services related to
              your products.
            </Text>
          </Grid.Col>

          <Grid.Col span="content">
            <Switch
              checked={isChecked}
              onChange={(event) => setIsChecked(event.target.checked)}
            />
          </Grid.Col>
        </Grid>
      </Stack>

      <StepsFooter>
        <Button variant="default" onClick={onPrev}>
          Back
        </Button>

        <Button
          loading={contextData.isLoading}
          disabled={isNextDisabled}
          onClick={() => contextData.onInviteExisting(isChecked)}
        >
          Invite
        </Button>
      </StepsFooter>
    </>
  );
}
