import { Button, Group, Paper, Stack, Text } from '@mantine/core';
import { isEmpty } from 'lodash/fp';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useTaxGroups } from '@portals/api/partners';
import { PermissionAccess } from '@portals/framework';
import { AccessLevelEnum } from '@portals/types';

import { TaxGroupsList } from './TaxGroupsList';

export const TaxGroups = () => {
  const navigate = useNavigate();

  const groups = useTaxGroups();

  return (
    <Paper p="xl" withBorder>
      <Stack spacing="xs">
        <Group position="apart" align="start">
          <Stack>
            <Text sx={(theme) => ({ color: theme.colors.gray[8] })}>
              Tax Groups
            </Text>

            <Text size="sm" sx={(theme) => ({ color: theme.colors.gray[5] })}>
              {isEmpty(groups.data)
                ? 'Tax groups help you create groups for products that need special tax rate'
                : 'Create groups for products that need special tax rate'}
            </Text>
          </Stack>

          <PermissionAccess
            minAccessLevel={AccessLevelEnum.Edit}
            permissionKeys="store_management"
          >
            <Button
              variant="default"
              onClick={() => navigate(`/settings/store/create-tax-group`)}
            >
              Add Group
            </Button>
          </PermissionAccess>
        </Group>

        {!isEmpty(groups.data) && <TaxGroupsList groups={groups.data} />}
      </Stack>
    </Paper>
  );
};
