import { createStyles, Select, SelectProps, Stack, Text } from '@mantine/core';
import { map } from 'lodash/fp';
import React from 'react';

import { DeviceModelType, useDeviceModels } from '@portals/api/partners';
import { useOpenModal } from '@portals/redux';

import { ConfigureModelProps } from '../../ConfigureModel';

interface CreatableDeviceModelSelectProps
  extends Omit<SelectProps, 'data' | 'onChange'> {
  onChange: (deviceModel: DeviceModelType) => void;
  onAfterCreate: (deviceModel: DeviceModelType) => void;
}

export function CreatableDeviceModelSelect({
  onChange,
  onAfterCreate,
  ...selectProps
}: CreatableDeviceModelSelectProps) {
  const { classes } = useStyles();

  const openModal = useOpenModal();

  const deviceModels = useDeviceModels();

  const deviceModelOptions = composeOptions(deviceModels);

  return (
    <Stack>
      <Select
        searchable
        disabled={deviceModels.isLoading}
        placeholder="Select device model..."
        data-testid="select-device-model"
        data={deviceModelOptions}
        onChange={(deviceModelId) => {
          const deviceModel = deviceModels.data?.find(
            (deviceModel) => deviceModel.id === deviceModelId
          ) as DeviceModelType;

          onChange(deviceModel);
        }}
        {...selectProps}
      />

      <Text>
        or{' '}
        <Text
          span
          color="primary"
          className={classes.createModelLink}
          data-testid="create-model-link"
          onClick={() =>
            openModal<ConfigureModelProps['data']>('ConfigureModel', {
              isCreate: true,
              model: undefined,
              onSuccess: onAfterCreate,
            })
          }
        >
          create a new model
        </Text>
      </Text>
    </Stack>
  );
}

const useStyles = createStyles(() => ({
  createModelLink: {
    cursor: 'pointer',
  },
}));

function composeOptions(deviceModels: ReturnType<typeof useDeviceModels>) {
  return map(
    ({ id, model }) => ({ label: model, value: id }),
    deviceModels?.data
  );
}
