import { Box, createStyles, LoadingOverlay } from '@mantine/core';
import { GeoJSON, Point } from 'geojson';
import { size } from 'lodash/fp';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { PartnerIncidentType, useIncidents } from '@portals/api/partners';
import {
  DeviceIncidentMarker,
  Map,
  MapListItem,
  PARTNERS_PRIORITY_COLORS,
} from '@portals/framework';
import { PaginatedQueryFilterType } from '@portals/types';
import { DEFAULT_MAP_CENTER, PRIORITY_OPTIONS } from '@portals/utils';

const mapper =
  (navigate: ReturnType<typeof useNavigate>) =>
  (incident: PartnerIncidentType) => {
    const severityCategory = PRIORITY_OPTIONS[incident.priority];
    const color = PARTNERS_PRIORITY_COLORS[incident.priority - 1];
    const onClick = () => navigate(`/incidents/${incident.id}`);

    return {
      id: incident.id,
      priority: incident.priority,
      severityCategory,
      renderSinglePoint: () => (
        <DeviceIncidentMarker
          title={incident.title}
          description={incident.device_name}
          color={color}
          onClick={onClick}
        />
      ),
      renderClusterItem: () => (
        <MapListItem
          key={incident.id}
          title={incident.title}
          description={incident.device_name}
          color={color}
          onClick={onClick}
        />
      ),
    };
  };

const childrenToPoints = (
  incidents: Array<PartnerIncidentType>,
  mapper: (partnerIncident: PartnerIncidentType) => Record<string, any>
) => {
  return incidents
    .map((incident): GeoJSON.Feature<Point> | null => {
      if (!incident.space_coordinates) {
        return null;
      }

      return {
        type: 'Feature',
        properties: { cluster: false, ...mapper(incident) },
        geometry: {
          type: 'Point',
          coordinates: [
            incident.space_coordinates.lng,
            incident.space_coordinates.lat,
          ],
        },
      };
    })
    .filter((item) => item !== null)
    .sort((a, b) =>
      (a?.properties?.priority || 0) > (b?.properties?.priority || 0) ? 1 : -1
    );
};

interface CustomerIncidentsMapProps {
  filters?: Array<PaginatedQueryFilterType<PartnerIncidentType>>;
}

export function CustomerIncidentsMap({
  filters = [],
}: CustomerIncidentsMapProps) {
  const { classes } = useStyles();
  const incidents = useIncidents(filters, '/ui/partner/monitoring/incidents');
  const navigate = useNavigate();

  const points = childrenToPoints(incidents?.data || [], mapper(navigate));

  const locationByPoints = {
    lng: points[0]?.geometry.coordinates[0] || DEFAULT_MAP_CENTER.lng,
    lat: points[0]?.geometry.coordinates[1] || DEFAULT_MAP_CENTER.lat,
  };

  const baseLocation = size(points) > 0 ? locationByPoints : DEFAULT_MAP_CENTER;

  return (
    <Box className={classes.mapContainer}>
      <LoadingOverlay visible={!incidents.isFetched || incidents.isLoading} />

      {incidents.isFetched ? (
        <Map
          points={points as Array<GeoJSON.Feature<Point>>}
          base={baseLocation}
          emptyStateText="No incidents to display"
        />
      ) : null}
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  mapContainer: {
    width: '100%',
    height: '100%',
    borderRadius: theme.radius.sm,
  },
}));
