import {
  Image,
  LoadingOverlay,
  Stack,
  Tabs,
  TabsProps,
  Text,
  useMantineTheme,
} from '@mantine/core';
import React from 'react';

import { usePurchasedProduct } from '@portals/api/partners';
import { DetailsPanel } from '@portals/core';
import {
  DetailsList,
  DetailsListProps,
  PostPurchaseParamsDetailsList,
  ProductCategoryBadge,
} from '@portals/framework';
import { ReactComponent as Gallery } from '@portals/icons/bold/gallery.svg';
import { prettyTime } from '@portals/utils';

interface PurchasedProductDetailsPanelProps {
  purchasedProductId: string;
  onClose: () => void;
}

export function PurchasedProductDetailsPanel({
  purchasedProductId,
  onClose,
}: PurchasedProductDetailsPanelProps) {
  const theme = useMantineTheme();
  const purchasedProduct = usePurchasedProduct(purchasedProductId);

  const infoDetailsListItems: DetailsListProps['items'] = [
    {
      label: 'Category',
      value: purchasedProduct.data?.product?.category ? (
        <ProductCategoryBadge
          category={purchasedProduct.data?.product?.category}
        />
      ) : (
        '--'
      ),
    },
    {
      label: 'Ordered By',
      value: purchasedProduct.data?.buyer?.name,
    },
    {
      label: 'Created',
      value: prettyTime(purchasedProduct.data?.created_at),
    },
  ];

  if (purchasedProduct?.data?.sn) {
    infoDetailsListItems.push({
      label: 'Serial Number',
      value: purchasedProduct?.data?.sn,
    });
  }

  return (
    <DetailsPanel
      enableScrollUiChanges={
        !purchasedProduct.data?.post_purchase_parameters_required
      }
    >
      <LoadingOverlay visible={purchasedProduct.isLoading} />

      {purchasedProduct.isFetched ? (
        <>
          <DetailsPanel.Header onClose={onClose} spacing={0}>
            <Stack align="center">
              <Image
                width={100}
                height={100}
                radius="md"
                withPlaceholder
                src={purchasedProduct.data?.product?.image_url}
                placeholder={
                  <Gallery
                    width="50%"
                    height="50%"
                    color={theme.colors.blue_gray[2]}
                  />
                }
              />

              <DetailsPanel.Title>
                {purchasedProduct.data?.product?.name}
              </DetailsPanel.Title>
            </Stack>
          </DetailsPanel.Header>

          {purchasedProduct.data?.post_purchase_parameters_required ? (
            <Tabs
              keepMounted={false}
              styles={tabsStyles}
              defaultValue="overview"
            >
              <Tabs.List grow>
                <Tabs.Tab value="overview">Overview</Tabs.Tab>
                <Tabs.Tab value="moreDetails">More details</Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel value="overview">
                <DetailsPanel.Body>
                  <DetailsList
                    title="Information"
                    items={infoDetailsListItems}
                  />
                </DetailsPanel.Body>
              </Tabs.Panel>

              <Tabs.Panel value="moreDetails">
                <DetailsPanel.Body>
                  {purchasedProduct.data?.post_purchase_parameters ? (
                    <PostPurchaseParamsDetailsList
                      pppFields={
                        purchasedProduct.data?.post_purchase_parameters
                      }
                    />
                  ) : (
                    <Text>
                      This product requires additional purchase information from
                      the buyer
                    </Text>
                  )}
                </DetailsPanel.Body>
              </Tabs.Panel>
            </Tabs>
          ) : (
            <DetailsPanel.Body>
              <DetailsList title="Information" items={infoDetailsListItems} />
            </DetailsPanel.Body>
          )}
        </>
      ) : null}
    </DetailsPanel>
  );
}

const tabsStyles: TabsProps['styles'] = () => ({
  root: {
    height: '100%',
  },
  tabsList: {
    marginInline: 32,
  },
  panel: {
    height: '100%',
  },
});
