import {
  Box,
  Button,
  CloseButton,
  createStyles,
  Group,
  Stack,
  Text,
  UnstyledButton,
} from '@mantine/core';
import { isNumber } from 'lodash/fp';
import React from 'react';
import { TooltipProps, TooltipRenderProps } from 'react-joyride';

type StepTooltipWithoutTitleProps = {
  nextButtonText: string;
  onSkip: () => void;
} & Partial<TooltipProps> &
  Partial<TooltipRenderProps>;

export function StepTooltipWithoutTitle({
  continuous,
  index,
  step,
  backProps,
  primaryProps,
  skipProps,
  size,
  nextButtonText,
  onSkip,
}: StepTooltipWithoutTitleProps) {
  const { classes } = useStyles();

  const showNextButton =
    continuous && isNumber(index) && isNumber(size) && index < size;

  return (
    <Stack className={classes.container} spacing={0}>
      <Group position="right" px="xl" py="md">
        <CloseButton
          onClick={(props) => {
            skipProps?.onClick(props);
            onSkip();
          }}
          variant="transparent"
        />
      </Group>

      <Box color="gray.2">{step?.content}</Box>

      <Group position="apart" p="xl">
        <Text color="gray.3" size="xs">
          {index + 1} of {size}
        </Text>

        <Group spacing="xl">
          {index > 0 && (
            <UnstyledButton
              onClick={(props) => {
                backProps?.onClick(props);
              }}
            >
              <Text color="gray.3" size="xs">
                Back
              </Text>
            </UnstyledButton>
          )}
          {showNextButton && (
            <Button
              data-testid="next-video-button"
              onClick={(props) => {
                primaryProps?.onClick(props);
              }}
            >
              {index + 1 === size ? 'Close' : nextButtonText}
            </Button>
          )}
        </Group>
      </Group>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    backgroundColor: theme.black,
    color: theme.white,
    borderRadius: theme.radius.lg,
    maxWidth: 550,
  },
}));
