import React from 'react';

import {
  ImageSelectorField,
  ImageSelectorFieldProps,
} from '@portals/autoformik';

import { useDesignField, ValuePath } from './hooks';

interface DesignImageInputProps {
  field: ImageSelectorFieldProps['field'];
  valuePath: ValuePath;
  isRemovable?: boolean;
  disabled?: boolean;
}

export function DesignImageInput({
  field,
  valuePath,
  isRemovable,
  disabled = false,
}: DesignImageInputProps) {
  const { value, onChange } = useDesignField(valuePath);

  return (
    <ImageSelectorField
      disabled={disabled}
      isRemovable={isRemovable}
      horizontal={false}
      setFieldValue={(_, value: string) => onChange(value)}
      field={field}
      value={value}
    />
  );
}
