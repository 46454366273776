import {
  Anchor,
  Group,
  Paper,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import React from 'react';

import { useRequestPlanUpgrade } from '@portals/api/partners';
import { ReactComponent as ArrowRight } from '@portals/icons/linear/arrow-right-1.svg';

import { usePricingPlan } from '../../../hooks/partner-config';
import { getPricingPlanIcon } from '../../../utils/pricing-plan.utils';

export function YourPlan() {
  const theme = useMantineTheme();

  const requestPlanUpgrade = useRequestPlanUpgrade();
  const pricingPlan = usePricingPlan();

  const PricingPlanIcon = getPricingPlanIcon(pricingPlan?.icon_name);

  return (
    <Paper withBorder p="xl" radius="md">
      <Stack spacing="xl">
        <Text size="md" color="gray.8">
          Your Plan
        </Text>

        <Group position="apart" align="center" h={48}>
          <Group align="center">
            {PricingPlanIcon ? (
              <PricingPlanIcon
                width={36}
                height={36}
                color={theme.colors.deep_purple_accent[4]}
              />
            ) : null}

            <Text color="blue_gray.8">{pricingPlan?.name}</Text>
          </Group>

          {pricingPlan?.is_top_tier ? null : (
            <Anchor
              size="sm"
              onClick={() =>
                requestPlanUpgrade.mutate(
                  'Partners > Settings > Plan and Billing > Your Plan > Upgrade'
                )
              }
            >
              <Group spacing="xs" align="center">
                Upgrade
                <ArrowRight height={15} width={15} />
              </Group>
            </Anchor>
          )}
        </Group>
      </Stack>
    </Paper>
  );
}
