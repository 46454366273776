import { StoreListing } from '@portals/api/partners';

export function canPublishStoreListing(storeListing: StoreListing) {
  return storeListing.editable && storeListing.status !== 'published';
}

export function canArchiveStoreListing(storeListing: StoreListing) {
  return storeListing.status !== 'archived';
}

export function canDeleteStoreListing(storeListing: StoreListing) {
  return storeListing.editable;
}
