import {
  Box,
  CloseButton,
  createStyles,
  Modal,
  ModalProps,
} from '@mantine/core';
import { useFocusTrap } from '@mantine/hooks';
import React, { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useIsModalOpen } from '@portals/redux';

import { useRouteModalConfig } from './route-modals.hooks';
import { getBackgroundPath, RouteOverlaysProvider } from '../route-overlays';

interface RouteModalProps extends Partial<ModalProps> {
  modalId: string;
}

export function RouteModal({
  children,
  modalId,
  ...modalProps
}: RouteModalProps) {
  const { classes } = useModalStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const defaultBackgroundPath = useRouteModalConfig(modalId).backgroundPath;

  const isModalOpen = useIsModalOpen();
  const focusTrapRef = useFocusTrap(!isModalOpen);

  const onClose = useCallback(() => {
    const backgroundPath = getBackgroundPath(location, defaultBackgroundPath);

    navigate(backgroundPath);
  }, [defaultBackgroundPath, location, navigate]);

  return (
    <RouteOverlaysProvider onClose={onClose}>
      <Modal
        opened
        onClose={onClose}
        closeOnClickOutside={false}
        size="100%"
        classNames={{
          inner: classes.inner,
          content: classes.content,
          header: classes.header,
          body: classes.body,
        }}
        radius="lg"
        {...modalProps}
      >
        <CloseButton
          size="md"
          onClick={onClose}
          className={classes.closeButton}
          data-testid="modal-close-button"
        />

        <Box ref={focusTrapRef} sx={{ width: '100%', height: '100%' }}>
          {children}
        </Box>
      </Modal>
    </RouteOverlaysProvider>
  );
}

const useModalStyles = createStyles((theme) => ({
  root: {
    [theme.fn.smallerThan('md')]: {
      borderRadius: 0,
    },
  },
  inner: {
    padding: theme.spacing.xl,

    [theme.fn.smallerThan('md')]: {
      padding: 0,
    },
  },
  content: {
    height: '100%',
    maxHeight: '100%',
  },
  header: {
    display: 'none',
  },
  body: {
    padding: 0,
    height: '100%',
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing.xs,
    right: theme.spacing.xs,
    zIndex: 1,
  },
}));
