import { useMutation, useQueryClient } from '@tanstack/react-query';
import { map } from 'lodash/fp';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';

import {
  DEVICE_MODELS_API_URL,
  deviceModelsQueryKeys,
  getDeviceModelApiUrl,
} from './device-models.constants';
import { DeviceModelType } from './device-models.types';
import { useApiQuery } from '../../hooks';
import { ServerError } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils/common';
import { devicesQueryKeys } from '../devices';
import { productsQueryKeys } from '../products';

export const useDeviceModels = () =>
  useApiQuery<DeviceModelType[]>(
    DEVICE_MODELS_API_URL,
    deviceModelsQueryKeys.base,
    {
      staleTime: 0,
      keepPreviousData: true,
    }
  );

export const useDeviceModel = (modelId: string = '') => {
  return useApiQuery<DeviceModelType>(
    getDeviceModelApiUrl(modelId),
    deviceModelsQueryKeys.detail(modelId),
    {
      staleTime: 0,
      enabled: !!modelId,
      keepPreviousData: true,
    }
  );
};

export interface UseCreateDeviceModelParams extends DeviceModelType {
  name: string;
  create_reboot_command: boolean;
  create_firmware_upgrade_command: boolean;
  create_dump_command: boolean;
}

export function useCreateDeviceModel() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: DEVICE_MODELS_API_URL,
    method: 'POST',
  });

  return useMutation<
    DeviceModelType,
    ServerError,
    Partial<UseCreateDeviceModelParams>
  >({
    mutationFn: (deviceModel) =>
      fetchApiRequest(url, {
        ...options,
        body: JSON.stringify(deviceModel),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(deviceModelsQueryKeys.base);
      queryClient.invalidateQueries(productsQueryKeys.all);
      queryClient.invalidateQueries(devicesQueryKeys.list);

      dispatch(toastrSuccess('Model created successfully'));
    },
    onError: ({ error }) => {
      dispatch(toastrError('Failed to create new model', error));
    },
    meta: {
      mutationName: 'useCreateDeviceModel',
      baseUrl: DEVICE_MODELS_API_URL,
      method: 'POST',
    },
  });
}

export function useEditDeviceModel() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: DEVICE_MODELS_API_URL,
    method: 'PUT',
  });

  return useMutation<
    DeviceModelType,
    ServerError,
    Partial<UseCreateDeviceModelParams> & { id: string }
  >({
    mutationFn: async (deviceModel) =>
      fetchApiRequest(`${url}/${deviceModel.id}`, {
        ...options,
        body: JSON.stringify(deviceModel),
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries(deviceModelsQueryKeys.base);
      await queryClient.invalidateQueries(productsQueryKeys.all);
      await queryClient.invalidateQueries(devicesQueryKeys.list);
      dispatch(toastrSuccess('Model successfully updated'));
    },
    onError: ({ error }: any) => {
      dispatch(toastrError('Failed to update model', error));
    },
    meta: {
      mutationName: 'useEditDeviceModel',
      baseUrl: `${DEVICE_MODELS_API_URL}/:id`,
      method: 'PUT',
    },
  });
}

export function useBulkEditDeviceModels() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { url, options } = useRequestOptions({
    url: DEVICE_MODELS_API_URL,
    method: 'PUT',
  });

  return useMutation({
    mutationFn: async (
      deviceModels: Array<Partial<UseCreateDeviceModelParams>>
    ): Promise<Array<DeviceModelType>> => {
      const promises = map((deviceModel) => {
        return fetchApiRequest(`${url}/${deviceModel.id}`, {
          ...options,
          body: JSON.stringify(deviceModel),
        });
      }, deviceModels);

      return await Promise.all(promises);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(deviceModelsQueryKeys.base);
      await queryClient.invalidateQueries(productsQueryKeys.all);
      await queryClient.invalidateQueries(devicesQueryKeys.list);

      dispatch(toastrSuccess('Edit models successful'));
    },
    onError: ({ error }: any) => {
      dispatch(toastrError('Edit models error', error));
    },
    meta: {
      mutationName: 'useBulkEditDeviceModels',
      baseUrl: `${DEVICE_MODELS_API_URL}/:id`,
      method: 'PUT',
    },
  });
}

export function useDeleteDeviceModel() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { url, options } = useRequestOptions({
    url: DEVICE_MODELS_API_URL,
    method: 'DELETE',
  });

  return useMutation<DeviceModelType, ServerError, string>({
    mutationFn: async (deviceModelId) =>
      fetchApiRequest(`${url}/${deviceModelId}`, options),
    onSuccess: async () => {
      await queryClient.invalidateQueries(deviceModelsQueryKeys.base);

      dispatch(toastrSuccess('Model successfully removed'));
    },
    onError: ({ error }: any) => {
      dispatch(toastrError('Failed to remove model', error));
    },
    meta: {
      mutationName: 'useDeleteDeviceModel',
      baseUrl: `${DEVICE_MODELS_API_URL}/:id`,
      method: 'DELETE',
    },
  });
}

export function useDuplicateDeviceModel() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: DEVICE_MODELS_API_URL,
    method: 'POST',
  });

  return useMutation<DeviceModelType, ServerError, string>({
    mutationFn: (deviceModelId) =>
      fetchApiRequest(`${url}/${deviceModelId}/duplicate`, options),
    onSuccess: () => {
      queryClient.invalidateQueries(deviceModelsQueryKeys.base);
      queryClient.invalidateQueries(productsQueryKeys.all);
      queryClient.invalidateQueries(devicesQueryKeys.list);

      dispatch(toastrSuccess('Model copied successfully'));
    },
    onError: ({ error }) => {
      dispatch(toastrError('Failed to copy new model', error));
    },
    meta: {
      mutationName: 'useDuplicateDeviceModel',
      baseUrl: `${DEVICE_MODELS_API_URL}/:id/duplicate`,
      method: 'POST',
    },
  });
}

export function useResetModelTroubleshootingSteps() {
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: DEVICE_MODELS_API_URL,
    method: 'POST',
  });

  return useMutation<DeviceModelType, ServerError, string>({
    mutationFn: async (deviceModelId) =>
      fetchApiRequest(`${url}/${deviceModelId}/reset_troubleshooting_steps`, {
        ...options,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries(deviceModelsQueryKeys.base);
      toastrSuccess('Model troubleshooting steps reset successfully');
    },
    onError: ({ error }: any) => {
      toastrError('Failed to reset model troubleshooting steps', error);
    },
    meta: {
      mutationName: 'useEditDeviceModel',
      baseUrl: `${DEVICE_MODELS_API_URL}/:id`,
      method: 'PUT',
    },
  });
}
