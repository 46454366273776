import { Badge } from '@mantine/core';
import React from 'react';

import {
  ORDERS_API_URL,
  OrderSummaryType,
  useOrders,
} from '@portals/api/partners';
import {
  OrderItemsPreviewTooltip,
  OrderItemsPreviewTooltipProps,
} from '@portals/framework';
import {
  PartnerOrderPaymentStatusBadge,
  RouteModalLink,
} from '@portals/framework/route-modals';
import { AvatarCell, DateCell, PaginatedTable } from '@portals/table';
import { TableColumn, TableFilterTypeEnum } from '@portals/types';
import {
  formatCurrency,
  generateExportFilename,
  getOrderPaymentMethodLabel,
} from '@portals/utils';

const TABLE_COLUMNS: Array<TableColumn<OrderSummaryType>> = [
  {
    dataField: 'short_id',
    text: 'ID',
    sort: true,
    filter: {
      type: TableFilterTypeEnum.Text,
    },
    minWidth: 100,
    isSticky: true,
    formatter: (_, { id, short_id }) => (
      <RouteModalLink modalId="order" pathParams={[id]}>
        {short_id}
      </RouteModalLink>
    ),
  },
  {
    dataField: 'organization_display_name',
    text: 'Customer',
    sort: true,
    filter: {
      type: TableFilterTypeEnum.Text,
    },
    formatter: (_, { organization }) => (
      <AvatarCell
        src={organization.logo_url}
        label={organization.name}
        radius="md"
      />
    ),
  },
  {
    dataField: 'items',
    text: 'Items',
    minWidth: 100,
    formatter: (_, { items, items_count }) => {
      if (!items_count) return null;

      const itemsList: OrderItemsPreviewTooltipProps['items'] = items.map(
        (orderItem) => ({
          name: orderItem.name,
          quantity: orderItem.quantity,
          imageUrl: orderItem.image_url,
        })
      );

      return (
        <OrderItemsPreviewTooltip items={itemsList}>
          <Badge fw={400}>
            {items_count === 1 ? '1 item' : `${items_count} items`}
          </Badge>
        </OrderItemsPreviewTooltip>
      );
    },
  },
  {
    dataField: 'created_at',
    text: 'Date Placed',
    sort: true,
    filter: {
      type: TableFilterTypeEnum.Date,
    },
    minWidth: 250,
    maxWidth: 250,
    formatter: (cell, { created_at }) => (
      <DateCell date={created_at} withTimeAgo={false} />
    ),
  },
  {
    dataField: 'amount_in_scu',
    text: 'Charged Amount',
    minWidth: 250,
    maxWidth: 250,
    sort: true,
    formatter: (_, { amount_in_scu, tax_in_scu, currency }) =>
      `${formatCurrency(amount_in_scu, currency)}${
        tax_in_scu !== 0
          ? ` + Tax: ${formatCurrency(tax_in_scu, currency)}`
          : ''
      }`,
  },
  {
    dataField: 'payment_method',
    text: 'Payment Method',
    filter: {
      type: TableFilterTypeEnum.Select,
      options: {
        credit_card: 'Credit Card',
        purchase_order: 'Purchase Order',
        lab: 'Lab',
      },
    },
    minWidth: 200,
    maxWidth: 200,
    formatter: (_, { payment_method }) =>
      getOrderPaymentMethodLabel(payment_method),
  },
  {
    dataField: 'payment_status',
    text: 'Payment Status',
    sort: false,
    minWidth: 200,
    maxWidth: 200,
    filter: {
      type: TableFilterTypeEnum.Select,
      options: {
        awaiting_po_upload: 'Requires PO',
        awaiting_po_approval: 'Pending seller approval',
        pending: 'Payment in process',
        paid: 'Paid',
        failed: 'Payment failed',
        expired: 'Expired',
        archived: 'Archived',
        refunded: 'Payment refunded',
      },
    },
    formatter: (_, order) => <PartnerOrderPaymentStatusBadge order={order} />,
  },
];

interface OrdersTableProps {
  paymentStatus: OrderSummaryType['payment_status'] | undefined;
}

export function OrdersTable({ paymentStatus }: OrdersTableProps) {
  return (
    <PaginatedTable<OrderSummaryType>
      keyField="id"
      dataHook={useOrders}
      dataHookUrl={getDataHookUrl(paymentStatus)}
      columns={TABLE_COLUMNS}
      noDataIndication={{ title: 'No orders' }}
      defaultSortBy={[{ id: 'created_at', desc: true }]}
      name="partners.orders"
      exportParams={{
        isEnabled: true,
        remoteUrl: `${ORDERS_API_URL}.csv`,
        fileName: generateExportFilename('orders'),
      }}
    />
  );
}

function getDataHookUrl(paymentStatus: OrdersTableProps['paymentStatus']) {
  switch (paymentStatus) {
    case 'pending':
      return ORDERS_API_URL + '?q[payment_status_eq]=pending';

    case 'paid':
      return ORDERS_API_URL + '?q[payment_status_eq]=paid';

    case 'archived':
      return ORDERS_API_URL + '?q[payment_status_eq]=archived';

    case 'expired':
      return ORDERS_API_URL + '?q[payment_status_eq]=expired';

    case 'failed':
      return ORDERS_API_URL + '?q[payment_status_eq]=failed';

    case 'refunded':
      return ORDERS_API_URL + '?q[payment_status_eq]=refunded';

    default:
      return ORDERS_API_URL;
  }
}
