import {
  Box,
  createEmotionCache,
  createStyles,
  MantineProvider,
} from '@mantine/core';
import React, { useEffect } from 'react';

import { FeatureNotificationEnum, useCurrentUser } from '@portals/api/ui';
import { Modals } from '@portals/framework';
import { FeaturesNotificationsProps } from '@portals/framework/modals';
import { useOpenModal } from '@portals/redux';

import { HubsHeader } from './hubs-header/HubsHeader';
import { HubsProvider } from './HubsContext';
import { HubsMainContent } from './HubsMainContent';
import { DashboardRoutesProvider } from '../routes/dashboard/DashboardRoutesProvider';
import { QuickNavigationProvider } from '../routes/dashboard/QuickNavigationProvider';

const emotionCache = createEmotionCache({
  key: 'mantine',
  prefix: false,
  stylisPlugins: [],
});

export function HubsLayout() {
  const { classes, cx, theme } = useStyles();

  const openModal = useOpenModal();
  const currentUser = useCurrentUser();

  useEffect(
    function showNewPartnerHubsAnnouncement() {
      if (
        currentUser.data?.feature_notifications?.find(
          (feature) => feature === FeatureNotificationEnum.NewPartnerHubs
        )
      ) {
        openModal<FeaturesNotificationsProps['data']>('FeaturesNotifications', {
          featureNotification: FeatureNotificationEnum.NewPartnerHubs,
        });
      }
    },
    [currentUser.data?.feature_notifications, openModal]
  );

  return (
    <MantineProvider
      inherit
      emotionCache={emotionCache}
      theme={{
        globalStyles: () => ({
          body: {
            fontFamily: '"Inter", sans-serif',
            lineHeight: 1.35,
            color: theme.colors.gray[9],
          },
        }),
        primaryShade: 9,
        primaryColor: 'gray',
        fontFamily: '"Inter", sans-serif',
        lineHeight: 1.35,
        headings: {
          ...theme.headings,
          fontFamily: '"Inter", sans-serif',
        },

        colors: { ...theme.colors, primary: theme.colors.gray },
      }}
    >
      <DashboardRoutesProvider>
        <QuickNavigationProvider>
          <HubsProvider>
            <Box className={cx(classes.wrapper, 'page-wrapper')}>
              <HubsHeader />

              <HubsMainContent />
            </Box>

            <Modals />
          </HubsProvider>
        </QuickNavigationProvider>
      </DashboardRoutesProvider>
    </MantineProvider>
  );
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    height: '100dvh',
    overflow: 'hidden',
    display: 'grid',
    gridTemplateRows: `${theme.other.navbarHeight}px 1fr`,
  },
}));
