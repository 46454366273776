import { Button, Stack, Text } from '@mantine/core';
import React from 'react';

import { UsersEmptyState } from '../../../assets';

interface GroupMembersListEmptyStateProps {
  onAddUsersClick: () => void;
}

export function GroupMembersListEmptyState({
  onAddUsersClick,
}: GroupMembersListEmptyStateProps) {
  return (
    <Stack spacing={0} h="100%" ta="center" justify="center" align="center">
      <UsersEmptyState />

      <Text size="md" color="gray.8" mb="xs" weight={600}>
        No members yet
      </Text>

      <Text size="sm" color="gray.5" mb="md">
        Looks like this user group doesn't have any members yet.
        <br /> Click the 'Add' button to start adding members
      </Text>

      <Button
        variant="light"
        data-testid="groups-and-members-add-members-button"
        onClick={onAddUsersClick}
      >
        Add Members
      </Button>
    </Stack>
  );
}
