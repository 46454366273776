import React, { createContext, ReactNode, useContext } from 'react';
import { useLocalStorage } from 'react-use';

import { useIsChannelPartner } from '@portals/api/partners';

interface SupportStateContextType {
  isLabIncluded: boolean;
  setIsLabIncluded: (isLabIncluded: boolean) => void;
}

const SupportStateContext = createContext<SupportStateContextType>(null);

export function SupportStateProvider({ children }: { children: ReactNode }) {
  const isChannelPartner = useIsChannelPartner();

  const [isLabIncluded, setIsLabIncluded] = useLocalStorage(
    'supportOverview.isLabIncluded',
    !isChannelPartner
  );

  return (
    <SupportStateContext.Provider value={{ isLabIncluded, setIsLabIncluded }}>
      {children}
    </SupportStateContext.Provider>
  );
}

export function useSupportState() {
  const context = useContext(SupportStateContext);

  if (context === null) {
    throw new Error('useSupportState must be used within SupportStateProvider');
  }

  return context;
}
