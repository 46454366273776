import { Menu, MenuItemProps } from '@mantine/core';
import { noop } from 'lodash/fp';
import React from 'react';
import { Row } from 'react-table';

import { ConnectedPartner } from '@portals/api/partners';
import { ReactComponent as Plus } from '@portals/icons/linear/add-square.svg';
import { ReactComponent as Briefcase } from '@portals/icons/linear/briefcase.svg';
import { ReactComponent as CloseCircle } from '@portals/icons/linear/close-circle.svg';
import { ReactComponent as Flash } from '@portals/icons/linear/flash.svg';
import { ReactComponent as TickCircle } from '@portals/icons/linear/tick-circle.svg';
import { useOpenModal } from '@portals/redux';
import { RowMenuItem, RowMenuItems, useTableInstance } from '@portals/table';
import { RowMenuWrapperType } from '@portals/types';

import { CreateNewPartnerTypeModalProps } from '../../modals';

interface RowMenuProps {
  rowIndex: number;
  connectedPartner: ConnectedPartner;
  wrapperProps: RowMenuWrapperType;
  onUpdateType: (
    id: string[],
    relationship_type: string,
    certified: boolean
  ) => void;
  onDisconnectPartners: (
    rowsById: Record<string, Row<ConnectedPartner>>,
    selectedRowIds: Record<string, any> | undefined
  ) => Promise<void>;
  relationshipTypes: string[] | undefined;
  certified: boolean;
}

export function PartnerRowMenu({
  connectedPartner,
  wrapperProps,
  onUpdateType,
  onDisconnectPartners,
  relationshipTypes,
  certified,
  rowIndex,
}: RowMenuProps) {
  const tableInstance = useTableInstance<ConnectedPartner>();

  const rowsById = tableInstance?.rowsById;
  const openModal = useOpenModal();

  const items: RowMenuItem[] = [
    {
      id: 'change_type',
      label: 'Change Type',
      Icon: Briefcase,
      onClick: noop,
      Wrapper: ({ children }) => (
        <Menu trigger="hover" position="left" styles={itemMenuStyles}>
          <Menu.Target>{children}</Menu.Target>

          <Menu.Dropdown p="sm">
            {relationshipTypes
              ? relationshipTypes.map((relationshipType) => (
                  <Menu.Item
                    p="xs"
                    onClick={() => {
                      wrapperProps?.menuRef?.onClose();
                      onUpdateType(
                        [connectedPartner.id],
                        relationshipType,
                        certified
                      );
                    }}
                    key={relationshipType}
                    w="100%"
                    color={
                      connectedPartner.relationship_type === relationshipType
                        ? 'primary.4'
                        : 'gray.9'
                    }
                    component="div"
                  >
                    {relationshipType}
                  </Menu.Item>
                ))
              : null}
            <Menu.Divider />
            <Menu.Item
              p="xs"
              color="gray.9"
              onClick={() => {
                wrapperProps?.menuRef?.onClose();
                openModal<CreateNewPartnerTypeModalProps['data']>(
                  'CreateNewPartnerTypeModal',
                  {
                    onChange: (relationshipType: string) =>
                      onUpdateType(
                        [connectedPartner.id],
                        relationshipType,
                        certified
                      ),
                  }
                );
              }}
              icon={<Plus />}
              component="div"
            >
              Create New
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      ),
    },
    {
      onClick: () => {
        wrapperProps?.menuRef?.onClose();
        onUpdateType(
          [connectedPartner.id],
          connectedPartner.relationship_type,
          !certified
        );
      },
      id: 'certified',
      label: certified ? 'Remove Certification' : 'Add Certification',
      Icon: certified ? CloseCircle : TickCircle,
    },
    {
      onClick: () => {
        wrapperProps?.menuRef?.onClose();

        onDisconnectPartners(rowsById, { [rowIndex]: true });
      },
      id: 'disconnect',
      label: 'Disconnect Partner',
      Icon: Flash,
      withTopDivider: true,
    },
  ];

  return <RowMenuItems wrapperProps={wrapperProps} items={items} />;
}

const itemMenuStyles: MenuItemProps['styles'] = (theme) => ({
  item: {
    fontSize: theme.fontSizes.xs,
  },
});
