import { Box, createStyles } from '@mantine/core';
import { GeoJSON, Point } from 'geojson';
import { capitalize, size } from 'lodash/fp';
import React, { FC, useMemo } from 'react';

import { CustomerWithControlledType } from '@portals/api/partners';
import {
  CustomerMarker,
  Map,
  MapListItem,
  PARTNERS_PRIORITY_COLORS,
} from '@portals/framework';
import {
  RouteModalParams,
  useOpenRouteModal,
} from '@portals/framework/route-modals';
import { DEFAULT_MAP_CENTER } from '@portals/utils';

const SEVERITY_MAPPING = {
  high: 1,
  medium: 2,
  low: 3,
} as const;

const mapper =
  (
    openRouteModal: ({
      modalId,
      pathParams,
      backgroundPath,
    }: RouteModalParams) => void
  ) =>
  (customer: CustomerWithControlledType) => {
    const priority = SEVERITY_MAPPING[customer.severity_status];
    const color = PARTNERS_PRIORITY_COLORS[priority - 1];
    const description =
      customer.incidents_count > 0
        ? `${customer.incidents_count} incidents`
        : 'No incidents';

    return {
      id: customer.id,
      priority,
      severityCategory: capitalize(customer.severity_status),
      renderSinglePoint: () => (
        <CustomerMarker customer={customer} color={color} />
      ),
      renderClusterItem: () => (
        <MapListItem
          key={customer.id}
          title={customer.name}
          description={description}
          color={color}
          onClick={() =>
            openRouteModal({ modalId: 'customer', pathParams: [customer.id] })
          }
        />
      ),
    };
  };

function childrenToPoints(
  customers: Array<CustomerWithControlledType>,
  mapper: (customer: CustomerWithControlledType) => Record<string, any>
) {
  return customers
    .map((customer): GeoJSON.Feature<Point> | null => {
      if (!customer.map_coordinates) {
        return null;
      }

      return {
        type: 'Feature',
        properties: { cluster: false, customer, ...mapper(customer) },
        geometry: {
          type: 'Point',
          coordinates: [
            customer.map_coordinates.lng,
            customer.map_coordinates.lat,
          ],
        },
      };
    })
    .filter((item) => item !== null)
    .sort((a, b) =>
      (a?.properties?.priority || 0) > (b?.properties?.priority || 0) ? 1 : -1
    );
}

interface CustomersIncidentsMapProps {
  customers: Array<CustomerWithControlledType>;
}

export const CustomersIncidentsMap: FC<CustomersIncidentsMapProps> = ({
  customers,
}) => {
  const { classes } = useStyles();
  const openRouteModal = useOpenRouteModal();

  const points = useMemo(
    () => childrenToPoints(customers, mapper(openRouteModal)),
    [customers, openRouteModal]
  );

  const locationByPoints = {
    lng: points[0]?.geometry.coordinates[0] || DEFAULT_MAP_CENTER.lng,
    lat: points[0]?.geometry.coordinates[1] || DEFAULT_MAP_CENTER.lat,
  };

  const baseLocation = size(points) > 0 ? locationByPoints : DEFAULT_MAP_CENTER;

  return (
    <Box className={classes.mapContainer}>
      <Map
        points={points as Array<GeoJSON.Feature<Point>>}
        base={baseLocation}
        emptyStateText="No customers to display"
      />
    </Box>
  );
};

const useStyles = createStyles((theme) => ({
  mapContainer: {
    width: '100%',
    height: '100%',
    borderRadius: theme.radius.sm,
  },
}));
