import {
  Group,
  LoadingOverlay,
  Paper,
  Stack,
  Switch,
  Text,
} from '@mantine/core';
import React from 'react';

import { useConfig, useUpdateConfig } from '@portals/api/partners';

export function SupportCenter() {
  const config = useConfig();
  const updateConfig = useUpdateConfig();

  const onChange = async (isEnabled: boolean) => {
    try {
      await updateConfig.mutateAsync({
        updatedConfig: { support_center_enabled: isEnabled },
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Paper withBorder p="xl" radius="md" pos="relative">
      <LoadingOverlay visible={updateConfig.isLoading} />

      <Stack spacing="xl">
        <Text size="md" color="gray.8">
          Support Center
        </Text>

        <Group position="apart" align="center" h={48}>
          <Text color="blue_gray.8">Support center enabled</Text>

          <Switch
            checked={config.data?.portal_capabilities.support_center}
            onChange={(event) => onChange(event.target.checked)}
          />
        </Group>
      </Stack>
    </Paper>
  );
}
