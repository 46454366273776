import { createStyles, Group, Text } from '@mantine/core';
import React from 'react';
import ReactMarkdown from 'react-markdown';

import { usePartnerConfig } from '@portals/api/partners';
import { ReactComponent as FacebookIcon } from '@portals/icons/social/facebook.svg';
import { ReactComponent as InstagramIcon } from '@portals/icons/social/instagram.svg';
import { ReactComponent as LinkedinIcon } from '@portals/icons/social/linkedin.svg';
import { ReactComponent as TwitterIcon } from '@portals/icons/social/twitter.svg';

import { useBrandingContext } from '../branding.context';

export function EmailTemplate() {
  const { classes } = useStyles();
  const { portalConfig, defaultValues } = useBrandingContext();
  const partnerConfig = usePartnerConfig();

  const senderName = portalConfig.email_settings?.custom_from_email
    ? partnerConfig?.display_name
    : 'Xyte';

  const senderEmailAddress = portalConfig.email_settings?.custom_from_email
    ? `<${partnerConfig?.name}@on-xyte.io>`
    : `<info@xyte.io>`;

  const primaryColor =
    portalConfig.email_settings?.primary_color ||
    defaultValues.email_settings?.primary_color;

  const logoSrc = portalConfig?.email_settings?.logo || 'logo_full_dark.svg';

  const footerMarkdown =
    portalConfig?.email_settings?.markdown ??
    defaultValues.email_settings?.markdown;

  const showFacebookLogo =
    portalConfig.email_settings?.facebook_is_active &&
    portalConfig.email_settings?.facebook_href;

  const showLinkedinLogo =
    portalConfig.email_settings?.linkedin_is_active &&
    portalConfig.email_settings?.linkedin_href;

  const showInstagramLogo =
    portalConfig.email_settings?.instagram_is_active &&
    portalConfig.email_settings?.instagram_href;

  const showTwitterLogo =
    portalConfig.email_settings?.twitter_is_active &&
    portalConfig.email_settings?.twitter_href;

  return (
    <svg width="100%" height="100%" viewBox="0 0 695 718" fill="none">
      <g filter="url(#filter0_f_3172_45691)">
        <rect x="89" y="89" width="517" height="540" fill="#EEEEEE" />
      </g>

      <foreignObject x="93.6689" y="73.8405" width="512" height="49">
        <Group spacing="xs" className={classes.senderDetails}>
          <Text weight={700}>{senderName}</Text>
          <Text weight={500} color="gray.6">
            {senderEmailAddress}
          </Text>
        </Group>
      </foreignObject>

      <g clipPath="url(#clip0_3172_45691)">
        <rect
          x="93.6689"
          y="138.84"
          width="512.663"
          height="472.686"
          rx="17.0888"
          fill="white"
        />

        <foreignObject x="125.669" y="150" width="100" height="100">
          <img
            width="100%"
            height="100%"
            className={classes.logo}
            src={logoSrc}
            alt="logo"
          />
        </foreignObject>

        <rect
          x="125.669"
          y="254.19"
          width="198"
          height="24"
          rx="12"
          fill="#424242"
        />
        <rect
          x="125.669"
          y="320.19"
          width="134"
          height="13"
          rx="6.5"
          fill="#BDBDBD"
        />
        <rect
          x="125.669"
          y="344.19"
          width="441"
          height="13"
          rx="6.5"
          fill="#BDBDBD"
        />
        <rect
          x="125.669"
          y="368.334"
          width="240"
          height="13"
          rx="6.5"
          fill="#BDBDBD"
        />
        <rect x="126" y="393" width="332" height="13" rx="6.5" fill="#BDBDBD" />
        <foreignObject x="301.896" y="448.427" width="112.361" height="35.3609">
          <Group
            bg={primaryColor}
            position="center"
            className={classes.primaryButton}
          >
            Primary button
          </Group>
        </foreignObject>

        <rect
          width="448"
          height="1"
          transform="matrix(1 0 0 -1 126 529.573)"
          fill="#E0E0E0"
        />

        <foreignObject x="125" y="528" width="448" height="83">
          <Group position="apart" noWrap className={classes.markdownWrapper}>
            <ReactMarkdown className={classes.markdown}>
              {footerMarkdown as string}
            </ReactMarkdown>

            <Group
              className={classes.socials}
              spacing="lg"
              position="right"
              noWrap
            >
              {showFacebookLogo && <FacebookIcon />}
              {showLinkedinLogo && <LinkedinIcon />}
              {showInstagramLogo && <InstagramIcon />}
              {showTwitterLogo && <TwitterIcon />}
            </Group>
          </Group>
        </foreignObject>
      </g>
      <defs>
        <filter
          id="filter0_f_3172_45691"
          x="0.699997"
          y="0.699997"
          width="693.6"
          height="716.6"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="44.15"
            result="effect1_foregroundBlur_3172_45691"
          />
        </filter>
        <clipPath id="clip0_3172_45691">
          <rect
            x="93.6689"
            y="138.84"
            width="512.663"
            height="472.686"
            rx="10.2533"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

const useStyles = createStyles((theme) => ({
  senderDetails: {
    width: '100%',
    height: '100%',
    paddingInline: theme.spacing.lg,
    borderRadius: theme.radius.lg,
    fontSize: theme.fontSizes.xs,
    backgroundColor: theme.white,
  },
  logo: {
    objectFit: 'contain',
  },
  primaryButton: {
    width: '100%',
    height: '100%',
    color: theme.white,
    borderRadius: 6,
    fontSize: theme.fontSizes.xs,
  },
  markdownWrapper: {
    width: '100%',
    height: '100%',
    paddingInline: theme.spacing.md,
    color: theme.colors.gray[6],
  },
  markdown: {
    fontSize: theme.fontSizes.xs,
    width: '100%',

    p: {
      margin: 0,
    },
  },
  socials: {
    svg: {
      width: 20,
      height: 20,
    },
  },
}));
