import { createStyles, LoadingOverlay } from '@mantine/core';
import React, { useEffect, useMemo } from 'react';

import { DeviceModelType, useDeviceModel } from '@portals/api/partners';
import {
  RoutedTabs,
  RoutedTabsProps,
  useHasSupportSeat,
  usePermissionAccess,
} from '@portals/framework';
import { NewDeviceWidgetsProps } from '@portals/framework/modals';
import { useOpenModal } from '@portals/redux';

import { ConfigSchema } from './ConfigSchema';
import { ModelCommands } from './model-commands';
import { ModelBaseInfo } from './ModelBaseInfo';
import { ModelFiles } from './ModelFiles';
import { ModelHardwareKeys } from './ModelHardwareKeys';
import { ModelImage } from './ModelImage';
import { ModelIntegrations } from './ModelIntegrations';
import ModelRules from './ModelRules';
import { useIsLegacyWidgets } from './monitoring-dashboard/monitoring-dashboard.hooks';
import { MonitoringDashboardWrapper } from './monitoring-dashboard/MonitoringDashboardWrapper';
import { Troubleshooting } from './troubleshooting/Troubleshooting';
import { useModelMigrationContext } from '../ModelMigrationProvider';

interface ModelDetailsProps {
  modelId: string;
  onDuplicate: (model: DeviceModelType) => void;
  onEdit: (model: DeviceModelType) => void;
  onDelete: (model: DeviceModelType) => void;
  onPublish: (model: DeviceModelType) => void;
}

export function ModelDetails({
  modelId,
  onDuplicate,
  onEdit,
  onDelete,
  onPublish,
}: ModelDetailsProps) {
  const { classes } = useStyles();
  const model = useDeviceModel(modelId);

  const hasSupportSeat = useHasSupportSeat();
  const { canEdit, canView } = usePermissionAccess();
  const openModal = useOpenModal();

  const legacyWidgets = useIsLegacyWidgets(modelId);
  const modelMigrationContext = useModelMigrationContext();

  const routes = useMemo<RoutedTabsProps['routes']>(
    () => [
      {
        path: 'info',
        tabLabel: 'Info',
        element: (
          <ModelBaseInfo
            model={model.data}
            onDuplicate={onDuplicate}
            onEdit={onEdit}
            onDelete={onDelete}
            onPublish={onPublish}
          />
        ),
      },
      {
        path: 'monitoring_dashboard',
        tabLabel: 'Monitoring Dashboard',
        element: <MonitoringDashboardWrapper />,
      },
      {
        path: 'files',
        tabLabel: 'Files',
        element: <ModelFiles model={model.data} />,
      },
      {
        path: 'commands',
        tabLabel: 'Supported Commands',
        element: <ModelCommands model={model.data} />,
      },
      {
        path: 'rules',
        tabLabel: 'Rules',
        element: <ModelRules model={model.data} />,
      },
      {
        path: 'schema',
        tabLabel: 'Config Schema',
        element: <ConfigSchema model={model.data} />,
      },
      {
        path: 'image',
        tabLabel: 'Image',
        element: <ModelImage model={model.data} />,
        visible: canEdit('models'),
      },
      {
        path: 'integrations',
        tabLabel: 'Integrations',
        element: <ModelIntegrations model={model.data} />,
      },
      {
        path: 'hardware_keys',
        tabLabel: 'Hardware Keys',
        element: <ModelHardwareKeys model={model.data} />,
        visible: hasSupportSeat || canView('models'),
      },
      {
        path: 'troubleshooting',
        tabLabel: 'Troubleshooting',
        element: <Troubleshooting model={model.data} />,
        visible: hasSupportSeat || canEdit('models'),
      },
    ],
    [
      canEdit,
      canView,
      hasSupportSeat,
      model.data,
      onDelete,
      onDuplicate,
      onEdit,
      onPublish,
    ]
  );

  useEffect(
    function displayDashboardMigrationNotification() {
      if (!canEdit('models')) return;

      const hasLegacyWidgets =
        !legacyWidgets.isLoading && legacyWidgets.isLegacy;
      const hasDismissedNotification =
        modelMigrationContext.modelsMigrationNotifications.has(modelId);

      if (!hasLegacyWidgets || hasDismissedNotification) {
        return;
      }

      openModal<NewDeviceWidgetsProps['data']>('NewDeviceWidgets', {
        modelId,
        onDismiss: () => modelMigrationContext.onToggleNotification(modelId),
      });
    },
    [canEdit, legacyWidgets, modelId, openModal, modelMigrationContext]
  );

  return (
    <div className={classes.container}>
      <LoadingOverlay visible={!model.isFetched} />

      {!model.isFetched ? null : (
        <RoutedTabs basePath="/models/:id" routes={routes} />
      )}
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    overflow: 'hidden',

    '.tab-wrapper': {
      padding: theme.spacing.md,
      border: `1px solid ${theme.colors.blue_gray[0]}`,
      borderTop: 'none',
    },
  },
}));
