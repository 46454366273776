import { Group, Stack, Text, Title, useMantineTheme } from '@mantine/core';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { useForgotPassword } from '@portals/api/auth';
import { AutoFormik } from '@portals/autoformik';
import { FieldTypeEnum, TenantType } from '@portals/types';
import { FADE_IN_OUT } from '@portals/ui';

import { FormWrapper } from './common';
import { useAppConfig } from '../../context';
import { useCommonPortalConfig } from '../../hooks/portal-config';

const FIELDS = [
  {
    name: 'email',
    title: 'Email address',
    type: FieldTypeEnum.Email,
    required: true,
    placeholder: 'The email you signed up with',
  },
];

interface ResetPasswordProps {
  error?: string | undefined;
  inProgress?: boolean;
}

export function ResetPassword({ error, inProgress }: ResetPasswordProps) {
  const { tenantType } = useAppConfig();
  const theme = useMantineTheme();
  const portalConfig = useCommonPortalConfig();

  const adjustedConfig =
    tenantType === TenantType.Partner ? {} : portalConfig.data;

  const forgotPassword = useForgotPassword();
  const [isEmailSent, setIsEmailSent] = useState(false);

  const onSubmit = async (email: string) => {
    if (!tenantType) {
      return;
    }

    forgotPassword.mutate(
      { email, tenantType },
      { onSuccess: () => setIsEmailSent(true) }
    );
  };

  if (isEmailSent) {
    return (
      <FormWrapper id="reset-password">
        <Stack>
          <Title order={1} mb="lg">
            Reset Password
          </Title>

          <Text color="dimmed" size="sm">
            If the email you provided is associated with an account, you will
            receive a password reset link shortly.
          </Text>
        </Stack>
      </FormWrapper>
    );
  }

  return (
    <FormWrapper id="reset-password">
      <Stack>
        <Title data-testid="reset-password-title" order={1} mb="lg">
          Reset Password
        </Title>

        <AutoFormik
          fields={FIELDS}
          inProgress={inProgress}
          handleSubmit={({ email }) => onSubmit(email)}
          submitTitle="Send reset link"
          buttonsProps={{
            size: 'sm',
            style: {
              width: '100%',
              marginTop: '20px',
            },
            bg:
              adjustedConfig?.design?.sign_in_page?.primary_color ||
              theme.fn.primaryColor(),
            styles: (theme) => ({
              root: {
                color:
                  adjustedConfig?.design?.sign_in_page?.text_color ||
                  theme.white,

                '&:hover': {
                  backgroundColor: theme.fn.darken(
                    adjustedConfig?.design?.sign_in_page?.primary_color ||
                      theme.fn.primaryColor(),
                    0.2
                  ),
                },
              },
            }),
          }}
        />

        <AnimatePresence>
          {error ? <motion.p {...FADE_IN_OUT}>{error}</motion.p> : null}
        </AnimatePresence>

        <Text color="dimmed" size="sm" align="center">
          <Group spacing={6}>
            Return to
            <Link to="/auth/sign-in">Sign-In</Link>
          </Group>
        </Text>
      </Stack>
    </FormWrapper>
  );
}
