import {
  Divider,
  Group,
  LoadingOverlay,
  Paper,
  Stack,
  Switch,
  Text,
} from '@mantine/core';
import React from 'react';

import {
  useStoreSettings,
  useUpdateStoreSettings,
} from '@portals/api/partners';
import { usePermissionAccess } from '@portals/framework';

export const PaymentMethods = () => {
  const { isAdmin } = usePermissionAccess();
  const storeSettings = useStoreSettings();
  const updateStoreSettings = useUpdateStoreSettings();

  const onToggle = (
    field:
      | 'credit_card_payment_method_enabled'
      | 'purchase_order_payment_method_enabled',
    value: boolean
  ) => {
    updateStoreSettings.mutate({
      [field]: value,
    });
  };

  return (
    <Paper p="xl" withBorder>
      <LoadingOverlay
        visible={storeSettings.isLoading || updateStoreSettings.isLoading}
      />

      <Stack spacing="xl">
        <Group position="apart">
          <Text sx={(theme) => ({ color: theme.colors.gray[8] })}>
            Payment Methods
          </Text>
        </Group>

        <Stack>
          <Group position="apart" py="md">
            <Text size="sm" color="blue_gray">
              Credit Card
            </Text>

            <Switch
              disabled
              checked={storeSettings.data?.credit_card_payment_method_enabled}
            />
          </Group>

          <Divider />

          <Group position="apart" py="md">
            <Group spacing="xs">
              <Text size="sm" color="blue_gray">
                Purchase Order
              </Text>
            </Group>

            <Switch
              disabled={!isAdmin}
              onChange={(event) =>
                onToggle(
                  'purchase_order_payment_method_enabled',
                  event.target.checked
                )
              }
              checked={
                storeSettings.data?.purchase_order_payment_method_enabled
              }
            />
          </Group>

          <Divider />

          <Group position="apart" py="md">
            <Group spacing="xs">
              <Text size="sm" color="blue_gray">
                ACH Transfer
              </Text>

              <Text
                size="sm"
                sx={(theme) => ({ color: theme.colors.blue_gray[3] })}
              >
                (Coming soon)
              </Text>
            </Group>

            <Switch disabled checked={false} />
          </Group>
        </Stack>
      </Stack>
    </Paper>
  );
};
