import { LoadingOverlay, Stack } from '@mantine/core';
import React from 'react';

import { useConfig } from '@portals/api/partners';

import { FeaturesPanel } from './FeaturesPanel';

export function CustomerPortalFeatures() {
  const config = useConfig();

  return (
    <>
      <LoadingOverlay visible={config.isInitialLoading} />

      {config.isFetched ? (
        <Stack spacing="xl" maw={760}>
          <FeaturesPanel />
        </Stack>
      ) : null}
    </>
  );
}
