import {
  ActionIcon,
  CloseButton,
  createStyles,
  Group,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import { useTimeout } from '@mantine/hooks';
import React, { useEffect, useRef, useState } from 'react';
import { useKey } from 'react-use';

import { useFeatureFlags } from '@portals/api/partners';
import { isPartnerFeatureOn } from '@portals/framework';
import { ReactComponent as NewIcon } from '@portals/icons/bold/new.svg';
import { useOnClickOutside } from '@portals/utils';

import { ReactComponent as InsightIcon } from './insight-icon.svg';
import { InsightsFooter } from './InsightsFooter';
import { InsightsList } from './InsightsList';
import { InsightsResponse } from './InsightsResponse';

export function InsightsDemoAi() {
  const featureFlags = useFeatureFlags();

  const { classes, theme } = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [userMessage, setUserMessage] = useState('');
  const [showResponse, setShowResponse] = useState(false);

  const timeout = useTimeout(() => {
    setIsLoading(false);
    setShowResponse(true);
  }, 2000);

  const onSubmit = (message: string) => {
    if (message.trim() === '') return;

    setIsLoading(true);
    setShowResponse(false);
    setUserMessage(message);
    setSearchTerm(message);

    timeout.start();
  };

  useEffect(
    function clearTimeout() {
      return () => {
        if (isLoading) {
          timeout.clear();
        }
      };
    },
    [timeout, isLoading]
  );

  useEffect(
    function resetAllWhenClosing() {
      return () => {
        if (isOpen) return;

        setIsLoading(false);
        setShowResponse(false);
        setUserMessage('');
        setSearchTerm('');
      };
    },
    [isOpen]
  );

  const tooltipContentRef = useRef(null);
  useOnClickOutside(tooltipContentRef, () => setIsOpen(false), isOpen);

  useKey(
    'Escape',
    () => {
      if (isOpen) {
        setIsOpen(false);
      }
    },
    {},
    [isOpen]
  );

  if (!isPartnerFeatureOn(featureFlags, 'ticket_insight_demo')) {
    return null;
  }

  return (
    <Tooltip
      opened={isOpen}
      withinPortal
      withArrow
      arrowSize={10}
      width={400}
      position="left-start"
      classNames={{
        tooltip: classes.tooltip,
      }}
      label={
        <Stack
          className={classes.tooltipContent}
          p="xs"
          c="gray.9"
          spacing={0}
          w={400}
          ref={tooltipContentRef}
        >
          <Group position="apart" p="xl" className={classes.header}>
            <Group spacing="xs">
              <InsightIcon />
              <Text size="md" weight={600}>
                Insights
              </Text>
            </Group>

            <CloseButton size="lg" onClick={() => setIsOpen(false)} />
          </Group>

          <div className={classes.body}>
            <Stack spacing="xl" h="100%">
              {showResponse ? (
                <InsightsResponse userMessage={userMessage} />
              ) : (
                <InsightsList
                  isLoading={isLoading}
                  onSelectInsight={onSubmit}
                />
              )}

              <InsightsFooter
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                isLoading={isLoading}
                onSubmit={onSubmit}
                showBaseInsightsAction={showResponse}
              />
            </Stack>
          </div>
        </Stack>
      }
    >
      <ActionIcon
        onClick={() => setIsOpen((prev) => !prev)}
        mr="md"
        size={30}
        variant="gradient"
        gradient={{
          deg: 124,
          from: '#9747FF',
          to: theme.fn.themeColor('primary', 3),
        }}
      >
        <NewIcon width={16} height={16} />
      </ActionIcon>
    </Tooltip>
  );
}

const useStyles = createStyles((theme) => ({
  header: {
    borderBottom: `1px solid ${theme.colors.gray[1]}`,
  },
  body: {
    overflow: 'hidden',
    paddingInline: theme.spacing.md,
    paddingBlock: theme.spacing.xl,
    width: '100%',
  },
  tooltip: {
    pointerEvents: 'all',
    background: 'white',
    filter: 'drop-shadow(-4px 4px 36px rgba(0, 2, 41, 0.1))',
    padding: 0,
  },
  tooltipContent: {
    border: 'none',
    minHeight: 410,
    display: 'grid',
    gridTemplateRows: 'max-content 1fr max-content',
    gridTemplateColumns: '1fr',
  },
}));
