import { Box, createStyles, Group, Spoiler, Stack, Text } from '@mantine/core';
import { size } from 'lodash/fp';
import React from 'react';

import { useDeviceUpdates } from '@portals/api/organizations';

import { ActiveCommandsEvent } from './ActiveCommandsEvent';
import { CommandInProgressEvent } from './CommandInProgressEvent';
import { ConfigurationEvent } from './ConfigurationEvent';
import { DeviceReplacementEvent } from './DeviceReplacementEvent';

export interface EventsProps {
  deviceId: string;
  waitingForDeviceReplacement: boolean;
}

export function Events({ deviceId, waitingForDeviceReplacement }: EventsProps) {
  const { classes } = useStyles();

  const deviceUpdates = useDeviceUpdates(deviceId, {
    staleTime: 5000,
    refetchInterval: 5000,
  });

  return (
    <Spoiler
      maxHeight={240}
      hideLabel={
        <Text color="gray.8" className={classes.spoilerHideLabel}>
          Hide older
        </Text>
      }
      showLabel={
        <Group w="100%" position="center" pt="sm" pos="relative">
          <Text color="gray.8" className={classes.spoilerShowLabel}>
            Show more
          </Text>

          <Box
            sx={{
              zIndex: 1,
              position: 'absolute',
              top: -24,
              left: 0,
              right: -20,
              height: 60,
              width: '100%',
              transform: 'translateY(-50%)',
              background:
                'linear-gradient(180deg, rgba(249, 250, 251, 0.00) 0%, #ffffff 100%)',
            }}
          />
        </Group>
      }
      styles={() => ({
        root: {
          '>button': {
            width: '100%',
            userSelect: 'none',
          },
        },
      })}
    >
      <Stack spacing="xs">
        {size(deviceUpdates.data?.active_commands) === 1 &&
        deviceUpdates.data?.active_commands[0].status === 'in_progress' ? (
          <CommandInProgressEvent
            command={deviceUpdates.data?.active_commands[0]}
          />
        ) : null}

        {size(deviceUpdates.data?.active_commands) > 1 ? (
          <ActiveCommandsEvent
            commands={deviceUpdates.data?.active_commands}
            deviceId={deviceId}
          />
        ) : null}

        {deviceUpdates.data?.should_update_config ? (
          <ConfigurationEvent />
        ) : null}

        {waitingForDeviceReplacement ? <DeviceReplacementEvent /> : null}
      </Stack>
    </Spoiler>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    backgroundColor: theme.fn.rgba(theme.colors.blue_gray[0], 0.3),
    borderRadius: theme.radius.lg,
    padding: theme.spacing.xxl,
  },

  spoilerShowLabel: {
    textDecoration: 'underline',
  },

  spoilerHideLabel: {
    textDecoration: 'underline',
    paddingTop: theme.spacing.sm,
  },
}));
