import {
  Avatar,
  Box,
  Button,
  createStyles,
  Group,
  Paper,
  SimpleGrid,
  Stack,
  Text,
} from '@mantine/core';
import { find } from 'lodash/fp';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as ArrowRight } from '@portals/icons/linear/arrow-right.svg';
import { ReactComponent as TickCircle } from '@portals/icons/linear/tick-circle.svg';

import xyteSrc from './assets/xyte.svg';
import { SERVICES } from './integrations.constants';

export const Payment = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const params = useParams<{ integration: string }>();
  const service = find({ id: params.integration }, SERVICES);

  return (
    <Box className={classes.container}>
      <Stack
        className={classes.content}
        spacing={32}
        align="center"
        justify="center"
      >
        <Group>
          <Avatar
            radius="md"
            src={xyteSrc}
            size={64}
            sx={(theme) => ({
              border: `1px solid ${theme.colors.blue_gray[0]}`,
              padding: 4,
              background: theme.white,
            })}
            imageProps={{
              style: {
                objectFit: 'contain',
              },
            }}
          />

          <ArrowRight />

          <Avatar
            radius="md"
            src={service?.logo}
            size={64}
            sx={(theme) => ({
              border: `1px solid ${theme.colors.blue_gray[0]}`,
              padding: 4,
              background: theme.white,
            })}
            imageProps={{
              style: {
                objectFit: 'contain',
              },
            }}
          />
        </Group>

        <Text mb={50} size="xl" color="dimmed">
          Choose a plan
        </Text>

        <SimpleGrid cols={2}>
          <Paper withBorder radius="md" p="lg">
            <Stack>
              <Text size="lg">Starter</Text>

              <Group spacing="xs" align="end">
                <Text size="xl" weight={600} inline>
                  $12
                </Text>
                <Text
                  size="xl"
                  weight={600}
                  inline
                  sx={(theme) => ({
                    color: theme.colors.gray[4],
                  })}
                >
                  /
                </Text>
                <Text
                  size="sm"
                  weight={600}
                  inline
                  sx={(theme) => ({
                    color: theme.colors.gray[4],
                  })}
                >
                  month
                </Text>
              </Group>

              <Stack spacing={0}>
                <Group align="center" spacing="xs">
                  <TickCircle />
                  <Text
                    size="sm"
                    sx={(theme) => ({ color: theme.colors.gray[7] })}
                  >
                    Access all basic features
                  </Text>
                </Group>
                <Group align="center" spacing="xs">
                  <TickCircle />
                  <Text
                    size="sm"
                    sx={(theme) => ({ color: theme.colors.gray[7] })}
                  >
                    Up to 2 team members
                  </Text>
                </Group>
                <Group align="center" spacing="xs">
                  <TickCircle />
                  <Text
                    size="sm"
                    sx={(theme) => ({ color: theme.colors.gray[7] })}
                  >
                    Basic reporting and analytics
                  </Text>
                </Group>
                <Group align="center" spacing="xs">
                  <TickCircle />
                  <Text
                    size="sm"
                    sx={(theme) => ({ color: theme.colors.gray[7] })}
                  >
                    Basic chat and email support
                  </Text>
                </Group>
              </Stack>

              <Button
                size="xs"
                mt={30}
                onClick={() =>
                  navigate(`/integrations?selected=${params.integration}`)
                }
              >
                Get Started
              </Button>
            </Stack>
          </Paper>

          <Paper withBorder radius="md" p="lg">
            <Stack>
              <Text size="lg">Premium</Text>

              <Group spacing="xs" align="end">
                <Text size="xl" weight={600} inline>
                  $25
                </Text>
                <Text
                  size="xl"
                  weight={600}
                  inline
                  sx={(theme) => ({
                    color: theme.colors.gray[4],
                  })}
                >
                  /
                </Text>
                <Text
                  size="sm"
                  weight={600}
                  inline
                  sx={(theme) => ({
                    color: theme.colors.gray[4],
                  })}
                >
                  month
                </Text>
              </Group>

              <Stack spacing={0}>
                <Group align="center" spacing="xs">
                  <TickCircle />
                  <Text
                    size="sm"
                    sx={(theme) => ({ color: theme.colors.gray[7] })}
                  >
                    Advanced features
                  </Text>
                </Group>
                <Group align="center" spacing="xs">
                  <TickCircle />
                  <Text
                    size="sm"
                    sx={(theme) => ({ color: theme.colors.gray[7] })}
                  >
                    Unlimited members
                  </Text>
                </Group>
                <Group align="center" spacing="xs">
                  <TickCircle />
                  <Text
                    size="sm"
                    sx={(theme) => ({ color: theme.colors.gray[7] })}
                  >
                    Audit log and data history
                  </Text>
                </Group>
                <Group align="center" spacing="xs">
                  <TickCircle />
                  <Text
                    size="sm"
                    sx={(theme) => ({ color: theme.colors.gray[7] })}
                  >
                    Personalised support
                  </Text>
                </Group>
              </Stack>

              <Button
                size="xs"
                mt={30}
                onClick={() =>
                  navigate(`/integrations?selected=${params.integration}`)
                }
              >
                Get Started
              </Button>
            </Stack>
          </Paper>
        </SimpleGrid>
      </Stack>
    </Box>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    overflow: 'auto',
    backgroundColor: theme.colors.gray[0],
  },
  content: {
    maxWidth: 574,
    height: '100%',
    width: '100%',
    paddingTop: 30,
  },
}));
