import {
  Button,
  Checkbox,
  Divider,
  Group,
  LoadingOverlay,
  SimpleGrid,
  Stack,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { isEmpty } from 'lodash/fp';
import React from 'react';
import { object, string } from 'yup';

import {
  useNotificationSettings,
  useUpdateConfig,
} from '@portals/api/partners';
import { usePermissionAccess } from '@portals/framework';
import { PartnerNotificationSettingsType } from '@portals/types';

import { Webhooks } from '../../components/Webhooks/Webhooks';

const DEFAULT_SETTINGS_CONFIG: PartnerNotificationSettingsType = {
  email: '',
  notify_referral: false,
  notify_ticket: false,
};

const schema = object({
  email: string().email(),
});

const EditNotifications = () => {
  const { isAdmin } = usePermissionAccess();

  const updateConfig = useUpdateConfig();
  const notificationSettings = useNotificationSettings();

  const form = useForm<PartnerNotificationSettingsType>({
    initialValues: isEmpty(notificationSettings)
      ? DEFAULT_SETTINGS_CONFIG
      : notificationSettings,
    validate: yupResolver(schema),
  });

  const onSubmit = (notifications: PartnerNotificationSettingsType) => {
    updateConfig.mutate({
      updatedConfig: { notification_settings: notifications },
      withSuccessNotification: true,
    });
  };

  return (
    <Stack
      p="md"
      spacing="xl"
      sx={{
        '.modal-body': {
          padding: 0,
          marginBottom: 20,
        },
      }}
    >
      <SimpleGrid cols={2}>
        <form onSubmit={form.onSubmit(onSubmit)}>
          <Stack sx={{ position: 'relative' }}>
            <LoadingOverlay visible={updateConfig.isLoading} />

            <Title order={3}>Email Notifications</Title>

            <TextInput
              label="Email"
              {...form.getInputProps('email')}
              disabled={!isAdmin}
            />

            <Checkbox
              label="Notify on new referral"
              {...form.getInputProps('notify_referral', { type: 'checkbox' })}
              disabled={!isAdmin}
            />

            <Checkbox
              label="Notify on new ticket"
              {...form.getInputProps('notify_ticket', { type: 'checkbox' })}
              disabled={!isAdmin}
            />

            <Group py="md" position="right">
              <Button type="submit">Save</Button>
            </Group>
          </Stack>
        </form>
      </SimpleGrid>

      <Divider />

      <Webhooks />
    </Stack>
  );
};

export default EditNotifications;
