import { Badge, Group, Stack, Tabs, TabsProps } from '@mantine/core';
import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import {
  OrderShipmentType,
  SHIPMENTS_API_URL,
  shipmentsQueryKeys,
  useNewShipments,
} from '@portals/api/partners';
import {
  DashboardContentLayout,
  OrderItemsPreviewTooltip,
  OrderItemsPreviewTooltipProps,
} from '@portals/framework';
import {
  PartnerOrderFulfillmentStatusBadge,
  RouteModalLink,
} from '@portals/framework/route-modals';
import { AvatarCell, DateCell, PaginatedTable } from '@portals/table';
import { TableColumn, TableFilterTypeEnum } from '@portals/types';

const TABLE_COLUMNS: Array<TableColumn<OrderShipmentType>> = [
  {
    dataField: 'short_id',
    text: 'ID',
    sort: true,
    minWidth: 100,
    isSticky: true,
    filter: {
      type: TableFilterTypeEnum.Text,
    },
    formatter: (_, { id, short_id }) => (
      <RouteModalLink modalId="order" pathParams={[id]}>
        {short_id}
      </RouteModalLink>
    ),
  },
  {
    dataField: 'organization_display_name',
    text: 'Customer',
    sort: true,
    maxWidth: 200,
    filter: {
      type: TableFilterTypeEnum.Text,
    },
    formatter: (_, { organization }) => (
      <AvatarCell
        src={organization.logo_url}
        label={organization.display_name}
        radius="md"
      />
    ),
  },
  {
    dataField: 'items_count',
    text: 'Items',
    minWidth: 100,
    sort: true,
    filter: {
      type: TableFilterTypeEnum.Number,
    },
    formatter: (_, { items, items_count }) => {
      if (!items_count) return null;

      const itemsList: OrderItemsPreviewTooltipProps['items'] = items.map(
        (orderShipmentItem) => ({
          name: orderShipmentItem.name,
          quantity: orderShipmentItem.quantity,
          imageUrl: orderShipmentItem.image_url,
        })
      );

      return (
        <OrderItemsPreviewTooltip items={itemsList}>
          <Badge fw={400}>
            {items_count === 1 ? '1 item' : `${items_count} items`}
          </Badge>
        </OrderItemsPreviewTooltip>
      );
    },
  },
  {
    dataField: 'created_at',
    text: 'Date Placed',
    sort: true,
    filter: {
      type: TableFilterTypeEnum.Date,
    },
    minWidth: 250,
    maxWidth: 250,
    formatter: (cell, { created_at }) => (
      <DateCell date={created_at} withTimeAgo={false} />
    ),
  },
  {
    dataField: 'fulfillment_status',
    filter: {
      type: TableFilterTypeEnum.Select,
      options: {
        processing: 'Needs Shipments',
        ready: 'Shipment ready',
        shipped: 'Shipped',
        delivered: 'Delivered',
        available: 'Available',
        order_placed: 'New order placed',
        missing_information: 'Missing buyer input',
        cancelled_during_ready: 'Cancelled during ready',
        cancelled: 'Cancelled',
      },
    },
    text: 'Status',
    sort: true,
    maxWidth: 150,
    formatter: (_, order) => (
      <PartnerOrderFulfillmentStatusBadge order={order} />
    ),
  },
];

type OrderStatusType =
  | 'all'
  | 'processing'
  | 'shipment_ready'
  | 'shipped'
  | 'delivered'
  | 'cancelled';

export function Shipping() {
  const navigate = useNavigate();
  const { tab } = useParams<{ tab?: string }>();

  useEffectOnce(() => {
    if (!tab) {
      navigate('/store-management/shipping/all', { replace: true });
    }
  });

  const onTabChange: TabsProps['onTabChange'] = (tabId: OrderStatusType) => {
    navigate(`/store-management/shipping/${tabId}`);
  };

  const dataHookUrl = useMemo(() => {
    switch (tab) {
      case 'processing':
        return `${SHIPMENTS_API_URL}?status=processing`;

      case 'shipment_ready':
        return `${SHIPMENTS_API_URL}?status=ready`;

      case 'shipped':
        return `${SHIPMENTS_API_URL}?status=shipped`;

      case 'delivered':
        return `${SHIPMENTS_API_URL}?status=delivered`;

      case 'cancelled':
        return `${SHIPMENTS_API_URL}?status=cancelled`;

      default:
        return SHIPMENTS_API_URL;
    }
  }, [tab]);

  const dataHookQueryKey = useMemo(() => {
    switch (tab) {
      case 'processing':
        return shipmentsQueryKeys.processing();

      case 'shipment_ready':
        return shipmentsQueryKeys.shipment_ready();

      case 'shipped':
        return shipmentsQueryKeys.shipped();

      case 'delivered':
        return shipmentsQueryKeys.delivered();

      default:
        return shipmentsQueryKeys.all;
    }
  }, [tab]);

  return (
    <DashboardContentLayout pageTitle="Shipping" bg="gray.0">
      <Stack>
        <Group>
          <Tabs
            keepMounted={false}
            value={tab || 'all'}
            onTabChange={onTabChange}
          >
            <Tabs.List grow>
              <Tabs.Tab value="all" px={35}>
                All
              </Tabs.Tab>

              <Tabs.Tab value="processing" px={35}>
                Processing
              </Tabs.Tab>

              <Tabs.Tab value="shipment_ready" px={35}>
                Shipment Ready
              </Tabs.Tab>

              <Tabs.Tab value="shipped" px={35}>
                Shipped
              </Tabs.Tab>

              <Tabs.Tab value="delivered" px={35}>
                Delivered
              </Tabs.Tab>

              <Tabs.Tab value="cancelled" px={35}>
                Cancelled
              </Tabs.Tab>
            </Tabs.List>
          </Tabs>
        </Group>

        <PaginatedTable<OrderShipmentType>
          keyField="id"
          dataHook={useNewShipments}
          dataHookUrl={dataHookUrl}
          dataHookQueryKey={dataHookQueryKey}
          columns={TABLE_COLUMNS}
          noDataIndication={{ title: 'No shipments' }}
          defaultSortBy={[{ id: 'created_at', desc: true }]}
          name="partners.orders"
        />
      </Stack>
    </DashboardContentLayout>
  );
}
