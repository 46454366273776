import { Alert, Button, createStyles, Group, Text } from '@mantine/core';
import React from 'react';

import { ReactComponent as InfoCircle } from '@portals/icons/bold/info-circle.svg';
import { ReactComponent as Eye } from '@portals/icons/linear/eye.svg';

export function PurchaseOrderAlert() {
  const { classes, theme } = useStyles();

  return (
    <Alert
      color="amber"
      radius="md"
      px="xl"
      py="lg"
      icon={
        <InfoCircle width={24} height={24} color={theme.colors.orange[6]} />
      }
      classNames={{
        root: classes.root,
        wrapper: classes.wrapper,
      }}
    >
      <Text color="gray.9" size="md">
        A purchase order must be uploaded by the customer before the order can
        be finalized.
      </Text>
    </Alert>
  );
}

interface PurchaseOrderReviewAlertProps {
  onReview: () => void;
}

export function PurchaseOrderReviewAlert({
  onReview,
}: PurchaseOrderReviewAlertProps) {
  const { classes, theme } = useStyles();

  return (
    <Alert
      color="amber"
      radius="md"
      px="xl"
      py="lg"
      icon={
        <InfoCircle width={24} height={24} color={theme.colors.orange[6]} />
      }
      classNames={{
        root: classes.root,
        wrapper: classes.wrapper,
      }}
    >
      <Group align="center" position="apart">
        <Text color="gray.9" size="md">
          You must review and approve purchase order before the order can be
          placed.
        </Text>

        <Button onClick={onReview} color="amber" variant="light">
          <Group align="center" spacing="xs">
            <Eye width={18} height={19} color={theme.colors.gray[9]} />

            <Text size="md" color="gray.9">
              Review Purchase Order
            </Text>
          </Group>
        </Button>
      </Group>
    </Alert>
  );
}

export function MissingInformationAlert() {
  const { classes, theme } = useStyles();

  return (
    <Alert
      color="amber"
      radius="md"
      px="xl"
      py="lg"
      icon={
        <InfoCircle width={24} height={24} color={theme.colors.orange[6]} />
      }
      classNames={{
        root: classes.root,
        wrapper: classes.wrapper,
      }}
    >
      <Text color="gray.9" size="md">
        This order is missing crucial details. Customer must complete the
        required information to proceed purchase.
      </Text>
    </Alert>
  );
}

const useStyles = createStyles((theme) => ({
  root: {
    border: `1px solid ${theme.colors.gray[2]}`,
    flexShrink: 0,
  },
  wrapper: {
    alignItems: 'center',
  },
  button: {
    backgroundColor: 'transparent',
  },
  buttonIcon: {
    marginRight: 5,

    svg: {
      width: 17,
      height: 17,
    },
  },
}));
