import { Badge, Group, SimpleGrid, Text, Title } from '@mantine/core';
import { noop } from 'lodash/fp';
import React, { useEffect, useRef } from 'react';

import { useFeatureFlags } from '@portals/api/partners';
import { isPartnerFeatureOn } from '@portals/framework';
import {
  BoxCogWrench,
  BoxOpen,
  CogWrench,
  Device,
  DocumentMedal,
  Platform,
} from '@portals/framework/icons';
import { ProductTypeEnum } from '@portals/types';

import { ProductTypeCard } from './ProductTypeCard';
import { useProductWizard } from '../use-product-wizard';

export function ProductTypeStep() {
  const { onNext, setContextData } = useProductWizard();
  const shouldCallOnNext = useRef(false);
  const featureFlags = useFeatureFlags();

  const showAllProductTypes = isPartnerFeatureOn(
    featureFlags,
    'enable_all_product_types'
  );

  const onSelectProductType = (productType: ProductTypeEnum) => {
    shouldCallOnNext.current = true;

    setContextData((prev) => ({
      ...prev,
      product: {
        ...prev.product,
        product_type: productType,
      },
    }));
  };

  useEffect(() => {
    // We need to invoke `onNext` only after the `contextData` was updated with the selected `product_type`
    if (shouldCallOnNext.current) {
      shouldCallOnNext.current = false;
      onNext();
    }
  }, [onNext]);

  return (
    <>
      <Title
        data-testid="add-product-listing-modal"
        order={2}
        weight={600}
        mb={40}
        align="center"
      >
        What product would you like to create?
      </Title>

      <SimpleGrid cols={3} spacing="xl" sx={{ placeContent: 'center' }}>
        <ProductTypeCard
          title="Physical product"
          description="Offer any type of hardware and peripheral equipment, in any type of business model"
          icon={<BoxOpen />}
          disabled={false}
          onClick={() => onSelectProductType(ProductTypeEnum.Physical)}
        />
        <ProductTypeCard
          title="Device license"
          description="Offer customers the ability to activate device-specific features for a fee. One model per license"
          icon={<Device />}
          disabled={false}
          onClick={() => onSelectProductType(ProductTypeEnum.DeviceLicense)}
        />
        <ProductTypeCard
          title={
            <Group
              data-testid="product-type-card-title-Platform-license"
              spacing="xs"
              position="center"
            >
              <Text>Platform license </Text>

              <Badge
                h={24}
                c="gray.9"
                fw={400}
                fz="xs"
                variant="gradient"
                gradient={{ from: '#E9F3F7', to: '#DEDBFD', deg: 108 }}
              >
                BETA
              </Badge>
            </Group>
          }
          description="Offer customers the ability to unlock remote commands for a fee. Works across multiple models"
          icon={<Platform />}
          disabled={false}
          onClick={() => onSelectProductType(ProductTypeEnum.PlatformLicense)}
        />

        {showAllProductTypes ? (
          <>
            <ProductTypeCard
              title="Service"
              description="Sell your customers any service, such as a long-term support contract, or a one-time visit"
              icon={<CogWrench />}
              disabled={showAllProductTypes}
              onClick={noop}
            />
            <ProductTypeCard
              title="Warranty"
              description="Is your customer’s warranty about to expire? easily offer an extended, customized warranty"
              icon={<DocumentMedal />}
              disabled={showAllProductTypes}
              onClick={noop}
            />
            <ProductTypeCard
              title="Bundle"
              description="Sell a bundle of a variety of products, of any type, and at any business model"
              icon={<BoxCogWrench />}
              disabled={showAllProductTypes}
              onClick={noop}
            />
          </>
        ) : null}
      </SimpleGrid>
    </>
  );
}
