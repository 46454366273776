import { Box, LoadingOverlay } from '@mantine/core';
import { capitalize, replace, startsWith } from 'lodash/fp';
import React, { useMemo } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';

import { useIncident } from '@portals/api/partners';
import { DashboardContentLayout } from '@portals/framework';
import { RouteModalLink } from '@portals/framework/route-modals';
import { AvatarCell, BadgeCell, InfoTable } from '@portals/table';
import { INCIDENT_TYPE, prettyTime } from '@portals/utils';

export function IncidentDetails() {
  const { id } = useParams<{ id: string }>();
  const incident = useIncident(id);

  const issueValue = useMemo(() => {
    if (!incident.isFetched || incident.error) return null;

    const isRule = startsWith('rule_', incident.data?.issue);

    return isRule ? (
      <Link to={`/rules/${replace('rule_', '', incident.data?.issue)}`}>
        View Rule
      </Link>
    ) : (
      capitalize(incident.data?.issue)
    );
  }, [incident.data?.issue, incident.error, incident.isFetched]);

  if (!incident.isFetched) {
    return <LoadingOverlay visible />;
  }

  if (incident.error) return <Navigate to="/incidents" replace />;

  return (
    <DashboardContentLayout
      pageTitle="Incident Information"
      breadcrumbs={[
        { label: 'Incidents', to: '/incidents' },
        { label: incident.data?.title },
      ]}
    >
      <Box>
        <InfoTable.Table>
          <InfoTable.Row isHeader label="General" value={null} />
          <InfoTable.Row label="ID" value={incident.data.id} />
          <InfoTable.Row label="Title" value={incident.data.title} />
          <InfoTable.Row
            label="Description"
            value={incident.data.description}
          />
          <InfoTable.Row
            label="Customer"
            value={
              incident.data.org_id ? (
                <RouteModalLink
                  modalId="customer"
                  pathParams={[incident.data?.org_id]}
                >
                  <AvatarCell
                    src={incident.data?.org_logo_url}
                    label={incident.data?.org_display_name}
                    radius="md"
                  />
                </RouteModalLink>
              ) : (
                <AvatarCell
                  src={incident.data?.org_logo_url}
                  label={incident.data?.org_display_name}
                  radius="md"
                />
              )
            }
          />
          <InfoTable.Row label="Device" value={incident.data.device_name} />
          <InfoTable.Row
            label="Model"
            value={incident.data.device_model_name}
          />
          <InfoTable.Row label="Space Name" value={incident.data.space_name} />
          <InfoTable.Row label="Space Path" value={incident.data.space_path} />

          <InfoTable.Row isHeader label="Details" value={null} />
          <InfoTable.Row
            label="Priority"
            value={
              <BadgeCell
                label={INCIDENT_TYPE[incident.data.priority - 1].title}
                color={INCIDENT_TYPE[incident.data.priority - 1].color}
              />
            }
          />
          <InfoTable.Row label="Issue" value={issueValue} />

          <InfoTable.Row isHeader label="History" value={null} />
          <InfoTable.Row
            label="Created"
            data-testid="created-row"
            value={prettyTime(incident.data.created_at)}
          />

          {incident.data?.updated_at ? (
            <InfoTable.Row
              label="Last Updated"
              value={prettyTime(incident.data.updated_at)}
            />
          ) : null}
        </InfoTable.Table>
      </Box>
    </DashboardContentLayout>
  );
}
