import { connect } from 'react-redux';

import { ConfigView } from '@portals/framework';

import { updateConfig } from '../../redux/actions/ticket_device';

const mapDispatchToProps = (dispatch, ownProps) => ({
  // The first parameter is deviceId - but we ignore it and use ticketId instead
  updateConfig: (_, config) =>
    dispatch(updateConfig(ownProps.ticket.id, config)),
});

export default connect(null, mapDispatchToProps)(ConfigView);
