import { Center, createStyles, Overlay, Portal } from '@mantine/core';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useMemo } from 'react';
import { useDragDropManager, useDragLayer } from 'react-dnd';

import { StoreListing } from '@portals/api/partners';
import { DragCross, ProductCard } from '@portals/framework';

import { StoreListingProductCardPrice } from '../../components/store-listings/StoreListingProductCardPrice';
import { mapStoreListingPricesToPaymentSettings } from '../../utils/store-listings.utils';

interface StoreListingDraggablePreviewProductCardProps {
  storeListing: StoreListing;
  paymentSettings: ReturnType<typeof mapStoreListingPricesToPaymentSettings>;
  position: number;
}

export function StoreListingDraggablePreviewProductCard({
  storeListing,
  paymentSettings,
  position,
}: StoreListingDraggablePreviewProductCardProps) {
  const { classes } = useStyles();

  const { isDragging, initialOffset, currentOffset, item } = useDragLayer(
    (monitor) => ({
      item: monitor.getItem(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    })
  );

  const isBeingDragged =
    isDragging &&
    initialOffset &&
    currentOffset &&
    item?.id === storeListing?.id;

  const previewTransform = useMemo(() => {
    if (!isBeingDragged) {
      return null;
    }

    return {
      x: currentOffset.x,
      y: currentOffset.y,
    };
  }, [currentOffset, isBeingDragged]);

  const dragDropManager = useDragDropManager();

  const targetIds = dragDropManager?.getMonitor()?.getTargetIds();
  const isOver = dragDropManager?.getMonitor()?.canDropOnTarget(targetIds[0]);

  return (
    <Portal>
      <AnimatePresence>
        {isBeingDragged ? (
          <motion.div
            className={classes.draggableProductCard}
            key={item?.id}
            initial={{
              width: item?.getSize().width,
              x: initialOffset?.x,
              y: initialOffset?.y,
              rotate: '0deg',
              opacity: 1,
              scale: 1,
              display: 'none',
            }}
            animate={{
              width: item?.getSize().width,
              x: previewTransform?.x,
              y: previewTransform?.y,
              display: 'inline-block',
              scale: 1.0,
              rotate: isOver ? '0deg' : '-10deg',
              opacity: 0.5,
            }}
            exit={{
              width: item?.getSize().width,
              display: 'none',
              scale: 1,
              x: initialOffset?.x,
              y: initialOffset?.y,
              rotate: '0deg',
              opacity: 0,
            }}
          >
            <ProductCard w="100%">
              <ProductCard.Image
                imageUrl={storeListing.product?.image_url}
                category={storeListing.product?.category}
                className={classes.productCardImage}
                headerSlot={
                  <Center className={classes.positionIndicator}>
                    {position}
                  </Center>
                }
              >
                <Overlay center zIndex={1}>
                  <DragCross className={classes.dragIcon} />
                </Overlay>
              </ProductCard.Image>

              <ProductCard.Details
                name={storeListing.product.name}
                subtitle={storeListing.product.subtitle}
              >
                <StoreListingProductCardPrice
                  showBasePrice
                  product={storeListing.product}
                  paymentSettings={paymentSettings}
                />
              </ProductCard.Details>
            </ProductCard>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </Portal>
  );
}

const useStyles = createStyles((theme) => ({
  positionIndicator: {
    minWidth: 50,
    padding: theme.spacing.xs,
    border: `1px solid ${theme.colors.gray[3]}`,
    borderRadius: theme.radius.md,
    backgroundColor: theme.white,
  },

  draggableProductCard: {
    pointerEvents: 'none',
    zIndex: 900,
    height: 'auto',
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'block',
  },

  productCardImage: {
    '.product-card-image-header': {
      flexWrap: 'nowrap',
      zIndex: 2,
    },
  },

  dragIcon: {
    width: '40%',
    height: '40%',
    maxWidth: 120,
    maxHeight: 120,
    color: theme.white,
  },
}));
