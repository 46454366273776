import React from 'react';

import {
  DashboardContentLayout,
  RoutedTabs,
  RoutedTabsProps,
} from '@portals/framework';

import { InvoicesTable } from './InvoicesTable';

const ROUTES: RoutedTabsProps['routes'] = [
  {
    path: 'all',
    tabLabel: 'All',
    element: <InvoicesTable status="all" />,
  },
  {
    path: 'pending',
    tabLabel: 'Pending',
    element: (
      <InvoicesTable
        status="pending"
        subtitle="Invoices that needs to be paid"
      />
    ),
  },
  {
    path: 'paid',
    tabLabel: 'Paid',
    element: <InvoicesTable status="paid" subtitle="Paid invoices" />,
  },
  {
    path: 'failed',
    tabLabel: 'Payment failed',
    element: (
      <InvoicesTable
        status="failed"
        subtitle="Invoices with failed payment. Please retry payment or update the payment method."
      />
    ),
  },
  {
    path: 'uncollectible',
    tabLabel: 'Uncollectible',
    element: (
      <InvoicesTable status="uncollectible" subtitle="Uncollectible invoices" />
    ),
  },
  {
    path: 'refunded',
    tabLabel: 'Payment refunded',
    element: (
      <InvoicesTable
        status="refunded"
        subtitle="Payment for these invoices was refunded"
      />
    ),
  },
];

export function Invoices() {
  return (
    <DashboardContentLayout pageTitle="Invoices" bg="gray.0">
      <RoutedTabs routes={ROUTES} basePath="/store-management/invoices" />
    </DashboardContentLayout>
  );
}
