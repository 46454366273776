import { Anchor, Group, Paper, Stack, Text } from '@mantine/core';
import React from 'react';

import {
  useIsChannelPartner,
  useRequestPlanUpgrade,
  useSupportSeats,
} from '@portals/api/partners';
import { useOpenModal } from '@portals/redux';

import { AddSupportSeatsModalProps } from '../../../modals';

export function SupportSeats() {
  const supportSeats = useSupportSeats();
  const requestPlanUpgrade = useRequestPlanUpgrade();
  const isChannelPartner = useIsChannelPartner();

  const openModal = useOpenModal();

  const onAddSeatsClick = () => {
    if (isChannelPartner) {
      openModal<AddSupportSeatsModalProps['data']>('AddSupportSeatsModal');
    } else {
      requestPlanUpgrade.mutate(
        'Partners > Settings > Support > Support Seats > Add Seats'
      );
    }
  };

  return (
    <Paper withBorder p="xl" radius="md">
      <Stack spacing="xl">
        <Text size="md" color="gray.8">
          Support Seats
        </Text>

        <Group position="apart" align="center" h={48}>
          <Text color="gray.9" size={32} weight={600}>
            {supportSeats.data?.in_use} / {supportSeats.data?.total}
          </Text>

          <Anchor size="md" onClick={onAddSeatsClick}>
            Add Seats
          </Anchor>
        </Group>
      </Stack>
    </Paper>
  );
}
