import {
  AvatarProps,
  Box,
  createStyles,
  Group,
  Indicator,
  IndicatorProps,
  Text,
  Tooltip,
} from '@mantine/core';
import React from 'react';

import { DeviceDetailsType, RelatedDevice } from '@portals/api/organizations';
import { DeviceStatusType } from '@portals/types';

import { DeviceAvatar } from './DeviceAvatar';
import {
  getDeviceName,
  getDeviceStatusColor,
  getDeviceStatusText,
} from '../route-modals';

interface DeviceAvatarProps {
  deviceName: string;
  deviceModelSettings:
    | RelatedDevice['model_settings']
    | DeviceDetailsType['model_settings'];
  deviceStatus: DeviceStatusType | undefined;
  avatarProps?: AvatarProps;
}

export function DeviceAvatarWithIndicator({
  deviceStatus,
  deviceName,
  deviceModelSettings,
  avatarProps = {},
}: DeviceAvatarProps) {
  const { classes } = useStyles();
  const statusColor = getDeviceStatusColor(deviceStatus);

  return (
    <Indicator
      inline
      withBorder
      size={20}
      styles={indicatorStyles}
      color={statusColor.indicator}
    >
      <Tooltip
        withArrow
        arrowSize={5}
        position="bottom"
        label={
          <Group noWrap>
            <Text size="xs">{getDeviceName(deviceName)}</Text>
            <Text size="xs" className={classes.statusText}>
              {getDeviceStatusText(deviceStatus)}
            </Text>
          </Group>
        }
      >
        <Box>
          <DeviceAvatar
            img={deviceModelSettings?.media?.img}
            icon={deviceModelSettings?.icon}
            {...avatarProps}
          />
        </Box>
      </Tooltip>
    </Indicator>
  );
}

const useStyles = createStyles((theme) => ({
  statusText: {
    fontSize: theme.fontSizes.xs,
    color: theme.colors.blue_gray[2],
    textTransform: 'capitalize',
  },
}));

const indicatorStyles: IndicatorProps['styles'] = () => ({
  root: { cursor: 'pointer', zIndex: 0 },
  indicator: { borderWidth: 5 },
});
