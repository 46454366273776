import { Center, createStyles, Paper } from '@mantine/core';
import React from 'react';

import { usePartnerConfig } from '@portals/api/partners';

import { TicketsChatPreview } from './TicketsChatPreview';
import { useBrandingContext } from '../../branding.context';

export function TicketsPreview() {
  const { classes } = useStyles();

  const { portalConfig } = useBrandingContext();
  const partnerConfig = usePartnerConfig();

  const logoSrc = portalConfig.design?.cec_tickets?.logo;
  const partnerName = partnerConfig?.display_name || 'Xyte';

  return (
    <Center h="100%" py={70}>
      <Paper withBorder className={classes.chatWrapper}>
        <TicketsChatPreview logoSrc={logoSrc} partnerName={partnerName} />
      </Paper>
    </Center>
  );
}

const useStyles = createStyles((theme) => ({
  chatWrapper: {
    overflow: 'hidden',
    width: '85%',
    maxHeight: '100%',
    padding: theme.spacing.xl,
    borderRadius: theme.radius.lg,
    boxShadow: theme.shadows.xl,
  },
}));
