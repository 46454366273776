import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '@portals/redux';
import { signedIn } from '@portals/redux/actions/auth';
import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';
import { AuthType, TenantType } from '@portals/types';

import { USERS_API_URL, usersQueryKeys } from './users.constants';
import {
  OrganizationWithUserAccess,
  SelfUserResponseType,
  UserResponseType,
  WelcomeDetailsType,
} from './users.types';
import { useApiQuery } from '../../hooks';
import { ServerError } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';

export function useUsers() {
  return useApiQuery<UserResponseType[]>(USERS_API_URL, usersQueryKeys.all());
}

interface UseCheckUserParams {
  email: string;
}

interface CheckUserResponseType {
  in_use: boolean;
}

export function useEmailAvailabilityCheckWithinDomain() {
  const dispatch = useDispatch();

  const { url, options } = useRequestOptions({
    url: `${USERS_API_URL}/check_email_within_domain`,
    method: 'POST',
  });

  return useMutation<CheckUserResponseType, ServerError, UseCheckUserParams>({
    mutationFn: ({ email }) => {
      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify({
          email,
        }),
      });
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
  });
}

export function useCurrentUser() {
  const auth = useAuth();

  return useApiQuery<SelfUserResponseType>(
    `${USERS_API_URL}/self`,
    usersQueryKeys.self(),
    {
      enabled: Boolean(auth),
    }
  );
}

interface UseCreateUserParams {
  email: string;
  name?: string;
  addToLab?: boolean;
  groupIds?: string[];
  assignSupportSeat?: boolean;
}

export function useCreateUser() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: USERS_API_URL,
    method: 'POST',
  });

  return useMutation<UserResponseType, ServerError, UseCreateUserParams>({
    mutationFn: ({ name, email, groupIds, addToLab, assignSupportSeat }) => {
      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify({
          name,
          email,
          add_to_lab: addToLab,
          group_ids: groupIds,
          assign_support_seat: assignSupportSeat,
        }),
      });
    },
    onSuccess: () => {
      dispatch(toastrSuccess('User created successfully'));

      queryClient.invalidateQueries(usersQueryKeys.all());
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useCreateUser',
      baseUrl: USERS_API_URL,
      method: 'POST',
    },
  });
}

export function useResendWelcomeEmail() {
  const dispatch = useDispatch();

  const { url, options } = useRequestOptions({
    url: USERS_API_URL,
    method: 'POST',
  });

  return useMutation<void, ServerError, { userId: string }>({
    mutationFn: ({ userId }) => {
      return fetchApiRequest(`${url}/${userId}/resend_welcome_email`, options);
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Welcome email sent successfully'));
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useResendWelcomeEmail',
      baseUrl: `${USERS_API_URL}/:id/resend_welcome_email`,
      method: 'POST',
    },
  });
}

export function useAssignSupportSeatToUser() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: USERS_API_URL,
    method: 'POST',
  });

  return useMutation<any, ServerError, { userId: string }>({
    mutationFn: ({ userId }) => {
      return fetchApiRequest(`${url}/${userId}/add_support_seat`, options);
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Support seat assigned successfully'));

      queryClient.invalidateQueries(usersQueryKeys.all());
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useAssignSupportSeatToUser',
      baseUrl: `${USERS_API_URL}/:id/add_support_seat`,
      method: 'POST',
    },
  });
}

export function useRevokeUserSupportSeat() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: USERS_API_URL,
    method: 'POST',
  });

  return useMutation<any, ServerError, { userId: string }>({
    mutationFn: ({ userId }) => {
      return fetchApiRequest(`${url}/${userId}/remove_support_seat`, options);
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Support seat revoked successfully'));

      queryClient.invalidateQueries(usersQueryKeys.all());
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useRevokeUserSupportSeat',
      baseUrl: `${USERS_API_URL}/:id/remove_support_seat`,
      method: 'POST',
    },
  });
}

interface UseWelcomeDetailsParams {
  tenant: TenantType;
  token: string;
}

export function useWelcomeDetails({ tenant, token }: UseWelcomeDetailsParams) {
  return useApiQuery<WelcomeDetailsType>(
    `${USERS_API_URL}/get_user_details/${tenant}/${token}`,
    usersQueryKeys.welcomeDetails(),
    {
      enabled: Boolean(tenant) && Boolean(token),
    }
  );
}

interface UseUpdateUserDetailsParams {
  token: string;
  password: string;
  name: string;
  tenant: TenantType;
}

export function useUpdateUserDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { url, options } = useRequestOptions({
    url: USERS_API_URL,
    method: 'POST',
  });

  return useMutation<AuthType, ServerError, UseUpdateUserDetailsParams>({
    mutationFn: (params) => {
      return fetchApiRequest(`${url}/set_user_details`, {
        ...options,
        body: JSON.stringify(params),
      });
    },
    onSuccess: (response) => {
      dispatch(toastrSuccess('User details updated successfully'));

      dispatch(signedIn(response));
      navigate('/');
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useUpdateUserDetails',
      baseUrl: `${USERS_API_URL}/set_user_details`,
      method: 'POST',
    },
  });
}

interface UserAssociationsAndAccessResponseType {
  has_support_seat: boolean;
  organizations: Array<OrganizationWithUserAccess>;
}

export function useUserAssociationsAndAccess(userId: string) {
  return useApiQuery<UserAssociationsAndAccessResponseType>(
    `${USERS_API_URL}/${userId}/get_user_associations`,
    usersQueryKeys.userAssociationsAndAccess(userId)
  );
}

interface UseDeleteUserParams {
  userId: string;
}

export function useDeleteUser() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: USERS_API_URL,
    method: 'POST',
  });

  return useMutation<void, ServerError, UseDeleteUserParams>({
    mutationFn: ({ userId }) =>
      fetchApiRequest(`${url}/${userId}/deactivate`, options),
    onSuccess: () => {
      queryClient.invalidateQueries(usersQueryKeys.all());
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useDeleteUser',
      baseUrl: `${USERS_API_URL}/:id/deactivate`,
      method: 'POST',
    },
  });
}

interface UseIsWelcomeVideosPlayedParams {
  user: SelfUserResponseType;
}

export function useIsWelcomeVideosPlayed() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: USERS_API_URL,
    method: 'PUT',
  });

  return useMutation<void, ServerError, UseIsWelcomeVideosPlayedParams>({
    mutationFn: ({ user }) => {
      return fetchApiRequest(`${url}/${user.id}`, {
        ...options,
        body: JSON.stringify({
          settings: { ...user.settings, isWelcomeVideosPlayed: true },
        }),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(usersQueryKeys.all());
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useIsWelcomeVideosPlayed',
      baseUrl: `${USERS_API_URL}/:id`,
      method: 'PUT',
    },
  });
}
