import { createStyles, Group, Stack, Text } from '@mantine/core';
import React, { ReactNode } from 'react';

interface InsightItemProps {
  title: string;
  description: string;
  icon: ReactNode;
  onClick: () => void;
}

export function InsightItem({
  title,
  description,
  icon,
  onClick,
}: InsightItemProps) {
  const { classes } = useStyles();

  return (
    <Group onClick={onClick} className={classes.container} noWrap>
      <div className={classes.iconWrapper}>{icon}</div>

      <Stack spacing={4} sx={{ overflow: 'hidden' }}>
        <Text weight={600}>{title}</Text>
        <Text color="gray.5" size="xs" truncate>
          {description}
        </Text>
      </Stack>
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    paddingInline: theme.spacing.xs,
    paddingBlock: theme.spacing.md,
    borderRadius: theme.radius.md,
    cursor: 'pointer',

    ':hover': {
      backgroundColor: theme.fn.rgba(theme.colors.indigo[0], 0.4),
    },
  },
  iconWrapper: {
    borderRadius: theme.radius.md,
    padding: theme.spacing.xs,
    color: theme.colors.indigo_accent[2],
    backgroundColor: theme.fn.rgba(theme.colors.indigo[0], 0.6),
  },
}));
