import { createStyles, Group, Paper, Stack, Text } from '@mantine/core';
import React from 'react';

import { OrderType } from '@portals/api/partners';
import { useGenericContext } from '@portals/framework';
import { prettyTimeMoment } from '@portals/utils';

import { OrderPurchaseTooltip } from './OrderPurchaseTooltip';

function CreditCard() {
  const { classes, cx } = useStyles();

  return (
    <Text className={cx(classes.title, classes.ellipsis)} mb="xs">
      Credit Card
    </Text>
  );
}

function PurchaseOrder() {
  const { classes, cx } = useStyles();
  const order = useGenericContext<OrderType>();

  return (
    <>
      <Text className={cx(classes.title, classes.ellipsis)} mb="xs">
        Purchase Order
      </Text>

      <Text className={cx(classes.details, classes.ellipsis)}>
        Pricing quote: {prettyTimeMoment(order.purchase_order?.created_at)}
      </Text>

      {order.purchase_order?.purchase_order_file_uploaded_at ? (
        <Text className={cx(classes.details, classes.ellipsis)}>
          Purchase order:{' '}
          {prettyTimeMoment(
            order.purchase_order?.purchase_order_file_uploaded_at
          )}
        </Text>
      ) : null}

      {order.purchase_order?.approved_at ? (
        <Group align="center" spacing="sm">
          <Text className={cx(classes.details, classes.ellipsis)}>
            Approved: {prettyTimeMoment(order.purchase_order?.approved_at)} by{' '}
            {order.approved_by?.name} (
            <a href={`mailto:${order.approved_by?.email}`}>
              {order.approved_by?.email}
            </a>
            )
          </Text>
          {order.approved_by?.note ? <OrderPurchaseTooltip /> : null}
        </Group>
      ) : null}
    </>
  );
}

export function OrderPaymentMethodCard() {
  const order = useGenericContext<OrderType>();
  const { classes, cx } = useStyles();

  let details: JSX.Element;

  switch (order.payment_method) {
    case 'credit_card':
      details = <CreditCard />;
      break;

    case 'purchase_order':
      details = <PurchaseOrder />;
      break;

    default:
      details = (
        <Text className={cx(classes.title, classes.ellipsis)} mb="xs">
          Lab
        </Text>
      );
  }

  return (
    <Paper withBorder p="xl" radius="lg">
      <Stack>
        <Text size="md" color="gray.8">
          Summary
        </Text>

        <Stack spacing="xs">{details}</Stack>
      </Stack>
    </Paper>
  );
}

const useStyles = createStyles((theme) => ({
  ellipsis: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  title: {
    fontSize: theme.fontSizes.sm,
    color: theme.colors.blue_gray[9],
    fontWeight: 600,
  },
  details: {
    fontSize: theme.fontSizes.sm,
    color: theme.colors.blue_gray[4],
  },
  tooltip: {
    border: `1px solid ${theme.colors.gray[2]}`,
    padding: theme.spacing.md,
    backgroundColor: theme.white,
  },
}));
