import { Group, Stack, Text, ThemeIcon } from '@mantine/core';
import { map } from 'lodash/fp';
import React, { FC } from 'react';

import { TaxGroupType } from '@portals/api/partners';

import { TAX_GROUPS_ICONS } from '../../../../components/tax-groups/tax-groups-icons';

interface TaxGroupsListProps {
  groups: Array<TaxGroupType>;
}

export const TaxGroupsList: FC<TaxGroupsListProps> = ({ groups }) => (
  <Stack>
    {map((group) => {
      return (
        <Group
          key={group.name}
          spacing="sm"
          py="xl"
          sx={(theme) => ({
            borderBottom: `1px solid ${theme.colors.gray[3]}`,
          })}
        >
          <ThemeIcon
            variant="light"
            radius="md"
            color={TAX_GROUPS_ICONS[group.icon_name]?.color}
            size={40}
          >
            {TAX_GROUPS_ICONS[group.icon_name]?.component}
          </ThemeIcon>

          <Group spacing="xs">
            <Text
              size="sm"
              sx={(theme) => ({ color: theme.colors.blue_gray[8] })}
            >
              {group.name}
            </Text>
            <Text
              size="sm"
              sx={(theme) => ({ color: theme.colors.blue_gray[4] })}
            >
              ({group.products_count} Products)
            </Text>
          </Group>
        </Group>
      );
    }, groups)}
  </Stack>
);
