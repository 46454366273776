import {
  ActionIcon,
  Button,
  createStyles,
  Group,
  MantineTheme,
  Stack,
  Text,
} from '@mantine/core';
import React, { useMemo } from 'react';

import { ReactComponent as CloseCircle } from '@portals/icons/linear/close-circle.svg';
import { ReactComponent as TickCircle } from '@portals/icons/linear/tick-circle.svg';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import { getFileTypeFromUrl } from '@portals/utils';

import { FileState } from './types';
import { FileTypeIcon, LinearProgressBar } from '../../../index';

interface FileRowProps extends FileState {
  onTryAgain: () => void;
  onDeleteFile: (fileToDeleteId: string) => void;
}

export function FileRow({
  id,
  file,
  state,
  error,
  onTryAgain,
  onDeleteFile,
}: FileRowProps) {
  const { classes, theme } = useStyles(state);

  const fileType = getFileTypeFromUrl(file.name);

  const content = useMemo(() => {
    if (state === 'pending' || state === 'uploading') {
      return (
        <Stack sx={{ flex: '1 !important' }}>
          <Text size="sm" data-testid="uploading-text">
            Uploading <b>{file.name}</b>
          </Text>

          <LinearProgressBar />
        </Stack>
      );
    }

    if (state === 'error') {
      return (
        <Group align="center" sx={{ flex: 1 }} position="apart">
          <Group spacing="xs">
            <CloseCircle fill={theme.colors.red[4]} color={theme.white} />

            <Stack spacing="xs">
              <Text size="sm">
                Couldn't upload <b>{file.name}</b>
              </Text>

              {error?.message ? (
                <Text color="red">{error?.message}</Text>
              ) : null}
            </Stack>
          </Group>

          <Button
            variant="white"
            onClick={onTryAgain}
            sx={{ background: 'none' }}
          >
            Try again
          </Button>
        </Group>
      );
    }

    if (state === 'finished') {
      return (
        <Group spacing="xs" align="center" sx={{ flexGrow: 1 }}>
          <TickCircle fill={theme.colors.teal_accent[7]} color={theme.white} />

          <Text size="sm">
            <b>{file.name}</b> uploaded successfully
          </Text>

          <ActionIcon
            sx={{ marginLeft: 'auto' }}
            onClick={() => onDeleteFile(id)}
          >
            <Trash />
          </ActionIcon>
        </Group>
      );
    }

    return null;
  }, [
    error,
    file.name,
    id,
    onDeleteFile,
    onTryAgain,
    state,
    theme.colors.red,
    theme.colors.teal_accent,
    theme.white,
  ]);

  return (
    <Group className={classes.container}>
      <FileTypeIcon width={40} fileType={fileType} />

      {content}
    </Group>
  );
}

const getFileRowBgColor = (state: FileState['state'], theme: MantineTheme) => {
  switch (state) {
    case 'finished': {
      return theme.fn.rgba(theme.colors.teal[0], 0.4);
    }

    case 'error': {
      return theme.fn.rgba(theme.colors.red[0], 0.4);
    }

    case 'uploading':
    case 'pending':
    default: {
      return theme.white;
    }
  }
};

const useStyles = createStyles((theme, state: FileState['state']) => ({
  container: {
    width: '100%',
    height: 105,
    padding: `0 ${theme.spacing.xl}`,
    border: state === 'pending' ? `1px solid ${theme.colors.gray[2]}` : 'none',
    backgroundColor: getFileRowBgColor(state, theme),
    borderRadius: theme.radius.md,
  },
  title: {
    color: theme.colors.gray[9],
  },
}));
