import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';
import {
  PaginatedQueryFilterType,
  PaginationResponse,
  UsePaginatedTableApiQuery,
} from '@portals/types';

import { PRODUCTS_API_BASE_URL, productsQueryKeys } from './products.constants';
import { Product, PublishableProduct } from './products.types';
import { useApiQuery, useClearDataCache } from '../../hooks';
import { ServerError } from '../../types';
import {
  buildUrlFromFilters,
  fetchApiRequest,
  usePaginatedTableApiQuery,
  useRequestOptions,
} from '../../utils';
import { storeListingsQueryKeys } from '../store-listings';
import { storeSettingsQueryKeys } from '../store-settings/store-settings.constants';

export function useInvalidatePaginatedProducts() {
  const queryClient = useQueryClient();

  return {
    invalidate: () =>
      queryClient.invalidateQueries({ queryKey: productsQueryKeys.all }),
  };
}

export function usePaginatedProducts(
  tableState: UsePaginatedTableApiQuery<Product>['tableState'],
  columns: UsePaginatedTableApiQuery<Product>['columns'],
  baseUrl: string = PRODUCTS_API_BASE_URL
) {
  return usePaginatedTableApiQuery<Product>({
    baseUrl,
    columns,
    tableState,
    queryKey: [...productsQueryKeys.all, baseUrl, tableState],
  });
}

export function useNonePaginatedProducts(params?: {
  filters: Array<PaginatedQueryFilterType<Product>>;
}) {
  const { url, options } = useRequestOptions({ url: PRODUCTS_API_BASE_URL });

  const requestUrl = buildUrlFromFilters({
    url,
    filters: params?.filters,
    pagination: {
      page: 0,
      pageSize: 1000,
    },
  });

  return useQuery<PaginationResponse<Product>, ServerError, Product[]>({
    queryKey: [...productsQueryKeys.all, JSON.stringify(params?.filters)],
    queryFn: () => fetchApiRequest(requestUrl, options),
    select: (response) => {
      return response.data;
    },
    meta: {
      baseUrl: PRODUCTS_API_BASE_URL,
      method: 'GET',
    },
  });
}

export function usePublishableProductsByIds(productIds: string[]) {
  const { url, options } = useRequestOptions({
    url: PRODUCTS_API_BASE_URL,
  });

  return useQuery<PublishableProduct[]>({
    queryKey: productsQueryKeys.byIds(productIds),
    queryFn: () => {
      const promises = productIds.map((productId) => {
        return fetchApiRequest(`${url}/${productId}`, { ...options });
      });

      return Promise.all(promises);
    },
    meta: {
      baseUrl: PRODUCTS_API_BASE_URL,
      method: 'GET',
    },
  });
}

export function useProductCategories() {
  return useApiQuery<string[]>(
    `${PRODUCTS_API_BASE_URL}/categories`,
    productsQueryKeys.categories(),
    {
      staleTime: 0,
    }
  );
}

export interface SaveProductRequestPayload {
  name: Product['name'];
  product_type: Product['product_type'];
  category: Product['category'];
  subtitle: Product['subtitle'];
  description: Product['description'];
  image_url: Product['image_url'];
  tax_group_id: Product['tax_group_id'];
  license_data: Product['license_data'];
  device_model_id: string | null;
  post_purchase_parameters: Product['post_purchase_parameters'];
  pricing_model: Product['pricing_model'];
  sku: Product['sku'];
  usage_based_display_name: Product['usage_based_display_name'];
  usage_based_telemetry_key: Product['usage_based_telemetry_key'];
  usage_based_unit_name: Product['usage_based_unit_name'];
  supported_command_ids: Array<string>;
}
export function useCreateProduct() {
  const dispatch = useDispatch();
  const clearDataCache = useClearDataCache();
  const { invalidate } = useInvalidatePaginatedProducts();

  const { url, options } = useRequestOptions({
    url: PRODUCTS_API_BASE_URL,
    method: 'POST',
  });

  return useMutation<Product, ServerError, SaveProductRequestPayload>({
    mutationFn: (payload) => {
      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify(payload),
      });
    },
    onSuccess: async (newProduct) => {
      clearDataCache([
        storeSettingsQueryKeys.all,
        storeSettingsQueryKeys.taxGroups.all,
        storeListingsQueryKeys.all,
      ]);
      await invalidate();
      return newProduct;
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useCreateProduct',
      baseUrl: PRODUCTS_API_BASE_URL,
      method: 'POST',
    },
  });
}

interface UpdateProductRequestPayload {
  productId: string;
  productPayload: SaveProductRequestPayload;
}
export function useUpdateProduct() {
  const dispatch = useDispatch();
  const clearDataCache = useClearDataCache();
  const { invalidate } = useInvalidatePaginatedProducts();

  const { url, options } = useRequestOptions({
    url: PRODUCTS_API_BASE_URL,
    method: 'PATCH',
  });

  return useMutation<Product, ServerError, UpdateProductRequestPayload>({
    mutationFn: ({ productId, productPayload }) => {
      return fetchApiRequest(`${url}/${productId}`, {
        ...options,
        body: JSON.stringify(productPayload),
      });
    },
    onSuccess: async () => {
      clearDataCache([
        storeSettingsQueryKeys.all,
        storeSettingsQueryKeys.taxGroups.all,
        storeListingsQueryKeys.all,
      ]);
      await invalidate();
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useUpdateProduct',
      baseUrl: `${PRODUCTS_API_BASE_URL}/:id`,
      method: 'PATCH',
    },
  });
}

export function useArchiveProduct() {
  const dispatch = useDispatch();
  const { invalidate } = useInvalidatePaginatedProducts();
  const clearDataCache = useClearDataCache();

  const { url, options } = useRequestOptions({
    url: PRODUCTS_API_BASE_URL,
    method: 'POST',
  });

  return useMutation<void, ServerError, string>({
    mutationFn: (productId) => {
      return fetchApiRequest(`${url}/${productId}/archive`, {
        ...options,
      });
    },
    onSuccess: async () => {
      await invalidate();
      clearDataCache([
        storeSettingsQueryKeys.all,
        storeSettingsQueryKeys.taxGroups.all,
        storeListingsQueryKeys.all,
      ]);
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useArchiveProduct',
      baseUrl: `${PRODUCTS_API_BASE_URL}/:id/archive`,
      method: 'POST',
    },
  });
}

export function usePublishProduct() {
  const dispatch = useDispatch();
  const clearDataCache = useClearDataCache();
  const { invalidate } = useInvalidatePaginatedProducts();

  const { url, options } = useRequestOptions({
    url: PRODUCTS_API_BASE_URL,
    method: 'POST',
  });

  return useMutation<Product, ServerError, string>({
    mutationFn: (productId) => {
      return fetchApiRequest(`${url}/${productId}/publish`, {
        ...options,
      });
    },
    onSuccess: async () => {
      clearDataCache([
        storeSettingsQueryKeys.all,
        storeSettingsQueryKeys.taxGroups.all,
        storeListingsQueryKeys.all,
      ]);
      await invalidate();
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'usePublishProduct',
      baseUrl: `${PRODUCTS_API_BASE_URL}/:id/publish`,
      method: 'POST',
    },
  });
}

export function useBulkPublishProducts() {
  const dispatch = useDispatch();
  const publishProduct = usePublishProduct();
  const clearDataCache = useClearDataCache();
  const { invalidate } = useInvalidatePaginatedProducts();

  return useMutation<Product[], ServerError, string[]>({
    mutationFn: async (productIds) => {
      const promises = productIds.map((productId) =>
        publishProduct.mutateAsync(productId)
      );
      return Promise.all(promises);
    },
    onSuccess: async () => {
      clearDataCache([
        storeSettingsQueryKeys.all,
        storeSettingsQueryKeys.taxGroups.all,
        storeListingsQueryKeys.all,
      ]);
      await invalidate();

      dispatch(
        toastrSuccess('Publish products', 'Products published successfully')
      );
    },
    meta: {
      mutationName: 'useBulkPublishProducts',
      baseUrl: PRODUCTS_API_BASE_URL,
      method: 'POST',
    },
  });
}

export function useDeleteProduct() {
  const dispatch = useDispatch();
  const clearDataCache = useClearDataCache();
  const { invalidate } = useInvalidatePaginatedProducts();

  const { url, options } = useRequestOptions({
    url: PRODUCTS_API_BASE_URL,
    method: 'DELETE',
  });

  return useMutation<void, ServerError, string>({
    mutationFn: (productId) => {
      return fetchApiRequest(`${url}/${productId}`, { ...options });
    },
    onSuccess: async () => {
      clearDataCache([
        storeSettingsQueryKeys.all,
        storeSettingsQueryKeys.taxGroups.all,
        storeListingsQueryKeys.all,
        productsQueryKeys.all,
      ]);
      await invalidate();
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useDeleteProduct',
      baseUrl: `${PRODUCTS_API_BASE_URL}/:id`,
      method: 'DELETE',
    },
  });
}
