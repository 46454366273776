import {
  Badge,
  createStyles,
  Group,
  LoadingOverlay,
  Stack,
  Text,
} from '@mantine/core';
import { keys, noop } from 'lodash/fp';
import React from 'react';
import { Row } from 'react-table';

import {
  CONNECTED_PARTNERS_API_URL,
  ConnectedPartner,
  connectedPartnersQueryKeys,
  useConnectedPartners,
  useConnectedRelationshipPartnerTypes,
  useDisconnectPartners,
  useUpdateConnectedPartner,
} from '@portals/api/partners';
import { useConfirmationModal, usePermissionAccess } from '@portals/framework';
import { ReactComponent as Flash } from '@portals/icons/linear/flash.svg';
import { ReactComponent as TickCircle } from '@portals/icons/linear/tick-circle.svg';
import { AvatarCell, DateCell, PaginatedTable } from '@portals/table';
import { TableColumn, TableFilterTypeEnum } from '@portals/types';

import { InvitePartnerButton } from './InvitePartnerButton';
import { PartnerRowMenu } from './PartnerRowMenu';
import { PartnersItemsActions } from './PartnersItemsActions';
import { PartnerType } from './PartnerType';
import { RelationshipTypeEnum } from '../../modals/InvitePartner/types';

export function PartnersActiveConnectionsTable() {
  const { canEdit } = usePermissionAccess();

  const { classes } = useStyles();

  const updatePartnerConnection = useUpdateConnectedPartner();

  const disconnectPartners = useDisconnectPartners();
  const relationshipTypes = useConnectedRelationshipPartnerTypes();
  const asyncConfirmation = useConfirmationModal();

  const onUpdateType = (
    partnersIds: string[],
    type: string,
    certified?: boolean
  ) => {
    const partnersToUpdate = {
      relationship_type: type,
      ids: partnersIds,
      certified,
    };

    updatePartnerConnection.mutate(partnersToUpdate);
  };

  const columns: TableColumn<ConnectedPartner>[] = [
    {
      dataField: 'display_name',
      text: 'Name',
      minWidth: 400,
      formatter: (_, { logo, display_name, certified }) => (
        <Group noWrap position="apart">
          <AvatarCell src={logo} label={display_name} />

          {certified ? (
            <Badge
              bg="indigo.0"
              color="gray.9"
              size="lg"
              leftSection={<TickCircle width={16} height={16} />}
              fz="xs"
              fw={400}
              className={classes.badge}
              data-testid="certified-service-provider-badge"
            >
              Certified service provider
            </Badge>
          ) : null}
        </Group>
      ),
      sort: true,
      filter: {
        type: TableFilterTypeEnum.Text,
      },
    },
    {
      dataField: 'relationship_type',
      text: 'Type',
      sort: true,
      formatter: (_, { id, relationship_type }) => (
        <PartnerType
          partnerId={id}
          partnerRelationshipType={relationship_type}
          onUpdateType={onUpdateType}
          relationshipTypes={relationshipTypes?.data}
        />
      ),
      filter: {
        type: TableFilterTypeEnum.Select,
        options: {
          [RelationshipTypeEnum.Manufacturer]:
            RelationshipTypeEnum.Manufacturer,
          [RelationshipTypeEnum.Integrator]: RelationshipTypeEnum.Integrator,
          [RelationshipTypeEnum.Distributor]: RelationshipTypeEnum.Distributor,
          [RelationshipTypeEnum.Reseller]: RelationshipTypeEnum.Reseller,
        },
      },
    },
    {
      dataField: 'created_at',
      text: 'Date',
      minWidth: 300,
      sort: true,
      formatter: (cell) => <DateCell date={cell.value} />,
      filter: {
        type: TableFilterTypeEnum.Date,
      },
    },
  ];

  const onDisconnectPartners = async (
    rowsById: Record<string, Row<ConnectedPartner>>,
    selectedRowIds: Record<string, any> | undefined
  ) => {
    const isConfirmed = await asyncConfirmation({
      title: (
        <Group spacing="md" data-testid="disconnect-partner-modal-group">
          <Flash width={24} height={24} />
          Disconnect Partner
        </Group>
      ),
      description: (
        <Stack spacing={5} mb="lg" color="blue_gray.9">
          <Text size="sm">
            Are you sure that you want to disconnect partner?
          </Text>
          <Text size="sm">
            You will no longer be able to offer shared services
          </Text>
        </Stack>
      ),
    });

    if (isConfirmed) {
      try {
        const partnersIdToDelete = keys(selectedRowIds).map(
          (rowIndex) => rowsById[rowIndex]?.original?.id
        );

        await disconnectPartners.mutateAsync({ ids: partnersIdToDelete });
      } catch (err) {
        console.error(err);
      }
    }
  };

  return (
    <>
      <LoadingOverlay
        visible={
          updatePartnerConnection.isLoading || disconnectPartners.isLoading
        }
      />

      {relationshipTypes.isFetched ? (
        <PaginatedTable<ConnectedPartner>
          readOnly={!canEdit('customers')}
          keyField="id"
          name="partners-active-connections"
          dataHookQueryKey={connectedPartnersQueryKeys.active()}
          dataHookUrl={`${CONNECTED_PARTNERS_API_URL}/?q[status_eq]=active`}
          dataHook={useConnectedPartners}
          columns={columns}
          onSelected={noop}
          defaultSortBy={[{ id: 'created_at', desc: true }]}
          additionalActions={() => <InvitePartnerButton />}
          selectedItemsActions={() => (
            <PartnersItemsActions
              onDisconnectPartners={onDisconnectPartners}
              onUpdateType={onUpdateType}
              relationshipTypes={relationshipTypes.data}
            />
          )}
          rowMenu={({ wrapperProps, row }) => (
            <PartnerRowMenu
              rowIndex={row.index}
              connectedPartner={row.original}
              wrapperProps={wrapperProps}
              onUpdateType={onUpdateType}
              onDisconnectPartners={onDisconnectPartners}
              relationshipTypes={relationshipTypes.data}
              certified={row.original.certified}
            />
          )}
        />
      ) : null}
    </>
  );
}

const useStyles = createStyles(() => ({
  badge: {
    overflow: 'visible',
  },
}));
