import { Anchor } from '@mantine/core';
import { get, isUndefined } from 'lodash/fp';
import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Spinner,
} from 'reactstrap';

import { useEditDeviceModel } from '@portals/api/partners';
import { AutoFormik } from '@portals/autoformik';
import { usePermissionAccess } from '@portals/framework';
import { useIntegrations } from '@portals/redux';
import { FieldTypeEnum } from '@portals/types';

import {
  fetchIntegrations,
  getIntegrationParam,
} from '../../../redux/actions/integrations';

const MAP_OPTIONS = {
  zoho: 'products',
};

interface ModelIntegrationsProps {
  model: any;
}

export function ModelIntegrations({ model }: ModelIntegrationsProps) {
  const dispatch = useDispatch();
  const { canEdit } = usePermissionAccess();
  const editDeviceModel = useEditDeviceModel();

  const integrations = useIntegrations();

  useEffect(() => {
    dispatch(fetchIntegrations());
  }, [dispatch]);

  const fields = useMemo(
    () =>
      Object.keys(integrations).map((integrationKey: string) => ({
        name: integrationKey,
        title: integrationKey,
        clearable: true,
        type: FieldTypeEnum.Select,
        options: get(
          [integrationKey, 'extra', MAP_OPTIONS[integrationKey]],
          integrations
        ),
      })),
    [integrations]
  );

  const handleSubmit = (values) =>
    editDeviceModel.mutate({
      id: model.id,
      user_settings: { ...model.user_settings, integrations: values },
    });

  const onIntegrationRefetch = () =>
    Object.keys(integrations).forEach((integration) =>
      dispatch(getIntegrationParam(integration, MAP_OPTIONS[integration]))
    );

  const refreshOptions = () => (
    <Button onClick={onIntegrationRefetch}>Reload options</Button>
  );

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h5">Integrations Data</CardTitle>
        <h6 className="text-muted">
          Mapping between model and integration data
        </h6>
      </CardHeader>
      <CardBody>
        {isUndefined(integrations) && <Spinner />}

        {!isUndefined(integrations) &&
          Object.keys(integrations).length === 0 && (
            <span>
              You must enable an{' '}
              <Anchor to="/settings/integrations" component={Link}>
                integration
              </Anchor>
            </span>
          )}

        {!isUndefined(integrations) && Object.keys(integrations).length > 0 && (
          <AutoFormik
            readOnly={!canEdit('models')}
            fields={fields}
            initialValues={model.user_settings.integrations}
            inProgress={editDeviceModel.isLoading}
            handleSubmit={handleSubmit}
            wrapped={refreshOptions}
          />
        )}
      </CardBody>
    </Card>
  );
}
