import {
  Anchor,
  Button,
  createStyles,
  Group,
  Stack,
  Text,
} from '@mantine/core';
import React from 'react';

import {
  PartnerInvoiceType,
  useInvoiceRetryCharge,
} from '@portals/api/partners';
import { MarkAsUncollectibleModalProps } from '@portals/framework/modals';
import { useOpenModal } from '@portals/redux';

interface InvoicePaymentFailedProps {
  invoice: PartnerInvoiceType;
}

export function InvoicePaymentFailed({ invoice }: InvoicePaymentFailedProps) {
  const { classes } = useStyles();

  const invoiceRetryCharge = useInvoiceRetryCharge();
  const openModal = useOpenModal();

  const onRetryPayment = () => {
    invoiceRetryCharge.mutate(
      { invoiceId: invoice.id },
      {
        onSuccess: () => openModal('RetryChargeSuccessModal'),
        onError: () => {
          openModal('RetryChargeFailureModal', {
            failureReason: invoice.failure_reason,
          });
        },
      }
    );
  };

  return (
    <Group align="center" spacing="xl" className={classes.container} w="100%">
      <Text weight={600} color="gray.9" align="center">
        {invoice.failure_reason}
      </Text>

      <Stack w="100%" spacing="sm">
        <Button
          onClick={onRetryPayment}
          variant="filled"
          w="100%"
          size="md"
          loading={invoiceRetryCharge.isLoading}
        >
          Retry Payment
        </Button>

        <Anchor
          onClick={() =>
            openModal<MarkAsUncollectibleModalProps['data']>(
              'MarkAsUncollectibleModal',
              { invoiceId: invoice.id }
            )
          }
          color="blue_gray.8"
          align="center"
          className={classes.link}
        >
          Mark as Uncollectible
        </Anchor>
      </Stack>
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    padding: theme.spacing.xl,
    backgroundColor: theme.colors.red[0],
    borderRadius: theme.radius.md,
  },
  link: {
    textDecoration: 'underline',
  },
}));
