import { createStyles } from '@mantine/core';
import React, { ReactNode } from 'react';

export function SidebarTabPanel({ children }: { children: ReactNode }) {
  const { classes } = useStyles();

  return <div className={classes.container}>{children}</div>;
}

function Header({ children }: { children: ReactNode }) {
  const { classes } = useStyles();

  return <header className={classes.header}>{children}</header>;
}

function Body({ children }: { children: ReactNode }) {
  const { classes } = useStyles();

  return <div className={classes.body}>{children}</div>;
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    height: '100%',
  },
  header: {
    paddingBlock: theme.spacing.lg,
    paddingInline: theme.spacing.xl,
    borderBottom: `1px solid ${theme.colors.blue_gray[0]}`,
    fontSize: theme.fontSizes.xl,
    color: theme.colors.blue_gray[8],
  },
  body: {
    overflow: 'auto',
    padding: theme.spacing.md,
  },
}));

SidebarTabPanel.Header = Header;
SidebarTabPanel.Body = Body;
