import { ReactComponent as Crown } from '@portals/icons/bulk/crown.svg';
import { ReactComponent as Flag } from '@portals/icons/bulk/flag.svg';
import { ReactComponent as Flash } from '@portals/icons/bulk/flash.svg';
import { ReactComponent as MagicStar } from '@portals/icons/bulk/magic-star.svg';
import { PartnerPricingPlanType } from '@portals/types';

export function getPricingPlanIcon(
  pricingPlanIconName: PartnerPricingPlanType['icon_name'] | undefined
) {
  if (!pricingPlanIconName) return null;

  switch (pricingPlanIconName) {
    case 'flag':
      return Flag;
    case 'star':
      return MagicStar;
    case 'crown':
      return Crown;
    case 'flash':
      return Flash;
    default:
      return Flag;
  }
}
