import { Box } from '@mantine/core';
import { get, set } from 'lodash/fp';
import React from 'react';

import { DeviceModelType, useEditDeviceModel } from '@portals/api/partners';
import { ImageSelectorField } from '@portals/autoformik';

interface ModelImageProps {
  model: DeviceModelType;
}

export function ModelImage({ model }: ModelImageProps) {
  const editDeviceModel = useEditDeviceModel();

  return (
    <Box w={250} h={250}>
      <ImageSelectorField
        horizontal={false}
        setFieldValue={(_, value) =>
          editDeviceModel.mutate(set('user_settings.media.img', value, model))
        }
        field={{
          title: 'Upload Model Image',
          name: 'logoUrl',
          height: 250,
          width: 250,
          previewConfig: {
            height: 230,
            width: 230,
          },
          cropConfig: {
            aspectRatio: 1,
            width: 560,
            height: 560,
          },
        }}
        value={get('user_settings.media.img', model)}
      />
    </Box>
  );
}
