import {
  Button,
  ButtonProps,
  Container,
  LoadingOverlay,
  Paper,
  Stack,
  Text,
} from '@mantine/core';
import React, { useMemo } from 'react';

import {
  useActiveConnectionsResponse,
  useCanceledConnectionsResponse,
  useConfig,
  useIncomingInvitations,
  useInvitedAvailablePartners,
  useUpdateConfig,
  useUpdateConnectedPartner,
} from '@portals/api/partners';
import {
  DashboardContentLayout,
  RoutedTabs,
  RoutedTabsProps,
} from '@portals/framework';
import { useOpenModal } from '@portals/redux';
import { NoDataState } from '@portals/table';

import { IncomingInvitations } from './incoming-invitations/IncomingInvitations';
import { InvitePartnerButton } from './InvitePartnerButton';
import { PartnersActiveConnectionsTable } from './PartnersActiveConnectionsTable';
import { PartnersCancelledConnectionsTable } from './PartnersCancelledConnectionsTable';
import { PartnersInvitationsTable } from './PartnersInvitationsTable';
import noConnectedPartnerSrc from '../../assets/img/no-connected-partners.png';
import noDataAssetSrc from '../../assets/img/no-partners-data.svg';
import { InvitePartnerModalProps } from '../../modals/InvitePartner';

const ROUTES: RoutedTabsProps['routes'] = [
  {
    path: 'connected_partners',
    tabLabel: 'Connected',
    element: <PartnersActiveConnectionsTable />,
  },
  {
    path: 'invitations',
    tabLabel: 'Invited',
    element: <PartnersInvitationsTable />,
  },
  {
    path: 'disconnected',
    tabLabel: 'Disconnected',
    element: <PartnersCancelledConnectionsTable />,
  },
];

function NoConnectedPartners() {
  return (
    <Paper h="100%" pos="relative">
      <NoDataState
        title="You don’t have any partners yet"
        subtitle={
          <Text size="sm">
            Partner with other manufacturers, distributors, integrators and
            others
            <br />
            to enhance your business with shared offerings.
          </Text>
        }
        actions={<InvitePartnerButton />}
        assetSrc={noDataAssetSrc}
      />
    </Paper>
  );
}

function NoEnabledPartnerToPartnerConnection() {
  const updateConfig = useUpdateConfig();
  const openModal = useOpenModal();

  const onUpdateConfig = async (isEnabled: boolean) => {
    try {
      await updateConfig.mutateAsync({
        updatedConfig: { visible_to_other_partners: isEnabled },
      });

      openModal<InvitePartnerModalProps['data']>('InvitePartner');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <LoadingOverlay visible={updateConfig.isLoading} />
      <Paper h="100%" pos="relative">
        <NoDataState
          title="You Must Enable Xyte Connect to invite partners"
          assetSrc={noConnectedPartnerSrc}
          subtitle={
            <Container size="xs" px="xs">
              <Text size="sm">
                Partner with other manufacturers, distributors, integrators and
                others to enhance your business with shared offerings.
                <br />
                <br />
                <Text weight={700} span>
                  Please note:
                </Text>{' '}
                Once you enable this feature, you will become visible to other
                partners and be able to invite and get invited by other partners
                in Xyte ecosystem.
              </Text>
              <Text color="gray.6" size="sm">
                <br />
                You can change this any time through the settings.
              </Text>
            </Container>
          }
          actions={
            <Button
              onClick={() => {
                onUpdateConfig(true);
              }}
              size="md"
              styles={buttonStyles}
              data-testid="enable-xyte-connect-button"
            >
              Enable Xyte Connect and Invite Your First Partner
            </Button>
          }
        />
      </Paper>
    </>
  );
}

export function Partners() {
  const updatePartnerConnection = useUpdateConnectedPartner();
  const incomingInvitations = useIncomingInvitations();

  const activeConnectionsResponse = useActiveConnectionsResponse();
  const canceledConnectionsResponse = useCanceledConnectionsResponse();

  const config = useConfig();

  const invitedPartners = useInvitedAvailablePartners();

  const content = useMemo(() => {
    if (config.data?.visible_to_other_partners) {
      if (
        activeConnectionsResponse.data?.page_info?.total_count > 0 ||
        invitedPartners.data?.page_info?.total_count > 0 ||
        canceledConnectionsResponse?.data?.page_info?.total_count > 0
      ) {
        return (
          <Stack spacing={32}>
            <IncomingInvitations invitations={incomingInvitations.data} />

            <RoutedTabs routes={ROUTES} basePath="/partners" />
          </Stack>
        );
      } else {
        return (
          <Stack spacing={32}>
            <IncomingInvitations invitations={incomingInvitations.data} />
            <NoConnectedPartners />
          </Stack>
        );
      }
    } else {
      return (
        <Stack spacing={32}>
          <IncomingInvitations invitations={incomingInvitations.data} />
          <NoEnabledPartnerToPartnerConnection />
        </Stack>
      );
    }
  }, [
    canceledConnectionsResponse.data?.page_info?.total_count,
    config.data?.visible_to_other_partners,
    incomingInvitations.data,
    activeConnectionsResponse.data?.page_info?.total_count,
    invitedPartners.data?.page_info?.total_count,
  ]);

  return (
    <DashboardContentLayout
      pageTitle={`Partners (${
        activeConnectionsResponse.data?.data?.length ?? 0
      })`}
      sx={(theme) => ({ backgroundColor: theme.colors.gray[0] })}
    >
      <LoadingOverlay
        visible={
          updatePartnerConnection.isLoading || incomingInvitations.isFetching
        }
      />

      {content}
    </DashboardContentLayout>
  );
}

const buttonStyles: ButtonProps['styles'] = (theme) => ({
  inner: { color: theme.white, textDecoration: 'none' },
});
