import {
  Badge,
  createStyles,
  Group,
  Stack,
  Text,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import React from 'react';

import { OrderType, PartnerOrderItemType } from '@portals/api/partners';
import { TextWithTooltip } from '@portals/core';
import { ProductImage, useGenericContext } from '@portals/framework';
import { ReactComponent as InfoCircle } from '@portals/icons/linear/info-circle.svg';
import { ProductPricingModel } from '@portals/types';
import { formatCurrency, getProductTypeDisplayName } from '@portals/utils';

interface OrderProductSummaryRowProps {
  orderItem: PartnerOrderItemType;
  withSku: boolean;
  withBasePrice: boolean;
}

export function OrderProductSummaryRow({
  orderItem,
  withSku,
  withBasePrice,
}: OrderProductSummaryRowProps) {
  const { classes } = useStyles();

  const theme = useMantineTheme();
  const order = useGenericContext<OrderType>();

  if (!order) return null;

  const getInterval = (
    paymentInterval: PartnerOrderItemType['payment_interval']
  ) => {
    if (paymentInterval === 'monthly') return ' / month';
    else if (paymentInterval === 'yearly') return ' / year';

    return '';
  };

  return (
    <div className={classes.container}>
      <Group noWrap spacing="xl" align="flex-start">
        <ProductImage
          src={orderItem.product.image_url}
          radius="lg"
          height={64}
          width="auto"
        />

        <Stack align="flex-start" className={classes.productNameWrapper}>
          <TextWithTooltip className={classes.cellHeader} w="100%" truncate>
            {orderItem.product.name}
          </TextWithTooltip>

          <Badge
            size="lg"
            color="gray.1"
            radius="md"
            variant="filled"
            c="gray.6"
            fw={400}
            fz="xs"
          >
            {orderItem.quantity}x
          </Badge>
        </Stack>
      </Group>

      <Stack className={classes.column}>
        <Text className={classes.cellHeader}>Type</Text>

        <Text className={classes.cellValue} transform="capitalize">
          {getProductTypeDisplayName(orderItem.product.product_type)}
        </Text>
      </Stack>

      <Stack className={classes.column}>
        <Text className={classes.cellHeader}>Price</Text>

        {orderItem.product.pricing_model === ProductPricingModel.UsageBased ? (
          <Text className={classes.cellValue}>
            {formatCurrency(
              orderItem.usage_based?.usage_price_in_scu,
              order.currency
            )}{' '}
            / {orderItem.product.usage_based_unit_name}
          </Text>
        ) : (
          <Text className={classes.cellValue}>
            {formatCurrency(orderItem.total_price_in_scu, order.currency)}
            {getInterval(orderItem.payment_interval)}
          </Text>
        )}
      </Stack>

      {withBasePrice && (
        <Stack className={classes.column}>
          {orderItem.usage_based?.base_price_in_scu ? (
            <Text className={classes.cellHeader}>Base Fee</Text>
          ) : null}

          {orderItem.usage_based?.base_price_in_scu ? (
            <Text className={classes.cellValue}>
              {formatCurrency(
                orderItem.usage_based?.base_price_in_scu,
                order.currency
              )}{' '}
              / month
            </Text>
          ) : null}
        </Stack>
      )}

      {withSku && (
        <Stack className={classes.column}>
          <Text className={classes.cellHeader}>
            {orderItem.product.sku ? 'SKU' : null}
          </Text>

          <Text className={classes.cellValue} data-testid="sku-value">
            {orderItem.product.sku}
          </Text>
        </Stack>
      )}

      <Stack className={classes.column}>
        <Group align="center" spacing="xs" noWrap>
          <Text className={classes.cellHeader}>Charged Amount</Text>

          <Tooltip
            withinPortal
            width={400}
            multiline
            label={`"Charged Amount" refers to the actual cost accrued for using this product during the specified time period. This cost is calculated based on the applicable pricing model, which can involve proportional calculations corresponding to the usage duration or direct correlation to the extent of actual usage.`}
          >
            <div>
              <InfoCircle width={16} height={16} color={theme.colors.gray[5]} />
            </div>
          </Tooltip>
        </Group>

        <Text className={classes.cellValue} transform="capitalize">
          {formatCurrency(
            orderItem.total_charged_amount_in_scu,
            order.currency
          )}
        </Text>
      </Stack>
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'minmax(150px, 2fr)',
    gridAutoColumns: '1fr',
    gap: theme.spacing.xl,
    alignItems: 'start',
    paddingBlock: theme.spacing.md,

    '&:not(:first-of-type)': {
      borderTop: `1px solid ${theme.colors.gray[2]}`,
    },

    [theme.fn.smallerThan('md')]: {
      gridAutoFlow: 'row',
      gridTemplateColumns: '1fr',
    },
  },
  productNameWrapper: {
    height: '100%',
    overflow: 'hidden',
    gap: theme.spacing.xs,
    justifyContent: 'center',
  },
  column: {
    justifyContent: 'center',
    gap: theme.spacing.xs,

    [theme.fn.smallerThan('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  cellHeader: {
    fontSize: theme.fontSizes.sm,
    fontWeight: 600,
    color: theme.colors.blue_gray[9],
  },
  cellValue: {
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[5],
  },
}));
