import { MenuProps, Tooltip } from '@mantine/core';
import { UseMutateFunction } from '@tanstack/react-query';
import React from 'react';

import { ServerError } from '@portals/api';
import {
  DeviceModelType,
  UseMoveSupportedCommandParams,
  useUpdateSupportedCommand,
} from '@portals/api/partners';
import { useConfirmationModal } from '@portals/framework';
import { ReactComponent as ArrowDown1 } from '@portals/icons/linear/arrow-down-1.svg';
import { ReactComponent as ArrowUp } from '@portals/icons/linear/arrow-up.svg';
import { ReactComponent as Edit } from '@portals/icons/linear/edit.svg';
import { ReactComponent as EyeSlash } from '@portals/icons/linear/eye-slash.svg';
import { ReactComponent as Eye } from '@portals/icons/linear/eye.svg';
import { ReactComponent as ToggleOffCircle } from '@portals/icons/linear/toggle-off-circle.svg';
import { ReactComponent as ToggleOnCircle } from '@portals/icons/linear/toggle-on-circle.svg';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import { useOpenModal } from '@portals/redux';
import { RowMenuItem, RowMenuItems } from '@portals/table';
import {
  RowMenuWrapperType,
  RowType,
  SupportedCommandType,
  UuidType,
} from '@portals/types';

interface SupportedCommandsTableRowMenuProps {
  row: RowType<SupportedCommandType>;
  wrapperProps: RowMenuWrapperType;
  isCustomCommandsEnabled: boolean;
  onMoveCommand: (params: UseMoveSupportedCommandParams) => void;
  deviceModel: DeviceModelType;
  numOfCommands: number;
  onDelete: UseMutateFunction<void, ServerError, UuidType>;
}

export function SupportedCommandsTableRowMenu({
  wrapperProps,
  row,
  isCustomCommandsEnabled,
  onMoveCommand,
  numOfCommands,
  deviceModel,
  onDelete,
}: SupportedCommandsTableRowMenuProps) {
  const command = row.original;
  const { menuRef } = wrapperProps;

  const updateSupportedCommand = useUpdateSupportedCommand(deviceModel.id);

  const asyncConfirmationCheck = useConfirmationModal();
  const openModal = useOpenModal();

  const isVisible = command.is_visible_in_panel;
  const isActive = command.active;
  const isSingleCommand = numOfCommands === 1;

  const toggleCommandState = () => {
    updateSupportedCommand.mutate({
      ...command,
      active: !command.active,
    });
  };

  const toggleCommandVisibility = () => {
    updateSupportedCommand.mutate({
      ...command,
      is_visible_in_panel: !command.is_visible_in_panel,
    });
  };

  const items: RowMenuItem[] = [];

  if (isCustomCommandsEnabled) {
    items.push({
      id: 'edit',
      Icon: Edit,
      label: 'Edit',
      onClick: () => {
        menuRef?.onClose();

        openModal('EditSupportedCommand', {
          modelId: deviceModel.id,
          commandId: row.original.id,
          commandData: row.original,
        });
      },
    });
  }

  if (!isSingleCommand && row.index > 0) {
    items.push({
      id: 'move_up',
      label: 'Move Up',
      Icon: ArrowUp,
      onClick: () => {
        menuRef?.onClose();
        onMoveCommand({ commandId: row.original.id, isUp: true });
      },
    });
  }

  if (!isSingleCommand && row.index !== numOfCommands - 1) {
    items.push({
      id: 'move_down',
      label: 'Move Down',
      Icon: ArrowDown1,
      onClick: () => {
        menuRef?.onClose();
        onMoveCommand({ commandId: row.original.id, isUp: false });
      },
    });
  }

  items.push({
    id: 'visible_command',
    Icon: isVisible ? EyeSlash : Eye,
    label: isVisible ? 'Hide from commands panel' : 'Show in commands panel',
    onClick: () => {
      menuRef?.onClose();
      toggleCommandVisibility();
    },
  });

  items.push({
    id: 'status',
    Wrapper: ({ children }) =>
      command.premium ? (
        <Tooltip
          multiline
          width={230}
          withArrow
          label="This command is linked to a license. Please disconnect the
                command from all licenses before deactivating it"
        >
          {children}
        </Tooltip>
      ) : (
        children
      ),
    Icon: isActive ? ToggleOffCircle : ToggleOnCircle,
    label: isActive ? 'Deactivate' : 'Activate',
    ...(isActive && { color: 'red' }),
    onClick: () => {
      if (command.premium) {
        return;
      }

      menuRef?.onClose();
      toggleCommandState();
    },
  });

  items.push({
    id: 'delete',
    Icon: Trash,
    color: 'red',
    label: 'Delete',
    withTopDivider: true,
    onClick: async () => {
      menuRef?.onClose();

      const isConfirmed = await asyncConfirmationCheck({
        description: 'Are you sure?',
      });

      if (isConfirmed) {
        onDelete(command.id);
      }
    },
  });

  return (
    <RowMenuItems
      items={items}
      wrapperProps={wrapperProps}
      menuProps={{
        styles: menuStyles(command.premium),
      }}
    />
  );
}

const menuStyles =
  (isPremium: boolean): MenuProps['styles'] =>
  (theme) => ({
    item: {
      '&.status': {
        opacity: isPremium ? 0.5 : 1,
        cursor: isPremium ? 'not-allowed' : 'cursor',

        '&:hover': {
          backgroundColor: isPremium
            ? 'transparent'
            : theme.colors[theme.primaryColor][0],
        },
      },
    },
  });
