import { Text, TextProps, Tooltip, TooltipProps } from '@mantine/core';
import React, { FC } from 'react';

interface TextWithTooltipProps extends TextProps {
  tooltipProps?: Partial<TooltipProps>;
}

export const TextWithTooltip: FC<TextWithTooltipProps> = ({
  children,
  tooltipProps,
  ...textProps
}) => {
  return (
    <Tooltip label={children} {...tooltipProps}>
      <Text truncate {...textProps}>
        {children}
      </Text>
    </Tooltip>
  );
};
