import { MantineTheme } from '@mantine/core';
import { extraErrorDataIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { getOr, set } from 'lodash/fp';
import { RefObject, useEffect, useState } from 'react';

export const PRIORITY_NAMES = {
  1: 'Critical',
  2: 'High',
  3: 'Moderate',
  4: 'Low',
  5: 'Planning',
} as const;

type OutsideClickEvent = MouseEvent | TouchEvent;

// Triggers a handler when user clicks / touches outside the element (ref)
export const useOnClickOutside = (
  ref: RefObject<HTMLElement>,
  handler: (event: OutsideClickEvent) => any,
  isActive: boolean
) => {
  useEffect(() => {
    if (isActive) {
      const listener = (event: OutsideClickEvent) => {
        if (!ref.current || ref.current.contains(event.target as HTMLElement)) {
          return;
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    }
  }, [ref, handler, isActive]);
};

export const useDebouncedValue = <TValue>(value: TValue, delay: number) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
};

export const suppressPropagation =
  (cb: any = null) =>
  (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (cb) cb(e);
  };

export function initSentry() {
  if (
    process.env.NX_ENV !== 'production' ||
    !process.env.NX_SENTRY ||
    Sentry.isInitialized()
  ) {
    return;
  }

  Sentry.init({
    dsn: process.env.NX_SENTRY,
    autoSessionTracking: true,
    normalizeDepth: 10,
    integrations: [
      Sentry.browserTracingIntegration(),
      extraErrorDataIntegration({ depth: 10 }),
      Sentry.replayIntegration(),
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    maxBreadcrumbs: 100,
    // Filter out large data sets
    beforeBreadcrumb: (breadcrumb, _) => {
      const firstArg = getOr('', ['data', 'arguments', 0], breadcrumb);

      if (firstArg.startsWith('ACTION:')) {
        return set('data.arguments.1.payload.data', '*removed*', breadcrumb);
      }
      return breadcrumb;
    },
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.01,
    environment: process.env.NX_ENV,
  });
}

export function isColorLight(hexColor: string) {
  // Convert hex color to RGB values
  const r = parseInt(hexColor.substring(1, 3), 16) / 255;
  const g = parseInt(hexColor.substring(3, 5), 16) / 255;
  const b = parseInt(hexColor.substring(5, 7), 16) / 255;

  // Calculate relative luminance
  const relativeLuminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  // Compare to threshold and return result
  return relativeLuminance > 0.5;
}

export function createColorShades(baseColorHex: string, amount: number) {
  return (
    '#' +
    baseColorHex
      .replace(/^#/, '')
      .replace(/../g, (color: string) =>
        (
          '0' +
          Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
        ).slice(-2)
      )
  );
}

export function generateRelatedColors(
  baseColorHex: string
): MantineTheme['colors']['string'] {
  let relatedColors = Array.from({ length: 5 }, (_, i) => {
    const amount = -i * 5;
    return createColorShades(baseColorHex, amount);
  })
    .filter((_, index) => index !== 0)
    .concat(
      Array.from({ length: 6 }, (_, i) => {
        const amount = i * 50;
        return createColorShades(baseColorHex, amount);
      })
    );

  relatedColors = isColorLight(baseColorHex)
    ? relatedColors.reverse()
    : relatedColors;

  return relatedColors as MantineTheme['colors']['string'];
}
