import { Group, Switch, Text } from '@mantine/core';
import React from 'react';

import { useRequestPlanUpgrade } from '@portals/api/partners';
import { UpgradeTierBadgeWithText } from '@portals/framework';

import { useDesignField } from './hooks';
import { usePricingPlanFeature } from '../../../../hooks/partner-config';

export function PremiumSwitch() {
  const requestPlanUpgrade = useRequestPlanUpgrade();

  const { value, onChange } = useDesignField(
    'design.sign_in_page.powered_by_xyte_label'
  );

  const isToggled = value !== false;
  const isDisabled =
    usePricingPlanFeature('powered_by_xyte_label_toggle') === 0;

  return (
    <Group position="apart" mb="xl">
      <Switch
        id="powered_by_xyte"
        name="powered_by_xyte"
        label="Powered by Xyte"
        size="xs"
        disabled={isDisabled}
        checked={isDisabled ? true : isToggled}
        onChange={() => onChange(!isToggled)}
      />

      {isDisabled ? (
        <UpgradeTierBadgeWithText
          size="md"
          badgeProps={{
            py: 'sm',
          }}
          onUpgrade={() =>
            requestPlanUpgrade.mutate(
              'Partners > Customer Portal Page > Powered by Xyte switch button > Premium Tag button > Upgrade Plan link'
            )
          }
          message={
            <Text size="xs">
              In order to remove the ‘Powered by Xyte’ label, upgrading to a
              higher plan is required.
            </Text>
          }
        />
      ) : null}
    </Group>
  );
}
