import { createStyles, LoadingOverlay, Stack, Text } from '@mantine/core';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { useState } from 'react';

import {
  useSubscription,
  useSubscriptionInvoiceItems,
} from '@portals/api/partners';
import { DetailsPanel } from '@portals/core';
import {
  PaymentsHistoryPanel,
  ProductImage,
  SubscriptionStatusBadge,
  usePermissionAccess,
} from '@portals/framework';
import { useOpenModal } from '@portals/redux';
import { ProductPricingModel, TenantType } from '@portals/types';

import { OverviewTab } from './OverviewTab';
import { SubscriptionCancellationReasonPanel } from './SubscriptionCancellationReasonPanel';
import { UsageBasedSubscriptionDetailsPanelContent } from './UsageBasedSubscriptionDetailsPanelContent';
import { SubscriptionCancellationFlowModalProps } from '../../../../modals';

interface SubscriptionDetailsPanelProps {
  subscriptionId: string;
  onClose: () => void;
}

export function SubscriptionDetailsPanel({
  subscriptionId,
  onClose,
}: SubscriptionDetailsPanelProps) {
  const { classes } = useStyles();

  const openModal = useOpenModal();
  const { canEdit } = usePermissionAccess();

  const [isPaymentHistoryPanelOpen, setIsPaymentHistoryPanelOpen] =
    useState(false);
  const [isCancellationReasonPanelOpen, setIsCancellationReasonPanelOpen] =
    useState(false);

  const subscription = useSubscription(subscriptionId);
  const subscriptionInvoiceItems = useSubscriptionInvoiceItems(subscriptionId, {
    enabled: isPaymentHistoryPanelOpen,
  });

  const isUsageBased =
    subscription.data?.product?.pricing_model ===
    ProductPricingModel.UsageBased;

  if (subscription.isLoading || !subscription.data) {
    return <LoadingOverlay visible />;
  }

  const showCancelButton =
    canEdit('finance') && subscription.data.status === 'active';

  const showCancellationReasonPanelButton =
    subscription.data.require_cancellation_reason &&
    (subscription.data.status === 'pending_cancellation' ||
      subscription.data.status === 'canceled');

  return (
    <>
      <PaymentsHistoryPanel
        isOpen={isPaymentHistoryPanelOpen}
        invoiceItems={subscriptionInvoiceItems.data || []}
        isFetching={subscriptionInvoiceItems.isFetching}
        currencyCode={subscription.data.currency}
        usageBasedDisplayName={
          subscription.data.product.usage_based_display_name || ''
        }
        usageBasedUnitName={
          subscription.data.product.usage_based_unit_name || ''
        }
        usageBasedPricePerUnit={
          subscription.data.usage_based?.usage_based_price_in_scu
        }
        onClose={() => setIsPaymentHistoryPanelOpen(false)}
        tenantType={TenantType.Partner}
      />

      <SubscriptionCancellationReasonPanel
        isOpen={isCancellationReasonPanelOpen}
        onClose={() => setIsCancellationReasonPanelOpen(false)}
        reason={subscription.data?.cancellation_details?.reason ?? undefined}
        freeText={subscription.data?.cancellation_details?.comment ?? undefined}
      />

      <DetailsPanel
        className={classes.detailsPanel}
        enableScrollUiChanges={false}
      >
        <LoadingOverlay visible={subscription.isLoading} />

        <DetailsPanel.Header onClose={onClose} spacing={0}>
          <Stack align="center">
            <ProductImage
              src={subscription.data?.product?.image_url}
              width={95}
            />

            <DetailsPanel.Title>
              {subscription.data?.product?.name}
            </DetailsPanel.Title>

            <SubscriptionStatusBadge status={subscription.data?.status} />

            {subscription.data?.status === 'pending_cancellation' && (
              <TimeLeftUntilCanceled
                canceledScheduledAt={
                  subscription.data?.canceled_scheduled_at as string
                }
              />
            )}

            {(showCancelButton || showCancellationReasonPanelButton) && (
              <DetailsPanel.Actions className={classes.actions}>
                {showCancellationReasonPanelButton && (
                  <DetailsPanel.ActionButton
                    onClick={() =>
                      setIsCancellationReasonPanelOpen((prev) => !prev)
                    }
                  >
                    {isCancellationReasonPanelOpen
                      ? 'Hide Cancellation Reason'
                      : 'Show Cancellation Reason'}
                  </DetailsPanel.ActionButton>
                )}

                {showCancelButton && (
                  <DetailsPanel.ActionButton
                    color="red.4"
                    variant="outline"
                    data-testid="cancel-subscription-button"
                    onClick={() =>
                      openModal<SubscriptionCancellationFlowModalProps['data']>(
                        'SubscriptionCancellationFlowModal',
                        {
                          subscription: subscription.data,
                        }
                      )
                    }
                  >
                    Cancel Subscription
                  </DetailsPanel.ActionButton>
                )}
              </DetailsPanel.Actions>
            )}
          </Stack>
        </DetailsPanel.Header>

        {isUsageBased ? (
          <UsageBasedSubscriptionDetailsPanelContent
            subscription={subscription.data}
            isPaymentHistoryPanelOpen={isPaymentHistoryPanelOpen}
            togglePaymentHistoryPanel={() =>
              setIsPaymentHistoryPanelOpen((prev) => !prev)
            }
          />
        ) : (
          <DetailsPanel.Body>
            <OverviewTab subscription={subscription.data} />
          </DetailsPanel.Body>
        )}
      </DetailsPanel>
    </>
  );
}

const useStyles = createStyles((theme) => ({
  detailsPanel: {
    position: 'relative',
    zIndex: 1,
    backgroundColor: theme.white,
  },
  header: {
    // For the actions menu to render above body content.
    zIndex: 1,
  },
  actions: {
    alignSelf: 'stretch',
  },
}));

dayjs.extend(relativeTime);

interface TimeLeftUntilCanceledProps {
  canceledScheduledAt: string;
}

function TimeLeftUntilCanceled({
  canceledScheduledAt,
}: TimeLeftUntilCanceledProps) {
  const timeLeft = dayjs().to(canceledScheduledAt);

  return (
    <Text color="amber.8">
      Expires {timeLeft} ({dayjs(canceledScheduledAt).format('MMM DD, YYYY')})
    </Text>
  );
}
