import { Button, Group, Paper, Text } from '@mantine/core';
import React from 'react';

import {
  usePartnerConfig,
  useRequestAccountDeletion,
} from '@portals/api/partners';
import { useConfirmationModal } from '@portals/framework';

export function AccountDeletion() {
  const partnerConfig = usePartnerConfig();
  const asyncConfirmation = useConfirmationModal();

  const requestAccountDeletion = useRequestAccountDeletion();

  const onRequestDeletion = async () => {
    const isConfirmed = await asyncConfirmation({
      description: 'Are you sure you want to request deletion of this account?',
    });

    if (isConfirmed) {
      requestAccountDeletion.mutate();
    }
  };

  return (
    <Paper withBorder p="xl" radius="md">
      <Group position="apart" align="center" h={48}>
        <Text color="blue_gray.8">Request account deletion</Text>

        {partnerConfig?.requested_tenant_deletion ? (
          <Text color="dimmed">
            Account deletion request is being processed.
          </Text>
        ) : (
          <Button color="red.4" onClick={onRequestDeletion}>
            Delete Account
          </Button>
        )}
      </Group>
    </Paper>
  );
}
