import React from 'react';

import {
  DashboardContentLayout,
  RoutedTabs,
  RoutedTabsProps,
} from '@portals/framework';

import { SubscriptionsTable } from './SubscriptionsTable';

const ROUTES: RoutedTabsProps['routes'] = [
  {
    path: 'active',
    tabLabel: 'Active',
    element: (
      <SubscriptionsTable statuses={['active', 'pending_cancellation']} />
    ),
  },
  {
    path: 'suspended',
    tabLabel: 'Suspended',
    element: <SubscriptionsTable statuses={['suspended']} />,
  },
  {
    path: 'canceled',
    tabLabel: 'Canceled',
    element: <SubscriptionsTable statuses={['canceled']} />,
  },
];

export function Subscriptions() {
  return (
    <DashboardContentLayout bg="gray.0" pageTitle="Subscriptions">
      <RoutedTabs routes={ROUTES} basePath="/store-management/subscriptions" />
    </DashboardContentLayout>
  );
}
