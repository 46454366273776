import {
  Avatar,
  Box,
  Button,
  Group,
  LoadingOverlay,
  Text,
} from '@mantine/core';
import React from 'react';

import { GroupType, useGroups, useRemoveGroup } from '@portals/api/partners';
import { CopyToClipboard, UsersAvatarGroup } from '@portals/core';
import { GroupIcon, useConfirmationModal } from '@portals/framework';
import { ReactComponent as ProfileCircle } from '@portals/icons/linear/profile-circle.svg';
import { ReactComponent as Setting2 } from '@portals/icons/linear/setting-2.svg';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import { useOpenModal } from '@portals/redux';
import { RowMenuItems, SmartTable } from '@portals/table';
import { TableColumn } from '@portals/types';

import { ManageGroupModalProps } from '../../../../modals';

export function GroupsTable() {
  const openModal = useOpenModal();
  const groups = useGroups();

  const asyncConfirmationCheck = useConfirmationModal();

  const removeGroup = useRemoveGroup();
  const deleteGroup = async (row: GroupType) => {
    const isConfirmed = await asyncConfirmationCheck({
      description: 'Are you sure?',
    });

    if (isConfirmed) {
      try {
        await removeGroup.mutateAsync({
          groupId: row.id,
        });
      } catch (e) {
        console.error(e);
      }
    }
  };

  const columns: TableColumn<GroupType>[] = [
    {
      dataField: 'name',
      text: 'Name',
      formatter: (_, row) => (
        <Group noWrap>
          <Avatar>
            <GroupIcon iconName={row.icon_name} />
          </Avatar>
          <Text truncate>{row.name}</Text>
        </Group>
      ),
    },
    {
      dataField: 'id',
      text: 'ID',
      formatter: (_, row) => (
        <Group noWrap>
          <Text truncate>{row.id}</Text>
          <CopyToClipboard value={row.id} />
        </Group>
      ),
    },
    {
      dataField: 'users',
      text: 'Members',
      formatter: (_, row) => (
        <UsersAvatarGroup
          users={row.users}
          onAddUserClick={() =>
            openModal<ManageGroupModalProps['data']>('ManageGroupModal', {
              groupId: row.id,
              tabToOpen: 'members',
            })
          }
        />
      ),
    },
  ];

  return (
    <Box h="100%">
      <LoadingOverlay visible={groups.isFetching || removeGroup.isLoading} />

      <SmartTable<GroupType>
        noColumnsSelection
        exportParams={{ isEnabled: false }}
        name="settings-groups"
        keyField="id"
        data={groups.data || []}
        columns={columns}
        onRowClick={(row) =>
          openModal<ManageGroupModalProps['data']>('ManageGroupModal', {
            groupId: row.original.id,
            tabToOpen: 'general',
          })
        }
        additionalActions={() => (
          <Button
            size="xs"
            data-testid="partners-create-group-button"
            onClick={() =>
              openModal<ManageGroupModalProps['data']>('ManageGroupModal', {
                tabToOpen: 'general',
              })
            }
          >
            Create Group
          </Button>
        )}
        rowMenu={({ row, wrapperProps }) => (
          <RowMenuItems
            wrapperProps={wrapperProps}
            items={[
              {
                id: 'add-members',
                label: 'Add Members',
                Icon: ProfileCircle,
                onClick: () => {
                  wrapperProps.menuRef?.onClose();
                  openModal<ManageGroupModalProps['data']>('ManageGroupModal', {
                    groupId: row.original.id,
                    tabToOpen: 'members',
                  });
                },
              },
              {
                id: 'manage-group',
                label: 'Manage Group',
                onClick: () => {
                  wrapperProps.menuRef?.onClose();
                  openModal<ManageGroupModalProps['data']>('ManageGroupModal', {
                    groupId: row.original.id,
                    tabToOpen: 'general',
                  });
                },
                Icon: Setting2,
              },
              {
                id: 'delete',
                label: 'Delete Group',
                withTopDivider: true,
                color: 'red',
                onClick: () => {
                  wrapperProps.menuRef?.onClose();
                  deleteGroup(row.original);
                },
                Icon: Trash,
              },
            ]}
          />
        )}
      />
    </Box>
  );
}
