import { get, isEmpty } from 'lodash/fp';
import React from 'react';
import { connect } from 'react-redux';

import { JsonBlob } from '@portals/ui';

const Response = ({ response }) =>
  !isEmpty(response) ? (
    <div>
      <h5 data-testid="response-status">
        Status:
        {response.status}
      </h5>
      <JsonBlob json={response.data} height="300px" />
    </div>
  ) : null;

const mapStateToProps = (state, ownProps) => ({
  response:
    get(['data', 'tracking', ownProps.type], state) || ownProps.response || {},
});

export default connect(mapStateToProps)(Response);
