import { WidgetColorType } from '../../widgets.types';

export enum WidgetDataRangeEnum {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Live = 'live',
  MonthToDate = 'monthToDate',
}

export type ScatterChartWidgetEventsConfig = Record<
  string, // event uuid
  {
    id: string;
    color: WidgetColorType;
    event_name: string;
    event_display_name: string;
    numeric_parameter: 'int_key_1' | 'int_key_2';
    units?: string;
  }
>;
