import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isBoolean } from 'lodash/fp';
import { useDispatch } from 'react-redux';

import { useAuth } from '@portals/redux';
import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';
import {
  OrganizationConfigurationType,
  OrganizationNotificationSettingsType,
  OrganizationPricingPlanFeatures,
  TenantType,
} from '@portals/types';

import {
  CONFIGURATION_API_URL,
  configurationQueryKeys,
} from './configuration.constants';
import { useApiQuery } from '../../hooks';
import { QueryOptions, ServerError } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';

export const useConfig = (
  queryOptions: QueryOptions<OrganizationConfigurationType> = {}
) => {
  const auth = useAuth();

  return useApiQuery<OrganizationConfigurationType>(
    CONFIGURATION_API_URL,
    configurationQueryKeys.config,
    {
      ...queryOptions,
      enabled:
        (!isBoolean(queryOptions.enabled) || queryOptions.enabled) &&
        Boolean(auth),
    }
  );
};

export function useRequestAccountDeletion() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: `${CONFIGURATION_API_URL}/request_account_deletion`,
    method: 'POST',
  });

  return useMutation<void, ServerError, void>({
    mutationFn: () => {
      return fetchApiRequest(url, options);
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Account deletion requested'));

      queryClient.invalidateQueries(configurationQueryKeys.config);
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useRequestAccountDeletion',
      baseUrl: `${CONFIGURATION_API_URL}/request_account_deletion`,
      method: 'POST',
    },
  });
}

export function useRequestQsysReflectIntegration() {
  const dispatch = useDispatch();

  const { url, options } = useRequestOptions({
    url: `${CONFIGURATION_API_URL}/request_qsys_reflect_integration`,
    method: 'POST',
  });

  return useMutation<void, ServerError>({
    mutationFn: () => fetchApiRequest(url, options),
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
  });
}

export function useUpdateNotifications() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: `${CONFIGURATION_API_URL}/notifications`,
    method: 'POST',
  });

  return useMutation<void, ServerError, OrganizationNotificationSettingsType>({
    mutationFn: (notifications) => {
      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify({
          notifications,
        }),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(configurationQueryKeys.config);
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useUpdateNotifications',
      baseUrl: `${CONFIGURATION_API_URL}/notifications`,
      method: 'POST',
    },
  });
}

interface UseUpdateConfigurationParams {
  updatedConfig: Partial<{ logo_url: string; display_name: string }>;
  withSuccessNotification?: boolean;
}

export const useUpdateConfiguration = () => {
  const dispatch = useDispatch();

  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: CONFIGURATION_API_URL,
    method: 'PUT',
  });

  return useMutation<
    OrganizationConfigurationType,
    ServerError,
    UseUpdateConfigurationParams
  >({
    mutationFn: ({ updatedConfig }) =>
      fetchApiRequest(url, {
        ...options,
        body: JSON.stringify(updatedConfig),
      }),
    onSuccess: (config, { withSuccessNotification }) => {
      queryClient.invalidateQueries(configurationQueryKeys.config);

      if (withSuccessNotification) {
        dispatch(toastrSuccess('Configuration settings updated successfully'));
      }
    },
    onError: ({ error }) => dispatch(toastrError(error)),
    meta: {
      mutationName: 'useUpdateConfiguration',
      baseUrl: CONFIGURATION_API_URL,
      method: 'PUT',
    },
  });
};

export function useSendReport() {
  const dispatch = useDispatch();

  const { url, options } = useRequestOptions({
    url: `${CONFIGURATION_API_URL}/send_report`,
    method: 'POST',
  });

  return useMutation<void, ServerError, string>({
    mutationFn: (email) => {
      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify(email),
      });
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Report sent successfully'));
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useSendReport',
      baseUrl: `${CONFIGURATION_API_URL}/send_report`,
      method: 'POST',
    },
  });
}

// Helpers
export function useOrganizationConfig() {
  const config = useConfig();

  return config.data?.[TenantType.Organization];
}

export function useSettingsConfig() {
  const config = useConfig();

  return config.data?.settings;
}

export function useDesign() {
  const organizationConfig = useOrganizationConfig();

  return organizationConfig?.design;
}

export function useFeatureFlags() {
  const { data } = useConfig();

  return data?.feature_flags;
}

export function usePortalCapabilities() {
  const config = useConfig();

  return config.data?.portal_capabilities;
}

export function useWithSupportCenter() {
  const portalCapabilities = usePortalCapabilities();

  return Boolean(portalCapabilities?.support_center);
}

export function useSpaceDashboard() {
  const config = useConfig();

  return config.data?.space_dashboard;
}

export function usePricingPlan() {
  const config = useConfig();

  return config?.data?.pricing_plan;
}

export function usePricingPlanFeatures() {
  const pricingPlan = usePricingPlan();

  return pricingPlan?.features;
}

export function usePricingPlanFeature(
  feature: keyof OrganizationPricingPlanFeatures
) {
  const pricingPlanFeatures = usePricingPlanFeatures();

  return pricingPlanFeatures?.[feature];
}

export function useOnboardingTours() {
  const config = useConfig();

  return config.data?.onboarding_tours;
}

export function useDismissOnboardingTours() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: CONFIGURATION_API_URL,
    method: 'PUT',
  });

  const onboardingTours = useOnboardingTours();

  return useMutation<void, ServerError>({
    mutationFn: () => {
      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify({
          onboarding_tours: { ...onboardingTours, dismiss: true },
        }),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(configurationQueryKeys.config);
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useDismissOnboardingTours',
      baseUrl: url,
      method: 'PUT',
    },
  });
}

export function useWelcomeVideoPlayed() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: CONFIGURATION_API_URL,
    method: 'PUT',
  });

  const onboardingTours = useOnboardingTours();

  return useMutation<void, ServerError>({
    mutationFn: () => {
      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify({
          onboarding_tours: { ...onboardingTours, isWelcomeVideoPlayed: true },
        }),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(configurationQueryKeys.config);
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useWelcomeVideoPlayed',
      baseUrl: url,
      method: 'PUT',
    },
  });
}

type GettingStartedTourType =
  keyof OrganizationConfigurationType['onboarding_tours']['getting_started'];

export function useCompleteGettingStartedTour() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: CONFIGURATION_API_URL,
    method: 'PUT',
  });

  const onboardingTours = useOnboardingTours();

  return useMutation<void, ServerError, GettingStartedTourType>({
    mutationFn: (stepId) => {
      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify({
          onboarding_tours: {
            ...onboardingTours,
            getting_started: {
              ...onboardingTours?.getting_started,
              [stepId]: true,
            },
          },
        }),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(configurationQueryKeys.config);
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useCompleteGettingStartedTour',
      baseUrl: url,
      method: 'PUT',
    },
  });
}
