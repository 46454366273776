import { Group, Paper, Stack, Text } from '@mantine/core';
import React from 'react';

import { Product } from '@portals/api/partners';

import {
  FIELD_ICONS,
  FIELD_LABELS,
} from '../../modals/product-wizard-modal/post-purchase-parameters-step/post-purchase-params-step.constants';

interface ReadOnlyPostPurchaseParamFieldsProps {
  fields: Product['post_purchase_parameters'];
}

export function ReadOnlyPostPurchaseParamsFields({
  fields,
}: ReadOnlyPostPurchaseParamFieldsProps) {
  return (
    <Stack>
      {fields.map((field) => {
        const Icon = FIELD_ICONS[field.type];

        return (
          <Paper
            key={`${field.type}_${field.label}`}
            withBorder
            p="xl"
            radius="lg"
          >
            <Group noWrap>
              <div>
                <Icon />
              </div>

              <Stack spacing={4} fw={500} sx={{ overflow: 'hidden' }}>
                <Group spacing="xs" noWrap>
                  <Text
                    size="md"
                    color="gray.8"
                    truncate
                    data-testid={`parameter-title-field-${field.label}`}
                  >
                    {field.label}
                  </Text>
                  {field.required ? (
                    <Text color="red" span>
                      *
                    </Text>
                  ) : null}
                </Group>
                <Text size="xs" color="gray.5">
                  {FIELD_LABELS[field.type]}
                </Text>
              </Stack>
            </Group>
          </Paper>
        );
      })}
    </Stack>
  );
}
