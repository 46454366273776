import { Text } from '@mantine/core';
import { Stack } from '@mantine/core';
import { get } from 'lodash/fp';
import React, { FC } from 'react';
import { connect } from 'react-redux';

import { AutoFormik } from '@portals/autoformik';
import { usePermissionAccess } from '@portals/framework';
import { RuleType, FieldType, FieldTypeEnum } from '@portals/types';

import RulesEditor from '../../components/RulesEditor';
import { ruleSetValidator } from '../../components/RulesEditor/mapping';
import { updateRule } from '../../redux/actions/rules';

const FIELDS: Array<FieldType> = [
  {
    name: 'definition',
    title: null,
    required: true,
    default: {},
    type: FieldTypeEnum.Custom,
    component: RulesEditor,
    validations: ruleSetValidator,
  },
];

type RuleDefinitionsProps = {
  rule: RuleType;
  inProgress: boolean;
};

type RuleDefinitionsConnectedActions = {
  updateRule: (ruleId: string, updatedRule: RuleType) => void;
};

const RuleDefinitions: FC<
  RuleDefinitionsProps & RuleDefinitionsConnectedActions
> = ({ rule, inProgress, updateRule }) => {
  const { canEdit } = usePermissionAccess();
  const handleSubmit = ({ definition }) =>
    updateRule(rule.id, { ...rule, definition });

  return (
    <Stack
      sx={(theme) => ({
        '.modal-body': {
          padding: 0,
        },
        '.modal-footer': {
          border: 'none',
          padding: 0,
          marginTop: theme.spacing.xs,
        },
      })}
    >
      <Stack spacing="xs">
        <Text color="gray.7">Definitions</Text>
        <Text color="gray.6" size="sm">
          Rule matching predicates
        </Text>
      </Stack>

      <AutoFormik
        readOnly={!canEdit('models')}
        fields={FIELDS}
        initialValues={rule}
        inProgress={inProgress}
        handleSubmit={handleSubmit}
        submitTitle="Update"
      />
    </Stack>
  );
};

const mapStateToProps = (state, ownProps) => ({
  inProgress: get(['ui', 'network', `updateRule_${ownProps.rule.id}`], state),
});

export default connect(mapStateToProps, {
  updateRule,
})(RuleDefinitions);
