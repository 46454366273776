import { Box, Button, createStyles, Menu } from '@mantine/core';
import { map } from 'lodash/fp';
import React from 'react';
import { Row } from 'react-table';

import { ConnectedPartner } from '@portals/api/partners';
import { ReactComponent as Plus } from '@portals/icons/linear/add-square.svg';
import { ReactComponent as Briefcase } from '@portals/icons/linear/briefcase.svg';
import { ReactComponent as Flash } from '@portals/icons/linear/flash.svg';
import { useOpenModal } from '@portals/redux';
import { useTableInstance } from '@portals/table';

import { CreateNewPartnerTypeModalProps } from '../../modals';

interface PartnersItemsActionsProps {
  relationshipTypes: string[] | undefined;
  onDisconnectPartners: (
    rowsById: Record<string, Row<ConnectedPartner>>,
    selectedRowIds: Record<string, boolean> | undefined
  ) => Promise<void>;
  onUpdateType: (id: string[], relationship_type: string) => void;
}

export function PartnersItemsActions({
  onDisconnectPartners,
  onUpdateType,
  relationshipTypes,
}: PartnersItemsActionsProps) {
  const openModal = useOpenModal();
  const tableInstance = useTableInstance<ConnectedPartner>();
  const rowsById = tableInstance?.rowsById;
  const selectedRowIds = tableInstance?.state?.selectedRowIds;
  const { classes } = useStyles();

  return (
    <>
      <Box className={classes.container} px="md">
        <Menu
          position="bottom-end"
          classNames={{
            dropdown: classes.menuDropdown,
            itemIcon: classes.menuItemIcon,
          }}
        >
          <Menu.Target>
            <Button
              variant="subtle"
              color="blue_gray"
              size="xs"
              px={5}
              styles={{
                leftIcon: {
                  width: 16,
                  height: 16,
                },
              }}
              leftIcon={<Briefcase />}
            >
              Change Type
            </Button>
          </Menu.Target>

          <Menu.Dropdown>
            {relationshipTypes?.map((relationshipType) => (
              <Menu.Item
                key={relationshipType}
                onClick={() =>
                  onUpdateType(
                    map(
                      ({ original }) => original.id,
                      tableInstance.selectedFlatRows
                    ),
                    relationshipType
                  )
                }
              >
                {relationshipType}
              </Menu.Item>
            ))}

            <Menu.Divider />
            <Menu.Item
              onClick={() => {
                openModal<CreateNewPartnerTypeModalProps['data']>(
                  'CreateNewPartnerTypeModal',
                  {
                    onChange: (relationshipType: string) =>
                      onUpdateType(
                        map(
                          ({ original }) => original.id,
                          tableInstance.selectedFlatRows
                        ),
                        relationshipType
                      ),
                  }
                );
              }}
              icon={<Plus />}
            >
              Create New
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Box>
      <Button
        variant="subtle"
        color="blue_gray"
        size="xs"
        px={5}
        styles={{
          leftIcon: {
            width: 16,
            height: 16,
          },
        }}
        onClick={() => onDisconnectPartners(rowsById, selectedRowIds)}
        leftIcon={<Flash />}
      >
        Disconnect Partners
      </Button>
    </>
  );
}

const useStyles = createStyles((theme) => ({
  dropdown: {
    padding: theme.spacing.md,
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr min-content',
    alignItems: 'center',
    gap: theme.spacing.md,
  },
  menuDropdown: {
    boxShadow: '0px 4px 10px rgba(38, 50, 56, 0.13)',
  },
  menuItemIcon: {
    width: 16,
    height: 16,
    marginRight: theme.spacing.sm,
  },
}));
