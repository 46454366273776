import React from 'react';

import { Product } from '@portals/api/partners';
import { DetailsPanel } from '@portals/core';

import {
  canArchiveProduct,
  canDeleteProduct,
  canEditProduct,
} from './products-catalog-page.utils';

interface DetailsPanelActionsProps {
  product: Product;
  onEdit: (product: Product) => void;
  onDuplicate: (product: Product) => void;
  onDelete: (product: Product) => void;
  onArchive: (product: Product) => void;
}

export function DetailsPanelActions({
  product,
  onEdit,
  onDuplicate,
  onDelete,
  onArchive,
}: DetailsPanelActionsProps) {
  return (
    <DetailsPanel.Actions>
      {canEditProduct(product) && (
        <DetailsPanel.ActionButton
          data-testid="details-panel-edit-button"
          onClick={() => onEdit(product)}
        >
          Edit
        </DetailsPanel.ActionButton>
      )}

      <DetailsPanel.ActionButton
        data-testid="details-panel-duplicate-button"
        onClick={() => onDuplicate(product)}
      >
        Duplicate
      </DetailsPanel.ActionButton>

      {canDeleteProduct(product) && (
        <DetailsPanel.ActionButton
          data-testid="details-panel-delete-button"
          onClick={() => onDelete(product)}
        >
          Delete
        </DetailsPanel.ActionButton>
      )}

      {canArchiveProduct(product) && (
        <DetailsPanel.ActionButton
          data-testid="details-panel-archive-button"
          onClick={() => onArchive(product)}
        >
          Archive
        </DetailsPanel.ActionButton>
      )}
    </DetailsPanel.Actions>
  );
}
