import React from 'react';

import { useDeleteFileInfo } from '@portals/api/partners';
import { useConfirmationModal } from '@portals/framework';
import { ReactComponent as DocumentCloud } from '@portals/icons/linear/document-cloud.svg';
import { ReactComponent as Edit } from '@portals/icons/linear/edit.svg';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import { useOpenModal } from '@portals/redux';
import { RowMenuItem, RowMenuItems } from '@portals/table';
import { PartnerFileResponseType, RowMenuWrapperType } from '@portals/types';

interface FileInfosTableRowMenuProps {
  fileInfo: PartnerFileResponseType;
  wrapperProps: RowMenuWrapperType;
}

export function FileInfosTableRowMenu({
  fileInfo,
  wrapperProps,
}: FileInfosTableRowMenuProps) {
  const { menuRef } = wrapperProps;
  const deleteFileInfo = useDeleteFileInfo();
  const asyncConfirmationCheck = useConfirmationModal();
  const openModal = useOpenModal();

  const deleteFile = async (file: PartnerFileResponseType) => {
    const isConfirmed = await asyncConfirmationCheck({
      description: 'Are you sure?',
    });

    if (isConfirmed) {
      deleteFileInfo.mutate(file.id);
    }
  };

  const onOpenFile = (fileUrl: string) => {
    window.open(fileUrl, `_blank`, 'noopener noreferrer');
  };

  const items: RowMenuItem[] = [
    {
      id: 'view',
      label: 'View File',
      Icon: DocumentCloud,
      onClick: () => {
        menuRef?.onClose();
        onOpenFile(fileInfo.url);
      },
    },
    {
      id: 'edit',
      label: 'Edit File',
      Icon: Edit,
      onClick: () => {
        menuRef?.onClose();
        openModal('FileUploader', {
          modelId: fileInfo.device_model_id,
          fileId: fileInfo.id,
        });
      },
    },
    {
      id: 'delete',
      label: 'Delete File',
      Icon: Trash,
      color: 'red',
      withTopDivider: true,
      onClick: () => {
        menuRef?.onClose();
        deleteFile(fileInfo);
      },
    },
  ];

  return <RowMenuItems items={items} wrapperProps={wrapperProps} />;
}
