import {
  Anchor,
  Avatar,
  Button,
  Group,
  Paper,
  Stack,
  Text,
} from '@mantine/core';
import React from 'react';

import { usePermissionAccess } from '@portals/framework';
import { ReactComponent as MessageText } from '@portals/icons/linear/message-text-1.svg';
import { ReactComponent as Star } from '@portals/icons/linear/star.svg';

export function SupportAgent() {
  const { isAdmin } = usePermissionAccess();

  return (
    <Paper withBorder p="xl" radius="md">
      <Stack spacing="xl">
        <Text size="md" color="gray.8">
          Your Support Agent
        </Text>

        <Group position="apart" align="center" h={48}>
          <Group>
            <Avatar radius="xl" />
            <Text color="blue_gray.8">McGowan Day</Text>
          </Group>

          {isAdmin ? (
            <Button
              variant="light"
              onClick={() => window.open('https://success.xyte.io', '_blank')}
              leftIcon={<Star />}
            >
              View Your Xyte Journey
            </Button>
          ) : null}

          <Anchor
            href="mailto:support@xyte.io"
            target="_blank"
            color="blue_accent.4"
          >
            <Group spacing="xs">
              <MessageText />
              Contact Support
            </Group>
          </Anchor>
        </Group>
      </Stack>
    </Paper>
  );
}
