import {
  Alert,
  AlertProps,
  createStyles,
  Group,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import React from 'react';

import { usePortalDomain } from '@portals/framework';
import { ReactComponent as Clock3Icon } from '@portals/icons/linear/clock-3.svg';
import { useOpenModal } from '@portals/redux';

import { useBrandingContext } from '../../branding.context';

export function DomainEdit() {
  const { classes } = useStyles();

  const { portalConfig, updatePortalConfig } = useBrandingContext();

  const openModal = useOpenModal();
  const currentDomainName = usePortalDomain();

  return (
    <Stack spacing="xs">
      <TextInput
        label="Your domain"
        disabled
        value={currentDomainName || portalConfig.candidate_domain}
      />

      <Group position="apart" fz="xs">
        <Group spacing="xs">
          <div className={classes.indicator} />
          <Text color="gray.7">Connected</Text>
        </Group>

        {!portalConfig.candidate_domain ? (
          <Text
            className={classes.changeDomainCta}
            onClick={() =>
              openModal('ChangeDomain', { onUpdate: updatePortalConfig })
            }
          >
            Change domain
          </Text>
        ) : null}
      </Group>

      {portalConfig.candidate_domain ? (
        <Alert
          color="blue_accent.4"
          radius="md"
          icon={<Clock3Icon />}
          title="Verifying new domain..."
          styles={alertStyles}
        >
          {portalConfig.candidate_domain}
        </Alert>
      ) : null}
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  indicator: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: theme.colors.teal_accent[3],
  },
  changeDomainCta: {
    cursor: 'pointer',
    color: theme.colors.blue_accent[4],
  },
}));

const alertStyles: AlertProps['styles'] = (theme) => ({
  wrapper: {
    alignItems: 'center',
  },
  title: {
    margin: 0,
    color: theme.colors.blue_gray[9],
    fontSize: theme.fontSizes.xs,
    fontWeight: 600,
  },
  message: {
    fontSize: theme.fontSizes.xs,
    color: theme.colors.blue_gray[6],
  },
});
