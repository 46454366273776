import React from 'react';
import { useParams } from 'react-router-dom';

import { RoutePanel } from '@portals/framework/route-panels';

import { PurchasedProductDetailsPanel } from '../../pages/store-management/pages/purchased-products/PurchasedProductDetailsPanel';

export function PurchasedProductRoutePanel() {
  const params = useParams<{ purchased_product_id: string }>();

  return (
    <RoutePanel panelId="purchased_product">
      {({ onClose }) => (
        <PurchasedProductDetailsPanel
          purchasedProductId={params.purchased_product_id}
          onClose={onClose}
        />
      )}
    </RoutePanel>
  );
}
