import { ActionIcon, createStyles, Group, Text, Tooltip } from '@mantine/core';
import { UseMutateFunction } from '@tanstack/react-query';
import { noop } from 'lodash/fp';
import React from 'react';

import { ServerError } from '@portals/api';
import {
  DeviceModelType,
  UseMoveSupportedCommandParams,
  useUpdateSupportedCommand,
} from '@portals/api/partners';
import {
  ModalButton,
  PermissionAccess,
  usePermissionAccess,
} from '@portals/framework';
import { ReactComponent as Crown } from '@portals/icons/bold/crown-2.svg';
import { ReactComponent as AddSquare } from '@portals/icons/linear/add-square.svg';
import { ReactComponent as EyeSlash } from '@portals/icons/linear/eye-slash.svg';
import { BooleanCell, SmartTable } from '@portals/table';
import {
  AccessLevelEnum,
  SupportedCommandType,
  TableColumn,
  UuidType,
} from '@portals/types';

import CommandsSelectedActions from './CommandsSelectedActions';
import { SupportedCommandsTableRowMenu } from './SupportedCommandsTableRowMenu';

interface SupportedCommandsTableProps {
  commands: Array<SupportedCommandType>;
  model: DeviceModelType;
  isLoading: boolean;
  onMove: (params: UseMoveSupportedCommandParams) => void;
  onDelete: UseMutateFunction<void, ServerError, UuidType>;
}

export function SupportedCommandsTable({
  commands,
  model,
  onDelete,
  onMove,
  isLoading,
}: SupportedCommandsTableProps) {
  const { canEdit } = usePermissionAccess();
  const isCustomCommandsEnabled = model.remaining_supported_commands > 0;

  const { classes } = useStyles();
  const updateSupportedCommand = useUpdateSupportedCommand(model.id);

  const toggleCommandVisibility = (
    id: string,
    is_visible_in_panel: boolean
  ) => {
    updateSupportedCommand.mutate({
      id,
      is_visible_in_panel: !is_visible_in_panel,
    });
  };

  const COLUMNS: Array<TableColumn<SupportedCommandType>> = [
    {
      dataField: 'name',
      text: 'Name',
      formatter: (_, { premium, name }) =>
        premium ? (
          <Group spacing={4} noWrap align="flex-start">
            <Tooltip label="Activation with a license enabled">
              <Crown className={classes.crownIcon} data-testid="crown-image" />
            </Tooltip>
            <Text truncate>{name}</Text>
          </Group>
        ) : (
          <Text truncate>{name}</Text>
        ),
    },
    {
      dataField: 'friendly_name',
      text: 'Friendly name',
    },
    {
      dataField: 'description',
      text: 'Description',
    },
    {
      dataField: 'with_file',
      text: 'Requires File',
      formatter: (_, { with_file }) => (
        <BooleanCell isActive={Boolean(with_file)} />
      ),
    },
    {
      dataField: 'file_type',
      text: 'File Type',
      formatter: (_, { with_file, file_type }) =>
        with_file ? file_type : null,
    },
    {
      dataField: 'active',
      text: 'Active',
      maxWidth: 100,
      minWidth: 100,
      formatter: (cell) => <BooleanCell isActive={cell.value} />,
    },
    {
      dataField: 'is_visible_in_panel',
      text: null,
      maxWidth: 50,
      formatter: (_, { id, is_visible_in_panel }) => {
        if (is_visible_in_panel) {
          return null;
        }

        return (
          <div className={classes.hovered}>
            <Tooltip label="Click to show in commands panel">
              <ActionIcon
                onClick={() => toggleCommandVisibility(id, is_visible_in_panel)}
                className={classes.hovered}
              >
                <EyeSlash data-testid="hidden-command-symbol" />
              </ActionIcon>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <SmartTable<SupportedCommandType>
      readOnly={!canEdit('models')}
      name="partners.models.commands"
      keyField="id"
      data={commands}
      columns={COLUMNS}
      noColumnsSelection
      noSort
      noFilters
      exportParams={{ isEnabled: false }}
      additionalActions={() => (
        <PermissionAccess
          minAccessLevel={AccessLevelEnum.Edit}
          permissionKeys="models"
        >
          {isCustomCommandsEnabled ? (
            <ModalButton
              modalName="AddSupportedCommand"
              label="Add Command"
              data-testid="add-command-button"
              disabled={isLoading}
              data={model.id}
              leftIcon={<AddSquare />}
            />
          ) : (
            <ModalButton
              modalName="UpgradePricingPlan"
              label="Add Commands"
              leftIcon={<AddSquare />}
              data={{
                source: `Partners > Models Page > Model > Support Commands > Add Command > Contact Us button in UpgradePricingPlan modal`,
              }}
            />
          )}
        </PermissionAccess>
      )}
      selectedItemsActions={() =>
        canEdit('models') ? (
          <CommandsSelectedActions
            onRemove={(commandId) => onDelete(commandId)}
            commands={commands}
          />
        ) : undefined
      }
      onSelected={noop}
      rowMenu={({ row, wrapperProps }) => (
        <SupportedCommandsTableRowMenu
          wrapperProps={wrapperProps}
          row={row}
          isCustomCommandsEnabled={isCustomCommandsEnabled}
          deviceModel={model}
          numOfCommands={commands.length}
          onDelete={onDelete}
          onMoveCommand={onMove}
        />
      )}
    />
  );
}

const useStyles = createStyles((theme) => ({
  hovered: {
    color: theme.colors.gray[6],

    '&:hover': {
      color: theme.colors.primary[3],
    },
  },

  crownIcon: {
    color: theme.colors.primary[4],
    width: 18,
    height: 18,
    minWidth: 18,
  },
}));
