import { createStyles, Group } from '@mantine/core';
import React from 'react';

import { useIsChannelPartner } from '@portals/api/partners';
import { TenantType } from '@portals/types';

import { FeedbackButton } from './FeedbackButton';
import { Notifications } from './Notifications';
import { ProductUpdates } from './ProductUpdates';
import { QuickSearch } from './QuickSearch';
import { SuccessCenter } from './SuccessCenter';
import { UserSettings } from './UserSettings';
import { useAppConfig } from '../../../context';

export const Navbar = () => {
  const { classes } = useStyles();
  const { extraLayout = {}, tenantType } = useAppConfig();
  const { navbarLayout, options = {} } = extraLayout;
  const isChannelPartner = useIsChannelPartner({
    enabled: tenantType === TenantType.Partner,
  });

  return (
    <Group
      position="right"
      align="center"
      spacing="xs"
      className={classes.container}
      bg="white"
    >
      <FeedbackButton />
      {options.isMobile ? null : <QuickSearch />}
      {navbarLayout ? navbarLayout() : null}
      <ProductUpdates />
      <Notifications />
      {isChannelPartner && <SuccessCenter />}
      <UserSettings />
    </Group>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    height: '100%',
    padding: '10px 12px',
    borderBottom: `2px solid ${theme.colors.gray[2]}`,

    li: {
      listStyle: 'none',
    },
  },
}));
