import { Badge } from '@mantine/core';
import React from 'react';

export function BetaBadge() {
  return (
    <Badge
      h={24}
      c="gray.9"
      fw={400}
      fz="xs"
      variant="gradient"
      gradient={{ from: '#E9F3F7', to: '#DEDBFD', deg: 108 }}
    >
      BETA
    </Badge>
  );
}
