import {
  Anchor,
  Box,
  Button,
  Divider,
  Group,
  ModalProps as MantineModalProps,
  ScrollArea,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { size } from 'lodash/fp';
import React from 'react';

import { useConfig } from '@portals/api/organizations';
import { ModalCenteredMediaLayout } from '@portals/core';
import { ReactComponent as InfoCircle } from '@portals/icons/bold/info-circle.svg';
import { VerticalScrollBar } from '@portals/scrollbar';
import { TenantType } from '@portals/types';

import { LicensesToAssign } from './LicenseToAssign';
import { LicenseToPurchase } from './LicenseToPurchase';
import crownSrc from '../../assets/img/crown-shadow.svg';
import { ModalProps } from '../../components/Modals';
import { useLicenseStoreListingsForSupportedCommand } from '../../hooks/store-listings';
import { useRelatedProductsToSupportedCommand } from '../../hooks/supported-commands';

export interface PremiumCommandLicenseModalProps
  extends ModalProps<{
    commandId: string | undefined;
    commandName: string | undefined;
    deviceId: string | undefined;
  }> {}

export function PremiumCommandLicenseModal({
  closeMe,
  data: { deviceId, commandId, commandName },
}: PremiumCommandLicenseModalProps) {
  const theme = useMantineTheme();

  const config = useConfig();

  const relatedProductsToSupportedCommand =
    useRelatedProductsToSupportedCommand(commandId);
  const licenseStoreListingsForSupportedCommand =
    useLicenseStoreListingsForSupportedCommand(commandId);

  if (
    relatedProductsToSupportedCommand.isLoading ||
    licenseStoreListingsForSupportedCommand.isLoading
  ) {
    return (
      <ModalCenteredMediaLayout
        opened
        onClose={closeMe}
        media={null}
        isLoading={
          relatedProductsToSupportedCommand.isLoading ||
          licenseStoreListingsForSupportedCommand.isLoading
        }
        radius="lg"
      >
        <Box h={300}></Box>
      </ModalCenteredMediaLayout>
    );
  }

  if (
    !relatedProductsToSupportedCommand.data?.length &&
    !licenseStoreListingsForSupportedCommand.data?.length
  ) {
    return (
      <ModalCenteredMediaLayout
        opened
        onClose={closeMe}
        media={<img src={crownSrc} />}
        radius="lg"
        styles={modalStyles}
      >
        <Stack>
          <Text ta="center" fz={24}>
            No available licenses to access this command
          </Text>

          <Group position="center" spacing={4}>
            <Text
              ta="center"
              color="gray.9"
              data-testid="please-contact-support-label"
            >
              Please contact support at
            </Text>

            <Anchor
              href={`mailto:${
                config.data?.[TenantType.Organization].partner_support_email
                  ? config.data?.[TenantType.Organization].partner_support_email
                  : 'support@xyte.io'
              }`}
            >
              support@xyte.io
            </Anchor>
          </Group>

          <Button mt="md" data-testid="got-it-button" onClick={closeMe}>
            Got it
          </Button>
        </Stack>
      </ModalCenteredMediaLayout>
    );
  }

  return (
    <ModalCenteredMediaLayout
      opened
      onClose={closeMe}
      media={<img src={crownSrc} />}
      title="Accessing this command requires a license"
      description={`In order to activate the ${commandName} command, please assign an
          available license or purchase a new one.`}
      footer={
        <Group position="right">
          <Button
            variant="default"
            data-testid="accessing-modal-cancel-button"
            onClick={closeMe}
          >
            Cancel
          </Button>
        </Group>
      }
      scrollAreaComponent={ScrollArea.Autosize}
      radius="lg"
      styles={modalStyles}
      size={650}
    >
      <VerticalScrollBar autoHeight autoHeightMax="40vh">
        <Stack spacing="xl">
          {size(relatedProductsToSupportedCommand.data) > 0 ? (
            <Stack spacing="xs">
              <Text size="md" weight={500}>
                Assign an available license
              </Text>

              <Group spacing="xs" mb="xs">
                <InfoCircle
                  color={theme.colors.gray[7]}
                  width={18}
                  height={18}
                />
                <Text color="gray.7">
                  Note: Assigning a license cannot be undone.
                </Text>
              </Group>

              <Stack>
                {relatedProductsToSupportedCommand.data?.map((product) => (
                  <LicensesToAssign
                    key={product.id}
                    licenses={product.licenses}
                    deviceId={deviceId}
                    closeModal={closeMe}
                  />
                ))}
              </Stack>
            </Stack>
          ) : null}

          {size(relatedProductsToSupportedCommand.data) > 0 &&
          size(licenseStoreListingsForSupportedCommand.data) > 0 ? (
            <Divider
              label="OR"
              labelPosition="center"
              color="gray.3"
              labelProps={{ color: 'gray.9' }}
            />
          ) : null}

          {size(licenseStoreListingsForSupportedCommand.data) > 0 ? (
            <Stack>
              <Text size="md" weight={500}>
                Purchase a new license
              </Text>

              <Stack>
                {licenseStoreListingsForSupportedCommand.data?.map(
                  (licenseStoreListing) => (
                    <LicenseToPurchase
                      key={licenseStoreListing.id}
                      licenseStoreListing={licenseStoreListing}
                      closeModal={closeMe}
                    />
                  )
                )}
              </Stack>
            </Stack>
          ) : null}
        </Stack>
      </VerticalScrollBar>
    </ModalCenteredMediaLayout>
  );
}

const modalStyles: MantineModalProps['styles'] = (theme) => ({
  header: {
    padding: theme.spacing.xxl,
  },
});
