import { Button, Group, Stack, Text, TextInput, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { useForgotPassword } from '@portals/api/auth';
import { TenantType } from '@portals/types';

export function ResetPassword() {
  const forgotPassword = useForgotPassword();
  const [isEmailSent, setIsEmailSent] = useState(false);

  const form = useForm({
    initialValues: {
      email: '',
    },
  });

  const onSubmit = (values: typeof form.values) => {
    forgotPassword.mutate(
      { email: values.email, tenantType: TenantType.Partner },
      { onSuccess: () => setIsEmailSent(true) }
    );
  };

  return (
    <Stack>
      <Title order={1} data-testid="reset-password-title">
        Reset Password
      </Title>

      {isEmailSent ? (
        <Text color="dimmed" size="sm">
          If the email you provided is associated with an account, you will
          receive a password reset link shortly.
        </Text>
      ) : (
        <>
          <form onSubmit={form.onSubmit(onSubmit)}>
            <TextInput
              required
              type="email"
              name="email"
              label="Email address"
              placeholder="The email you signed up with"
              {...form.getInputProps('email')}
            />

            <Button
              fullWidth
              size="sm"
              mt="lg"
              type="submit"
              loading={forgotPassword.isLoading}
            >
              Send reset link
            </Button>
          </form>

          <Text color="dimmed" size="sm" align="center">
            <Group spacing={6}>
              Return to
              <Link to="/auth/sign-in">Sign-In</Link>
            </Group>
          </Text>
        </>
      )}
    </Stack>
  );
}
