import {
  ActionIcon,
  Box,
  createStyles,
  Flex,
  Group,
  Text,
  Tooltip,
} from '@mantine/core';
import React from 'react';

import { PermissionAccess } from '@portals/framework';
import { ReactComponent as CloseX } from '@portals/icons/linear/close-x.svg';
import { AccessLevelEnum } from '@portals/types';
import { suppressPropagation } from '@portals/utils';

interface WidgetPreviewEmptyStateProps {
  onRemoveWidget: () => void;
}

export function WidgetPreviewEmptyState({
  onRemoveWidget,
}: WidgetPreviewEmptyStateProps) {
  const { classes } = useStyles();

  return (
    <Box pos="relative" h="100%" className={classes.wrapper}>
      <PermissionAccess
        minAccessLevel={AccessLevelEnum.Edit}
        permissionKeys="models"
      >
        <Tooltip.Group openDelay={500} closeDelay={100}>
          <Group
            className={classes.actions}
            w="100%"
            position="right"
            spacing="xs"
          >
            <Tooltip label="Remove widget" withinPortal>
              <ActionIcon
                size="sm"
                color="gray.9"
                radius="xl"
                p={4}
                variant="filled"
                onClick={suppressPropagation(onRemoveWidget)}
              >
                <CloseX />
              </ActionIcon>
            </Tooltip>
          </Group>
        </Tooltip.Group>
      </PermissionAccess>

      <Box h="100%" sx={{ pointerEvents: 'none' }}>
        <Flex
          justify="center"
          align="center"
          bg="white"
          w="100%"
          h="100%"
          className={classes.container}
        >
          <Text>Unknown widget</Text>
        </Flex>
      </Box>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    cursor: 'grab',

    ':active': {
      cursor: 'grabbing',
    },
  },
  actions: {
    position: 'absolute',
    top: 0,
    zIndex: 1,
    transform: 'translateY(-50%)',
  },
  container: {
    boxShadow: `0px 24px 36px rgba(13, 14, 48, 0.05)`,
    border: `1px solid ${theme.colors.gray[2]}`,
  },
}));
