import { Button, Group, LoadingOverlay, Stack } from '@mantine/core';
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { useDeviceModel } from '@portals/api/partners';
import {
  ModalButton,
  PermissionAccess,
  useConfirmationModal,
  usePermissionAccess,
} from '@portals/framework';
import { ReactComponent as CloseSquare } from '@portals/icons/linear/close-square.svg';
import { ReactComponent as Edit } from '@portals/icons/linear/edit.svg';
import { ReactComponent as TickSquare } from '@portals/icons/linear/tick-square.svg';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import { useRuleById } from '@portals/redux';
import { BooleanCell, InfoTable } from '@portals/table';
import { AccessLevelEnum } from '@portals/types';
import { PRIORITY_NAMES } from '@portals/utils';

import RuleDefinitions from './RuleDefinitions';
import RuleErrors from './RuleErrors';
import { deleteRule, getRule, updateRule } from '../../redux/actions/rules';

interface SingleRuleProps {
  ruleId: string;
}

const SingleRule: FC<SingleRuleProps> = ({ ruleId }) => {
  const { canEdit } = usePermissionAccess();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const rule = useRuleById(ruleId);
  const deviceModel = useDeviceModel(rule?.device_model);

  useEffect(() => {
    dispatch(getRule(ruleId));
  }, [dispatch, ruleId]);

  const asyncConfirmation = useConfirmationModal();

  const handleDelete = async () => {
    const isConfirmed = await asyncConfirmation({
      title: `Delete "${rule?.name}"`,
      description: `Are you sure you want to delete "${rule?.name}"?`,
    });

    if (isConfirmed) {
      dispatch(
        deleteRule(rule?.id, () =>
          navigate(`/models/${rule?.device_model}/rules`)
        )
      );
    }
  };

  const handleToggle = () => {
    dispatch(updateRule(rule?.id, { ...rule, active: !rule.active }));
  };

  if (!rule || deviceModel.isLoading) {
    return <LoadingOverlay visible />;
  }

  return (
    <Stack spacing="xl">
      <Stack>
        <PermissionAccess
          minAccessLevel={AccessLevelEnum.Edit}
          permissionKeys="models"
        >
          <Group position="right">
            <Button
              size="xs"
              data-testid="disable-rule-button"
              className="d-flex align-items-center"
              color={rule.active ? 'red' : 'green'}
              onClick={handleToggle}
              leftIcon={rule.active ? <CloseSquare /> : <TickSquare />}
              variant="outline"
              sx={{
                svg: {
                  width: 16,
                  height: 16,
                },
              }}
            >
              {rule.active ? 'Disable' : 'Enable'}
            </Button>

            <ModalButton
              modalName="EditRule"
              label="Edit"
              data-testid="edit-rule-button"
              data={rule}
              leftIcon={<Edit />}
            />

            <Button
              size="xs"
              data-testid="delete-rule-button"
              onClick={handleDelete}
              color="red"
              leftIcon={<Trash />}
              sx={{
                svg: {
                  width: 16,
                  height: 16,
                },
              }}
            >
              Delete
            </Button>
          </Group>
        </PermissionAccess>

        <InfoTable.Table>
          <InfoTable.Row label="Name" value={rule.name} />
          <InfoTable.Row label="Description" value={rule.description} />
          <InfoTable.Row
            label="Priority"
            value={PRIORITY_NAMES[rule.priority]}
          />
          <InfoTable.Row
            label="Model"
            value={
              deviceModel ? (
                <Link to={`/models/${deviceModel.data?.id}`}>
                  {deviceModel.data?.model}
                </Link>
              ) : null
            }
          />
          <InfoTable.Row
            label="Active"
            value={<BooleanCell isActive={rule.active} />}
          />
        </InfoTable.Table>
      </Stack>

      <RuleDefinitions rule={rule} />

      <RuleErrors rule={rule} readOnly={!canEdit('models')} />
    </Stack>
  );
};

export default SingleRule;
