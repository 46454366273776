import { filter } from 'lodash/fp';

import { useSupportSeats } from '@portals/api/partners';
import { useUsers } from '@portals/api/ui';

export function useUsersWithSupportSeats() {
  const users = useUsers();

  return filter((user) => user.has_support_seat, users.data);
}

export function useUsersWithoutSupportSeats() {
  const users = useUsers();

  return filter((user) => !user.has_support_seat, users.data);
}

export function useNumOfAvailableSupportSeats() {
  const supportSeats = useSupportSeats();

  if (!supportSeats.data) return 0;

  return supportSeats.data.total - supportSeats.data.in_use;
}
