import { Box, createStyles, Group } from '@mantine/core';
import React, { useState } from 'react';

import { RouteModal } from '@portals/framework/route-modals';

import { PROVIDERS } from './credit-providers.constants';
import { ProvidersList } from './ProvidersList';

export const CreditProviders = () => {
  const { classes } = useStyles();
  const [selectedProvider, setSelectedProvider] = useState<string>();

  const toggleSelectedProvider = (vendorId: string) =>
    setSelectedProvider(vendorId);

  return (
    <RouteModal modalId="order">
      <Box className={classes.container}>
        <Group className={classes.header}>Add New Provider</Group>

        <ProvidersList
          providers={PROVIDERS}
          selectedProvider={selectedProvider}
          toggleSelectedProvider={toggleSelectedProvider}
        />
      </Box>
    </RouteModal>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
  },
  header: {
    padding: theme.spacing.xl,
    fontSize: theme.fontSizes.xl,
    alignItems: 'center',
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },
}));
