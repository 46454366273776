import {
  ActionIcon,
  Box,
  createStyles,
  Loader,
  Stack,
  Text,
} from '@mantine/core';
import { find } from 'lodash/fp';
import React from 'react';

import { ReactComponent as Crown2 } from '@portals/icons/bold/crown-2.svg';
import { suppressPropagation } from '@portals/utils';

import { useGroupedSwitchesWidgetContext } from './grouped-switches-widget.context';
import { WIDGET_ICONS } from '../../widgets.constants';
import { useDebouncedTelemetryValue } from '../../widgets.hooks';
import { WidgetColorType } from '../../widgets.types';

export interface GroupedSwitchProps {
  key: string;
  iconName: string;
  commandName: string;
  commandParamKey: string;
  telemetry: string;
  label: string;
  color: WidgetColorType;
  displayTitle: boolean;
  disabled?: boolean;
  isPremiumCommand?: boolean | undefined;
  deviceId?: string | undefined;
  commandId?: string | undefined;
  onPremiumCommandClick?: (commandId: string, commandName: string) => void;
}

export function GroupedSwitch({
  commandName,
  commandParamKey,
  iconName,
  label,
  telemetry,
  color,
  displayTitle,
  disabled,
  isPremiumCommand,
  commandId,
  onPremiumCommandClick,
}: GroupedSwitchProps) {
  const { onToggle, deviceState, activeCommands } =
    useGroupedSwitchesWidgetContext();

  const { classes, theme, cx } = useStyles();
  const widgetIcon = WIDGET_ICONS.find(
    ({ iconName: name }) => name === iconName
  );

  const Icon = widgetIcon?.Icon || WIDGET_ICONS[0].Icon;

  const telemetryValue = deviceState?.[telemetry];
  const activeCommand = find({ name: commandName }, activeCommands);
  const isCommandActive = Boolean(activeCommand);

  const sentCommandValue =
    activeCommand && activeCommand.params?.[commandParamKey];

  const debouncedTelemetryValue = useDebouncedTelemetryValue(
    telemetryValue,
    sentCommandValue
  );

  const showEnabledStyle =
    debouncedTelemetryValue && !isCommandActive && !disabled;

  const onGroupedSwitchClick = () => {
    if (isPremiumCommand && commandId && commandName) {
      onPremiumCommandClick?.(commandId, commandName);
    } else {
      onToggle(commandName, commandParamKey, !telemetryValue);
    }
  };

  return (
    <ActionIcon
      w={86}
      h={86}
      className={cx(classes.container, {
        [classes.disabled]: isCommandActive || disabled,
      })}
      color={showEnabledStyle ? color : 'gray.7'}
      variant={showEnabledStyle ? 'filled' : 'light'}
      onClick={onGroupedSwitchClick}
      bg={showEnabledStyle ? color : 'gray.1'}
    >
      <Stack justify="center" align="center" spacing="xs" w="100%">
        {isCommandActive ? <Loader size="sm" color={color} /> : <Icon />}

        {displayTitle ? (
          <Text
            size="xs"
            data-testid="dashboard-grouped-switched-title"
            truncate
            align="center"
            w="100%"
            p="xs"
          >
            {label}
          </Text>
        ) : null}
      </Stack>

      {isPremiumCommand ? (
        <Box
          className={classes.iconContainer}
          onClick={suppressPropagation(() => {
            if (commandId && commandName) {
              onPremiumCommandClick?.(commandId, commandName);
            }
          })}
        >
          <Crown2
            color={theme.colors['blue_accent'][4]}
            width={18}
            height={18}
          />
        </Box>
      ) : null}
    </ActionIcon>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    borderRadius: theme.radius.md,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  iconContainer: {
    backgroundColor: theme.colors['blue_accent'][0],
    padding: theme.spacing.xs,
    position: 'absolute',
    top: -12,
    right: -10,
    borderRadius: '50%',
    boxShadow: '0px 2px 8px 0px rgba(38, 50, 56, 0.13)',
    pointerEvents: 'auto',
  },

  disabled: {
    pointerEvents: 'none',
    cursor: 'not-allowed',
  },
}));
