import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { SmartTable } from '@portals/table';
import { TableColumn } from '@portals/types';
import { prettyTime } from '@portals/utils';

const TABLE_COLUMNS: Array<TableColumn> = [
  {
    dataField: 'id',
    text: 'ID',
    formatter: (_, { id }) => (
      <Link to={`/store-management/licenses/${id}`}>{id}</Link>
    ),
  },
  {
    dataField: 'state',
    text: 'State',
  },
  {
    dataField: 'family',
    text: 'Family',
    formatter: (_, { family }) => family || 'None',
  },
  {
    dataField: 'expires_at',
    text: 'Expiring',
    formatter: (_, { expires_at }) =>
      expires_at ? prettyTime(expires_at) : 'None',
  },
];

function Licenses({ device }: { device: any }) {
  return (
    <Container className="p-3">
      <SmartTable
        isUrlSyncEnabled={false}
        name="partners.device.licenses"
        keyField="id"
        noHeader
        noFilters
        noDataIndication={{ title: 'Device has no assigned licenses' }}
        data={device.licenses}
        columns={TABLE_COLUMNS}
      />
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr;
`;

export default Licenses;
