import { Button, createStyles, Group, Loader, Text } from '@mantine/core';
import { size } from 'lodash/fp';
import React, { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import {
  useConfig,
  usePaginatedDevicesData,
  usePortalConfig,
  useRequestPlanUpgrade,
} from '@portals/api/partners';
import { useUsers } from '@portals/api/ui';
import { usePortalDomain } from '@portals/framework';
import { ReactComponent as Global } from '@portals/icons/linear/global.svg';
import { ReactComponent as MonitorMobbile } from '@portals/icons/linear/monitor-mobbile.svg';
import { ReactComponent as Profile2User } from '@portals/icons/linear/profile-2user.svg';
import { useOpenModal } from '@portals/redux';

import { usePricingPlanFeature } from '../../../hooks/partner-config';

export const NumOfDevices = () => {
  const { classes } = useStyles();
  const devices = usePaginatedDevicesData({ pageIndex: 0 });
  const config = useConfig();
  const registeredDevicesLimit = usePricingPlanFeature(
    'registered_devices_limit'
  );
  const requestPlanUpgrade = useRequestPlanUpgrade();

  const numberOfDevices = useMemo(() => {
    if (devices.isLoading) return '';

    const totalCount = devices?.data?.page_info?.total_count || 0;
    const limit = registeredDevicesLimit || 'Unlimited';

    return `${totalCount} / ${limit}`;
  }, [devices, registeredDevicesLimit]);

  return (
    <Group className={classes.item}>
      <MonitorMobbile className={classes.icon} />

      <div className={classes.texts}>
        <Text lineClamp={1} className={classes.itemTitle}>
          Number of Devices:&nbsp;
          <span>
            {devices.isLoading || config.isLoading ? (
              <Loader size="xs" />
            ) : (
              numberOfDevices
            )}
          </span>
        </Text>
        <Text
          className={classes.link}
          onClick={() => {
            requestPlanUpgrade.mutate(
              'Partners > Homepage > Number of Devices > Upgrade Plan link'
            );
          }}
        >
          Upgrade Plan
        </Text>
      </div>
    </Group>
  );
};

export const NumOfMembers = () => {
  const { classes } = useStyles();
  const openModal = useOpenModal();
  const users = useUsers();

  const numberOfMembers = useMemo(() => size(users?.data), [users]);

  return (
    <Group className={classes.item}>
      <Profile2User className={classes.icon} />

      <div className={classes.texts}>
        <Text lineClamp={1} className={classes.itemTitle}>
          Number of Members:&nbsp;
          <span>
            {users.isFetching ? <Loader size="xs" /> : numberOfMembers}
          </span>
        </Text>
        <Text
          className={classes.link}
          onClick={() => openModal('AddUserModal')}
        >
          Invite Members
        </Text>
      </div>
    </Group>
  );
};

export const EditURL = () => {
  const { classes } = useStyles();
  const domain = usePortalDomain();
  const portalConfig = usePortalConfig();

  return (
    <Group className={classes.item}>
      <Global className={classes.icon} />

      <div className={classes.texts}>
        <Text lineClamp={1} className={classes.itemTitle}>
          Your URL:&nbsp;
          <span>{portalConfig?.data?.candidate_domain || domain}</span>
        </Text>
        <Text
          className={classes.link}
          component={Link}
          to="/branding/customer-portal?tab=domain"
        >
          Edit URL
        </Text>
      </div>
    </Group>
  );
};

export const EditYourCloud = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  return (
    <Group className={classes.item}>
      <Button
        fullWidth
        color="primary"
        onClick={() => navigate('/branding/customer-portal')}
      >
        Edit Customer Portal
      </Button>
    </Group>
  );
};

const useStyles = createStyles((theme) => ({
  item: {
    gap: theme.spacing.sm,
    flexWrap: 'nowrap',

    '&:not(:last-child)': {
      paddingRight: theme.spacing.md,
      borderRight: `1px solid ${theme.colors.blue_gray[1]}`,
    },
  },
  icon: {
    width: 36,
    height: 36,
    color: theme.colors.blue_gray[7],
  },
  texts: {
    flexGrow: 1,
  },
  itemTitle: {
    marginBottom: 3,
    fontSize: theme.fontSizes.sm,
    fontWeight: 700,
    lineHeight: 1.43,
    color: theme.colors.blue_gray[7],

    span: {
      fontWeight: 500,
      color: theme.colors.blue_gray[4],
    },
  },
  link: {
    fontSize: theme.fontSizes.sm,
    fontWeight: 700,
    color: theme.other.primaryColor,
    cursor: 'pointer',

    ':hover': {
      textDecoration: 'none',
      color: theme.other.primaryColor,
    },
  },
}));
