import {
  AspectRatio,
  BackgroundImage,
  Grid,
  Group,
  Image,
  LoadingOverlay,
  Stack,
  Text,
} from '@mantine/core';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { TenantType } from '@portals/types';

import { PoweredByXyteIcon } from '../assets';
import { useAppConfig } from '../context';
import { useCommonPortalConfig } from '../hooks/portal-config';

type AuthProps = {
  children: ReactNode;
  isPreview?: boolean;
};

export function Auth({ children, isPreview = false }: AuthProps) {
  const { extraLayout = {}, tenantType } = useAppConfig();

  const portalConfig = useCommonPortalConfig({
    enabled: !isPreview && tenantType !== TenantType.Partner,
  });

  if (tenantType !== TenantType.Partner && !portalConfig.isFetched)
    return <LoadingOverlay visible />;

  const logoSrc =
    tenantType === TenantType.Partner
      ? 'logo.png'
      : portalConfig.data?.design?.sign_in_page?.logo;

  return (
    <>
      {extraLayout?.globalLayout && !isPreview
        ? extraLayout.globalLayout()
        : null}

      <Grid
        sx={(theme) => ({
          height: '100%',
          backgroundColor: theme.white,
          position: 'relative',
        })}
        m={0}
      >
        {logoSrc ? <Logo $logo={logoSrc} /> : null}

        <Grid.Col
          lg={4}
          md={3}
          p={0}
          sx={(theme) => ({
            backgroundColor:
              portalConfig.data?.design?.sign_in_page?.background_color ||
              theme.colors.blue_accent[4],
          })}
        >
          <BackgroundImage
            src={
              portalConfig.data?.design?.sign_in_page?.background_mode ===
              'image'
                ? portalConfig.data?.design?.sign_in_page?.background_image
                : ''
            }
            sx={{ height: '100%' }}
            p={0}
            radius={0}
          >
            <Stack
              align="center"
              justify="center"
              style={{
                height: '100%',
                position: 'relative',
                transform: 'translateY(-100px)',
              }}
            >
              <Group style={{ width: 305 }} position="center">
                <AspectRatio
                  ratio={1}
                  sx={(theme) => ({
                    maxWidth: 305,
                    width: '100%',

                    [`@media (max-width: ${theme.breakpoints.lg})`]: {
                      maxWidth: 250,
                    },
                  })}
                  mb="md"
                >
                  <Image
                    src={
                      portalConfig.data?.design?.sign_in_page?.login_image ||
                      'logo_full.svg'
                    }
                    mb="lg"
                  />
                </AspectRatio>

                {portalConfig.data?.design?.sign_in_page?.tagline ? (
                  <Text
                    align="center"
                    sx={(theme) => ({
                      color:
                        portalConfig.data?.design?.sign_in_page
                          ?.tagline_color || theme.white,
                      fontWeight: 600,
                      fontSize: 25,
                      lineHeight: '34px',
                      letterSpacing: '0.01em',
                      padding: '0 10px',
                      wordBreak: 'break-word',
                    })}
                  >
                    {portalConfig.data.design?.sign_in_page?.tagline ||
                      'Supercharging your HaaS business'}
                  </Text>
                ) : null}
              </Group>

              {portalConfig.data?.design?.sign_in_page
                ?.powered_by_xyte_label !== false ? (
                <PoweredByXyteIcon
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    mixBlendMode: portalConfig.data?.design?.sign_in_page
                      ?.background_color
                      ? 'difference'
                      : 'unset',
                  }}
                />
              ) : null}
            </Stack>
          </BackgroundImage>
        </Grid.Col>

        <Grid.Col lg={8} md={9}>
          <Group
            style={{ height: '100%', width: '100%' }}
            grow
            position="center"
          >
            <ContentWrapper>{children}</ContentWrapper>
          </Group>
        </Grid.Col>
      </Grid>
    </>
  );
}

const ContentWrapper = styled.div`
  max-width: 470px !important;
  width: 470px;
  padding: 0 35px;
`;

const Logo = styled.div<{ $logo: string }>`
  position: absolute;
  top: 36px;
  left: 36px;
  width: 50px;
  height: 50px;
  background-image: url(${({ $logo }) => $logo});
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
`;

export default Auth;
