import React from 'react';

import { TicketDeviceType } from '@portals/api/partners';
import { StateDumpsView } from '@portals/framework';

interface StateDumpProps {
  device: TicketDeviceType;
}

export function StateDumps({ device }: StateDumpProps) {
  return (
    <StateDumpsView
      isUrlSyncEnabled={false}
      data={device.state_dumps}
      name="partners.tickets.device.stateDump"
    />
  );
}
