import {
  Box,
  Card,
  createStyles,
  Group,
  Skeleton,
  SkeletonProps,
  Stack,
} from '@mantine/core';
import React from 'react';

import { ReactComponent as SearchNormal } from '@portals/icons/linear/search-normal 2.svg';

function SkeletonWithoutAnimation(props: SkeletonProps) {
  return <Skeleton animate={false} {...props} />;
}

export function StoreMock() {
  const { classes } = useStoreMockStyles();

  return (
    <div className={classes.grid}>
      <StoreSidebarSkeleton />

      <StoreContentSkeleton />
    </div>
  );
}

const useStoreMockStyles = createStyles({
  grid: {
    display: 'grid',
    gridTemplateColumns: 'minmax(150px, 1fr) 5fr',
  },
});

function StoreContentSkeleton() {
  const { classes } = useStoreContentSkeletonStyles();
  return (
    <Box bg="gray.0" p="lg">
      <div className={classes.container}>
        <StoreProductCardSkeleton />
        <StoreProductCardSkeleton />
        <StoreProductCardSkeleton />
        <StoreProductCardSkeleton />
      </div>
    </Box>
  );
}

const useStoreContentSkeletonStyles = createStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    gap: theme.spacing.md,
  },
}));

function StoreProductCardSkeleton() {
  return (
    <Card shadow="sm" h={350} p={0}>
      <Stack p={0} spacing={0} bg="gray.2" w="100%" h="100%">
        <Box pos="relative" h={234} w="100%">
          <SkeletonWithoutAnimation
            width={81}
            height={20}
            radius="lg"
            sx={(theme) => ({
              position: 'absolute',
              left: theme.spacing.md,
              top: theme.spacing.md,
              flexShrink: 0,

              '&:after': {
                backgroundColor: theme.colors.gray[5],
              },
            })}
          />
        </Box>

        <Stack bg="white" h={116} p="md">
          <SkeletonWithoutAnimation
            width="30%"
            height={14}
            radius="lg"
            sx={(theme) => ({
              '&:after': {
                backgroundColor: theme.colors.gray[3],
              },
            })}
          />

          <SkeletonWithoutAnimation
            width="90%"
            height={14}
            radius="lg"
            sx={(theme) => ({
              '&:after': {
                backgroundColor: theme.colors.gray[2],
              },
            })}
          />

          <SkeletonWithoutAnimation
            width="15%"
            height={14}
            radius="lg"
            sx={(theme) => ({
              '&:after': {
                backgroundColor: theme.colors.gray[4],
              },
            })}
          />
        </Stack>
      </Stack>
    </Card>
  );
}

function StoreSidebarSkeleton() {
  const { classes } = useStorePreviewStyles();

  return (
    <Stack p="md" pt={25} bg="white" w="100%" h="100%" spacing="xl">
      <Group
        w="100%"
        h={25}
        bg="gray.1"
        align="center"
        position="right"
        px="md"
        sx={(theme) => ({
          borderRadius: theme.radius.md,
          border: `1px solid ${theme.colors.gray[3]}`,
        })}
      >
        <SearchNormal width={12} height={12} />
      </Group>

      <Stack spacing="md">
        <Group align="center" position="apart" px="sm">
          <SkeletonWithoutAnimation width="30%" height={8} />

          <SkeletonWithoutAnimation width={14} height={9} />
        </Group>

        <Group
          position="apart"
          bg="primary"
          p="sm"
          sx={(theme) => ({ borderRadius: theme.radius.md })}
        >
          <SkeletonWithoutAnimation
            width="50%"
            height={8}
            className={classes.activeItem}
          />

          <SkeletonWithoutAnimation
            width={14}
            height={9}
            className={classes.activeItem}
          />
        </Group>

        <Group position="apart" px="sm">
          <SkeletonWithoutAnimation width="20%" height={8} />

          <SkeletonWithoutAnimation width={14} height={9} />
        </Group>

        <Group position="apart" px="sm">
          <SkeletonWithoutAnimation width="40%" height={8} />

          <SkeletonWithoutAnimation width={14} height={9} />
        </Group>

        <Group position="apart" px="sm">
          <SkeletonWithoutAnimation width="20%" height={8} />

          <SkeletonWithoutAnimation width={14} height={9} />
        </Group>
      </Stack>
    </Stack>
  );
}

const useStorePreviewStyles = createStyles((theme) => ({
  activeItem: {
    '&::after': {
      background: theme.white,
    },
  },
}));
