import { Anchor, createStyles, Group, Stack, Text } from '@mantine/core';
import React, { FC } from 'react';

import { ProviderType } from './credit-providers.types';

interface VendorCardProps extends ProviderType {
  onClick: () => void;
  isSelected: boolean;
}

export const ProviderCard: FC<VendorCardProps> = ({
  name,
  logo,
  url,
  onClick,
  isSelected,
}) => {
  const { classes, cx } = useStyles();

  return (
    <Stack
      className={cx(classes.container, { selected: isSelected })}
      spacing="md"
      onClick={onClick}
    >
      <Group sx={{ height: 76 }} align="center">
        <img src={logo} height={76} />
      </Group>

      <Stack spacing="xs">
        <Text className={classes.title}>{name}</Text>

        <Anchor href={url} className={classes.url} target="_blank">
          {url}
        </Anchor>
      </Stack>
    </Stack>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    padding: 32,
    borderRadius: theme.radius.sm,
    boxShadow: `0 0 0 1px ${theme.fn.rgba(theme.colors.gray[2], 1)}`,
    background: theme.white,
    cursor: 'pointer',
    transition: 'box-shadow 0.15s ease-in-out',

    '&.selected': {
      boxShadow: `0 0 0 2px ${theme.fn.rgba(theme.colors.blue_accent[4], 1)}`,
    },
  },
  title: {
    fontSize: theme.fontSizes.lg,
    color: theme.colors.gray[9],
    fontWeight: 600,
  },
  subtitle: {
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[5],
    fontWeight: 600,
  },
  url: {
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[5],
    fontWeight: 500,
  },
}));
