import { Anchor, Badge, Text } from '@mantine/core';
import React from 'react';

import { CardBrand, CurrencyCode, PaymentMethodEnum } from '@portals/types';
import { formatCurrency } from '@portals/utils';

import { ReactComponent as PurchaseOrderLogo } from '../../assets/img/po.svg';
import { CreditCardLogo } from '../CreditCardLogo';
import { DetailsList, DetailsListProps } from '../details-list/DetailsList';

interface InvoicePaymentDetailsProps {
  payment_method: string;
  credit_card: { last_4_digits: string; brand: CardBrand };
  tax: number;
  currency: CurrencyCode;
  amount: number;
  onOpenPurchaseOrderPanel: () => void;
  invoiceFileUploadedAt: string | null;
}

export function InvoicePaymentDetails({
  payment_method,
  credit_card,
  amount,
  tax,
  currency,
  onOpenPurchaseOrderPanel,
  invoiceFileUploadedAt,
}: InvoicePaymentDetailsProps) {
  const detailsList: DetailsListProps['items'] = [];

  if (payment_method === PaymentMethodEnum.CreditCard) {
    detailsList.push({
      label: <CreditCardLogo brand={credit_card?.brand} />,
      value: <Text>•••• {credit_card?.last_4_digits}</Text>,
    });
  } else if (payment_method === PaymentMethodEnum.PurchaseOrder) {
    detailsList.push({
      label: <PurchaseOrderLogo data-testid="purchase-order-logo" />,
      value: invoiceFileUploadedAt ? (
        <Anchor underline={false} onClick={onOpenPurchaseOrderPanel}>
          Show purchase order
        </Anchor>
      ) : null,
    });
  } else if (payment_method === PaymentMethodEnum.Lab) {
    detailsList.push({
      label: (
        <Badge size="xs" radius="sm" variant="filled" data-testid="lab-badge">
          LAB
        </Badge>
      ),
      value: 'Free',
    });
  }

  detailsList.push(
    { label: 'Tax', value: formatCurrency(tax, currency) },
    {
      label: 'Total Charged',
      value: formatCurrency(amount, currency),
    }
  );

  return <DetailsList title="Payment" items={detailsList} />;
}
