import { Button, Group, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { nanoid } from 'nanoid';
import React from 'react';
import styled from 'styled-components';

import { DevTestingFormValues } from '@portals/api/partners';
import { CopyToClipboard } from '@portals/core';
import { getStyledThemeColor } from '@portals/utils';

interface CloudIdInputProps {
  fieldName: keyof DevTestingFormValues;
  shortcode: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setFieldValue: UseFormReturnType<DevTestingFormValues>['setFieldValue'];
}

export function CloudIdInput({
  fieldName,
  shortcode,
  value,
  onChange,
  setFieldValue,
}: CloudIdInputProps) {
  const generateCloudId = () => setFieldValue(fieldName, nanoid(20));

  return (
    <Group>
      <TextInput
        style={{
          flex: 1,
        }}
        name={fieldName}
        value={value}
        onChange={onChange}
        iconWidth={65}
        icon={<StyledInputGroupText>{shortcode}</StyledInputGroupText>}
        rightSection={<CopyToClipboard value={`${shortcode}${value}`} />}
      />

      <Button data-testid="cloud-id-generate-button" onClick={generateCloudId}>
        Generate
      </Button>
    </Group>
  );
}

const StyledInputGroupText = styled.div`
  border-right: none;
  background-color: ${getStyledThemeColor('gray150')};
  color: ${getStyledThemeColor('dark')};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  font-size: 15px;
  font-weight: 500;
`;
