import { createStyles, Text } from '@mantine/core';
import React from 'react';

import { BrowserURLControls } from './BrowserURLControls';

interface AddressBarProps {
  favicon: string;
  windowTitle: string;
  domain: string;
}

export function AddressBar({ favicon, windowTitle, domain }: AddressBarProps) {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <BrowserURLControls
        favicon={<img width={18} height={18} src={favicon} alt="favicon" />}
        windowTitle={
          <Text className={classes.windowTitle} truncate>
            {windowTitle}
          </Text>
        }
        domain={
          <Text className={classes.domain} truncate>
            {domain}
          </Text>
        }
      />
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.white,
  },
  windowTitle: {
    width: 67,
    fontSize: 11,
    color: theme.colors.gray[9],
  },
  domain: {
    fontSize: 13,
    color: theme.colors.gray[9],
  },
}));
