import {
  ActionIcon,
  createStyles,
  Group,
  Popover,
  TextInput,
  TextInputProps,
} from '@mantine/core';
import React from 'react';

import { ReactComponent as Send2 } from '@portals/icons/bold/send-2.svg';
import { ReactComponent as Add } from '@portals/icons/linear/add.svg';
import { suppressPropagation } from '@portals/utils';

import { InsightsList } from './InsightsList';

interface InsightsFooterProps {
  searchTerm: string;
  setSearchTerm: (value: string) => void;
  isLoading: boolean;
  onSubmit: (searchTerm: string) => void;
  showBaseInsightsAction: boolean;
}

export function InsightsFooter({
  searchTerm,
  setSearchTerm,
  onSubmit,
  isLoading,
  showBaseInsightsAction,
}: InsightsFooterProps) {
  const { classes, theme } = useStyles();

  return (
    <form
      className={classes.footer}
      onSubmit={suppressPropagation(() => onSubmit(searchTerm))}
    >
      <Group>
        {showBaseInsightsAction && (
          <Popover
            width={400}
            position="top-start"
            shadow="xl"
            radius="md"
            withArrow
            arrowOffset={20}
            arrowSize={20}
          >
            <Popover.Target>
              <ActionIcon
                variant="gradient"
                size={30}
                gradient={{
                  deg: 124,
                  from: '#9747FF',
                  to: theme.fn.themeColor('primary', 3),
                }}
              >
                <Add width={18} height={18} />
              </ActionIcon>
            </Popover.Target>

            <Popover.Dropdown>
              <InsightsList isLoading={isLoading} onSelectInsight={onSubmit} />
            </Popover.Dropdown>
          </Popover>
        )}
        <TextInput
          autoFocus
          radius="xl"
          placeholder="Type your message..."
          styles={inputStyles}
          disabled={isLoading}
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.target.value)}
        />
        <ActionIcon size="lg" color="gray" type="submit" loading={isLoading}>
          <Send2 />
        </ActionIcon>
      </Group>
    </form>
  );
}

const inputStyles: TextInputProps['styles'] = (theme) => ({
  root: { flexGrow: 1 },
  input: {
    paddingLeft: theme.spacing.md,
    borderColor: theme.colors.gray[3],
  },
});

const useStyles = createStyles((theme) => ({
  footer: {
    marginTop: 'auto',
  },
}));
