import { Button } from '@mantine/core';
import React from 'react';

import { DashboardContentLayout } from '@portals/framework';
import { useOpenRouteModal } from '@portals/framework/route-modals';
import { BadgeCell, NameAbbreviationCell, SmartTable } from '@portals/table';
import { TableColumn } from '@portals/types';

const COLUMNS: Array<TableColumn<{ name: string }>> = [
  {
    dataField: 'name',
    text: 'Provider',
    formatter: (_, { name }) => <NameAbbreviationCell name={name} />,
  },
  {
    dataField: 'status',
    text: 'Status',
    minWidth: 50,
    maxWidth: 50,
    formatter: () => <BadgeCell label="Connected" color="green" />,
  },
];

const DATA = [
  {
    name: 'Barton, Brooks & Goldner',
  },
];

export const CreditProviders = () => {
  const openRouteModal = useOpenRouteModal();

  return (
    <DashboardContentLayout pageTitle="Credit Providers">
      <SmartTable
        keyField="name"
        columns={COLUMNS}
        data={DATA}
        name="page.credit-providers"
        withGlobalSearch
        additionalActions={() => (
          <Button
            onClick={() => openRouteModal({ modalId: 'credit-providers' })}
            size="xs"
          >
            Connect Credit Provider
          </Button>
        )}
      />
    </DashboardContentLayout>
  );
};
