import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError } from '@portals/redux/actions/toastr';

import {
  MONITORING_CUSTOMERS_API_URL,
  monitoringQueryKeys,
} from './monitoring.constants';
import { CustomerType } from './monitoring.types';
import { useApiQuery } from '../../hooks';
import { ServerError } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils/common';

export function useSupportCenterCustomers(isLabIncluded = false) {
  return useApiQuery<Array<CustomerType>>(
    `${MONITORING_CUSTOMERS_API_URL}?include_lab=${isLabIncluded}`,
    isLabIncluded
      ? monitoringQueryKeys.customers.withLabs()
      : monitoringQueryKeys.customers.withoutLabs(),
    {
      staleTime: 0,
    }
  );
}

interface CustomerPortalToken {
  token: string;
}

export function useGetCustomerPortalToken() {
  const dispatch = useDispatch();

  const { url, options } = useRequestOptions({
    url: MONITORING_CUSTOMERS_API_URL,
    method: 'GET',
  });

  return useMutation<CustomerPortalToken, ServerError, { id: string }>({
    mutationFn: ({ id }) => {
      return fetchApiRequest(`${url}/${id}/get_customer_portal_token`, options);
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useGetCustomerPortalToken',
      baseUrl: `${MONITORING_CUSTOMERS_API_URL}/:id/get_customer_portal_token`,
      method: 'GET',
    },
  });
}
