import { createStyles, Group, Stack, Text } from '@mantine/core';
import React from 'react';

import { StoreListing } from '@portals/api/partners';
import { CURRENCIES_FLAGS } from '@portals/countries';
import { CurrencyInput } from '@portals/framework';
import { CurrencyCode } from '@portals/types';

import { mapStoreListingPricesToPaymentSettings } from '../../../../utils/store-listings.utils';

interface PaymentTabUsageBasedPricesProps {
  defaultCurrency: CurrencyCode;
  storeListing: StoreListing;
  paymentSettings: ReturnType<typeof mapStoreListingPricesToPaymentSettings>;
}

export function PaymentTabUsageBasedPrices({
  defaultCurrency,
  storeListing,
  paymentSettings,
}: PaymentTabUsageBasedPricesProps) {
  const { classes } = useStyles();

  const usageBasedPrices = paymentSettings.usage_based?.prices ?? [];

  const basePrices = paymentSettings.monthly?.prices ?? [];

  return (
    <Stack spacing="lg" py="md">
      {usageBasedPrices.map((price) => {
        const basePrice = basePrices.find(
          (basePrice) => basePrice.currency === price.currency
        );

        return (
          <Group key={price.currency}>
            <Group>
              <img
                width={30}
                height="auto"
                src={CURRENCIES_FLAGS[price.currency]}
                alt="country flag"
                className={classes.flag}
              />

              <Text size="sm">
                <span>{price.currency}</span>
                {defaultCurrency === price.currency && (
                  <Text component="span" color="blue_gray.3">
                    &nbsp;(default)
                  </Text>
                )}
              </Text>
            </Group>

            <Group spacing="xs" ml="auto">
              {basePrice && <Text color="gray.6">+</Text>}

              <Stack spacing="xs">
                {basePrice ? (
                  <Group spacing="xs">
                    <CurrencyInput
                      readOnly
                      currencyCode={basePrice.currency}
                      value={basePrice.amountInScu ?? undefined}
                    />

                    <Text color="gray.6"> / month</Text>
                  </Group>
                ) : null}

                <Group spacing="xs">
                  <CurrencyInput
                    readOnly
                    currencyCode={price.currency}
                    value={price.amountInScu ?? undefined}
                  />
                  <Text color="gray.6">
                    / {storeListing.product.usage_based_unit_name}
                  </Text>
                </Group>
              </Stack>
            </Group>
          </Group>
        );
      })}
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  flag: {
    borderRadius: theme.radius.xs,
  },
}));
