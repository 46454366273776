import {
  Stack,
  Text,
  Center,
  Paper,
  Button,
  LoadingOverlay,
} from '@mantine/core';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useUnsubscribeEmail } from '@portals/api/ui';

import { ReactComponent as UnsubsribeImage } from '../assets/img/Unsubscribe.svg';
import { ReactComponent as XyteLogo } from '../assets/img/xyte-logo.svg';
import { useCommonPortalConfig } from '../hooks/portal-config';
import { Page404 } from '../pages/auth/Page404';

export function UnsubscribeLayout() {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const portalConfig = useCommonPortalConfig();
  const [unsubscribed, setIsUnsubscribed] = useState(false);

  const unsubscribeEmail = useUnsubscribeEmail();

  // We must extract the email with a regex because the email might contain a '+' character
  // which is interpreted as a space by the URLSearchParams constructor
  // https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams#preserving_plus_signs
  const emailMatchResult = location.search.match(/email=([^&#]*)/i);

  const email = emailMatchResult?.[1] ?? null;
  const token = urlParams.get('token');
  const tenant_id = urlParams.get('tenant_id');

  const handleUnsubscribe = async () => {
    try {
      await unsubscribeEmail.mutateAsync({
        email,
        token,
        tenant_id,
      });

      setIsUnsubscribed(true);
    } catch (error) {
      console.error(error);
    }
  };

  if (!email || !token || !tenant_id) {
    return <Page404 />;
  }

  return (
    <>
      <LoadingOverlay
        visible={unsubscribeEmail.isLoading || portalConfig.isInitialLoading}
      />

      <Center maw={620} mx="auto" py={50}>
        <Paper withBorder>
          <Stack p={40} align="center" spacing={60}>
            {portalConfig.data?.design?.sign_in_page?.logo ? (
              <img
                src={portalConfig.data?.design?.sign_in_page?.logo}
                alt="logo"
                width={80}
                height="auto"
              />
            ) : (
              <XyteLogo width={80} height={20} />
            )}

            <UnsubsribeImage width={260} height={260} />

            {!unsubscribed ? (
              <>
                <Stack align="center">
                  <Text size={24} weight={600}>
                    Do you want to unsubscribe?
                  </Text>

                  <Text size="lg" color="gray.6">
                    If you unsubscribe, you will stop receiving emails from Xyte
                  </Text>
                </Stack>

                <Button
                  color="red"
                  fullWidth
                  data-testid="unsubscribe-button"
                  onClick={handleUnsubscribe}
                >
                  Unsubscribe
                </Button>
              </>
            ) : (
              <Stack align="center">
                <Text size={24} weight={600} data-testid="unsubscribe-success">
                  We are sad to see you go
                </Text>

                <Text size="lg" color="gray.6" align="center">
                  Your request to unsubscribe has been successfully processed.
                </Text>
              </Stack>
            )}
          </Stack>
        </Paper>
      </Center>
    </>
  );
}
