import {
  Button,
  Group,
  MantineProvider,
  Stack,
  useMantineTheme,
} from '@mantine/core';
import { useDebouncedValue, useElementSize } from '@mantine/hooks';
import { find, forEach, isEmpty } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';
import { Layout, Responsive, WidthProvider } from 'react-grid-layout';
import { useParams } from 'react-router-dom';

import {
  useNewDeviceModelWidgets,
  useNewUpdateDeviceModelWidgetsLayout,
  useWidgetColors,
} from '@portals/api/partners';
import { PermissionAccess, usePermissionAccess } from '@portals/framework';
import { ReactComponent as Add } from '@portals/icons/linear/add.svg';
import { useOpenModal } from '@portals/redux';
import { VerticalScrollBar } from '@portals/scrollbar';
import { AccessLevelEnum } from '@portals/types';

import { MonitoringDashboardEmptyState } from './MonitoringDashboardEmptyState';
import { generateWidgetsLayoutPerWidgetType } from './widget-layout-dimension-calculation.utils';
import { WidgetPreview } from './widget-preview/WidgetPreview';
import { AddDeviceWidgetModalProps } from '../../../../modals';

const ResponsiveGridLayout = WidthProvider(Responsive);

export function MonitoringDashboard() {
  const { id } = useParams<{ id?: string }>();
  const { canEdit } = usePermissionAccess();
  const { ref, width } = useElementSize();
  const [debouncedWidth] = useDebouncedValue(width, 150);
  const openModal = useOpenModal();
  const theme = useMantineTheme();

  const colors = useWidgetColors();

  const deviceModelWidgets = useNewDeviceModelWidgets(id);
  const updateWidgetsLayout = useNewUpdateDeviceModelWidgetsLayout(id);

  const widgets = useMemo(
    () =>
      (deviceModelWidgets.data || []).map(generateWidgetsLayoutPerWidgetType),
    [deviceModelWidgets.data]
  );

  const onLayoutChange = useCallback(
    (layout: Array<Layout>) => {
      const widgetsToUpdate = [];

      forEach((itemLayout) => {
        const widgetId = itemLayout.i;

        if (widgetId === 'placeholder') {
          return;
        }

        const currentWidget = find({ id: widgetId }, deviceModelWidgets.data);

        if (!currentWidget) return;

        const hasChanged =
          currentWidget?.config?.layout?.x !== itemLayout.x ||
          currentWidget?.config?.layout?.y !== itemLayout.y ||
          currentWidget?.config?.layout?.h !== itemLayout.h ||
          currentWidget?.config?.layout?.w !== itemLayout.w;

        if (hasChanged) {
          widgetsToUpdate.push({
            id: currentWidget.id,
            width: currentWidget.config.width,
            height: currentWidget.config.height,
            layout: itemLayout,
          });
        }
      }, layout);

      if (!isEmpty(widgetsToUpdate)) {
        updateWidgetsLayout.mutate(widgetsToUpdate);
      }
    },
    [deviceModelWidgets.data, updateWidgetsLayout]
  );

  const memoizedWidgets = useMemo(
    () =>
      widgets.map((item) => (
        <div key={item.i} data-grid={item.i}>
          <WidgetPreview
            id={item.i}
            name={item.name}
            config={item.config}
            modelId={id}
          />
        </div>
      )),
    [widgets, id]
  );

  return (
    <Stack h="100%" p={0} ref={ref} maw={1260}>
      <Group position="right">
        {isEmpty(widgets) ? null : (
          <PermissionAccess
            minAccessLevel={AccessLevelEnum.Edit}
            permissionKeys="models"
          >
            <Button
              data-testid="add-another-widget-button"
              leftIcon={<Add width={16} height={16} />}
              size="xs"
              onClick={() =>
                openModal<AddDeviceWidgetModalProps['data']>(
                  'AddDeviceWidgetModal',
                  {
                    modelId: id,
                  }
                )
              }
            >
              Add widget
            </Button>
          </PermissionAccess>
        )}
      </Group>

      <VerticalScrollBar
        style={{
          border: `1px solid ${theme.colors.gray[2]}`,
          backgroundColor: theme.colors.gray[0],
        }}
      >
        {isEmpty(widgets) ? (
          <MonitoringDashboardEmptyState>
            <PermissionAccess
              minAccessLevel={AccessLevelEnum.Edit}
              permissionKeys="models"
            >
              <Button
                leftIcon={<Add width={16} height={16} />}
                onClick={() =>
                  openModal<AddDeviceWidgetModalProps['data']>(
                    'AddDeviceWidgetModal',
                    {
                      modelId: id,
                    }
                  )
                }
                data-testid="add-new-widget-button"
              >
                Add widget
              </Button>
            </PermissionAccess>
          </MonitoringDashboardEmptyState>
        ) : (
          <MantineProvider
            theme={{
              ...theme,
              colors: { ...theme.colors, ...colors },
              primaryShade: 4,
              primaryColor: 'blue_accent',
            }}
          >
            <ResponsiveGridLayout
              breakpoint="lg"
              key={debouncedWidth}
              cols={{
                lg: 24,
                md: 24,
                sm: 24,
                xs: 24,
                xxs: 24,
              }}
              layouts={{
                lg: widgets,
              }}
              rowHeight={25}
              containerPadding={[15, 15]}
              margin={[16, 16]}
              className="layout"
              isResizable={canEdit('models')}
              isDraggable={canEdit('models')}
              onLayoutChange={onLayoutChange}
              measureBeforeMount
              useCSSTransforms={false}
            >
              {memoizedWidgets}
            </ResponsiveGridLayout>
          </MantineProvider>
        )}
      </VerticalScrollBar>
    </Stack>
  );
}
