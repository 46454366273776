import { Group, Stack, Text, Tooltip, useMantineTheme } from '@mantine/core';
import React, { useState } from 'react';

import { OrderType } from '@portals/api/partners';
import { NameAbbreviationAvatar } from '@portals/core';
import { useGenericContext } from '@portals/framework';
import { ReactComponent as Message2 } from '@portals/icons/bold/message-2.svg';
import { prettyTimeMoment } from '@portals/utils';

export function OrderPurchaseTooltip() {
  const theme = useMantineTheme();
  const order = useGenericContext<OrderType>();

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  return (
    <Tooltip
      withArrow
      arrowSize={20}
      multiline
      styles={{
        tooltip: {
          padding: theme.spacing.xl,
          backgroundColor: theme.white,
          width: 666,
          borderRadius: theme.spacing.md,
          filter: 'drop-shadow(-4px 4px 36px rgba(0, 2, 41, 0.1))',
        },
      }}
      label={
        <Stack>
          <Group>
            <NameAbbreviationAvatar
              name={order.approved_by?.name}
              radius="xl"
              size={40}
            />
            <Group spacing={6}>
              <Text color="gray.8" size="md">
                {order.approved_by?.name}
              </Text>
              <Text color="gray.5" size="md">
                {prettyTimeMoment(order.purchase_order?.approved_at)}
              </Text>
            </Group>
          </Group>
          <Text color="gray.7" size="md">
            {order.approved_by?.note}
          </Text>
        </Stack>
      }
    >
      <Message2
        onMouseEnter={() => setIsTooltipOpen(true)}
        onMouseLeave={() => setIsTooltipOpen(false)}
        color={isTooltipOpen ? theme.colors.gray[6] : theme.colors.gray[4]}
      />
    </Tooltip>
  );
}
