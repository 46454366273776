import { useUploadInvoice } from '@portals/api/partners';
import { UploadFileProps } from '@portals/framework/modals';
import { useOpenModal } from '@portals/redux';

import { ReviewReceiptModalProps } from '../../modals/ReviewReceiptModal';

export function useUploadReceipt(invoiceId: string) {
  const openModal = useOpenModal();
  const uploadReceipt = useUploadInvoice(invoiceId);

  const onReviewReceiptSuccess = (receiptUrl: string) => {
    try {
      uploadReceipt.mutateAsync(receiptUrl);
    } catch (err) {
      console.error(err);
    }
  };

  const onUploadFileSuccess = ([receiptUrl]: string[]) => {
    openModal<ReviewReceiptModalProps['data']>('ReviewReceiptModal', {
      invoiceId,
      receiptUrl,
      onSuccess: onReviewReceiptSuccess,
    });
  };

  return () =>
    openModal<UploadFileProps['data']>('UploadFile', {
      title: 'Upload receipt file',
      onSuccess: onUploadFileSuccess,
      dropzoneProps: {
        sx: { height: 350 },
        maxSize: 20 * 1024 * 1024,
      },
    });
}
