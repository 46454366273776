import { createStyles, Group, Stack, Text } from '@mantine/core';
import React from 'react';

import { NameAbbreviationAvatar } from '@portals/core';
import { ChatMessageType } from '@portals/types';
import { mediumDateFormatter, shortTimeFormatter } from '@portals/utils';

import { SystemChatMessage } from './SystemChatMessage';

interface MessageProps {
  message: ChatMessageType;
  chatOwner: string;
}

export function ChatMessage({ message, chatOwner }: MessageProps) {
  const isOwner = message.owner_type === chatOwner;

  const { classes } = useStyles(isOwner);
  const user = message.user
    ? { name: message.user, label: `(${message.owner})` }
    : { name: message.owner, label: 'Representative' };

  if (message.system) {
    return (
      <SystemChatMessage
        messageText={message.text}
        createdAt={message.created_at}
        user={message.user}
      />
    );
  }

  return (
    <Stack align={isOwner ? 'flex-end' : 'flex-start'}>
      <Group spacing="sm" className={classes.userRow}>
        <Text color="gray.4">{mediumDateFormatter(message.created_at)}</Text>

        <Group spacing={4}>
          <Text weight={700}>{user.name}</Text>
          <Text color="dimmed">{user.label}</Text>
        </Group>

        <NameAbbreviationAvatar
          size={40}
          radius="xl"
          name={message.user ?? ''}
          src={message.owner_logo}
        />
      </Group>

      <Stack spacing="xs" className={classes.messageBubble}>
        <Text className={classes.messageText}>{message.text}</Text>
        <Text size="xs" className={classes.messageTimestamp}>
          {shortTimeFormatter(message.created_at)}
        </Text>
      </Stack>
    </Stack>
  );
}

const useStyles = createStyles((theme, isOwner: boolean) => ({
  userRow: {
    flexDirection: isOwner ? 'row' : 'row-reverse',
  },
  messageBubble: {
    minWidth: 150,
    paddingInline: theme.spacing.xl,
    paddingBlock: theme.spacing.md,
    borderRadius: 24,
    backgroundColor: isOwner
      ? theme.fn.primaryColor()
      : theme.colors.blue_gray[0],
    wordBreak: 'break-word',

    ...(isOwner ? { borderTopRightRadius: 0 } : { borderTopLeftRadius: 0 }),
  },
  messageText: {
    color: isOwner ? theme.white : theme.colors.gray[9],
  },
  messageTimestamp: {
    alignSelf: 'flex-end',
    color: isOwner
      ? theme.fn.rgba(theme.colors.primary[0], 0.6)
      : theme.colors.blue_gray[4],
  },
}));
