import React from 'react';
import { useParams } from 'react-router-dom';

import { useFileInfoById } from '@portals/api/partners';
import { PageBreadcrumbsProps } from '@portals/core';
import { DashboardContentLayout } from '@portals/framework';

import { FileDetails } from './FileDetails';
import { FileInfosTable } from './FileInfosTable';

export function Files() {
  const { id: fileId } = useParams<{ id: string | undefined }>();

  const file = useFileInfoById(fileId);

  const pageTitle = fileId ? 'File Info' : 'Files';
  const subtitle = !fileId && 'Device Files';
  const breadcrumbs: PageBreadcrumbsProps['crumbs'] = fileId
    ? [{ label: 'Files', to: '/files' }, { label: file.data?.name || '' }]
    : [];

  return (
    <DashboardContentLayout
      pageTitle={pageTitle}
      subtitle={subtitle}
      breadcrumbs={breadcrumbs}
    >
      {fileId ? <FileDetails fileId={fileId} /> : <FileInfosTable />}
    </DashboardContentLayout>
  );
}
