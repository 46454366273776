import { createStyles } from '@mantine/core';
import React from 'react';
import { CardHeader, CardTitle } from 'reactstrap';

import { useDeviceModels } from '@portals/api/partners';
import { ModelType, RuleType } from '@portals/types';

import RulesList from './RulesList';

type RulesProps = {
  model?: ModelType;
  rules: Array<RuleType>;
  showHeader?: boolean;
  noFilters?: boolean;
  noExport?: boolean;
  exportParams: { isEnabled: boolean };
  noColumnsSelection?: boolean;
  noHeader?: boolean;
  noSort?: boolean;
};

function Rules({ model, rules, showHeader, ...tableProps }: RulesProps) {
  const { classes } = useStyles({ showHeader });
  const deviceModels = useDeviceModels();

  return (
    <div className={classes.container}>
      {showHeader && (
        <CardHeader>
          <CardTitle className="page-title">Rules</CardTitle>

          <h6 className="card-subtitle text-muted">Incident detection rules</h6>
        </CardHeader>
      )}

      <RulesList
        {...tableProps}
        rules={rules}
        device_models={deviceModels?.data}
        model={model}
      />
    </div>
  );
}

const useStyles = createStyles((theme, params: { showHeader?: boolean }) => ({
  container: {
    ...(params.showHeader
      ? {
          height: '100%',
          display: 'grid',
          gridTemplateRows: 'max-content 1fr',
        }
      : {}),
  },
}));

export default Rules;
