import { Textarea, TextareaProps } from '@mantine/core';
import React from 'react';

import { useDesignField, ValuePath } from './hooks';

interface TextareaFieldProps extends Omit<TextareaProps, 'value' | 'onChange'> {
  valuePath: ValuePath;
}

export function TextareaField({
  valuePath,
  ...textareaProps
}: TextareaFieldProps) {
  const { value, onChange } = useDesignField(valuePath);

  return (
    <Textarea
      value={value ?? ''}
      onChange={(event) => onChange(event.target.value)}
      {...textareaProps}
    />
  );
}
