import { ActionIcon, Box, createStyles, Group, Tooltip } from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { FC, useCallback, useMemo } from 'react';
import { useUpdateEffect } from 'react-use';

import { useNewRemoveDeviceModelWidget } from '@portals/api/partners';
import {
  CommonWidgetFormType,
  convertWidgetFormToWidgetProps,
  NewWidgetType,
  WidgetPropsMapType,
} from '@portals/device-widgets';
import { PermissionAccess, useConfirmationModal } from '@portals/framework';
import { ReactComponent as CloseX } from '@portals/icons/linear/close-x.svg';
import { ReactComponent as Edit } from '@portals/icons/linear/edit-2.svg';
import { useOpenModal } from '@portals/redux';
import { AccessLevelEnum } from '@portals/types';
import { suppressPropagation } from '@portals/utils';

import { WidgetPreviewEmptyState } from './WidgetPreviewEmptyState';
import { EditDeviceWidgetModalProps } from '../../../../../modals';
import { getDeviceWidgetMeta } from '../../../../../modals/devices-widgets/devices-widgets.constants';
import { getDeviceWidgetRenderer } from '../monitoring-dashboard.constants';

interface WidgetPreviewProps<TForm = CommonWidgetFormType>
  extends NewWidgetType<TForm> {
  modelId: string;
}

export function WidgetPreview<TForm = CommonWidgetFormType>(
  props: WidgetPreviewProps<TForm>
) {
  const { classes } = useStyles();

  const widgetMeta = getDeviceWidgetMeta(props.config.id);
  const removeWidget = useNewRemoveDeviceModelWidget(props.modelId);

  const { id, name, modelId, config } = props as WidgetPreviewProps<TForm>;

  const form = useForm({
    initialValues: config.fields,
  });

  const asyncConfirmationCheck = useConfirmationModal();
  const openModal = useOpenModal();

  const onRemoveWidget = useCallback(async () => {
    const isConfirmed = await asyncConfirmationCheck({
      description: `Widget "${name}" will be removed. Are you sure?`,
    });

    if (isConfirmed) removeWidget.mutate(id);
  }, [asyncConfirmationCheck, id, name, removeWidget]);

  useUpdateEffect(() => {
    form.setValues(config.fields);
  }, [config.fields]);

  const widgetProps = useMemo(() => {
    if (!widgetMeta?.id) return null;

    const widgetPropsConverter = convertWidgetFormToWidgetProps[widgetMeta.id];

    return widgetPropsConverter({
      formValues: form.values as any,
    });
  }, [form, widgetMeta?.id]);

  const Widget = useMemo(
    () =>
      widgetMeta?.id
        ? (getDeviceWidgetRenderer(widgetMeta.id) as FC<
            WidgetPropsMapType[typeof widgetMeta.id]
          >)
        : null,
    [widgetMeta]
  );

  if (!Widget || !widgetProps) {
    return <WidgetPreviewEmptyState onRemoveWidget={onRemoveWidget} />;
  }

  return (
    <Box pos="relative" h="100%" className={classes.container}>
      <PermissionAccess
        minAccessLevel={AccessLevelEnum.Edit}
        permissionKeys="models"
      >
        <Tooltip.Group openDelay={500} closeDelay={100}>
          <Group
            className={classes.actions}
            w="100%"
            position="right"
            spacing="xs"
          >
            <Tooltip label="Edit widget" withinPortal>
              <ActionIcon
                size="sm"
                color="gray.9"
                radius="xl"
                p={4}
                variant="filled"
                onClick={suppressPropagation(() =>
                  openModal<EditDeviceWidgetModalProps<TForm>['data']>(
                    'EditDeviceWidgetModal',
                    {
                      widget: props,
                      modelId,
                    }
                  )
                )}
              >
                <Edit />
              </ActionIcon>
            </Tooltip>

            <Tooltip label="Remove widget" withinPortal>
              <ActionIcon
                size="sm"
                color="gray.9"
                radius="xl"
                p={4}
                variant="filled"
                onClick={suppressPropagation(onRemoveWidget)}
              >
                <CloseX />
              </ActionIcon>
            </Tooltip>
          </Group>
        </Tooltip.Group>
      </PermissionAccess>

      <Box h="100%" sx={{ pointerEvents: 'none' }}>
        <Widget
          {...widgetProps}
          stackProps={{
            sx: (theme) => ({
              boxShadow: `0px 24px 36px rgba(13, 14, 48, 0.05)`,
              border: `1px solid ${theme.colors.gray[2]}`,
            }),
          }}
        />
      </Box>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    cursor: 'grab',

    ':active': {
      cursor: 'grabbing',
    },
  },
  actions: {
    position: 'absolute',
    top: 0,
    zIndex: 1,
    transform: 'translateY(-50%)',
  },
}));
