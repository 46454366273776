import { Box, Button, createStyles, Group, Paper, Text } from '@mantine/core';
import React, { forwardRef, PropsWithChildren } from 'react';

import { ReactComponent as Export2 } from '@portals/icons/linear/export-2.svg';

export const DevCenterSection = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{
    title: string;
    subtitle: string;
    id: string;
    docsLink?: string;
  }>
>(({ title, subtitle, id, docsLink, children }, ref) => {
  const { classes } = useStyles();

  return (
    <Paper radius="md" withBorder p="lg" id={id} ref={ref}>
      <Group position="apart">
        <Text size="lg" mb={4}>
          {title}
        </Text>
        {docsLink && (
          <Button
            component="a"
            variant="outline"
            className={classes.docsLink}
            href={docsLink}
            target="_blank"
            rightIcon={<Export2 width={16} height={16} />}
          >
            Documentation
          </Button>
        )}
      </Group>
      <Text color="dimmed">{subtitle}</Text>

      <Box pt="md">{children}</Box>
    </Paper>
  );
});

const useStyles = createStyles(() => ({
  docsLink: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));
