import { Flex, Stack, Text } from '@mantine/core';
import React from 'react';

import emptyStateSrc from './empty-state.svg';

export function BrandingEmptyState() {
  return (
    <Flex direction="column" justify="center" align="center" gap="xxl" h="100%">
      <img width={350} height="auto" src={emptyStateSrc} alt="empty state" />

      <Stack spacing="xs" ta="center">
        <Text color="gray.8">Bring your brand to life</Text>
        <Text color="gray.5">
          Select a category from the left panel to start customizing your brand.
          <br />
          Your changes will appear here in the preview.
        </Text>
      </Stack>
    </Flex>
  );
}
