import {
  Group,
  Input,
  Loader,
  Text,
  TextInput,
  TextInputProps,
} from '@mantine/core';
import React, { useState } from 'react';

import { GroupIconNames } from './group-icon/group-icons';
import { GroupIconPicker } from './group-icon/GroupIconPicker';

interface GroupNameAndIconProps {
  groupName: string;
  onChangeGroupName: (groupName: string) => void;
  iconName: GroupIconNames;
  onChangeIconName: (iconName: GroupIconNames) => void;
  error: boolean;
  disabled: boolean;
  onBlur?: TextInputProps['onBlur'];
  isLoading?: boolean;
}

export function GroupNameAndIcon({
  groupName,
  onChangeGroupName,
  iconName,
  onChangeIconName,
  error,
  disabled,
  onBlur,
  isLoading,
}: GroupNameAndIconProps) {
  return (
    <Group>
      <Input.Wrapper>
        <Input.Label>
          <Text span size="lg" color="gray.8" weight={500}>
            Group name
          </Text>{' '}
          <Text span color="red">
            *
          </Text>
        </Input.Label>

        <Group>
          <TextInput
            disabled={disabled}
            autoFocus
            data-autofocus
            w={330}
            required
            error={error}
            placeholder="Name your group"
            data-testid="group-name-input"
            value={groupName}
            onBlur={onBlur}
            rightSection={isLoading && <Loader size="xs" />}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangeGroupName(e.currentTarget.value)
            }
          />

          <GroupIconPicker
            iconName={iconName}
            onChange={onChangeIconName}
            isLoading={isLoading}
          />
        </Group>

        {error ? (
          <Input.Error mt="xs">Group name is required</Input.Error>
        ) : null}
      </Input.Wrapper>
    </Group>
  );
}

export function useGroupNameAndIcon() {
  const [groupName, setGroupName] = useState('');
  const [iconName, setIconName] = useState<GroupIconNames>('crown');
  const [isGroupNameError, setIsGroupNameError] = useState(false);

  return {
    groupName,
    setGroupName,

    iconName,
    setIconName,

    isGroupNameError,
    setIsGroupNameError,
  };
}
