import {
  Button,
  Divider,
  Group,
  LoadingOverlay,
  Modal,
  Select,
  Stack,
  Textarea,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React from 'react';
import { useDispatch } from 'react-redux';

import {
  useCreatePartner,
  UseCreatePartnerParams,
} from '@portals/api/partners';
import { ModalProps } from '@portals/framework';
import { switchTenant } from '@portals/redux/actions/auth';
import { TenantType } from '@portals/types';

interface CreatePartnerProps extends ModalProps {}

type FormValues = Omit<UseCreatePartnerParams, 'is_internal_tenant'> & {
  tenantType: string;
};

export function CreatePartner({ closeMe }: CreatePartnerProps) {
  const dispatch = useDispatch();
  const createPartner = useCreatePartner();

  const form = useForm<FormValues>({
    initialValues: {
      display_name: '',
      name: '',
      emails: '',
      admin_contact_email: '',
      admin_contact_name: '',
      finance_contact_email: '',
      finance_contact_name: '',
      tenantType: '',
    },
  });

  const onSubmit = async (values: typeof form.values) => {
    try {
      const { tenantType, ...formValues } = values;
      const newTenant = await createPartner.mutateAsync({
        ...formValues,
        is_internal_tenant: tenantType === 'demo',
      });

      dispatch(
        switchTenant(
          { id: newTenant.id, name: newTenant.name },
          TenantType.Partner
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal opened onClose={closeMe} centered title="Create a new Partner">
      <LoadingOverlay visible={createPartner.isLoading} />

      <form onSubmit={form.onSubmit(onSubmit)}>
        <Stack spacing="md">
          <TextInput
            {...form.getInputProps('name')}
            required
            label="Name"
            placeholder="Lower case name, without spaces"
          />

          <TextInput
            {...form.getInputProps('display_name')}
            required
            label="Display Name"
            placeholder="User friendly display name"
          />

          <Textarea
            {...form.getInputProps('emails')}
            required
            label="Admins Emails"
            placeholder="Comma-seperated list of emails"
            rows={6}
          />

          <Divider color="gray.2" />

          <TextInput
            {...form.getInputProps('admin_contact_email')}
            required
            label="Admin Contact Email"
            type="email"
          />

          <TextInput
            {...form.getInputProps('admin_contact_name')}
            required
            label="Admin Contact Name"
          />

          <Divider color="gray.2" />

          <TextInput
            {...form.getInputProps('finance_contact_email')}
            required
            label="Finance Contact Email"
            type="email"
          />

          <TextInput
            {...form.getInputProps('finance_contact_name')}
            required
            label="Finance Contact Name"
          />

          <Divider color="gray.2" />

          <Select
            {...form.getInputProps('tenantType')}
            required
            label="Tenant type"
            placeholder="Select tenant type"
            data={[
              { label: 'Demo', value: 'demo' },
              { label: 'Customer', value: 'customer' },
            ]}
          />

          <Group py="lg" position="right">
            <Button variant="default" onClick={closeMe}>
              Cancel
            </Button>

            <Button type="submit" disabled={!form.isValid}>
              Create
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
}
