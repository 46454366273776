import { Box, Button, createStyles, Group, Stack, Text } from '@mantine/core';
import { isEmpty } from 'lodash/fp';
import React from 'react';

import { OrderType, PartnerOrderItemType } from '@portals/api/partners';
import { ProductImage, useGenericContext } from '@portals/framework';
import { ProductPricingModel } from '@portals/types';
import { formatCurrency } from '@portals/utils';

interface OrderProductBreakdownRowProps {
  orderItemId: string;
  onSelect: () => void;
  isSelected: boolean;
  withSerialNumber: boolean;
  withSku: boolean;
  withBasePrice: boolean;
  sn: string | null;
  numOfGridMiddleColumns: number;
}

export function OrderProductBreakdownRow({
  orderItemId,
  onSelect,
  isSelected,
  withSerialNumber,
  withSku,
  withBasePrice,
  sn,
  numOfGridMiddleColumns,
}: OrderProductBreakdownRowProps) {
  const { classes, cx } = useStyles(numOfGridMiddleColumns);
  const order = useGenericContext<OrderType>();

  if (!order || isEmpty(order?.items)) return null;

  const orderItem = order.items.find((item) => item.id === orderItemId);

  if (!orderItem) return null;

  const getInterval = (
    paymentInterval: PartnerOrderItemType['payment_interval']
  ) => {
    if (paymentInterval === 'monthly') return ' / month';
    else if (paymentInterval === 'yearly') return ' / year';

    return '';
  };

  return (
    <Box
      className={cx(classes.container, { selected: isSelected })}
      onClick={onSelect}
    >
      <Group noWrap spacing="sm" p={0}>
        <ProductImage
          src={orderItem.product.image_url}
          radius="md"
          height={34}
          width="auto"
        />

        <Text size="sm" color="gray.7">
          {orderItem.product.name}
        </Text>
      </Group>

      <Stack justify="center">
        <Text size="sm" color="gray.5" transform="capitalize">
          {orderItem.product.device_model_name}
        </Text>
      </Stack>

      {withSerialNumber ? (
        <Stack justify="center">
          <Text size="sm" color="gray.5" transform="capitalize">
            {sn || 'N/A'}
          </Text>
        </Stack>
      ) : null}

      {withSku ? (
        <Stack justify="center">
          <Text size="sm" color="gray.5">
            {orderItem.product.sku || 'N/A'}
          </Text>
        </Stack>
      ) : null}

      {withBasePrice && (
        <Stack justify="center">
          {orderItem.usage_based?.base_price_in_scu ? (
            <Text size="sm" color="gray.5">
              {formatCurrency(
                orderItem.usage_based.base_price_in_scu,
                order.currency
              )}{' '}
              / month
            </Text>
          ) : null}
        </Stack>
      )}

      <Stack justify="center">
        {orderItem.product.pricing_model === ProductPricingModel.UsageBased ? (
          <Text size="sm" color="gray.5">
            {formatCurrency(
              orderItem.usage_based?.usage_price_in_scu,
              order.currency
            )}{' '}
            / {orderItem.product.usage_based_unit_name}
          </Text>
        ) : (
          <Text size="sm" color="gray.5">
            {formatCurrency(orderItem.price_in_scu, order.currency)}
            {getInterval(orderItem.payment_interval)}
          </Text>
        )}
      </Stack>

      <Stack justify="center">
        {isSelected ? null : (
          <Button size="xs" variant="light" radius="xl">
            Show details
          </Button>
        )}
      </Stack>
    </Box>
  );
}

const useStyles = createStyles((theme, numOfGridMiddleColumns: number) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: `2fr repeat(${numOfGridMiddleColumns}, 1.5fr) 100px`,
    transition: 'all 0.15s ease-in-out',
    gap: theme.spacing.sm,
    paddingBlock: theme.spacing.sm,
    paddingInline: theme.spacing.xs,

    '&:not(:first-of-type)': {
      borderTop: `1px solid ${theme.colors.gray[2]}`,
    },

    '&:hover': {
      backgroundColor: theme.colors.gray[0],
    },

    '&.selected': {
      backgroundColor: theme.colors.blue_accent[0],
    },
  },
}));
