import { createEmotionCache, MantineProvider } from '@mantine/core';
import React, { ReactNode } from 'react';

import { THEME } from './theme';

const emotionCache = createEmotionCache({
  key: 'mantine',
  prefix: false,
  stylisPlugins: [],
});

export function ThemeProvider({ children }: { children: ReactNode }) {
  return (
    <MantineProvider emotionCache={emotionCache} theme={THEME}>
      {children}
    </MantineProvider>
  );
}
