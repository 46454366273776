import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { QueryOptions } from '../types';
import { fetchApiRequest, useRequestOptions } from '../utils';

export function useApiQuery<T>(
  url: string,
  key: string[],
  queryOptions: QueryOptions<T> = {}
): UseQueryResult<T> {
  const { url: adjustedUrl, options } = useRequestOptions({
    url,
  });

  return useQuery({
    queryKey: key,
    queryFn: () => fetchApiRequest(adjustedUrl, options),
    // Adding meta for better error handling
    meta: {
      baseUrl: adjustedUrl,
      method: options?.method || 'GET',
    },
    ...queryOptions,
  });
}
