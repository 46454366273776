import { Divider, Stack, Text } from '@mantine/core';
import { Prism } from '@mantine/prism';
import { get } from 'lodash/fp';
import React from 'react';
import { connect } from 'react-redux';

import { MethodType, StateType } from '@portals/types';

type TestCommandProps = {
  url: string;
  data?: any;
  method?: MethodType;
  noAuth?: boolean;
  provisionServer?: boolean;
  contentType?: string;
};

type TestCommandConnectedProps = {
  auth: StateType['data']['dev']['auth'];
};

function TestCommand({
  auth,
  url,
  data,
  method = 'POST',
  noAuth = false,
  provisionServer = false,
  contentType = 'application/json',
}: TestCommandProps & TestCommandConnectedProps) {
  const serverURL = provisionServer
    ? process.env.NX_DEVICE_HUB_URL
    : auth.hub_url;

  let command = `curl ${serverURL}/v1/devices${url} \\
      -H "Content-Type: ${contentType}"`;

  if (!noAuth) {
    command += ` \\
      -H "Authorization: ${auth.access_key}"`;
  }

  if (data) {
    command += ` \\
      -X ${method} \\
      --data '${JSON.stringify(data)}'
    `;
  }

  return (
    <Stack spacing="xs">
      <Divider color="gray" label="OR" labelPosition="center" />

      <Text size="md" weight={500}>
        Try this command using bash / zsh / etc:
      </Text>

      <Prism language="bash" data-testid="bash-command">
        {command}
      </Prism>
    </Stack>
  );
}

const mapStateToProps = (state) => ({
  auth: get('data.dev.auth', state),
});

export default connect<TestCommandConnectedProps>(mapStateToProps)(TestCommand);
