import { Funnel } from '@nivo/funnel';
import React from 'react';
import { AutoSizer } from 'react-virtualized';
import styled from 'styled-components';

import { useTheme } from '@portals/ui';
import { getStyledThemeColor, hexToRgba } from '@portals/utils';

import { StatCardContainer } from './common';
import { ReactComponent as ClaimedDevicesIcon } from '../../assets/img/claimed-devices.svg';
import { ReactComponent as OnlineDevicesIcon } from '../../assets/img/online-devices.svg';
import { ReactComponent as RegisteredDevicesIcon } from '../../assets/img/registered-devices.svg';

const DevicesFunnel = ({ stats }) => {
  const { color } = useTheme();
  const data = [
    {
      label: 'Registered Devices',
      id: 'registered',
      value: stats.connected_new + stats.connected_old,
    },
    {
      label: 'Claimed Devices',
      id: 'claimed',
      value: stats.claimed_new + stats.claimed_old,
    },
    {
      label: 'Claimed Online Devices',
      id: 'online',
      value: stats.claimed_online_new + stats.claimed_online_old,
    },
  ];

  return (
    <Container>
      <div className="header">
        <div className="title">Devices Funnel</div>
      </div>

      <div className="chart-info">
        <div className="chart-names">
          <div>Registered Devices</div>
          <div>Claimed Devices</div>
          <div>Claimed Online Devices</div>
        </div>

        <div className="chart-labels">
          <div className="chart-label">
            <RegisteredDevicesIcon className="mr-4" />

            <div className="stat-info">
              <div className="stat-value">
                {stats.connected_new + stats.connected_old}
              </div>

              <div>+{stats.connected_new} in last 30 days</div>
            </div>
          </div>
          <div className="chart-label">
            <ClaimedDevicesIcon className="mr-4" />

            <div className="stat-info">
              <div className="stat-value">
                {stats.claimed_new + stats.claimed_old}
              </div>

              <div>+{stats.claimed_new} in last 30 days</div>
            </div>
          </div>
          <div className="chart-label">
            <OnlineDevicesIcon className="mr-4" />

            <div className="stat-info">
              <div className="stat-value">
                {stats.claimed_online_new + stats.claimed_online_old}
              </div>

              <div>+{stats.claimed_online_new} in last 30 days</div>
            </div>
          </div>
        </div>
      </div>

      <div className="chart-wrapper">
        <AutoSizer>
          {({ width, height }) => (
            <Funnel
              width={width}
              height={height}
              data={data}
              margin={{ top: 20, right: 0, bottom: 40, left: 0 }}
              direction="horizontal"
              fillOpacity={0.5}
              colors={[
                hexToRgba(color.green, 75),
                hexToRgba(color.green, 50),
                hexToRgba(color.green, 25),
              ]}
              beforeSeparatorLength={40}
              afterSeparatorLength={0}
              borderWidth={5}
              enableLabel={false}
              labelColor={color.dark}
              currentPartSizeExtension={10}
              currentBorderWidth={10}
              motionConfig="gentle"
            />
          )}
        </AutoSizer>
      </div>
    </Container>
  );
};

const Container = styled(StatCardContainer)`
  height: 400px;
  grid-template-rows: max-content max-content 1fr;
  grid-template-columns: 1fr;
  position: relative;

  .chart-info {
    padding-top: 20px;
    margin-bottom: 10px;

    .chart-names {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      color: ${getStyledThemeColor('gray600')};

      > div {
        display: flex;
        align-items: center;
        font-size: 16px;
        padding-left: 5px;
      }
    }

    .chart-labels {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      .chart-label {
        display: flex;
        align-items: center;
        padding-left: 5px;
        transform: translateY(20px);

        .stat-info {
          .stat-value {
            color: ${getStyledThemeColor('dark')};
            font-size: 24px;
            line-height: 24px;
            font-weight: bold;
          }
        }
      }
    }
  }

  .chart-wrapper {
    width: 100%;
    height: 100%;

    svg {
      path {
        filter: drop-shadow(0px 2px 2px ${getStyledThemeColor('gray400')});
      }
    }
  }
`;

export default DevicesFunnel;
