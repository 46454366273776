import { LoadingOverlay } from '@mantine/core';
import { isEmpty } from 'lodash/fp';
import React, { FC, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';

import { PartnerIncidentType } from '@portals/api/partners';
import { useItemsVirtualization } from '@portals/framework/infinite-scroll';
import { EmptyState } from '@portals/table';
import { PaginatedQueryParamsType, TicketType } from '@portals/types';

import { ItemType, TicketOrIncidentItem } from './TicketOrIncidentItem';

interface ListProps {
  itemsParams: PaginatedQueryParamsType<PartnerIncidentType | TicketType>;
  endpointUrl: string;
  itemsKey: string;
  emptyStateLabel: string;
  canViewDetails: boolean;
  itemType?: ItemType;
}

export const List: FC<ListProps> = ({
  itemsParams,
  endpointUrl,
  itemsKey,
  emptyStateLabel,
  itemType,
  canViewDetails,
}) => {
  const [selectedItemId, setSelectedItemId] = useState<null | string>(null);

  const {
    flattenItems,
    isFetching,
    virtualItems,
    ScrollContainerRef,
    containerStyle,
  } = useItemsVirtualization<PartnerIncidentType | TicketType>(
    itemsParams,
    endpointUrl,
    itemsKey,
    (item) => (
      <TicketOrIncidentItem
        item={item}
        canViewDetails={canViewDetails}
        itemType={itemType}
        onSelectItemId={setSelectedItemId}
        selectedItemId={selectedItemId}
      />
    )
  );

  if (isEmpty(flattenItems) && isFetching) {
    return <LoadingOverlay visible />;
  }

  if (isEmpty(flattenItems) && !isFetching)
    return <EmptyState label={emptyStateLabel} />;

  return (
    <Scrollbars
      autoHide
      hideTracksWhenNotNeeded
      key="table-scroll-wrapper"
      ref={(node: (Scrollbars & { view: HTMLElement }) | null) => {
        if (!node) return;

        ScrollContainerRef.current = node.view;
      }}
    >
      <div style={containerStyle}>{virtualItems}</div>
    </Scrollbars>
  );
};
