import { Group, Text, Tooltip, useMantineTheme } from '@mantine/core';
import React from 'react';

import { SubscriptionDetailsType } from '@portals/api/partners';
import { CopyToClipboard } from '@portals/core';
import {
  DetailsList,
  DetailsListProps,
  PartnerInvoicePaymentStatusBadge,
} from '@portals/framework';
import { RouteModalLink } from '@portals/framework/route-modals';
import { ReactComponent as InfoCircleIcon } from '@portals/icons/linear/info-circle.svg';
import { ProductPricingModel } from '@portals/types';
import {
  formatPrice,
  getPaymentMethodDisplayName,
  getProductPriceDisplayText,
  mediumDateFormatter,
} from '@portals/utils';

interface OverviewTabProps {
  subscription: SubscriptionDetailsType | undefined;
}

export function OverviewTab({ subscription }: OverviewTabProps) {
  const theme = useMantineTheme();

  if (!subscription) return null;

  const priceText = getProductPriceDisplayText({
    pricingModel: subscription.product.pricing_model,
    currency: subscription.currency,
    usageBasedPriceInScu: subscription.usage_based?.usage_based_price_in_scu,
    usageBasedUnitName: subscription.product.usage_based_unit_name,
    amountInScu: subscription.amount_in_scu,
    paymentInterval: subscription.payment_interval,
  });

  const isUsageBased =
    subscription.product.pricing_model === ProductPricingModel.UsageBased;
  const detailsListItems: DetailsListProps['items'] = [];

  if (subscription.usage_based?.base_price_in_scu) {
    detailsListItems.push({
      label: 'Base price',
      value: formatPrice({
        value: subscription.usage_based.base_price_in_scu,
        currencyCode: subscription.currency,
      }),
    });
  }

  detailsListItems.push(
    {
      label: isUsageBased
        ? `Price per ${subscription.product.usage_based_unit_name}`
        : 'Price',
      value: priceText,
    },
    {
      label: 'Order number',
      value: (
        <Group spacing="xs">
          <RouteModalLink
            modalId="order"
            pathParams={[subscription.order_id]}
            target="_blank"
          >
            {subscription.initial_order_short_id}
          </RouteModalLink>
          <CopyToClipboard value={subscription.initial_order_short_id} />
        </Group>
      ),
    },
    {
      label: 'Date placed',
      value: mediumDateFormatter(subscription.created_at),
    },
    {
      label: 'Next billing date',
      value: mediumDateFormatter(subscription.next_charge_at, '--'),
    }
  );

  if (subscription.canceled_scheduled_at) {
    detailsListItems.push({
      label: 'Cancellation date',
      value: mediumDateFormatter(subscription.canceled_scheduled_at),
    });
  }

  if (
    subscription.cancellation_details &&
    (subscription.status === 'canceled' ||
      subscription.status === 'pending_cancellation')
  ) {
    detailsListItems.push({
      label: 'Canceled by',
      value: (
        <Group spacing="xs" noWrap>
          <Text tt="capitalize">
            {subscription.cancellation_details.initiator}
          </Text>

          {subscription.cancellation_details.initiator === 'seller' && (
            <Tooltip
              withArrow
              label={`Canceled by ${subscription.cancellation_details.name}`}
            >
              <InfoCircleIcon
                width={16}
                height={16}
                color={theme.colors.gray[5]}
              />
            </Tooltip>
          )}
        </Group>
      ),
    });
  }

  detailsListItems.push(
    {
      label: 'Payment method',
      value: subscription
        ? getPaymentMethodDisplayName(subscription?.payment_method)
        : null,
    },
    {
      label: 'Customer',
      value: subscription?.organization?.display_name,
    },
    {
      label: 'ID',
      value: subscription?.id,
    },
    {
      label: 'Last invoice status',
      value: (
        <PartnerInvoicePaymentStatusBadge
          status={subscription?.latest_invoice?.status}
        />
      ),
    }
  );

  return <DetailsList items={detailsListItems} />;
}
