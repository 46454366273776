import { Button, Group, Stack, TextInput } from '@mantine/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Response from '../../components/Response';
import TestCommand from '../../components/TestCommand';
import { closeIncident, useGetApiDevice } from '../../redux/actions/api';

export function CloseIncident() {
  const [incidentId, setIncidentId] = useState('');

  const dispatch = useDispatch();
  const access = useGetApiDevice();

  return (
    <Stack>
      <TextInput
        placeholder="Incident ID"
        onChange={(event) => setIncidentId(event.target.value)}
        value={incidentId}
      />

      <Group>
        <Button
          onClick={() => dispatch(closeIncident(incidentId, access.id))}
          disabled={!access.id}
        >
          Delete
        </Button>
        <span>
          Device ID:
          {access.id || 'Not set'}
        </span>
      </Group>

      {access.id && (
        <TestCommand
          url={`/${access.id}/incidents/${incidentId}`}
          method="DELETE"
          data={{
            id: access.id,
          }}
        />
      )}

      <Response type="closeIncident" />
    </Stack>
  );
}
