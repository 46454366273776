import { LoadingOverlay } from '@mantine/core';
import { get } from 'lodash/fp';
import React, { FC, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import styled from 'styled-components';

import { useDeviceModel } from '@portals/api/partners';
import { useIsPending } from '@portals/redux';
import { StateType } from '@portals/types';
import { DeviceName } from '@portals/ui';
import { getStyledThemeColor } from '@portals/utils';

import EditDevice from '../../components/EditDevice';
import { getTicketDevice } from '../../redux/actions/ticket_device';

interface DeviceDetailsProps {
  ticket: any;
}

const DeviceDetails: FC<DeviceDetailsProps & PropsFromRedux> = ({
  ticket,
  device,
  getTicketDevice,
}) => {
  const fetchingDevice = useIsPending(`getTicketDevice_${ticket.id}`);
  const model = useDeviceModel(device?.model?.id);

  useEffect(() => {
    getTicketDevice(ticket.id);
  }, [ticket.id, getTicketDevice]);

  if (!device || !model.isFetched) {
    return <LoadingOverlay visible />;
  }

  return (
    <Container>
      <LoadingOverlay visible={fetchingDevice || model.isInitialLoading} />

      <div className="device-title p-2">
        <DeviceName name={device.name || device.model?.name + ' - Unnamed'} />
      </div>

      <EditDevice device={device} model={model.data} ticket={ticket} />
    </Container>
  );
};

const Container = styled.div`
  border: 1px solid ${getStyledThemeColor('gray300')};
  display: grid;
  grid-template-rows: max-content 1fr;
  min-height: 700px;

  .device-title {
    background-color: ${getStyledThemeColor('gray150')};
    font-size: 30px;
    margin-bottom: 15px;
  }
`;

const mapStateToProps = (state: StateType, ownProps: DeviceDetailsProps) => ({
  device: get(['data', 'ticket_device', ownProps.ticket.id], state),
});

const connector = connect(mapStateToProps, {
  getTicketDevice,
});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(DeviceDetails);
