import { SegmentedControl, SegmentedControlItem } from '@mantine/core';
import React from 'react';

import { useDesignField, ValuePath } from './hooks';

interface SegmentedControlFieldProps {
  valuePath: ValuePath;
  options: Array<SegmentedControlItem>;
}

export function SegmentedControlField({
  valuePath,
  options,
}: SegmentedControlFieldProps) {
  const { value, onChange } = useDesignField(valuePath);

  return <SegmentedControl value={value} data={options} onChange={onChange} />;
}
