import { Text } from '@mantine/core';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { AuditLogType } from '@portals/types';

interface PublishModelProps {
  auditLog: AuditLogType;
}

export const PublishModel: FC<PublishModelProps> = ({ auditLog }) => (
  <Text size="sm">
    Published {auditLog?.params[0]?.model}:{' '}
    <Text<typeof Link>
      size="sm"
      component={Link}
      to={`/models/${auditLog?.params[0]?.id}`}
      color="blue"
      underline
    >
      {auditLog?.params[0]?.display_name}
    </Text>
  </Text>
);
