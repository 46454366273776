import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { useWithSupportCenter } from '@portals/api/partners';
import { getStyledThemeColor } from '@portals/utils';

import { ReactComponent as CustomersIcon } from '../../assets/img/customers.svg';
import { ReactComponent as IncidentsIcon } from '../../assets/img/incidents.svg';
import { ReactComponent as LicenseIcon } from '../../assets/img/licenses.svg';

const STATS_KEYS = ['total_customers', 'total_tickets', 'total_licenses'];

const STATS_MAP = {
  total_customers: {
    label: 'Customers',
    icon: <CustomersIcon />,
    route: '/customers',
  },
  total_tickets: {
    label: 'Support Tickets',
    icon: <IncidentsIcon />,
    route: '/tickets',
  },
  total_licenses: {
    label: 'Licenses',
    icon: <LicenseIcon />,
    route: '/store-management/licenses',
  },
};

const StatsCards = ({ stats }) => {
  const withSupportCenter = useWithSupportCenter();

  return (
    <Container>
      {STATS_KEYS.map((statKey) => {
        if (!withSupportCenter && statKey === 'total_tickets') return null;

        const { label, icon, route } = STATS_MAP[statKey];

        return (
          <Box key={statKey} to={route}>
            <div className="stat-icon">{icon}</div>

            <div className="stat-info">
              <div className="stat-value">{stats[statKey] || 0}</div>
              <div className="stat-label">{label}</div>
            </div>
          </Box>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 25px;
`;

const Box = styled(Link)`
  background: ${getStyledThemeColor('white')};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04), 0 0 2px rgba(0, 0, 0, 0.06),
    0 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  height: 130px;
  padding-left: 40px;
  transition: box-shadow 0.15s ease-in-out;
  display: flex;
  align-items: center;

  .stat-icon {
    svg {
      margin-right: 25px;
    }
  }

  .stat-info {
    .stat-value {
      color: ${getStyledThemeColor('dark')};
      font-size: 24px;
      font-weight: bold;
    }

    .stat-label {
      color: ${getStyledThemeColor('dark')};
    }
  }

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 0 2px rgba(0, 0, 0, 0.1),
      0 0 1px rgba(0, 0, 0, 0.1);
    text-decoration: none;
  }
`;

export default StatsCards;
