import { Box, createStyles, Group, Text } from '@mantine/core';
import React from 'react';

interface LegendLabelProps {
  label: string;
  color: string | undefined;
  onClick: VoidFunction;
  disabled: boolean;
}

export function LegendLabel({
  label,
  color,
  disabled,
  onClick,
}: LegendLabelProps) {
  const { classes } = useStyles();

  return (
    <Group
      spacing="xs"
      noWrap
      onClick={onClick}
      opacity={disabled ? 0.5 : 1}
      className={classes.container}
    >
      <Box className={classes.indicator} bg={color} />

      <Text size="xs" inline color={color} truncate>
        {label}
      </Text>
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    cursor: 'pointer',
  },
  indicator: {
    width: 21,
    height: 8,
    borderRadius: theme.radius.xs,
  },
}));
