import { Button } from '@mantine/core';
import React from 'react';

import { OrderType } from '@portals/api/partners';
import { usePermissionAccess } from '@portals/framework';
import { ReactComponent as BoxIcon } from '@portals/icons/linear/box.svg';
import { ReactComponent as TickCircleIcon } from '@portals/icons/linear/tick-circle.svg';
import { useOpenModal } from '@portals/redux';

import { CreateShipmentModalProps } from '../../modals';

interface OrderShipmentStatusChangeProps {
  order: OrderType;
  onChange: (newStatus: 'ready' | 'shipped' | 'delivered') => void;
}

export function OrderShipmentStatusChange({
  order,
  onChange,
}: OrderShipmentStatusChangeProps) {
  const { canEdit } = usePermissionAccess();
  const openModal = useOpenModal();

  if (!canEdit('fulfillment')) return null;

  if (
    order.payment_status === 'refunded' &&
    order.fulfillment_status !== 'shipped'
  ) {
    return null;
  }

  switch (order.fulfillment_status) {
    case 'order_placed':
    case 'missing_information':
      return null;

    case 'processing':
      return (
        <Button
          leftIcon={<BoxIcon width={16} height={16} />}
          data-testid="create-shipment-button"
          onClick={() =>
            openModal<CreateShipmentModalProps['data']>('CreateShipmentModal', {
              orderId: order.id,
            })
          }
        >
          Create Shipment
        </Button>
      );

    case 'ready':
      return (
        <Button
          leftIcon={<TickCircleIcon width={16} height={16} />}
          onClick={() => onChange('shipped')}
          data-testid="order-shipment-mark-as-shipped-button"
        >
          Mark as Shipped
        </Button>
      );

    case 'shipped':
      return (
        <Button
          leftIcon={<TickCircleIcon width={16} height={16} />}
          onClick={() => onChange('delivered')}
          data-testid="order-shipment-mark-as-delivered-button"
        >
          Mark as Delivered
        </Button>
      );

    default:
      return null;
  }
}
