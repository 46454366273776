import { MantineTheme } from '@mantine/core';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';
import { generateRelatedColors } from '@portals/utils';

import {
  PORTAL_CONFIG_URL,
  portalConfigQueryKeys,
} from './portal-config.constants';
import { PortalConfigType } from './portal-config.types';
import { useApiQuery } from '../../hooks';
import { QueryOptions, ServerError, ServerSuccess } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';

export function usePortalConfig(
  queryOptions: QueryOptions<PortalConfigType> = {}
) {
  return useApiQuery<PortalConfigType>(
    PORTAL_CONFIG_URL,
    portalConfigQueryKeys.base,
    queryOptions
  );
}

/*
  MUTATIONS
* */
export const useRequestDomainUpdate = () => {
  const { url, options } = useRequestOptions({
    url: `${PORTAL_CONFIG_URL}/request_domain_update`,
    method: 'POST',
  });

  return useMutation<ServerSuccess, ServerError, { domain: string }>({
    mutationFn: ({ domain }) =>
      fetchApiRequest(url, { ...options, body: JSON.stringify({ domain }) }),
  });
};

interface UseSendTestEmailParams {
  logo?: string;
  primary?: string;
  markdown?: string;
  site_url?: string;
  tertiary?: string;
  secondary?: string;
}

export function useSendTestEmail() {
  const dispatch = useDispatch();
  const { url, options } = useRequestOptions({
    url: `${PORTAL_CONFIG_URL}/test_email`,
    method: 'POST',
  });

  return useMutation<ServerSuccess, ServerError, UseSendTestEmailParams>({
    mutationFn: (emailSettings) =>
      fetchApiRequest(url, {
        ...options,
        body: JSON.stringify(emailSettings),
      }),
    onSuccess: () => {
      dispatch(toastrSuccess('Test email sent successfully'));
    },
    onError: (error) =>
      dispatch(toastrError('Failed to send a test email', error?.error)),
    meta: {
      mutationName: 'useSendTestEmail',
      baseUrl: `${PORTAL_CONFIG_URL}/test_email`,
      method: 'POST',
    },
  });
}

interface MutationFnParams {
  updatedConfig: Partial<PortalConfigType>;
  withSuccessNotification?: boolean;
}

export function useUpdatePortalConfig() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: PORTAL_CONFIG_URL,
    method: 'PUT',
  });

  return useMutation({
    mutationFn: ({
      updatedConfig,
    }: MutationFnParams): Promise<PortalConfigType> => {
      const queryData = queryClient.getQueryData<PortalConfigType>(
        portalConfigQueryKeys.base
      );

      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify({ ...queryData, ...updatedConfig }),
      });
    },
    onSuccess: (response: PortalConfigType, { withSuccessNotification }) => {
      queryClient.setQueryData(portalConfigQueryKeys.base, response);

      if (withSuccessNotification) {
        dispatch(toastrSuccess('Portal configuration updated successfully'));
      }
    },
    onError: ({ error }: any) => dispatch(toastrError(error)),
    meta: {
      mutationName: 'useUpdatePortalConfig',
      baseUrl: PORTAL_CONFIG_URL,
      method: 'PUT',
    },
  });
}

export function useWidgetColors() {
  const portalConfig = usePortalConfig();

  const result: Record<string, MantineTheme['colors']['string']> = {};
  const design = portalConfig.data?.design;

  if (design && design.custom_colors) {
    const customColors = design.custom_colors;

    for (let i = 0; i < customColors.length; i++) {
      const colorHex = customColors[i];

      result[colorHex] = generateRelatedColors(colorHex);
    }
  }

  return result;
}
