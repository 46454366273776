import {
  createStyles,
  Global,
  Grid,
  LoadingOverlay,
  Stack,
  Title,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { find, first, size, some } from 'lodash/fp';
import React, { useEffect } from 'react';

import {
  useDeviceModels,
  useFeatureFlags,
  useOrganizations,
} from '@portals/api/partners';
import { FeatureNotificationEnum, useCurrentUser } from '@portals/api/ui';
import { FeaturesNotificationsProps } from '@portals/framework/modals';
import { useOpenModal } from '@portals/redux';

import { GroupedGuidesCard } from './GuidesCard/GroupedGuidesCard';
import { StackedGuidesCard } from './GuidesCard/StackedGuidesCard';
import { InfoStrip } from './InfoStrip';
import { NextActionCard } from './NextActionCard';
import { TasksCard } from './TasksCard/TasksCard';

export function Home() {
  const { classes, theme } = useStyles();

  const isSmallerThanXl = useMediaQuery(`(max-width: ${theme.breakpoints.xl})`);

  const currentUser = useCurrentUser();
  const organizations = useOrganizations();

  const deviceModels = useDeviceModels();

  const featureFlags = useFeatureFlags();
  const openModal = useOpenModal();

  useEffect(() => {
    if (
      currentUser.data?.feature_notifications?.find(
        (feature) => feature === FeatureNotificationEnum.NewPartnerToPartner
      )
    ) {
      openModal<FeaturesNotificationsProps['data']>('FeaturesNotifications', {
        featureNotification: FeatureNotificationEnum.NewPartnerToPartner,
      });
    }
  }, [currentUser.data?.feature_notifications, openModal, featureFlags]);

  if (
    !deviceModels.isFetched ||
    organizations.isLoading ||
    currentUser.isFetching
  ) {
    return <LoadingOverlay visible />;
  }

  const hasModel = size(deviceModels.data) > 0;
  const showGroupedGuidsCard = hasModel && isSmallerThanXl;
  const labOrganization = find({ lab: true }, organizations.data);
  const hasDeviceTelemetries = some(
    { has_device_telemetries: true },
    deviceModels?.data
  );

  return (
    <div className={classes.container}>
      <GlobalOverride />
      <div className={classes.contentContainer}>
        <Title className={classes.title} order={1} data-testid="home-title">
          Welcome {currentUser.data?.name}, Let's Get Started
        </Title>
        <InfoStrip />

        <Grid gutter="xl">
          <Grid.Col span={7}>
            <TasksCard />
          </Grid.Col>

          <Grid.Col span={5}>
            <Stack spacing="xl" sx={{ height: '100%' }}>
              {hasModel && (
                <NextActionCard
                  labOrganizationId={labOrganization?.id}
                  hasDeviceTelemetries={hasDeviceTelemetries}
                  modelId={first(deviceModels?.data)?.id}
                />
              )}
              {showGroupedGuidsCard ? (
                <GroupedGuidesCard />
              ) : (
                <StackedGuidesCard />
              )}
            </Stack>
          </Grid.Col>
        </Grid>
      </div>
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    position: 'relative',
    height: '100%',
    padding: '32px 30px 40px',
    backgroundColor: theme.colors.gray[0],

    [theme.fn.largerThan('xl')]: {
      paddingBlock: 56,
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    maxWidth: 1300,
    margin: '0 auto',
  },
  title: {
    marginBottom: 32,
    fontWeight: 400,
    fontSize: theme.headings.sizes.h4.fontSize,

    [theme.fn.largerThan('xl')]: {
      marginBottom: 44,
      fontSize: 34,
    },
  },
}));

const GlobalOverride = () => (
  <Global
    styles={(theme) => ({
      '.wrapper': {
        '.main': {
          '.content': {
            padding: 0,
            backgroundColor: theme.colors.gray[0],
          },
        },
      },
    })}
  />
);
