import { Button, Stack, Text } from '@mantine/core';
import React from 'react';

import troubleshootingEmptySrc from '../../../../assets/img/troubleshooting-empty-state.svg';

interface TroubleshootingEmptyStateProps {
  onAddItem: () => void;
}

export function TroubleshootingEmptyState({
  onAddItem,
}: TroubleshootingEmptyStateProps) {
  return (
    <Stack align="center" justify="center" w="100%" h="100%">
      <img src={troubleshootingEmptySrc} />

      <Text size="md" align="center" color="gray.8">
        No troubleshooting guide available
      </Text>

      <Text size="sm" color="gray.5" maw={570} align="center">
        It looks like you haven't created a troubleshooting guide for your
        device model yet. Start configuring and writing a detailed guide to help
        users resolve common issues if they are unable to claim their device.
      </Text>

      <Button onClick={onAddItem}>+ Add issue</Button>
    </Stack>
  );
}
