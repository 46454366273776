import { Box, Button, Stack, Text, createStyles } from '@mantine/core';
import React from 'react';

import { ModalCenteredMediaLayout } from '@portals/core';

import { ModalProps, lockedCardSrc } from '../../index';

export interface RegisteredWithDifferentPortalModalProps
  extends ModalProps<{ domain: string; partnerName: string }> {}

export function RegisteredWithDifferentPortal({
  data,
  closeMe,
}: RegisteredWithDifferentPortalModalProps) {
  const { classes } = useStyles();
  const { domain, partnerName } = data;

  return (
    <ModalCenteredMediaLayout
      media={<img src={lockedCardSrc} />}
      opened
      onClose={closeMe}
      radius="md"
      title={`You're registered with ${partnerName}`}
      padding="xxl"
    >
      <Stack align="center" spacing="sm">
        <Text size="sm" color="gray.9" align="center">
          Looks like you're trying to log into the wrong portal. To reach the
          right place, please click on the link below to get to your partner's
          portal.
        </Text>

        <Text size="sm" color="gray.9" align="center">
          Bookmark this link for a smoother login experience in the future.
        </Text>

        <Box p="md">
          <Button
            onClick={closeMe}
            component="a"
            href={domain}
            className={classes.button}
            px="xxl"
          >
            Go to the portal
          </Button>
        </Box>
      </Stack>
    </ModalCenteredMediaLayout>
  );
}

const useStyles = createStyles((theme) => ({
  button: {
    '&:hover': {
      textDecoration: 'none',
      color: theme.white,
    },
  },
}));
