import { Box, Paper, Stack, Text } from '@mantine/core';
import React, { FC, ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';

interface GroupedGuideItemProps {
  bgColor: string;
  hoverBgColor: string;
  title: string;
  subtitle: string;
  icon: ReactNode;
  linkTo: LinkProps['to'];
}

export const GroupedGuideItem: FC<GroupedGuideItemProps> = ({
  title,
  subtitle,
  linkTo,
  bgColor,
  hoverBgColor,
  icon,
}) => {
  return (
    <Paper
      component={Link}
      to={linkTo}
      target="_blank"
      radius="md"
      sx={{
        backgroundColor: bgColor,
        ':hover': {
          backgroundColor: hoverBgColor,
          // override anchor tag default styling
          color: 'unset',
          textDecoration: 'none !important',
        },
      }}
    >
      <Stack py="md" sx={{ height: '100%' }}>
        <Text align="center" size="xs" weight={600}>
          {title}
        </Text>
        <Text
          align="center"
          size="xs"
          sx={(theme) => ({ color: theme.colors.blue_gray[6] })}
        >
          {subtitle}
        </Text>
        <Box mt="auto" sx={{ textAlign: 'center' }}>
          {icon}
        </Box>
      </Stack>
    </Paper>
  );
};
