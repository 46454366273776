import {
  ActionId,
  ActionImpl,
  KBarAnimator,
  KBarPortal,
  KBarPositioner,
  KBarProvider,
  KBarResults,
  KBarSearch,
  useMatches,
  useRegisterActions,
} from 'kbar';
import {
  castArray,
  first,
  isFunction,
  isString,
  size,
  sortBy,
} from 'lodash/fp';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useSignOut } from '@portals/api/auth';
import { useTranslation } from '@portals/i18n';
import { ReactComponent as CloudChange } from '@portals/icons/linear/cloud-change.svg';
import { ReactComponent as CloudPlus } from '@portals/icons/linear/cloud-plus.svg';
import { ReactComponent as DocumentText } from '@portals/icons/linear/document-text.svg';
import { ReactComponent as Flag } from '@portals/icons/linear/flag.svg';
import { ReactComponent as Link } from '@portals/icons/linear/link.svg';
import { ReactComponent as Logout1 } from '@portals/icons/linear/logout-1.svg';
import { ReactComponent as MessageQuestion } from '@portals/icons/linear/message-question.svg';
import { ReactComponent as PasswordCheck } from '@portals/icons/linear/password-check.svg';
import { ReactComponent as Profile2User } from '@portals/icons/linear/profile-2user.svg';
import { ReactComponent as SecurityUser } from '@portals/icons/linear/security-user.svg';
import { ReactComponent as User } from '@portals/icons/linear/user.svg';
import { useOpenModal } from '@portals/redux';
import {
  CategoryRouteItem,
  CommonConfigurationType,
  ComponentRendererType,
  OrganizationPortalCapabilities,
  TenantType,
} from '@portals/types';
import { useTheme } from '@portals/ui';
import { getStyledThemeColor } from '@portals/utils';

import { usePermissionAccess } from './permission-access/use-permission-access';
import { useAppConfig } from '../context';
import { useHubspotConversations } from '../external/HubspotConversationsProvider';
import {
  useCommonConfig,
  useCommonPortalCapabilities,
} from '../hooks/portal-config';
import { useHasSupportSeat } from '../hooks/support-seats';
import { useCurrentUserAccessibleTenants } from '../hooks/users';
import {
  isCustomerFeatureAll,
  isCustomerFeatureLabOnly,
} from '../utils/feature-flags.utils';

export function QuickNavProvider({ children }: { children: ReactNode }) {
  return (
    <KBarProvider options={{ disableScrollbarManagement: true }}>
      <QuickNav />

      {children}
    </KBarProvider>
  );
}

interface QuickNavItem {
  id: string;
  name: string;
  Icon: ComponentRendererType;
  perform: () => void;
}

function QuickNav() {
  const { routes } = useAppConfig();
  const t = useTranslation();
  const configuration = useCommonConfig();
  const portalCapabilities = useCommonPortalCapabilities();
  const openModal = useOpenModal();
  const { tenantType } = useAppConfig();
  const { isSuperAdmin, isAdmin, canView, canEdit } = usePermissionAccess();
  const hasSupportSeat = useHasSupportSeat();
  const hubspotConversations = useHubspotConversations();
  const navigate = useNavigate();

  const organizationConfig = configuration.data?.[TenantType.Organization];

  const signOut = useSignOut();

  const accessibleTenants = useCurrentUserAccessibleTenants();
  const numOfAccessibleTenants = useMemo(
    () => size(accessibleTenants),
    [accessibleTenants]
  );

  const isDashboardRouteAvailable = useCallback(
    ({ canAccessRoute }: CategoryRouteItem<CommonConfigurationType>) => {
      return (
        !canAccessRoute ||
        canAccessRoute({
          isAdmin,
          canView,
          canEdit,
          configuration: configuration.data,
          hasSupportSeat,
        })
      );
    },
    [canEdit, canView, configuration.data, hasSupportSeat, isAdmin]
  );

  const getAvailableChildRoutes = useCallback(
    (
      childRoutes: CategoryRouteItem<CommonConfigurationType>['childRoutes']
    ) => {
      return childRoutes.filter(({ canAccessRoute, isVisibleInSidebar }) => {
        const isVisible =
          isVisibleInSidebar?.({
            configuration: configuration.data,
          }) ?? true;
        const hasAccess =
          canAccessRoute?.({
            isAdmin,
            hasSupportSeat,
            canView,
            canEdit,
            configuration: configuration.data,
          }) ?? true;

        return isVisible && hasAccess;
      });
    },
    [canEdit, canView, configuration.data, hasSupportSeat, isAdmin]
  );

  const actions = useMemo(() => {
    const defaultActions: Array<QuickNavItem> = [
      {
        id: 'help',
        Icon: MessageQuestion,
        name: 'Help',
        perform: () =>
          window.open(
            tenantType === TenantType.Partner
              ? 'https://dev.xyte.io/'
              : 'https://docs.xyte.io/',
            '_blank'
          ),
      },
      {
        id: 'change_password',
        Icon: PasswordCheck,
        name: 'Change Password',
        perform: () => openModal('ChangePasswordModal'),
      },
      {
        id: 'tos',
        Icon: DocumentText,
        name: 'Terms of service',
        perform: () =>
          window.open('https://xyte.io/terms-of-service', '_blank'),
      },
      {
        id: 'privacy',
        Icon: SecurityUser,
        name: 'Privacy',
        perform: () => window.open('https://xyte.io/privacy-policy', '_blank'),
      },
      {
        id: 'sign_out',
        Icon: Logout1,
        name: 'Sign out',
        perform: () => {
          hubspotConversations.removeWidget();
          signOut.mutate();
        },
      },
    ];

    if (
      isAdmin &&
      !isCustomerFeatureAll(
        (portalCapabilities as OrganizationPortalCapabilities)?.b2c_view
      ) &&
      !isCustomerFeatureLabOnly(
        (portalCapabilities as OrganizationPortalCapabilities)?.b2c_view,
        organizationConfig?.lab
      )
    ) {
      const adminActions: QuickNavItem[] = [
        {
          id: 'users',
          name: 'Users',
          Icon: User,
          perform: () => navigate('/settings/users_and_groups/users'),
        },
        {
          id: 'groups',
          name: 'Groups',
          Icon: Profile2User,
          perform: () => navigate('/settings/users_and_groups/groups'),
        },
        {
          id: 'integrations',
          name: 'Integrations',
          Icon: Link,
          perform: () => navigate('/settings/integrations'),
        },
      ];

      defaultActions.push(...adminActions);
    }

    if (numOfAccessibleTenants > 1) {
      defaultActions.push({
        id: 'switch_tenant',
        name: 'Switch Tenant',
        Icon: CloudChange,
        perform: () => openModal('SwitchTenantModal'),
      });
    }

    if (isSuperAdmin && tenantType === TenantType.Partner) {
      defaultActions.push({
        id: 'create_tenant',
        name: 'Create New Tenant',
        Icon: CloudPlus,
        perform: () => openModal('CreatePartner'),
      });
    }

    if (isSuperAdmin) {
      defaultActions.push({
        id: 'featureFlags',
        name: 'Feature flags',
        Icon: Flag,
        perform: () => openModal('FeatureFlags'),
      });
    }

    routes?.dashboard.forEach((dashboardRoute) => {
      if (!isDashboardRouteAvailable(dashboardRoute)) {
        return;
      }

      const availableChildRoutes = getAvailableChildRoutes(
        dashboardRoute.childRoutes
      );

      availableChildRoutes.forEach((childRoute) => {
        const path = isFunction(childRoute.path)
          ? childRoute.path({
              configuration: configuration.data,
              isAdmin,
            })
          : childRoute.path;

        defaultActions.push({
          id: childRoute.id,
          name: childRoute.id ? t(childRoute.id) : '',
          Icon: childRoute.icon,
          perform: () =>
            childRoute.href
              ? window.open(childRoute.href, '_blank')
              : navigate(generatePath(first(castArray(path)))),
        });
      });
    });

    return sortBy('name', defaultActions);
  }, [
    numOfAccessibleTenants,
    getAvailableChildRoutes,
    hubspotConversations,
    isAdmin,
    isDashboardRouteAvailable,
    isSuperAdmin,
    openModal,
    portalCapabilities,
    routes?.dashboard,
    t,
    tenantType,
    configuration.data,
    navigate,
    signOut,
    organizationConfig,
  ]);

  useRegisterActions(actions, [actions]);

  return (
    <KBarPortal>
      <StyledKBarPositioner>
        <StyledKBarAnimator>
          <StyledKBarSearch />

          <Results />
        </StyledKBarAnimator>
      </StyledKBarPositioner>
    </KBarPortal>
  );
}

const Results = () => {
  const { results, rootActionId } = useMatches();

  return (
    <KBarResults
      maxHeight={500}
      items={results}
      onRender={({ item, active }) =>
        isString(item) ? (
          <>{item}</>
        ) : (
          <ResultItem
            action={item}
            active={active}
            currentRootActionId={rootActionId}
          />
        )
      }
    />
  );
};

interface ResultItemProps {
  action: ActionImpl;
  active: boolean;
  currentRootActionId: ActionId;
}

const ResultItem = React.forwardRef(
  (
    { action, active, currentRootActionId }: ResultItemProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const { Icon } = action as ActionImpl & { Icon: React.ComponentType<any> };
    const { color } = useTheme();

    return (
      <ItemContainer ref={ref} $isActive={active}>
        <div className="item-content" style={{ color: color.gray600 }}>
          {Icon ? <Icon size={18} className="align-middle mr-3" /> : null}

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>{action.name}</div>
          </div>
        </div>
      </ItemContainer>
    );
  }
);

const StyledKBarPositioner = styled(KBarPositioner)`
  z-index: 999;
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.3);
`;

const StyledKBarAnimator = styled(KBarAnimator)`
  width: 600px;
  background: ${getStyledThemeColor('white')};
  color: ${getStyledThemeColor('dark')};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 16px 24px rgba(0, 0, 0, 0.05), 0 2px 6px rgba(0, 0, 0, 0.05),
    0 0 1px rgba(0, 0, 0, 0.05);
  border: 1px solid ${getStyledThemeColor('gray300')};
`;

const StyledKBarSearch = styled(KBarSearch)`
  padding: 12px 16px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  border: none;
  background: ${getStyledThemeColor('white')};
  color: ${getStyledThemeColor('dark')};
`;

const ItemContainer = styled.div<{ $isActive: boolean }>`
  padding: 12px 16px;
  background: ${({ $isActive, theme }) =>
    $isActive ? theme.color.gray150 : theme.color.white};,
  borderLeft: 2px solid ${({ $isActive, theme }) =>
    $isActive ? theme.color.primary : 'transparent'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: background 0.15s ease-in-out;

  .item-content {
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 14px;
  }
`;
