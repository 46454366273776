import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import moment from 'moment';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

TimeAgo.addLocale(en);

const timeAgoFormatter = new TimeAgo('en-US');

export const timeAgo = (time: number): string =>
  time ? (timeAgoFormatter.format(time) as string) : 'None';

export const prettyTime = (
  time: number | string | null | undefined
): string => {
  if (!time) {
    return 'Unknown';
  }

  const dateObject = new Date(time);

  return (
    dateObject.toLocaleDateString() + ' ' + dateObject.toLocaleTimeString()
  );
};

export const zeroPad = (num: number, places: number): string =>
  String(num).padStart(places, '0');

export const formatUTC = (offset: number): string => {
  const absOffset = Math.abs(offset);

  if (typeof offset === 'undefined') {
    return 'Unknown';
  }

  let str = `UTC ${offset >= 0 ? '+' : '-'}`;
  str += zeroPad(Math.floor(absOffset / 60), 2);
  str += ':';
  str += zeroPad(absOffset % 60, 2);

  return str;
};

export const prettyTimeMoment = (
  time: number | string | null | undefined
): string => {
  if (!time) {
    return 'Unknown';
  }

  return moment(time).format('MMMM Do, YYYY h:mm A');
};

const mediumDateFormat = new Intl.DateTimeFormat(undefined, {
  dateStyle: 'medium',
});

export function mediumDateFormatter(
  date: string | Date | null | undefined,
  invalidMessage = 'Invalid Date'
) {
  if (!date) return invalidMessage;
  if (typeof date === 'string') return mediumDateFormat.format(new Date(date));

  return mediumDateFormat.format(date);
}

const shortTimeFormat = new Intl.DateTimeFormat(undefined, {
  timeStyle: 'short',
});

export function shortTimeFormatter(date: string | Date | undefined) {
  if (!date) return 'Invalid Date';
  if (typeof date === 'string') return shortTimeFormat.format(new Date(date));

  return shortTimeFormat.format(date);
}

export function formatDateTime(
  datetime: Date | string | undefined | null,
  format = 'MMM DD, YYYY hh:mm A',
  invalidMessage = 'Invalid Date',
  timeZone?: string
) {
  if (!datetime) return invalidMessage;

  if (timeZone) {
    return dayjs(datetime).tz(timeZone).format(format);
  }

  return dayjs(datetime).format(format);
}
