import { Switch } from '@mantine/core';
import React from 'react';

import { useSupportState } from '../pages/support-overview/SupportStateProvider';

export function IncludeLabCustomersToggle() {
  const { isLabIncluded, setIsLabIncluded } = useSupportState();

  return (
    <Switch
      label="Include lab customers"
      checked={isLabIncluded}
      onChange={(e) => setIsLabIncluded(e.target.checked)}
    />
  );
}
