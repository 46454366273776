import { createStyles } from '@mantine/core';
import React from 'react';

import { CardContainer } from '../common';
import { EditURL, EditYourCloud, NumOfDevices, NumOfMembers } from './cards';

export function InfoStrip() {
  const { classes } = useStyles();

  return (
    <CardContainer className={classes.container}>
      <NumOfDevices />

      <NumOfMembers />

      <EditURL />

      <EditYourCloud />
    </CardContainer>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr) 18%',
    gridGap: theme.spacing.md,
    marginBottom: theme.spacing.xl,

    [theme.fn.largerThan('xl')]: {
      gridGap: theme.spacing.lg,
    },
  },
}));
