import {
  Accordion,
  AccordionProps,
  createStyles,
  LoadingOverlay,
} from '@mantine/core';
import { keys, noop } from 'lodash/fp';
import React, { useState } from 'react';

import { StoreListing, useStoreSettings } from '@portals/api/partners';
import { CurrencyRow } from '@portals/framework';
import { PaymentIntervalEnum, ProductPricingModel } from '@portals/types';

import { PaymentTabUsageBasedPrices } from './PaymentTabUsageBasedPrices';
import { StoreListingPersonalizedPricingEmptyState } from '../../../../components/store-listings/StoreListingPersonalizedPricingEmptyState';
import { useStoreListingPeriodPriceTitle } from '../../../../hooks/store-listings.hooks';
import { mapStoreListingPricesToPaymentSettings } from '../../../../utils/store-listings.utils';

interface PaymentTabProps {
  storeListing: StoreListing;
  paymentSettings: ReturnType<typeof mapStoreListingPricesToPaymentSettings>;
}

export function PaymentTab({ storeListing, paymentSettings }: PaymentTabProps) {
  const { classes } = useStyles();

  const storeSettings = useStoreSettings();
  const { getPriceTitle } = useStoreListingPeriodPriceTitle();

  const [accordionItems, setAccordionItems] = useState<PaymentIntervalEnum[]>(
    []
  );

  if (!storeSettings.data || !storeSettings.isFetched) {
    return <LoadingOverlay visible />;
  }

  const pricingModel = storeListing.product.pricing_model;

  if (pricingModel === ProductPricingModel.Personalized) {
    return <StoreListingPersonalizedPricingEmptyState />;
  }

  if (pricingModel === ProductPricingModel.UsageBased) {
    return (
      <PaymentTabUsageBasedPrices
        defaultCurrency={storeSettings.data.default_currency}
        storeListing={storeListing}
        paymentSettings={paymentSettings}
      />
    );
  }

  return (
    <Accordion<true>
      multiple
      styles={accordionStyles}
      value={accordionItems}
      onChange={(value: PaymentIntervalEnum[]) => setAccordionItems(value)}
    >
      {(keys(paymentSettings) as Array<PaymentIntervalEnum>).map((period) => {
        return (
          <Accordion.Item
            value={period}
            key={period}
            data-testid="payment-tab-accordion-item"
          >
            <Accordion.Control>
              {getPriceTitle({
                period,
                paymentSettings,
                product: storeListing.product,
              })}
            </Accordion.Control>
            <Accordion.Panel>
              {paymentSettings[period]?.prices.map((price) => (
                <CurrencyRow
                  readOnly
                  key={price.currency}
                  className={classes.currencyRow}
                  value={price.amountInScu ?? undefined}
                  onChange={noop}
                  currencyCode={price.currency}
                  isDefaultCurrency={
                    storeSettings.data.default_currency === price.currency
                  }
                />
              ))}
            </Accordion.Panel>
          </Accordion.Item>
        );
      })}
    </Accordion>
  );
}

const useStyles = createStyles(() => ({
  currencyRow: {
    '&:last-child': {
      borderBottom: 'none',
    },
  },
}));

const accordionStyles: AccordionProps['styles'] = (theme) => ({
  panel: {
    position: 'relative',
  },
  control: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: theme.white,
  },
});
