import {
  Box,
  createStyles,
  Portal,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { AnimatePresence } from 'framer-motion';
import { get } from 'lodash/fp';
import moment from 'moment';
import React, { FC, useState } from 'react';

import { PartnerIncidentType } from '@portals/api/partners';
import { DeviceModelIcon } from '@portals/framework';
import { ReactComponent as Danger } from '@portals/icons/linear/danger.svg';
import { TableDetailsPanel } from '@portals/table';
import { TicketType } from '@portals/types';
import { INCIDENT_TYPE } from '@portals/utils';

import { SpacePath } from './SpacePath';
import { useRedirectToCustomerPortal } from '../../hooks/customers.hooks';
import { IncidentDetailsPanel } from '../../pages/incidents/IncidentDetailsPanel';

export enum ItemType {
  Ticket = 'ticket',
  Incident = 'incident',
}

interface TicketOrIncidentItemProps {
  item: TicketType | PartnerIncidentType;
  canViewDetails: boolean;
  itemType?: ItemType;
  selectedItemId?: string | null;
  onSelectItemId?: (id: string | null) => void;
}

export const TicketOrIncidentItem: FC<TicketOrIncidentItemProps> = ({
  item,
  canViewDetails,
  itemType,
  selectedItemId,
  onSelectItemId,
}) => {
  const { classes, cx, theme } = useStyles();
  const [isIncidentDetailsPanelOpen, setIsIncidentDetailsPanelOpen] =
    useState(false);

  const { colors } = useMantineTheme();

  const redirectToCustomerPortal = useRedirectToCustomerPortal();

  const organizationName =
    'organization_display_name' in item
      ? item.organization_display_name
      : item.org_display_name;

  const getCreatedAt = moment(item.created_at).calendar(null, {
    lastWeek: '[Last] dddd LT',
    lastDay: '[Yesterday] LT',
    sameDay: '[Today] LT',
    sameElse: 'DD/MM/YYYY LT',
  });

  const getIcon = () => {
    if ('priority' in item) {
      const { color } = INCIDENT_TYPE[item.priority - 1];

      return <Danger style={{ color: colors[color][4] }} />;
    }

    return (
      <Box sx={{ color: theme.other.primaryColor }}>
        <DeviceModelIcon
          iconName={get('item.device_model_icon', item)}
          size={24}
        />
      </Box>
    );
  };

  const onItemClick = () => {
    if (!canViewDetails || !item.org_id) return;

    if (itemType === ItemType.Ticket) {
      redirectToCustomerPortal.redirect({
        customerId: item.org_id,
        afterAuthRedirectPathname: `/tickets/${item.id}`,
      });
    }
    if (itemType === ItemType.Incident) {
      onSelectItemId(item.id);
      setIsIncidentDetailsPanelOpen(true);
    }
  };

  const portalTarget = document.querySelector(
    `[id^="customer-route-modal"]`
  ) as HTMLElement | null;

  return (
    <>
      <Portal target={portalTarget ?? undefined}>
        <AnimatePresence>
          {itemType === ItemType.Incident && isIncidentDetailsPanelOpen ? (
            <TableDetailsPanel
              onClose={() => {
                setIsIncidentDetailsPanelOpen(false);
                onSelectItemId(null);
              }}
              type="inner"
            >
              <IncidentDetailsPanel
                incident={item as PartnerIncidentType}
                onClosePanel={() => {
                  setIsIncidentDetailsPanelOpen(false);
                  onSelectItemId(null);
                }}
              />
            </TableDetailsPanel>
          ) : null}
        </AnimatePresence>
      </Portal>

      <Box
        className={cx(
          classes.container,
          { readonly: !canViewDetails },
          {
            selected: selectedItemId === item.id,
          }
        )}
        onClick={onItemClick}
      >
        {getIcon()}
        <Stack spacing={2} sx={{ overflow: 'hidden' }}>
          <Text size="xs" color="gray.4">
            {getCreatedAt}
          </Text>

          <Text
            size="sm"
            weight={600}
            className={cx(classes.title, classes.longText)}
          >
            {item.title}
          </Text>

          <Text size="xs" className={classes.organizationDisplayName}>
            {organizationName}
          </Text>

          <Box className={classes.longText} c="gray.5">
            <SpacePath item={item} />
          </Box>
        </Stack>
      </Box>
    </>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    display: 'grid',
    flexDirection: 'column',
    gridTemplateColumns: '24px 1fr 24px',
    gap: theme.spacing.xs,
    alignItems: 'center',
    padding: `${theme.spacing.xl} ${theme.spacing.xs}`,
    borderBottom: `1px solid ${theme.colors.gray[3]}`,

    [theme.fn.largerThan('xl')]: {
      gap: theme.spacing.md,
      padding: `${theme.spacing.xl} ${theme.spacing.md}`,
    },

    '.details-arrow': {
      opacity: 0,
      transition: 'opacity 0.15s ease-in-out',
    },

    '&.selected': {
      backgroundColor: theme.colors.primary[0],
    },

    '&:not(.readonly):hover': {
      cursor: 'pointer',

      '.details-arrow': {
        opacity: 1,
      },
    },
  },

  title: {
    color: theme.colors.gray[9],

    [theme.fn.largerThan('xl')]: {
      fontSize: theme.fontSizes.md,
    },
  },
  organizationDisplayName: {
    color: theme.colors.gray[7],

    [theme.fn.largerThan('xl')]: {
      fontSize: theme.fontSizes.sm,
    },
  },
  longText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));
