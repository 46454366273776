import { Button } from '@mantine/core';
import React from 'react';

import { useBulkInvitePartners } from '@portals/api/partners';
import { PermissionAccess } from '@portals/framework';
import { useOpenModal } from '@portals/redux';
import { AccessLevelEnum } from '@portals/types';

import { SelectInvitationTypeModalProps } from '../../modals';

export function InvitePartnerButton() {
  const openModal = useOpenModal();

  const bulkInvitePartners = useBulkInvitePartners();

  const onClick = () => {
    openModal<SelectInvitationTypeModalProps['data']>(
      'SelectInvitationTypeModal',
      {
        title: 'How would you like to invite partners?',
        directInvitationText:
          'Find partners by name using search or send an email invitation directly. This option is swift, precise and ideal for individual invitations.',
        bulkInvitationText:
          'Invite all your partners at once. Our support team will reach out to you, assisting in the creation and import of a CSV file. Perfect for mass collaborations.',
        onDirectInvitationClick: () => openModal('InvitePartner'),
        onBulkInvitationClick: () => bulkInvitePartners.mutate(),
      }
    );
  };

  return (
    <PermissionAccess
      minAccessLevel={AccessLevelEnum.Edit}
      permissionKeys="customers"
    >
      <Button onClick={onClick} data-testid="invite-partner-button">
        Invite Partner
      </Button>
    </PermissionAccess>
  );
}
