import { Group, TextInput } from '@mantine/core';
import { set } from 'lodash/fp';
import React from 'react';

import { CodeEditor } from '@portals/framework';

import { DetailsColumnContent } from './common/DetailsColumnContent';
import { StepId } from './types';
import { useProductWizard } from './use-product-wizard';

export function ProductDetailsStep() {
  const { contextData, setContextData } = useProductWizard();

  return (
    <DetailsColumnContent title="Product Details">
      <DetailsColumnContent.Section
        title="License Data"
        subtitle="Please submit here the license activation data to be sent to the device"
      >
        <Group noWrap>
          <CodeEditor />

          <TextInput
            required
            error={contextData.errors[StepId.ProductDetails].licenseData}
            label="License Data"
            sx={{ flexGrow: 1 }}
            data-testid="license-data-input"
            placeholder="Example: heated_seats_enablement"
            value={contextData.product?.license_data ?? ''}
            onChange={({ target }) =>
              setContextData((curr) =>
                set(['product', 'license_data'], target.value, curr)
              )
            }
            onBlur={() => {
              if (contextData.product.license_data?.trim()) {
                setContextData((curr) =>
                  set(
                    ['errors', StepId.ProductDetails, 'licenseData'],
                    '',
                    curr
                  )
                );
              }
            }}
          />
        </Group>
      </DetailsColumnContent.Section>
    </DetailsColumnContent>
  );
}
