import {
  Button,
  Group,
  Image,
  ImageProps,
  LoadingOverlay,
  MantineProvider,
  Paper,
  Stack,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import React, { useMemo, useState } from 'react';

import { useUpdatePartnerInfo, useWidgetColors } from '@portals/api/partners';
import {
  ColorSelector,
  WIDGET_COLORS,
  WidgetColorType,
} from '@portals/device-widgets';
import { useCommonPartnerConfig } from '@portals/framework';
import { useOpenModal } from '@portals/redux';

import { useUpdateCustomColors } from '../../../modals/devices-widgets/device-widgets.hooks';

export function General() {
  const partnerConfig = useCommonPartnerConfig();
  const openModal = useOpenModal();

  const updatePartnerName = useUpdatePartnerInfo();

  const onUpdate = (value: string | undefined) => {
    if (value) {
      updatePartnerName.mutate({ display_name: value });
    }
  };

  const [partnerName, setPartnerName] = useState<string | undefined>(
    partnerConfig?.display_name
  );
  const [color, setColor] = useState<WidgetColorType>('red.4');

  const partnerLogo = useMemo(
    () =>
      partnerConfig?.logo_url === null || partnerConfig?.logo_url === ''
        ? 'logo_full_dark.svg'
        : partnerConfig?.logo_url,
    [partnerConfig?.logo_url]
  );
  const theme = useMantineTheme();
  const colors = useWidgetColors();

  const widgetColors: WidgetColorType[] = WIDGET_COLORS.concat(
    Object.keys(colors)
  );

  const updateCustomColors = useUpdateCustomColors();

  if (!partnerConfig) {
    return <LoadingOverlay visible />;
  }

  return (
    <Stack data-test-id="change-logo-container" maw={768}>
      <Paper mb="xl" withBorder p="xl" radius="md">
        <Stack spacing="xl">
          <Text size="md" color="gray.8">
            Information
          </Text>

          <Group spacing="xl" align="start">
            <Stack>
              <Image
                src={partnerLogo}
                fit="contain"
                alt="With default placeholder"
                withPlaceholder
                width={127}
                height={127}
                radius="md"
                styles={imageStyles}
              />

              <Button
                variant="default"
                onClick={() => openModal('EditPartnerLogo')}
              >
                Upload Logo
              </Button>
            </Stack>

            <Group position="right" align="end">
              <TextInput
                label="Partner name"
                w={300}
                placeholder="Change Partner name"
                value={partnerName}
                onChange={(event) => setPartnerName(event.currentTarget.value)}
              />

              <Button
                variant="default"
                disabled={
                  partnerName === '' ||
                  partnerName === partnerConfig?.display_name
                }
                onClick={() => onUpdate(partnerName)}
                loading={updatePartnerName.isLoading}
              >
                Update Name
              </Button>
            </Group>
          </Group>
        </Stack>
      </Paper>

      <Paper withBorder mb="xl" p="xl" radius="md">
        <Stack spacing="xl">
          <Text size="md" color="gray.8">
            Theme colors
          </Text>
          <MantineProvider
            theme={{
              ...theme,
              colors: { ...theme.colors, ...colors },
              primaryShade: 4,
              primaryColor: 'blue_accent',
            }}
          >
            <ColorSelector
              selectedColor={color}
              colors={widgetColors}
              onAddCustomColor={updateCustomColors}
              onPresetColorSelect={(color) => setColor(color)}
            />
          </MantineProvider>
        </Stack>
      </Paper>
    </Stack>
  );
}

const imageStyles: ImageProps['styles'] = (theme) => ({
  image: {
    border: `1px solid ${theme.colors.gray[2]}`,
  },
});
