import {
  Box,
  Button,
  createStyles,
  Group,
  Paper,
  Stack,
  Text,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useCreateTaxGroup } from '@portals/api/partners';
import { DashboardContentLayout } from '@portals/framework';

import { CreateTaxGroupForm } from '../../../../../components/tax-groups/create-tax-group-form/CreateTaxGroupForm';
import { TaxGroupIconName } from '../../../../../components/tax-groups/tax-groups-icons';

interface FormType {
  groupName: string;
  taxCode: string;
  iconName: TaxGroupIconName;
}

export function CreateTaxGroup() {
  const { classes } = useStyles();

  const navigate = useNavigate();
  const createTaxGroup = useCreateTaxGroup();

  const form = useForm<FormType>({
    initialValues: {
      groupName: '',
      taxCode: '',
      iconName: 'electricity',
    },
    clearInputErrorOnChange: true,
    validate: (values) => ({
      groupName: values.groupName === '' ? 'Required' : null,
      taxCode: values.taxCode === '' ? 'Required' : null,
    }),
  });

  const onSubmit = async (values: FormType) => {
    try {
      await createTaxGroup.mutateAsync({
        groupName: values.groupName,
        taxCode: values.taxCode,
        iconName: values.iconName,
      });
      navigate(`/settings/store`);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <DashboardContentLayout
      breadcrumbs={[
        {
          label: 'Settings',
          to: '/settings',
        },
        {
          label: 'Store',
          to: '/settings/store',
        },
      ]}
      pageTitle="Create New Tax Group"
      withBreadcrumbsEndSeparator
      className={classes.layout}
    >
      <form onSubmit={form.onSubmit(onSubmit)} className={classes.form}>
        <Box sx={{ maxWidth: 768 }}>
          <Paper p="xl" shadow="md" withBorder>
            <Stack spacing="xl">
              <Text>General Info</Text>
              <CreateTaxGroupForm
                iconName={form.values.iconName}
                onChangeIconName={(iconName) =>
                  form.setFieldValue('iconName', iconName)
                }
                fieldsWidth={290}
                form={form}
              />
            </Stack>
          </Paper>
        </Box>

        <Group position="right" className={classes.footer} p="xl">
          <Button variant="default" onClick={() => navigate(`/settings/store`)}>
            Cancel
          </Button>
          <Button type="submit">Create</Button>
        </Group>
      </form>
    </DashboardContentLayout>
  );
}

const useStyles = createStyles((theme) => ({
  layout: {
    backgroundColor: theme.colors.gray[0],
  },
  footer: {
    backgroundColor: theme.white,
    position: 'absolute',
    width: '100%',
    bottom: 0,
    left: 0,
  },
  form: {
    display: 'grid',
    gridTemplateRows: '1fr 88px',
    height: '100%',
  },
}));
