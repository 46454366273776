import {
  createStyles,
  Group,
  Stack,
  Switch,
  SwitchProps,
  Text,
  TextInput,
} from '@mantine/core';
import React, { ReactNode } from 'react';

interface SocialCardProps {
  label: string;
  placeholder: string;
  icon: ReactNode;
  isActive: boolean;
  onIsActiveChange: (value: boolean) => void;
  hrefValue: string;
  onHrefChange: (value: string) => void;
  disabled: boolean;
}

export function SocialCard({
  label,
  placeholder,
  icon,
  isActive,
  onIsActiveChange,
  hrefValue,
  onHrefChange,
  disabled,
}: SocialCardProps) {
  const { classes } = useStyles();

  return (
    <Stack spacing="md" className={classes.card}>
      <Switch
        checked={isActive}
        onChange={(e) => {
          const { checked } = e.currentTarget;

          onIsActiveChange(checked);

          if (checked && !hrefValue) {
            onHrefChange('');
          }
        }}
        styles={switchStyles}
        disabled={disabled}
        labelPosition="left"
        label={
          <Group spacing="xs" c="gray.9">
            <span>{icon}</span>
            <Text>{label}</Text>
          </Group>
        }
      />

      <TextInput
        type="url"
        placeholder={placeholder}
        value={hrefValue}
        onChange={(e) => onHrefChange(e.target.value)}
        disabled={disabled}
      />
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  card: {
    padding: theme.spacing.md,
    borderRadius: theme.radius.md,
    border: `1px solid ${theme.colors.gray[2]}`,
  },
}));

const switchStyles: SwitchProps['styles'] = {
  body: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  labelWrapper: {
    flexGrow: 1,
  },
};
