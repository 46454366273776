import { Product } from '@portals/api/partners';

export function canEditProduct(product: Product) {
  return product.editable;
}

export function canDeleteProduct(product: Product) {
  return product.editable;
}

export function canArchiveProduct(product: Product) {
  return !product.editable && !product.archived;
}
