import {
  Group,
  Loader,
  Paper,
  Stack,
  Switch,
  Text,
  Alert,
  useMantineTheme,
} from '@mantine/core';
import { size } from 'lodash';
import React from 'react';

import {
  useActiveConnectionsResponse,
  useConfig,
  useUpdateConfig,
} from '@portals/api/partners';
import { ReactComponent as InfoCircle } from '@portals/icons/linear/info-circle.svg';

export function PartnerToPartnerConnection() {
  const theme = useMantineTheme();

  const config = useConfig();
  const updateConfig = useUpdateConfig();

  const availableConnectedPartners = useActiveConnectionsResponse();
  const hasConnectedPartners = size(availableConnectedPartners?.data?.data) > 0;

  const onChange = async (isEnabled: boolean) => {
    updateConfig.mutate({
      updatedConfig: { visible_to_other_partners: isEnabled },
    });
  };

  if (!config.isFetched) {
    return null;
  }

  return (
    <Stack spacing="xl" maw={760}>
      <Paper withBorder p="xl" radius="md" pos="relative">
        <Stack spacing="xl">
          <Group style={{ position: 'relative' }} spacing="xl" grow>
            <Text size="md" color="gray.8">
              Partners Connection
            </Text>
            {updateConfig.isLoading ? (
              <Loader size="sm" style={{ position: 'absolute', right: 0 }} />
            ) : null}
          </Group>

          <Group position="apart" align="center" h={48}>
            <Text color="blue_gray.8">Activate partner to partner</Text>

            <Switch
              size="md"
              checked={config.data?.visible_to_other_partners}
              onChange={(event) => onChange(event.target.checked)}
              disabled={hasConnectedPartners}
            />
          </Group>
        </Stack>
        <Alert
          color={hasConnectedPartners ? 'amber.0' : 'primary.0'}
          radius="md"
          icon={<InfoCircle color={theme.colors.blue_gray[8]} />}
          title={
            <Text size="md" color="blue_gray.8">
              Please note
            </Text>
          }
        >
          <Text color="blue_gray.6">
            {hasConnectedPartners ? (
              <>
                You are currently connected with one or more partners on the
                platform. As such, the partner to partner connection feature
                cannot be turned off in order to maintain the collaboration. To
                disable this feature, please disconnect from all partners first
                or contact support for more help.
              </>
            ) : (
              <>
                By enabling this feature, you can unlock new business
                opportunities for you and your channel partners and dealers,
                empowering them to add their own services and support to your
                manufacturer store, create a Unified Channel Fabric and take our
                businesses to the next level.
                <br />
                <br />
                Once you enable this feature, you will become visible to other
                partners and be able to invite and be invited by other partners
                in Xyte ecosystem. This will enable you to create new
                partnerships and expand your business reach.
              </>
            )}
          </Text>
        </Alert>
      </Paper>
    </Stack>
  );
}
