import { Paper, Text } from '@mantine/core';
import React, { ReactNode } from 'react';

export function MainContentCardTitle({
  className,
  children,
}: {
  className?: string;
  children: ReactNode;
}) {
  return (
    <Text
      className={className}
      sx={(theme) => ({
        fontSize: theme.fontSizes.md,
        fontWeight: 500,
        lineHeight: 1.375,
        color: theme.colors.blue_gray[6],

        [theme.fn.largerThan('xl')]: {
          fontSize: theme.fontSizes.xl,
        },
      })}
    >
      {children}
    </Text>
  );
}

export function CardContainer({
  className,
  children,
}: {
  className?: string;
  children: ReactNode;
}) {
  return (
    <Paper p="xl" radius="md" shadow="sm" className={className}>
      {children}
    </Paper>
  );
}
