import { HubType } from '@portals/types';

import {
  ADD_NOTIFICATION,
  END_NETWORK,
  REMOVE_NOTIFICATION,
  RESET_AUTH_ERROR,
  START_NETWORK,
} from '../constants';

export const startNetwork = (name) => ({
  type: START_NETWORK,
  payload: name,
  meta: { quiet: true },
});

export const endNetwork = (name) => ({
  type: END_NETWORK,
  payload: name,
  meta: { quiet: true },
});

export const addNotification = (id, message, link = null) => ({
  type: ADD_NOTIFICATION,
  payload: { id, message, link, visible: true },
});

export const removeNotification = (id) => ({
  type: REMOVE_NOTIFICATION,
  payload: id,
});

export const resetAuthError = () => ({
  type: RESET_AUTH_ERROR,
});

export const setActiveHub = (activeHubType: HubType) => ({
  type: 'SET_ACTIVE_HUB',
  payload: {
    activeHubType,
  },
});
