import React from 'react';

import emptyStoreListingsSrc from '../../../assets/img/store-listing-empty-state.svg';
import { StoreListingsTable } from './StoreListingsTable';

export function ArchivedStoreListingsTable() {
  return (
    <StoreListingsTable
      storeListingStatus="archived"
      noDataIndication={{
        title: 'No archived product listings found',
        assetSrc: emptyStoreListingsSrc,
      }}
    />
  );
}
