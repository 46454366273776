import {
  Button,
  Group,
  Select,
  Stack,
  TextInput,
  Textarea,
  Anchor,
} from '@mantine/core';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import Response from '../../components/Response';
import TestCommand from '../../components/TestCommand';
import { getApiDevice, sendDump } from '../../redux/actions/api';

const MIME_TYPES = [
  'text/plain',
  'text/csv',
  'application/pdf',
  'application/zip',
  'application/x-tar',
];

const SetCommand = ({ access, sendDump }) => {
  const [dump, setDump] = useState('');
  const [mimeType, setMimeType] = useState(MIME_TYPES[0]);
  const [filename, setFilename] = useState('');

  return (
    <Stack>
      <Stack>
        <Group>
          <Select
            data={MIME_TYPES}
            value={mimeType}
            onChange={(value) => setMimeType(value)}
          />

          <Anchor
            href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types"
            rel="noopener noreferrer"
            target="_blank"
          >
            (List of common MIME types - file types)
          </Anchor>
        </Group>

        <TextInput
          placeholder="Filename"
          onChange={(e) => setFilename(e.target.value)}
        />

        <Textarea
          name="report"
          minRows={10}
          value={dump}
          onChange={(e) => setDump(e.target.value)}
        />

        <Group>
          <Button
            disabled={!access.id}
            onClick={() => sendDump(access.id, mimeType, filename, dump)}
          >
            Send
          </Button>
          <span>
            Device ID:
            {access.id || 'Not set'}
          </span>
        </Group>

        {access.id && (
          <TestCommand
            url={`/${access.id}/dump`}
            contentType="plain/text"
            data={dump}
          />
        )}
      </Stack>

      <Response type="sendDump" />
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  access: getApiDevice(state),
});

export default connect(mapStateToProps, { sendDump })(SetCommand);
