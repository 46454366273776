import { find, noop } from 'lodash/fp';
import React from 'react';

import { DeviceLocationMarker } from './device-location';
import {
  getScatterChartEventsConfig,
  getScatterChartMockData,
} from './scatter-chart';
import { WidgetDataRangeEnum } from './scatter-chart/widget/scatter-chart.types';
import { getSplineChartMockData } from './spline-chart';
import { WIDGET_ICONS } from './widgets.constants';
import {
  ConvertWidgetFormToWidgetProps,
  WidgetIconNameType,
} from './widgets.types';

export const convertWidgetFormToWidgetProps: ConvertWidgetFormToWidgetProps = {
  status: ({ formValues }) => ({
    title: formValues.telemetry_as_title
      ? formValues.title_telemetry
      : formValues.name || 'Title',
    value: formValues.status?.[0].display_name || 'Status',
    color: formValues.status?.[0].color,
    stackProps: {
      sx: {
        boxShadow: `0px 24px 36px rgba(13, 14, 48, 0.05)`,
        width: 300,
      },
    },
  }),
  numeric_metric: ({ formValues }) => ({
    title: formValues.telemetry_as_title
      ? formValues.title_telemetry
      : formValues.name || 'Title',
    value: 100,
    numberFormat: formValues.number_format,
    numberOfDecimals: formValues.num_of_decimals,
    unit: formValues.with_measurement_unit ? formValues.measurement_unit : null,
    stackProps: {
      sx: {
        boxShadow: `0px 24px 36px rgba(13, 14, 48, 0.05)`,
        width: 300,
      },
    },
  }),
  value: ({ formValues }) => ({
    title: formValues.telemetry_as_title
      ? formValues.title_telemetry
      : formValues.name || 'Title',
    value: formValues.telemetry ? formValues.telemetry : 'Value',
    unit: formValues.with_measurement_unit ? formValues.measurement_unit : null,
    stackProps: {
      sx: {
        boxShadow: `0px 24px 36px rgba(13, 14, 48, 0.05)`,
        width: 300,
      },
    },
  }),
  spline_chart: ({ formValues }) => ({
    title: formValues.name || 'Title',
    data: getSplineChartMockData(
      formValues.measurements,
      formValues.scale_type
    ),
    id: 'preview',
    measurements: formValues.measurements,
    scaleType: formValues.scale_type,
    numberFormat: formValues.number_format,
    numOfDecimals: formValues.num_of_decimals,
    stackProps: {
      sx: {
        boxShadow: `0px 24px 36px rgba(13, 14, 48, 0.05)`,
        width: 300,
      },
    },
  }),
  scatter_chart: ({ formValues }) => ({
    title: formValues.name || 'Title',
    data: getScatterChartMockData(formValues.events),
    eventsConfig: getScatterChartEventsConfig(formValues.events),
    id: 'preview',
    scaleType: formValues.scale_type,
    numberFormat: formValues.number_format,
    numOfDecimals: formValues.num_of_decimals,
    range: WidgetDataRangeEnum.Week,
    stackProps: {
      sx: {
        boxShadow: `0px 24px 36px rgba(13, 14, 48, 0.05)`,
        width: 300,
      },
    },
  }),
  toggle: ({ formValues }) => ({
    title: formValues.telemetry_as_title
      ? formValues.title_telemetry
      : formValues.name || 'Title',
    iconName: formValues.icon_name,
    color: formValues.color,
    isChecked: true,
    stackProps: {
      sx: {
        boxShadow: `0px 24px 36px rgba(13, 14, 48, 0.05)`,
        width: 244,
      },
    },
  }),
  button: ({ formValues }) => ({
    title: formValues.name || 'Title',
    iconName: formValues.icon_name,
    showIcon: formValues.show_icon,
    color: formValues.color,
    stackProps: {
      sx: {
        boxShadow: `0px 24px 36px rgba(13, 14, 48, 0.05)`,
        width: 244,
      },
    },
  }),
  device_location: ({ formValues }) => ({
    title: 'Device Location',
    showLocationName: formValues.show_location_name,
    color: formValues.color,
    points: [
      {
        type: 'Feature',
        id: '179ebb81-d6b1-45de-89f8-e90138c58879',
        properties: {
          cluster: false,
          type: 'main',
          id: '179ebb81-d6b1-45de-89f8-e90138c58879',
          coordinates: {
            lng: -122.4128146844463,
            lat: 37.80099060955891,
          },
          name: 'Device Location',
          status: 'online',
          renderSinglePoint: () => (
            <DeviceLocationMarker
              key="179ebb81-d6b1-45de-89f8-e90138c58879"
              title="Device Location"
              showLocationName={formValues.show_location_name}
              color={formValues.color}
              isDeviceOffline={false}
              lastUpdateTimestamp="1"
              status="online"
              lat={37.80099060955891}
              lng={-122.4128146844463}
            />
          ),
        },
        geometry: {
          type: 'Point',
          coordinates: [-122.4128146844463, 37.80099060955891],
        },
      },
    ],
    base: { lat: 37.80099060955891, lng: -122.4128146844463 },
    stackProps: {
      sx: {
        boxShadow: `0px 24px 36px rgba(13, 14, 48, 0.05)`,
        width: 244,
      },
    },
  }),
  grouped_switches: ({ formValues, onToggle = noop, deviceState = {} }) => ({
    onToggle,
    deviceState,
    activeCommands: [],

    switches: formValues.commands.map((command) => ({
      key: command.key,
      label: command.label,
      commandName: command.command_name,
      commandParamKey: command.command_param_key,
      displayTitle: formValues.display_title,
      iconName: command.icon_name,
      telemetry: command.telemetry,
      color: formValues.color,
    })),
  }),
  state_controller: ({ formValues, onToggle = noop }) => ({
    onToggle,
    color: formValues.color,
    title: formValues.telemetry_as_title
      ? formValues.title_telemetry
      : formValues.name || 'Title',
    value: formValues.options?.[0]?.value,
    displayIcons: formValues.display_icons,
    displayTitle: formValues.display_title,
    isLoading: false,
    options: formValues.options.map((option) => ({
      key: option.key,
      label: option.label,
      value: option.value,
      iconName: option.icon_name,
      telemetryValue: option.value,
    })),
  }),
  bar_indicator: ({ formValues, value }) => ({
    title: formValues.telemetry_as_title
      ? formValues.title_telemetry
      : formValues.name || 'Title',
    value: value ? value : formValues.value,
    unit: formValues.unit,
    min: formValues.min,
    max: formValues.max,
    numOfDecimals: formValues.num_of_decimals,
    numberFormat: formValues.number_format,
    scaleType: formValues.scale_type,
    segments: formValues.segments,
    stackProps: {
      sx: {
        boxShadow: `0px 24px 36px rgba(13, 14, 48, 0.05)`,
        width: '100%',
      },
    },
  }),
  bar_controller: ({
    formValues,
    value,
    onChange = noop,
    isLoading = false,
  }) => ({
    value: value ? value : formValues.value,
    onChange,
    isLoading,
    title: formValues.telemetry_as_title
      ? formValues.title_telemetry
      : formValues.name || 'Title',
    unit: formValues.unit,
    min: formValues.min,
    max: formValues.max,
    gradient: formValues.gradient,
    stackProps: {
      sx: {
        boxShadow: `0px 24px 36px rgba(13, 14, 48, 0.05)`,
        width: '100%',
      },
    },
  }),
  section_header: ({ formValues }) => ({
    title: formValues.name || 'Title',
    textColor: formValues.text_color,
    backgroundColor: formValues.background_color,
    displayIcon: formValues.display_icon,
    iconName: formValues.icon_name,
    stackProps: {
      sx: {
        boxShadow: `0px 24px 36px rgba(13, 14, 48, 0.05)`,
        width: '100%',
      },
    },
  }),
  gauge_indicator: ({ formValues, value }) => ({
    title: formValues.telemetry_as_title
      ? formValues.title_telemetry
      : formValues.name || 'Title',
    value: value ? value : formValues.value,
    unit: formValues.unit,
    min: formValues.min,
    max: formValues.max,
    numOfDecimals: formValues.num_of_decimals,
    numberFormat: formValues.number_format,
    scaleType: formValues.scale_type,
    segments: formValues.segments,
    stackProps: {
      sx: {
        boxShadow: `0px 24px 36px rgba(13, 14, 48, 0.05)`,
        width: '100%',
      },
    },
  }),
};

export function getWidgetIcon(iconName: WidgetIconNameType) {
  return find({ iconName }, WIDGET_ICONS) || WIDGET_ICONS[0];
}
