import React from 'react';

import { DashboardContentLayout } from '@portals/framework';

import { OrganizationsTable } from './OrganizationsTable';
import { SupportStateProvider } from '../support-overview/SupportStateProvider';

export function Organizations() {
  return (
    <DashboardContentLayout
      pageTitle="Supported customers"
      subtitle="Customers supported by the current user"
      pos="relative"
    >
      <SupportStateProvider>
        <OrganizationsTable />
      </SupportStateProvider>
    </DashboardContentLayout>
  );
}
