import { LoadingOverlay } from '@mantine/core';
import React from 'react';

import {
  DeviceModelType,
  HardwareKeyType,
  useDeviceModelHardwareKeys,
} from '@portals/api/partners';
import {
  ModalButton,
  useHasSupportSeat,
  usePermissionAccess,
} from '@portals/framework';
import { ReactComponent as AddSquare } from '@portals/icons/linear/add-square.svg';
import { SmartTable } from '@portals/table';

import { AddHardwareKeyProps } from '../../../modals';

interface ModelHardwareKeysProps {
  model: DeviceModelType;
}

export function ModelHardwareKeys({ model }: ModelHardwareKeysProps) {
  const deviceModelHardwareKeys = useDeviceModelHardwareKeys(model.id);
  const hasSupportSeat = useHasSupportSeat();
  const { canEdit } = usePermissionAccess();

  if (!deviceModelHardwareKeys.isFetched || !deviceModelHardwareKeys.data) {
    return <LoadingOverlay visible />;
  }

  return (
    <SmartTable<HardwareKeyType>
      name="partners.settings.keys"
      keyField="id"
      noColumnsSelection
      noFilters
      noDataIndication={{ title: 'No hardware keys' }}
      data={deviceModelHardwareKeys.data}
      columns={[
        {
          dataField: 'friendly_name',
          text: 'Name',
        },
        { dataField: 'id', text: 'Key' },
        { dataField: 'expected_device_count', text: 'Max devices number' },
        {
          dataField: 'used_devices_count',
          text: 'Registered devices',
        },
      ]}
      additionalActions={() =>
        (hasSupportSeat || canEdit('models')) && (
          <ModalButton
            modalName="AddHardwareKey"
            data-testid="add-hardware-key-button"
            label="Add Hardware Key"
            leftIcon={<AddSquare />}
            data={{ modelId: model.id } as AddHardwareKeyProps['data']}
          />
        )
      }
    />
  );
}
