import React from 'react';

import { DeviceModelType } from '@portals/api/partners';
import { PermissionAccess } from '@portals/framework';
import { ReactComponent as Duplicate } from '@portals/icons/linear/copy.svg';
import { ReactComponent as Edit } from '@portals/icons/linear/edit.svg';
import { ReactComponent as FolderOpen } from '@portals/icons/linear/folder-open.svg';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import { RowMenuItem, RowMenuItems } from '@portals/table';
import { AccessLevelEnum, RowMenuWrapperType } from '@portals/types';

interface ModelsListRowMenuProps {
  wrapperProps: RowMenuWrapperType;
  deviceModel: DeviceModelType;
  onDuplicate: (model: DeviceModelType) => void;
  onEdit: (model: DeviceModelType) => void;
  onDelete: (model: DeviceModelType) => void;
  onPublish: (model: DeviceModelType) => void;
}
export function ModelsListRowMenu({
  deviceModel,
  wrapperProps,
  onDuplicate,
  onEdit,
  onDelete,
  onPublish,
}: ModelsListRowMenuProps) {
  const rowItems: RowMenuItem[] = [
    {
      id: 'edit',
      label: 'Edit',
      Icon: Edit,
      onClick: () => {
        wrapperProps.menuRef?.onClose();
        onEdit(deviceModel);
      },
    },
    {
      id: 'duplicate',
      label: 'Duplicate',
      Icon: Duplicate,
      onClick: () => {
        wrapperProps.menuRef?.onClose();
        onDuplicate(deviceModel);
      },
    },
  ];

  if (!deviceModel.active) {
    rowItems.push({
      id: 'publish',
      label: 'Publish',
      Icon: FolderOpen,
      onClick: () => {
        wrapperProps.menuRef?.onClose();
        onPublish(deviceModel);
      },
    });
  }

  if (deviceModel.devices_count === 0) {
    rowItems.push({
      id: 'delete',
      label: 'Delete',
      Icon: Trash,
      color: 'red',
      withTopDivider: true,
      onClick: () => {
        wrapperProps.menuRef?.onClose();
        onDelete(deviceModel);
      },
    });
  }

  return (
    <PermissionAccess
      minAccessLevel={AccessLevelEnum.Edit}
      permissionKeys="models"
    >
      <RowMenuItems wrapperProps={wrapperProps} items={rowItems} />
    </PermissionAccess>
  );
}
