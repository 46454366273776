import {
  Anchor,
  Box,
  Button,
  Group,
  Select,
  Stack,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { flatten } from 'lodash';
import { compact, find, flow, map } from 'lodash/fp';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';

import {
  DevTestingFormValues,
  HardwareKeyType,
  useDeviceModelHardwareKeys,
  useDeviceModels,
  usePartnerConfig,
  useRegisterDevice,
} from '@portals/api/partners';

import { CloudIdInput } from './CloudIdInput';
import Response from '../../../components/Response';
import TestCommand from '../../../components/TestCommand';
import { AUTH_METHOD } from '../../../constants/auth';

const INITIAL_VALUES: DevTestingFormValues = {
  device_model: '',
  hardware_key: '',
  cloud_id: '',
  mac: '',
  sn: '',
  firmware_version: '',
};

export function Register() {
  const form = useForm<DevTestingFormValues>({
    initialValues: INITIAL_VALUES,
  });

  const partnerConfig = usePartnerConfig();
  const registerDevice = useRegisterDevice();

  const deviceModels = useDeviceModels();
  const deviceModelsOptions = useMemo(() => {
    if (!deviceModels.isFetched) {
      return [];
    }

    return map(({ id, model, auth_method, active }) => {
      return {
        value: id,
        label: `${model} | Auth method: ${AUTH_METHOD[auth_method]} | ${
          active ? 'Published' : 'Unpublished'
        }`,
      };
    }, deviceModels.data);
  }, [deviceModels]);
  const selectedModel = form.values.device_model
    ? find({ id: form.values.device_model }, deviceModels.data)
    : null;

  const hardwareKeys = useDeviceModelHardwareKeys(form.values.device_model);
  const hardwareKeysOptions = useMemo(() => {
    if (!hardwareKeys.isFetched) {
      return [];
    }

    return flow([
      map((hardwareKey: HardwareKeyType) => {
        if (
          hardwareKey.used_devices_count === hardwareKey.expected_device_count
        ) {
          return null;
        }

        return {
          value: hardwareKey.id,
          label: `${hardwareKey.friendly_name} | ID: ${hardwareKey.id} | Used: ${hardwareKey.used_devices_count}/${hardwareKey.expected_device_count}`,
        };
      }),
      flatten,
      compact,
    ])(hardwareKeys.data);
  }, [hardwareKeys]);

  const formatValues = (
    values: DevTestingFormValues
  ): Partial<DevTestingFormValues> => {
    return {
      ...values,
      mac: selectedModel?.auth_method === 'mac_sn' ? values.mac : undefined,
      sn: values.sn,
      cloud_id:
        selectedModel?.auth_method === 'cloud_id'
          ? `${partnerConfig?.shortcode}${values.cloud_id}`
          : undefined,
    };
  };

  const onSubmit = async (values: DevTestingFormValues) => {
    const formatted = formatValues(values);

    try {
      await registerDevice.mutateAsync(formatted);
    } catch (err) {
      console.error(err);
    }
  };

  const response =
    registerDevice.data || registerDevice.error
      ? {
          status: registerDevice.status,
          data: registerDevice.data || registerDevice.error,
        }
      : null;

  useUpdateEffect(
    function resetHardwareKeyOnDeviceModelChange() {
      form.setFieldValue('hardware_key', '');
    },
    [form.values.device_model]
  );

  return (
    <Stack>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Stack spacing="md">
          <div>
            <Select
              label="Device Model"
              name="device_model"
              data-testid="register-device-model"
              searchable
              multiple={false}
              data={deviceModelsOptions}
              {...form.getInputProps('device_model')}
            />

            <Box mt="xs">
              The{' '}
              <Anchor
                href="https://dev.xyte.io/reference/register-device"
                target="_blank"
                rel="noreferrer"
              >
                Device Model
              </Anchor>{' '}
              can be found under: Models &gt; Model
              {selectedModel ? (
                <>
                  {' '}
                  <Anchor to={`/models/${selectedModel.id}`} component={Link}>
                    ({selectedModel.model})
                  </Anchor>
                </>
              ) : null}
            </Box>
          </div>

          <div>
            <Select
              key={hardwareKeys.dataUpdatedAt}
              label="Hardware key"
              name="hardware_key"
              data-testid="register-device-hardware-key"
              searchable
              multiple={false}
              data={hardwareKeysOptions}
              disabled={
                !form.values.device_model || hardwareKeys.isInitialLoading
              }
              {...form.getInputProps('hardware_key')}
            />

            <Box mt="xs">
              The{' '}
              <Anchor
                href="https://dev.xyte.io/reference/hardware-keys"
                target="_blank"
                rel="noreferrer"
              >
                Hardware key
              </Anchor>{' '}
              can be found under: Models &gt; Model &gt; Hardware Keys
              {selectedModel ? (
                <>
                  {' '}
                  <Link to={`/models/${selectedModel.id}/hardware_keys`}>
                    ({selectedModel.model} HW keys)
                  </Link>
                </>
              ) : null}
            </Box>
          </div>

          {selectedModel?.auth_method === 'cloud_id' ? (
            <CloudIdInput
              fieldName="cloud_id"
              shortcode={partnerConfig?.shortcode || ''}
              setFieldValue={form.setFieldValue}
              {...form.getInputProps('cloud_id')}
            />
          ) : null}

          {selectedModel?.auth_method === 'mac_sn' ? (
            <TextInput
              label="MAC address"
              data-testid="register-device-serial-number"
              name="mac"
              placeholder="MAC address"
              {...form.getInputProps('mac')}
            />
          ) : null}

          <TextInput
            label="Serial number"
            data-testid="register-device-serial-number"
            name="sn"
            placeholder="Serial number"
            {...form.getInputProps('sn')}
          />

          <TextInput
            label="Firmware version"
            data-testid="register-device-firmware-version"
            name="firmware_version"
            placeholder="Firmware version"
            {...form.getInputProps('firmware_version')}
          />

          <Group>
            <Button
              data-testid="register-device-button"
              type="submit"
              loading={registerDevice.isLoading}
            >
              Register
            </Button>
          </Group>
        </Stack>
      </form>

      <TestCommand
        url=""
        data={formatValues(form.values)}
        noAuth
        provisionServer
      />

      <Response response={response} />
    </Stack>
  );
}
