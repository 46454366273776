import { Button, createStyles, Group, Modal, Stack, Text } from '@mantine/core';
import React from 'react';

import {
  DeviceModelType,
  useBulkEditDeviceModels,
} from '@portals/api/partners';
import { DeviceAvatar, ModalProps } from '@portals/framework';

export interface PublishDeviceModelsSummarizedModalProps
  extends ModalProps<{
    deviceModels: Array<DeviceModelType>;
  }> {}

export function PublishDeviceModelsSummarized({
  closeMe,
  data: modalData,
}: PublishDeviceModelsSummarizedModalProps) {
  const { classes } = useStyles();
  const updateDeviceModels = useBulkEditDeviceModels();

  const onSubmit = async () => {
    try {
      const activeDeviceModels = modalData.deviceModels.map((deviceModel) => ({
        id: deviceModel.id,
        active: true,
      }));

      await updateDeviceModels.mutateAsync(activeDeviceModels);

      closeMe();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal opened onClose={closeMe} title="Publish Model">
      <Stack spacing="md">
        {modalData.deviceModels.map(
          ({ id: key, model: label, user_settings }) => (
            <Group key={key} className={classes.row}>
              <DeviceAvatar
                img={user_settings.media?.img}
                icon={user_settings.icon}
                size={36}
              />

              <Text size="sm" color="blue_gray.9">
                {label}
              </Text>
            </Group>
          )
        )}

        <Group grow spacing="md">
          <Button variant="default" onClick={closeMe}>
            Back
          </Button>

          <Button
            onClick={onSubmit}
            loading={updateDeviceModels.isLoading}
            data-testid="publish-models"
          >
            Publish Models
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
}

const useStyles = createStyles((theme) => ({
  row: {
    borderBottom: `1px solid ${theme.colors.gray[3]}`,
    padding: `${theme.spacing.md} ${theme.spacing.xs}`,
  },
}));
