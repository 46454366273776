import {
  Box,
  ClassNames,
  createStyles,
  LoadingOverlay,
  Modal,
  ModalProps,
  Stack,
  Text,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React from 'react';

interface ModalCenteredMediaLayoutProps extends ModalProps {
  media: React.ReactNode;
  mediaSize?: number | string;
  description?: React.ReactNode;
  footer?: React.ReactNode;
  isLoading?: boolean;
  classNames?: ModalProps['classNames'] & ClassNames<'media' | 'headerContent'>;
}

export function ModalCenteredMediaLayout({
  children,
  title,
  media,
  mediaSize = 96,
  description,
  footer,
  withCloseButton = true,
  closeButtonProps,
  isLoading = false,
  classNames,
  ...modalProps
}: ModalCenteredMediaLayoutProps) {
  const { classes, cx, theme } = useStyles(mediaSize);
  const isSmallerThanMd = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

  return (
    <Modal.Root
      centered
      radius="lg"
      size="lg"
      padding={isSmallerThanMd ? 'xl' : 'xxl'}
      className={classNames?.root}
      {...modalProps}
    >
      <Modal.Overlay />

      <Modal.Content className={classNames?.content}>
        <Modal.Header className={cx(classes.header, classNames?.header)}>
          <Stack
            className={cx(classes.headerContent, classNames?.headerContent)}
          >
            <div className={cx(classes.media, classNames?.media)}>{media}</div>

            {title ? (
              <Modal.Title data-testid="modal-title">{title}</Modal.Title>
            ) : null}

            {description ? <Text size="sm">{description}</Text> : null}
          </Stack>

          {withCloseButton && (
            <Modal.CloseButton data-testid="modal-close-button" size="md" />
          )}
        </Modal.Header>

        <Modal.Body pos="relative">
          {children}

          {footer ? <Box mt="xxl">{footer}</Box> : null}

          <LoadingOverlay visible={isLoading} />
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
}

const useStyles = createStyles((_, mediaSize?: number | string) => ({
  header: {
    alignItems: 'flex-start',
  },
  headerContent: {
    flexGrow: 1,
    textAlign: 'center',
  },
  media: {
    display: 'flex',
    justifyContent: 'center',

    '> *': {
      width: mediaSize,
      height: mediaSize,
    },
  },
}));
