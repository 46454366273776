import { createStyles, LoadingOverlay, Progress } from '@mantine/core';
import { filter, isEmpty, size } from 'lodash/fp';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { DeviceModelType, useDeviceModels } from '@portals/api/partners';
import { useFetchDashboardStepsStatus } from '@portals/framework';
import { useOpenModal } from '@portals/redux';

import { TaskItem } from './TaskItem';
import { CardContainer, MainContentCardTitle } from '../common';

const useStyles = createStyles((theme) => ({
  container: {
    height: '100%',
    position: 'relative',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginBottom: theme.spacing.lg,

    [theme.fn.largerThan('xl')]: {
      marginBottom: theme.spacing.xl,
    },
  },
  progress: {
    flex: '0 0 120px',
  },
  taskList: {
    '> *:not(:last-child)': {
      borderBottom: `1px solid ${theme.colors.blue_gray[0]}`,
    },
  },
}));

export function TasksCard() {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const deviceModels = useDeviceModels();
  const openModal = useOpenModal();
  const { data: stepsStatus, isLoading } = useFetchDashboardStepsStatus();

  const modalNameToOpen = isEmpty(deviceModels?.data)
    ? 'AddModel'
    : 'SelectModel';

  const progressValue = calcProgressValue(stepsStatus);

  return (
    <CardContainer className={classes.container}>
      <LoadingOverlay visible={isLoading} />

      <header className={classes.header}>
        <MainContentCardTitle>
          Follow the next steps to create your first model
        </MainContentCardTitle>
        <Progress
          className={classes.progress}
          size="xs"
          value={progressValue}
        />
      </header>

      <div className={classes.taskList}>
        <TaskItem
          iconFileName="shop-add.svg"
          title="Create your first digital product"
          description="Generating new revenue streams begins here"
          isCompleted={Boolean(stepsStatus?.products_exist)}
          onClick={() => {
            openModal(modalNameToOpen, {
              title: 'Create your first digital product',
              onSubmit: (model: DeviceModelType) =>
                navigate(`/models/${model.id}/products`),
            });
          }}
        />

        <TaskItem
          iconFileName="code.svg"
          title="Set up your commands"
          description="Easily set up any command like restart, generating dump files and more"
          isCompleted={Boolean(stepsStatus?.commands_exist)}
          onClick={() => {
            openModal(modalNameToOpen, {
              title: 'Set up your commands',
              onSubmit: (model: DeviceModelType) => {
                navigate(`/models/${model.id}/commands`);
                openModal('AddSupportedCommand', model.id);
              },
            });
          }}
        />

        <TaskItem
          iconFileName="folder-cloud.svg"
          title="Upload files"
          description="Upload firmware files, documentations and others"
          isCompleted={Boolean(stepsStatus?.files_exist)}
          onClick={() => {
            openModal(modalNameToOpen, {
              title: 'Upload files',
              onSubmit: (model: DeviceModelType) => {
                navigate(`/models/${model.id}/files`);
                openModal('FileUploader', { modelId: model.id });
              },
            });
          }}
        />

        <TaskItem
          iconFileName="warning-2.svg"
          title="Create incident rules"
          description="Use telemetries to alert your users when needed. No coding required"
          isCompleted={Boolean(stepsStatus?.rules_exist)}
          onClick={() => {
            openModal(modalNameToOpen, {
              title: 'Create incident rules',
              onSubmit: (model: DeviceModelType) => {
                navigate(`/models/${model.id}/rules`);
                openModal('AddRule', model);
              },
            });
          }}
        />

        <TaskItem
          iconFileName="radar.svg"
          title="Define your telemetries"
          description="Easily define what telemetries should be sent by your devices"
          isCompleted={false}
          disabled
          isComingSoon
          onClick={() =>
            openModal(modalNameToOpen, {
              title: 'Define your telemetries',
              onSubmit: (model: DeviceModelType) => {
                console.log('T.B.D');
              },
            })
          }
        />
      </div>
    </CardContainer>
  );
}

function calcProgressValue(
  stepsStatus: ReturnType<typeof useFetchDashboardStepsStatus>['data']
) {
  const completedCount = filter(Boolean, stepsStatus).length;

  return Math.round((completedCount / size(stepsStatus)) * 100);
}
