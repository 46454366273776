import { ActionIcon, createStyles, Menu, Text } from '@mantine/core';
import React, { FC } from 'react';

import { ReactComponent as More } from '@portals/icons/linear/more.svg';
import { ComponentRendererType } from '@portals/types';

interface MenuItemType {
  title: string;
  Icon: ComponentRendererType;
  onClick: () => void;
  color?: string;
}

interface ItemMenuProps {
  items: Array<MenuItemType>;
}

export const ItemMenu: FC<ItemMenuProps> = ({ items }) => {
  const { classes } = useStyles();

  return (
    <Menu trigger="click" classNames={classes}>
      <Menu.Target>
        <ActionIcon>
          <More className={classes.menuIcon} />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        {items.map(({ Icon, title, onClick, color }) => (
          <Menu.Item
            key={title}
            icon={<Icon width={16} height={16} />}
            onClick={onClick}
            sx={(theme) => ({
              color: color ? theme.colors[color][7] : theme.colors.blue_gray[7],
            })}
          >
            <Text size="sm">{title}</Text>
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};

const useStyles = createStyles((theme) => ({
  item: {
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
  },
  itemBody: {
    padding: 0,
  },
  menuIcon: {
    color: theme.colors.gray[4],
    fill: theme.colors.gray[4],
  },
}));
