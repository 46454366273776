import React from 'react';

import { StoreListingsTable } from './StoreListingsTable';
import emptyStoreListingsSrc from '../../../assets/img/store-listing-empty-state.svg';
import { AddNewProductButton } from '../AddNewProductButton';

export function AllStoreListingsTable() {
  return (
    <StoreListingsTable
      storeListingStatus={undefined}
      noDataIndication={{
        title: 'No product listings found',
        assetSrc: emptyStoreListingsSrc,
        actions: <AddNewProductButton />,
      }}
    />
  );
}
