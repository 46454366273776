import { Anchor, Box, Button, Group, LoadingOverlay } from '@mantine/core';
import React from 'react';

import {
  ReferralCode,
  useDeleteReferralCode,
  useReferralCodes,
} from '@portals/api/partners';
import { CopyToClipboard } from '@portals/core';
import {
  useConfirmationModal,
  usePortalProtocolAndDomain,
} from '@portals/framework';
import { ReactComponent as Edit } from '@portals/icons/linear/edit.svg';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import { useOpenModal } from '@portals/redux';
import { RowMenuItems, SmartTable } from '@portals/table';
import { RowMenuContentRendererParams, TableColumn } from '@portals/types';

import { ReferralCodeModalProps } from '../../../modals';

function ReferralLinkCell({ link }: { link: string }) {
  return (
    <Group noWrap>
      <Anchor truncate href={link} target="_blank">
        {link}
      </Anchor>
      <CopyToClipboard value={link} />
    </Group>
  );
}

export function ReferralCodesTable() {
  const openModal = useOpenModal();
  const asyncConfirmation = useConfirmationModal();

  const referralCodes = useReferralCodes();
  const deleteReferralCode = useDeleteReferralCode();
  const { protocolAndDomain, isLoading } = usePortalProtocolAndDomain();

  const showLoadingOverlay =
    isLoading || referralCodes.isFetching || deleteReferralCode.isLoading;

  const onDelete = async (referralCodeId: string) => {
    const isConfirmed = await asyncConfirmation({
      description: 'Are you sure you want to delete this referral code?',
    });

    if (isConfirmed) {
      deleteReferralCode.mutate({
        referralCodeId,
      });
    }
  };

  const getSignupLink = (referralCodeId: ReferralCode['id']) => {
    return `${protocolAndDomain}/auth/sign-up?referral=${referralCodeId}`;
  };

  const getShopLink = (referralCodeId: ReferralCode['id']) => {
    return `${protocolAndDomain}/store?referral=${referralCodeId}`;
  };

  const columns: TableColumn<ReferralCode>[] = [
    {
      dataField: 'title',
      text: 'Name',
    },
    {
      dataField: 'referral_code',
      text: 'Code',
      maxWidth: 100,
      minWidth: 100,
    },
    {
      dataField: 'signup_link',
      text: 'Signup link',
      formatter: (_, row) => {
        const signupLink = getSignupLink(row.referral_code);

        return <ReferralLinkCell link={signupLink} />;
      },
    },
    {
      dataField: 'shop_link',
      text: 'Shop link',
      formatter: (_, row) => {
        const shopLink = getShopLink(row.referral_code);

        return <ReferralLinkCell link={shopLink} />;
      },
    },
    {
      dataField: 'registered',
      text: 'Registered customers',
      formatter: (_, row) => {
        return row.registered?.length || 0;
      },
    },
  ];

  return (
    <Box pos="relative">
      <LoadingOverlay visible={showLoadingOverlay} />

      <SmartTable<ReferralCode>
        exportParams={{ isEnabled: false }}
        noColumnsSelection
        name="referral_codes"
        keyField="id"
        data={referralCodes.data || []}
        columns={columns}
        rowMenu={({ row, wrapperProps }) => (
          <ReferralCodesTableRowMenu
            row={row}
            wrapperProps={wrapperProps}
            onDelete={() => onDelete(row.original.id)}
          />
        )}
        additionalActions={() => (
          <Button
            onClick={() =>
              openModal<ReferralCodeModalProps['data']>('ReferralCodeModal', {
                referralCode: undefined,
              })
            }
          >
            Create Referral
          </Button>
        )}
      />
    </Box>
  );
}

function ReferralCodesTableRowMenu({
  wrapperProps,
  row,
  onDelete,
}: RowMenuContentRendererParams<ReferralCode> & { onDelete: () => void }) {
  const { menuRef } = wrapperProps;
  const openModal = useOpenModal();

  return (
    <RowMenuItems
      wrapperProps={wrapperProps}
      items={[
        {
          id: 'edit',
          label: 'Edit',
          Icon: Edit,
          onClick: () => {
            menuRef?.onClose();
            openModal<ReferralCodeModalProps['data']>('ReferralCodeModal', {
              referralCode: row.original,
            });
          },
        },
        {
          id: 'delete',
          label: 'Delete',
          Icon: Trash,
          color: 'red',
          withTopDivider: true,
          onClick: () => {
            menuRef?.onClose();
            onDelete();
          },
        },
      ]}
    />
  );
}
