import { Button, Group, Stack, Text } from '@mantine/core';
import React from 'react';
import { useDispatch } from 'react-redux';

import Response from '../../components/Response';
import TestCommand from '../../components/TestCommand';
import { getChildDevices, useGetApiDevice } from '../../redux/actions/api';

export function GetChildDevices() {
  const dispatch = useDispatch();

  const access = useGetApiDevice();

  return (
    <Stack>
      <Group>
        <Button
          onClick={() => dispatch(getChildDevices(access.id))}
          disabled={!access.id}
        >
          Get
        </Button>
        <Text>
          Device ID:
          {access.id || 'Not set'}
        </Text>
      </Group>

      {access.id && <TestCommand url={`/${access.id}/children`} />}

      <Response type="getChildDevices" />
    </Stack>
  );
}
