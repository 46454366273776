import { Button, TextInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import React from 'react';
import { InferType, object, string } from 'yup';

import {
  ReferralCode,
  useCreateReferralCode,
  useUpdateReferralCode,
} from '@portals/api/partners';
import {
  ModalBody,
  ModalCenteredMediaLayout,
  ModalFooter,
} from '@portals/core';
import { ModalProps } from '@portals/framework';
import { Global } from '@portals/framework/icons';

const schema = object({
  name: string().required(),
});

export interface ReferralCodeModalProps
  extends ModalProps<{ referralCode: ReferralCode | undefined }> {}

export function ReferralCodeModal({
  closeMe,
  data: { referralCode },
}: ReferralCodeModalProps) {
  const createReferralCode = useCreateReferralCode();
  const updateReferralCode = useUpdateReferralCode();

  const form = useForm<InferType<typeof schema>>({
    validate: yupResolver(schema),
    initialValues: {
      name: referralCode?.title || '',
    },
  });

  const onSubmit = async (values: typeof form.values) => {
    try {
      if (referralCode !== undefined) {
        await updateReferralCode.mutateAsync({
          referralCodeId: referralCode.id,
          title: values.name,
        });

        closeMe();
      } else {
        await createReferralCode.mutateAsync({
          title: values.name,
        });

        closeMe();
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <ModalCenteredMediaLayout
      opened
      onClose={closeMe}
      title={
        referralCode !== undefined
          ? 'Edit referral code'
          : 'Create referral code'
      }
      media={<Global />}
    >
      <form noValidate onSubmit={form.onSubmit(onSubmit)}>
        <ModalBody>
          <TextInput
            data-autofocus
            required
            label="Name"
            placeholder="Name"
            {...form.getInputProps('name')}
          />
        </ModalBody>
        <ModalFooter position="right">
          <Button variant="default" onClick={closeMe}>
            Cancel
          </Button>
          <Button
            type="submit"
            loading={
              createReferralCode.isLoading || updateReferralCode.isLoading
            }
          >
            {referralCode !== undefined ? 'Save' : 'Create'}
          </Button>
        </ModalFooter>
      </form>
    </ModalCenteredMediaLayout>
  );
}
