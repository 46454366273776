import { createStyles, Grid } from '@mantine/core';
import React from 'react';

import { useSupportOverviewKpis } from '@portals/api/partners';
import { KpiWidget, KpiWidgetType } from '@portals/core';
import { ReactComponent as Danger } from '@portals/icons/bold//danger.svg';
import { ReactComponent as CPUCharge } from '@portals/icons/bold/cpu-charge.svg';
import { ReactComponent as LifeBuoy } from '@portals/icons/bold/lifebuoy.svg';
import { ReactComponent as ProfileCircle } from '@portals/icons/bold/profile-circle.svg';
import { formatNumber } from '@portals/utils';

import { useSupportState } from './SupportStateProvider';

export function KpiWidgets() {
  const { classes } = useStyles();

  const { isLabIncluded } = useSupportState();
  const supportOverviewKpis = useSupportOverviewKpis(isLabIncluded);

  const kpiWidgetItems: Array<KpiWidgetType> = [
    {
      title: 'Customers',
      avatarColor: 'teal',
      label: formatNumber(supportOverviewKpis.data?.customers_count, {
        fallbackValue: 0,
      }),
      icon: <ProfileCircle />,
    },
    {
      title: 'Monitored Devices',
      avatarColor: 'deep_purple',
      label: formatNumber(supportOverviewKpis.data?.monitored_devices_count, {
        fallbackValue: 0,
      }),
      icon: <CPUCharge />,
    },
    {
      title: 'Active Incidents',
      avatarColor: 'amber',
      label: formatNumber(supportOverviewKpis.data?.incidents_count, {
        fallbackValue: 0,
      }),
      icon: <Danger />,
    },
    {
      title: 'Open Tickets',
      avatarColor: 'indigo',
      label: formatNumber(supportOverviewKpis.data?.tickets_count, {
        fallbackValue: 0,
      }),
      icon: <LifeBuoy />,
    },
  ];

  return (
    <Grid grow justify="space-between" className={classes.wrapper} gutter="xl">
      {kpiWidgetItems?.map((kpiWidgetItem, index) => (
        <Grid.Col span={2} key={index}>
          <KpiWidget kpiWidget={kpiWidgetItem} />
        </Grid.Col>
      ))}
    </Grid>
  );
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    flexWrap: 'nowrap',
  },
}));
