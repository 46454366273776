import {
  ActionIcon,
  Box,
  createStyles,
  Group,
  Stack,
  Textarea,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { Reorder, useDragControls } from 'framer-motion';
import React from 'react';

import { TroubleshootingStepType } from '@portals/api/partners';
import { PopoverIconSelector } from '@portals/device-widgets';
import { ReactComponent as CloseCircle } from '@portals/icons/bold/close-circle.svg';
import { ReactComponent as Drag } from '@portals/icons/linear/drag.svg';

interface TroubleshootingStepProps {
  index: number;
  form: UseFormReturnType<{
    steps: Array<TroubleshootingStepType & { id: string }>;
  }>;
}

export function TroubleshootingStep({ index, form }: TroubleshootingStepProps) {
  const theme = useMantineTheme();
  const dragControls = useDragControls();
  const { cx, classes } = useStyles();

  const step = form.values.steps[index];

  return (
    <Reorder.Item
      key={step.id}
      value={step}
      dragListener={false}
      dragControls={dragControls}
      animate={false}
    >
      <Stack p="xl" spacing="md" bg="white" className={classes.container}>
        <Group w="100%" noWrap>
          <PopoverIconSelector
            selectedIconName={step.icon}
            onChange={(iconName) =>
              form.setFieldValue(`steps.${index}.icon`, iconName)
            }
            actionIconProps={{
              color: theme.colors.gray[3],
              size: 24,
              sx: {
                flexShrink: 0,
              },
            }}
          />

          <TextInput
            w="100%"
            placeholder="Issue title"
            autoFocus
            id={`steps.${index}.title`}
            classNames={{
              input: cx(classes.inputs, classes.textInput),
            }}
            {...form.getInputProps(`steps.${index}.title`)}
          />
        </Group>

        <Box w="100%">
          <Textarea
            autosize
            id={`steps.${index}.description`}
            placeholder="Detailed description, which can help users prevent the issue or maintain their device better."
            classNames={{
              input: cx(classes.inputs, classes.textArea),
            }}
            {...form.getInputProps(`steps.${index}.description`)}
          />
        </Box>

        <Group position="center" align="center">
          <ActionIcon onPointerDown={(e) => dragControls.start(e)}>
            <Drag color={theme.colors.gray[3]} />
          </ActionIcon>
        </Group>
      </Stack>

      <Box
        pos="absolute"
        top={0}
        right={0}
        sx={{ transform: 'translateX(50%) translateY(-50%)' }}
      >
        <ActionIcon onClick={() => form.removeListItem('steps', index)}>
          <CloseCircle width={32} height={32} />
        </ActionIcon>
      </Box>
    </Reorder.Item>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    borderRadius: theme.radius.lg,
    border: `1px solid ${theme.colors.gray[2]}`,
  },

  inputs: {
    border: 'none',
    padding: 0,
    width: '100%',
    borderRadius: 0,
  },

  textInput: {
    fontSize: theme.fontSizes.md,
  },

  textArea: {
    fontSize: theme.fontSizes.sm,
  },
}));
