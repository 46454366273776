import { createStyles, Text } from '@mantine/core';
import React from 'react';
import { useRoutes } from 'react-router-dom';

import { useIsChannelPartner } from '@portals/api/partners';
import {
  Page403,
  Page404,
  useHasSupportSeat,
  usePermissionAccess,
} from '@portals/framework';
import { useIsSidebarOpen } from '@portals/redux';

import { HubsSidebar } from './hubs-sidebar/HubsSidebar';
import { useDashboardRoutes } from '../routes/dashboard/DashboardRoutesProvider';

export function HubsMainContent() {
  const isSidebarOpen = useIsSidebarOpen();

  const { classes, cx } = useStyles(isSidebarOpen);

  return (
    <div className={cx(classes.main, 'page-main')}>
      <HubsSidebar />

      <div id="page-content" className={cx(classes.content, 'page-content')}>
        <PageContent />
      </div>
    </div>
  );
}

function PageContent() {
  const dashboardRoutes = useDashboardRoutes();

  const routesElement = useRoutes(dashboardRoutes.routes);

  const hasSupportSeat = useHasSupportSeat();
  const isChannelPartner = useIsChannelPartner();

  const { isMissingAllPermissions, isSuperAdmin } = usePermissionAccess();

  const hasNoPermissions =
    isMissingAllPermissions && !isSuperAdmin && !hasSupportSeat;

  const channelPartnerHasNoSupportSeat = isChannelPartner && !hasSupportSeat;

  if (channelPartnerHasNoSupportSeat) {
    return (
      <Page403
        title={
          <Text fz="md" fw={500} ta="center">
            No support seat access
          </Text>
        }
        description={
          <Text fz="xs" fw={500} color="gray.5" ta="center">
            You don't have a support seat.
            <br />
            Please contact your admin to get access.
          </Text>
        }
      />
    );
  }

  if (hasNoPermissions) {
    return (
      <Page404
        hideActionButton
        hideImage
        title="Missing permissions"
        description="We're sorry, it looks like you don't have the necessary permissions to access this page. Please reach out to your administrator for assistance."
      />
    );
  }

  return routesElement;
}

const useStyles = createStyles((theme, isSidebarOpen: boolean) => ({
  main: {
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
    backgroundColor: theme.white,
    letterSpacing: '0.02em',

    display: 'grid',
    gridTemplateColumns: `${
      isSidebarOpen
        ? theme.other.sidebarFullWidth
        : theme.other.sidebarCollapsedWidth
    }px 1fr`,

    [theme.fn.smallerThan('xl')]: {
      gridTemplateColumns: `${
        isSidebarOpen
          ? theme.other.sidebarCompactWidth
          : theme.other.sidebarCollapsedWidth
      }px 1fr`,
    },
  },
  content: {
    flex: 1,
    overflow: 'auto',
    position: 'relative',
  },
}));
