import {
  ActionIcon,
  createStyles,
  Group,
  Skeleton,
  SkeletonProps,
  Stack,
  Text,
} from '@mantine/core';
import React, { useMemo } from 'react';

import { usePartnerConfig } from '@portals/api/partners';
import { ReactComponent as ArrowLeft } from '@portals/icons/linear/arrow-left.svg';

import { useBrandingContext } from '../../branding.context';

function SkeletonWithoutAnimation(props: SkeletonProps) {
  return <Skeleton animate={false} {...props} />;
}

export function SidebarMock() {
  const { classes } = useStyles();

  const { portalConfig } = useBrandingContext();
  const partnerConfig = usePartnerConfig();

  const navigationMenuLogo = useMemo(() => {
    if (partnerConfig?.is_cec_partner) {
      return 'logo_full_dark.svg';
    }

    return (
      portalConfig.design?.navigation_menu?.logo ||
      partnerConfig?.logo_url ||
      portalConfig.design?.sign_in_page?.logo ||
      'logo_full_dark.svg'
    );
  }, [
    partnerConfig?.is_cec_partner,
    partnerConfig?.logo_url,
    portalConfig.design?.navigation_menu?.logo,
    portalConfig.design?.sign_in_page?.logo,
  ]);

  return (
    <div className={classes.container}>
      <Group className={classes.header}>
        <img width={30} height={30} alt="logo" src={navigationMenuLogo} />

        <Text weight={700}>{partnerConfig?.display_name}</Text>
      </Group>

      <Stack spacing="xl" px="sm" sx={{ overflow: 'auto' }}>
        <SidebarSection />

        <SidebarSection active />

        <SidebarSection />
      </Stack>

      <Group position="right" className={classes.footer}>
        <ActionIcon color="blue_gray">
          <ArrowLeft width={15} height={15} />
        </ActionIcon>
      </Group>
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    overflow: 'hidden',
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'max-content 1fr max-content',
    borderRight: `2px solid ${theme.colors.gray[2]}`,
  },
  header: {
    paddingBlock: theme.spacing.sm,
    paddingInline: theme.spacing.xs,
  },
  footer: {
    paddingInline: theme.spacing.xs,
    paddingBlock: theme.spacing.sm,
    borderTop: `1px solid ${theme.colors.gray[2]}`,
  },
}));

function SidebarSection({ active = false }: { active?: boolean }) {
  const { classes, cx } = useSidebarSectionStyles();

  return (
    <div>
      <SkeletonWithoutAnimation width={65} height={8} />

      <Group spacing="xs" py="xs">
        <SkeletonWithoutAnimation width={20} height={20} circle />
        <SkeletonWithoutAnimation width={78} height={8} />
      </Group>

      <Group
        spacing="xs"
        py="xs"
        className={cx({ [classes.activeItem]: active })}
      >
        <SkeletonWithoutAnimation
          circle
          width={20}
          height={20}
          className={cx({ [classes.activeSkeleton]: active })}
        />
        <SkeletonWithoutAnimation
          width={57}
          height={8}
          className={cx({ [classes.activeSkeleton]: active })}
        />
      </Group>

      <Group spacing="xs" py="xs">
        <SkeletonWithoutAnimation width={20} height={20} circle />
        <SkeletonWithoutAnimation width={72} height={8} />
      </Group>
    </div>
  );
}

const useSidebarSectionStyles = createStyles((theme) => ({
  activeItem: {
    paddingInlineStart: theme.spacing.md,
    borderRadius: theme.radius.md,
    backgroundColor: theme.fn.primaryColor(),
  },
  activeSkeleton: {
    '&:after': {
      backgroundColor: theme.white,
    },
  },
}));
