import { Button, Stack, Text, Tooltip } from '@mantine/core';
import { any, keys, map } from 'lodash/fp';
import React from 'react';

import { useBulkDeleteDevices } from '@portals/api/partners';
import {
  ModalButton,
  useConfirmationModal,
  useHasSupportSeat,
  usePermissionAccess,
} from '@portals/framework';
import { ReactComponent as CloseSquare } from '@portals/icons/linear/close-square.svg';
import { ReactComponent as TickSquare } from '@portals/icons/linear/tick-square.svg';
import { useTableInstance } from '@portals/table';
import { DeviceType } from '@portals/types';

import { ClaimDeviceProps } from '../../modals';

export function Actions() {
  const { canEdit } = usePermissionAccess();
  const hasSupportSeat = useHasSupportSeat();
  const { state, rows } = useTableInstance();
  const { selectedRowIds } = state;
  const bulkDeleteDevices = useBulkDeleteDevices();
  const asyncConfirmation = useConfirmationModal();

  const selected: Array<DeviceType> = map(
    (index) => rows[index]?.original,
    keys(selectedRowIds)
  );

  const hasClaimedDevicesSelected = any({ claimed: true }, selected);

  const canRemoveDevice = canEdit('models') || hasSupportSeat;
  const canAssignDevices = canEdit('fulfillment');

  const handleDelete = async () => {
    const isConfirmed = await asyncConfirmation({
      title: 'Delete Devices',
      description: (
        <Stack>
          <Text>Are you sure you want to delete those devices?</Text>
          <Text>All historical information will be lost.</Text>
          <Text>
            Device deletion may take a few minutes and will occur in the
            background. You can continue working in the meantime.
          </Text>
        </Stack>
      ),
    });

    if (isConfirmed) {
      try {
        await bulkDeleteDevices.mutateAsync({
          device_ids: selected.map((device) => device.id),
        });
      } catch (err) {
        console.error(err);
      }
    }
  };

  if (!canRemoveDevice && !canAssignDevices) return null;

  return (
    <>
      {canRemoveDevice ? (
        <Tooltip label="Please remove claimed device from selection">
          <span>
            <Button
              size="xs"
              color="red"
              variant="subtle"
              leftIcon={<CloseSquare width={16} />}
              onClick={handleDelete}
            >
              Delete Devices
            </Button>
          </span>
        </Tooltip>
      ) : null}

      {canAssignDevices ? (
        <Tooltip
          disabled={!hasClaimedDevicesSelected}
          label="Please remove claimed device from selection"
        >
          <span>
            <ModalButton
              disabled={hasClaimedDevicesSelected}
              data-testid="assign-devices-button"
              modalName="ClaimDevice"
              label="Assign Devices"
              variant="subtle"
              data={{ devices: selected } satisfies ClaimDeviceProps['data']}
              leftIcon={<TickSquare />}
            />
          </span>
        </Tooltip>
      ) : null}
    </>
  );
}
