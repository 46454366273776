import {
  Anchor,
  Badge,
  createStyles,
  Group,
  Paper,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import React, { ReactNode } from 'react';

import {
  useIsChannelPartner,
  usePortalConfig,
  useRequestPlanUpgrade,
  useSupportSeats,
  useUpdatePortalConfig,
} from '@portals/api/partners';
import { usePortalDomain } from '@portals/framework';
import { ReactComponent as Slash } from '@portals/icons/linear/slash.svg';
import { ReactComponent as TickCircle } from '@portals/icons/linear/tick-circle.svg';
import { useOpenModal } from '@portals/redux';

import { usePricingPlanFeatures } from '../../../hooks/partner-config';
import { AddSupportSeatsModalProps } from '../../../modals';

const UNLIMITED = 1_000_000;

function Unlimited() {
  return (
    <Text color="teal.5" weight={500} size="sm">
      Unlimited
    </Text>
  );
}

interface FeatureRowProps {
  label: string;
  value: ReactNode;
}

function FeatureRow({ label, value }: FeatureRowProps) {
  const { classes } = useStyles();

  return (
    <Group
      position="apart"
      align="center"
      className={classes.featureRow}
      py="xl"
    >
      <Text color="blue_gray.9" size="sm">
        {label}
      </Text>

      {value}
    </Group>
  );
}

export function Features() {
  const theme = useMantineTheme();
  const openModal = useOpenModal();
  const currentDomainName = usePortalDomain();
  const isChannelPartner = useIsChannelPartner();

  const supportSeats = useSupportSeats();
  const portalConfig = usePortalConfig();
  const updatePortalConfig = useUpdatePortalConfig();
  const requestPlanUpgrade = useRequestPlanUpgrade();

  const pricingFeatures = usePricingPlanFeatures();

  const onAddSeatsClick = () => {
    if (isChannelPartner) {
      openModal<AddSupportSeatsModalProps['data']>('AddSupportSeatsModal');
    } else {
      requestPlanUpgrade.mutate(
        'Partners > Settings > Plan and Billing > Features > Add Seats'
      );
    }
  };

  return (
    <Paper withBorder p="xl" radius="md">
      <Stack spacing={0}>
        <FeatureRow
          label="Support seats"
          value={
            isUnlimited(supportSeats.data?.total) ? (
              <Unlimited />
            ) : (
              <Group spacing="xs" align="center">
                <Text color="blue_gray.9" size="sm">
                  {supportSeats.data?.total}
                </Text>

                <Text color="blue_gray.9" size="sm">
                  /
                </Text>

                <Anchor size="sm" onClick={onAddSeatsClick}>
                  Add Seats
                </Anchor>
              </Group>
            )
          }
        />

        <FeatureRow
          label="Max num. of registered devices"
          value={
            pricingFeatures?.registered_devices_limit === 0 ? (
              <Unlimited />
            ) : (
              pricingFeatures?.registered_devices_limit
            )
          }
        />

        <FeatureRow
          label="Max num. of device models"
          value={
            isUnlimited(pricingFeatures?.device_models_limit) ? (
              <Unlimited />
            ) : (
              pricingFeatures?.device_models_limit
            )
          }
        />

        <FeatureRow
          label="Max num. of custom commands per model"
          value={
            isUnlimited(pricingFeatures?.custom_device_commands) ? (
              <Unlimited />
            ) : (
              pricingFeatures?.custom_device_commands
            )
          }
        />

        <FeatureRow
          label="API access"
          value={
            pricingFeatures?.api_access ? (
              <TickCircle color={theme.colors.teal[7]} />
            ) : (
              <Slash color={theme.colors.red[4]} />
            )
          }
        />

        <FeatureRow
          label="Role based permissions"
          value={
            pricingFeatures?.role_based_permissions ? (
              <TickCircle color={theme.colors.teal[7]} />
            ) : (
              <Slash color={theme.colors.red[4]} />
            )
          }
        />

        <FeatureRow
          label="Audit log history retention"
          value={`${pricingFeatures?.audit_log_history_retention_in_months} ${
            pricingFeatures?.audit_log_history_retention_in_months === 1
              ? 'month'
              : 'months'
          }`}
        />

        <FeatureRow
          label="Custom domain"
          value={
            portalConfig.data?.candidate_domain ? (
              <Group align="center">
                <Text>{portalConfig.data?.candidate_domain}</Text>
                <Badge size="md">Verifying new domain...</Badge>
              </Group>
            ) : (
              <Group spacing="xs" align="center">
                <Text>{currentDomainName}</Text>
                <Text>/</Text>
                <Anchor
                  size="sm"
                  onClick={() =>
                    openModal('ChangeDomain', { onUpdate: updatePortalConfig })
                  }
                >
                  Update
                </Anchor>
              </Group>
            )
          }
        />

        <FeatureRow
          label='Toggle "Powered by XYTE" label'
          value={
            pricingFeatures?.powered_by_xyte_label_toggle ? (
              <TickCircle color={theme.colors.teal[7]} />
            ) : (
              <Slash color={theme.colors.red[4]} />
            )
          }
        />
      </Stack>
    </Paper>
  );
}

function isUnlimited(value: number | undefined) {
  return value === UNLIMITED;
}

const useStyles = createStyles((theme) => ({
  featureRow: {
    '&:not(:last-of-type)': {
      borderBottom: `1px solid ${theme.colors.gray[3]}`,
    },
  },
}));
