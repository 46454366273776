import { LoadingOverlay, Stack } from '@mantine/core';
import React from 'react';

import { useStoreSettings } from '@portals/api/partners';

import { Address } from './Address';
import { EnabledCurrencies } from './enabled-currencies';
import { PaymentMethods } from './PaymentMethods';
import { Policies } from './policies';
import { TaxGroups } from './tax-groups';

export function StoreSettings() {
  const storeSettings = useStoreSettings();

  return (
    <>
      <LoadingOverlay visible={storeSettings.isLoading} />

      <Stack spacing="xl" sx={{ maxWidth: 760 }}>
        <PaymentMethods />

        <EnabledCurrencies />

        <TaxGroups />

        <Address />

        <Policies />
      </Stack>
    </>
  );
}
