import { createStyles } from '@mantine/core';
import React from 'react';

import { usePortalDomain } from '@portals/framework';

import portalDomainPreviewBg from './portal-domain-preview-bg.jpg';
import { useBrandingContext } from '../../branding.context';
import { BrowserMock } from '../../browser-mock/BrowserMock';

export function PortalDomainPreview() {
  const { classes } = useStyles();

  const portalDomain = usePortalDomain();
  const { portalConfig } = useBrandingContext();

  return (
    <div className={classes.container}>
      <BrowserMock
        favicon={portalConfig.favicon}
        windowTitle={portalConfig.window_title}
        domain={portalDomain}
        className={classes.browserMock}
      >
        <img width="100%" src={portalDomainPreviewBg} alt="preview" />
      </BrowserMock>
    </div>
  );
}

const useStyles = createStyles({
  container: {
    height: '100%',
    paddingLeft: '20%',
    paddingTop: 140,
  },
  browserMock: {
    transform: 'scale(1.6)',
    transformOrigin: 'top left',
  },
});
