import { Button, Modal, Text } from '@mantine/core';
import { filter, find, lowerCase, map } from 'lodash/fp';
import React, { FormEvent, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import { DeviceModelType, useDeviceModels } from '@portals/api/partners';
import { ModalBody, ModalFooter } from '@portals/core';
import { ModalProps, PermissionAccess } from '@portals/framework';
import { useOpenModal } from '@portals/redux';
import { AccessLevelEnum } from '@portals/types';
import { SearchInput } from '@portals/ui';
import { getStyledThemeColor, useDebouncedValue } from '@portals/utils';

import { AddModelProps } from './AddModel';

function SelectModel({
  closeMe,
  data,
}: ModalProps<{ title: string; onSubmit: (model: DeviceModelType) => void }>) {
  const openModal = useOpenModal();

  const deviceModels = useDeviceModels();

  const [selectedModelId, setSelectedModelId] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebouncedValue(searchTerm, 300);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    const selectedModel = find({ id: selectedModelId }, deviceModels.data);

    if (!selectedModel) return;

    closeMe();
    data.onSubmit(selectedModel);
  };

  const handleCreateNewModel = () => {
    closeMe();
    openModal<AddModelProps['data']>('AddModel', data);
  };

  const filteredDeviceModels = useMemo(() => {
    const lowercaseSearchTerm = lowerCase(debouncedSearchTerm);

    return filter(
      (model) => lowerCase(model.model).includes(lowercaseSearchTerm),
      deviceModels.data
    );
  }, [debouncedSearchTerm, deviceModels.data]);

  return (
    <Modal opened onClose={closeMe} title={data.title}>
      <ModalBody>
        <Text>
          Before moving on, let's start with selecting the required model
        </Text>
        <SearchForm onSubmit={handleSubmit}>
          <SearchBar>
            <StyledSearchInput value={searchTerm} onChange={setSearchTerm} />

            <PermissionAccess
              minAccessLevel={AccessLevelEnum.Edit}
              permissionKeys="models"
            >
              <CreateButton type="button" onClick={handleCreateNewModel}>
                + Create new model
              </CreateButton>
            </PermissionAccess>
          </SearchBar>
          <ModelList>
            {map(
              (model) => (
                <ModelItem
                  key={model.id}
                  $selected={selectedModelId === model.id}
                  onClick={() => setSelectedModelId(model.id)}
                >
                  {model.model}
                </ModelItem>
              ),
              filteredDeviceModels
            )}
          </ModelList>
        </SearchForm>
      </ModalBody>

      <ModalFooter position="right">
        <Button onClick={closeMe} variant="default">
          Cancel
        </Button>
        <Button disabled={!selectedModelId} onClick={handleSubmit}>
          Continue
        </Button>
      </ModalFooter>
    </Modal>
  );
}

const SearchForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const SearchBar = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledSearchInput = styled(SearchInput)`
  flex: 1;
`;

const CreateButton = styled.button`
  color: ${getStyledThemeColor('primary')};
  border: none;
  background: none;

  &:disabled {
    opacity: 0.7;
  }
`;

const ModelList = styled.div`
  flex: 1 0 300px;
  margin-top: 16px;
  overflow: auto;
`;

const ModelItem = styled.div<{ $selected: boolean }>`
  display: flex;
  align-items: center;
  height: 50px;
  padding: 8px;
  cursor: pointer;

  &:hover {
    background-color: #fafafa;
  }

  ${({ $selected }) =>
    $selected &&
    css`
      background-color: #fafafa;
    `};
`;

export default SelectModel;
