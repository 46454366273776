import { Button, Group, Stack, TextInput } from '@mantine/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Response from '../../components/Response';
import TestCommand from '../../components/TestCommand';
import { getFile, useGetApiDevice } from '../../redux/actions/api';

export function GetFile() {
  const [fileId, setFileId] = useState('');

  const dispatch = useDispatch();
  const access = useGetApiDevice();

  return (
    <Stack>
      <TextInput
        placeholder="File ID"
        onChange={(event) => setFileId(event.target.value)}
        value={fileId}
      />

      <Group>
        <Button
          onClick={() => dispatch(getFile(access.id, fileId))}
          disabled={!access.id || !fileId}
        >
          Get
        </Button>
        <span>
          Device ID:
          {access.id || 'Not set'}
        </span>
      </Group>

      {access.id ? <TestCommand url={`/${access.id}/files/${fileId}`} /> : null}

      <Response type="getFile" />
    </Stack>
  );
}
