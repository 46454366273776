import { LoadingOverlay } from '@mantine/core';
import React from 'react';
import { Navigate } from 'react-router-dom';

import { useConfig, usePortalConfig } from '@portals/api/partners';
import { useCurrentUser } from '@portals/api/ui';

import { HubsLayout } from '../../hubs/HubsLayout';

export function DashboardLayout() {
  const config = useConfig();
  const portalConfig = usePortalConfig();
  const currentUser = useCurrentUser();

  const isFetched =
    config.isFetched && portalConfig.isFetched && currentUser.isFetched;

  if (!isFetched) {
    return <LoadingOverlay visible />;
  }

  if (config.data?.partner?.onboarding_step !== 'complete') {
    return <Navigate to="/onboarding" replace />;
  }

  return <HubsLayout />;
}
