import { Button, createStyles, Group } from '@mantine/core';
import React from 'react';

import { useBrandingContext } from './branding.context';

export function Footer() {
  const { classes } = useStyles();

  const { isPristine, revertChanges, isLoading, saveChanges } =
    useBrandingContext();

  return (
    <Group position="right" className={classes.container}>
      <Button
        variant="white"
        color="dark"
        onClick={revertChanges}
        disabled={isPristine || isLoading}
      >
        Cancel
      </Button>

      <Button onClick={saveChanges} loading={isLoading} disabled={isPristine}>
        Save Changes
      </Button>
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    paddingBlock: theme.spacing.md,
    paddingInline: theme.spacing.xl,
    borderTop: `1px solid ${theme.colors.gray[2]}`,
  },
}));
