import React from 'react';

import emptyStoreListingsSrc from '../../../assets/img/store-listing-empty-state.svg';
import { StoreListingsTable } from './StoreListingsTable';

export function PublishedStoreListingsTable() {
  return (
    <StoreListingsTable
      storeListingStatus="published"
      noDataIndication={{
        title: 'No published product listings found',
        assetSrc: emptyStoreListingsSrc,
      }}
    />
  );
}
