import React from 'react';

import { StoreListing } from '@portals/api/partners';
import { DetailsPanel } from '@portals/core';

import {
  canArchiveStoreListing,
  canDeleteStoreListing,
  canPublishStoreListing,
} from '../../store-listings-page.utils';

interface DetailsPanelActionsProps {
  storeListing: StoreListing;
  onArchive: (storeListing: StoreListing) => void;
  onDelete?: (storeListing: StoreListing) => void;
  onEdit?: (storeListing: StoreListing) => void;
  onPublish?: (storeListing: StoreListing) => void;
}

export function DetailsPanelActions({
  storeListing,
  onEdit,
  onPublish,
  onDelete,
  onArchive,
}: DetailsPanelActionsProps) {
  return (
    <DetailsPanel.Actions>
      {canPublishStoreListing(storeListing) && onPublish ? (
        <DetailsPanel.ActionButton
          variant="filled"
          onClick={() => onPublish(storeListing)}
        >
          Publish
        </DetailsPanel.ActionButton>
      ) : null}

      {onEdit ? (
        <DetailsPanel.ActionButton
          data-testid="edit-store-listing-button"
          onClick={() => onEdit(storeListing)}
        >
          Edit
        </DetailsPanel.ActionButton>
      ) : null}

      {canArchiveStoreListing(storeListing) && onArchive ? (
        <DetailsPanel.ActionButton
          data-testid="archive-store-listing-button"
          onClick={() => onArchive(storeListing)}
        >
          Archive
        </DetailsPanel.ActionButton>
      ) : null}

      {canDeleteStoreListing(storeListing) && onDelete ? (
        <DetailsPanel.ActionButton
          data-testid="delete-store-listing-button"
          onClick={() => onDelete(storeListing)}
        >
          Delete
        </DetailsPanel.ActionButton>
      ) : null}
    </DetailsPanel.Actions>
  );
}
