import { Button } from '@mantine/core';
import React from 'react';
import { Card } from 'reactstrap';

import { useUpdateTicket } from '@portals/api/partners';
import { TicketStatusBadge } from '@portals/framework';
import { ReactComponent as TickSquare } from '@portals/icons/linear/tick-square.svg';
import { AvatarCell, BooleanCell, InfoTable } from '@portals/table';
import { TicketType } from '@portals/types';

import { InsightsDemoAi } from './insights-demo-ai/InsightsDemoAI';

interface TicketDetailsProps {
  ticket: TicketType;
}

export function TicketDetails({ ticket }: TicketDetailsProps) {
  const updateTicket = useUpdateTicket(ticket.id);

  return (
    <Card color="white">
      <InfoTable.Table>
        <InfoTable.Header>
          <InsightsDemoAi />

          <Button
            disabled={ticket.status !== 'open'}
            onClick={() => updateTicket.mutate({ status: 'review' })}
            size="xs"
            leftIcon={<TickSquare width={16} height={16} />}
          >
            Mark Solved (For review)
          </Button>
        </InfoTable.Header>

        <InfoTable.Row label="Title" value={ticket.title || 'No Title'} />

        <InfoTable.Row
          label="Description"
          value={ticket.description || 'No Description'}
        />

        <InfoTable.Row
          label="Organization"
          value={
            <AvatarCell
              src={ticket.organization_logo_url}
              label={ticket.organization_display_name}
              radius="md"
            />
          }
        />

        <InfoTable.Row
          label="Status"
          value={<TicketStatusBadge status={ticket.status} w="fit-content" />}
        />

        <InfoTable.Row
          label={`Seen by ${ticket.organization_display_name}`}
          value={<BooleanCell isActive={ticket.org_seen} />}
        />
      </InfoTable.Table>
    </Card>
  );
}
