import { Stack } from '@mantine/core';
import React from 'react';

import { DesignImageInput } from '../../../common/fields/ImageField';
import { TextareaField } from '../../../common/fields/TextareaField';

export function AuthPagesFormCEC() {
  return (
    <Stack spacing="md">
      <DesignImageInput
        valuePath="design.cec_auth_page.logo"
        field={{
          title: 'Logo',
          name: 'logo',
          cropConfig: {
            aspectRatio: 2.5,
            width: 500,
            height: 200,
          },
          previewConfig: {
            width: 500,
            height: 200,
          },

          height: 200,
        }}
      />

      <TextareaField label="Tagline" valuePath="design.cec_auth_page.tagline" />
    </Stack>
  );
}
