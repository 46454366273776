import { isEmpty } from 'lodash/fp';
import React from 'react';
import { AutoSizer } from 'react-virtualized';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart as Chart,
  Tooltip,
  XAxis,
  YAxis,
  Dot,
} from 'recharts';
import styled from 'styled-components';

import { EmptyState } from '@portals/table';
import { useTheme } from '@portals/ui';
import { getStyledThemeColor } from '@portals/utils';

import { StatCardContainer } from './common';

const LABELS_MAP = {
  total: 'Total',
  offline: 'Disconnected Devices',
};

const RegisteredDevices = ({ data }) => {
  const { color } = useTheme();

  if (isEmpty(data)) {
    return (
      <StatCardContainer style={{ height: 380 }}>
        <EmptyState
          label="No available devices data"
          src="empty-state-widgets"
        />
      </StatCardContainer>
    );
  }

  return (
    <Container>
      <div className="header">
        <div className="title">Registered Devices</div>

        {/*<ButtonGroup*/}
        {/*  height={35}*/}
        {/*  options={[*/}
        {/*    { label: 'Weekly', value: 'weekly', isDisabled: true },*/}
        {/*    { label: 'Monthly', value: 'monthly' },*/}
        {/*    { label: 'Yearly', value: 'yearly', isDisabled: true },*/}
        {/*  ]}*/}
        {/*  value="monthly"*/}
        {/*  onChange={noop}*/}
        {/*/>*/}
      </div>

      <div className="chart-wrapper">
        <AutoSizer>
          {({ width, height }) => (
            <Chart data={data} width={width} height={height}>
              <CartesianGrid horizontal={false} stroke={color.gray300} />
              <XAxis
                dataKey="label"
                tickMargin={20}
                tickSize={0}
                axisLine={false}
              />
              <YAxis
                axisLine={false}
                width={50}
                tickMargin={20}
                tickSize={0}
                allowDecimals={false}
              />
              <Tooltip />
              <Legend
                height={40}
                formatter={(label) => LABELS_MAP[label]}
                iconType="circle"
                iconSize={8}
              />
              <Line
                type="monotone"
                dataKey="total"
                stroke={color.primary}
                strokeWidth={2}
                dot={(props) => (
                  <Dot
                    {...props}
                    fill={color.primary}
                    r={6}
                    stroke={color.white}
                    strokeWidth={2}
                  />
                )}
                activeDot={(props) => (
                  <Dot
                    {...props}
                    fill={color.primary}
                    r={6}
                    stroke={color.white}
                    strokeWidth={0}
                  />
                )}
              />
              <Line
                type="monotone"
                dataKey="offline"
                stroke={color.danger}
                strokeWidth={2}
                strokeDasharray="4 4"
                dot={(props) => (
                  <Dot
                    {...props}
                    strokeDasharray="0"
                    fill={color.danger}
                    r={6}
                    stroke={color.white}
                    strokeWidth={2}
                  />
                )}
                activeDot={(props) => (
                  <Dot
                    {...props}
                    fill={color.danger}
                    strokeDasharray="0"
                    r={6}
                    stroke={color.white}
                    strokeWidth={0}
                  />
                )}
              />
            </Chart>
          )}
        </AutoSizer>
      </div>
    </Container>
  );
};

const Container = styled(StatCardContainer)`
  height: 380px;
  justify-content: center;
  align-items: center;
  grid-template-rows: max-content 1fr;
  grid-row-gap: 40px;
  grid-template-columns: 1fr;

  .chart-wrapper {
    width: 100%;
    height: 100%;

    ul.recharts-default-legend {
      padding-top: 20px !important;
      display: flex !important;
      align-items: center;
      justify-content: center;

      li.recharts-legend-item {
        display: flex !important;
        align-items: center;
      }
    }

    g.recharts-line {
      path {
        filter: drop-shadow(0px 2px 2px ${getStyledThemeColor('gray400')});
      }
    }

    g.recharts-line-dots {
      circle {
        filter: drop-shadow(0px 2px 2px ${getStyledThemeColor('gray400')});
      }
    }
  }
`;

export default RegisteredDevices;
