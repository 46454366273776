import { compact as lodashCompact, map, pick } from 'lodash/fp';
import { Layout } from 'react-grid-layout';
import { v4 as uuid } from 'uuid';

import {
  CommonLegacyWidgetType,
  LegacyGaugeWidgetType,
  LegacyLineChartWidgetType,
  LegacyMetricWidgetType,
  LegacyStatusWidgetType,
} from './migrations.types';
import { GaugeIndicatorWidgetFormType } from '../gauge-indicator';
import { SplineChartWidgetFormType } from '../spline-chart';
import { StatusWidgetFormStatusGroup, StatusWidgetFormType } from '../status';
import { ValueWidgetFormType } from '../value';
import { NewWidgetType, WidgetColorType, WidgetType } from '../widgets.types';

/* ---- WIDGETS CONFIG MIGRATION ---- */
export function transformLegacyWidgetConfig(
  legacyWidget: CommonLegacyWidgetType
): NewWidgetType | undefined {
  switch (legacyWidget.config.id) {
    case 'line_chart':
      return transformToSplineChartWidget(
        legacyWidget as LegacyLineChartWidgetType
      ) as NewWidgetType<SplineChartWidgetFormType>;

    case 'status_widget':
      return transformToStatusWidget(
        legacyWidget as LegacyStatusWidgetType
      ) as NewWidgetType<StatusWidgetFormType>;

    case 'gauge_widget':
      return transformToGaugeIndicatorWidget(
        legacyWidget as LegacyGaugeWidgetType
      ) as NewWidgetType<GaugeIndicatorWidgetFormType>;

    case 'metric_widget':
      return transformToValueWidget(
        legacyWidget as LegacyMetricWidgetType
      ) as NewWidgetType<ValueWidgetFormType>;

    default:
      return;
  }
}

type LineChartMeasurementKeyType = `tracked_measurement_${1 | 2 | 3 | 4}`;

function transformToSplineChartWidget(
  legacyWidget: LegacyLineChartWidgetType
): NewWidgetType<SplineChartWidgetFormType> | undefined {
  try {
    const transformMeasurements = (
      fields: LegacyLineChartWidgetType['config']['fields']
    ) => {
      const measurements: SplineChartWidgetFormType['measurements'] = [];

      const availableIndexes = [1, 2, 3, 4] as const;

      for (const i of availableIndexes) {
        const fieldKey: LineChartMeasurementKeyType = `tracked_measurement_${i}`;
        const telemetryKey = fields[fieldKey];

        if (telemetryKey) {
          measurements.push({
            key: uuid(),
            label: telemetryKey,
            telemetry: telemetryKey,
            color: getColorType(i - 1),
          });
        }
      }

      return measurements;
    };

    let adjustedWidth = legacyWidget.config.layout.w * 2;
    if (adjustedWidth > 24) {
      adjustedWidth = 24;
    } else if (adjustedWidth < 12) {
      adjustedWidth = 12;
    }

    return {
      id: legacyWidget.id,
      name: legacyWidget.name,
      config: {
        id: 'spline_chart',
        width: adjustedWidth,
        height: 8,
        layout: {
          ...pick(['x', 'y'], legacyWidget.config.layout),
          w: adjustedWidth,
          h: 8,
        },
        fields: {
          name: legacyWidget.name,
          scale_type: 'linear',
          num_of_decimals: 1,
          number_format: 'none',
          measurements: transformMeasurements(legacyWidget.config.fields),
        },
      },
    };
  } catch (error) {
    console.error(error);

    return;
  }
}

type StatusMeasurementValueType<Key extends string = 'value' | 'display_name'> =
  `tracked_measurement_${Key}_${1 | 2 | 3 | 4 | 5 | 6 | 7 | 8}`;

function transformToStatusWidget(
  legacyWidget: LegacyStatusWidgetType
): NewWidgetType<StatusWidgetFormType> | undefined {
  try {
    const transformStatus = (
      fields: LegacyStatusWidgetType['config']['fields']
    ): StatusWidgetFormStatusGroup[] => {
      const status: StatusWidgetFormType['status'] = [];

      const availableIndexes = [1, 2, 3, 4, 5, 6, 7, 8] as const;

      for (const i of availableIndexes) {
        const measurementValueKey: StatusMeasurementValueType<'value'> = `tracked_measurement_value_${i}`;
        const measurementValue = fields[measurementValueKey];

        if (measurementValue) {
          const measurementLabelKey: StatusMeasurementValueType<'display_name'> = `tracked_measurement_display_name_${i}`;
          const measurementLabel = fields[measurementLabelKey];

          status.push({
            key: uuid(),
            display_name: measurementLabel,
            value: measurementValue,
            color: getColorType(i - 1),
          });
        }
      }

      return status;
    };

    return {
      id: legacyWidget.id,
      name: legacyWidget.name,
      config: {
        id: 'status',
        width: 6,
        height: 3,
        layout: {
          ...pick(['x', 'y'], legacyWidget.config.layout),
          w: 6,
          h: 3,
        },
        fields: {
          name: legacyWidget.name,
          telemetry: legacyWidget.config.fields.status_measurement,
          status: transformStatus(legacyWidget.config.fields),
          telemetry_as_title: false,
          title_telemetry: '',
        },
      },
    };
  } catch (error) {
    console.error(error);

    return;
  }
}

function transformToGaugeIndicatorWidget(
  legacyWidget: LegacyGaugeWidgetType
): NewWidgetType<GaugeIndicatorWidgetFormType> | undefined {
  const transformSegments = (
    fields: LegacyGaugeWidgetType['config']['fields']
  ): GaugeIndicatorWidgetFormType['segments'] => {
    const { low_range_end, medium_range_end, max_value, min_value } = fields;

    const range = max_value - min_value;

    return [
      {
        id: uuid(),
        color: getColorType(0),
        max: ((low_range_end - min_value) / range) * 100,
      },
      {
        id: uuid(),
        color: getColorType(1),
        max: ((medium_range_end - low_range_end) / range) * 100,
      },
      {
        id: uuid(),
        color: getColorType(2),
        max: 100,
      },
    ];
  };

  return {
    id: legacyWidget.id,
    name: legacyWidget.name,
    config: {
      id: 'gauge_indicator',
      width: 6,
      height: 8,
      layout: {
        ...pick(['x', 'y'], legacyWidget.config.layout),
        w: 6,
        h: 8,
      },
      fields: {
        name: legacyWidget.name,
        telemetry: legacyWidget.config.fields.tracked_measurement,
        min: legacyWidget.config.fields.min_value,
        max: legacyWidget.config.fields.max_value,
        unit: legacyWidget.config.fields.tracked_measurement_unit,
        scale_type: 'linear',
        number_format: 'none',
        num_of_decimals: 1,
        segments: transformSegments(legacyWidget.config.fields),
        title_telemetry: '',
        telemetry_as_title: false,
      },
    },
  };
}

function transformToValueWidget(
  legacyWidget: LegacyMetricWidgetType
): NewWidgetType<ValueWidgetFormType> {
  return {
    id: legacyWidget.id,
    name: legacyWidget.name,
    config: {
      id: 'value',
      width: 6,
      height: 3,
      layout: {
        ...pick(['x', 'y'], legacyWidget.config.layout),
        w: 6,
        h: 3,
      },
      fields: {
        name: legacyWidget.name,
        telemetry_as_title: false,
        title_telemetry: '',
        telemetry: legacyWidget.config.fields.tracked_measurement,
        with_measurement_unit: Boolean(
          legacyWidget.config.fields.tracked_measurement_unit
        ),
        measurement_unit: legacyWidget.config.fields.tracked_measurement_unit,
      },
    },
  };
}

function getColorType(index: number): WidgetColorType {
  const colors: WidgetColorType[] = [
    'deep_purple_accent.2',
    'indigo_accent.3',
    'blue_accent.4',
    'purple_accent.2',
    'pink_accent.2',
    'red.4',
    'orange.6',
    'yellow.6',
    'lime_accent.4',
    'teal_accent.3',
    'cyan.4',
    'cyan_accent.4',
    'gray.3',
    'teal_accent.4',
  ];

  return colors[index % colors.length];
}
/* ---- WIDGETS CONFIG MIGRATION ---- */

/* ---- RGL LAYOUT UTILS COPY-PASTE ---- */
export function bottom(layout: Layout[]): number {
  let max = 0,
    bottomY;

  for (let i = 0, len = layout.length; i < len; i++) {
    bottomY = layout[i].y + layout[i].h;
    if (bottomY > max) max = bottomY;
  }

  return max;
}

export function cloneLayout(layout: Layout[]): Layout[] {
  const newLayout = Array(layout.length);
  for (let i = 0, len = layout.length; i < len; i++) {
    newLayout[i] = cloneLayoutItem(layout[i]);
  }

  return newLayout;
}

// Fast path to cloning, since this is monomorphic
export function cloneLayoutItem(layoutItem: Layout): Layout {
  return {
    w: layoutItem.w,
    h: layoutItem.h,
    x: layoutItem.x,
    y: layoutItem.y,
    i: layoutItem.i,
    minW: layoutItem.minW,
    maxW: layoutItem.maxW,
    minH: layoutItem.minH,
    maxH: layoutItem.maxH,
    moved: Boolean(layoutItem.moved),
    static: Boolean(layoutItem.static),
    // These can be null/undefined
    isDraggable: layoutItem.isDraggable,
    isResizable: layoutItem.isResizable,
    resizeHandles: layoutItem.resizeHandles,
    isBounded: layoutItem.isBounded,
  };
}

export function collides(l1: Layout, l2: Layout): boolean {
  if (l1.i === l2.i) return false; // same element
  if (l1.x + l1.w <= l2.x) return false; // l1 is left of l2
  if (l1.x >= l2.x + l2.w) return false; // l1 is right of l2
  if (l1.y + l1.h <= l2.y) return false; // l1 is above l2
  if (l1.y >= l2.y + l2.h) return false; // l1 is below l2

  return true;
}

export function compact(
  layout: Layout[],
  compactType: 'vertical' | 'horizontal' | boolean,
  cols: number
): Layout[] {
  // Statics go in the compareWith array right away so items flow around them.
  const compareWith = [];

  // We go through the items by row and column.
  const sorted = sortLayoutItems(layout, compactType);
  // Holding for new items.
  const out = Array(layout.length);

  for (let i = 0, len = sorted.length; i < len; i++) {
    let currentLayout = cloneLayoutItem(sorted[i]);

    currentLayout = compactItem(
      compareWith,
      currentLayout,
      compactType,
      cols,
      sorted
    );

    // Add to comparison array. We only collide with items before this one.
    // Statics are already in this array.
    compareWith.push(currentLayout);

    // Add to output array to make sure they still come out in the right order.
    out[layout.indexOf(sorted[i])] = currentLayout;
  }

  return out;
}

const heightWidth = { x: 'w', y: 'h' } as const;

function resolveCompactionCollision(
  layout: Layout[],
  item: Layout,
  moveToCoord: number,
  axis: 'x' | 'y'
) {
  const sizeProp = heightWidth[axis];

  item[axis] += 1;

  const itemIndex = layout.findIndex((layoutItem) => layoutItem.i === item.i);

  // Go through each item we collide with.
  for (let i = itemIndex + 1; i < layout.length; i++) {
    const otherItem = layout[i];

    // Optimization: we can break early if we know we're past this el
    // We can do this b/c it's a sorted layout
    if (otherItem.y > item.y + item.h) break;

    if (collides(item, otherItem)) {
      resolveCompactionCollision(
        layout,
        otherItem,
        moveToCoord + item[sizeProp],
        axis
      );
    }
  }

  item[axis] = moveToCoord;
}

export function compactItem(
  compareWith: Layout[],
  l: Layout,
  compactType: 'vertical' | 'horizontal' | boolean,
  cols: number,
  fullLayout: Layout[]
): Layout {
  const compactV = compactType === 'vertical';
  const compactH = compactType === 'horizontal';

  if (compactV) {
    // Bottom 'y' possible is the bottom of the layout.
    // This allows you to do nice stuff like specify {y: Infinity}
    // This is here because the layout must be sorted in order to get the correct bottom `y`.
    l.y = Math.min(bottom(compareWith), l.y);
    // Move the element up as far as it can go without colliding.
    while (l.y > 0 && !getFirstCollision(compareWith, l)) {
      l.y--;
    }
  } else if (compactH) {
    // Move the element left as far as it can go without colliding.
    while (l.x > 0 && !getFirstCollision(compareWith, l)) {
      l.x--;
    }
  }

  // Move it down, and keep moving it down if it's colliding.
  let collides;
  while ((collides = getFirstCollision(compareWith, l))) {
    if (compactH) {
      resolveCompactionCollision(fullLayout, l, collides.x + collides.w, 'x');
    } else {
      resolveCompactionCollision(fullLayout, l, collides.y + collides.h, 'y');
    }
    // Since we can't grow without bounds horizontally, if we've overflown, let's move it down and try again.
    if (compactH && l.x + l.w > cols) {
      l.x = cols - l.w;
      l.y++;
    }
  }

  // Ensure that there are no negative positions
  l.y = Math.max(l.y, 0);
  l.x = Math.max(l.x, 0);

  return l;
}

export function correctBounds(
  layout: Layout[],
  bounds: { cols: number }
): Layout[] {
  for (let i = 0, len = layout.length; i < len; i++) {
    const l = layout[i];
    // Overflows right
    if (l.x + l.w > bounds.cols) l.x = bounds.cols - l.w;
    // Overflows left
    if (l.x < 0) {
      l.x = 0;
      l.w = bounds.cols;
    }
  }

  return layout;
}

export function getFirstCollision(
  layout: Layout[],
  layoutItem: Layout
): Layout | void {
  for (let i = 0, len = layout.length; i < len; i++) {
    if (collides(layout[i], layoutItem)) return layout[i];
  }
}

export function sortLayoutItems(
  layout: Layout[],
  compactType: 'vertical' | 'horizontal' | boolean
): Layout[] {
  if (compactType === 'horizontal') {
    return sortLayoutItemsByColRow(layout);
  }

  if (compactType === 'vertical') {
    return sortLayoutItemsByRowCol(layout);
  }

  return layout;
}

export function sortLayoutItemsByRowCol(layout: Layout[]): Layout[] {
  // Slice to clone array as sort modifies
  return layout.slice(0).sort(function (a, b) {
    if (a.y > b.y || (a.y === b.y && a.x > b.x)) {
      return 1;
    } else if (a.y === b.y && a.x === b.x) {
      // Without this, we can get different sort results in IE vs. Chrome/FF
      return 0;
    }

    return -1;
  });
}

export function sortLayoutItemsByColRow(layout: Layout[]): Layout[] {
  return layout.slice(0).sort(function (a, b) {
    if (a.x > b.x || (a.x === b.x && a.y > b.y)) {
      return 1;
    }

    return -1;
  });
}

export function validateLayout(layout: Layout[]): void {
  const subProps = ['x', 'y', 'w', 'h'] as const;

  if (!Array.isArray(layout)) throw new Error();

  for (let i = 0, len = layout.length; i < len; i++) {
    const item = layout[i];

    for (let j = 0; j < subProps.length; j++) {
      if (typeof item[subProps[j]] !== 'number') {
        throw new Error();
      }
    }
  }
}
/* ---- RGL LAYOUT UTILS COPY-PASTE ---- */

export function migrateWidgetsAndAdjustLayout(
  legacyWidgets: WidgetType[]
): NewWidgetType[] {
  const migratedWidgets = lodashCompact(
    map((widget) => {
      const migratedWidget = transformLegacyWidgetConfig(
        // @ts-ignore
        widget
      );

      if (!migratedWidget) return null;

      return {
        name: migratedWidget.name,
        config: migratedWidget.config,
      };
    }, legacyWidgets)
  );

  const allLayouts = lodashCompact(
    migratedWidgets.map((widget) => widget.config.layout)
  );

  let updatedLayouts = cloneLayout(allLayouts as Layout[]).map(
    (widgetLayout) => {
      const updatedWidgetLayout = cloneLayoutItem(widgetLayout);

      updatedWidgetLayout.x = (updatedWidgetLayout.x * 24) / 12;

      return updatedWidgetLayout;
    }
  );

  updatedLayouts = compact(updatedLayouts, false, 24);
  updatedLayouts = correctBounds(updatedLayouts, { cols: 24 });

  try {
    validateLayout(updatedLayouts);

    updatedLayouts.forEach((layout, index) => {
      migratedWidgets[index].config.layout = layout;
    });
  } catch (error) {
    console.error(error);
  }

  return migratedWidgets;
}
