import { Center, createStyles, Text } from '@mantine/core';
import React from 'react';

import { usePartnerConfig } from '@portals/api/partners';
import {
  deviceClaimingDefaultBannerSrc,
  PartnerAvatar,
} from '@portals/framework';

import { DeviceClaimingPreviewBackground } from './DeviceClaimingPreviewBackground';
import { useBrandingContext } from '../../branding.context';
import { useDesignField } from '../../common/fields/hooks';

export function DeviceClaimingPreview() {
  const { classes } = useStyles();

  const { portalConfig } = useBrandingContext();
  const partnerConfig = usePartnerConfig();

  const bannerSrc =
    portalConfig?.design?.cec_device_claiming?.banner ||
    deviceClaimingDefaultBannerSrc;
  const logoField = useDesignField('design.cec_device_claiming.logo');

  return (
    <Center h="100%">
      <div className={classes.content}>
        <DeviceClaimingPreviewBackground
          banner={<img width="100%" src={bannerSrc} alt="banner" />}
          logo={
            <PartnerAvatar
              src={logoField.value}
              className={classes.logo}
              size="100%"
              partnerName={partnerConfig?.display_name || ''}
            />
          }
          name={
            <Text size="lg" color="gray.9" ta="center" truncate>
              {partnerConfig?.display_name}
            </Text>
          }
        />
      </div>
    </Center>
  );
}

const useStyles = createStyles((theme) => ({
  content: {
    width: '85%',
    height: '100%',
  },
  logo: {
    border: `1px solid ${theme.colors.gray[3]}`,
    backgroundColor: theme.white,
  },
}));
