import { ScrollArea, Tabs, TabsProps } from '@mantine/core';
import { isEmpty } from 'lodash/fp';
import React from 'react';
import {
  Navigate,
  Outlet,
  PathRouteProps,
  Route,
  Routes,
  useMatch,
  useNavigate,
} from 'react-router-dom';

import { Page404 } from '../../pages/auth/Page404';

export interface RoutedTabsProps {
  basePath: string;
  routes: Array<
    {
      tabLabel: TabsProps['children'];
      path: string;
      visible?: boolean;
    } & PathRouteProps
  >;
}

export function RoutedTabs({ basePath, routes }: RoutedTabsProps) {
  const availableRoutes = routes.filter((route) => route.visible !== false);

  return (
    <Routes>
      <Route
        path="/*"
        element={<Layout basePath={basePath} routes={availableRoutes} />}
      >
        {availableRoutes.map(({ path, ...rest }) => (
          <Route key={path} path={path} {...rest} />
        ))}
      </Route>
    </Routes>
  );
}

function Layout({ basePath, routes }: RoutedTabsProps) {
  const navigate = useNavigate();
  const match = useMatch({ path: `${basePath}/:tab`, end: false });

  if (isEmpty(routes)) {
    return <Page404 />;
  }

  if (match === null) {
    return <Navigate to={routes[0].path} replace />;
  }

  if (routes.find((route) => route.path === match.params.tab) === undefined) {
    return <Page404 />;
  }

  return (
    <Tabs
      keepMounted={false}
      styles={tabsStyles}
      value={match?.params?.tab}
      onTabChange={(value) => {
        if (value === null || value === match.params.tab) {
          return;
        }

        navigate(value);
      }}
    >
      <ScrollArea type="auto">
        <Tabs.List>
          {routes.map((route) => (
            <Tabs.Tab
              key={route.path}
              value={route.path}
              data-testid={`location-tab-${route.path}`}
            >
              {route.tabLabel}
            </Tabs.Tab>
          ))}
        </Tabs.List>
      </ScrollArea>

      {routes.map((route) => (
        <Tabs.Panel key={route.path} value={route.path}>
          <Outlet />
        </Tabs.Panel>
      ))}
    </Tabs>
  );
}

const tabsStyles: TabsProps['styles'] = (theme) => ({
  root: {
    display: 'grid',
    gridTemplateRows: 'max-content 1fr',
    height: '100%',
  },
  tabsList: {
    flexWrap: 'nowrap',
    width: 'fit-content',
    borderWidth: 3,

    '.location-tab-wrapper': {
      position: 'relative',

      '.location-tab-badge-wrapper': {
        zIndex: 1,
        position: 'absolute',
        paddingRight: theme.spacing.xs,
        bottom: 0,
        right: 0,
      },
    },
  },
  tab: {
    marginBottom: -3,
    borderWidth: 3,
  },
  panel: {
    paddingBlock: theme.spacing.md,
  },

  // This is a hacky way to display a badge over a disabled tab. The original
  // badge is rendered and takes up space, but is hidden with opacity.
  // An additional badge is then rendered alongside the tab, which is positioned
  // absolutely over the original badge.
  tabRightSection: {
    opacity: 0,
    pointerEvents: 'none',
    marginRight: theme.spacing.xs,
  },
  tabLabel: {
    fontWeight: 500,
  },
});
