import {
  ActionIcon,
  ColorInput,
  ColorInputProps,
  Tooltip,
} from '@mantine/core';
import React, { ReactNode } from 'react';

import { ReactComponent as RotateLeft } from '@portals/icons/linear/rotate-left.svg';
import { suppressPropagation } from '@portals/utils';

import { useDesignField, ValuePath } from './hooks';

interface DesignColorInputProps {
  label: ReactNode;
  valuePath: ValuePath;
  disabled?: boolean;
}

export function DesignColorInput({
  label,
  valuePath,
  disabled = false,
}: DesignColorInputProps) {
  const { value, onChange, defaultValue } = useDesignField(valuePath);

  const onChangeWrapper: ColorInputProps['onChange'] = (color) => {
    onChange(color);
  };

  return (
    <ColorInput
      label={label}
      disabled={disabled}
      rightSection={
        value === defaultValue ? null : (
          <Tooltip label="Revert to default color" withArrow>
            <ActionIcon
              onClick={suppressPropagation(() => onChange(defaultValue))}
            >
              <RotateLeft width={16} height={16} />
            </ActionIcon>
          </Tooltip>
        )
      }
      value={value}
      styles={() => ({
        label: {
          width: '100%',
        },
      })}
      onChange={onChangeWrapper}
    />
  );
}
