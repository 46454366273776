import { createStyles, Text } from '@mantine/core';
import React from 'react';

import { useSupportState } from './SupportStateProvider';
import { IncidentsList } from '../../components/TicketsAndIncidentsList/IncidentsList';

export function SupportCenter() {
  const { classes } = useStyles();
  const { isLabIncluded } = useSupportState();

  return (
    <div className={classes.container}>
      <Text size="lg" sx={(theme) => ({ color: theme.colors.blue_gray[6] })}>
        Incidents
      </Text>

      <IncidentsList
        endpointUrl="/ui/partner/monitoring/incidents"
        viewAllLink="/incidents"
        itemsKey="incidents"
        isLabIncluded={isLabIncluded}
      />
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    border: `1px solid ${theme.colors.gray[2]}`,
    backgroundColor: theme.white,
    borderRadius: theme.radius.md,
    padding: theme.spacing.md,
    display: 'grid',
    gridTemplateRows: 'max-content 1fr',
    rowGap: theme.spacing.xs,

    [theme.fn.largerThan('xl')]: {
      padding: theme.spacing.xl,
    },
  },
  labelText: {
    [theme.fn.largerThan('xl')]: {
      fontSize: theme.fontSizes.sm,
    },
  },
  itemsCount: {
    padding: theme.spacing.xs,
    fontSize: theme.fontSizes.xs,
    fontWeight: 'normal',
  },
}));
