import { Badge, Box, Button, createStyles, Group, Text } from '@mantine/core';
import React, { FC } from 'react';

import { ReactComponent as TickCircle } from '@portals/icons/linear/tick-circle.svg';

interface TaskItemProps {
  title: string;
  description: string;
  iconFileName: string;
  onClick: () => void;
  disabled?: boolean;
  isComingSoon?: boolean;
  isCompleted: boolean;
}

const useStyles = createStyles((theme) => ({
  container: {
    paddingTop: theme.spacing.sm,
    ':not(:last-child)': {
      paddingBottom: theme.spacing.sm,
    },

    [theme.fn.largerThan('xl')]: {
      paddingTop: 32,
      ':not(:last-child)': {
        paddingBottom: 32,
      },
    },
  },
  title: {
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
    color: theme.colors.blue_gray[9],

    [theme.fn.largerThan('xl')]: {
      fontSize: theme.fontSizes.md,
      fontWeight: 400,
    },
  },
  description: {
    fontSize: theme.fontSizes.xs,
    fontWeight: 600,
    color: theme.colors.blue_gray[6],

    [theme.fn.largerThan('xl')]: {
      fontSize: theme.fontSizes.sm,
      fontWeight: 500,
    },
  },
}));

export const TaskItem: FC<TaskItemProps> = ({
  title,
  description,
  iconFileName,
  onClick,
  disabled,
  isComingSoon,
  isCompleted,
}) => {
  const { classes } = useStyles();

  return (
    <Group noWrap spacing="md" className={classes.container}>
      <img width={32} height={32} src={iconFileName} />

      <Box sx={{ flex: '1 !important' }}>
        <Group spacing="xs" sx={{ marginBottom: 3 }}>
          <Text className={classes.title}>{title}</Text>
          {isComingSoon && <Badge>Coming soon</Badge>}
        </Group>
        <Text className={classes.description}>{description}</Text>
      </Box>

      {isCompleted ? (
        <Group
          spacing="xs"
          sx={(theme) => ({ color: theme.colors.teal_accent[7] })}
        >
          <TickCircle width={18} height={18} />
          <Text size="sm">Completed</Text>
        </Group>
      ) : (
        <Button variant="light" onClick={onClick} disabled={disabled}>
          Start
        </Button>
      )}
    </Group>
  );
};
