import { Anchor } from '@mantine/core';
import React from 'react';
import { Link } from 'react-router-dom';

import { Product, StoreListing } from '@portals/api/partners';
import {
  DetailsList,
  DetailsListProps,
  ProductCategoryBadge,
  usePortalProtocolAndDomain,
} from '@portals/framework';
import { ProductPricingModel } from '@portals/types';
import {
  formatDateTime,
  getPricingModelDisplayName,
  getProductTypeDisplayName,
} from '@portals/utils';

interface ProductDetailsListProps {
  productType: Product['product_type'];
  manufacturerName: Product['manufacturer']['display_name'];
  deviceModel: Product['device_model'];
  createdAt: Product['created_at'];
  category: Product['category'];
  pricingModel: Product['pricing_model'];
  sku: Product['sku'];
  slug?: StoreListing['slug'];
  usageBasedUnitName: Product['usage_based_unit_name'] | undefined;
  usageBasedTelemetryKey: Product['usage_based_telemetry_key'] | undefined;
  usageBasedDisplayName: Product['usage_based_display_name'] | undefined;
}

export function ProductDetailsList({
  productType,
  createdAt,
  manufacturerName,
  deviceModel,
  category,
  pricingModel,
  sku,
  usageBasedTelemetryKey,
  usageBasedUnitName,
  usageBasedDisplayName,
  slug,
}: ProductDetailsListProps) {
  const { protocolAndDomain } = usePortalProtocolAndDomain();

  const detailsListItems: DetailsListProps['items'] = [
    {
      label: 'Product type',
      value: getProductTypeDisplayName(productType),
    },
    {
      label: 'Pricing model',
      value: getPricingModelDisplayName(pricingModel),
    },
    {
      label: 'Manufacturer',
      value: manufacturerName,
    },
    {
      label: 'Device model',
      value: deviceModel ? (
        <Link to={`/models/${deviceModel.id}`}>{deviceModel.name}</Link>
      ) : (
        '--'
      ),
    },
    {
      label: 'Created',
      value: formatDateTime(createdAt),
    },
    {
      label: 'Category',
      value: category ? <ProductCategoryBadge category={category} /> : '--',
    },
    {
      label: 'SKU',
      value: sku ?? '--',
    },
    {
      label: 'Permalink',
      value: slug ? (
        <Anchor
          truncate
          display="block"
          w={150}
          href={`${protocolAndDomain}/store/${slug}`}
          target="_blank"
        >
          {`${protocolAndDomain}/store/${slug}`}
        </Anchor>
      ) : (
        '--'
      ),
    },
  ];

  return (
    <>
      <DetailsList title="Information" items={detailsListItems} />

      {pricingModel === ProductPricingModel.UsageBased && (
        <DetailsList
          title="Usage based information"
          items={[
            {
              label: 'Display name',
              value: usageBasedDisplayName ?? '--',
            },
            {
              label: 'Telemetry key',
              value: usageBasedTelemetryKey ?? '--',
            },
            {
              label: 'Unit name',
              value: usageBasedUnitName ?? '--',
            },
          ]}
        />
      )}
    </>
  );
}
