import React, { useEffect } from 'react';

import { StoreListing } from '@portals/api/partners';
import {
  ControlledTextEditor,
  DetailsPanel,
  TextEditorProps,
  useTextEditor,
} from '@portals/core';

import { CommandsAndModelsList } from '../../../../components/CommandsAndModelList';
import { ProductDetailsList } from '../../../products-catalog/ProductDetailsList';

interface OverviewTabProps {
  storeListing: StoreListing;
  slug: StoreListing['slug'];
}

export function OverviewTab({ storeListing, slug }: OverviewTabProps) {
  const { product } = storeListing;

  const editor = useTextEditor({
    content: storeListing.product_description,
    editable: false,
  });

  useEffect(
    function updateDescriptionIfChanged() {
      if (editor?.getHTML() !== storeListing.product_description) {
        editor?.commands?.setContent(storeListing.product_description);
      }
    },
    [editor, storeListing.product_description]
  );

  return (
    <>
      <ProductDetailsList
        category={storeListing.product_category}
        productType={product.product_type}
        createdAt={product.created_at}
        deviceModel={product.device_model}
        manufacturerName={product.partner_name}
        pricingModel={product.pricing_model}
        sku={product.sku}
        usageBasedDisplayName={product.usage_based_display_name}
        usageBasedTelemetryKey={product.usage_based_telemetry_key}
        usageBasedUnitName={product.usage_based_unit_name}
        slug={slug}
      />

      <DetailsPanel.Section title="Description">
        <ControlledTextEditor
          styles={textEditorStyles}
          editor={editor}
          data-testid="description-controlled-text-editor"
        />
      </DetailsPanel.Section>

      {product.supported_commands?.length > 0 ? (
        <DetailsPanel.Section title="Models & commands">
          <CommandsAndModelsList
            supportedCommands={product.supported_commands}
          />
        </DetailsPanel.Section>
      ) : null}
    </>
  );
}

const textEditorStyles: TextEditorProps['styles'] = (theme) => ({
  root: {
    border: 'none',
  },
  typographyStylesProvider: {
    color: theme.colors.gray[8],
    fontSize: theme.fontSizes.sm,
  },
  content: {
    '.ProseMirror': {
      padding: 0,
    },
  },
});
