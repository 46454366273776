import { Group, Input, Stack, Text } from '@mantine/core';
import React from 'react';

// import { useConfirmNavigation } from '@portals/framework';
import { useRequestPlanUpgrade } from '@portals/api/partners';
import { UpgradeTierBadgeWithText } from '@portals/framework';

import { StoreBannerSelector } from './StoreBannerSelector';
import { useIsPricingPlanFeatureEnabled } from '../../../hooks/partner-config';
import { useBrandingContext } from '../branding.context';
import { BetaBadge } from '../common/BetaBadge';
import { DesignImageInput } from '../common/fields/ImageField';
import { TextareaField } from '../common/fields/TextareaField';
import { SidebarTabPanel } from '../SidebarTabPanel';

export function StoreSidebar() {
  const { portalConfig } = useBrandingContext();

  const requestPlanUpgrade = useRequestPlanUpgrade();

  const isStoreBrandingEnabled =
    useIsPricingPlanFeatureEnabled('store_branding');

  // useConfirmNavigation({
  //   isActive: !isPristine,
  //   confirmationModal: {
  //     title: 'Leave page without saving?',
  //     description:
  //       'You are about to leave the white-label progress and discard all your work. Are you sure?',
  //     confirmationLabel: 'Discard and Leave',
  //     cancelLabel: 'Keep Editing',
  //   },
  //   onConfirm: revertChanges,
  // });

  return (
    <SidebarTabPanel>
      <SidebarTabPanel.Header>
        <Group position="apart">
          <span>Store</span>
          {isStoreBrandingEnabled ? (
            <BetaBadge />
          ) : (
            <UpgradeTierBadgeWithText
              message={
                <Text size="xs">
                  This feature is not included in your current tier, please
                  upgrade to activate.
                </Text>
              }
              onUpgrade={() =>
                requestPlanUpgrade.mutate(
                  `Partners > Branding > Store > Upgrade to premium plan`
                )
              }
            >
              Upgrade
            </UpgradeTierBadgeWithText>
          )}
        </Group>
      </SidebarTabPanel.Header>

      <SidebarTabPanel.Body>
        <Stack>
          <DesignImageInput
            disabled={!isStoreBrandingEnabled}
            valuePath="design.store.logo"
            field={{
              title: 'Logo',
              name: 'logo',
              height: 200,
              cropConfig: {
                aspectRatio: 1,
                width: 200,
                height: 200,
              },
            }}
          />

          <TextareaField
            label="Tagline"
            valuePath="design.store.tagline"
            disabled={!isStoreBrandingEnabled}
          />

          <Input.Wrapper>
            <Input.Label mb="xl">Banner</Input.Label>
            <StoreBannerSelector disabled={!isStoreBrandingEnabled} />
          </Input.Wrapper>
        </Stack>

        {portalConfig?.design?.store?.with_banner ? (
          <DesignImageInput
            disabled={!isStoreBrandingEnabled}
            valuePath="design.store.banner"
            field={{
              title: 'Banner',
              name: 'banner',
              cropConfig: {
                aspectRatio: 8,
                height: 240,
                width: 1920,
              },
              previewConfig: {
                height: 30,
                width: 240,
              },
              height: 200,
            }}
          />
        ) : null}
      </SidebarTabPanel.Body>
    </SidebarTabPanel>
  );
}
