import { Button, Group, Stack } from '@mantine/core';
import React from 'react';
import { useDispatch } from 'react-redux';

import Response from '../../components/Response';
import TestCommand from '../../components/TestCommand';
import { deleteDevice, useGetApiDevice } from '../../redux/actions/api';

export function DeleteDevice() {
  const dispatch = useDispatch();
  const access = useGetApiDevice();

  return (
    <Stack>
      <Group>
        <Button
          onClick={() => dispatch(deleteDevice(access.id))}
          disabled={!access.id}
        >
          Delete
        </Button>
        <span>
          Device ID:
          {access.id || 'Not set'}
        </span>
      </Group>

      {access.id ? (
        <TestCommand
          url={`/${access.id}`}
          method="DELETE"
          data={{
            id: access.id,
          }}
        />
      ) : null}

      <Response type="deleteDevice" />
    </Stack>
  );
}
