import { Center, createStyles } from '@mantine/core';
import React from 'react';

import { usePortalDomain } from '@portals/framework';

import { StoreBrowserMock } from './store-browser-mock/StoreBrowserMock';
import { useBrandingContext } from '../branding.context';
import { BrowserMock } from '../browser-mock/BrowserMock';

export function StorePreview() {
  const { classes } = useStyles();

  const portalDomain = usePortalDomain();
  const { portalConfig } = useBrandingContext();

  return (
    <Center h="100%" py={64}>
      <BrowserMock
        className={classes.browserMock}
        windowTitle={portalConfig.window_title}
        favicon={portalConfig.favicon}
        domain={portalDomain}
      >
        <StoreBrowserMock />
      </BrowserMock>
    </Center>
  );
}

const useStyles = createStyles({
  browserMock: {
    width: '85%',
    aspectRatio: '1.8',
  },
});
