import { createStyles, Group, Text } from '@mantine/core';
import { join, split } from 'lodash/fp';
import React, { FC } from 'react';

import { PartnerIncidentType } from '@portals/api/partners';
import { TicketType } from '@portals/types';

interface SpacePathProps {
  item: TicketType | PartnerIncidentType;
}

export const SpacePath: FC<SpacePathProps> = ({ item }) => {
  const { classes } = useStyles();

  const path = 'space_path' in item ? item.space_path : item.space.path;
  const spaceNames = split('/', path).slice(1);
  const firstSpaceName = spaceNames[0];
  const otherSpaceNames = spaceNames.slice(1);
  const spacePath = join(' / ', otherSpaceNames);
  const deviceName =
    'device_name' in item ? item.device_name : item.device.name;

  return (
    <Group noWrap sx={{ overflow: 'hidden' }} spacing={3}>
      <Text size="xs">{firstSpaceName}</Text>
      {spacePath ? (
        <Text size="xs" className={classes.longText}>
          / {spacePath}
        </Text>
      ) : null}
      <Text size="xs">/ {deviceName}</Text>
    </Group>
  );
};

const useStyles = createStyles((theme) => ({
  longText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));
