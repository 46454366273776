import {
  Button,
  createStyles,
  Divider,
  LoadingOverlay,
  Stack,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useEffect, useRef } from 'react';

import {
  PartnerContacts,
  useContacts,
  useUpdateContacts,
} from '@portals/api/partners';
import { useCurrentUser } from '@portals/api/ui';

export function Contacts() {
  const { classes } = useStyles();
  const formValuesAreSet = useRef(false);

  const updateContacts = useUpdateContacts();
  const contacts = useContacts();

  const currentUser = useCurrentUser();

  const form = useForm<PartnerContacts>({
    initialValues: {
      finance_contact_name: '',
      finance_contact_email: '',
      admin_contact_name: '',
      admin_contact_email: '',
      support_contact_name: currentUser.data?.name || '',
      support_contact_email: currentUser.data?.email || '',
    },
  });

  useEffect(
    function initializeFormValuesOnceDataFetched() {
      if (formValuesAreSet.current || !contacts.isFetched || !contacts.data) {
        return;
      }

      form.setValues(contacts.data);
      formValuesAreSet.current = true;
    },
    [contacts.data, contacts.isFetched, form]
  );

  const onSubmit = (values: typeof form.values) => {
    updateContacts.mutate(values);
  };

  return (
    <>
      <LoadingOverlay visible={currentUser.isInitialLoading} />
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Stack spacing="xxl" maw={500}>
          <Stack>
            <Title order={5}>Finance</Title>
            <TextInput
              required
              label="Finance Contact Name"
              data-testid="finance-contact-name-input"
              {...form.getInputProps('finance_contact_name')}
            />

            <TextInput
              required
              type="email"
              label="Finance Contact Email"
              data-testid="finance-contact-email-input"
              {...form.getInputProps('finance_contact_email')}
            />
          </Stack>

          <Stack>
            <Title order={5}>Admin</Title>
            <TextInput
              required
              label="Admin Contact Name"
              data-testid="admin-contact-name-input"
              {...form.getInputProps('admin_contact_name')}
            />

            <TextInput
              required
              type="email"
              label="Admin Contact Email"
              data-testid="admin-contact-email-input"
              {...form.getInputProps('admin_contact_email')}
            />
          </Stack>

          <Stack>
            <Title order={5}>Support</Title>
            <TextInput
              label="Support Contact Name"
              data-testid="support-contact-name-input"
              {...form.getInputProps('support_contact_name')}
            />

            <TextInput
              type="email"
              label="Support Contact email"
              data-testid="support-contact-email-input"
              {...form.getInputProps('support_contact_email')}
            />
          </Stack>

          <Divider />
          <Button
            type="submit"
            data-testid="update-button"
            loading={updateContacts.isLoading}
            className={classes.submitButton}
          >
            Update
          </Button>
        </Stack>
      </form>
    </>
  );
}

const useStyles = createStyles(() => ({
  submitButton: {
    alignSelf: 'flex-end',
  },
}));
