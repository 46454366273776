import { Button, Modal, Text, Title } from '@mantine/core';
import React from 'react';

import { useRequestCecEnrollment } from '@portals/api/partners';
import { ModalBody, ModalFooter } from '@portals/core';
import { ModalProps } from '@portals/framework';

import imageSrc from './request-cec-enrollment-bg.svg';

interface RequestCecEnrollmentModalProps extends ModalProps {}

export function RequestCecEnrollmentModal({
  closeMe,
}: RequestCecEnrollmentModalProps) {
  const requestCecEnrollment = useRequestCecEnrollment();

  return (
    <Modal
      opened
      onClose={closeMe}
      withCloseButton={false}
      padding={0}
      size={714}
    >
      <img width="100%" height="auto" src={imageSrc} alt="connect" />

      <ModalBody p="xxl" ta="center">
        <Title order={4} weight={400} mb="xl">
          Access exclusive branding features with Connect
        </Title>

        <Text size="md">
          Unlock premium branding options and enhance your customization
          experience with our "Connect" feature. By opting in, you'll gain
          access to this and many more advanced branding assets that can elevate
          your brand presence.
        </Text>
      </ModalBody>

      <ModalFooter grow p="xl">
        <Button variant="default" onClick={closeMe}>
          Cancel
        </Button>
        <Button
          onClick={() =>
            requestCecEnrollment.mutate(undefined, { onSuccess: closeMe })
          }
        >
          I'm interested
        </Button>
      </ModalFooter>
    </Modal>
  );
}
