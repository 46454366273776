import { Group, Text } from '@mantine/core';
import React, { FC } from 'react';

interface ItemsCountProps {
  title: string;
  count?: number;
}

export const ItemsCount: FC<ItemsCountProps> = ({ title, count }) => (
  <Group spacing="xs">
    <Text size="lg" sx={(theme) => ({ color: theme.colors.blue_gray[6] })}>
      {title}
    </Text>
    {count ? (
      <Text size="lg" sx={(theme) => ({ color: theme.colors.blue_gray[2] })}>
        ({count})
      </Text>
    ) : null}
  </Group>
);
