import { Anchor, Group, LoadingOverlay, Text } from '@mantine/core';
import { compact, get, size } from 'lodash/fp';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { DeviceModelType, useDeviceModels } from '@portals/api/partners';
import {
  DeviceAvatar,
  ModalButton,
  PermissionAccess,
} from '@portals/framework';
import { ReactComponent as AddSquare } from '@portals/icons/linear/add-square.svg';
import { BooleanCell, SmartTable } from '@portals/table';
import {
  AccessLevelEnum,
  SmartTableProps,
  TableColumn,
  TableColumnFormatter,
  TableFilterTypeEnum,
} from '@portals/types';

import { ModelsListRowMenu } from './ModelsListRowMenu';
import { usePricingPlanFeature } from '../../hooks/partner-config';
import { ConfigureModelProps } from '../../modals/ConfigureModel';

const nameFormatter: TableColumnFormatter<DeviceModelType> = (_, row) => {
  return (
    <Group noWrap>
      <DeviceAvatar
        radius="xl"
        color="indigo"
        icon={get('user_settings.icon', row)}
      />

      <Text truncate>
        <Anchor to={`/models/${row.id}`} component={Link}>
          {row.model}
        </Anchor>
      </Text>
    </Group>
  );
};

const integratedFormatter: TableColumnFormatter<DeviceModelType> = (_, row) => {
  const { has_device_telemetries } = row;

  if (has_device_telemetries) {
    return <BooleanCell isActive />;
  } else {
    return (
      <PermissionAccess
        minAccessLevel={AccessLevelEnum.Edit}
        permissionKeys="models"
      >
        <ModalButton
          modalName="ConnectFlow"
          label="Integrate"
          data={{ modelId: row.id }}
        />
      </PermissionAccess>
    );
  }
};

interface ModelsListProps {
  defaultFilters?: SmartTableProps<DeviceModelType, 'id'>['defaultFilters'];
  onDuplicate: (model: DeviceModelType) => void;
  onEdit: (model: DeviceModelType) => void;
  onDelete: (model: DeviceModelType) => void;
  onPublish: (model: DeviceModelType) => void;
}

const ModelsList: FC<ModelsListProps> = ({
  defaultFilters,
  onDuplicate,
  onEdit,
  onDelete,
  onPublish,
}) => {
  const deviceModels = useDeviceModels();

  const isModelsAdditionEnabled =
    size(deviceModels?.data) < usePricingPlanFeature('device_models_limit');

  if (!deviceModels.isFetched) {
    return <LoadingOverlay visible />;
  }

  const tableColumns: Array<TableColumn<DeviceModelType>> = compact([
    {
      dataField: 'model',
      text: 'Name',
      sort: true,
      filter: {
        type: TableFilterTypeEnum.Text,
      },
      formatter: nameFormatter,
    },
    {
      dataField: 'type',
      text: 'Type',
      sort: true,
      filter: {
        type: TableFilterTypeEnum.Text,
      },
    },
    {
      dataField: 'has_device_telemetries',
      text: 'Integrated',
      sort: true,
      filter: {
        type: TableFilterTypeEnum.Select,
        options: {
          true: 'Integrated',
          false: 'Not Integrated',
        },
      },
      formatter: integratedFormatter,
    },
    {
      dataField: 'active',
      text: 'Published',
      sort: true,
      filter: {
        type: TableFilterTypeEnum.Select,
        options: { true: 'Published', false: 'Not Published' },
        placeholder: 'Published...',
      },
      formatter: (_, { active }) => <BooleanCell isActive={active} />,
    },
    {
      dataField: 'sku',
      text: 'SKU',
      sort: true,
      filter: {
        type: TableFilterTypeEnum.Text,
      },
    },
  ]);

  const additionalActions = () => {
    if (isModelsAdditionEnabled) {
      return (
        <PermissionAccess
          minAccessLevel={AccessLevelEnum.Edit}
          permissionKeys="models"
        >
          <ModalButton
            modalName="ConfigureModel"
            label="Add Model"
            data-testid="add-model-button"
            size="sm"
            leftIcon={<AddSquare />}
            data={
              {
                isCreate: true,
                model: undefined,
              } satisfies ConfigureModelProps['data']
            }
          />
        </PermissionAccess>
      );
    }

    return (
      <PermissionAccess
        minAccessLevel={AccessLevelEnum.Edit}
        permissionKeys="models"
      >
        <ModalButton
          modalName="UpgradePricingPlan"
          label="Add Model"
          leftIcon={<AddSquare />}
          data={{
            source:
              'Partners > Models Page > Add Model button > Contact Us button in UpgradePricingPlan modal',
          }}
        />
      </PermissionAccess>
    );
  };

  return (
    <SmartTable<DeviceModelType>
      keyField="id"
      name="partners.models"
      data={deviceModels?.data}
      defaultFilters={defaultFilters}
      columns={tableColumns}
      noDataIndication={{ title: 'No models' }}
      additionalActions={additionalActions}
      defaultSorted={[{ id: 'model', desc: false }]}
      rowMenu={({ row, wrapperProps }) => (
        <ModelsListRowMenu
          deviceModel={row.original}
          wrapperProps={wrapperProps}
          onEdit={onEdit}
          onPublish={onPublish}
          onDelete={onDelete}
          onDuplicate={onDuplicate}
        />
      )}
    />
  );
};

export default ModelsList;
