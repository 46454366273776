import {
  Box,
  Button,
  CloseButton,
  createStyles,
  Flex,
  Group,
  Space,
  Stack,
  Text,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React from 'react';
import { v4 as uuid } from 'uuid';

import {
  convertWidgetFormToWidgetProps,
  ScatterChartWidget,
  ScatterChartWidgetForm,
  ScatterChartWidgetFormType,
  WIDGET_COLORS,
} from '@portals/device-widgets';
import { useConfirmationModal } from '@portals/framework';
import { VerticalScrollBar } from '@portals/scrollbar';

import { CommonWidgetFormWrapperProps } from './form-wrappers.types';

export function ScatterChartWidgetFormWrapper({
  initialValues,
  onSubmit,
  onCancel,
  onClose,
  onAddCustomColor,
  colors,
}: CommonWidgetFormWrapperProps<ScatterChartWidgetFormType>) {
  const { classes } = useStyles();
  const asyncConfirmationCheck = useConfirmationModal();

  const form = useForm<ScatterChartWidgetFormType>({
    initialValues: {
      name: '',
      scale_type: 'linear',
      number_format: 'none',
      num_of_decimals: 1,
      events: [
        {
          id: uuid(),
          event_name: '',
          color: colors?.[0] || WIDGET_COLORS[0],
          numeric_parameter: 'int_key_1',
          event_display_name: '',
          units: '',
        },
      ],
      ...(initialValues || {}),
    },
  });

  const onCloseWrapper = async () => {
    if (!form.isDirty()) {
      onClose();
      return;
    }

    const isConfirmed = await asyncConfirmationCheck({
      title: 'Are you sure?',
      description: 'All your changes will be lost',
      confirmationLabel: 'Confirm',
      cancelLabel: 'Cancel',
    });

    if (isConfirmed) onClose();
  };

  const onCancelWrapper = async () => {
    if (!form.isDirty()) {
      onCancel();
      return;
    }

    const isConfirmed = await asyncConfirmationCheck({
      title: 'Are you sure?',
      description: 'All your changes will be lost',
      confirmationLabel: 'Confirm',
      cancelLabel: 'Cancel',
    });

    if (isConfirmed) onCancel();
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Box className={classes.container}>
        <Stack className={classes.preview} p={30} pr={0} pb={0} spacing={0}>
          <Text size={24} color="blue_gray.9">
            Add Scatter Chart
          </Text>

          <VerticalScrollBar>
            <Space h="xl" />

            <Box pr={30}>
              <ScatterChartWidgetForm
                form={form}
                colors={colors}
                onAddCustomColor={onAddCustomColor}
              />
            </Box>

            <Space h="xl" />
          </VerticalScrollBar>
        </Stack>

        <Stack className={classes.config} p={30}>
          <Group position="right">
            <CloseButton size="xl" onClick={onCloseWrapper} />
          </Group>

          <Flex align="center" justify="center" h="100%" w="100%">
            <ScatterChartWidget
              {...convertWidgetFormToWidgetProps.scatter_chart({
                formValues: form.values,
              })}
              stackProps={{ h: 380, w: '100%' }}
              withZoom={false}
            />
          </Flex>
        </Stack>

        <Group className={classes.footer} p="xl" position="right">
          <Button variant="default" onClick={onCancelWrapper}>
            {initialValues ? 'Close' : 'Back'}
          </Button>

          <Button
            type="submit"
            data-testid={
              initialValues ? 'save-widget-button' : 'add-widget-button'
            }
          >
            {initialValues ? 'Save' : 'Add Widget'}
          </Button>
        </Group>
      </Box>
    </form>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr min-content',
    gridTemplateAreas: `
      "preview config"
      "footer footer"
    `,
    gap: 0,
    height: 790,
  },
  preview: {
    gridArea: 'preview',
  },
  config: {
    gridArea: 'config',
    backgroundColor: theme.colors.gray[0],
    width: 0,
    minWidth: '100%',
  },
  footer: {
    borderTop: `1px solid ${theme.colors.gray[2]}`,
    gridArea: 'footer',
  },
}));
