import { Box, Button, createStyles, Group } from '@mantine/core';
import { isEmpty, map, take } from 'lodash/fp';
import React from 'react';

import { OverflowGroup } from '@portals/core';
import { ReactComponent as Danger } from '@portals/icons/linear/danger.svg';
import { ReactComponent as InfoCircle } from '@portals/icons/linear/info-circle.svg';
import { ReactComponent as Refresh } from '@portals/icons/linear/refresh.svg';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import { useOpenModal } from '@portals/redux';
import { SupportedCommandType } from '@portals/types';

import MoreCommands from './MoreCommands';
import { ModalButton } from '../ModalButton';

interface CommandButtonsProps<TDevice> {
  device: TDevice;
  updateDeviceData: () => void;
  management: boolean;
  getCommandParams: (command: SupportedCommandType) => any;
  supportedCommands: Array<SupportedCommandType>;
  isPending: boolean;
  withSupportCenter: boolean;
}

const MAX_NUM_OF_COMMANDS = 5;

export function CommandButtons<TDevice>({
  device,
  updateDeviceData,
  management,
  getCommandParams,
  supportedCommands = [],
  isPending,
  withSupportCenter = false,
}: CommandButtonsProps<TDevice>) {
  const openModal = useOpenModal();
  const { classes } = useStyles();

  const commands = isEmpty(supportedCommands) ? null : (
    <Box
      sx={(theme) => ({
        display: 'grid',
        gridAutoColumns: 'max-content',
        gridAutoFlow: 'column',
        columnGap: theme.spacing.xs,
        overflow: 'hidden',
        width: '100%',
        maxWidth: 'fit-content',
      })}
    >
      {map(
        (command) => (
          <ModalButton
            key={command.id}
            modalName="RunCommand"
            variant="outline"
            label={command.friendly_name}
            data={getCommandParams(command)}
          />
        ),
        take<SupportedCommandType>(MAX_NUM_OF_COMMANDS, supportedCommands)
      )}
    </Box>
  );

  return (
    <Box py="sm" px="md" className={classes.container}>
      <Box className={classes.commandsWrapper}>
        <OverflowGroup
          isAlwaysRenderOverflow
          overflowRenderer={({ hiddenIndexes, amount }) => (
            <MoreCommands
              key={amount}
              maxNumOfCommands={MAX_NUM_OF_COMMANDS}
              hiddenIndexes={hiddenIndexes}
              supportedCommands={supportedCommands}
              onOpenCommandModal={(command) =>
                openModal('RunCommand', getCommandParams(command))
              }
            />
          )}
        >
          {commands}
        </OverflowGroup>
      </Box>

      <Group noWrap position="right" spacing="xs">
        {management && (
          <>
            <ModalButton
              variant="default"
              modalName="ReportIncident"
              label="Report Incident"
              leftIcon={<Danger />}
              data={{ device }}
            />

            {withSupportCenter && (
              <ModalButton
                variant="default"
                modalName="CreateTicket"
                label="Open Ticket"
                leftIcon={<InfoCircle />}
                data={{ device }}
              />
            )}
          </>
        )}

        <Button
          onClick={updateDeviceData}
          variant="outline"
          loading={isPending}
          size="xs"
          sx={{
            svg: {
              width: 16,
              height: 16,
            },
          }}
          leftIcon={<Refresh />}
        >
          Refresh
        </Button>

        {management ? (
          <ModalButton
            variant="outline"
            modalName="DeleteDevice"
            label="Delete"
            color="red"
            leftIcon={<Trash />}
            data={device}
          />
        ) : null}
      </Group>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr max-content',
    columnGap: theme.spacing.md,
    borderBottom: `1px solid ${theme.colors.gray[3]}`,
  },
  commandsWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    columnGap: theme.spacing.xs,
    alignItems: 'center',
  },
}));
