import { Button, Group, Stack, Text } from '@mantine/core';
import React from 'react';
import { connect } from 'react-redux';

import Response from '../../components/Response';
import TestCommand from '../../components/TestCommand';
import { getApiDevice, getCommand } from '../../redux/actions/api';

const GetCommand = ({ access, getCommand }) => (
  <Stack>
    <Group>
      <Button onClick={() => getCommand(access.id)} disabled={!access.id}>
        Get
      </Button>
      <Text>
        Device ID:
        {access.id || 'Not set'}
      </Text>
    </Group>

    {access.id && <TestCommand url={`/${access.id}/command`} />}

    <Response type="getCommand" />
  </Stack>
);

const mapStateToProps = (state) => ({
  access: getApiDevice(state),
});

export default connect(mapStateToProps, { getCommand })(GetCommand);
