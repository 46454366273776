import React from 'react';

import { SmartTableProps } from '@portals/types';

import { useSmartTable } from './hooks';
import { SmartTable } from './SmartTable';
import { TableInstanceProvider } from '../context';

export function SmartTableWrapper<
  TData extends object,
  TKeyField extends keyof TData = keyof TData
>({
  name,
  additionalActions,
  selectedItemsActions,
  noDataIndication,
  exportParams,
  noHeader,
  noFilters,
  noColumnsSelection,
  defaultSorted,
  defaultFilters,
  columns,
  data = [],
  expandRow,
  noSort,
  onSelected,
  rowStyle,
  rowMenu,
  isUrlSyncEnabled,
  isCompact,
  expanded,
  pageSize,
  menuProps = {},
  detailsPanel,
  viewType,
  gridView,
  readOnly = false,
  onRowClick,
  keyField,
}: SmartTableProps<TData, TKeyField>) {
  const tableInstance = useSmartTable<TData>({
    defaultSortBy: defaultSorted,
    defaultFilters,
    data,
    expandRow,
    rowStyle,
    columns,
    onSelected,
    isUrlSyncEnabled,
    name,
    noColumnsSelection,
    expanded,
    pageSize,
    readOnly,
  });

  return (
    <TableInstanceProvider instance={tableInstance}>
      <SmartTable<TData, TKeyField>
        name={name}
        rowStyle={rowStyle}
        expandRow={expandRow}
        onSelected={onSelected}
        additionalActions={additionalActions}
        selectedItemsActions={selectedItemsActions}
        noHeader={noHeader}
        noFilters={noFilters}
        noSort={noSort}
        noDataIndication={noDataIndication}
        noColumnsSelection={noColumnsSelection}
        exportParams={exportParams}
        rowMenu={rowMenu}
        isCompact={isCompact}
        withGlobalSearch
        menuProps={menuProps}
        detailsPanel={detailsPanel}
        viewType={viewType}
        gridView={gridView}
        readOnly={readOnly}
        onRowClick={onRowClick}
        keyField={keyField}
        columns={columns}
      />
    </TableInstanceProvider>
  );
}

export default SmartTableWrapper;
