import React from 'react';
import { useParams } from 'react-router-dom';

import { IncidentDetails } from './IncidentDetails';
import IncidentsList from './IncidentsList';
import { SupportStateProvider } from '../support-overview/SupportStateProvider';

function Incidents() {
  const { id } = useParams<{ id: string | undefined }>();

  return (
    <SupportStateProvider>
      {id ? <IncidentDetails /> : <IncidentsList />}
    </SupportStateProvider>
  );
}

export default Incidents;
