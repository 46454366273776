import { isEmpty } from 'lodash/fp';
import React from 'react';
import { connect } from 'react-redux';
import {
  Table,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Button,
} from 'reactstrap';

import { prettyTime } from '@portals/utils';

import { clearErrors } from '../../redux/actions/rules';

const RuleErrors = ({ rule, errors, clearErrors, readOnly }) => (
  <Card className="bg-transparent">
    <CardHeader>
      <CardTitle tag="h5">Errors</CardTitle>
      <Button
        disabled={readOnly}
        className="float-right"
        color="primary"
        onClick={() => clearErrors(rule.device_model, rule.id)}
      >
        Clear All
      </Button>
      <h6 className="card-subtitle text-muted">
        Errors that have risen since last edited
      </h6>
    </CardHeader>
    <CardBody className="p-0">
      <Table>
        <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Description</th>
            <th>Create At</th>
          </tr>
        </thead>
        <tbody>
          {!isEmpty(errors) &&
            errors.map((error) => (
              <tr key={error.id}>
                <td>{error.id}</td>
                <td>{error.name}</td>
                <td>{error.description}</td>
                <td>{prettyTime(error.created_at)}</td>
              </tr>
            ))}

          {isEmpty(errors) && (
            <tr>
              <td colSpan={4} className="text-muted text-center">
                No Errors
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </CardBody>
  </Card>
);

const mapStateToProps = (state, ownProps) => ({
  rule: ownProps.rule,
  errors: ownProps.rule.errors,
});

export default connect(mapStateToProps, { clearErrors })(RuleErrors);
