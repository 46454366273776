import { Anchor, Group } from '@mantine/core';
import { noop, truncate } from 'lodash/fp';
import React from 'react';
import { Link } from 'react-router-dom';

import { useFileInfos } from '@portals/api/partners';
import { FileTypeIcon } from '@portals/core';
import { usePermissionAccess } from '@portals/framework';
import { DateCell, PaginatedTable } from '@portals/table';
import {
  PartnerFileResponseType,
  TableColumn,
  TableFilterTypeEnum,
} from '@portals/types';

import { FileInfosTableRowMenu } from './FileInfosTableRowMenu';
import FilesSelectedActions from './FilesSelectedActions';
import { FileDetailsPanel } from '../../components/Files/FileDetailsPanel';

const COLUMNS: Array<TableColumn<PartnerFileResponseType>> = [
  {
    dataField: 'name',
    text: 'Name',
    sort: true,
    isSticky: true,
    filter: {
      type: TableFilterTypeEnum.Text,
    },
    formatter: (cell, row) => (
      <Group align="center" spacing="md">
        <FileTypeIcon width={24} fileUrl={row.url} />

        <Anchor to={`/files/${row.id}`} component={Link}>
          {truncate({ length: 20 }, row.name)}
        </Anchor>
      </Group>
    ),
  },
  {
    dataField: 'device_model_name',
    text: 'Model',
    sort: true,
    filter: {
      type: TableFilterTypeEnum.Text,
    },
    formatter: (cell, row) => (
      <Anchor to={`/models/${row.device_model_id}`} component={Link}>
        {row.device_model_name}
      </Anchor>
    ),
  },
  {
    dataField: 'desc',
    text: 'Description',
    sort: true,
    filter: {
      type: TableFilterTypeEnum.Text,
    },
    formatter: (cell, row) => truncate({ length: 30 }, row.desc),
  },
  {
    dataField: 'version',
    text: 'Version',
    sort: true,
    filter: {
      type: TableFilterTypeEnum.Text,
    },
  },
  {
    dataField: 'created_at',
    text: 'Date Created',
    sort: true,
    filter: {
      type: TableFilterTypeEnum.Date,
    },
    formatter: (cell, row) => <DateCell date={row.created_at} />,
  },
];

export function FileInfosTable() {
  const { canEdit } = usePermissionAccess();

  return (
    <PaginatedTable<PartnerFileResponseType>
      readOnly={!canEdit('models')}
      keyField="id"
      name="partners.files"
      dataHook={useFileInfos}
      columns={COLUMNS}
      onSelected={noop}
      noDataIndication={{ title: 'No files' }}
      defaultSortBy={[{ id: 'name', desc: false }]}
      selectedItemsActions={() => <FilesSelectedActions />}
      rowMenu={({ row, wrapperProps }) => (
        <FileInfosTableRowMenu
          wrapperProps={wrapperProps}
          fileInfo={row.original}
        />
      )}
      detailsPanel={{
        type: 'page',
        renderer: ({ row, onClose }) => (
          <FileDetailsPanel file={row.original} onClose={onClose} />
        ),
      }}
    />
  );
}
