import { ActionIcon, createStyles, Group, Menu, Text } from '@mantine/core';
import React, { useState } from 'react';

import { ReactComponent as Plus } from '@portals/icons/linear/add-square.svg';
import { ReactComponent as ChevronDownIcon } from '@portals/icons/linear/chevron-down.svg';
import { useOpenModal } from '@portals/redux';

import { CreateNewPartnerTypeModalProps } from '../../modals';

interface PartnerTypeProps {
  partnerId: string;
  partnerRelationshipType: string;
  onUpdateType: (id: string[], relationshipType: string) => void;
  relationshipTypes: string[] | undefined;
}

export function PartnerType({
  partnerId,
  partnerRelationshipType,
  onUpdateType,
  relationshipTypes,
}: PartnerTypeProps) {
  const { classes, cx } = useStyles();
  const openModal = useOpenModal();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Group onClick={() => setIsOpen(true)} noWrap>
      <Text w="100%" color="blue_gray.7">
        {partnerRelationshipType}
      </Text>

      <Menu
        position="bottom-end"
        opened={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
      >
        <Menu.Target>
          <ActionIcon variant="subtle" color="blue_gray" size="md">
            <ChevronDownIcon />
          </ActionIcon>
        </Menu.Target>

        <Menu.Dropdown>
          {relationshipTypes?.map((relationshipType) => (
            <Menu.Item
              key={relationshipType}
              onClick={() => onUpdateType([partnerId], relationshipType)}
              className={cx({
                [classes.selected]:
                  partnerRelationshipType === relationshipType,
              })}
            >
              {relationshipType}
            </Menu.Item>
          ))}

          <Menu.Divider />

          <Menu.Item
            icon={<Plus />}
            onClick={() =>
              openModal<CreateNewPartnerTypeModalProps['data']>(
                'CreateNewPartnerTypeModal',
                {
                  onChange: (relationshipType: string) =>
                    onUpdateType([partnerId], relationshipType),
                }
              )
            }
          >
            Create New
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  selected: {
    color: theme.fn.primaryColor(),
  },
}));
