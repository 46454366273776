import { LoadingOverlay } from '@mantine/core';
import React from 'react';

import { useOrganizations } from '@portals/api/partners';
import { useCurrentUser } from '@portals/api/ui';
import { DashboardContentLayout } from '@portals/framework';

import { CustomersTable } from './CustomersTable';

export function Customers() {
  const currentUser = useCurrentUser();
  const organizations = useOrganizations();

  const accessibleOrganizations =
    currentUser?.data?.accessible_tenants?.organizations;

  return (
    <DashboardContentLayout
      pageTitle="Customers"
      subtitle="Registered Customers"
      pos="relative"
    >
      <LoadingOverlay visible={organizations.isLoading} />

      <CustomersTable
        organizations={organizations.data ?? []}
        accessibleOrganizations={accessibleOrganizations}
      />
    </DashboardContentLayout>
  );
}
