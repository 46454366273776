import { Carousel, CarouselProps } from '@mantine/carousel';
import { Box, createStyles } from '@mantine/core';
import React from 'react';

import { ReactComponent as ArrowLeftIcon } from '@portals/icons/linear/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from '@portals/icons/linear/arrow-right.svg';

import { DevicesOverviewSlide } from './DevicesOverviewSlide';
import { DevicesTileSlide } from './DevicesTileSlide';
import { useBrandingContext } from '../../branding.context';

export function DevicesPreview() {
  const { classes } = useStyles();

  const { portalConfig, defaultValues } = useBrandingContext();

  const logoSrc =
    portalConfig.design?.cec_devices?.logo ||
    defaultValues.design?.cec_devices?.logo;

  const logoImage = logoSrc ? (
    <img
      width="100%"
      height="100%"
      src={logoSrc}
      alt="logo"
      className={classes.logo}
    />
  ) : null;

  return (
    <Box h="100%">
      <Carousel
        align="center"
        slideGap="xxl"
        slideSize="80%"
        controlSize={56}
        styles={carouselStyles}
        withIndicators
        nextControlIcon={<ArrowRightIcon />}
        previousControlIcon={<ArrowLeftIcon />}
      >
        <Carousel.Slide>
          <DevicesTileSlide logo={logoImage} />
        </Carousel.Slide>

        <Carousel.Slide>
          <DevicesOverviewSlide logo={logoImage} />
        </Carousel.Slide>
      </Carousel>
    </Box>
  );
}

const useStyles = createStyles({
  logo: {
    objectFit: 'contain',
  },
});

const carouselStyles: CarouselProps['styles'] = (theme) => ({
  root: {
    paddingBlock: 70,
    height: '100%',
  },
  viewport: {
    overflow: 'visible',
    height: '100%',
  },
  container: {
    height: '100%',
  },
  slide: {
    height: '100%',
  },
  control: {
    '&:not(:active)': { boxShadow: '0px 11px 18px 0px rgba(38, 50, 56, 0.23)' },
    '&:nth-child(1)': { marginRight: 'auto' },
    '&:nth-child(2)': { marginLeft: 'auto' },
    '&[data-inactive]': { display: 'none' },
  },
  indicators: {
    bottom: 50,
  },
  indicator: {
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: theme.colors.blue_gray[1],

    '&[data-active]': {
      backgroundColor: theme.fn.primaryColor(),
    },
  },
});
