import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isBoolean } from 'lodash/fp';
import { useDispatch } from 'react-redux';

import { useAuth } from '@portals/redux';
import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';
import {
  PartnerConfigurationType,
  PartnerNotificationSettingsType,
  TenantType,
} from '@portals/types';

import {
  CONFIG_API_URL,
  configurationQueryKeys,
} from './configuration.constants';
import { useApiQuery } from '../../hooks';
import { QueryOptions, ServerError } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';
import { connectedPartnersQueryKeys } from '../connected-partners';

export function useConfig(
  queryOptions: QueryOptions<PartnerConfigurationType> = {}
) {
  const auth = useAuth();

  return useApiQuery<PartnerConfigurationType>(
    CONFIG_API_URL,
    configurationQueryKeys.config,
    {
      ...queryOptions,
      enabled:
        (!isBoolean(queryOptions.enabled) || queryOptions.enabled) &&
        Boolean(auth),
    }
  );
}

/*
 * Mutations
 * */
interface UseUpdateConfigParams {
  updatedConfig: Partial<{
    support_center_enabled: boolean;
    device_monitoring_enabled: boolean;
    organization_self_managed_warranties_enabled: boolean;
    organization_signup_enabled: boolean;
    notification_settings: PartnerNotificationSettingsType;
    visible_to_other_partners: boolean;
  }>;
  withSuccessNotification?: boolean;
}

export const useUpdateConfig = () => {
  const dispatch = useDispatch();

  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: CONFIG_API_URL,
    method: 'PUT',
  });

  return useMutation<
    PartnerConfigurationType,
    ServerError,
    UseUpdateConfigParams
  >({
    mutationFn: ({ updatedConfig }) => {
      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify(updatedConfig),
      });
    },
    onSuccess: (config, { withSuccessNotification }) => {
      queryClient.setQueryData(configurationQueryKeys.config, config);

      queryClient.invalidateQueries(connectedPartnersQueryKeys.active());

      if (withSuccessNotification) {
        dispatch(toastrSuccess('Configuration settings updated successfully'));
      }
    },
    onError: ({ error }) => dispatch(toastrError(error)),
    meta: {
      mutationName: 'useUpdateConfig',
      baseUrl: CONFIG_API_URL,
      method: 'PUT',
    },
  });
};

export function useRequestPlanUpgrade() {
  const dispatch = useDispatch();
  const { url, options } = useRequestOptions({
    url: `${CONFIG_API_URL}/request_plan_upgrade`,
    method: 'POST',
  });

  return useMutation<void, ServerError, string>({
    mutationFn: (source) =>
      fetchApiRequest(url, { ...options, body: JSON.stringify({ source }) }),
    onSuccess: () => {
      dispatch(
        toastrSuccess("We'll contact you shortly to upgrade your plan!")
      );
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useRequestPlanUpgrade',
      baseUrl: `${CONFIG_API_URL}/request_plan_upgrade`,
      method: 'POST',
    },
  });
}

export function useRequestAccountDeletion() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: `${CONFIG_API_URL}/request_account_deletion`,
    method: 'POST',
  });

  return useMutation<void, ServerError, void>({
    mutationFn: () => {
      return fetchApiRequest(url, options);
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Account deletion requested'));

      queryClient.invalidateQueries(configurationQueryKeys.config);
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useRequestAccountDeletion',
      baseUrl: `${CONFIG_API_URL}/request_account_deletion`,
      method: 'POST',
    },
  });
}

export function useRequestCecEnrollment() {
  const dispatch = useDispatch();

  const { url, options } = useRequestOptions({
    url: `${CONFIG_API_URL}/request_cec_enrollment`,
    method: 'POST',
  });

  return useMutation<void, ServerError>({
    mutationFn: () => {
      return fetchApiRequest(url, options);
    },
    onSuccess: () => {
      dispatch(
        toastrSuccess(
          'Thank you for your interest, our customer success team will contact you shortly'
        )
      );
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
  });
}

// Helpers

export function useFeatureFlags() {
  const { data } = useConfig();

  return data?.feature_flags;
}

export function usePartnerConfig(
  queryOptions: QueryOptions<PartnerConfigurationType> = {}
) {
  const { data } = useConfig(queryOptions);

  return data?.[TenantType.Partner];
}

export function useNotificationSettings() {
  const { data } = useConfig();

  return data?.notification_settings;
}

export function usePortalCapabilities() {
  const config = useConfig();

  return config.data?.portal_capabilities;
}

export function useWithSupportCenter() {
  const portalCapabilities = usePortalCapabilities();

  return Boolean(portalCapabilities?.support_center);
}

export function useIsChannelPartner(
  queryOptions: QueryOptions<PartnerConfigurationType> = {}
) {
  const partnerConfig = usePartnerConfig(queryOptions);

  return partnerConfig?.classification === 'channel_partner';
}

export function useIsCecPartner() {
  const partnerConfig = usePartnerConfig();

  return partnerConfig?.is_cec_partner;
}
