import {
  Box,
  Button,
  createStyles,
  Group,
  LoadingOverlay,
  Stack,
  Text,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { FormErrors } from '@mantine/form/lib/types';
import { Reorder } from 'framer-motion';
import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { v4 as uuid } from 'uuid';
import { array, object, string } from 'yup';

import {
  DeviceModelType,
  TroubleshootingStepType,
  useEditDeviceModel,
  useResetModelTroubleshootingSteps,
} from '@portals/api/partners';
import { useConfirmationModal } from '@portals/framework';
import { ReactComponent as Refresh } from '@portals/icons/linear/refresh.svg';
import { VerticalScrollBar } from '@portals/scrollbar';

import { TroubleshootingEmptyState } from './TroubleshootingEmptyState';
import { TroubleshootingPreview } from './TroubleshootingPreview';
import { TroubleshootingStep } from './TroubleshootingStep';

interface TroubleshootingProps {
  model: DeviceModelType;
}

const stepSchema = object({
  steps: array()
    .of(
      object({
        id: string(),
        title: string().required('Title is required'),
        description: string().required('Description is required'),
        icon: string().required(),
      })
    )
    .required(),
});

function addIdsToTroubleshootingSteps(
  troubleshootingSteps: Array<TroubleshootingStepType>
): Array<TroubleshootingStepType & { id: string }> {
  return troubleshootingSteps.map((step) => ({
    ...step,
    id: uuid(),
  }));
}

function removeIdsFromTroubleshootingSteps(
  troubleshootingSteps: Array<TroubleshootingStepType & { id: string }>
): Array<TroubleshootingStepType> {
  return troubleshootingSteps.map(({ id, ...step }) => step);
}

export function Troubleshooting({ model }: TroubleshootingProps) {
  const { classes, theme } = useStyles();

  const asyncConfirmationCheck = useConfirmationModal();
  const editDeviceModel = useEditDeviceModel();
  const resetModelTroubleshootingSteps = useResetModelTroubleshootingSteps();

  const form = useForm<{
    steps: Array<TroubleshootingStepType & { id: string }>;
  }>({
    initialValues: {
      steps: addIdsToTroubleshootingSteps(model.troubleshooting_steps),
    },
    validate: yupResolver(stepSchema),
  });

  const onAddItem = () => {
    form.insertListItem(
      'steps',
      {
        id: uuid(),
        label: '',
        description: '',
        icon: 'password-check',
      },
      0
    );
  };

  const onCancelChanges = async () => {
    const isConfirmed = await asyncConfirmationCheck({
      title: 'Are you sure?',
      description: 'All your changes will be lost',
      confirmationLabel: 'Confirm',
      cancelLabel: 'Cancel',
    });

    if (isConfirmed) {
      form.reset();
      form.setErrors({});
    }
  };

  const onSave = async () => {
    if (form.validate().hasErrors) {
      return;
    } else {
      const updatedSteps = removeIdsFromTroubleshootingSteps(form.values.steps);

      editDeviceModel.mutate({
        ...model,
        troubleshooting_steps: updatedSteps,
      });
    }
  };

  const onError = (errors: FormErrors) => {
    const firstErrorPath = Object.keys(errors)[0];

    if (!firstErrorPath) return;

    const firstInput = document.getElementById(firstErrorPath);

    if (!firstInput) return;

    firstInput.focus?.();
  };

  const onReset = async () => {
    const isConfirmed = await asyncConfirmationCheck({
      title: 'Are you sure?',
      description: 'All your changes will be lost',
      confirmationLabel: 'Confirm',
      cancelLabel: 'Cancel',
    });

    if (!isConfirmed) return;

    resetModelTroubleshootingSteps.mutate(model.id, {
      onSuccess: (updatedDeviceModel) => {
        form.setFieldValue(
          'steps',
          addIdsToTroubleshootingSteps(updatedDeviceModel.troubleshooting_steps)
        );

        form.reset();
        form.setErrors({});
      },
    });
  };

  if (!form.values.steps.length) {
    return (
      <Box
        className={classes.wrapper}
        bg="gray.0"
        sx={{ borderRadius: theme.radius.lg }}
      >
        <TroubleshootingEmptyState onAddItem={onAddItem} />
      </Box>
    );
  }

  return (
    <form onSubmit={form.onSubmit(onSave, onError)} className={classes.form}>
      <Box className={classes.wrapper}>
        <LoadingOverlay
          visible={
            editDeviceModel.isLoading ||
            resetModelTroubleshootingSteps.isLoading
          }
        />

        <Box className={classes.container} h="100%" bg="gray.0">
          <VerticalScrollBar
            renderView={(props) => (
              <Box
                {...props}
                display="grid"
                p="xl"
                sx={{ justifyItems: 'center' }}
              />
            )}
          >
            <Stack maw={910} w="100%" align="center" spacing="xxl" p="xxl">
              <Text color="gray.6" size="sm" align="center">
                Create a troubleshooting guide for your device model: this guide
                will be
                <br /> shown to customers if they are unable to claim their
                device.
              </Text>

              <Box className={classes.header}>
                <Button
                  data-analyticsid="troubleshooting-reset-to-default"
                  px="xl"
                  variant="default"
                  sx={{ flexShrink: 0 }}
                  leftIcon={<Refresh width={18} height={18} />}
                  onClick={onReset}
                >
                  Reset to default
                </Button>

                <Button
                  data-analyticsid="troubleshooting-add-issue"
                  w="100%"
                  color="gray.9"
                  variant="filled"
                  onClick={onAddItem}
                >
                  + Add Issue
                </Button>
              </Box>

              <Reorder.Group
                axis="y"
                values={form.values.steps}
                onReorder={(values) => form.setFieldValue('steps', values)}
                animate={false}
              >
                <Stack spacing="xxl" w="100%" maw={510}>
                  {form.values.steps.map((step, index) => (
                    <TroubleshootingStep
                      key={step.id}
                      index={index}
                      form={form}
                    />
                  ))}
                </Stack>
              </Reorder.Group>
            </Stack>
          </VerticalScrollBar>

          <VerticalScrollBar
            renderView={(props) => (
              <Box {...props} display="grid" sx={{ justifyItems: 'center' }} />
            )}
          >
            <TroubleshootingPreview steps={form.values.steps} />
          </VerticalScrollBar>
        </Box>

        <Group
          spacing="md"
          position="right"
          align="center"
          w="100%"
          p="xl"
          bg="white"
          pb={0}
        >
          <Button
            variant="default"
            onClick={onCancelChanges}
            disabled={!form.isDirty()}
          >
            Cancel
          </Button>

          <Button
            data-analyticsid="troubleshooting-save"
            variant="filled"
            color="gray.9"
            type="submit"
            disabled={!form.isDirty()}
          >
            Save
          </Button>
        </Group>
      </Box>

      <GlobalOverride />
    </form>
  );
}

const GlobalOverride = createGlobalStyle`
  .page-content {
    >div {
      >div {
        padding-bottom: 1rem;
      }
    }
  }
`;

const useStyles = createStyles((theme) => ({
  form: {
    width: '100%',
    height: '100%',
  },
  wrapper: {
    display: 'grid',
    gridTemplateRows: '1fr max-content',
    height: '100%',
  },
  header: {
    display: 'grid',
    gridTemplateColumns: 'min-content 1fr',
    width: '100%',
    maxWidth: 510,
    gap: theme.spacing.md,
  },
  container: {
    borderRadius: theme.radius.lg,
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: '1.5fr 1fr',
    overflow: 'hidden',

    'ul, li': {
      listStyle: 'none',
      padding: 0,
      margin: 0,
    },

    ul: {
      position: 'relative',
      maxWidth: 510,
      width: '100%',
    },

    li: {
      position: 'relative',
    },
  },
  footer: {
    background: theme.white,
    width: '100%',
    borderTop: `1px solid ${theme.colors.gray[2]}`,
    borderBottomLeftRadius: theme.radius.lg,
    borderBottomRightRadius: theme.radius.lg,
    bottom: 0,
  },
}));
