import React from 'react';
import { connect } from 'react-redux';

import { TelemetriesView } from '@portals/framework';

import { getTelemetries } from '../../redux/actions/ticket_device';

const Telemetries = ({ device, getTelemetries }) => (
  <TelemetriesView
    telemetries={device.telemetries}
    showing={device.telemetries.length}
    getTelemetries={getTelemetries}
  />
);

const mapDispatchToProps = (dispatch, ownProps) => ({
  getTelemetries: () => dispatch(getTelemetries(ownProps.ticket.id)),
});

export default connect(null, mapDispatchToProps)(Telemetries);
