import {
  Box,
  createStyles,
  Group,
  Image,
  LoadingOverlay,
  Stack,
  Text,
} from '@mantine/core';
import { map } from 'lodash/fp';
import React, { useCallback } from 'react';

import {
  useRemoveCurrency,
  useStoreSettings,
  useUpdateStoreSettings,
} from '@portals/api/partners';
import { CURRENCIES, CURRENCIES_FLAGS } from '@portals/countries';
import { usePermissionAccess } from '@portals/framework';
import { ReactComponent as DollarCircle } from '@portals/icons/linear/dollar-circle.svg';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import { CurrencyCode } from '@portals/types';

import { ItemMenu } from './ItemMenu';

export const EnabledCurrenciesList = () => {
  const { classes } = useStyles();
  const storeSettings = useStoreSettings();
  const updateStoreSettings = useUpdateStoreSettings();
  const removeCurrency = useRemoveCurrency();
  const { isAdmin } = usePermissionAccess();

  const onRemove = useCallback(
    async (currencyKey: CurrencyCode) => {
      try {
        await removeCurrency.mutateAsync({
          currency: currencyKey,
        });
      } catch (err) {
        console.error(err);
      }
    },
    [removeCurrency]
  );

  const onSetDefault = useCallback(
    async (currencyKey: CurrencyCode) => {
      try {
        await updateStoreSettings.mutateAsync({
          ...storeSettings.data,
          default_currency: currencyKey,
        });
      } catch (err) {
        console.error(err);
      }
    },
    [storeSettings.data, updateStoreSettings]
  );

  return (
    <Stack>
      <LoadingOverlay visible={storeSettings.isLoading} />
      {map((currency) => {
        const isDefault = currency === storeSettings.data?.default_currency;

        let availableActions;

        if (isAdmin) {
          if (isDefault) {
            availableActions = [
              {
                Icon: Trash,
                title: 'Remove Currency',
                onClick: () => onRemove(currency),
                color: 'red',
              },
            ];
          } else {
            availableActions = [
              {
                Icon: DollarCircle,
                title: 'Set as Default',
                onClick: () => onSetDefault(currency),
              },
              {
                Icon: Trash,
                title: 'Remove Currency',
                onClick: () => onRemove(currency),
                color: 'red',
              },
            ];
          }
        }

        return (
          <Group
            key={currency}
            spacing="sm"
            className={classes.container}
            py="xl"
            position="apart"
            sx={{
              order: isDefault ? -1 : 'unset',
            }}
          >
            <Group>
              <Box
                sx={(theme) => ({
                  borderRadius: '2.5px',
                  overflow: 'hidden',
                  width: 30,
                  height: 20,
                  border: `1px solid ${theme.colors.gray[3]}`,
                })}
              >
                <Image
                  src={CURRENCIES_FLAGS[currency]}
                  sx={{ width: '100%', height: '100%' }}
                />
              </Box>

              <Group align="end" spacing="xs">
                <Text className="currency-name">{CURRENCIES[currency]}</Text>

                {isDefault ? (
                  <Text className="currency-default-text">(default)</Text>
                ) : null}
              </Group>
            </Group>

            {!availableActions ? null : <ItemMenu items={availableActions} />}
          </Group>
        );
      }, storeSettings.data?.currencies)}
    </Stack>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    borderBottom: `1px solid ${theme.colors.gray[3]}`,
    fontSize: theme.fontSizes.sm,

    '.currency-name': {
      color: theme.colors.blue_gray[8],
      fontSize: theme.fontSizes.sm,
    },

    '.currency-default-text': {
      color: theme.colors.blue_gray[3],
      fontSize: theme.fontSizes.sm,
    },
  },
}));
