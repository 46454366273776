export const CUSTOMER_PORTAL_PATHS = {
  PortalDomain: 'portal-domain',
  AuthPages: 'auth-pages',
  Connect: 'connect',
  DeviceClaiming: 'device-claiming',
  Devices: 'devices',
  Orders: 'orders',
  Tickets: 'tickets',
  NavigationMenu: 'navigation-menu',
} as const;

export type CustomerPortalPaths =
  (typeof CUSTOMER_PORTAL_PATHS)[keyof typeof CUSTOMER_PORTAL_PATHS];
