import {
  ActionIcon,
  Badge,
  Box,
  createStyles,
  Group,
  Text,
} from '@mantine/core';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { useMemo } from 'react';

import {
  INVOICES_API_URL,
  PartnerInvoiceType,
  useInvoicesTableData,
  useUpdateInvoicePartnerNote,
} from '@portals/api/partners';
import { FileTypeIcon } from '@portals/core';
import {
  OrderItemsPreviewTooltip,
  OrderItemsPreviewTooltipProps,
  PartnerInvoicePaymentStatusBadge,
  usePermissionAccess,
} from '@portals/framework';
import { EditNoteModalProps } from '@portals/framework/modals';
import { ReactComponent as Edit } from '@portals/icons/linear/edit.svg';
import { useOpenModal } from '@portals/redux';
import { AvatarCell, DateCell, PaginatedTable } from '@portals/table';
import {
  OrderPaymentStatusType,
  TableColumn,
  TableFilterTypeEnum,
} from '@portals/types';
import {
  downloadFile,
  formatCurrency,
  getOrderPaymentMethodLabel,
  suppressPropagation,
} from '@portals/utils';

import { InvoiceDetailsPanel } from './InvoiceDetailsPanel';
import { ReactComponent as UploadFile } from '../../../../assets/img/icons/upload-file.svg';

dayjs.extend(relativeTime);

interface InvoicesTableProps {
  status: OrderPaymentStatusType | 'all';
  subtitle?: string;
}
export function InvoicesTable({ status, subtitle }: InvoicesTableProps) {
  const { canEdit } = usePermissionAccess();
  const { classes } = useStyles();
  const openModal = useOpenModal();
  const updateInvoicePartnerNote = useUpdateInvoicePartnerNote();

  const tableColumns = useMemo(() => {
    const idColumn: TableColumn<PartnerInvoiceType> = {
      dataField: 'orders_short_id',
      text: 'ID',
      sort: true,
      filter: {
        type: TableFilterTypeEnum.Text,
      },
      minWidth: 200,
      maxWidth: 200,
      formatter: (_, { order_short_id }) => order_short_id,
    };

    const customerColumn: TableColumn<PartnerInvoiceType> = {
      dataField: 'organization_display_name',
      text: 'Customer',
      sort: true,
      filter: {
        type: TableFilterTypeEnum.Text,
      },
      formatter: (_, { organization }) => (
        <AvatarCell
          label={organization.display_name}
          src={organization.logo_url}
        />
      ),
    };

    const itemsColumn: TableColumn<PartnerInvoiceType> = {
      dataField: 'items',
      text: 'Items',
      minWidth: 100,
      formatter: (_, { items, items_count }) => {
        if (!items_count) return null;

        const itemsList: OrderItemsPreviewTooltipProps['items'] = items.map(
          (invoiceItem) => ({
            name: invoiceItem.product_name,
            quantity: invoiceItem.quantity,
            imageUrl: invoiceItem.product_image_url,
          })
        );

        return (
          <OrderItemsPreviewTooltip items={itemsList}>
            <Badge fw={400}>
              {items_count === 1 ? '1 item' : `${items_count} items`}
            </Badge>
          </OrderItemsPreviewTooltip>
        );
      },
    };

    const createdAtColumn: TableColumn<PartnerInvoiceType> = {
      dataField: 'created_at',
      text: 'Created at',
      sort: true,
      formatter: (_, { created_at }) => {
        const parsedCreatedAtDate = Date.parse(created_at);
        const createdAtTimeAgo = dayjs().to(dayjs(parsedCreatedAtDate));

        return (
          <>
            <DateCell date={created_at} withTimeAgo={false} />
            <Text size="xs" color="blue_gray.7">
              {createdAtTimeAgo}
            </Text>
          </>
        );
      },
      filter: {
        type: TableFilterTypeEnum.Date,
      },
    };

    const uncollectibleAtColumn: TableColumn<PartnerInvoiceType> = {
      dataField: 'uncollectible_at',
      text: 'Marked uncollectible on',
      minWidth: 300,
      sort: true,
      formatter: (_, { uncollectible_at }) => {
        if (!uncollectible_at) return null;

        return <DateCell date={uncollectible_at} withTimeAgo={false} />;
      },
      filter: {
        type: TableFilterTypeEnum.Date,
      },
    };

    const amountColumn: TableColumn<PartnerInvoiceType> = {
      dataField: 'amount_in_scu',
      text: 'Charged Amount',
      minWidth: 220,
      maxWidth: 220,
      sort: true,
      formatter: (_, { amount_in_scu, currency }) =>
        formatCurrency(amount_in_scu, currency),
    };

    const paymentMethodColumn: TableColumn<PartnerInvoiceType> = {
      dataField: 'payment_method',
      text: 'Payment Method',
      sort: true,
      filter: {
        type: TableFilterTypeEnum.Select,
        options: {
          credit_card: 'Credit Card',
          purchase_order: 'Purchase Order',
          lab: 'Lab',
        },
      },
      minWidth: 250,
      maxWidth: 250,
      formatter: (_, { payment_method }) =>
        getOrderPaymentMethodLabel(payment_method),
    };

    const paymentStatusColumn: TableColumn<PartnerInvoiceType> = {
      dataField: 'status',
      text: 'Status',
      sort: true,
      filter: {
        type: TableFilterTypeEnum.Select,
        options: {
          pending: 'Pending',
          paid: 'Paid',
          failed: 'Payment failed',
          uncollectible: 'Uncollectible',
          refunded: 'Payment Refunded',
        },
      },
      formatter: (_, { status }) => (
        <PartnerInvoicePaymentStatusBadge status={status} />
      ),
    };

    const noteColumn: TableColumn<PartnerInvoiceType> = {
      dataField: 'partner_note',
      text: 'Note',
      sort: false,
      filter: {
        type: TableFilterTypeEnum.Text,
      },
      readOnlyFormatter: (_, row) => (
        <Text
          size="xs"
          title={row.invoice.note}
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {row.invoice.note}
        </Text>
      ),
      formatter: (cell, row) => (
        <Group noWrap>
          <ActionIcon
            size="sm"
            onClick={suppressPropagation(() =>
              openModal<EditNoteModalProps['data']>('EditNoteModal', {
                note: row.invoice.note,
                onSubmit: (newNote) =>
                  updateInvoicePartnerNote.mutateAsync({
                    note: newNote,
                    invoiceId: row.id,
                  }),
              })
            )}
          >
            <Edit />
          </ActionIcon>

          <Text size="xs" title={row.invoice.note} truncate>
            {row.invoice.note}
          </Text>
        </Group>
      ),
    };

    const invoiceFileColumn: TableColumn<PartnerInvoiceType> = {
      dataField: 'invoice_file_url',
      text: 'Receipt file',
      formatter: (_, { id, invoice, order_id, status }) => {
        if (!invoice.invoice_file_url && status === 'paid') {
          return (
            <ActionIcon
              w={28}
              h={37}
              className={classes.uploadFile}
              onClick={suppressPropagation(() =>
                openModal('UploadInvoice', {
                  invoiceId: id,
                })
              )}
            >
              <UploadFile />
            </ActionIcon>
          );
        }

        if (invoice.invoice_file_url) {
          return (
            <Box
              onClick={suppressPropagation(() =>
                downloadFile(invoice.invoice_file_url, `invoice_${order_id}`)
              )}
            >
              <FileTypeIcon fileUrl={invoice.invoice_file_url} width={28} />
            </Box>
          );
        }

        return null;
      },
    };

    if (status === 'all') {
      return [
        idColumn,
        customerColumn,
        itemsColumn,
        paymentStatusColumn,
        createdAtColumn,
        amountColumn,
        paymentMethodColumn,
        noteColumn,
        invoiceFileColumn,
      ];
    } else if (status === 'failed') {
      return [
        idColumn,
        customerColumn,
        itemsColumn,
        createdAtColumn,
        amountColumn,
        paymentMethodColumn,
        noteColumn,
        invoiceFileColumn,
      ];
    } else if (status === 'uncollectible') {
      return [
        idColumn,
        customerColumn,
        itemsColumn,
        createdAtColumn,
        uncollectibleAtColumn,
        amountColumn,
        paymentMethodColumn,
        noteColumn,
        invoiceFileColumn,
      ];
    } else {
      return [
        idColumn,
        customerColumn,
        itemsColumn,
        createdAtColumn,
        amountColumn,
        paymentMethodColumn,
        noteColumn,
        invoiceFileColumn,
      ];
    }
  }, [classes.uploadFile, openModal, status, updateInvoicePartnerNote]);

  return (
    <>
      <Text color="gray.7" size="sm">
        {subtitle}
      </Text>
      <PaginatedTable<PartnerInvoiceType>
        readOnly={!canEdit('finance')}
        keyField="id"
        name="partners.invoicing"
        dataHook={useInvoicesTableData}
        dataHookUrl={buildDataHookUrl(status)}
        columns={tableColumns}
        noDataIndication={{ title: 'No invoices' }}
        defaultSortBy={[{ id: 'created_at', desc: true }]}
        detailsPanel={{
          type: 'page',
          width: 400,
          renderer: ({ row, onClose }) => (
            <InvoiceDetailsPanel invoice={row.original} onClose={onClose} />
          ),
        }}
      />
    </>
  );
}

function buildDataHookUrl(status: OrderPaymentStatusType | 'all') {
  if (status === 'all') return INVOICES_API_URL;

  const queryString = `q[status_eq]=${status}`;

  return `${INVOICES_API_URL}?${queryString}`;
}

const useStyles = createStyles(() => ({
  uploadFile: {
    border: 'none',

    svg: {
      width: 28,
      height: 37,
    },
  },
}));
