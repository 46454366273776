import React from 'react';
import { useParams } from 'react-router-dom';

import { RoutePanel } from '@portals/framework/route-panels';

import { SubscriptionDetailsPanel } from '../../pages/store-management/pages/subscriptions/SubscriptionDetailsPanel';

export function SubscriptionRoutePanel() {
  const params = useParams<{ subscription_id: string }>();

  return (
    <RoutePanel panelId="subscription">
      {({ onClose }) => (
        <SubscriptionDetailsPanel
          subscriptionId={params.subscription_id}
          onClose={onClose}
        />
      )}
    </RoutePanel>
  );
}
