import { Tabs, TabsProps, Tooltip } from '@mantine/core';
import React from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as GlobalIcon } from '@portals/icons/bold/global.svg';
import { ReactComponent as ShopIcon } from '@portals/icons/bulk/shop.svg';
import { ReactComponent as SmsIcon } from '@portals/icons/bulk/sms.svg';

import { BrandingType } from './branding.types';
import { CustomerPortalSidebar } from './customer-portal-branding/CustomerPortalSidebar';
import { EmailSidebar } from './email-branding/EmailSidebar';
import { StoreSidebar } from './store-branding/StoreSidebar';
import { DASHBOARD_PATHS } from '../../routes/dashboard/dashboard-paths.constants';

export function BrandingSidebar() {
  const navigate = useNavigate();
  const params = useParams<{ type: BrandingType }>();

  return (
    <Tabs
      keepMounted={false}
      variant="outline"
      radius={0}
      styles={tabsStyles}
      value={params.type}
      defaultValue={BrandingType.CustomerPortal}
      onTabChange={(tab) =>
        navigate(
          generatePath(DASHBOARD_PATHS.staticPaths.branding, {
            type: tab,
            '*': null,
          })
        )
      }
    >
      <Tabs.List grow>
        <Tooltip label="Customer portal">
          <Tabs.Tab value={BrandingType.CustomerPortal} icon={<GlobalIcon />} />
        </Tooltip>

        <Tooltip label="Store branding">
          <Tabs.Tab value={BrandingType.Store} icon={<ShopIcon />} />
        </Tooltip>

        <Tooltip label="Email branding">
          <Tabs.Tab value={BrandingType.Email} icon={<SmsIcon />} />
        </Tooltip>
      </Tabs.List>

      <Tabs.Panel value={BrandingType.CustomerPortal}>
        <CustomerPortalSidebar />
      </Tabs.Panel>

      <Tabs.Panel value={BrandingType.Store}>
        <StoreSidebar />
      </Tabs.Panel>

      <Tabs.Panel value={BrandingType.Email}>
        <EmailSidebar />
      </Tabs.Panel>
    </Tabs>
  );
}

const tabsStyles: TabsProps['styles'] = (theme) => ({
  root: {
    overflow: 'hidden',
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    borderRight: `2px solid ${theme.colors.gray[2]}`,
  },
  tabsList: {
    height: 56,
    borderBottomWidth: 2,
    borderColor: theme.colors.gray[2],
  },
  tab: {
    borderTop: 0,
    borderColor: theme.colors.gray[2],
    backgroundColor: theme.colors.gray[0],

    '&[data-active]': {
      borderColor: theme.colors.gray[2],
      backgroundColor: theme.white,

      '&:before': {
        height: 2,
        bottom: -2,
      },
    },

    '&:first-of-type': {
      borderLeft: 'none',
    },

    '&:last-of-type': {
      borderRight: 'none',
    },
  },
  panel: {
    overflow: 'hidden',
  },
});
