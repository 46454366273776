import { Box, createStyles, Stack, Text } from '@mantine/core';
import React from 'react';
import Joyride, { CallBackProps, Step } from 'react-joyride';

import { useConfig } from '@portals/api/partners';
import { useCurrentUser, useIsWelcomeVideosPlayed } from '@portals/api/ui';
import { TenantType } from '@portals/types';

import { StepTooltipWithoutTitle } from '../../components/StepTooltipWithoutTitle';

export function ChannelPartnerWelcomeVideosTour() {
  const { classes } = useStyles();
  const config = useConfig();
  const isWelcomeVideosPlayed = useIsWelcomeVideosPlayed();
  const currentUser = useCurrentUser();

  const steps: Array<Step> = [
    {
      target: 'body',
      content: (
        <Stack>
          <Box className={classes.videoWrapper}>
            <iframe
              title="welcome-video"
              width="100%"
              height="370"
              src="https://www.youtube.com/embed/1ySSfeQPn7c?&mute=1&autoplay=1"
              allowFullScreen
            />
          </Box>
          <Stack spacing="xs" align="center" px="xl">
            <Text data-testid="welcome-title" size="lg">
              Welcome to {config.data?.[TenantType.Partner].display_name}!
            </Text>
            <Text align="center">
              We’re happy you’re here. Discover the platform's full potential in
              just a few minutes. Watch this short video and unlock a world of
              productivity, collaboration, and success.
            </Text>
          </Stack>
        </Stack>
      ),
      disableBeacon: true,
      floaterProps: {
        disableAnimation: true,
      },
      placement: 'center',
    },
    {
      target: 'body',
      content: (
        <Stack>
          <Box className={classes.videoWrapper}>
            <iframe
              title="supporting-customers-video"
              width="100%"
              height="370"
              src="https://www.youtube.com/embed/IlQwNTS5qkw?&mute=1&autoplay=1"
              allowFullScreen
            />
          </Box>
          <Stack spacing="xs" align="center" px="xl">
            <Text data-testid="supporting-customers-title" size="lg">
              Supporting Customers
            </Text>
            <Text align="center">
              Watch this short video for a quick overview of how to start
              supporting your customers through the platform.
            </Text>
          </Stack>
        </Stack>
      ),
      disableBeacon: true,
      floaterProps: {
        disableAnimation: true,
      },
      placement: 'center',
    },
  ];

  const onTransitionCallback = (data: CallBackProps) => {
    const { status, lifecycle } = data;

    if (status === 'finished' && lifecycle === 'complete') {
      isWelcomeVideosPlayed.mutate({ user: currentUser.data });
    }
  };

  return (
    <Joyride
      steps={steps}
      continuous
      disableOverlayClose
      callback={onTransitionCallback}
      tooltipComponent={(props) => (
        <StepTooltipWithoutTitle
          nextButtonText="Next video"
          onSkip={() =>
            isWelcomeVideosPlayed.mutate({ user: currentUser.data })
          }
          {...props}
        />
      )}
    />
  );
}

const useStyles = createStyles((theme) => ({
  videoWrapper: {
    iframe: {
      border: 'none',
    },
  },
}));
