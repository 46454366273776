import { Box, createStyles } from '@mantine/core';
import React, { useMemo } from 'react';

import { TenantType } from '@portals/types';

import { AuthenticationGuard } from '../components/AuthenticationGuard';
import { GlobalSubscription } from '../components/GlobalSubscription';
import { Navbar } from '../components/layout/navbar/Navbar';
import { OrganizationsSidebar } from '../components/layout/organizations-sidebar/OrganizationsSidebar';
import { Main } from '../components/Main';
import Onboarded from '../components/Onboarded';
import { usePermissionAccess } from '../components/permission-access/use-permission-access';
import { QuickNavProvider } from '../components/QuickNav';
import { useAppConfig } from '../context';
import { useHasSupportSeat } from '../hooks/support-seats';
import { Page404 } from '../pages/auth/Page404';

interface DashboardProps {
  children: React.ReactNode;
}

// Leftovers of the original layout that was used in both Parnters & Organizations.
// As of now, used in Orgs only, and will be deprecated after Orgs goes through planned routing
// refactor
export function DashboardLayout({ children }: DashboardProps) {
  const { classes, cx } = useStyles();
  const { extraLayout = {} } = useAppConfig();
  const hasSupportSeat = useHasSupportSeat();
  const { isMissingAllPermissions, isSuperAdmin } = usePermissionAccess();

  const {
    dashboardDataLayout: DataLayout,
    globalLayout,
    options = {},
  } = extraLayout;

  const hasNoPermissions =
    isMissingAllPermissions && !isSuperAdmin && !hasSupportSeat;

  const content = useMemo(
    () => (
      <>
        {options.isMobile ? null : <OrganizationsSidebar />}

        <Main>
          <Navbar />

          <div
            id="page-content"
            className={cx(classes.content, 'page-content')}
          >
            {hasNoPermissions ? (
              <Page404
                hideActionButton
                hideImage
                title="Missing permissions"
                description="We're sorry, it looks like you don't have the necessary permissions to access this page. Please reach out to your administrator for assistance."
              />
            ) : (
              children
            )}
          </div>

          {globalLayout ? globalLayout() : null}

          <GlobalSubscription tenantType={TenantType.Organization} />
        </Main>
      </>
    ),
    [
      children,
      classes.content,
      cx,
      globalLayout,
      hasNoPermissions,
      options.isMobile,
    ]
  );

  return (
    <AuthenticationGuard>
      <QuickNavProvider>
        <Box className={cx(classes.wrapper, 'page-wrapper')}>
          {DataLayout ? (
            <DataLayout>
              <Onboarded>{content}</Onboarded>
            </DataLayout>
          ) : (
            <Onboarded>{content}</Onboarded>
          )}
        </Box>
      </QuickNavProvider>
    </AuthenticationGuard>
  );
}

const useStyles = createStyles(() => ({
  wrapper: {
    height: '100vh',
    overflow: 'hidden',
    display: 'grid',
    gridTemplateColumns: `100px 1fr`,
  },
  content: {
    flex: 1,
    overflow: 'auto',
    position: 'relative',
  },
}));
