import { Box, Group, Text } from '@mantine/core';
import lottie, { AnimationItem } from 'lottie-web';
import React, { useRef } from 'react';

import * as animation from './loader-animation.json';

export function LoadingInsight() {
  const animationRef = useRef<AnimationItem>();

  const onRef = (node: HTMLDivElement) => {
    if (!node || !!animationRef.current) return;

    animationRef.current = lottie.loadAnimation({
      container: node,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animation,
    });
  };

  return (
    <Group w="100%">
      <Box ref={onRef} w={27} h={27} />
      <Text color="gray.7">Diagnosing...</Text>
    </Group>
  );
}
