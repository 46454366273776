import { Group, Stack, Tabs, TabsProps } from '@mantine/core';
import React, { useEffect } from 'react';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';

import { TicketsTabKeyEnum } from './tickets.types';
import { TicketsList } from './TicketsList';

export function TicketsListWrapper() {
  const navigate = useNavigate();
  const location = useLocation();

  const isAll = !!useMatch('/tickets/all');
  const isActive = !!useMatch('/tickets/active');

  const currTabKey = isAll ? TicketsTabKeyEnum.All : TicketsTabKeyEnum.Active;

  useEffect(() => {
    if (!isActive && !isAll) {
      navigate(
        { pathname: '/tickets/active', search: location.search },
        { replace: true }
      );
    }
  }, [isActive, isAll, location.search, navigate]);

  const onTabChange: TabsProps['onTabChange'] = (tabId) => {
    navigate(`/tickets/${tabId}`);
  };

  return (
    <Stack>
      <Group>
        <Tabs keepMounted={false} value={currTabKey} onTabChange={onTabChange}>
          <Tabs.List grow>
            <Tabs.Tab
              key={TicketsTabKeyEnum.Active}
              value={TicketsTabKeyEnum.Active}
              px={35}
            >
              Active Only
            </Tabs.Tab>

            <Tabs.Tab
              key={TicketsTabKeyEnum.All}
              value={TicketsTabKeyEnum.All}
              px={35}
            >
              All Tickets
            </Tabs.Tab>
          </Tabs.List>
        </Tabs>
      </Group>

      <TicketsList
        type={currTabKey}
        defaultFilters={
          isActive
            ? '?q[status_in][]=open&q[status_in][]=replied&q[status_in][]=review'
            : ''
        }
      />
    </Stack>
  );
}
