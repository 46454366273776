import { Descope, getSessionToken, useDescope } from '@descope/react-sdk';
import { Group, Stack, Title } from '@mantine/core';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Pendo } from '@portals/analytics';
import { useSignIn } from '@portals/api/auth';
import {
  descopeErrorTransformer,
  sendDescopeErrorReport,
} from '@portals/framework';
import { useAuth } from '@portals/redux';
import { toastrError } from '@portals/redux/actions/toastr';
import { TenantType } from '@portals/types';

export function Signin() {
  const navigate = useNavigate();

  const descope = useDescope();

  const auth = useAuth();
  const dispatch = useDispatch();

  const signIn = useSignIn();

  useEffect(
    function redirectedAuthenticatedUser() {
      if (!auth?.token) return;

      const path = localStorage.getItem('afterAuth') || '/';
      localStorage.removeItem('afterAuth');

      navigate(path, { replace: true });
    },
    [auth?.token, navigate]
  );

  useEffect(
    function redirectToChangePassword() {
      if (!auth?.change || !!auth?.token) return;

      navigate('/auth/change-password', { replace: true });
    },
    [auth?.change, auth?.token, navigate]
  );

  return (
    <Stack>
      <Group mb="lg" align="baseline" position="center">
        <Title order={1} className="auth-page-title" align="center">
          Sign-In
        </Title>
      </Group>

      <Descope
        flowId="sign-in-partner"
        onSuccess={async (e) => {
          const sessionToken = getSessionToken();

          try {
            const signInResponse = await signIn.mutateAsync({
              token: sessionToken,
              tenant_type: TenantType.Partner,
            });

            Pendo.track('Successful sign-in (v1)', {
              email: signInResponse?.email,
              tenantType: TenantType.Partner,
            });
          } catch (error) {
            await descope.logout();
            await descope.refresh();
            dispatch(
              toastrError(
                'Failed to sign in',
                error?.data?.error || error?.data
              )
            );
          } finally {
            navigate('/');
          }
        }}
        errorTransformer={descopeErrorTransformer}
        logger={{
          debug: (title: string, description?: string) => {
            return;
          },
          info: (title, description, state) => {
            sendDescopeErrorReport(state, 'Partners > SignIn');
          },
          warn: () => {
            return;
          },
          error: () => {
            return;
          },
        }}
      />
    </Stack>
  );
}
