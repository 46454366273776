import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Pendo } from '@portals/analytics';
import { useFeatureFlags } from '@portals/api/partners';
import { useAuth } from '@portals/redux';

export function useAnalytics() {
  const auth = useAuth();
  const featureFlags = useFeatureFlags();

  const location = useLocation();

  useEffect(
    function trackFeatureFlags() {
      if (featureFlags) {
        Pendo.track('featureFlags', featureFlags);
      }
    },
    [featureFlags]
  );

  useEffect(
    function configurePendo() {
      // Pendo.initialize() was called already
      if (Pendo.isReady()) {
        if (auth && Pendo.isAnonymousVisitor()) {
          // User is logged in, but Pendo visitor is still set to anonymous
          Pendo.identify({
            visitor: {
              id: auth.email,
              firstName: auth.name?.split(' ')?.[0],
            },
            account: {
              id: auth.tenant?.display_name,
              support_email: 'support@xyte.io',
            },
          });
        }
      } else {
        // Pendo.initialize() wasn't called yet
        if (auth) {
          // Pendo is not ready, initializing with authenticated visitor data
          Pendo.initializeAuthenticated({
            visitor: {
              id: auth.email,
              firstName: auth.name?.split(' ')?.[0],
            },
            account: {
              id: auth.tenant?.display_name,
              support_email: 'support@xyte.io',
            },
          });
        } else {
          // Do not initialize pendo on sign-in page
          if (location.pathname.includes('/auth/sign-in')) return;

          // Pendo is not ready, initializing with anonymous visitor data
          Pendo.initializeAnonymous();
        }
      }
    },
    [auth, location.pathname]
  );

  // useEffect(
  //   function trackPageViewPerPathname() {
  //     const userEmail = auth?.email;
  //
  //     const isSignInPage = location.pathname.includes('/auth/sign-in');
  //     const isAuthPage = ['/auth/sign-up', '/auth/reset-password'].some(
  //       (path) => location.pathname.includes(path)
  //     );
  //
  //     if (isSignInPage || (!userEmail && !isAuthPage)) return;
  //
  //     Pendo.trackPageView(location.pathname, userEmail);
  //   },
  //   [auth?.email, location.pathname]
  // );
}
