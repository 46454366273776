import { Stack } from '@mantine/core';
import React from 'react';

import { AccountDeletion } from './AccountDeletion';
import { Billing } from './Billing';
import { Features } from './Features';
import { SupportAgent } from './SupportAgent';
import { YourPlan } from './YourPlan';

export function PlanAndBilling() {
  return (
    <Stack spacing="xl" maw={760}>
      <YourPlan />

      <Billing />

      <Features />

      <SupportAgent />

      <AccountDeletion />
    </Stack>
  );
}
