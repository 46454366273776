import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import {
  DashboardContentLayout,
  RoutedTabs,
  RoutedTabsProps,
} from '@portals/framework';
import { useOpenModal } from '@portals/redux';

import { AllStoreListingsTable } from './store-listing-table/AllStoreListingsTable';
import { ArchivedStoreListingsTable } from './store-listing-table/ArchivedStoreListingsTable';
import { DraftStoreListingsTable } from './store-listing-table/DraftStoreListingsTable';
import { PublishedStoreListingsTable } from './store-listing-table/PublishedStoreListingsTable';
import { StoreListingsTableContextProvider } from './store-listing-table/StoreListingTableContextProvider';
import { StoreListingWizardModalProps } from '../../modals';

const ROUTES: RoutedTabsProps['routes'] = [
  {
    path: 'all',
    tabLabel: 'All Products',
    element: <AllStoreListingsTable />,
  },
  {
    path: 'published',
    tabLabel: 'Published',
    element: <PublishedStoreListingsTable />,
  },
  {
    path: 'draft',
    tabLabel: 'Draft',
    element: <DraftStoreListingsTable />,
  },
  {
    path: 'archived',
    tabLabel: 'Archived',
    element: <ArchivedStoreListingsTable />,
  },
];

export function StoreListingsPage() {
  const openModal = useOpenModal();
  const location = useLocation();

  useEffect(
    function openModalAccordingToSearchParams() {
      const searchParams = new URLSearchParams(location.search);

      const modalToOpen = searchParams.get('modal');

      if (!modalToOpen) return;

      if (modalToOpen === 'store-listing-wizard') {
        openModal<StoreListingWizardModalProps['data']>(
          'StoreListingWizardModal',
          {
            intent: 'create',
            storeListing: undefined,
          }
        );
      }

      searchParams.delete('modal');
    },
    [location.search, openModal]
  );

  return (
    <DashboardContentLayout pageTitle="Product Listings">
      <StoreListingsTableContextProvider>
        <RoutedTabs routes={ROUTES} basePath="/products" />
      </StoreListingsTableContextProvider>
    </DashboardContentLayout>
  );
}
