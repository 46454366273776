import { WebhookMetaType } from './webhooks.types';

export const WEBHOOKS: Array<WebhookMetaType> = [
  {
    event: 'claimed_device',
    title: 'Claimed Device Notifications',
    responseSample: `
{
  "event_type": "claimed_device",
  "secret": "SERVER_SECRET",
  "device_id": "DEVICE_ID",
  "device_model_id": "DEVICE_MODEL_ID",
  "device_model_name": "DEVICE_MODEL_NAME",
  "claiming_organization_name": "ORGANIZATION_NAME",
  "claiming_organization_email": "ORGANIZATION_EMAIL"
}
`,
  },
  {
    event: 'registered_device',
    title: 'Registered Device Notifications',
    responseSample: `
{
  "event_type": "registered_device",
  "secret": "SERVER_SECRET",
  "device_id": "DEVICE_ID",
  "device_sn": "DEVICE_SN",
  "device_mac": "DEVICE_MAC",
  "device_cloud_id": "DEVICE_CLOUD_ID",
  "device_name": "DEVICE_NAME",
  "device_model_id": "DEVICE_MODEL_ID",
  "device_model_name": "DEVICE_MODEL_NAME",
  "device_firmware_version": "DEVICE_FIRMWARE_VERSION",
  "claiming_organization_name": "ORGANIZATION_NAME",
  "claiming_organization_display_name": "ORGANIZATION_DISPLAY_NAME"
}
`,
  },
  {
    event: 'deleted_device',
    title: 'Deleted Device Notifications',
    responseSample: `
{
  "event_type": "deleted_device",
  "secret": "SERVER_SECRET",
  "device_id": "DEVICE_ID",
  "device_sn": "DEVICE_SN",
  "device_mac": "DEVICE_MAC",
  "device_cloud_id": "DEVICE_CLOUD_ID",
  "device_name": "DEVICE_NAME",
  "device_model_id": "DEVICE_MODEL_ID",
  "device_model_name": "DEVICE_MODEL_NAME",
  "device_firmware_version": "DEVICE_FIRMWARE_VERSION"
}
`,
  },
  {
    event: 'created_license',
    title: 'Created License Notifications',
    responseSample: `
{
  "event_type": "created_license",
  "secret": "SERVER_SECRET",
  "license_product_id": "LICENSE_PRODUCT_ID",
  "license_product_name": "LICENSE_PRODUCT_NAME",
  "device_model_id": "DEVICE_MODEL_ID",
  "device_model_name": "DEVICE_MODEL_NAME"
}
`,
  },
  {
    event: 'assigned_license',
    title: 'Assigned License Notifications',
    responseSample: `
{
  "event_type": "assigned_license",
  "secret": "SERVER_SECRET",
  "device_id": "DEVICE_ID",
  "device_sn": "DEVICE_SN",
  "device_mac": "DEVICE_MAC",
  "device_cloud_id": "DEVICE_CLOUD_ID",
  "device_name": "DEVICE_NAME",
  "device_model_id": "DEVICE_MODEL_ID",
  "device_model_name": "DEVICE_MODEL_NAME",
  "device_firmware_version": "DEVICE_FIRMWARE_VERSION",
  "license_product_id": "LICENSE_PRODUCT_ID",
  "license_product_name": "LICENSE_PRODUCT_NAME",
  "license_expires_at": "LICENSE_EXPIRES_AT"
}
`,
  },
  {
    event: 'revoked_license',
    title: 'Revoked License Notifications',
    responseSample: `
{
  "event_type": "revoked_license",
  "secret": "SERVER_SECRET",
  "device_id": "DEVICE_ID",
  "device_sn": "DEVICE_SN",
  "device_mac": "DEVICE_MAC",
  "device_cloud_id": "DEVICE_CLOUD_ID",
  "device_name": "DEVICE_NAME",
  "device_model_id": "DEVICE_MODEL_ID",
  "device_model_name": "DEVICE_MODEL_NAME",
  "device_firmware_version": "DEVICE_FIRMWARE_VERSION"
  "license_product_id": "LICENSE_PRODUCT_ID",
  "license_product_name": "LICENSE_PRODUCT_NAME",
  "license_expires_at": "LICENSE_EXPIRES_AT"
}
`,
  },
  {
    event: 'updated_license',
    title: 'Updated License Notifications',
    responseSample: `
{
  "event_type": "updated_license",
  "secret": "SERVER_SECRET",
  "device_id": "DEVICE_ID",
  "device_sn": "DEVICE_SN",
  "device_mac": "DEVICE_MAC",
  "device_cloud_id": "DEVICE_CLOUD_ID",
  "device_name": "DEVICE_NAME",
  "device_model_id": "DEVICE_MODEL_ID",
  "device_model_name": "DEVICE_MODEL_NAME",
  "device_firmware_version": "DEVICE_FIRMWARE_VERSION"
  "license_product_id": "LICENSE_PRODUCT_ID",
  "license_product_name": "LICENSE_PRODUCT_NAME",
  "license_state": "LICENSE_STATE",
  "license_expires_at": "LICENSE_EXPIRES_AT"
}
`,
  },
  {
    event: 'ticket_opened',
    title: 'Opened Ticket Notifications',
    responseSample: `
{
  "event_type": "ticket_opened",
  "ticket_id": "TICKET_ID",
  "organization_display_name": "ORGANIZATION_DISPLAY_NAME",
  "partner_display_name": "PARTNER_DISPLAY_NAME",
  "user_email": "USER_EMAIL",
  "user_name": "USER_NAME",
  "user_id": "USER_ID",
  "status": "STATUS",
  "title": "TITLE",
  "description": "DESCRIPTION",
  "space_name": "SPACE_NAME",
  "device_id": "DEVICE_ID",
  "device_name": "DEVICE_NAME",
  "device_sn": "DEVICE_SN",
  "device_mac": "DEVICE_MAC",
  "device_cloud_id": "DEVICE_CLOUD_ID",
  "device_model_name": "DEVICE_MODEL_NAME",
  "device_sub_model": "DEVICE_SUB_MODEL",
  "device_created_at": "DEVICE_CREATED_AT"
}

`,
  },
  {
    event: 'ticket_updated',
    title: 'Updated Ticket Notifications',
    responseSample: `
{
  "event_type": "ticket_updated",
  "ticket_id": "TICKET_ID",
  "organization_display_name": "ORGANIZATION_DISPLAY_NAME",
  "partner_display_name": "PARTNER_DISPLAY_NAME",
  "user_email": "USER_EMAIL",
  "user_name": "USER_NAME",
  "user_id": "USER_ID",
  "status": "STATUS",
  "title": "TITLE",
  "description": "DESCRIPTION",
  "device_id": "DEVICE_ID",
  "device_name": "DEVICE_NAME",
  "device_model_name": "DEVICE_MODEL_NAME",
  "device_sub_model": "DEVICE_SUB_MODEL",
  "device_created_at": "DEVICE_CREATED_AT"
}
`,
  },
  {
    event: 'ticket_closed',
    title: 'Closed Ticket Notifications',
    responseSample: `
{
  "event_type": "ticket_closed",
  "ticket_id": "TICKET_ID"
}
`,
  },
  {
    event: 'new_chat_message',
    title: 'New Chat Message Notifications',
    responseSample: `
{
  "event_type": "new_chat_message",
  "ticket_id": "TICKET_ID",
  "user_name": "USER_NAME",
  "text": "TEXT"
}
`,
  },
  {
    event: 'post_shipment',
    title: 'New Shipment With Post Purchase Params Notifications',
    responseSample: `
{
  "event_type": "post_shipment",
  "sn": "SN",
  "custom_parameters": "CUSTOM_PARAMETERS",
  "device_model_id": "DEVICE_MODEL_ID",
  "device_model_name": "DEVICE_MODEL_NAME",
  "product_id": "PRODUCT_ID",
  "product_name": "PRODUCT_NAME"
}
`,
  },
  {
    event: 'new_customer',
    title: 'New Customer Notifications',
    responseSample: `
{
  "event_type": "new_customer",
  "organization_display_name": "ORGANIZATION_DISPLAY_NAME",
  "organization_id": "ORGANIZATION_ID",
  "user_email": "USER_EMAIL",
  "user_name": "USER_NAME",
  "user_id": "USER_ID",
}
`,
  },
];
