import { Box, Button, Group, Paper, Stack, Text } from '@mantine/core';
import React from 'react';

import { usePermissionAccess } from '@portals/framework';
import { ReactComponent as ArrowRight1 } from '@portals/icons/linear/arrow-right-1.svg';
import { useOpenModal } from '@portals/redux';

import { EnabledCurrenciesList } from './enabled-currencies-list';

export const EnabledCurrencies = () => {
  const openModal = useOpenModal();
  const { isAdmin } = usePermissionAccess();

  return (
    <Paper p="xl" withBorder>
      <Stack spacing="xl">
        <Group position="apart">
          <Text color="gray.8">Regional Pricing</Text>

          {isAdmin ? (
            <Button variant="default" onClick={() => openModal('AddCurrency')}>
              Add Currency
            </Button>
          ) : null}
        </Group>

        <EnabledCurrenciesList />

        <Group
          position="apart"
          pb="xl"
          sx={{
            cursor: 'pointer',
          }}
          onClick={() => openModal('ProductsPricing')}
        >
          <Text size="sm" color="blue_gray">
            Show products pricing list
          </Text>

          <Box color="blue_gray.5">
            <ArrowRight1 width={15} height={15} />
          </Box>
        </Group>
      </Stack>
    </Paper>
  );
};
