import {
  Box,
  Button,
  Stack,
  Text,
  ModalProps as MantineModalProps,
} from '@mantine/core';
import { captureMessage } from '@sentry/react';
import lottie, { AnimationItem } from 'lottie-web';
import React, { useRef, useState } from 'react';

import {
  useCancelDeviceCommand,
  useFailedDeviceCommands,
  useOpenTunnel,
} from '@portals/api/organizations';
import { useCurrentUser } from '@portals/api/ui';
import { ModalCenteredMediaLayout, ModalFooter } from '@portals/core';
import { CommandType, SupportedCommandType } from '@portals/types';

import SSHTunnelError from '../assets/img/ssh-tunnel-error.svg';
import SSHTunnelOpened from '../assets/img/ssh-tunnel-opened.svg';
import animation from '../assets/tunnelAnimation.json';
import { ModalProps } from '../components/Modals';
import { useConfirmationModal } from '../hooks/modals';

export interface SSHTunnelLoadingModalProps
  extends ModalProps<{
    command: CommandType;
    deviceId: string;
    onSendCommand: (command: SupportedCommandType) => void;
    supportedCommand?: SupportedCommandType;
  }> {}

const POLLING_TIMEOUT_THRESHOLD = 8;

export function SSHTunnelLoadingModal({
  data,
  closeMe,
}: SSHTunnelLoadingModalProps) {
  const { command, deviceId, onSendCommand, supportedCommand } = data;

  const [openTunnelUrl, setOpenTunnelUrl] = useState<null | string>(null);

  const onOpenTunnel = (tunnelAuthenticateUrl: string) => {
    setOpenTunnelUrl(tunnelAuthenticateUrl);
  };

  const openTunnel = useOpenTunnel({
    tunnelStatusUrl: command.tunnel_status_url,
    tunnelAuthenticateUrl: command.tunnel_authenticate_url,
    onOpenTunnel,
  });

  const failedDevicesCommands = useFailedDeviceCommands(deviceId);

  const animationRef = useRef<AnimationItem>();

  const currentUser = useCurrentUser();

  const onRef = (node: HTMLDivElement) => {
    if (!node || !!animationRef.current) return;

    animationRef.current = lottie.loadAnimation({
      container: node,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animation,
    });
  };

  const failedCommand = failedDevicesCommands.data?.filter(
    (failedCommand) => command.id === failedCommand.id
  )[0];

  const cancelDeviceCommand = useCancelDeviceCommand();
  const asyncConfirmation = useConfirmationModal();

  const onClose = async () => {
    const isConfirm = await asyncConfirmation({
      description: 'Are you sure you want to cancel this command?',
    });

    if (isConfirm) {
      openTunnel.closeTunnel();

      cancelDeviceCommand.mutate(
        {
          deviceId: deviceId,
          commandId: command.id,
        },
        {
          onSettled: () => {
            captureMessage(
              `Command cancelled - SSH Tunnel Loading Modal by user ${currentUser.data?.email}`
            );

            closeMe();
          },
        }
      );
    }
  };

  if (
    failedCommand?.status === 'failed' ||
    failedCommand?.status === 'aborted' ||
    (failedCommand?.status === 'done' && !openTunnelUrl)
  ) {
    return (
      <ModalCenteredMediaLayout
        opened
        onClose={onClose}
        title="Connection failed"
        media={<img src={SSHTunnelError} />}
        mediaSize="auto"
        radius="lg"
        withCloseButton={false}
        styles={modalStyles}
      >
        <Stack>
          <Text align="center">
            We encountered an issue while trying to establish the tunnel.
            <br />
            Please try again or contact support if the problem persists.
          </Text>

          <ModalFooter position="center">
            <Button
              variant="default"
              onClick={onClose}
              data-testid="cancel-button"
              loading={cancelDeviceCommand.isLoading}
            >
              Cancel
            </Button>

            <Button
              onClick={async () => {
                await onClose();
                onSendCommand?.(supportedCommand);
              }}
              data-testid="retry-button"
              loading={cancelDeviceCommand.isLoading}
            >
              Retry
            </Button>
          </ModalFooter>
        </Stack>
      </ModalCenteredMediaLayout>
    );
  }

  if (openTunnelUrl) {
    return (
      <ModalCenteredMediaLayout
        opened
        onClose={onClose}
        title="Tunnel has been successfully established"
        media={<img src={SSHTunnelOpened} />}
        mediaSize="auto"
        radius="lg"
        withCloseButton={false}
        styles={modalStyles}
      >
        <Stack>
          <Text align="center">
            You can now proceed by clicking the button below
          </Text>

          <ModalFooter position="center">
            <Button
              onClick={() => {
                window.open(openTunnelUrl, '_blank', 'noopener noreferrer');
                closeMe();
              }}
              data-testid="open-tunnel-modal-button"
              loading={cancelDeviceCommand.isLoading}
            >
              Connect to device
            </Button>
          </ModalFooter>
        </Stack>
      </ModalCenteredMediaLayout>
    );
  }

  return (
    <ModalCenteredMediaLayout
      opened
      onClose={onClose}
      title={
        openTunnel.pollingIteration > POLLING_TIMEOUT_THRESHOLD
          ? 'Still waiting for the device to respond'
          : 'Establishing SSH Tunnel...'
      }
      media={<Box ref={onRef} />}
      mediaSize="auto"
      radius="lg"
      withCloseButton={false}
      styles={modalStyles}
    >
      <Stack>
        {openTunnel.pollingIteration > POLLING_TIMEOUT_THRESHOLD ? (
          <Text align="center">
            We're still waiting for the device to establish the SSH tunnel.
            <br />
            If this takes too long, please cancel and try again.
          </Text>
        ) : (
          <Text align="center">
            Please wait while we establish the SSH tunnel.
            <br />
            This may take a few seconds.
          </Text>
        )}

        <ModalFooter position="center">
          {openTunnel.pollingIteration > POLLING_TIMEOUT_THRESHOLD &&
            !openTunnelUrl && (
              <Button
                variant="default"
                onClick={onClose}
                data-testid="cancel-button"
                loading={cancelDeviceCommand.isLoading}
              >
                Cancel
              </Button>
            )}
        </ModalFooter>
      </Stack>
    </ModalCenteredMediaLayout>
  );
}

const modalStyles: MantineModalProps['styles'] = (theme) => ({
  header: {
    padding: `${theme.spacing.xxl} ${theme.spacing.xxl} ${theme.spacing.md} ${theme.spacing.xxl}`,
  },
});
