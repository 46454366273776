import { useMantineTheme } from '@mantine/core';
import React from 'react';
import { CardTitle } from 'reactstrap';
import styled from 'styled-components';

import { useConfig } from '@portals/api/partners';
import { ReactComponent as DocumentDownload } from '@portals/icons/linear/document-download.svg';
import { InfoTable } from '@portals/table';
import { TenantType } from '@portals/types';

function Provision() {
  const theme = useMantineTheme();

  const env = process.env.NX_ENV || 'development';
  const cert = env === 'staging' ? 'staging-ca.crt' : 'prod-ca.crt';
  const customSSLUrl = process.env.NX_DEVICE_HUB_URL_STATIC_CERT;
  const standardSSLUrl = process.env.NX_DEVICE_HUB_URL;

  const config = useConfig();
  const shortCode = config.data?.[TenantType.Partner]?.shortcode;

  return (
    <div>
      <CardTitle>
        <h4 className="text-muted">Provision</h4>
        <h5 className="text-muted">
          Devices provision data (SSL, target urls and manufacturer's short
          code)
        </h5>
      </CardTitle>
      <InfoTable.Table>
        <InfoTable.Row
          isHeader
          value={null}
          label={<b>Using custom SSL certificate</b>}
        />

        <InfoTable.Row
          value={
            <div className="d-flex align-items-center">
              <a href={cert} download className="btn btn-primary">
                <DocumentDownload
                  width={17}
                  height={17}
                  style={{ marginRight: theme.spacing.xs }}
                />
                Download certificate
              </a>
            </div>
          }
          label="Certificate"
        />

        <InfoTable.Row
          value={customSSLUrl}
          label="Target URL for device registration"
        />

        <InfoTable.Row
          isHeader
          value={null}
          label={<b>Using standard common CA authorities</b>}
        />

        <InfoTable.Row
          label="Target url for device registration"
          value={standardSSLUrl}
        />
      </InfoTable.Table>

      <ShortCodeTable>
        <InfoTable.Row label="Short code" value={shortCode} />
      </ShortCodeTable>
    </div>
  );
}

const ShortCodeTable = styled(InfoTable.Table)`
  margin-top: 41px;
`;

export default Provision;
