import {
  SpotlightAction,
  SpotlightProvider,
  SpotlightProviderProps,
} from '@mantine/spotlight';
import React, { ReactNode, useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { useSignOut } from '@portals/api/auth';
import { ReactComponent as BarcodeIcon } from '@portals/icons/linear/barcode.svg';
import { ReactComponent as BoxIcon } from '@portals/icons/linear/box.svg';
import { ReactComponent as BrushIcon } from '@portals/icons/linear/brush.svg';
import { ReactComponent as CalendarIcon } from '@portals/icons/linear/calendar.svg';
import { ReactComponent as CloudConnectionIcon } from '@portals/icons/linear/cloud-connection.svg';
import { ReactComponent as CodeIcon } from '@portals/icons/linear/code.svg';
import { ReactComponent as CpuIcon } from '@portals/icons/linear/cpu.svg';
import { ReactComponent as DangerIcon } from '@portals/icons/linear/danger.svg';
import { ReactComponent as DocumentTextIcon } from '@portals/icons/linear/document-text.svg';
import { ReactComponent as DollarCircleIcon } from '@portals/icons/linear/dollar-circle.svg';
import { ReactComponent as Element4Icon } from '@portals/icons/linear/element-4.svg';
import { ReactComponent as Logout1Icon } from '@portals/icons/linear/logout-1.svg';
import { ReactComponent as MessageQuestionIcon } from '@portals/icons/linear/message-question.svg';
import { ReactComponent as MonitorMobileIcon } from '@portals/icons/linear/monitor-mobbile.svg';
import { ReactComponent as PasswordCheckIcon } from '@portals/icons/linear/password-check.svg';
import { ReactComponent as Receipt1Icon } from '@portals/icons/linear/receipt-1.svg';
import { ReactComponent as SearchNormal2Icon } from '@portals/icons/linear/search-normal 2.svg';
import { ReactComponent as SecurityUserIcon } from '@portals/icons/linear/security-user.svg';
import { ReactComponent as Setting2Icon } from '@portals/icons/linear/setting-2.svg';
import { ReactComponent as SidebarLeftIcon } from '@portals/icons/linear/sidebar-left.svg';
import { ReactComponent as Tag2Icon } from '@portals/icons/linear/tag-2.svg';
import { ReactComponent as TaskSquareIcon } from '@portals/icons/linear/task-square.svg';
import { ReactComponent as TaskIcon } from '@portals/icons/linear/task.svg';
import { ReactComponent as TicketIcon } from '@portals/icons/linear/ticket.svg';
import { ReactComponent as TruckFastIcon } from '@portals/icons/linear/truck-fast.svg';
import { ReactComponent as UserTagIcon } from '@portals/icons/linear/user-tag.svg';
import { useOpenModal, useToggleSidebar } from '@portals/redux';

import { DASHBOARD_PATHS, StaticPath } from './dashboard-paths.constants';
import { useDashboardRoutes } from './DashboardRoutesProvider';
import { useTenantMenuItems } from '../../hubs/hubs-header/TenantMenu';
import { useSettingsPage } from '../../pages/settings/Settings';

type PathBasedSpotlightAction = Pick<
  SpotlightAction,
  'title' | 'group' | 'icon' | 'keywords'
> & {
  path: StaticPath;
};

const HOME_HUB_ACTIONS: Array<PathBasedSpotlightAction> = [
  {
    title: 'Get started',
    path: DASHBOARD_PATHS.staticPaths.getStarted,
    icon: <TaskIcon />,
  },
  {
    title: 'Overview',
    path: DASHBOARD_PATHS.staticPaths.dashboardOverview,
    icon: <Element4Icon />,
  },
  {
    title: 'Customers',
    path: DASHBOARD_PATHS.staticPaths.customers,
    icon: <UserTagIcon />,
  },
  {
    title: 'Partners',
    path: DASHBOARD_PATHS.staticPaths.partners,
    icon: <CloudConnectionIcon />,
  },
  {
    title: 'Settings',
    path: DASHBOARD_PATHS.staticPaths.settings,
    icon: <Setting2Icon />,
  },
].map((action) => ({ ...action, group: 'home', keywords: 'home' }));

const PRODUCT_HUB_ACTIONS: Array<PathBasedSpotlightAction> = [
  {
    title: 'Models',
    path: DASHBOARD_PATHS.staticPaths.deviceModels,
    icon: <BarcodeIcon />,
  },
  {
    title: 'Devices',
    path: DASHBOARD_PATHS.staticPaths.devices,
    icon: <MonitorMobileIcon />,
  },
  {
    title: 'Files',
    path: DASHBOARD_PATHS.staticPaths.files,
    icon: <CpuIcon />,
  },
  {
    title: 'Dev Center',
    path: DASHBOARD_PATHS.staticPaths.devCenter,
    icon: <CodeIcon />,
  },
].map((action) => ({ ...action, group: 'product', keywords: 'product' }));

const ECOMMERCE_HUB_ACTIONS: Array<PathBasedSpotlightAction> = [
  {
    title: 'Product Catalog',
    path: DASHBOARD_PATHS.staticPaths.productCatalog,
    icon: <BoxIcon />,
  },
  {
    title: 'Product Listings',
    path: DASHBOARD_PATHS.staticPaths.storeListings,
    icon: <Tag2Icon />,
  },
  {
    title: 'Orders',
    path: DASHBOARD_PATHS.staticPaths.orders,
    icon: <TaskSquareIcon />,
  },
  {
    title: 'Subscriptions',
    path: DASHBOARD_PATHS.staticPaths.subscriptions,
    icon: <CalendarIcon />,
  },
  {
    title: 'Sold Products',
    path: DASHBOARD_PATHS.staticPaths.soldProducts,
    icon: <BoxIcon />,
  },
].map((action) => ({ ...action, group: 'eCommerce', keywords: 'ecommerce' }));

const SUPPORT_HUB_ACTIONS: Array<PathBasedSpotlightAction> = [
  {
    title: 'Overview',
    path: DASHBOARD_PATHS.staticPaths.supportOverview,
    icon: <Element4Icon />,
  },
  {
    title: 'Tickets',
    path: DASHBOARD_PATHS.staticPaths.tickets,
    icon: <TicketIcon />,
  },
  {
    title: 'Incidents',
    path: DASHBOARD_PATHS.staticPaths.incidents,
    icon: <DangerIcon />,
  },
  {
    title: 'Supported Customers',
    path: DASHBOARD_PATHS.staticPaths.supportedCustomers,
    icon: <UserTagIcon />,
  },
].map((action) => ({ ...action, group: 'support', keywords: 'support' }));

const FINANCE_HUB_ACTIONS: Array<PathBasedSpotlightAction> = [
  {
    title: 'Credit Providers',
    path: DASHBOARD_PATHS.staticPaths.creditProviders,
    icon: <DollarCircleIcon />,
  },
  {
    title: 'Invoices',
    path: DASHBOARD_PATHS.staticPaths.invoices,
    icon: <Receipt1Icon />,
  },
  {
    title: 'Orders',
    path: DASHBOARD_PATHS.staticPaths.orders,
    icon: <TaskSquareIcon />,
  },
].map((action) => ({ ...action, group: 'finance', keywords: 'finance' }));

const OPERATIONS_HUB_ACTIONS: Array<PathBasedSpotlightAction> = [
  {
    title: 'Shipping',
    path: DASHBOARD_PATHS.staticPaths.shipping,
    icon: <TruckFastIcon />,
  },
].map((action) => ({
  ...action,
  group: 'operations',
  keywords: 'operations',
}));

const MARKETING_HUB_ACTIONS: Array<PathBasedSpotlightAction> = [
  {
    title: 'Branding',
    path: DASHBOARD_PATHS.staticPaths.branding,
    icon: <BrushIcon />,
  },
].map((action) => ({
  ...action,
  group: 'marketing',
  keywords: 'marketing',
}));

const pathBasedActions: Array<PathBasedSpotlightAction> = [
  ...HOME_HUB_ACTIONS,
  ...PRODUCT_HUB_ACTIONS,
  ...ECOMMERCE_HUB_ACTIONS,
  ...SUPPORT_HUB_ACTIONS,
  ...FINANCE_HUB_ACTIONS,
  ...OPERATIONS_HUB_ACTIONS,
  ...MARKETING_HUB_ACTIONS,
];

export function QuickNavigationProvider({ children }: { children: ReactNode }) {
  const openModal = useOpenModal();
  const navigate = useNavigate();
  const [, toggleSidebar] = useToggleSidebar();

  const { canAccessPath } = useDashboardRoutes();
  const settingsPage = useSettingsPage();
  const tenantMenuItems = useTenantMenuItems();

  // Avoid modifying the destructuring in this section! It's essential for maintaining a consistent reference.
  // The reference returned by `useMutation` isn't stable and will trigger infinite re-renders
  // if used as a dependency.
  const { mutate: signOut } = useSignOut();

  const defaultActions = useMemo(
    () =>
      [
        {
          title: 'Help',
          icon: <MessageQuestionIcon />,
          onTrigger: () => window.open('https://dev.xyte.io/', '_blank'),
        },
        {
          title: 'Change Password',
          icon: <PasswordCheckIcon />,
          onTrigger: () => openModal('ChangePasswordModal'),
        },
        {
          title: 'Terms of service',
          icon: <DocumentTextIcon />,
          onTrigger: () =>
            window.open('https://xyte.io/terms-of-service', '_blank'),
        },
        {
          title: 'Privacy',
          icon: <SecurityUserIcon />,
          onTrigger: () =>
            window.open('https://xyte.io/privacy-policy', '_blank'),
        },
        {
          title: 'Toggle Sidebar',
          icon: <SidebarLeftIcon />,
          onTrigger: () => toggleSidebar(),
        },
        {
          title: 'Sign out',
          icon: <Logout1Icon />,
          keywords: 'logout',
          onTrigger: () => signOut(),
        },
      ].map((action) => ({ ...action, group: 'other' })),
    [openModal, signOut, toggleSidebar]
  );

  const accessiblePathBasedActions = useMemo<Array<SpotlightAction>>(
    () =>
      pathBasedActions
        .filter((action) => canAccessPath(action.path))
        .map((action) => ({
          ...action,
          onTrigger: (action) => navigate(generatePath(action.path)),
        })),
    [canAccessPath, navigate]
  );

  const settingsPageActions = useMemo<Array<SpotlightAction>>(() => {
    if (!canAccessPath(DASHBOARD_PATHS.staticPaths.settings)) {
      return [];
    }

    return settingsPage.pages
      .filter((page) => page.isRouteBlocked !== true && page.visible !== false)
      .map((page) => ({
        group: 'settings',
        title: page.title,
        description: page.description,
        icon: page.icon,
        onTrigger: () =>
          navigate(generatePath(`${settingsPage.basePath}${page.path}`)),
      }));
  }, [canAccessPath, navigate, settingsPage.basePath, settingsPage.pages]);

  const tenantMenuActions = useMemo<Array<SpotlightAction>>(
    () =>
      tenantMenuItems.map((menuItem) => ({
        group: 'tenant',
        title: menuItem.label,
        icon: menuItem.icon,
        onTrigger: menuItem.onClick,
      })),
    [tenantMenuItems]
  );

  const allActions = useMemo(
    () => [
      ...accessiblePathBasedActions,
      ...settingsPageActions,
      ...tenantMenuActions,
      ...defaultActions,
    ],
    [
      accessiblePathBasedActions,
      defaultActions,
      settingsPageActions,
      tenantMenuActions,
    ]
  );

  return (
    <SpotlightProvider
      centered={false}
      highlightQuery
      radius="lg"
      searchPlaceholder="Search..."
      searchIcon={<SearchNormal2Icon />}
      searchInputProps={{ size: 'xl' }}
      limit={allActions.length}
      actions={allActions}
      styles={spotlightStyles}
    >
      {children}
    </SpotlightProvider>
  );
}

const spotlightStyles: SpotlightProviderProps['styles'] = (theme) => ({
  action: {
    borderRadius: theme.radius.md,
  },
  actionsGroup: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    paddingBottom: theme.spacing.xs,
  },
  actionHighlight: {
    mark: {
      padding: 0,
    },
  },
});
