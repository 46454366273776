import React from 'react';
import styled from 'styled-components';

import { IncidentsView } from '@portals/framework';

import { IncidentDetailsPanel } from '../../pages/incidents/IncidentDetailsPanel';

const Incidents = ({ device }) => (
  <Container className="p-3">
    <div className="mb-3 font-size-lg text-center">10 latest incidents</div>

    <IncidentsView
      tableName="partners.tickets.device.incidents"
      incidents={device.incidents}
      device={device}
      detailsPanelRenderer={({ row, onClose }) => (
        <IncidentDetailsPanel incident={row.original} onClosePanel={onClose} />
      )}
    />
  </Container>
);

const Container = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: max-content 1fr;
`;

export default Incidents;
