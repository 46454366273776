import { createStyles } from '@mantine/core';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { BrandingType } from './branding.types';
import { CustomerPortalPreview } from './customer-portal-branding/CustomerPortalPreview';
import { EmailPreview } from './email-branding/EmailPreview';
import { StorePreview } from './store-branding/StorePreview';

export function BrandingPreview() {
  const { classes } = useStyles();

  const params = useParams<{ type: BrandingType }>();

  const content = useMemo(() => {
    switch (params.type) {
      case BrandingType.CustomerPortal:
        return <CustomerPortalPreview />;
      case BrandingType.Store:
        return <StorePreview />;
      case BrandingType.Email:
        return <EmailPreview />;
    }
  }, [params.type]);

  return <div className={classes.container}>{content}</div>;
}
const useStyles = createStyles((theme) => ({
  container: {
    overflow: 'hidden',
    backgroundColor: theme.colors.gray[0],
  },
}));
