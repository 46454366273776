import React from 'react';

import { DesignImageInput } from '../../common/fields/ImageField';

export function TicketsForm() {
  return (
    <DesignImageInput
      valuePath="design.cec_tickets.logo"
      field={{
        title: 'Logo',
        name: 'logo',
        cropConfig: {
          aspectRatio: 1,
          width: 200,
          height: 200,
        },
        height: 200,
      }}
    />
  );
}
