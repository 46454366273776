import { Anchor, Box, LoadingOverlay } from '@mantine/core';
import { truncate } from 'lodash/fp';
import React from 'react';
import { Link } from 'react-router-dom';

import {
  DeviceModelType,
  useDeleteFileInfo,
  useDeviceModelFiles,
} from '@portals/api/partners';
import {
  ModalButton,
  PermissionAccess,
  useConfirmationModal,
  usePermissionAccess,
} from '@portals/framework';
import { ReactComponent as Book } from '@portals/icons/linear/book.svg';
import { ReactComponent as DocumentUpload } from '@portals/icons/linear/document-upload.svg';
import { ReactComponent as Edit } from '@portals/icons/linear/edit.svg';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import { useOpenModal } from '@portals/redux';
import { DateCell, RowMenuItems, SmartTable } from '@portals/table';
import {
  AccessLevelEnum,
  PartnerFileResponseType,
  TableColumn,
} from '@portals/types';

import { FileDetailsPanel } from '../../../components/Files/FileDetailsPanel';

interface ModelFilesProps {
  model: DeviceModelType;
}

const COLUMNS: Array<TableColumn<PartnerFileResponseType>> = [
  {
    dataField: 'name',
    text: 'Name',
    formatter: (_, { name, id }) => (
      <Anchor to={`/files/${id}`} component={Link}>
        {name}
      </Anchor>
    ),
  },
  {
    dataField: 'version',
    text: 'Version',
  },
  {
    dataField: 'desc',
    text: 'Description',
    formatter: (_, { desc }) => truncate({ length: 30 }, desc),
  },
  {
    dataField: 'file_type',
    text: 'Type',
  },
  {
    dataField: 'created_at',
    text: 'Created',
    formatter: (_, { created_at }) => <DateCell date={created_at} />,
  },
];

export function ModelFiles({ model }: ModelFilesProps) {
  const deviceModelFiles = useDeviceModelFiles(model.id);
  const asyncConfirmationCheck = useConfirmationModal();
  const openModal = useOpenModal();
  const deleteFileInfo = useDeleteFileInfo();
  const { canEdit } = usePermissionAccess();

  const deleteFile = async (file: PartnerFileResponseType) => {
    const isConfirmed = await asyncConfirmationCheck({
      description: `Are you sure you want to delete ${file.name}?`,
    });

    if (isConfirmed) {
      deleteFileInfo.mutate(file.id);
    }
  };

  return (
    <Box sx={{ height: '100%' }}>
      <LoadingOverlay visible={deviceModelFiles.isLoading} />

      <SmartTable<PartnerFileResponseType>
        readOnly={!canEdit('models')}
        name="partners.files"
        keyField="id"
        data={deviceModelFiles.data ?? []}
        columns={COLUMNS}
        noFilters
        exportParams={{ isEnabled: false }}
        noColumnsSelection
        isUrlSyncEnabled={false}
        additionalActions={() =>
          model ? (
            <PermissionAccess
              minAccessLevel={AccessLevelEnum.Edit}
              permissionKeys="models"
            >
              <ModalButton
                modalName="FileUploader"
                label="Upload File"
                data-testid="upload-file-button"
                data={{ modelId: model.id }}
                leftIcon={<DocumentUpload />}
              />
            </PermissionAccess>
          ) : null
        }
        detailsPanel={{
          type: 'page',
          renderer: ({ row, onClose }) => (
            <FileDetailsPanel file={row.original} onClose={onClose} />
          ),
        }}
        rowMenu={({ row, wrapperProps }) => (
          <RowMenuItems
            wrapperProps={wrapperProps}
            items={[
              {
                id: 'view',
                Icon: Book,
                label: 'View',
                onClick: () => {
                  wrapperProps.menuRef?.onClose();
                  window.open(
                    row.original.url,
                    '_blank',
                    'noopener noreferrer'
                  );
                },
              },
              {
                id: 'edit',
                label: 'Edit',
                Icon: Edit,
                onClick: () => {
                  wrapperProps.menuRef?.onClose();
                  openModal('FileUploader', {
                    modelId: row.original.device_model_id,
                    fileId: row.original.id,
                  });
                },
              },
              {
                id: 'delete',
                color: 'red',
                withTopDivider: true,
                Icon: Trash,
                label: 'Delete',
                onClick: () => {
                  wrapperProps.menuRef?.onClose();
                  deleteFile(row.original);
                },
              },
            ]}
          />
        )}
      />
    </Box>
  );
}
