import {
  Badge,
  Box,
  Button,
  createStyles,
  Group,
  LoadingOverlay,
  Stack,
  Text,
} from '@mantine/core';
import React from 'react';

import { OrderType, useUpdateOrderShipmentStatus } from '@portals/api/partners';
import { useGenericContext } from '@portals/framework';
import {
  PartnerOrderFulfillmentStatusBadge,
  PartnerOrderPaymentStatusBadge,
} from '@portals/framework/route-modals';
import { ReactComponent as DocumentUpload } from '@portals/icons/linear/document-upload.svg';
import { ReactComponent as Export } from '@portals/icons/linear/export-2.svg';
import { ReactComponent as Eye } from '@portals/icons/linear/eye.svg';
import { useOpenModal } from '@portals/redux';
import { prettyTimeMoment } from '@portals/utils';

import { OrderShipmentStatusChange } from './OrderShipmentStatusChange';
import { useUploadReceipt } from '../../hooks/Receipt/receipt.hooks';
import { ReviewPurchaseOrderModalProps } from '../../modals';

export function OrderHeader() {
  const { classes } = useStyles();
  const order = useGenericContext<OrderType>();
  const openModal = useOpenModal();
  const uploadReceiptFile = useUploadReceipt(order.invoice?.id);

  const updateOrderShipmentStatus = useUpdateOrderShipmentStatus();

  const isInactiveOrder = ['expired', 'archived', 'failed'].includes(
    order.payment_status
  );

  const onUpdateOrderShipmentStatus = async (
    newStatus: 'ready' | 'shipped' | 'delivered'
  ) => {
    try {
      await updateOrderShipmentStatus.mutateAsync({
        orderId: order.id,
        newStatus,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box className={classes.wrapper}>
      <LoadingOverlay visible={updateOrderShipmentStatus.isLoading} />

      <Box className={classes.container}>
        <Group align="center" position="apart" noWrap>
          <Stack spacing="xs">
            <Group align="center">
              <span>{order.short_id}</span>

              <PartnerOrderPaymentStatusBadge order={order} />

              {isInactiveOrder ? null : (
                <PartnerOrderFulfillmentStatusBadge order={order} />
              )}

              {order.tracking_number ? (
                <Badge
                  size="md"
                  color="gray"
                  variant="outline"
                  c="gray.9"
                  fw={300}
                  py="sm"
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    window.open(
                      `https://t.17track.net/en#nums=${order.tracking_number}`,
                      '_blank'
                    )
                  }
                >
                  <Group c="gray.7" spacing="xs">
                    Tracking No: {order.tracking_number}
                    <Export width={12} height={12} />
                  </Group>
                </Badge>
              ) : null}
            </Group>

            <Text size="sm" className={classes.date}>
              Placed on {prettyTimeMoment(order.created_at)}
            </Text>
          </Stack>

          <Group align="center">
            {isInactiveOrder ? null : (
              <OrderShipmentStatusChange
                order={order}
                onChange={onUpdateOrderShipmentStatus}
              />
            )}

            {order.payment_status === 'awaiting_po_approval' ? (
              <Button
                leftIcon={<Eye width={16} height={16} />}
                onClick={() =>
                  openModal<ReviewPurchaseOrderModalProps['data']>(
                    'ReviewPurchaseOrderModal',
                    {
                      orderId: order.id,
                    }
                  )
                }
              >
                Review Purchase Order
              </Button>
            ) : null}

            {order.payment_status === 'paid' && !order?.invoice?.file_url ? (
              <Button
                leftIcon={<DocumentUpload width={16} height={16} />}
                onClick={uploadReceiptFile}
              >
                Upload Receipt
              </Button>
            ) : null}
          </Group>
        </Group>
      </Box>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: 'grid',
    background: theme.white,
    width: '100%',
    gridTemplateColumns: '1fr',
    justifyItems: 'center',
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },

  container: {
    padding: `${theme.spacing.md} 30px`,
    fontSize: '28px',
    color: theme.colors.blue_gray[8],
    fontWeight: 500,
    maxWidth: 1600,
    width: '100%',
  },

  date: { color: theme.colors.gray[5], fontWeight: 300 },

  badge: {
    color: theme.colors.gray[9],
    fontWeight: 400,
  },

  badgeIndicator: {
    width: 8,
    height: 8,
    borderRadius: '50%',
  },
}));
