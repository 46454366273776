import { useMutation, useQueryClient } from '@tanstack/react-query';
import { find, findIndex, set, without } from 'lodash/fp';
import { useDispatch } from 'react-redux';

import {
  CommonWidgetFormType,
  DeviceWidgetNameType,
  NewWidgetConfigType,
  NewWidgetType,
  WidgetType,
} from '@portals/device-widgets';
import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';

import {
  DEVICE_MODELS_API_URL,
  deviceModelsQueryKeys,
  getDeviceModelApiUrl,
} from './device-models.constants';
import { useApiQuery } from '../../hooks';
import { ServerError } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';

function getApiUrl(modelId: string) {
  return `${getDeviceModelApiUrl(modelId)}/new_device_widgets`;
}

export function useNewDeviceModelWidgets<TFields extends CommonWidgetFormType>(
  modelId: string
) {
  return useApiQuery<Array<WidgetType<TFields, DeviceWidgetNameType>> | null>(
    getApiUrl(modelId),
    deviceModelsQueryKeys.widgets.new(modelId)
  );
}

interface CreateDeviceModelWidgetParams {
  name: string;
  config: NewWidgetConfigType;
  withNotification?: boolean;
}

export const useNewCreateDeviceModelWidget = (modelId: string) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: getApiUrl(modelId),
    method: 'POST',
  });

  return useMutation<NewWidgetType, ServerError, CreateDeviceModelWidgetParams>(
    {
      mutationFn: ({ name, config }) =>
        fetchApiRequest(url, {
          ...options,
          body: JSON.stringify({ name, config }),
        }),
      onSuccess: (data, { withNotification }) => {
        if (withNotification) {
          dispatch(toastrSuccess(`Widget ${data.name} created`));
        }

        const currentWidgets = queryClient.getQueryData<Array<WidgetType>>(
          deviceModelsQueryKeys.widgets.new(modelId)
        );

        const updatedWidgets = [...(currentWidgets || []), data];

        queryClient.setQueryData(
          deviceModelsQueryKeys.widgets.new(modelId),
          updatedWidgets
        );
      },
      onError: (error, { withNotification }) => {
        if (withNotification) {
          dispatch(toastrError(error.error));
        }
      },
      meta: {
        mutationName: 'useCreateDeviceModelWidget',
        baseUrl: `${DEVICE_MODELS_API_URL}/:id/new_device_widgets`,
        method: 'POST',
      },
    }
  );
};

interface NewUpdateDeviceModelWidgetParams<TForm = CommonWidgetFormType> {
  widget: NewWidgetType<TForm>;
  widgetId: string;
  withNotification?: boolean;
}

export function useNewUpdateDeviceModelWidget<TForm = CommonWidgetFormType>(
  modelId: string
) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: getApiUrl(modelId),
    method: 'PUT',
  });

  return useMutation<
    NewWidgetType<TForm>,
    ServerError,
    NewUpdateDeviceModelWidgetParams<TForm>
  >({
    mutationFn: ({ widget, widgetId }) =>
      fetchApiRequest(`${url}/${widgetId}`, {
        ...options,
        body: JSON.stringify(widget),
      }),
    onSuccess: (data, { withNotification }) => {
      queryClient.invalidateQueries(deviceModelsQueryKeys.widgets.new(modelId));

      if (withNotification) {
        dispatch(toastrSuccess(`Widget ${data.name} updated`));
      }

      const currentWidgets =
        queryClient.getQueryData<Array<WidgetType>>(
          deviceModelsQueryKeys.widgets.new(modelId)
        ) || [];
      const updatedWidgetIndex = findIndex({ id: data?.id }, currentWidgets);
      const updatedWidgets = set(updatedWidgetIndex, data, currentWidgets);

      queryClient.setQueryData(
        deviceModelsQueryKeys.widgets.new(modelId),
        updatedWidgets
      );
    },
    onError: ({ error }) => dispatch(toastrError(error)),
    meta: {
      mutationName: 'useUpdateDeviceModelWidget',
      baseUrl: `${DEVICE_MODELS_API_URL}/:id/new_device_widgets/:id`,
      method: 'PUT',
    },
  });
}

export const useNewUpdateDeviceModelWidgetsLayout = (modelId: string) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: `${getApiUrl(modelId)}/update_layout`,
    method: 'POST',
  });

  return useMutation<Array<NewWidgetType>, ServerError, Array<NewWidgetType>>({
    mutationFn: (widgetsToUpdate) =>
      fetchApiRequest(url, {
        ...options,
        body: JSON.stringify(widgetsToUpdate),
      }),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(deviceModelsQueryKeys.widgets.new(modelId));
    },
    onError: ({ error }) => dispatch(toastrError(error)),
    meta: {
      mutationName: 'useUpdateDeviceModelWidgetsLayout',
      baseUrl: `${DEVICE_MODELS_API_URL}/:id/new_device_widgets/update_layout`,
      method: 'POST',
    },
  });
};

export const useNewRemoveDeviceModelWidget = (modelId: string) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: getApiUrl(modelId),
    method: 'DELETE',
  });

  return useMutation<void, ServerError, string>({
    mutationFn: (widgetId) => fetchApiRequest(`${url}/${widgetId}`, options),
    onSuccess: (data, widgetId) => {
      dispatch(toastrSuccess(`Widget removed`));

      const currentWidgets =
        queryClient.getQueryData<Array<WidgetType>>(
          deviceModelsQueryKeys.widgets.new(modelId)
        ) || [];
      const removedWidget = find({ id: widgetId }, currentWidgets);
      const updatedWidgets = without([removedWidget], currentWidgets);

      queryClient.setQueryData(
        deviceModelsQueryKeys.widgets.new(modelId),
        updatedWidgets
      );
    },
    onError: ({ error }) => dispatch(toastrError(error)),
    meta: {
      mutationName: 'useRemoveDeviceModelWidget',
      baseUrl: `${DEVICE_MODELS_API_URL}/:id/new_device_widgets/:id`,
      method: 'DELETE',
    },
  });
};
