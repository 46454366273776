import { Anchor, Text } from '@mantine/core';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { TICKETS_API_URL, useTickets } from '@portals/api/partners';
import { TicketStatusBadge, useHasSupportSeat } from '@portals/framework';
import {
  AvatarCell,
  BooleanCell,
  DateCell,
  PaginatedTable,
} from '@portals/table';
import { TableColumn, TableFilterTypeEnum, TicketType } from '@portals/types';

import { TicketsTabKeyEnum } from './tickets.types';

interface TicketsListProps {
  type: TicketsTabKeyEnum;
  defaultFilters: string;
}

export function TicketsList({ type, defaultFilters }: TicketsListProps) {
  const hasSupportSeat = useHasSupportSeat();

  const columns = useMemo<TableColumn<TicketType>[]>(
    () => [
      {
        dataField: 'title',
        text: 'Title',
        sort: true,
        isSticky: true,
        filter: { type: TableFilterTypeEnum.Text },
        formatter: (cell, { id, title }) => (
          <Anchor to={`/tickets/${id}`} component={Link}>
            {title}
          </Anchor>
        ),
        readOnlyFormatter: (cell) => <Text>{cell.value}</Text>,
      },
      {
        dataField: 'description',
        text: 'Description',
        filter: { type: TableFilterTypeEnum.Text },
      },
      {
        dataField: 'partner_seen',
        text: 'Seen',
        sort: true,
        filter: {
          type: TableFilterTypeEnum.SingleSelect,
          options: { true: 'Seen', false: 'Not seen', all: 'All' },
          placeholder: 'Seen...',
        },
        formatter: (cell, { partner_seen }) => (
          <BooleanCell isActive={partner_seen} />
        ),
      },
      {
        dataField: 'organization_display_name',
        text: 'Organization',
        sort: true,
        filter: { type: TableFilterTypeEnum.Text },
        formatter: (
          _,
          { organization_display_name, organization_logo_url }
        ) => (
          <AvatarCell
            label={organization_display_name}
            src={organization_logo_url}
          />
        ),
      },
      {
        dataField: 'status',
        text: 'Status',
        sort: true,
        formatter: (_, { status }) => <TicketStatusBadge status={status} />,
        filter: {
          type: TableFilterTypeEnum.Select,
          options:
            type === TicketsTabKeyEnum.Active
              ? {
                  open: 'Open',
                  replied: 'Replied',
                  review: 'Review',
                }
              : {
                  open: 'Open',
                  replied: 'Replied',
                  review: 'Review',
                  resolved: 'Resolved',
                },
        },
      },
      {
        dataField: 'device_name',
        text: 'Device',
        sort: true,
        filter: { type: TableFilterTypeEnum.Text },
        formatter: (_, { device }) => device?.name,
      },
      {
        dataField: 'device_model',
        text: 'Device Model',
        sort: true,
        filter: { type: TableFilterTypeEnum.Text },
        formatter: (_, { device }) => device?.model,
      },
      {
        dataField: 'device_sn',
        text: 'Device Serial #',
        sort: true,
        filter: { type: TableFilterTypeEnum.Text },
        formatter: (_, { device }) => device?.sn,
      },
      {
        dataField: 'created_at',
        text: 'Created',
        sort: true,
        filter: { type: TableFilterTypeEnum.Date },
        formatter: (_, { created_at }) => <DateCell date={created_at} />,
      },
    ],
    [type]
  );

  return (
    <PaginatedTable<TicketType>
      key={type}
      readOnly={!hasSupportSeat}
      keyField="id"
      name={`partners.tickets.${type}`}
      dataHook={useTickets}
      dataHookUrl={`${TICKETS_API_URL}/${defaultFilters}`}
      columns={columns}
      noDataIndication={{ title: 'No Tickets' }}
      defaultSortBy={[{ id: 'created_at', desc: false }]}
    />
  );
}
