import { Button, Group, Stack, Text } from '@mantine/core';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { JsonEditor } from '@portals/ui';
import { IsJsonString } from '@portals/utils';

import Response from '../../components/Response';
import TestCommand from '../../components/TestCommand';
import { getApiDevice, sendTelemetry } from '../../redux/actions/api';

const TELEMETRY_BASE = `{
  "status": "online",
  "telemetries": {
    "fan_speed": 5.5,
    "temp": 30,
    "input": "hdmi"
  }
}`;

const SendTelemetry = ({ access, sendTelemetry }) => {
  const [telemetry, setTelemetry] = useState(TELEMETRY_BASE);
  const validTelemetry = IsJsonString(telemetry);

  return (
    <Stack data-testid="send-telemetry-stack">
      <JsonEditor onChange={setTelemetry} data={telemetry} />

      {!validTelemetry && <Text color="red">Invalid json</Text>}

      <Group>
        <Button
          disabled={!access.id || !validTelemetry}
          data-testid="send-telemetry-button"
          onClick={() => sendTelemetry(access.id, JSON.parse(telemetry))}
        >
          Send
        </Button>
        <span>
          Device ID:
          {access.id || 'Not set'}
        </span>
      </Group>
      {access.id && validTelemetry && (
        <TestCommand
          url={`/${access.id}/telemetry`}
          data={JSON.parse(telemetry)}
        />
      )}
      <Response type="sendTelemetry" />
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  access: getApiDevice(state),
});

export default connect(mapStateToProps, { sendTelemetry })(SendTelemetry);
