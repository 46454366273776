import { FC } from 'react';

import {
  BarControllerWidget,
  BarControllerWidgetProps,
  BarIndicatorWidget,
  BarIndicatorWidgetProps,
  ButtonWidget,
  ButtonWidgetProps,
  DeviceLocationWidget,
  DeviceLocationWidgetProps,
  DeviceWidgetNameType,
  GaugeIndicatorWidget,
  GaugeIndicatorWidgetProps,
  GroupedSwitchesWidget,
  GroupedSwitchesWidgetProps,
  NumericMetricWidget,
  NumericMetricWidgetProps,
  ScatterChartWidget,
  ScatterChartWidgetProps,
  SectionHeaderWidget,
  SectionHeaderWidgetProps,
  SplineChartWidget,
  SplineChartWidgetProps,
  StateControllerWidget,
  StateControllerWidgetProps,
  StatusWidget,
  StatusWidgetProps,
  ToggleWidget,
  ToggleWidgetProps,
  ValueWidget,
  ValueWidgetProps,
} from '@portals/device-widgets';

export function getDeviceWidgetRenderer(
  deviceWidgetName: DeviceWidgetNameType
) {
  switch (deviceWidgetName) {
    case 'status':
      return StatusWidget as FC<StatusWidgetProps>;

    case 'numeric_metric':
      return NumericMetricWidget as FC<NumericMetricWidgetProps>;

    case 'toggle':
      return ToggleWidget as FC<ToggleWidgetProps>;

    case 'button':
      return ButtonWidget as FC<ButtonWidgetProps>;

    case 'device_location':
      return DeviceLocationWidget as FC<DeviceLocationWidgetProps>;

    case 'bar_indicator':
      return BarIndicatorWidget as FC<BarIndicatorWidgetProps>;

    case 'gauge_indicator':
      return GaugeIndicatorWidget as FC<GaugeIndicatorWidgetProps>;

    case 'bar_controller':
      return BarControllerWidget as FC<BarControllerWidgetProps>;

    case 'grouped_switches':
      return GroupedSwitchesWidget as FC<GroupedSwitchesWidgetProps>;

    case 'section_header':
      return SectionHeaderWidget as FC<SectionHeaderWidgetProps>;

    case 'state_controller':
      return StateControllerWidget as FC<StateControllerWidgetProps>;

    case 'spline_chart':
      return SplineChartWidget as FC<SplineChartWidgetProps>;

    case 'value':
      return ValueWidget as FC<ValueWidgetProps>;

    case 'scatter_chart':
      return ScatterChartWidget as FC<ScatterChartWidgetProps>;

    default:
      return null;
  }
}
