import React from 'react';

import {
  DashboardContentLayout,
  RoutedTabs,
  RoutedTabsProps,
} from '@portals/framework';

import { OrdersTable } from './OrdersTable';

const routes: RoutedTabsProps['routes'] = [
  {
    path: 'all',
    tabLabel: 'All',
    element: <OrdersTable paymentStatus={undefined} />,
  },
  {
    path: 'pending',
    tabLabel: 'Pending',
    element: <OrdersTable paymentStatus="pending" />,
  },
  {
    path: 'paid',
    tabLabel: 'Paid',
    element: <OrdersTable paymentStatus="paid" />,
  },
  {
    path: 'archived',
    tabLabel: 'Archived',
    element: <OrdersTable paymentStatus="archived" />,
  },
  {
    path: 'expired',
    tabLabel: 'Expired',
    element: <OrdersTable paymentStatus="expired" />,
  },
  {
    path: 'failed',
    tabLabel: 'Failed',
    element: <OrdersTable paymentStatus="failed" />,
  },
  {
    path: 'refunded',
    tabLabel: 'Refunded',
    element: <OrdersTable paymentStatus="refunded" />,
  },
];

export function Orders() {
  return (
    <DashboardContentLayout bg="gray.0" pageTitle="Orders">
      <RoutedTabs basePath="/:hub/orders" routes={routes} />
    </DashboardContentLayout>
  );
}
