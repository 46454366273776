import { Button, createStyles, Group, Text } from '@mantine/core';
import React from 'react';

import { NameAbbreviationAvatar } from '@portals/core';

import { useRedirectToCustomerPortal } from '../../hooks/customers.hooks';

interface CustomerViewHeaderProps {
  customerName: string;
  isControlled: boolean;
  customerId: string;
  customerLogoUrl: string | null;
}

export function CustomerViewHeader({
  customerName,
  isControlled,
  customerId,
  customerLogoUrl,
}: CustomerViewHeaderProps) {
  const { classes } = useStyles();
  const redirectToCustomerPortal = useRedirectToCustomerPortal();

  return (
    <Group position="apart" mt={110}>
      <Group spacing="sm" align="end">
        <NameAbbreviationAvatar
          name={customerName}
          size={108}
          radius={76}
          src={customerLogoUrl}
        />
        <Text weight={600} sx={{ fontSize: 28 }}>
          {customerName}
        </Text>
      </Group>

      {isControlled && (
        <Button
          loading={redirectToCustomerPortal.isLoading}
          className={classes.visitPortalLink}
          onClick={() => redirectToCustomerPortal.redirect({ customerId })}
        >
          Visit Portal
        </Button>
      )}
    </Group>
  );
}

const useStyles = createStyles(() => ({
  visitPortalLink: {
    alignSelf: 'end',
  },
}));
