import { remove } from 'lodash/fp';
import React, { FC, useEffect, useState } from 'react';

import {
  PaginatedFilterTypeEnum,
  PaginatedQueryParamsType,
  TicketType,
} from '@portals/types';

import { ListWithActions } from './ListWithActions';
import { ItemType } from './TicketOrIncidentItem';

const INITIAL_STATE_PARAMS: PaginatedQueryParamsType<TicketType> = {
  sorting: [{ id: 'created_at', desc: false }],
  pagination: {
    page: 0,
    pageSize: 10,
  },
  filters: [
    {
      id: 'status',
      value: 'resolved',
      type: PaginatedFilterTypeEnum.NotEq,
    },
  ],
};

interface TicketsListProps {
  endpointUrl: string;
  itemsKey: string;
  viewAllLink?: string;
  closeModal?: () => void;
  organizationId?: string;
  isLabIncluded?: boolean;
  canViewDetails?: boolean;
}

export const TicketsList: FC<TicketsListProps> = ({
  endpointUrl,
  viewAllLink,
  itemsKey,
  closeModal,
  organizationId,
  isLabIncluded = false,
  canViewDetails = true,
}) => {
  const initialStateParams = organizationId
    ? {
        ...INITIAL_STATE_PARAMS,
        filters: [
          ...INITIAL_STATE_PARAMS.filters,
          {
            id: 'organization_id',
            value: organizationId,
            type: PaginatedFilterTypeEnum.Eq,
          },
        ],
      }
    : INITIAL_STATE_PARAMS;

  const [ticketsParams, setTicketsParams] = useState(initialStateParams);
  const [ticketsSortBy, setTicketsSortBy] = useState('created_at');

  useEffect(
    function onLabInclusionChange() {
      setTicketsParams((ticketsParams) => ({
        ...ticketsParams,
        filters: isLabIncluded
          ? remove({ id: 'organization_lab' }, ticketsParams.filters)
          : [
              ...ticketsParams.filters,
              {
                id: 'organization_lab',
                value: 'false',
                type: PaginatedFilterTypeEnum.Eq,
              },
            ],
      }));
    },
    [isLabIncluded]
  );

  const onSortByChanged = (sortBy: string) => {
    setTicketsSortBy(sortBy);

    setTicketsParams({
      ...ticketsParams,
      sorting: [{ id: sortBy, desc: false }],
    });
  };

  return (
    <ListWithActions
      showSortBySeverity={false}
      viewAllLink={viewAllLink}
      onSortByChanged={onSortByChanged}
      selectedItem={ticketsSortBy}
      itemsParams={ticketsParams}
      itemsKey={itemsKey}
      itemType={ItemType.Ticket}
      endpointUrl={endpointUrl}
      emptyStateLabel="No active tickets"
      closeModal={closeModal}
      canViewDetails={canViewDetails}
    />
  );
};
