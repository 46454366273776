import {
  createStyles,
  MantineProvider,
  Modal,
  useMantineTheme,
} from '@mantine/core';
import React, { useCallback } from 'react';

import {
  useNewUpdateDeviceModelWidget,
  useWidgetColors,
} from '@portals/api/partners';
import {
  CommonWidgetFormType,
  NewWidgetType,
  WIDGET_COLORS,
  WidgetColorType,
} from '@portals/device-widgets';
import { ModalProps } from '@portals/framework';

import { useUpdateCustomColors } from './device-widgets.hooks';
import { getDeviceWidgetMeta } from './devices-widgets.constants';

export interface EditDeviceWidgetModalProps<TForm = CommonWidgetFormType>
  extends ModalProps<{
    widget: NewWidgetType<TForm> & { modelId: string };
    modelId: string;
  }> {}

export function EditDeviceWidgetModal<TForm = CommonWidgetFormType>({
  closeMe,
  data,
}: EditDeviceWidgetModalProps<TForm>) {
  const { widget, modelId } = data;
  const { classes } = useStyles();

  const updateDeviceModelWidget = useNewUpdateDeviceModelWidget<TForm>(modelId);
  const updateCustomColors = useUpdateCustomColors();

  const theme = useMantineTheme();

  const colors = useWidgetColors();

  const widgetColors: WidgetColorType[] = WIDGET_COLORS.concat(
    Object.keys(colors)
  );

  const onSubmit = useCallback(
    async (values: CommonWidgetFormType) => {
      try {
        await updateDeviceModelWidget.mutate({
          widget: {
            name: values.name,
            config: {
              ...widget.config,
              fields: values as TForm,
            },
          },
          widgetId: widget.id,
          withNotification: true,
        });

        closeMe();
      } catch (error) {
        console.error(error);
      }
    },
    [closeMe, updateDeviceModelWidget, widget.config, widget.id]
  );

  const widgetMeta = getDeviceWidgetMeta(widget.config.id);

  if (!widgetMeta) {
    closeMe();

    return null;
  }

  return (
    <MantineProvider
      theme={{
        ...theme,
        colors: { ...theme.colors, ...colors },
        primaryShade: 4,
        primaryColor: 'blue_accent',
      }}
    >
      <Modal
        opened
        onClose={closeMe}
        size="1028px"
        padding={0}
        classNames={{
          content: classes.modalContent,
        }}
        title={null}
        withCloseButton={false}
      >
        {widgetMeta.widgetFormRenderer({
          onCancel: closeMe,
          onClose: closeMe,
          onSubmit,
          initialValues: widget.config.fields as any,
          modelId,
          colors: widgetColors,
          onAddCustomColor: updateCustomColors,
        })}
      </Modal>
    </MantineProvider>
  );
}

const useStyles = createStyles(() => ({
  modalContent: {
    paddingBottom: '0 !important',
    height: 790,
  },
}));
