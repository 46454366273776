import {
  Button,
  ButtonProps,
  createStyles,
  Group,
  LoadingOverlay,
} from '@mantine/core';
import { get } from 'lodash/fp';
import React from 'react';
import { Card } from 'reactstrap';

import {
  DeviceModelType,
  useDuplicateDeviceModel,
} from '@portals/api/partners';
import { DeviceModelIcon, PermissionAccess } from '@portals/framework';
import { ReactComponent as Duplicate } from '@portals/icons/linear/copy.svg';
import { ReactComponent as Edit } from '@portals/icons/linear/edit.svg';
import { ReactComponent as FolderOpen } from '@portals/icons/linear/folder-open.svg';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import { BooleanCell, InfoTable } from '@portals/table';
import { AccessLevelEnum } from '@portals/types';

import { AUTH_METHOD } from '../../../constants/auth';

type ModelBaseInfoProps = {
  model: DeviceModelType;
  onDuplicate: (model: DeviceModelType) => void;
  onEdit: (model: DeviceModelType) => void;
  onDelete: (model: DeviceModelType) => void;
  onPublish: (model: DeviceModelType) => void;
};

const MODEL_TYPE = {
  physical: 'Physical',
  digital: 'digital',
  application: 'Application',
};

export function ModelBaseInfo({
  model,
  onDuplicate,
  onEdit,
  onDelete,
  onPublish,
}: ModelBaseInfoProps) {
  const duplicateDeviceModel = useDuplicateDeviceModel();

  const { classes } = useStyles();

  return (
    <Card>
      <LoadingOverlay visible={duplicateDeviceModel.isLoading} />
      <PermissionAccess
        minAccessLevel={AccessLevelEnum.Edit}
        permissionKeys="models"
      >
        <Group position="right" p="sm" className={classes.container}>
          <Button
            color="red"
            disabled={model.devices_count !== 0}
            onClick={() => onDelete(model)}
            data-testid="delete-button"
            leftIcon={<Trash />}
            size="xs"
            styles={buttonStyles}
          >
            Delete
          </Button>

          <Button
            onClick={() => onEdit(model)}
            data-testid="edit-button"
            leftIcon={<Edit />}
            size="xs"
            styles={buttonStyles}
          >
            Edit
          </Button>

          {!model.active ? (
            <Button
              color="cyan"
              onClick={() => onPublish(model)}
              data-testid="publish-button"
              leftIcon={<FolderOpen />}
              size="xs"
              styles={buttonStyles}
            >
              Publish
            </Button>
          ) : null}

          <Button
            onClick={() => onDuplicate(model)}
            data-testid="duplicate-button"
            leftIcon={<Duplicate />}
            size="xs"
            styles={buttonStyles}
          >
            Duplicate
          </Button>
        </Group>
      </PermissionAccess>
      <InfoTable.Table>
        <InfoTable.Row label="Model Name" value={model.model} />
        <InfoTable.Row label="Model ID" value={model.id} />
        <InfoTable.Row label="SKU" value={model.sku} />
        <InfoTable.Row
          label="Icon"
          value={
            <DeviceModelIcon
              iconName={get('user_settings.icon', model)}
              size={16}
            />
          }
        />
        <InfoTable.Row
          label="Num. of Registered Devices"
          value={model.devices_count > 0 ? model.devices_count : 'None'}
        />
        <InfoTable.Row
          label="Published"
          value={<BooleanCell isActive={model.active} />}
        />

        <InfoTable.Row
          label="Type"
          value={MODEL_TYPE[model.device_type] || model.device_type || '--'}
        />
        <InfoTable.Row
          label="Authentication Method"
          value={AUTH_METHOD[model.auth_method] || model.auth_method || '--'}
        />
        <InfoTable.Row
          label="Communication Protocol"
          value={model.communication_protocol || '--'}
        />
        <InfoTable.Row
          label="Device API Version"
          value={model.current_api_version}
        />
        <InfoTable.Row
          label="Offline detection threshold"
          value={
            model.ttl_in_minutes ? (
              `${model.ttl_in_minutes} minutes`
            ) : (
              <BooleanCell isActive={false} label="Disabled" />
            )
          }
        />
      </InfoTable.Table>
    </Card>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    border: `1px solid ${theme.colors.gray[3]}`,
    borderBottom: 'none',
  },
}));

const buttonStyles: ButtonProps['styles'] = (theme) => ({
  leftIcon: {
    height: 16,
    width: 16,
  },
});
