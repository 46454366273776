import {
  Box,
  createStyles,
  LoadingOverlay,
  ModalProps,
  Stack,
  Text,
} from '@mantine/core';
import { find } from 'lodash/fp';
import React from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { useSupportCenterCustomers } from '@portals/api/partners';
import { useCurrentUser } from '@portals/api/ui';
import { useHasSupportSeat } from '@portals/framework';
import { RouteModal } from '@portals/framework/route-modals';
import { PaginatedFilterTypeEnum } from '@portals/types';

import { CustomerViewHeader } from './CustomerViewHeader';
import { ItemsCount } from './ItemsCount';
import { CustomerIncidentsMap } from '../../components/Maps/CustomerIncidentsMap';
import { IncidentsList } from '../../components/TicketsAndIncidentsList/IncidentsList';
import { TicketsList } from '../../components/TicketsAndIncidentsList/TicketsList';

const DEFAULT_COVER_IMAGE =
  'https://s3.eu-west-1.amazonaws.com/static.xyte.io/default_background.png';

export function Customer() {
  const params = useParams<{ customer_id?: string }>();

  const customers = useSupportCenterCustomers(true);
  const currentUser = useCurrentUser();
  const controlled = currentUser?.data?.accessible_tenants?.organizations;

  const customer = find({ id: params.customer_id }, customers?.data);
  const hasSupportSeat = useHasSupportSeat();

  const isOrganizationAccessible =
    find({ id: customer?.id }, controlled) !== undefined;

  const { classes: contentClasses } = useContentStyles();

  if (customers.isLoading || currentUser.isInitialLoading) {
    return <LoadingOverlay visible />;
  }

  if (!params?.customer_id || !customer) {
    return <Navigate to="/customers" replace />;
  }

  return (
    <RouteModal
      modalId="customer"
      styles={modalStyles}
      id="customer-route-modal"
      padding={0}
    >
      <Box className={contentClasses.container}>
        <CustomerViewHeader
          customerName={customer.name}
          isControlled={isOrganizationAccessible}
          customerId={customer.id}
          customerLogoUrl={customer.logo_url}
        />
        <Box className={contentClasses.mainView}>
          <Stack className={contentClasses.mainViewItem}>
            <ItemsCount title="Incidents" />
            <IncidentsList
              organizationId={customer.id}
              endpointUrl="/ui/partner/monitoring/incidents"
              itemsKey="customer-incidents"
              isLabIncluded
            />
          </Stack>

          <Stack spacing="xl" className={contentClasses.mainViewItem}>
            <Text
              size="lg"
              sx={(theme) => ({ color: theme.colors.blue_gray[6] })}
            >
              Map
            </Text>

            <CustomerIncidentsMap
              filters={[
                {
                  id: 'organization_id',
                  type: PaginatedFilterTypeEnum.Eq,
                  value: customer.id,
                },
                {
                  id: 'status',
                  type: PaginatedFilterTypeEnum.Eq,
                  value: 'active',
                },
              ]}
            />
          </Stack>

          <Stack className={contentClasses.mainViewItem}>
            <ItemsCount title="Tickets" />
            <TicketsList
              organizationId={customer.id}
              endpointUrl="/ui/partner/monitoring/tickets"
              itemsKey="customer-tickets"
              isLabIncluded
              canViewDetails={hasSupportSeat}
            />
          </Stack>
        </Box>
      </Box>
    </RouteModal>
  );
}

const modalStyles: ModalProps['styles'] = {
  inner: {
    padding: 30,
  },
  content: {
    width: '100%',
    height: '100%',
    backgroundImage: `url(${DEFAULT_COVER_IMAGE})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 184px',
  },
  body: {
    height: '100%',
    paddingBottom: 30,
  },
  close: {
    opacity: 0,
    pointerEvents: 'none',
  },
};

const useContentStyles = createStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateRows: 'max-content 1fr',
    gap: theme.spacing.md,
    height: '100%',
    padding: 30,

    [theme.fn.largerThan('xl')]: {
      gap: theme.spacing.xl,
    },
  },
  mainView: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: theme.spacing.md,

    [theme.fn.largerThan('xl')]: {
      gap: theme.spacing.xl,
    },
  },

  mainViewItem: {
    border: `1px solid ${theme.colors.gray[2]}`,
    borderRadius: theme.radius.md,
    padding: `${theme.spacing.xl} ${theme.spacing.xl} 0`,
    maxWidth: '100%',
  },
}));
