import { Button, Group, Stack } from '@mantine/core';
import React from 'react';
import { connect } from 'react-redux';

import Response from '../../components/Response';
import TestCommand from '../../components/TestCommand';
import { getDeviceInfo, getApiDevice } from '../../redux/actions/api';

const GetDevice = ({ access, getDeviceInfo }) => (
  <Stack>
    <Group>
      <Button onClick={() => getDeviceInfo(access.id)} disabled={!access.id}>
        Get
      </Button>
      <span>
        Device ID:
        {access.id || 'Not set'}
      </span>
    </Group>

    {access.id && <TestCommand url={`/${access.id}`} />}

    <Response type="getDeviceInfo" />
  </Stack>
);

const mapStateToProps = (state) => ({
  access: getApiDevice(state),
});

export default connect(mapStateToProps, { getDeviceInfo })(GetDevice);
