import {
  Box,
  createStyles,
  Group,
  LoadingOverlay,
  Stack,
  Tabs,
  TabsProps,
  Text,
  Title,
} from '@mantine/core';
import React from 'react';

import { StoreListing, useStoreSettings } from '@portals/api/partners';
import { DetailsPanel } from '@portals/core';
import { StoreListingStatusBadge } from '@portals/framework';

import { DetailsPanelActions } from './DetailsPanelActions';
import { OverviewTab } from './OverviewTab';
import { PaymentTab } from './PaymentTab';
import { StoreListingProductCard } from '../../../../components/store-listings/StoreListingProductCard';
import { mapStoreListingPricesToPaymentSettings } from '../../../../utils/store-listings.utils';

interface StoreListingsTableDetailsPanelProps {
  storeListing: StoreListing;
  onClose?: () => void;
  onArchive: (storeListing: StoreListing) => void;
  onDelete?: (storeListing: StoreListing) => void;
  onEdit?: (storeListing: StoreListing) => void;
  onPublish?: (storeListing: StoreListing) => void;
}

export function StoreListingsTableDetailsPanel({
  storeListing,
  onClose,
  onEdit,
  onPublish,
  onDelete,
  onArchive,
}: StoreListingsTableDetailsPanelProps) {
  const { classes } = useStyles();
  const storeSettings = useStoreSettings();

  const paymentSettings = mapStoreListingPricesToPaymentSettings({
    currencies: storeSettings.data?.currencies,
    storeListingPrices: storeListing.prices,
    defaultCurrency: storeSettings.data?.default_currency,
  });

  return (
    <Box className={classes.container}>
      <LoadingOverlay visible={storeSettings.isFetching} />

      <DetailsPanel enableScrollUiChanges={false}>
        <DetailsPanel.Header>
          <Stack>
            <Group position="apart" sx={{ overflow: 'hidden' }} noWrap>
              <Title
                order={5}
                weight={400}
                data-testid="store-listing-details-panel-product-name"
                truncate
              >
                {storeListing.product_name}
              </Title>
              <StoreListingStatusBadge
                status={storeListing.status}
                sx={{ overflow: 'unset' }}
              />
            </Group>
            {storeListing.product_subtitle ? (
              <Text data-testid="product-subtitle" color="gray.6">
                {storeListing.product_subtitle}
              </Text>
            ) : null}
          </Stack>

          <DetailsPanelActions
            storeListing={storeListing}
            onEdit={onEdit}
            onArchive={onArchive}
            onDelete={onDelete}
            onPublish={onPublish}
          />
        </DetailsPanel.Header>

        <Tabs defaultValue="overview" styles={tabsStyles} keepMounted={false}>
          <Tabs.List grow>
            <Tabs.Tab
              value="overview"
              data-testid="store-listing-details-panel-overview-tab"
            >
              Overview
            </Tabs.Tab>
            <Tabs.Tab
              value="payment"
              data-testid="store-listing-details-panel-payment-tab"
            >
              Payment
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="overview">
            <DetailsPanel.Body>
              <OverviewTab
                storeListing={storeListing}
                slug={storeListing.slug}
              />
            </DetailsPanel.Body>
          </Tabs.Panel>

          <Tabs.Panel value="payment">
            <DetailsPanel.Body pt={0}>
              <PaymentTab
                storeListing={storeListing}
                paymentSettings={paymentSettings}
              />
            </DetailsPanel.Body>
          </Tabs.Panel>
        </Tabs>
      </DetailsPanel>

      <DetailsPanel bg="gray.1">
        <DetailsPanel.Header onClose={onClose} />

        <DetailsPanel.Body justify="center" align="center">
          <StoreListingProductCard
            paymentSettings={paymentSettings}
            product={storeListing.product}
            productImageUrl={storeListing.product_image_url}
            productName={storeListing.product_name}
            productCategory={storeListing.product_category}
            productSubtitle={storeListing.product_subtitle}
          />
        </DetailsPanel.Body>
      </DetailsPanel>
    </Box>
  );
}

const useStyles = createStyles(() => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    height: '100%',
  },
}));

const tabsStyles: TabsProps['styles'] = () => ({
  root: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'max-content 1fr',
  },
  tabsList: {
    marginInline: 32,
  },
  panel: {
    height: '100%',
  },
});
