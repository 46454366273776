import { LoadingOverlay } from '@mantine/core';
import React from 'react';
import { useParams } from 'react-router-dom';

import { LegacyMonitoringDashboard } from './legacy-monitoring-dashboard/LegacyMonitoringDashboard';
import { useIsLegacyWidgets } from './monitoring-dashboard.hooks';
import { MonitoringDashboard } from './MonitoringDashboard';

export function MonitoringDashboardWrapper() {
  const { id } = useParams<{ id?: string }>();
  const { isLegacy, isLoading } = useIsLegacyWidgets(id);

  if (isLoading) return <LoadingOverlay visible={isLoading} />;

  return isLegacy ? <LegacyMonitoringDashboard /> : <MonitoringDashboard />;
}
