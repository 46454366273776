import { Text } from '@mantine/core';
import React from 'react';

import { EmptyState } from '@portals/core';
import { RoundDollarWithCursor } from '@portals/framework';

export function StoreListingPersonalizedPricingEmptyState() {
  return (
    <EmptyState
      p={32}
      media={<RoundDollarWithCursor />}
      messageTitle="Personalized pricing product"
      messageDescription={
        <Text maw={450}>
          This product is using personalized pricing in which customers will
          manually enter the price for a product or service.
        </Text>
      }
    />
  );
}
