import { Stack, TextInput } from '@mantine/core';
import { motion } from 'framer-motion';
import React from 'react';

import { ImageSelectorField } from '@portals/autoformik';

import { DomainEdit } from './DomainEdit';
import { useDesignField } from '../../common/fields/hooks';

export function PortalDomainForm() {
  const windowTitleField = useDesignField('window_title');
  const faviconField = useDesignField('favicon');

  return (
    <Stack spacing="xl">
      <motion.div layout="position">
        <DomainEdit />
      </motion.div>

      <motion.div layout="position">
        <TextInput
          label="Window title"
          placeholder="Xyte - Simply Connected"
          value={windowTitleField.value || ''}
          onChange={(e) => windowTitleField.onChange(e.target.value)}
        />
      </motion.div>

      <motion.div layout="position">
        <ImageSelectorField
          horizontal={false}
          value={faviconField.value || ''}
          setFieldValue={(_, value: string) => faviconField.onChange(value)}
          field={{
            title: 'Favicon',
            name: 'favicon',
            height: 200,
            cropConfig: {
              aspectRatio: 1,
              width: 96,
              height: 96,
            },
            previewConfig: {
              width: 96,
              height: 96,
            },
          }}
        />
      </motion.div>
    </Stack>
  );
}
