import { Descope } from '@descope/react-sdk';
import React from 'react';

import { sendDescopeErrorReport } from '../../utils/descope.utils';

export function ChangePassword() {
  return (
    <Descope
      flowId="change-password"
      onSuccess={async () => {
        window.open('/auth/sign-in');
      }}
      logger={{
        debug: (title: string, description?: string) => {
          return;
        },
        info: (title, description, state) => {
          sendDescopeErrorReport(state, 'Framework > ChangePassword');
        },
        warn: () => {
          return;
        },
        error: () => {
          return;
        },
      }}
    />
  );
}
