import { createStyles } from '@mantine/core';
import React from 'react';

import { DashboardContentLayout } from '@portals/framework';

import Stats from './Stats';

const Dashboard = () => {
  const { classes } = useStyles();

  return (
    <DashboardContentLayout
      pageTitle="Overview"
      bg="gray.0"
      data-testid="dashboard-title"
    >
      <div className={classes.container}>
        <Stats />
      </div>
    </DashboardContentLayout>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    display: 'grid',
    gridRowGap: theme.spacing.lg,
  },
}));

export default Dashboard;
