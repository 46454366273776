import {
  Button,
  Divider,
  Group,
  LoadingOverlay,
  MultiSelect,
  Stack,
  Switch,
  SwitchProps,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import React, { useMemo, useState } from 'react';
import * as Yup from 'yup';

import {
  useGroups,
  useIsChannelPartner,
  useRequestPlanUpgrade,
} from '@portals/api/partners';
import { useCreateUser } from '@portals/api/ui';
import {
  ModalBody,
  ModalCenteredMediaLayout,
  ModalFooter,
} from '@portals/core';
import { ModalProps } from '@portals/framework';
import { Members } from '@portals/framework/icons';
import { useOpenModal } from '@portals/redux';
import { suppressPropagation } from '@portals/utils';

import { AddSupportSeatsModalProps } from './AddSupportSeatsModal';
import { useNumOfAvailableSupportSeats } from '../pages/settings/support/support.hooks';

const schema = Yup.object().shape({
  email: Yup.string().required().email('Please enter a valid email address'),
});

interface FormValues {
  email: string;
  name: string | undefined;
  groupIds: string[] | undefined;
  addToLab: boolean | undefined;
  assignSupportSeat: boolean | undefined;
}

export function AddUserModal({ closeMe }: ModalProps) {
  const [isOpen, setIsOpen] = useState(true);

  const openModal = useOpenModal();

  const createUser = useCreateUser();
  const groups = useGroups();
  const requestPlanUpgrade = useRequestPlanUpgrade();

  const isChannelPartner = useIsChannelPartner();
  const numOfAvailableSeats = useNumOfAvailableSupportSeats();

  const groupOptions = composeGroupsOptions(groups.data);

  const form = useForm<FormValues>({
    validate: yupResolver(schema),
    initialValues: {
      email: '',
      name: '',
      groupIds: undefined,
      addToLab: true,
      assignSupportSeat: isChannelPartner,
    },
  });

  const onAddSeatsClick = () => {
    if (isChannelPartner) {
      setIsOpen(false);

      openModal<AddSupportSeatsModalProps['data']>('AddSupportSeatsModal', {
        onClose: () => setIsOpen(true),
      });
    } else {
      requestPlanUpgrade.mutate('Partners > Add user modal > Add Seats');
    }
  };

  const onSubmit = async (values: typeof form.values) => {
    try {
      await createUser.mutateAsync({
        name: values.name,
        email: values.email,
        groupIds: values.groupIds,
        addToLab: values.addToLab,
        assignSupportSeat: values.assignSupportSeat,
      });

      closeMe();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <ModalCenteredMediaLayout
      opened={isOpen}
      onClose={closeMe}
      title="Add a new user"
      media={<Members />}
    >
      <form onSubmit={form.onSubmit(onSubmit)} noValidate>
        <ModalBody>
          <LoadingOverlay visible={groups.isFetching} />

          <Stack spacing="xxl">
            <Stack>
              <TextInput
                required
                data-autofocus
                label="Email"
                placeholder="Email"
                data-testid="add-user-modal-email-input"
                {...form.getInputProps('email')}
              />

              <TextInput
                label="Name"
                placeholder="Name"
                data-testid="add-user-modal-name-input"
                {...form.getInputProps('name')}
              />

              <MultiSelect
                label="User groups"
                placeholder="Choose groups"
                data={groupOptions}
                data-testid="add-user-modal-groups-select"
                {...form.getInputProps('groupIds')}
              />
            </Stack>

            <Stack>
              <Switch
                labelPosition="left"
                label="Grant admin access to lab"
                description="Granting the user full access to configure and use an end-customer test tenant."
                styles={switchStyles}
                {...form.getInputProps('addToLab', { type: 'checkbox' })}
              />

              <Divider />

              <Switch
                disabled={!isChannelPartner && numOfAvailableSeats === 0}
                labelPosition="left"
                label={
                  <AssignSupportSeatSwitchLabel
                    numOfAvailableSeats={numOfAvailableSeats}
                    isChannelPartner={isChannelPartner}
                    onAddSeatsClick={onAddSeatsClick}
                  />
                }
                description="A support seat is required for the user to access, provide support and manage end customers' tenants."
                styles={switchStyles}
                {...form.getInputProps('assignSupportSeat', {
                  type: 'checkbox',
                })}
              />
            </Stack>

            {createUser.error && (
              <Text color="red" data-testid="add-user-modal-error-text">
                {createUser.error.error}
              </Text>
            )}
          </Stack>
        </ModalBody>

        <ModalFooter grow>
          <Button
            variant="default"
            data-testid="add-user-modal-cancel-button"
            onClick={closeMe}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            data-testid="add-user-modal-create-button"
            loading={createUser.isLoading}
          >
            Add user
          </Button>
        </ModalFooter>
      </form>
    </ModalCenteredMediaLayout>
  );
}

const switchStyles: SwitchProps['styles'] = (theme) => ({
  label: {
    color: theme.colors.gray[9],
  },
  description: {
    color: theme.colors.gray[5],
  },
  labelWrapper: {
    maxWidth: '90%',
  },
  body: {
    justifyContent: 'space-between',
  },
});

function composeGroupsOptions(groups: ReturnType<typeof useGroups>['data']) {
  if (!groups) return [];

  return groups.map((group) => ({
    value: group.id,
    label: group.name,
  }));
}

interface AssignSupportSeatSwitchLabelProps {
  isChannelPartner: boolean;
  numOfAvailableSeats: number;
  onAddSeatsClick: () => void;
}

function AssignSupportSeatSwitchLabel({
  numOfAvailableSeats,
  isChannelPartner,
  onAddSeatsClick,
}: AssignSupportSeatSwitchLabelProps) {
  const availableSeatsText = useMemo(() => {
    if (numOfAvailableSeats === 0) return '0 seats available';

    if (numOfAvailableSeats === 1) return '1 seat available';

    return `${numOfAvailableSeats} seats available`;
  }, [numOfAvailableSeats]);

  return (
    <Group spacing={0}>
      <Text>Assign a support seat -</Text>&nbsp;
      <Group onClick={suppressPropagation()}>
        <Text weight={700} color={numOfAvailableSeats === 0 ? 'red' : 'gray.5'}>
          {availableSeatsText}
        </Text>
        <Button variant="white" p={0} onClick={onAddSeatsClick}>
          <Text weight={700}>Add seats {isChannelPartner && '(free)'}</Text>
        </Button>
      </Group>
    </Group>
  );
}
