import React from 'react';

import { ModalButton, PermissionAccess } from '@portals/framework';
import { AccessLevelEnum } from '@portals/types';

export function RearrangeStoreButton() {
  return (
    <PermissionAccess
      minAccessLevel={AccessLevelEnum.Edit}
      permissionKeys="store_management"
    >
      <ModalButton
        modalName="StoreListingsSortingModal"
        data-testid="rearrange-store-button"
        label="Rearrange Store"
        variant="default"
        size="sm"
      />
    </PermissionAccess>
  );
}
