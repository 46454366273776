import { Anchor } from '@mantine/core';
import React from 'react';

import { PartnerInvoiceType } from '@portals/api/partners';
import { DetailsList, DetailsListProps } from '@portals/framework';

interface CustomerDetailsProps {
  invoiceOrganization: PartnerInvoiceType['organization'];
}

export function CustomerDetails({ invoiceOrganization }: CustomerDetailsProps) {
  const contactName = invoiceOrganization?.finance_contact_name
    ? invoiceOrganization?.finance_contact_name
    : invoiceOrganization?.admin_contact_name;
  const contactEmail = invoiceOrganization?.finance_contact_email
    ? invoiceOrganization?.finance_contact_email
    : invoiceOrganization?.admin_contact_email;

  const detailsList: DetailsListProps['items'] = [
    { label: 'Customer', value: invoiceOrganization?.display_name },
    { label: 'Finance Contact', value: contactName },
    {
      label: 'Email',
      value: <Anchor href={`mailto:${contactEmail}`}>{contactEmail}</Anchor>,
    },
  ];

  return <DetailsList items={detailsList} />;
}
