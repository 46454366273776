import { noop } from 'lodash/fp';
import React from 'react';

import {
  ConnectedPartner,
  connectedPartnersQueryKeys,
  useConnectedPartners,
  useResendCanceledPartnerConnection,
} from '@portals/api/partners';
import { CONNECTED_PARTNERS_API_URL } from '@portals/api/partners';
import { usePermissionAccess } from '@portals/framework';
import { ReactComponent as Send2 } from '@portals/icons/linear/send-2.svg';
import {
  AvatarCell,
  DateCell,
  PaginatedTable,
  RowMenuItems,
} from '@portals/table';
import {
  PaginatedFilterTypeEnum,
  TableColumn,
  TableFilterTypeEnum,
} from '@portals/types';

import { RelationshipTypeEnum } from '../../modals/InvitePartner/types';
import { InvitePartnerButton } from './InvitePartnerButton';

export function PartnersCancelledConnectionsTable() {
  const { canEdit } = usePermissionAccess();
  const resendInvitation = useResendCanceledPartnerConnection();

  const columns: TableColumn<ConnectedPartner>[] = [
    {
      dataField: 'invited_partner_display_name',
      filter: {
        type: TableFilterTypeEnum.Text,
      },
      sort: true,
      text: 'Name',
      formatter: (_, { logo, display_name }) => (
        <AvatarCell src={logo} label={display_name} />
      ),
    },
    {
      dataField: 'relationship_type',
      text: 'Type',
      sort: true,
      filter: {
        type: PaginatedFilterTypeEnum.Select,
        options: {
          [RelationshipTypeEnum.Manufacturer]:
            RelationshipTypeEnum.Manufacturer,
          [RelationshipTypeEnum.Integrator]: RelationshipTypeEnum.Integrator,
          [RelationshipTypeEnum.Distributor]: RelationshipTypeEnum.Distributor,
          [RelationshipTypeEnum.Reseller]: RelationshipTypeEnum.Reseller,
        },
      },
    },
    {
      dataField: 'created_at',
      text: 'Date sent',
      sort: true,
      filter: {
        type: PaginatedFilterTypeEnum.Date,
      },
      formatter: (_, { created_at }) => <DateCell date={created_at} />,
    },
  ];

  return (
    <PaginatedTable<ConnectedPartner>
      readOnly={!canEdit('customers')}
      keyField="id"
      name="partner-canceled-connections"
      onSelected={noop}
      columns={columns}
      dataHookQueryKey={connectedPartnersQueryKeys.canceled()}
      defaultSortBy={[{ id: 'created_at', desc: true }]}
      dataHookUrl={`${CONNECTED_PARTNERS_API_URL}/?q[status_eq]=canceled`}
      dataHook={useConnectedPartners}
      additionalActions={() => <InvitePartnerButton />}
      rowMenu={({ row, wrapperProps }) => {
        return (
          <RowMenuItems
            wrapperProps={wrapperProps}
            items={[
              {
                id: 'resend',
                Icon: Send2,
                label: 'Resend invitation',
                onClick: () => {
                  wrapperProps.menuRef?.onClose();
                  resendInvitation.mutate({
                    partner_id: row.original.partner_id,
                    relationship_type: row.original.relationship_type,
                  });
                },
              },
            ]}
          />
        );
      }}
    />
  );
}
