import { Group } from '@mantine/core';
import React from 'react';

import { PartnerIncidentType } from '@portals/api/partners';
import { DetailsPanel } from '@portals/core';
import {
  DetailsList,
  DetailsListProps,
  PriorityIcon,
} from '@portals/framework';
import { ReactComponent as Export } from '@portals/icons/linear/export-2.svg';
import { formatDateTime, timeAgo } from '@portals/utils';

import { RedirectToCustomerPortalButton } from '../../components/RedirectToCustomerPortalButton';
import { useRedirectToCustomerPortal } from '../../hooks/customers.hooks';

interface IncidentDetailsPanelProps {
  incident: PartnerIncidentType;
  onClosePanel: () => void;
}

export function IncidentDetailsPanel({
  incident,
  onClosePanel,
}: IncidentDetailsPanelProps) {
  const redirectToCustomerPortal = useRedirectToCustomerPortal();

  const detailsList: DetailsListProps['items'] = [
    { label: 'Space', value: incident.space_name },
    { label: 'Created', value: timeAgo(Date.parse(incident.created_at)) },
    {
      label: 'Last Updated',
      value: timeAgo(Date.parse(incident.updated_at)),
    },
    {
      label: 'ID',
      value: incident.id,
    },
  ];

  const deviceDetailsList: DetailsListProps['items'] = [
    {
      label: 'Device',
      value: incident.org_id ? (
        <RedirectToCustomerPortalButton
          customerId={incident.org_id}
          afterAuthRedirectPathname={`/m/device/${incident.device_id}`}
        >
          {incident.device_name}
        </RedirectToCustomerPortalButton>
      ) : (
        incident.device_name
      ),
    },
    {
      label: 'Vendor',
      value: 'TBD',
    },
    {
      label: 'Model',
      value: incident.device_model_name,
    },
    {
      label: 'Last seen',
      value: formatDateTime(incident.device_last_seen_at),
    },
    {
      label: 'Firmware version',
      value: incident.device_firmware_version,
    },
  ];

  return (
    <DetailsPanel>
      <DetailsPanel.Header
        onClose={onClosePanel}
        title={
          <Group spacing="xs">
            <PriorityIcon priorityLevel={incident.priority} iconSize={18} />
            <DetailsPanel.Title>{incident.title}</DetailsPanel.Title>
          </Group>
        }
      >
        <DetailsPanel.Actions>
          <DetailsPanel.ActionButton
            leftIcon={<Export />}
            onClick={() => {
              if (incident.org_id) {
                redirectToCustomerPortal.redirect({
                  customerId: incident.org_id,
                  afterAuthRedirectPathname: `/m/device/${incident.device_id}`,
                });
              }
            }}
          >
            Show Device
          </DetailsPanel.ActionButton>
        </DetailsPanel.Actions>
      </DetailsPanel.Header>

      <DetailsPanel.Body>
        <DetailsPanel.Section
          title="Description"
          content={incident.description || '--'}
        />

        <DetailsList title="Information" items={detailsList} />

        <DetailsList title="Device details" items={deviceDetailsList} />
      </DetailsPanel.Body>
    </DetailsPanel>
  );
}
