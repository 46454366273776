import { Card, createStyles, Divider, Group, Stack, Text } from '@mantine/core';
import React, { FC } from 'react';

import { CustomerWithControlledType } from '@portals/api/partners';
import { NameAbbreviationAvatar } from '@portals/core';
import { CustomerMenu } from '@portals/framework';
import { ReactComponent as Danger } from '@portals/icons/linear/danger.svg';
import { ReactComponent as MonitorMobbile } from '@portals/icons/linear/monitor-mobbile.svg';
import { ReactComponent as Ticket } from '@portals/icons/linear/ticket.svg';
import { TextWithIconCell } from '@portals/table';

interface CustomerTileViewProps {
  customer: CustomerWithControlledType;
}

export const CustomerTileView: FC<CustomerTileViewProps> = ({ customer }) => {
  const { classes, theme } = useStyles();

  return (
    <Card className={classes.container} withBorder radius="md">
      <Group position="apart" align="normal">
        <Stack>
          <NameAbbreviationAvatar
            name={customer.name}
            size={76}
            radius={37}
            src={customer.logo_url}
          />
          <Text
            size="lg"
            weight={600}
            sx={{ color: theme.colors.blue_gray[8] }}
          >
            {customer.name}
          </Text>
        </Stack>
        <CustomerMenu customer={customer} />
      </Group>
      <Divider sx={{ borderColor: theme.fn.rgba(theme.colors.gray[3], 0.8) }} />
      <Group>
        <TextWithIconCell
          Icon={MonitorMobbile}
          label={customer.devices_count.toString()}
          spacing="xs"
          color="blue_gray"
        />
        <TextWithIconCell
          Icon={Ticket}
          label={customer.tickets_count.toString()}
          spacing="xs"
          color="blue_gray"
        />
        <TextWithIconCell
          Icon={Danger}
          label={customer.incidents_count.toString()}
          spacing="xs"
          color="blue_gray"
        />
      </Group>
    </Card>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    height: 226,
    padding: `${theme.spacing.xl} ${theme.spacing.xl} ${theme.spacing.md} !important`,
    display: 'grid',
    gridTemplateRows: '1fr auto 16px',
    gap: theme.spacing.md,
  },
}));
