import { Anchor, createStyles, Text } from '@mantine/core';
import React, { FC } from 'react';

interface GuideItemProps {
  linkTo: string;
  title: string;
  subtitle: string;
  imageSrc: string;
  bgColor: string;
  hoverBgColor: string;
}

const useStyles = createStyles(
  (
    theme,
    {
      bgColor,
      hoverBgColor,
    }: {
      bgColor: GuideItemProps['bgColor'];
      hoverBgColor: GuideItemProps['hoverBgColor'];
    }
  ) => ({
    container: {
      overflow: 'hidden',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing.xl,
      borderRadius: theme.radius.md,
      fontWeight: 600,
      lineHeight: 1.34,
      backgroundColor: bgColor,
      transition: 'all 0.3s linear',
      textDecoration: 'none',

      img: {
        position: 'absolute',
        zIndex: 0,
        right: 0,
        bottom: 0,
        height: '100%',
        width: 'auto',
        transition: 'all 0.2s linear',
        transformOrigin: 'right bottom',
      },

      ':hover': {
        textDecoration: 'none',
        backgroundColor: hoverBgColor,

        img: {
          transform: 'scale(1.1)',
        },
      },
    },
    texts: {
      position: 'relative',
      zIndex: 1,
    },
    title: {
      fontSize: theme.fontSizes.sm,

      [theme.fn.largerThan('xl')]: {
        fontSize: theme.fontSizes.md,
      },
    },
    subtitle: {
      fontSize: theme.fontSizes.xs,
      color: theme.colors.blue_gray[5],

      [theme.fn.largerThan('xl')]: {
        fontSize: theme.fontSizes.sm,
        fontWeight: 400,
      },
    },
  })
);

export const StackedGuideItem: FC<GuideItemProps> = ({
  linkTo,
  title,
  subtitle,
  imageSrc,
  bgColor,
  hoverBgColor,
}) => {
  const { classes } = useStyles({ bgColor, hoverBgColor });
  return (
    <Anchor
      className={classes.container}
      href={linkTo}
      target="_blank"
      rel="noreferrer"
    >
      <div className={classes.texts}>
        <Text color="black" className={classes.title}>
          {title}
        </Text>
        <Text className={classes.subtitle}>{subtitle}</Text>
      </div>
      <img src={imageSrc} />
    </Anchor>
  );
};
