import {
  Button,
  createStyles,
  Divider,
  Group,
  Paper,
  Stack,
  Switch,
  Text,
} from '@mantine/core';
import { find } from 'lodash/fp';
import React from 'react';
import { useLocation } from 'react-router-dom';

import email from './assets/email.svg';
import googleChat from './assets/google-chat.svg';
import slack from './assets/slack.svg';
import { DashboardContentLayout } from '../../components/layout/DashboardContentLayout';
import { SERVICES } from '../../route-modals/modals/Integrations/integrations.constants';
import { useOpenRouteModal } from '../../route-modals/route-modals.hooks';

export function IntegrationsDemoPage() {
  const { classes } = useStyles();
  const openRouteModal = useOpenRouteModal();
  const location = useLocation();
  const selectedParam = new URLSearchParams(location.search).get('selected');

  const selectedService =
    selectedParam && find({ id: selectedParam }, SERVICES);

  return (
    <DashboardContentLayout pageTitle="Integrations" bg="gray.0">
      <Stack spacing="xl" maw={760}>
        <Paper p="xl" withBorder radius="md">
          <Stack spacing="xl">
            <Group position="apart">
              <Text color="gray.8">Integrations</Text>

              <Button
                variant="default"
                onClick={() => openRouteModal({ modalId: 'integrations' })}
              >
                Explore Integrations
              </Button>
            </Group>

            <Stack>
              <Group position="apart" py="md" align="center">
                <Group spacing="md">
                  <img src={slack} className={classes.serviceLogo} />

                  <Stack spacing={4}>
                    <Text className={classes.serviceName}>Slack</Text>

                    <Text className={classes.serviceDescription}>
                      Send notifications to individuals and channels using Slack
                      app
                    </Text>
                  </Stack>
                </Group>

                <Switch defaultChecked />
              </Group>

              <Divider />

              <Group position="apart" py="md" align="center">
                <Group spacing="md">
                  <img src={email} className={classes.serviceLogo} />

                  <Stack spacing={4}>
                    <Text className={classes.serviceName}>Email</Text>

                    <Text className={classes.serviceDescription}>
                      Send emails to individuals based on specific events
                    </Text>
                  </Stack>
                </Group>

                <Switch defaultChecked />
              </Group>

              <Divider />

              <Group position="apart" py="md" align="center">
                <Group spacing="md">
                  <img src={googleChat} className={classes.serviceLogo} />

                  <Stack spacing={4}>
                    <Text className={classes.serviceName}>Google Chat</Text>

                    <Text className={classes.serviceDescription}>
                      Send notifications to individuals and channels using
                      Google Chat
                    </Text>
                  </Stack>
                </Group>

                <Switch defaultChecked />
              </Group>

              {selectedService ? (
                <>
                  <Divider />

                  <Group position="apart" py="md" align="center" noWrap>
                    <Group spacing="md" noWrap>
                      <img
                        src={selectedService.logo}
                        className={classes.serviceLogo}
                      />

                      <Stack spacing={4}>
                        <Text className={classes.serviceName}>
                          {selectedService.name}
                        </Text>

                        <Text className={classes.serviceDescription}>
                          {selectedService.shortDescription}
                        </Text>
                      </Stack>
                    </Group>

                    <Switch defaultChecked />
                  </Group>
                </>
              ) : null}
            </Stack>
          </Stack>
        </Paper>
      </Stack>
    </DashboardContentLayout>
  );
}

const useStyles = createStyles((theme) => ({
  serviceLogo: {
    height: 33,
    width: 33,
    flexShrink: 0,

    img: {
      height: 33,
      width: 33,
    },
  },
  serviceName: {
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[9],
  },
  serviceDescription: {
    fontSize: theme.fontSizes.xs,
    color: theme.colors.gray[5],
  },
}));
