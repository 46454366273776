import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';
import {
  PaginatedFilterTypeEnum,
  PaginatedQueryFilterType,
  PaginationResponse,
  ProductPricingModel,
  UsePaginatedTableApiQuery,
} from '@portals/types';

import {
  STORE_LISTINGS_API_URL,
  storeListingsQueryKeys,
} from './store-listings.constants';
import { StoreListing, StoreListingPrice } from './store-listings.types';
import { useApiQuery } from '../../hooks';
import { ServerError } from '../../types';
import {
  buildUrlFromFilters,
  fetchApiRequest,
  usePaginatedTableApiQuery,
  useRequestOptions,
} from '../../utils';

export function useStoreListings(
  tableState: UsePaginatedTableApiQuery<StoreListing>['tableState'],
  columns: UsePaginatedTableApiQuery<StoreListing>['columns'],
  baseUrl = STORE_LISTINGS_API_URL
) {
  return usePaginatedTableApiQuery<StoreListing>({
    baseUrl,
    columns,
    tableState,
    queryKey: [...storeListingsQueryKeys.all, baseUrl, tableState],
  });
}

export function useSortableStoreListings() {
  const { url, options } = useRequestOptions({
    url: STORE_LISTINGS_API_URL,
  });

  return useQuery<
    PaginationResponse<StoreListing>,
    ServerError,
    StoreListing[]
  >({
    queryKey: [
      ...storeListingsQueryKeys.all,
      `${url}?per_page=1000&q[status_eq]=published&q[s]=position+asc&q[product_pricing_model_not_eq]=personalized`,
    ],
    queryFn: () =>
      fetchApiRequest(
        `${url}?per_page=1000&q[status_eq]=published&q[s]=position+asc&q[product_pricing_model_not_eq]=personalized`,
        options
      ),
    select: (response) => response.data,
    staleTime: 0,
    meta: {
      baseUrl: STORE_LISTINGS_API_URL,
      method: 'GET',
    },
  });
}

export function useStoreListing(storeListingId: string | undefined) {
  return useApiQuery<StoreListing>(
    `${STORE_LISTINGS_API_URL}/${storeListingId}`,
    storeListingsQueryKeys.byId(storeListingId ?? ''),
    {
      staleTime: 0,
      enabled: !!storeListingId,
    }
  );
}

export function useCheckStoreListingSlugAvailability() {
  const dispatch = useDispatch();

  const { url, options } = useRequestOptions({
    url: STORE_LISTINGS_API_URL,
    method: 'GET',
  });

  return useMutation<
    StoreListing | undefined,
    ServerError,
    string | null | undefined
  >({
    mutationFn: async (slug: string | null | undefined) => {
      const response = await fetchApiRequest(
        `${url}/?q[slug_eq]=${encodeURIComponent(slug ?? '')}`,
        options
      );

      return response.data[0];
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useCheckStoreListingSlugAvailability',
      baseUrl: `${STORE_LISTINGS_API_URL}/?q[slug_eq]=:slug`,
      method: 'GET',
    },
  });
}

interface UseCreateStoreListingParams {
  productId: string;
  prices: Array<StoreListingPrice>;
  slug?: string | null | undefined;
  requireCancellationReason?: boolean;
  productName: string;
  productCategory?: string | null;
  productSubtitle?: string | null;
  productDescription?: string;
  productImageUrl?: string | null;
}
export function useCreateStoreListing() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { url, options } = useRequestOptions({
    url: STORE_LISTINGS_API_URL,
    method: 'POST',
  });

  return useMutation<StoreListing, ServerError, UseCreateStoreListingParams>({
    mutationFn: ({
      productId,
      prices,
      slug,
      requireCancellationReason,
      productName,
      productDescription,
      productSubtitle,
      productCategory,
      productImageUrl,
    }) => {
      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify({
          product_id: productId,
          prices,
          slug,
          require_cancellation_reason: requireCancellationReason,
          product_name: productName,
          product_image_url: productImageUrl,
          product_description: productDescription,
          product_subtitle: productSubtitle,
          product_category: productCategory,
        }),
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(storeListingsQueryKeys.all);
    },
    onError: ({ error }: any) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useCreateStoreListing',
      baseUrl: STORE_LISTINGS_API_URL,
      method: 'POST',
    },
  });
}

interface UseUpdateStoreListingPrices {
  storeListingId: string;
  prices: Array<StoreListingPrice>;
}
function useUpdateStoreListingPrices() {
  const { url, options } = useRequestOptions({
    url: STORE_LISTINGS_API_URL,
    method: 'PATCH',
  });

  return useMutation<StoreListing, ServerError, UseUpdateStoreListingPrices>({
    mutationFn: ({ storeListingId, prices }: UseUpdateStoreListingPrices) => {
      return fetchApiRequest(`${url}/${storeListingId}/update_prices`, {
        ...options,
        body: JSON.stringify({ prices }),
      });
    },
    meta: {
      mutationName: 'useUpdateStoreListingPrices',
      baseUrl: `${STORE_LISTINGS_API_URL}/:id/update_prices`,
      method: 'PATCH',
    },
  });
}

interface UseUpdateStoreListing {
  storeListingId: string;
  slug: string | null | undefined;
  requireCancellationReason?: boolean;
  productName?: string;
  productCategory?: string | null;
  productSubtitle?: string | null;
  productDescription?: string;
  productImageUrl?: string | null;
}

function useUpdateStoreListingEditableFields() {
  const { url, options } = useRequestOptions({
    url: STORE_LISTINGS_API_URL,
    method: 'PATCH',
  });

  return useMutation<StoreListing, ServerError, UseUpdateStoreListing>({
    mutationFn: ({
      storeListingId,
      slug,
      requireCancellationReason,
      productName,
      productImageUrl,
      productDescription,
      productSubtitle,
      productCategory,
    }: UseUpdateStoreListing) => {
      return fetchApiRequest(`${url}/${storeListingId}`, {
        ...options,
        body: JSON.stringify({
          slug,
          require_cancellation_reason: requireCancellationReason,
          product_name: productName,
          product_image_url: productImageUrl,
          product_description: productDescription,
          product_subtitle: productSubtitle,
          product_category: productCategory,
        }),
      });
    },
    meta: {
      mutationName: 'useUpdateStoreListingEditableFields',
      baseUrl: `${STORE_LISTINGS_API_URL}/:id`,
      method: 'PATCH',
    },
  });
}

interface UseUpdateStoreListingWithPricesParams extends UseUpdateStoreListing {
  isStoreListingEditable: boolean;
  prices?: Array<StoreListingPrice>;
}

export function useUpdateStoreListing() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const updateStoreListingPrices = useUpdateStoreListingPrices();
  const updateStoreListing = useUpdateStoreListingEditableFields();

  return useMutation<
    StoreListing,
    ServerError,
    UseUpdateStoreListingWithPricesParams
  >({
    mutationFn: async (params) => {
      // since one of the mutations can fail and the other can succeed,
      // we need to keep track of whether we should invalidate the cache or not.
      let shouldInvalidate = false;

      const { storeListingId, isStoreListingEditable, prices, ...rest } =
        params;

      try {
        const updatedStoreListing = await updateStoreListing.mutateAsync({
          storeListingId,
          ...rest,
        });

        shouldInvalidate = true;

        if (isStoreListingEditable && prices) {
          return await updateStoreListingPrices.mutateAsync({
            storeListingId,
            prices,
          });
        }

        return updatedStoreListing;
      } finally {
        if (shouldInvalidate) {
          queryClient.invalidateQueries(storeListingsQueryKeys.all);
        }
      }
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Product listing updated successfully'));
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useUpdateStoreListing',
      baseUrl: `${STORE_LISTINGS_API_URL}/:id`,
      method: 'PATCH',
    },
  });
}

interface UseUpdateStoreListings {
  items: Array<{ id: string; position: StoreListing['position'] }>;
}
export function useUpdateStoreListingsPositions() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { url, options } = useRequestOptions({
    url: STORE_LISTINGS_API_URL,
    method: 'POST',
  });

  return useMutation<StoreListing, ServerError, UseUpdateStoreListings>({
    mutationFn: ({ items }: UseUpdateStoreListings) => {
      return fetchApiRequest(`${url}/bulk_update`, {
        ...options,
        body: JSON.stringify({ items }),
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(storeListingsQueryKeys.all);
      dispatch(toastrSuccess('Store was updated successfully'));
    },
    onError: ({ error }: any) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useUpdateStoreListingsPositions',
      baseUrl: `${STORE_LISTINGS_API_URL}/bulk_update`,
      method: 'POST',
    },
  });
}

interface UsePublishStoreListingParams {
  storeListingId: string;
}
export function usePublishStoreListing() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: STORE_LISTINGS_API_URL,
    method: 'POST',
  });

  return useMutation<StoreListing, ServerError, UsePublishStoreListingParams>({
    mutationFn: ({ storeListingId }) => {
      return fetchApiRequest(`${url}/${storeListingId}/publish`, options);
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Product published successfully'));

      queryClient.invalidateQueries(storeListingsQueryKeys.all);
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'usePublishStoreListing',
      baseUrl: `${STORE_LISTINGS_API_URL}/:id/publish`,
      method: 'POST',
    },
  });
}

export function useNonePaginatedStoreListings(params?: {
  filters: Array<PaginatedQueryFilterType<StoreListing>>;
}) {
  const { url, options } = useRequestOptions({ url: STORE_LISTINGS_API_URL });

  const requestUrl = buildUrlFromFilters({
    url,
    filters: params?.filters,
    pagination: {
      page: 0,
      pageSize: 1000,
    },
  });

  return useQuery<
    PaginationResponse<StoreListing>,
    ServerError,
    StoreListing[]
  >({
    queryKey: [...storeListingsQueryKeys.all, JSON.stringify(params?.filters)],
    queryFn: () => fetchApiRequest(requestUrl, options),
    select: (response) => {
      // Workaround to filter out personalized products.
      // We cannot filter nested fields via API
      return response.data.filter(
        (storeListing) =>
          storeListing.product.pricing_model !==
          ProductPricingModel.Personalized
      );
    },
    meta: {
      baseUrl: STORE_LISTINGS_API_URL,
      method: 'GET',
    },
  });
}

export function useDeleteStoreListing() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: STORE_LISTINGS_API_URL,
    method: 'DELETE',
  });

  return useMutation<void, ServerError, { storeListingId: string }>({
    mutationFn: ({ storeListingId }) => {
      return fetchApiRequest(`${url}/${storeListingId}`, options);
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Product deleted successfully'));

      queryClient.invalidateQueries(storeListingsQueryKeys.all);
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useDeleteStoreListing',
      baseUrl: `${STORE_LISTINGS_API_URL}/:id`,
      method: 'DELETE',
    },
  });
}

export function useArchiveStoreListing() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: STORE_LISTINGS_API_URL,
    method: 'POST',
  });

  return useMutation<void, ServerError, { storeListingId: string }>({
    mutationFn: ({ storeListingId }) => {
      return fetchApiRequest(`${url}/${storeListingId}/archive`, options);
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Product archived successfully'));

      queryClient.invalidateQueries(storeListingsQueryKeys.all);
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useArchiveStoreListing',
      baseUrl: `${STORE_LISTINGS_API_URL}/:id/archive`,
      method: 'POST',
    },
  });
}

export function useFindCatalogProductStoreListings(productId: string | null) {
  const { url, options } = useRequestOptions({ url: STORE_LISTINGS_API_URL });

  const requestUrl = buildUrlFromFilters({
    url,
    filters: [
      {
        id: 'product_id',
        value: productId,
        type: PaginatedFilterTypeEnum.Eq,
      },
    ],
    pagination: {
      page: 0,
      pageSize: 1000,
    },
  });

  return useQuery<
    PaginationResponse<StoreListing>,
    ServerError,
    StoreListing[]
  >({
    enabled: !!productId,
    queryKey: [...storeListingsQueryKeys.all, JSON.stringify({ productId })],
    queryFn: () => fetchApiRequest(requestUrl, options),
    select: (response) => response.data,
    staleTime: 0,
    meta: {
      baseUrl: STORE_LISTINGS_API_URL,
      method: 'GET',
    },
  });
}
