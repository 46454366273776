import styled from 'styled-components';

import { getStyledThemeColor } from '@portals/utils';

export const StatCardContainer = styled.div`
  background-color: ${getStyledThemeColor('white')};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04), 0 0 2px rgba(0, 0, 0, 0.06),
    0 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  width: 100%;
  padding: 25px 30px;
  transition: box-shadow 0.15s ease-in-out;
  display: grid;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 0 2px rgba(0, 0, 0, 0.1),
      0 0 1px rgba(0, 0, 0, 0.1);
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 18px;
      line-height: 32px;
      font-weight: bold;
    }
  }
`;
