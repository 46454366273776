import { Button, Group, Stack, Text } from '@mantine/core';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { JsonEditor } from '@portals/ui';
import { IsJsonString } from '@portals/utils';

import Response from '../../components/Response';
import TestCommand from '../../components/TestCommand';
import { getApiDevice, updateDevice } from '../../redux/actions/api';

const DEVICE_INFO_BASE = `{
  "firmware_version": "1.0.2",
  "name": "New device"
}`;

const UpdateDevice = ({ access, updateDevice }) => {
  const [deviceInfo, setDeviceInfo] = useState(DEVICE_INFO_BASE);
  const validDeviceInfo = IsJsonString(deviceInfo);

  return (
    <Stack data-testid="update-command-stack">
      <JsonEditor onChange={setDeviceInfo} data={deviceInfo} size={10} />

      {!validDeviceInfo && <Text color="red">Invalid json</Text>}

      <Group>
        <Button
          disabled={!access.id || !validDeviceInfo}
          onClick={() => updateDevice(access.id, JSON.parse(deviceInfo))}
        >
          Send
        </Button>
        <span>
          Device ID:
          {access.id || 'Not set'}
        </span>
      </Group>

      {access.id && validDeviceInfo && (
        <TestCommand
          url={`/${access.id}`}
          data={JSON.parse(deviceInfo)}
          method="PUT"
        />
      )}

      <Response type="updateDevice" />
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  access: getApiDevice(state),
});

export default connect(mapStateToProps, { updateDevice })(UpdateDevice);
