import { Button } from '@mantine/core';
import { useForm } from '@mantine/form';
import React from 'react';

import { useAssignSupportSeatToUser, UserResponseType } from '@portals/api/ui';
import {
  ModalBody,
  ModalCenteredMediaLayout,
  ModalFooter,
} from '@portals/core';
import { ModalProps, UsersSelect } from '@portals/framework';
import { Members } from '@portals/framework/icons';

export interface AssignSupportSeatToUserModalProps
  extends ModalProps<{
    usersList: UserResponseType[];
  }> {}

export function AssignSupportSeatToUserModal({
  closeMe,
  data: { usersList },
}: AssignSupportSeatToUserModalProps) {
  const assignSupportSeatToUsers = useAssignSupportSeatToUser();

  const form = useForm({
    initialValues: {
      userId: '',
    },
  });

  const options = usersList.map((user) => ({
    value: user.id,
    label: user.name,
    email: user.email,
  }));

  const onSubmit = async (values: typeof form.values) => {
    try {
      await assignSupportSeatToUsers.mutateAsync({ userId: values.userId });
      closeMe();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <ModalCenteredMediaLayout
      opened
      onClose={closeMe}
      title="Add Members"
      media={<Members />}
    >
      <form onSubmit={form.onSubmit(onSubmit)}>
        <ModalBody>
          <UsersSelect
            withinPortal
            data={options}
            {...form.getInputProps('userId')}
          />
        </ModalBody>

        <ModalFooter grow>
          <Button variant="default" onClick={closeMe}>
            Cancel
          </Button>
          <Button type="submit">Add member</Button>
        </ModalFooter>
      </form>
    </ModalCenteredMediaLayout>
  );
}
