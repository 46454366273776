import cx from 'classnames';
import isMobile from 'is-mobile';
import { castArray, noop } from 'lodash/fp';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import styled, { createGlobalStyle } from 'styled-components';

import { getStyledThemeColor } from '@portals/utils';

import errorSrc from '../../assets/img/icons/404.svg';

interface Page404Props {
  title?: string | JSX.Element | Array<string | JSX.Element>;
  description?: string | JSX.Element;
  actionLabel?: string | JSX.Element;
  onAction?: () => void;
  hideActionButton?: boolean;
  hideImage?: boolean;
}

const DEFAULT_TITLE = ['Oops...', 'The page not found'];
const DEFAULT_DESC =
  "The page your are looking for doesn't exist or has been moved";
const DEFAULT_ACTION_LABEL = 'Return to main page';

export function Page404({
  title = DEFAULT_TITLE,
  description = DEFAULT_DESC,
  actionLabel = DEFAULT_ACTION_LABEL,
  onAction = noop,
  hideActionButton = false,
  hideImage = false,
}: Page404Props) {
  return (
    <Container className={cx({ mobile: isMobile() })}>
      <ContentOverride />

      <div>
        {castArray(title).map((title, index) => (
          <h1 className="mb-0" key={index} data-testid="404-page-title">
            {title}
          </h1>
        ))}

        <div className="mt-2 mb-5 font-size-lg text-muted">{description}</div>

        {!hideActionButton && (
          <Link to="/" onClick={onAction}>
            <Button color="dark" size="lg" className="btn-branding pr-5 pl-5">
              {actionLabel}
            </Button>
          </Link>
        )}
      </div>

      {!hideImage && (
        <div>
          <img src={errorSrc} alt="404" />
        </div>
      )}
    </Container>
  );
}

const ContentOverride = createGlobalStyle`
  body {
    .wrapper {
      >.main {
        >.content {
          padding: 0;
          background-color: ${getStyledThemeColor('gray150')};
        }
      }
    }

    .shop-page-container {
      .shop-page-content {
        height: calc(100vh - 120px);

        .shop-page-content-wrapper {
          height: 100%;
        }
      }
    }

    .auth-container {
      @media (max-width: 1450px) {
        .hero-container {
          display: none;
        }

        #logo_dark {
          display: unset;
        }

        #logo {
          display: none;
        }
      }

      .auth-content {
        .auth-content-wrapper {
          width: 100%;
        }
      }
    }
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 450px);
  column-gap: 50px;
  justify-content: center;
  padding: 50px;

  > div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    max-width: 450px;

    h1 {
      font-size: 40px;
    }

    img {
      max-width: 100%;
      max-height: calc(100vh - 300px);
    }
  }

  &.mobile {
    grid-template-columns: unset;
    display: flex;
    flex-direction: column-reverse;

    > div {
      align-items: center;

      h1 {
        text-align: left;
        width: 100%;
      }
    }
  }
`;
