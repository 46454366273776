import { Box, createStyles, Group, Stack, Text } from '@mantine/core';
import React, { useMemo } from 'react';

import { usePartnerConfig } from '@portals/api/partners';

import { SidebarMock } from './SidebarMock';
import { StoreMock } from './StoreMock';
import { useBrandingContext } from '../../branding.context';

export function StoreBrowserMock() {
  const { classes, cx } = useStyles();

  const { portalConfig, defaultValues } = useBrandingContext();

  const partnerConfig = usePartnerConfig();

  const isWithBanner = useMemo(() => {
    if (portalConfig.design?.store?.with_banner) {
      return portalConfig.design?.store?.with_banner;
    }

    return defaultValues?.design?.store?.with_banner;
  }, [
    defaultValues.design?.store?.with_banner,
    portalConfig.design?.store?.with_banner,
  ]);

  const logoSrc =
    portalConfig.design?.store?.logo || defaultValues.design?.store?.logo;
  const bannerSrc =
    portalConfig.design?.store?.banner || defaultValues.design?.store?.banner;

  return (
    <Box className={classes.container}>
      <SidebarMock />

      <div className={cx(classes.content, { 'with-banner': isWithBanner })}>
        {/* Navbar */}
        <Box
          sx={(theme) => ({
            borderBottom: `2px solid ${theme.colors.blue_gray[0]}`,
          })}
        />

        {/* Banner */}
        {isWithBanner ? (
          <img
            height="100%"
            width="100%"
            src={bannerSrc ?? undefined}
            className={classes.bannerImage}
            alt="banner"
          />
        ) : null}

        {/* Header */}
        <Group
          p="md"
          noWrap
          sx={(theme) => ({
            overflow: 'hidden',
            borderBottom: `1px solid ${theme.colors.gray[2]}`,
          })}
        >
          {/* Header - Partner Logo */}
          <img
            width={60}
            height={60}
            src={logoSrc ?? undefined}
            className={classes.oemLogo}
            alt="oem logo"
          />

          {/* Header - Partner name & store tagline */}
          <Stack spacing="xs" mah={60} sx={{ overflow: 'hidden' }}>
            <Text size="lg" color="blue_gray.8" weight={500} truncate>
              {partnerConfig?.display_name}
            </Text>

            {portalConfig?.design?.store?.tagline ? (
              <Text size="sm" color="blue_gray.4" truncate>
                {portalConfig?.design?.store?.tagline}
              </Text>
            ) : null}
          </Stack>
        </Group>

        <StoreMock />
      </div>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    overflow: 'hidden',
    display: 'grid',
    gridTemplateColumns: '195px 1fr',
    background: theme.white,
  },
  content: {
    overflow: 'auto',
    display: 'grid',
    gridTemplateRows: '40px 90px 1fr',

    '&.with-banner': {
      gridTemplateRows: '40px 100px 90px 1fr',
    },
  },
  bannerImage: {
    objectFit: 'cover',
  },
  oemLogo: {
    objectFit: 'contain',
    borderRadius: theme.radius.md,
  },
}));
