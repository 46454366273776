import { createStyles } from '@mantine/core';
import React, { ReactNode } from 'react';

import { AddressBar } from './AddressBar';

interface BrowserMockProps {
  favicon?: string | null;
  windowTitle?: string;
  domain: string;
  className?: string;
  children: ReactNode;
}

export function BrowserMock({
  favicon = 'favicon.ico',
  windowTitle = 'Xyte - Simply Connected',
  domain,
  className,
  children,
}: BrowserMockProps) {
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.container, className)}>
      <AddressBar
        favicon={favicon as string}
        windowTitle={windowTitle}
        domain={domain}
      />

      {children}
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateRows: 'max-content 1fr',
    borderRadius: theme.radius.md,
    overflow: 'hidden',
    backgroundColor: theme.white,
    boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.1)',
  },
}));
