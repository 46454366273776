import { Badge, createStyles, Group } from '@mantine/core';
import { noop } from 'lodash/fp';
import React from 'react';

import {
  PartnerInvitationsResponse,
  PartnerInvitationStatus,
  usePartnerInvitations,
  useResendPartnerInvitation,
} from '@portals/api/partners';
import {
  PARTNERS_INVITATIONS_API_URL,
  partnersInvitationsQueryKeys,
} from '@portals/api/partners';
import { usePermissionAccess } from '@portals/framework';
import { ReactComponent as Send2 } from '@portals/icons/linear/send-2.svg';
import { ReactComponent as TickCircle } from '@portals/icons/linear/tick-circle.svg';
import {
  AvatarCell,
  BadgeCell,
  DateCell,
  PaginatedTable,
  RowMenuItem,
  RowMenuItems,
} from '@portals/table';
import {
  PaginatedFilterTypeEnum,
  TableColumn,
  TableFilterTypeEnum,
} from '@portals/types';

import { InvitePartnerButton } from './InvitePartnerButton';
import { RelationshipTypeEnum } from '../../modals/InvitePartner/types';

export function PartnersInvitationsTable() {
  const { canEdit } = usePermissionAccess();
  const resendInvitation = useResendPartnerInvitation();

  const { classes } = useStyles();

  const columns: TableColumn<PartnerInvitationsResponse>[] = [
    {
      dataField: 'invited_partner_display_name',
      filter: {
        type: TableFilterTypeEnum.Text,
      },
      sort: true,
      text: 'Name',
      minWidth: 450,
      formatter: (_, { email, partner, certified }) => (
        <Group position="apart" noWrap>
          <AvatarCell
            src={partner?.logo}
            label={partner?.display_name ?? email ?? ''}
          />

          {certified ? (
            <Badge
              bg="indigo.0"
              color="gray.9"
              size="lg"
              leftSection={<TickCircle width={16} height={16} />}
              fz="xs"
              fw={400}
              className={classes.badge}
            >
              Certified service provider
            </Badge>
          ) : null}
        </Group>
      ),
    },
    {
      dataField: 'relationship_type',
      text: 'Type',
      sort: true,
      filter: {
        type: PaginatedFilterTypeEnum.Select,
        options: {
          [RelationshipTypeEnum.Manufacturer]:
            RelationshipTypeEnum.Manufacturer,
          [RelationshipTypeEnum.Integrator]: RelationshipTypeEnum.Integrator,
          [RelationshipTypeEnum.Distributor]: RelationshipTypeEnum.Distributor,
          [RelationshipTypeEnum.Reseller]: RelationshipTypeEnum.Reseller,
        },
      },
    },
    {
      dataField: 'created_at',
      text: 'Date sent',
      sort: true,
      filter: {
        type: PaginatedFilterTypeEnum.Date,
      },
      formatter: (_, { created_at }) => <DateCell date={created_at} />,
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      filter: {
        type: PaginatedFilterTypeEnum.Select,
        options: {
          [PartnerInvitationStatus.Pending]: PartnerInvitationStatus.Pending,
          [PartnerInvitationStatus.Rejected]: PartnerInvitationStatus.Rejected,
        },
      },
      formatter: (_, { status }) => {
        const color = getInvitationsStatusColor(status);
        return <BadgeCell label={status} size="lg" radius="sm" color={color} />;
      },
    },
  ];

  return (
    <PaginatedTable<PartnerInvitationsResponse>
      readOnly={!canEdit('customers')}
      keyField="id"
      name="partner-invitations"
      onSelected={noop}
      columns={columns}
      defaultSortBy={[{ id: 'created_at', desc: true }]}
      dataHookQueryKey={partnersInvitationsQueryKeys.invited()}
      dataHookUrl={`${PARTNERS_INVITATIONS_API_URL}/?q[status_in][]=pending&q[status_in][]=rejected`}
      dataHook={usePartnerInvitations}
      additionalActions={() => <InvitePartnerButton />}
      rowMenu={({ row, wrapperProps }) => {
        const items: RowMenuItem[] = [
          {
            id: 'resend',
            Icon: Send2,
            label: 'Resend invitation',
            onClick: () => {
              wrapperProps.menuRef?.onClose();
              resendInvitation.mutate(row.original.id);
            },
          },
        ];

        return <RowMenuItems wrapperProps={wrapperProps} items={items} />;
      }}
    />
  );
}

function getInvitationsStatusColor(
  status: PartnerInvitationsResponse['status']
) {
  switch (status) {
    case 'canceled':
      return 'blue_gray';
    case 'pending':
      return 'primary';
    case 'rejected':
      return 'red';
    case 'accepted':
      return 'teal';
  }
}

const useStyles = createStyles(() => ({
  badge: {
    overflow: 'visible',
  },
}));
