import {
  createStyles,
  Group,
  Paper,
  Tabs,
  TabsProps,
  Text,
} from '@mantine/core';
import dayjs from 'dayjs';
import React from 'react';

import {
  SubscriptionDetailsType,
  useDeviceUsageBasedStateHistories,
} from '@portals/api/partners';
import { DetailsPanel } from '@portals/core';
import {
  UsageBasedLineChart,
  UsageBasedPriceBreakdownPreview,
  useUsageBasedLineChart,
} from '@portals/framework';
import {
  formatNumber,
  formatPrice,
  getCurrencySign,
  mediumDateFormatter,
} from '@portals/utils';

import { OverviewTab } from './OverviewTab';

interface UsageBasedSubscriptionDetailsPanelContentProps {
  subscription: SubscriptionDetailsType;
  isPaymentHistoryPanelOpen: boolean;
  togglePaymentHistoryPanel: () => void;
}

export function UsageBasedSubscriptionDetailsPanelContent({
  subscription,
  isPaymentHistoryPanelOpen,
  togglePaymentHistoryPanel,
}: UsageBasedSubscriptionDetailsPanelContentProps) {
  const { classes } = useStyles();

  const deviceUsageBasedStateHistories = useDeviceUsageBasedStateHistories({
    deviceId: subscription.device?.id as string,
    from: dayjs().startOf('month').toDate(),
    to: dayjs().toDate(),
  });

  const { chartData, currentMeterDelta } = useUsageBasedLineChart({
    stateHistories: deviceUsageBasedStateHistories.data,
    telemetryKey: subscription.product.usage_based_telemetry_key ?? undefined,
    currentMeter: subscription.usage_based?.current_meter ?? undefined,
    lastMeterEnd: subscription.usage_based?.last_meter_end ?? undefined,
  });

  const usageChargeInScu = subscription.usage_based?.usage_charge_in_scu ?? 0;
  const basePriceInScu = subscription.usage_based?.base_price_in_scu ?? 0;

  return (
    <Tabs defaultValue="overview" keepMounted={false} styles={tabsStyles}>
      <Tabs.List grow>
        <Tabs.Tab value="overview">Overview</Tabs.Tab>
        <Tabs.Tab value="usage">Usage</Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="overview">
        <DetailsPanel.Body>
          <OverviewTab subscription={subscription} />
        </DetailsPanel.Body>
      </Tabs.Panel>

      <Tabs.Panel value="usage">
        <DetailsPanel.Body>
          <Paper className={classes.usageBasedCard}>
            <Group position="apart" mb="sm">
              <Group spacing="xs">
                <Text span color="gray.6" weight={500}>
                  Current usage cost
                </Text>

                {subscription.device?.last_seen_at &&
                  subscription.usage_based && (
                    <UsageBasedPriceBreakdownPreview
                      currencyCode={subscription.currency}
                      deviceLastSeenAt={subscription.device.last_seen_at}
                      currentUsageBasedMeter={currentMeterDelta}
                      basePriceInScu={basePriceInScu}
                      usageChargeInScu={usageChargeInScu}
                      usageBasedUnitName={
                        subscription.product.usage_based_unit_name
                      }
                    />
                  )}
              </Group>

              <Text
                className={classes.showHistoryAction}
                onClick={togglePaymentHistoryPanel}
              >
                {isPaymentHistoryPanelOpen ? 'Hide history' : 'Show history'}
              </Text>
            </Group>

            <Group spacing="xs" mb="md" fz={24} fw={700}>
              <Text span color="gray.5">
                {getCurrencySign(subscription.currency)}
              </Text>
              <Text span color="gray.8">
                {formatPrice({
                  value: usageChargeInScu + basePriceInScu,
                  currencyCode: subscription.currency,
                  withCurrencySign: false,
                })}
              </Text>
            </Group>

            <Text color="gray.6" weight={500} size="xs">
              due on {mediumDateFormatter(subscription.next_charge_at)}
            </Text>
          </Paper>

          <Paper className={classes.usageBasedCard}>
            <Text color="gray.6" weight={500} mb="sm">
              {subscription.product.usage_based_display_name}
            </Text>

            {!subscription.usage_based?.current_meter ? (
              <Text>No data received yet</Text>
            ) : (
              <>
                <Group spacing="xs" mb="md" fz={24}>
                  <Text color="gray.8" weight={700}>
                    {formatNumber(currentMeterDelta)}
                  </Text>
                  <Text color="gray.6">
                    {subscription.product.usage_based_unit_name}
                  </Text>
                </Group>

                <UsageBasedLineChart
                  chartData={chartData}
                  usageBasedUnitName={
                    subscription.product.usage_based_unit_name
                  }
                  usageBasedDisplayName={
                    subscription.product.usage_based_display_name
                  }
                />
              </>
            )}
          </Paper>
        </DetailsPanel.Body>
      </Tabs.Panel>
    </Tabs>
  );
}

const useStyles = createStyles((theme) => ({
  usageBasedCard: {
    padding: theme.spacing.xxl,
    borderRadius: 20,
    border: `1px solid ${theme.colors.gray[1]}`,
    backgroundColor: theme.fn.rgba(theme.colors.blue_gray[0], 0.33),
  },

  showHistoryAction: {
    fontWeight: 600,
    color: theme.fn.primaryColor(),
    cursor: 'pointer',
    userSelect: 'none',
  },
}));

const tabsStyles: TabsProps['styles'] = (theme) => ({
  root: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'max-content 1fr',
  },
  tabsList: {
    marginInline: theme.spacing.xxl,
  },
  panel: {
    height: '100%',
  },
});
