import { Box, createStyles, ScrollArea, Stack } from '@mantine/core';
import { every } from 'lodash/fp';
import React from 'react';

import { OrderType, useStoreSettings } from '@portals/api/partners';
import { useGenericContext } from '@portals/framework';
import {
  OrderAttachmentsCard,
  OrderCustomerCard,
  OrderNotesToSellerCard,
  OrderShipmentProgress,
  OrderSummaryCard,
} from '@portals/framework/route-modals';
import { useOpenModal } from '@portals/redux';
import { ProductTypeEnum } from '@portals/types';

import {
  MissingInformationAlert,
  PurchaseOrderAlert,
  PurchaseOrderReviewAlert,
} from './Alerts';
import { OrderProductsCard } from './order-products-card/OrderProductsCard';
import { OrderPaymentMethodCard } from './OrderPaymentMethodCard';
import { ReviewPurchaseOrderModalProps } from '../../modals';

export function OrderContent() {
  const order = useGenericContext<OrderType>();
  const openModal = useOpenModal();
  const { classes } = useStyles();

  const storeSettings = useStoreSettings();

  const isInactiveOrder = ['expired', 'archived', 'failed'].includes(
    order.payment_status
  );
  const isDeviceLicenceOnly = every(
    (item) => item.product.product_type === ProductTypeEnum.DeviceLicense,
    order.items
  );
  const isMissingPO =
    !isInactiveOrder && order.payment_status === 'awaiting_po_upload';
  const isPendingPOApproval =
    !isInactiveOrder && order.payment_status === 'awaiting_po_approval';
  const isMissingPPP =
    !isInactiveOrder && order.fulfillment_status === 'missing_information';

  return (
    <ScrollArea styles={{ viewport: { '> div': { height: '100%' } } }}>
      <Box className={classes.wrapper}>
        <Stack className={classes.container} spacing="xl">
          {isMissingPO ? <PurchaseOrderAlert /> : null}
          {isPendingPOApproval ? (
            <PurchaseOrderReviewAlert
              onReview={() =>
                openModal<ReviewPurchaseOrderModalProps['data']>(
                  'ReviewPurchaseOrderModal',
                  {
                    orderId: order.id,
                  }
                )
              }
            />
          ) : null}
          {isMissingPPP ? <MissingInformationAlert /> : null}

          {isInactiveOrder || isDeviceLicenceOnly ? null : (
            <OrderShipmentProgress
              fulfillmentStatus={order.fulfillment_status}
            />
          )}

          <div className={classes.grid}>
            <Stack spacing="xl">
              <OrderProductsCard />
              <OrderSummaryCard
                isTaxEnabled={Boolean(storeSettings.data?.tax_enabled)}
              />
              <OrderPaymentMethodCard />
            </Stack>

            <Stack spacing="xl">
              <OrderNotesToSellerCard />
              <OrderCustomerCard />
              <OrderAttachmentsCard />
            </Stack>
          </div>
        </Stack>
      </Box>
    </ScrollArea>
  );
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: 'grid',
    background: theme.white,
    width: '100%',
    height: '100%',
    gridTemplateColumns: '1fr',
    justifyItems: 'center',
    backgroundColor: theme.colors.gray[0],
    overflow: 'hidden',
    position: 'relative',
  },
  container: {
    maxWidth: 1600,
    width: '100%',
    height: '100%',
    padding: 30,
    overflow: 'hidden',
  },
  grid: {
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    gap: theme.spacing.xl,
  },
}));
