import { createStyles, Group, Stack, Text, ThemeIcon } from '@mantine/core';
import { AnimatePresence, motion } from 'framer-motion';
import { noop } from 'lodash/fp';
import React, { useCallback, useEffect, useState } from 'react';

import { useCurrentUser } from '@portals/api/ui';
import { NameAbbreviationAvatar } from '@portals/core';
import { ReactComponent as NewIcon } from '@portals/icons/bold/new.svg';
import { ReactComponent as CloudAdd } from '@portals/icons/bulk/cloud-add.svg';
import { ReactComponent as Messages3 } from '@portals/icons/bulk/messages-3.svg';

import { InsightItem } from './InsightItem';

const responseText = `Based on the device logs, telemetries, commands, and firmware version data we have collected, we have identified some potential issues with your connected device.

Firstly, we have detected that your device's firmware version is outdated. This may be the cause of some of the issues you are experiencing. We recommend updating the firmware to the latest version to ensure that your device is operating at peak performance and to address any known bugs or vulnerabilities.

Secondly, based on the telemetries and device logs, we have observed that there have been some errors during the execution of certain commands. This could be due to incorrect syntax or a bug in the device's software. We recommend reviewing the user manual or contacting our customer support team to ensure that you are using the correct commands.

In summary, we recommend updating your device's firmware to the latest version, troubleshooting your network to ensure a stable connection, and reviewing your commands to ensure they are being executed correctly.`;

interface InsightsResponseProps {
  userMessage: string;
}

export function InsightsResponse({ userMessage }: InsightsResponseProps) {
  const { cx, classes, theme } = useStyles();
  const currentUser = useCurrentUser();
  const [showActions, setShowActions] = useState(false);

  const onFinished = useCallback(() => {
    setShowActions(true);
  }, []);

  const gptResponse = useChatGPTLikeResponse({
    text: responseText,
    delay: 80,
    onFinished,
  });

  return (
    <Stack>
      <Stack spacing="sm" align="flex-end">
        <Group>
          <Text color="gray.4">1 min ago</Text>
          <NameAbbreviationAvatar
            radius="md"
            size={40}
            name={currentUser.data?.name ?? ''}
          />
        </Group>
        <div className={cx(classes.message, classes.userMessage)}>
          <Text>{userMessage}</Text>
        </div>
      </Stack>

      <Stack spacing="sm" align="flex-start">
        <Group>
          <ThemeIcon
            size="xl"
            variant="gradient"
            gradient={{
              deg: 124,
              from: '#9747FF',
              to: theme.fn.themeColor('primary', 3),
            }}
          >
            <NewIcon width={20} height={20} />
          </ThemeIcon>
          <Text color="gray.4">1 min ago</Text>
        </Group>
        <div className={cx(classes.message, classes.botMessage)}>
          <Text sx={{ whiteSpace: 'pre-line' }}>{gptResponse}</Text>
        </div>
      </Stack>

      <AnimatePresence initial={false}>
        {showActions && (
          <motion.div
            initial={{ y: '-100%', opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: '-100%', opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Stack spacing="xs">
              <InsightItem
                title="Update Firmware"
                description="Update device firmware to the latest version"
                icon={<CloudAdd />}
                onClick={noop}
              />
              <InsightItem
                title="Generate Response to Customer"
                description="Suggest a response based on this solution"
                icon={<Messages3 />}
                onClick={noop}
              />
            </Stack>
          </motion.div>
        )}
      </AnimatePresence>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  message: {
    paddingInline: theme.spacing.md,
    paddingBlock: theme.spacing.sm,
  },
  botMessage: {
    borderRadius: '0 24px 24px 24px',
    color: theme.colors.gray[9],
    backgroundColor: theme.fn.rgba(theme.colors.indigo[0], 0.6),
  },
  userMessage: {
    borderRadius: '24px 0 24px 24px',
    color: theme.white,
    backgroundColor: theme.fn.primaryColor(),
  },
}));

function useChatGPTLikeResponse({
  text,
  delay,
  onFinished,
}: {
  text: string;
  delay: number;
  onFinished: () => void;
}) {
  const [displayedText, setDisplayedText] = useState('');

  useEffect(() => {
    let tokenIndex = 0;
    const tokens = text.split(' ');

    const interval = setInterval(() => {
      if (tokenIndex < tokens.length) {
        setDisplayedText((prev) => `${prev} ${tokens[tokenIndex]}`);
        tokenIndex++;
      } else {
        clearInterval(interval);
        onFinished();
      }
    }, delay);

    return () => {
      clearInterval(interval);
      onFinished();
    };
  }, [text, delay, onFinished]);

  return displayedText;
}
