import { SimpleGrid } from '@mantine/core';
import React, { FC } from 'react';

import { CustomerWithControlledType } from '@portals/api/partners';
import { VerticalScrollBar } from '@portals/scrollbar';

import { CustomerTileView } from './CustomerTileView';

interface CustomersTilesViewProps {
  customers: Array<CustomerWithControlledType>;
}

export const CustomersTilesView: FC<CustomersTilesViewProps> = ({
  customers,
}) => {
  return (
    <VerticalScrollBar>
      <SimpleGrid cols={3} spacing="md">
        {customers.map((customer) => (
          <CustomerTileView key={customer.id} customer={customer} />
        ))}
      </SimpleGrid>
    </VerticalScrollBar>
  );
};
