import { Stack } from '@mantine/core';
import React from 'react';

import { DesignImageInput } from '../../common/fields/ImageField';

export function DeviceClaimingForm() {
  return (
    <Stack>
      <DesignImageInput
        valuePath="design.cec_device_claiming.banner"
        field={{
          title: 'Banner',
          name: 'banner',
          cropConfig: {
            aspectRatio: 10,
            width: 1300,
            height: 130,
          },
          previewConfig: {
            width: 200,
            height: 20,
          },
          height: 200,
        }}
      />

      <DesignImageInput
        valuePath="design.cec_device_claiming.logo"
        field={{
          title: 'Logo',
          name: 'logo',
          cropConfig: {
            aspectRatio: 1,
            width: 200,
            height: 200,
          },
          height: 200,
        }}
      />
    </Stack>
  );
}
