import React, { useMemo, useState } from 'react';

import { Product, useFeatureFlags } from '@portals/api/partners';
import {
  isPartnerFeatureOn,
  ModalProps,
  WizardProvider,
  WizardSteps,
} from '@portals/framework';
import { ProductTypeEnum } from '@portals/types';

import { calcPhysicalIntegrationType } from './physical-product/physical-product-utils';
import {
  DEVICE_LICENSE_STEPS_MAP,
  DEVICE_LICENSE_STEPS_MAP_WITH_PPP,
  DEVICE_PLATFORM_STEPS_MAP,
  PHYSICAL_PRODUCT_STEPS_MAP,
  PHYSICAL_PRODUCT_STEPS_MAP_WITH_PPP,
  PRODUCT_TYPE_FIRST_STEP,
} from './steps-map';
import { ProductWizardContext, StepId } from './types';
import { WizardContent } from './WizardContent';

const initialErrors: ProductWizardContext['errors'] = {
  [StepId.Integration]: '',
  [StepId.GeneralInformation]: {
    name: '',
    description: '',
  },
  [StepId.ProductDetails]: {
    licenseData: '',
    supportedCommandIds: '',
  },
  [StepId.PricingModel]: {
    general: '',
    usageBasedDisplayName: '',
    usageBasedTelemetryKey: '',
    usageBasedUnitName: '',
  },
  [StepId.TaxDetails]: '',
};

function composeInitialProduct(
  product: ProductWizardModalData['product']
): ProductWizardContext['product'] {
  return {
    name: product?.name,
    category: product?.category,
    subtitle: product?.subtitle,
    description: product?.description,
    image_url: product?.image_url,
    license_data: product?.license_data,
    tax_group_id: product?.tax_group_id,
    device_model_id: product?.device_model?.id,
    product_type: product?.product_type,
    post_purchase_parameters: product?.post_purchase_parameters,
    pricing_model: product?.pricing_model,
    sku: product?.sku,
    usage_based_display_name: product?.usage_based_display_name,
    usage_based_telemetry_key: product?.usage_based_telemetry_key,
    usage_based_unit_name: product?.usage_based_unit_name,
    supported_command_ids: product?.supported_commands?.map(
      (supportedCommand) => supportedCommand.id
    ),
  };
}

type ProductWithoutId = Omit<Product, 'id'>;
export type ProductWizardModalData =
  | {
      intent: 'create';
      product: undefined;
    }
  | {
      intent: 'duplicate';
      product: ProductWithoutId;
    }
  | {
      intent: 'edit';
      product: Product;
    };

export interface ProductWizardModalProps
  extends ModalProps<ProductWizardModalData> {}

export function ProductWizardModal({
  closeMe,
  data: { intent, product },
}: ProductWizardModalProps) {
  const isEdit = intent === 'edit';

  const featureFlags = useFeatureFlags();
  const [contextData, setContextData] = useState<ProductWizardContext>({
    intent,

    product: composeInitialProduct(product),

    productId: intent === 'edit' ? (product as Product)?.id : null,

    physicalIntegrationType: calcPhysicalIntegrationType(
      product?.product_type,
      product?.device_model?.id
    ),

    errors: initialErrors,
  });

  const steps = useMemo(() => {
    const isPhysicalProduct =
      contextData.product?.product_type === ProductTypeEnum.Physical;
    const isDeviceLicenseProduct =
      contextData.product?.product_type === ProductTypeEnum.DeviceLicense;
    const isPlatformLicenseProduct =
      contextData.product?.product_type === ProductTypeEnum.PlatformLicense;

    if (contextData.product?.product_type === undefined) {
      return PRODUCT_TYPE_FIRST_STEP;
    }

    if (isPartnerFeatureOn(featureFlags, 'ppp') && isPhysicalProduct) {
      return PHYSICAL_PRODUCT_STEPS_MAP_WITH_PPP;
    }

    if (isPartnerFeatureOn(featureFlags, 'ppp') && isDeviceLicenseProduct) {
      return DEVICE_LICENSE_STEPS_MAP_WITH_PPP;
    }

    if (isPhysicalProduct) {
      return PHYSICAL_PRODUCT_STEPS_MAP;
    }

    if (isDeviceLicenseProduct) {
      return DEVICE_LICENSE_STEPS_MAP;
    }

    if (isPlatformLicenseProduct) {
      return DEVICE_PLATFORM_STEPS_MAP;
    }
  }, [contextData, featureFlags]);

  const getInitialStep = () => {
    if (
      product &&
      (product.product_type === ProductTypeEnum.Physical ||
        product.product_type === ProductTypeEnum.DeviceLicense)
    ) {
      return StepId.Integration;
    } else if (
      product &&
      product.product_type === ProductTypeEnum.PlatformLicense
    ) {
      return StepId.GeneralInformation;
    }

    return StepId.ProductType;
  };

  return (
    <WizardProvider<ProductWizardContext, StepId>
      steps={steps as WizardSteps<ProductWizardContext, StepId>}
      initialStep={getInitialStep()}
      contextData={contextData}
      setContextData={setContextData}
    >
      <WizardContent
        productId={product && 'id' in product ? product.id : undefined}
        isEdit={isEdit}
        onClose={closeMe}
      />
    </WizardProvider>
  );
}
