import { MantineColor } from '@mantine/core';
import React, { useMemo } from 'react';

import {
  CustomerWithControlledType,
  useIsChannelPartner,
} from '@portals/api/partners';
import { RouteModalLink } from '@portals/framework/route-modals';
import { ReactComponent as Danger } from '@portals/icons/linear/danger.svg';
import { ReactComponent as MonitorMobbile } from '@portals/icons/linear/monitor-mobbile.svg';
import { ReactComponent as Ticket } from '@portals/icons/linear/ticket.svg';
import {
  AvatarCell,
  BadgeCell,
  BooleanCell,
  SmartTable,
  TextWithIconCell,
} from '@portals/table';
import {
  PaginatedFilterTypeEnum,
  TableColumn,
  TableColumnSortValue,
} from '@portals/types';

import { RedirectToCustomerPortalButton } from '../../components/RedirectToCustomerPortalButton';
import { useRedirectToCustomerPortal } from '../../hooks/customers.hooks';

const CUSTOMER_SEVERITY_TYPE: Record<
  CustomerWithControlledType['severity_status'],
  { id: number; title: string; color: MantineColor }
> = {
  high: { id: 3, title: 'High', color: 'red' },
  medium: { id: 2, title: 'Medium', color: 'orange' },
  low: { id: 1, title: 'Low', color: 'teal' },
};

const sortBySeverity: TableColumnSortValue<CustomerWithControlledType> = (
  rowA,
  rowB
) => {
  const rowAValues = rowA.original;
  const rowBValues = rowB.original;

  const { id: rowASeverity } =
    CUSTOMER_SEVERITY_TYPE[rowAValues.severity_status];
  const { id: rowBSeverity } =
    CUSTOMER_SEVERITY_TYPE[rowBValues.severity_status];

  const rowAAdjustedValue = rowASeverity > rowBSeverity ? 1 : 0;
  const rowBAdjustedValue = rowBSeverity > rowASeverity ? 1 : 0;

  return rowAAdjustedValue - rowBAdjustedValue;
};

interface CustomersListViewProps {
  customers: Array<CustomerWithControlledType>;
}

export function CustomersListView({ customers }: CustomersListViewProps) {
  const redirectToCustomerPortal = useRedirectToCustomerPortal();

  const isChannelPartner = useIsChannelPartner();

  const columns = useMemo(() => {
    const tableColumns: Array<TableColumn<CustomerWithControlledType>> = [
      {
        dataField: 'name',
        text: 'Name',
        sort: true,
        formatter: (_, { name, id, logo_url }) => (
          <RouteModalLink modalId="customer" pathParams={[id]}>
            <AvatarCell src={logo_url} label={name} radius="md" />
          </RouteModalLink>
        ),
      },
      {
        dataField: 'devices_count',
        text: 'Devices',
        minWidth: 200,
        sort: true,
        formatter: (_, row) => (
          <TextWithIconCell
            Icon={MonitorMobbile}
            label={row.devices_count.toString()}
            spacing="xs"
            color="primary"
            textProps={{
              component: 'a',
              onClick: () =>
                redirectToCustomerPortal.redirect({
                  customerId: row.id,
                  afterAuthRedirectPathname: '/devices',
                }),
            }}
          />
        ),
      },
      {
        dataField: 'incidents_count',
        text: 'Incidents',
        minWidth: 200,
        sort: true,
        formatter: (_, row) => {
          return (
            <TextWithIconCell
              Icon={Danger}
              label={row.incidents_count.toString()}
              spacing="xs"
              color="primary"
              textProps={{
                component: 'a',
                onClick: () =>
                  redirectToCustomerPortal.redirect({
                    customerId: row.id,
                    afterAuthRedirectPathname: '/incidents',
                  }),
              }}
            />
          );
        },
      },
      {
        dataField: 'tickets_count',
        text: 'Tickets',
        sort: true,
        formatter: (_, row) => (
          <TextWithIconCell
            Icon={Ticket}
            label={row.tickets_count.toString()}
            spacing="xs"
            color="primary"
            textProps={{
              component: 'a',
              onClick: () =>
                redirectToCustomerPortal.redirect({
                  customerId: row.id,
                  afterAuthRedirectPathname: '/tickets',
                }),
            }}
          />
        ),
      },
      {
        dataField: 'severity_status',
        text: 'Status',
        sort: true,
        sortValue: (rowA, rowB) => sortBySeverity(rowA, rowB),
        formatter: (cell, row) => {
          const { color, title } = CUSTOMER_SEVERITY_TYPE[row.severity_status];

          return <BadgeCell color={color} label={`${title} Severity`} />;
        },
      },
    ];

    if (isChannelPartner) {
      tableColumns.push({
        dataField: 'manufacturer',
        text: 'Manufacturer',
        sort: true,
        filter: {
          type: PaginatedFilterTypeEnum.Text,
        },
        formatter: (_, row) => (
          <AvatarCell
            src={row.partner.logo_url}
            label={row.partner.display_name}
            radius="md"
            withAbbreviation={false}
          />
        ),
      });
    }

    tableColumns.push({
      dataField: 'controlled',
      text: 'Access',
      sort: true,
      formatter: (_, row) => {
        if (!row.controlled) {
          return <BooleanCell isActive={false} label="No access" />;
        }

        return (
          <RedirectToCustomerPortalButton customerId={row.id}>
            Visit portal
          </RedirectToCustomerPortalButton>
        );
      },
    });

    return tableColumns;
  }, [isChannelPartner, redirectToCustomerPortal]);

  return (
    <SmartTable<CustomerWithControlledType>
      keyField="id"
      name="partners.customers"
      data={customers}
      columns={columns}
      isUrlSyncEnabled={false}
      noDataIndication={{ title: 'No customers' }}
    />
  );
}
