import cscLeasingSrc from './assets/csc-leasing.png';
import fcbSrc from './assets/first-citizens-bank.jpeg';
import xyteSrc from './assets/xyte.png';
import { ProviderType } from './credit-providers.types';

export const PROVIDERS: Array<ProviderType> = [
  {
    id: 'csc_leasing',
    name: 'CSC Leasing',
    logo: cscLeasingSrc,
    url: 'https://www.cscleasing.com/',
  },
  {
    id: 'fcb',
    name: 'First Citizens Bank',
    logo: fcbSrc,
    url: 'https://www.firstcitizens.com',
  },
  {
    id: 'xyte',
    name: 'Xyte',
    logo: xyteSrc,
    url: 'https://www.xyte.io/product-finance',
  },
];
