import { Carousel, CarouselProps } from '@mantine/carousel';
import { Stack, Text } from '@mantine/core';
import { size } from 'lodash/fp';
import React from 'react';

import {
  useAcceptPartnerInvitation,
  useIncomingInvitations,
  useRejectPartnerInvitation,
} from '@portals/api/partners';
import { ReactComponent as ArrowCircleLeft } from '@portals/icons/linear/arrow-circle-left.svg';
import { ReactComponent as ArrowCircleRight } from '@portals/icons/linear/arrow-circle-right.svg';

import { IncomingInvitationCard } from './IncomingInvitationCard';

const getTitle = (numOfInvitations: number) => {
  if (numOfInvitations > 1) {
    return `There are ${numOfInvitations} invitations waiting for your approval`;
  }

  return `There is 1 invitations waiting for your approval`;
};

interface IncomingInvitationsProps {
  invitations: ReturnType<typeof useIncomingInvitations>['data'];
}

export function IncomingInvitations({ invitations }: IncomingInvitationsProps) {
  const numOfInvitations = size(invitations);

  const acceptInvitation = useAcceptPartnerInvitation();
  const rejectInvitation = useRejectPartnerInvitation();

  if (!invitations || !numOfInvitations) {
    return null;
  }

  return (
    <Stack>
      <Text size="md" color="gray.5">
        {getTitle(numOfInvitations)}
      </Text>

      <Carousel
        slideGap="xl"
        styles={carouselStyles(numOfInvitations)}
        withControls={numOfInvitations > 1}
        nextControlIcon={<ArrowCircleRight width={32} height={32} />}
        previousControlIcon={<ArrowCircleLeft width={32} height={32} />}
      >
        {invitations.map((invitation) => (
          <Carousel.Slide key={invitation.id}>
            <IncomingInvitationCard
              invitation={invitation}
              isLoading={
                acceptInvitation.isLoading || rejectInvitation.isLoading
              }
              onAccept={(invitationId) => acceptInvitation.mutate(invitationId)}
              onReject={(invitationId) => rejectInvitation.mutate(invitationId)}
            />
          </Carousel.Slide>
        ))}
      </Carousel>
    </Stack>
  );
}

const carouselStyles =
  (numOfInvitations: number): CarouselProps['styles'] =>
  (theme) => ({
    root: {
      paddingInline: numOfInvitations > 1 ? 40 : 0,
    },
    controls: {
      padding: 0,
    },
    control: {
      border: 'none',
      boxShadow: 'none',
      color: theme.colors.gray[7],
      backgroundColor: 'transparent',

      '&[data-inactive]': {
        opacity: 0.2,
        cursor: 'default',
      },
    },
  });
