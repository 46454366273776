import { Center, createStyles, Text } from '@mantine/core';
import React from 'react';

import { usePartnerConfig } from '@portals/api/partners';
import { PartnerAvatar } from '@portals/framework';

import { SkeletonCard } from './SkeletonCard';
import { useDesignField } from '../../common/fields/hooks';

export function ConnectPreview() {
  const { classes } = useStyles();

  const logoField = useDesignField('design.cec_connect_page.logo');
  const aboutTextField = useDesignField('design.cec_connect_page.about');

  const partnerConfig = usePartnerConfig();

  return (
    <Center className={classes.container}>
      <div className={classes.content}>
        <SkeletonCard />

        <svg width="100%" height="100%" viewBox="0 0 394 394" fill="none">
          <rect
            x="2.2842"
            y="1.35482"
            width="391.542"
            height="390.465"
            rx="20.3223"
            stroke="#EEEEEE"
            strokeWidth="2.70964"
            fill="white"
          />

          <foreignObject r="50" width="100" height="100" x="148" y="43">
            <PartnerAvatar
              src={logoField.value}
              size="100%"
              partnerName={partnerConfig?.display_name || ''}
            />
          </foreignObject>

          <foreignObject width="80%" height="25" x="10%" y="177">
            <Text size="lg" ta="center" truncate>
              {partnerConfig?.display_name}
            </Text>
          </foreignObject>

          <foreignObject width="80%" height="120" x="10%" y="229">
            <Text size="lg" color="gray.6" ta="center" lineClamp={5}>
              {aboutTextField.value}
            </Text>
          </foreignObject>
        </svg>

        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
      </div>
    </Center>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    height: '100%',
    paddingBlock: 70,
  },
  content: {
    overflow: 'hidden',
    maxHeight: '100%',
    width: '85%',
    padding: theme.spacing.md,
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridTemplateRows: '1fr 1fr',
    gap: theme.spacing.lg,
    borderRadius: theme.radius.lg,
    backgroundColor: theme.colors.gray[1],
  },
}));
