import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { getOr } from 'lodash/fp';
import React, { FC, useState } from 'react';
import { Nav, NavItem, NavLink, Row } from 'reactstrap';
import styled from 'styled-components';

import {
  DeviceModelType,
  TicketDeviceType,
  useFeatureFlags,
  usePortalCapabilities,
} from '@portals/api/partners';
import {
  DetailsView,
  StateView,
  usePermissionAccess,
} from '@portals/framework';
import {
  DeviceType,
  PartnerFeatureFlagsType,
  PartnerPortalCapabilities,
  TabType,
  TicketType,
} from '@portals/types';
import { FADE_IN_OUT } from '@portals/ui';
import { getStyledThemeColor } from '@portals/utils';

import { ActionButtons } from './ActionButtons';
import { Commands } from './Commands';
import DeviceConfig from './DeviceConfig';
import General from './General';
import Incidents from './Incidents';
import Licenses from './Licenses';
import { StateDumps } from './StateDumps';
import Telemetries from './Telemetries';

interface TabVisibleFnExtraParams {
  device: DeviceType | TicketDeviceType;
}

// The `TabComponentProps` are not correct for all the tab `Component`s, so using `any` for now
const TABS_MAP: TabType<
  PartnerFeatureFlagsType,
  PartnerPortalCapabilities,
  any,
  TabVisibleFnExtraParams
>[] = [
  {
    id: 'info',
    title: 'Info',
    Component: General,
  },
  {
    id: 'config',
    title: 'Config',
    Component: DeviceConfig,
    visible: ({ device }) => getOr(true, 'model_settings.config', device),
  },
  {
    id: 'licenses',
    title: 'Licenses',
    Component: Licenses,
  },
  {
    id: 'incidents',
    title: 'Incidents',
    Component: Incidents,
  },
  {
    id: 'dumps',
    title: 'Dumps',
    Component: StateDumps,
    visible: ({ device }) => getOr(false, 'model_settings.file_dumps', device),
  },
  {
    id: 'details',
    title: 'Details',
    Component: DetailsView,
  },
  {
    id: 'state',
    title: 'State',
    Component: StateView,
  },
  {
    id: 'commands',
    title: 'Commands',
    Component: Commands,
  },
  {
    id: 'telemetry',
    title: 'Telemetries',
    Component: Telemetries,
  },
];

interface EditDeviceProps {
  device: DeviceType;
  model: DeviceModelType;
  ticket: TicketType;
}

const EditDevice: FC<EditDeviceProps> = ({ ticket, device, model }) => {
  const { isAdmin, canView, canEdit } = usePermissionAccess();
  const [selected, setSelected] = useState(TABS_MAP[0].id);
  const featureFlags = useFeatureFlags();
  const portalCapabilities = usePortalCapabilities();

  if (!device) return null;

  const visibleTabs = TABS_MAP.filter(
    (tab) =>
      !tab.visible ||
      tab.visible?.({
        isAdmin,
        canView,
        canEdit,
        featureFlags,
        portalCapabilities,
        device,
      })
  );

  let selectedTab = visibleTabs.find((tab) => tab.id === selected);

  // Make sure that if visibleTabs are different from previous devices, we show something
  if (!selectedTab) {
    selectedTab = visibleTabs[Object.keys(visibleTabs)[0]];
    setSelected(selectedTab.id);
    return null;
  }

  const Component = selectedTab.Component;

  return (
    <Container className="tab">
      <div className="mb-3">
        <ActionButtons ticket={ticket} device={device} model={model} />
      </div>

      <Row className="justify-content-between m-0 p-0">
        <Nav tabs>
          {visibleTabs.map(({ id, title, Counter, counterProps }) => (
            <NavItem key={id}>
              <AnimatePresence>
                {selected === id ? <SelectedTab {...FADE_IN_OUT} /> : null}
              </AnimatePresence>

              <NavLink
                className={classNames({ active: selected === id })}
                onClick={() => setSelected(id)}
              >
                {title}
                {Counter && (
                  <Counter
                    {...counterProps(device)}
                    className="d-inline ml-1"
                  />
                )}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </Row>

      <Component device={device} model={model} ticket={ticket} />
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-rows: repeat(2, max-content) 1fr;
  height: 100%;

  .nav-tabs {
    padding: 0;
    border-bottom-color: ${getStyledThemeColor('gray300')};
    border-top-color: ${getStyledThemeColor('gray200')};
    background-color: ${getStyledThemeColor('gray150')};
    height: 50px;
    width: 100%;
    margin: 0;

    .nav-item {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      &:first-of-type {
        a {
          padding-left: 20px !important;
        }
      }

      a {
        z-index: 1;
        padding: 0 15px !important;

        &.active {
          color: ${getStyledThemeColor('dark')} !important;
        }
      }
    }
  }
`;

const SelectedTab = styled(motion.div)`
  position: absolute;
  top: 0;
  background-color: ${getStyledThemeColor('gray300')};
  width: 100%;
  height: 100%;
  z-index: 0;
`;

export default EditDevice;
