import { Avatar, createStyles, Group, Menu, Text } from '@mantine/core';
import React from 'react';

import { useStoreSettings } from '@portals/api/partners';
import { CURRENCIES_FLAGS } from '@portals/countries';
import { ReactComponent as ArrowDown } from '@portals/icons/linear/arrow-down.svg';

import { useStoreListingsTableContext } from './StoreListingTableContextProvider';

export function CurrencySelector() {
  const { classes } = useStyles();
  const storeSettings = useStoreSettings();
  const { currency, setCurrency } = useStoreListingsTableContext();

  if (!storeSettings.isFetched) return null;

  return (
    <Group align="baseline">
      <Text>Store Price</Text>

      <Menu classNames={classes}>
        <Menu.Target>
          <Group
            align="center"
            sx={(theme) => ({ color: theme.colors.gray[5] })}
            spacing="xs"
          >
            <Text size="xs" weight={400}>
              {currency}
            </Text>
            <ArrowDown style={{ width: 15 }} />
          </Group>
        </Menu.Target>

        <Menu.Dropdown>
          {storeSettings?.data?.currencies.map((currencyOption) => (
            <Menu.Item
              key={currencyOption}
              onClick={() => setCurrency(currencyOption)}
            >
              <Group spacing="xs" px="sm">
                <Avatar
                  size="xs"
                  radius="xl"
                  src={CURRENCIES_FLAGS[currencyOption]}
                />

                <Text
                  size="sm"
                  sx={(theme) => ({ color: theme.colors.gray[8] })}
                >
                  {currencyOption}
                </Text>
              </Group>
            </Menu.Item>
          ))}
        </Menu.Dropdown>
      </Menu>
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  body: {
    padding: `${theme.spacing.xs} ${theme.spacing.xs}`,
  },
  itemBody: {
    padding: 0,
  },
  item: {
    padding: theme.spacing.xs,
  },
}));
