import { join } from 'lodash/fp';
import { useMemo } from 'react';
import { pdfjs } from 'react-pdf';

import { WS_URL } from '@portals/utils';

const IS_DEV = process.env.NX_ENV !== 'production';

const PDFJS_WORKER_SRC = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

// While in dev, server's running on diff ports - 'self' doesn't include it
const DEFAULT_SRC = IS_DEV
  ? `'self' localhost:* *.xyte.io *.syncpro.io *.amazonaws.com *.hubspot.com`
  : `'self' *.xyte.io *.syncpro.io *.amazonaws.com *.hubspot.com ${window.location.host}`;

// Native
const BLOB_SRC = 'blob:';
const DATA_SRC = 'data:';
const WS_SRC = WS_URL;
const UNSAFE_INLINE_SRC = `'unsafe-inline'`;
const ALL_SRC = `*`;

// 3-rd party
const GOOGLE_SRC =
  '*.googleapis.com *.gstatic.com *.google.com *.googletagmanager.com *.google-analytics.com';
const AMAZON_SRC = '*.amazonaws.com';
const SENTRY_SRC = '*.sentry.io';
const AMPLITUDE_SRC = '*.amplitude.com';
const LAUNCH_NOTES_SRC = '*.launchnotes.io *.launchnotes.com';
const STRIPE_SRC = '*.stripe.com *.stripe.network';
const YOUTUBE_SRC = 'https://www.youtube.com/embed/';
const DESCOPE_SRC =
  "*.descope.com *.licdn.com *.jsdelivr.net *.font-woff.com 'self' data:";
const METABASE_SRC = 'xyte.metabaseapp.com';
const PENDO_SRC = '*.pendo.io';
const TUNNEL_SRC = '*.hub.tunnel.xyte.io:*';

/*
 * Dictionary:
 * [directive]: 'default-src', 'img-src', 'font-src', ...
 * [source]: 'self', *.url.com, blob:, ...
 *
 * Not all existing directives are assigned here. Whichever directives are un-assigned, are using
 * the 'default-src' directive source.
 * */
const CSP_DIRECTIVES = [
  {
    // Default directive, used for directives that were not assigned here
    directive: 'default-src',
    sources: [DEFAULT_SRC],
  },
  {
    directive: 'object-src',
    sources: ["'none'"],
  },
  {
    // Specifies which sources are allowed to load images. Using "ALL_SRC" bc some of the used
    // images are integration-service based. For example: Slack's logo, Salesforce's logo
    directive: 'img-src',
    sources: [DEFAULT_SRC, ALL_SRC, DATA_SRC, PENDO_SRC],
  },
  {
    // Specifies which sources are allowed to load css.
    // - Google is loading its various CSS-sheets for its Maps & Places elements.
    // - 'unsafe-inline' is telling that it's safe to load inline <style>-based css in header
    directive: 'style-src',
    sources: [
      DEFAULT_SRC,
      STRIPE_SRC,
      UNSAFE_INLINE_SRC,
      PENDO_SRC,
      DESCOPE_SRC,
    ],
  },
  {
    // Specifies which sources are allowed to load fonts.
    // - Google is loading main app's font: Nunito
    directive: 'font-src',
    sources: [DEFAULT_SRC, GOOGLE_SRC, DESCOPE_SRC],
  },
  {
    // Specifies which sources are allowed to load scripts.
    // - Amazon is used for uploading files to S3, required this source to work properly
    // - Google is used for displaying maps
    directive: 'script-src',
    sources: [
      DEFAULT_SRC,
      AMAZON_SRC,
      GOOGLE_SRC,
      PENDO_SRC,
      DESCOPE_SRC,
      TUNNEL_SRC,
    ],
  },
  {
    // Specifies which URLs can be loaded using script interfaces (<a>, fetch(), WebSocket...)
    // - Amazon is used for accessing S3 files
    // - Sentry is used for errors reporting
    directive: 'connect-src',
    sources: [
      DEFAULT_SRC,
      TUNNEL_SRC,
      WS_SRC,
      AMAZON_SRC,
      SENTRY_SRC,
      GOOGLE_SRC,
      AMPLITUDE_SRC,
      LAUNCH_NOTES_SRC,
      PENDO_SRC,
      DESCOPE_SRC,
    ],
  },
  {
    // Specifies valid sources for JavaScript <script> elements, but
    // not inline script event handlers like onclick
    directive: 'script-src-elem',
    sources: [
      DEFAULT_SRC,
      GOOGLE_SRC,
      LAUNCH_NOTES_SRC,
      STRIPE_SRC,
      PENDO_SRC,
      PDFJS_WORKER_SRC,
      DESCOPE_SRC,
      // Hashes for scripts generated by Google Tag Manager.
      // They hash includes GTM IDs provided in env variables,
      // and might break if Google changes its implementation
      `'sha256-wg8b9QWapr5OEdgI5hkglurbO7fw2pwXTOqIA92v1pw='`, // Production Organizations GTM
      `'sha256-AffdoFJMz545k4EM5enoLjIBA22p0WA/fluxShbc4S0='`, // Production Partners GTM
      `'sha256-tVmOazJ4LQBmkXe9yYRFgZ7muZkBOHovJDcQuYsfurg='`, // Staging GTM
    ],
  },
  {
    // Specifies valid sources for stylesheets <style> elements and <link> elements with
    // rel="stylesheet".
    directive: 'style-src-elem',
    sources: [DEFAULT_SRC, UNSAFE_INLINE_SRC, GOOGLE_SRC, DESCOPE_SRC],
  },
  {
    // Specifies valid sources for nested browsing contexts loading using elements such as
    // <frame> and <iframe>
    // - Chargify opens an iframe for payment information
    directive: 'frame-src',
    sources: [
      DEFAULT_SRC,
      GOOGLE_SRC,
      STRIPE_SRC,
      YOUTUBE_SRC,
      DESCOPE_SRC,
      METABASE_SRC,
      PENDO_SRC,
    ],
  },
  {
    // Specifies valid sources for Worker, SharedWorker, or ServiceWorker scripts.
    directive: 'worker-src',
    sources: [DEFAULT_SRC, BLOB_SRC, PENDO_SRC, DESCOPE_SRC],
  },
];

function getCSP() {
  return CSP_DIRECTIVES.reduce((acc, curr) => {
    const { directive, sources } = curr;

    return `${acc}\n${directive} ${join(' ', sources)};`;
  }, '');
}

export function useCSP() {
  return useMemo(() => getCSP(), []);
}
