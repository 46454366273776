import React from 'react';
import { useParams } from 'react-router-dom';

import { useTicket } from '@portals/api/partners';
import { PageBreadcrumbsProps } from '@portals/core';
import { DashboardContentLayout } from '@portals/framework';

import { TicketsListWrapper } from './TicketsListWrapper';
import { TicketView } from './TicketView';

export const Tickets = () => {
  const { id: ticketId } = useParams<{ id: string | undefined }>();
  const ticket = useTicket(ticketId);

  const pageTitle = !ticketId ? 'Tickets' : 'Ticket Information';
  const breadcrumbs: PageBreadcrumbsProps['crumbs'] = ticketId && [
    { label: 'Tickets', to: '/tickets' },
    { label: ticket.data?.title },
  ];

  return (
    <DashboardContentLayout pageTitle={pageTitle} breadcrumbs={breadcrumbs}>
      {ticketId ? <TicketView ticketId={ticketId} /> : <TicketsListWrapper />}
    </DashboardContentLayout>
  );
};
