import { Accordion, AccordionProps, createStyles, Stack } from '@mantine/core';
import { size } from 'lodash/fp';
import React, { useState } from 'react';

import {
  DeviceDetailsType,
  DeviceModelType as OrgDeviceModelType,
} from '@portals/api/organizations';
import { DeviceModelType as PartnerDeviceModelType } from '@portals/api/partners';
import { VerticalScrollBar } from '@portals/scrollbar';
import {
  AccessLevelEnum,
  DeviceType as CommonDeviceType,
} from '@portals/types';

import { Actions } from './Actions';
import { ChildDevices } from './ChildDevices';
import { Commands } from './Commands';
import { Details } from './Details';
import { Events } from './Events';
import { Header } from './Header';
import { ReplaceDeviceAlert } from './ReplaceDeviceAlert';
import { usePermissionAccess } from '../../../../components/permission-access/use-permission-access';
import { TOUR_STEPS_IDS } from '../../../../constants/setup/setup.constants';

interface DeviceInfoProps<
  TDevice extends DeviceDetailsType | CommonDeviceType,
  TDeviceModel extends OrgDeviceModelType | PartnerDeviceModelType
> {
  device: TDevice;
  model: TDeviceModel;
}

export function DeviceInfo<
  TDevice extends DeviceDetailsType | CommonDeviceType,
  TDeviceModel extends OrgDeviceModelType | PartnerDeviceModelType
>({ device, model }: DeviceInfoProps<TDevice, TDeviceModel>) {
  const { classes } = useStyles();
  const { isAdmin } = usePermissionAccess();

  const hasChildDevices =
    size((device as DeviceDetailsType)?.child_devices) > 0;

  const [accordionValues, setAccordionValues] = useState([
    'child-devices',
    'commands',
  ]);

  const isDeviceAdmin = isAdmin || device.access_level >= AccessLevelEnum.Admin;

  return (
    <Stack className={classes.container} spacing="xl">
      <Header<TDevice> device={device} />

      <Actions<TDevice> device={device} />

      {device.registration_failed_at && isDeviceAdmin ? (
        <ReplaceDeviceAlert device={device} />
      ) : null}

      <Events
        deviceId={device?.id}
        waitingForDeviceReplacement={device?.awaiting_replacement}
      />

      <VerticalScrollBar>
        <Stack
          p={0}
          spacing={32}
          data-tour-step-id={TOUR_STEPS_IDS.deviceDetails}
          data-testid="device-details-stack"
        >
          <Details<TDevice> device={device} />

          <Accordion<true>
            multiple
            styles={accordionStyles}
            value={accordionValues}
            onChange={setAccordionValues}
          >
            {hasChildDevices && (
              <Accordion.Item
                data-testid="child-devices-accordion"
                value="child-devices"
              >
                <Accordion.Control>Child Devices</Accordion.Control>

                <Accordion.Panel>
                  <ChildDevices device={device as DeviceDetailsType} />
                </Accordion.Panel>
              </Accordion.Item>
            )}

            <Accordion.Item data-testid="command-accordion" value="commands">
              <Accordion.Control>Commands</Accordion.Control>

              <Accordion.Panel>
                <Commands<TDevice> device={device} />
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </Stack>
      </VerticalScrollBar>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    height: '100%',
    width: '100%',
    borderRight: `1px solid ${theme.colors.gray[2]}`,
    background: theme.white,
    padding: 30,
  },
}));

const accordionStyles: AccordionProps['styles'] = (theme) => ({
  root: {
    backgroundColor: theme.colors.gray[0],
  },

  item: {
    borderBottom: 'none',
    borderTop: `1px solid ${theme.colors.gray[2]}`,
    backgroundColor: theme.white,

    '&:last-child': {
      borderBottom: `1px solid ${theme.colors.gray[2]}`,
    },
  },

  label: {
    color: theme.colors.gray[7],
    paddingBlock: theme.spacing.xl,
  },

  control: {
    paddingInline: 0,

    '&:hover': {
      backgroundColor: 'inherit',
    },
  },

  content: {
    paddingInline: 0,
    paddingTop: 0,
  },
});
