import { Box, createStyles, Divider, ScrollArea, Stack } from '@mantine/core';
import { compact } from 'lodash/fp';
import React, { useMemo } from 'react';

import { SidebarSection } from './SidebarSection';
import { TenantMenu } from './tenant-menu/TenantMenu';
import { useAppConfig } from '../../../context';
import { useCommonConfig } from '../../../hooks/portal-config';
import { useHasSupportSeat } from '../../../hooks/support-seats';
import { usePermissionAccess } from '../../permission-access/use-permission-access';

export function OrganizationsSidebar() {
  const { classes } = useStyles();

  const { routes, extraLayout } = useAppConfig();
  const hasSupportSeat = useHasSupportSeat();
  const { canView, canEdit, isAdmin, isSuperAdmin, isMissingAllPermissions } =
    usePermissionAccess();

  const configuration = useCommonConfig();

  const sidebarSections = useMemo(() => {
    const sections = routes?.dashboard?.map(
      ({ id, headerId, childRoutes, canAccessRoute }) => {
        if (canAccessRoute) {
          const canAccess = canAccessRoute({
            configuration: configuration.data,
            canView,
            canEdit,
            isAdmin,
            hasSupportSeat,
          });

          if (!canAccess) {
            return undefined;
          }
        }

        return <SidebarSection key={id} childRoutes={childRoutes} />;
      }
    );

    return compact(sections);
  }, [
    routes?.dashboard,
    configuration.data,
    canView,
    canEdit,
    isAdmin,
    hasSupportSeat,
  ]);

  const isSidebarEmpty =
    isMissingAllPermissions && !isSuperAdmin && !hasSupportSeat;

  return (
    <div className={classes.container}>
      <Box sx={{ overflow: 'hidden' }}>
        <Box p="md">
          <TenantMenu />
        </Box>

        <Box px="md">
          <Divider bg="gray.2" h={1} w="100%" />
        </Box>
      </Box>

      {isSidebarEmpty ? null : (
        <ScrollArea type="hover" scrollbarSize="8px">
          <Stack className={classes.content}>
            {sidebarSections}

            {extraLayout?.sidebarLayout?.({
              configuration: configuration.data,
            }) ?? null}
          </Stack>
        </ScrollArea>
      )}
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    height: '100vh',
    width: 100,
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    background: theme.white,
    borderRight: `1px solid ${theme.colors.gray[2]}`,
  },
  content: {
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    gap: theme.spacing.xs,
  },
}));
