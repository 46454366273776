import {
  createStyles,
  Group,
  LoadingOverlay,
  Stack,
  Text,
} from '@mantine/core';
import { filter, find, size } from 'lodash/fp';
import React, { useMemo, useState } from 'react';

import { useSupportCenterCustomers } from '@portals/api/partners';
import { useCurrentUser } from '@portals/api/ui';

import { CustomersListView } from './CustomersListView';
import { CustomersTilesView } from './CustomersTilesView';
import { CustomersViewHeaderActions } from './CustomersViewHeaderActions';
import { useSupportState } from './SupportStateProvider';
import { ViewType } from './types';
import { CustomersIncidentsMap } from '../../components/Maps/CustomersIncidentsMap';

export function CustomersView() {
  const { classes, theme } = useStyles();

  const [selectedView, setSelectedView] = useState(ViewType.List);

  const { isLabIncluded } = useSupportState();
  const customers = useSupportCenterCustomers(isLabIncluded);

  const currentUser = useCurrentUser();
  const controlled = currentUser?.data?.accessible_tenants?.organizations;

  const customersWithControlled = useMemo(
    () =>
      customers.data?.map((customer) => ({
        ...customer,
        controlled: find({ id: customer.id }, controlled) !== undefined,
      })),
    [customers, controlled]
  );

  const numberOfCustomers = size(customers.data);

  const customersWithoutLocation = size(
    filter((customer) => customer.map_coordinates, customers.data)
  );

  return customers.isLoading ? (
    <LoadingOverlay visible style={{ position: 'relative' }} />
  ) : (
    <Stack className={classes.container} spacing="xl">
      <Group position="apart">
        <Group spacing="xs">
          <Text size="lg" sx={{ color: theme.colors.blue_gray[6] }}>
            Customers
          </Text>
          <Text size="lg" sx={{ color: theme.colors.blue_gray[2] }}>
            ({customersWithoutLocation}/{numberOfCustomers})
          </Text>
        </Group>

        <CustomersViewHeaderActions
          selectedView={selectedView}
          setView={setSelectedView}
        />
      </Group>

      {selectedView === ViewType.Map && (
        <CustomersIncidentsMap customers={customersWithControlled} />
      )}

      {selectedView === ViewType.List && (
        <CustomersListView customers={customersWithControlled} />
      )}

      {selectedView === ViewType.Tiles && (
        <CustomersTilesView customers={customersWithControlled} />
      )}
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    border: `1px solid ${theme.colors.gray[2]}`,
    backgroundColor: theme.white,
    borderRadius: theme.radius.md,
    padding: theme.spacing.xl,
  },
}));
