import React from 'react';

import {
  AddUserToGroup,
  AttributeUpdated,
  AuditLogsProps,
  CreateGroup,
  CreateIntegration,
  CreateUser,
  DeleteDevice,
  RemoveAccess,
  RemoveUserFromGroup,
  UpdateGroupPermissions,
  UpdateIntegration,
} from '@portals/framework';

import { ClaimDevice } from './ClaimDevice';
import { CreateModel } from './CreateModel';
import { CreateOrganization } from './CreateOrganization';
import { InvitedExistingPartner } from './InvitedExistingPartner';
import { InvitedNewPartner } from './InvitedNewPartner';
import { PartnerAcceptedInvitation } from './PartnerAcceptedInvitation';
import { PartnerRejectedInvitation } from './PartnerRejectedInvitation';
import { PublishModel } from './PublishModel';
import { PurchaseOrderApproved } from './PurchaseOrderApproved';

export const MESSAGES_LIST: AuditLogsProps['messagesList'] = {
  user_created: (auditLog) => <CreateUser auditLog={auditLog} />,
  user_added_to_group: (auditLog) => <AddUserToGroup auditLog={auditLog} />,
  user_removed_from_group: (auditLog) => (
    <RemoveUserFromGroup auditLog={auditLog} />
  ),
  group_created: (auditLog) => <CreateGroup auditLog={auditLog} />,
  update_group_permissions: (auditLog) => (
    <UpdateGroupPermissions auditLog={auditLog} />
  ),
  device_claimed: (auditLog) => <ClaimDevice auditLog={auditLog} />,
  device_deleted: (auditLog) => <DeleteDevice auditLog={auditLog} />,
  integration_created: (auditLog) => <CreateIntegration auditLog={auditLog} />,
  integration_updated: (auditLog) => <UpdateIntegration auditLog={auditLog} />,
  model_created: (auditLog) => <CreateModel auditLog={auditLog} />,
  customer_created: (auditLog) => <CreateOrganization auditLog={auditLog} />,
  removed_access: (auditLog) => <RemoveAccess auditLog={auditLog} />,

  model_published: (auditLog) => <PublishModel auditLog={auditLog} />,

  invited_existing_partner: (auditLog) => (
    <InvitedExistingPartner auditLog={auditLog} />
  ),
  invited_new_partner: (auditLog) => <InvitedNewPartner auditLog={auditLog} />,
  partner_accepted_invitation: (auditLog) => (
    <PartnerAcceptedInvitation auditLog={auditLog} />
  ),
  partner_rejected_invitation: (auditLog) => (
    <PartnerRejectedInvitation auditLog={auditLog} />
  ),
  attribute_updated: (auditLog) => <AttributeUpdated auditLog={auditLog} />,
  purchase_order_approved: (auditLog) => (
    <PurchaseOrderApproved auditLog={auditLog} />
  ),
};
