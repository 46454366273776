import { createStyles, Group } from '@mantine/core';
import React, { ReactNode } from 'react';

import { ProductCategoryBadge } from '../ProductCategoryBadge';
import { ProductImage } from '../ProductImage';

interface ProductCardImageProps {
  imageUrl?: string | null;
  category?: string | null;
  headerSlot?: ReactNode;
  children?: ReactNode;
  className?: string;
}

export function ProductCardImage({
  imageUrl,
  category,
  headerSlot,
  children,
  className,
}: ProductCardImageProps) {
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.container, className)}>
      <ProductImage src={imageUrl} data-testid="product-card-img" />

      {category || headerSlot ? (
        <Group
          className={cx(classes.floatingHeader, 'product-card-image-header')}
        >
          {category && <ProductCategoryBadge category={category} />}
          {headerSlot}
        </Group>
      ) : null}

      {children}
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    position: 'relative',
  },
  floatingHeader: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    padding: theme.spacing.xl,
  },
}));
