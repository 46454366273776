import React from 'react';

import { DesignImageInput } from '../../common/fields/ImageField';

export function NavigationMenuForm() {
  return (
    <DesignImageInput
      valuePath="design.navigation_menu.logo"
      field={{
        title: 'Logo',
        name: 'logo',
        cropConfig: {
          aspectRatio: 1,
          width: 84,
          height: 84,
        },
        previewConfig: {
          width: 84,
          height: 84,
        },
        height: 200,
        inputProps: {
          mb: 15,
        },
      }}
    />
  );
}
