import { LoadingOverlay } from '@mantine/core';
import React from 'react';
import { useParams } from 'react-router-dom';

import { useDeviceModels } from '@portals/api/partners';
import { DashboardContentLayout } from '@portals/framework';

import SingleRule from './SingleRule';

const RulesPage = () => {
  const { id: ruleId } = useParams<{ id: string }>();
  const deviceModels = useDeviceModels();

  if (!deviceModels.isFetched) {
    return <LoadingOverlay visible />;
  }

  return (
    <DashboardContentLayout pageTitle="Rule Information">
      <SingleRule ruleId={ruleId} />
    </DashboardContentLayout>
  );
};

export default RulesPage;
