import {
  DeviceType,
  IntegrationsExtraZoomType,
  IntegrationType,
  LicenseType,
  NotificationType,
  OrganizationAddressType,
  OrganizationIncidentType,
  PaymentIntervalEnum,
  PaymentMethodEnum,
  PeriodEnum,
  RuleType,
  SystemIssuesType,
  TelemetryType,
  TenantType,
  UuidType,
} from './common';
import { CurrencyCode } from './countries';
import { CreditCardType } from './credit-cards';
import { OrganizationStoreListing } from './store-listings';

export interface SidebarType {
  isOpen: boolean;
  isSticky: boolean;
}

export enum CommandStatusType {
  InProgress = 'in_progress',
  Pending = 'pending',
  Done = 'done',
  Aborted = 'aborted',
  Failed = 'failed',
}

export interface CommandType {
  id: string;
  status: CommandStatusType;
  name: string;
  friendly_name: string;
  params: any;
  message: any;
  device_id: string;
  created_at: string;
  tunnel_authenticate_url: string;
  tunnel_status_url: string;
  issuer_name: string | null;
}

export type DataType = Record<string, unknown> & {
  commands: Record<string, CommandType>;
  dev?: {
    auth?: {
      id?: string;
      access_key?: string;
      hub_url?: string;
    };
  };
  devices: Record<string, DeviceType>;
  incidents: Record<string, OrganizationIncidentType>;
  integrations: {
    email?: IntegrationType;
    cisco_teams?: IntegrationType;
    zoom?: IntegrationType<IntegrationsExtraZoomType>;
    mtr?: IntegrationType;
    xio?: IntegrationType;
    q_sys?: IntegrationType;
  };
  licenses: Record<string, LicenseType>;
  rules: Record<string, RuleType>;
  system_issues: Record<string, SystemIssuesType>;
  telemetries: Record<string, TelemetryType>;

  // ORGANIZATION ONLY
  cartPreview: Record<string, any>;
};

export interface StoreStateType {
  storeCurrency: CurrencyCode | null;
  cart: {
    items?: Record<
      string,
      Record<PeriodEnum, { quantity: number; price?: number }>
    >;
    notes?: string;
    notesSwitchStatus?: boolean;
  };
  creditCardId: null | string;
  billingAddressId: null | string;
  shippingAddressId: null | string;
  isSameAddress: boolean;
  paymentMethod: PaymentMethodEnum;
  version: number;
}

export interface StripeInfoSetupIntentType {
  client_secret: string;
}

export interface AuthType {
  client: string;
  token: string;
  uid: string;
  expiry: string;
  change?: boolean;
  global_admin?: boolean;
  org_admin?: boolean;
  confirmed_at: string;
  partner_admin?: boolean;
  id: string;
  access: Array<UuidType>;
  name: string;
  email: string;
  tenant: {
    type: TenantType;
    name: string;
    display_name: string;
    id: string;
  };
}

export enum HubType {
  Home = 'home',
  Products = 'products',
  Ecommerce = 'ecommerce',
  Operations = 'operations',
  Finance = 'finance',
  Support = 'support',
  Marketing = 'marketing',
}

export interface UIType {
  auth: AuthType;
  notifications: Array<NotificationType>;
  network?: Record<string, boolean>;
  authError?: string;
  activeHub: HubType | null;
}

export interface ToastrType {
  toastrs: Array<Record<string, any>>;
  confirm: any;
}

export interface StateType {
  sidebar: SidebarType;
  layout: { isBoxed: boolean };
  ui: UIType;
  data: DataType;
  toastr: ToastrType;
  modals: Record<string, any>;
  errors: Record<string, string>;
  store: StoreStateType;
}

export interface AddProductToCartPayload {
  id: string;
  period: PaymentIntervalEnum;
  quantity: number;
  price?: number;
}

export interface CheckSelectedCurrencyPayload {
  currencies: Array<CurrencyCode>;
  defaultCurrency: CurrencyCode;
}

export interface CheckCartItemsPayload {
  storeListings: Array<OrganizationStoreListing>;
}

export interface CheckSelectedAddressPayload {
  addresses: Array<OrganizationAddressType>;
}

export interface CheckSelectedCreditCardPayload {
  creditCards: Array<CreditCardType>;
}
