import { Stack } from '@mantine/core';
import React from 'react';

import { DesignImageInput } from '../../common/fields/ImageField';
import { TextareaField } from '../../common/fields/TextareaField';

export function ConnectForm() {
  return (
    <Stack>
      <DesignImageInput
        valuePath="design.cec_connect_page.logo"
        field={{
          title: 'Logo',
          name: 'logo',
          cropConfig: {
            aspectRatio: 1,
            width: 200,
            height: 200,
          },
          height: 200,
        }}
      />

      <TextareaField
        label="About"
        valuePath="design.cec_connect_page.about"
        maxRows={5}
        minRows={5}
      />
    </Stack>
  );
}
