import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  DeviceModelType,
  useDeleteDeviceModel,
  useDeviceModel,
  useDuplicateDeviceModel,
  useEditDeviceModel,
} from '@portals/api/partners';
import { PageBreadcrumbsProps } from '@portals/core';
import {
  DashboardContentLayout,
  useConfirmationModal,
} from '@portals/framework';
import { useOpenModal } from '@portals/redux';

import { ModelDetails } from './ModelDetails';
import { ModelMigrationProvider } from './ModelMigrationProvider';
import ModelsList from './ModelsList';
import { ConfigureModelProps } from '../../modals/ConfigureModel';

const Models = () => {
  const { id: modelId } = useParams<{ id: string | undefined }>();
  const model = useDeviceModel(modelId);

  const asyncConfirmation = useConfirmationModal();
  const navigate = useNavigate();

  const openModal = useOpenModal();

  const editDeviceModel = useEditDeviceModel();
  const deleteDeviceModel = useDeleteDeviceModel();
  const duplicateDeviceModel = useDuplicateDeviceModel();

  let breadcrumbs: PageBreadcrumbsProps['crumbs'] | undefined;

  if (modelId && model?.data) {
    breadcrumbs = [
      { label: 'Models', to: '/models' },
      { label: model.data?.model },
    ];
  }

  const onDelete = async (model: DeviceModelType) => {
    const isConfirmed = await asyncConfirmation({
      title: `Delete ${model.model}`,
      description: `Are you sure you want to delete ${model.model}?`,
    });

    if (isConfirmed) {
      try {
        await deleteDeviceModel.mutateAsync(model.id);

        navigate('/models');
      } catch (error) {
        console.error(error?.error);
      }
    }
  };

  const onPublish = async (model: DeviceModelType) => {
    const isConfirmed = await asyncConfirmation({
      title: `Publish ${model.model}`,
      description: `Are you sure you want to publish ${model.model}?`,
    });

    if (isConfirmed) {
      editDeviceModel.mutate({
        ...model,
        active: true,
      });
    }
  };

  const onDuplicate = async (model: DeviceModelType) => {
    try {
      const duplicatedModel = await duplicateDeviceModel.mutateAsync(model.id);
      navigate(`/models/${duplicatedModel.id}/info`);

      openModal('ConfigureModel', { isCreate: false, model: duplicatedModel });
    } catch (error) {
      console.error(error);
    }
  };

  const onEdit = (model: DeviceModelType) => {
    openModal<ConfigureModelProps['data']>('ConfigureModel', {
      isCreate: false,
      model: model,
    });
  };

  return (
    <ModelMigrationProvider>
      <DashboardContentLayout
        pageTitle={modelId ? 'Model Details' : 'Models'}
        breadcrumbs={breadcrumbs}
      >
        {modelId ? (
          <ModelDetails
            modelId={modelId}
            onDuplicate={onDuplicate}
            onEdit={onEdit}
            onDelete={onDelete}
            onPublish={onPublish}
          />
        ) : (
          <ModelsList
            onDuplicate={onDuplicate}
            onEdit={onEdit}
            onDelete={onDelete}
            onPublish={onPublish}
          />
        )}
      </DashboardContentLayout>
    </ModelMigrationProvider>
  );
};

export default Models;
