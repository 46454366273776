import { Anchor, Group, Text } from '@mantine/core';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { SortMenu } from './SortMenu';
import { MenuItemType } from './types';

interface ListActionsProps {
  showSortBySeverity: boolean;
  onSortByChanged: (sortBy: string) => void;
  items: Array<MenuItemType>;
  selectedItem: string;
  closeModal?: () => void;
  viewAllLink?: string;
}

export const ListActions: FC<ListActionsProps> = ({
  showSortBySeverity,
  viewAllLink,
  onSortByChanged,
  items,
  selectedItem,
  closeModal,
}) => {
  return (
    <Group position="apart">
      <Group spacing="xs">
        <Text size="xs" sx={(theme) => ({ color: theme.colors.blue_gray[3] })}>
          Sort by:
        </Text>
        <SortMenu
          showSortBySeverity={showSortBySeverity}
          onSortByChanged={onSortByChanged}
          items={items}
          selectedItemName={selectedItem}
        />
      </Group>

      {viewAllLink ? (
        <Anchor
          component={Link}
          to={viewAllLink}
          onClick={() => {
            closeModal?.();
          }}
          size="xs"
        >
          View All
        </Anchor>
      ) : null}
    </Group>
  );
};
