import React from 'react';

import { Product } from '@portals/api/partners';
import { ReactComponent as Copy } from '@portals/icons/linear/copy.svg';
import { ReactComponent as DirectInbox } from '@portals/icons/linear/direct-inbox.svg';
import { ReactComponent as Edit } from '@portals/icons/linear/edit.svg';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import { RowMenuItem, RowMenuItems } from '@portals/table';
import { RowMenuWrapperType } from '@portals/types';

import {
  canArchiveProduct,
  canDeleteProduct,
  canEditProduct,
} from './products-catalog-page.utils';

interface ProductRowMenuProps {
  wrapperProps: RowMenuWrapperType;
  product: Product;
  onEdit: (product: Product) => void;
  onDuplicate: (product: Product) => void;
  onDelete: (product: Product) => void;
  onArchive: (product: Product) => void;
}

export function ProductRowMenu({
  product,
  wrapperProps,
  onEdit,
  onDuplicate,
  onDelete,
  onArchive,
}: ProductRowMenuProps) {
  const items: RowMenuItem[] = [];

  if (canEditProduct(product)) {
    items.push({
      id: 'edit',
      label: 'Edit',
      Icon: Edit,
      onClick: () => {
        wrapperProps.menuRef?.onClose();
        onEdit(product);
      },
    });
  }

  items.push({
    id: 'duplicate',
    label: 'Duplicate',
    Icon: Copy,
    onClick: () => {
      wrapperProps.menuRef?.onClose();
      onDuplicate(product);
    },
  });

  if (canArchiveProduct(product)) {
    items.push({
      id: 'archive',
      label: 'Archive',
      Icon: DirectInbox,
      onClick: () => {
        wrapperProps.menuRef?.onClose();
        onArchive(product);
      },
    });
  }

  if (canDeleteProduct(product)) {
    items.push({
      id: 'delete',
      label: 'Delete',
      Icon: Trash,
      color: 'red',
      withTopDivider: true,
      onClick: () => {
        wrapperProps.menuRef?.onClose();
        onDelete(product);
      },
    });
  }

  return <RowMenuItems items={items} wrapperProps={wrapperProps} />;
}
