import {
  Button,
  createStyles,
  Group,
  LoadingOverlay,
  Stack,
} from '@mantine/core';
import React from 'react';

import { useSendTestEmail } from '@portals/api/partners';
import { ReactComponent as SmsTracking } from '@portals/icons/linear/sms-tracking.svg';

import { EmailTemplate } from './EmailTemplate';
import { useBrandingContext } from '../branding.context';

export function EmailPreview() {
  const { classes } = useStyles();
  const sendTestEmail = useSendTestEmail();
  const { portalConfig } = useBrandingContext();

  const onSendTestEmail = async () => {
    try {
      await sendTestEmail.mutateAsync({
        ...portalConfig?.email_settings,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <LoadingOverlay visible={sendTestEmail.isLoading} />

      <Stack align="center" justify="center" className={classes.container}>
        <EmailTemplate />

        <Group position="center">
          <Button
            variant="default"
            onClick={onSendTestEmail}
            leftIcon={<SmsTracking width={18} height={18} />}
          >
            Test Email
          </Button>
        </Group>
      </Stack>
    </>
  );
}

const useStyles = createStyles({
  container: {
    height: '100%',
    padding: 70,
  },
});
